import { useSegments } from '@hooks';
import { PageHeader } from '@organisms';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FilterSegments } from './Filter.segments';
import { TableSegments } from './Table.segments';

export const SegmentsPage: React.FC = () => {
  const { getSegmentsWithCounts, segmentsWithCounts, getFilterSegmentList } =
    useSegments();

  const history = useHistory();

  useEffect(() => {
    getSegmentsWithCounts();
  }, []);

  useBreadcrumbs([
    {
      label: 'Segmentos',
    },
  ]);

  const handleChangePagination = (take: number, skip: number) => {
    getSegmentsWithCounts({
      take,
      skip,
    });
  };

  return (
    <>
      <PageHeader
        backToPath='/'
        title='Segmentos'
        subTitle='Gerencie, edite e crie novos Segmentos.'
      />

      <TableSegments
        segmentsWithCounts={segmentsWithCounts}
        onChangePagination={handleChangePagination}
        onDeleteSegment={() => null}
        filterComponent={
          <FilterSegments
            onClickAddSegment={() =>
              history.replace('/screens/segments/detalhes')
            }
            onFilterList={getFilterSegmentList}
          />
        }
      />
    </>
  );
};
