import { ReactComponent as PhotoAlternate } from '@assets/icons/FunnelType/photoAlternate.svg';
import { Col, Typography } from 'antd';
import React from 'react';

export const EmptyUploadImage: React.FC = () => {
  return (
    <Col
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '8px',
        paddingInline: '25px',
      }}
    >
      <PhotoAlternate />
      <Typography.Text style={{ textAlign: 'left' }}>
        Arraste seu assets aqui ou{' '}
        <Typography.Text underline style={{ color: '#177DDC' }}>
          faça upload de um arquivo
        </Typography.Text>
      </Typography.Text>
    </Col>
  );
};
