import { Input } from '@atoms';
import { Form } from 'antd';
import React from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

const { Item: FormItemAntd } = Form;

type ValidationStatus =
  | ''
  | 'error'
  | 'success'
  | 'warning'
  | 'validating'
  | undefined;

export interface FormItemProps
  extends ControllerRenderProps<FieldValues, string> {
  status: ValidationStatus;
  help?: string;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  autoComplete?: string;
  prefix?: React.ReactNode;
}
export const FormItem: React.FC<FormItemProps> = ({
  status,
  help,
  placeholder,
  name,
  onClick,
  autoComplete,
  prefix,
  ...props
}) => {
  return (
    <FormItemAntd validateStatus={status} help={help}>
      <Input
        name={name}
        placeholder={placeholder}
        data-testid={name}
        onClick={onClick}
        autoComplete={autoComplete}
        prefix={prefix}
        {...props}
      />
    </FormItemAntd>
  );
};
