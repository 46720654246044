import { AnyObject } from 'yup/lib/types';

export const convertArrayOfFlatObjectsToCSV = (
  objArray: Array<AnyObject>
): string => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = '';

  for (const index in objArray[0]) {
    row += index + ',';
  }

  row = row.slice(0, -1);
  str += row + '\r\n';

  for (let i = 0; i < array.length; i++) {
    let line = '';

    for (const key in array[i]) {
      if (line !== '') {
        line += ',';
      }

      line += array[i][key];
    }

    str += line + '\r\n';
  }

  return str;
};
