import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { customerAdyenReportApi } from 'src/features/clients/api/customerAdyenReportApi';
import { IAdyenRefundReportApiDto } from 'src/features/clients/types/adyenRefundApiReportDto';
import {
  AdyenTransaction,
  ClientType,
} from 'src/features/clients/types/clientTypes';
import { customerAdyenRefundPdf } from 'src/features/clients/utils/customerAdyenReportUtils';

import { ModalRefund } from '../../components/ModalRefund';

export type TableProps = {
  transactionsAdyen?: AdyenTransaction[];
  dados?: ClientType;
  filter: string;
};

export const TransactionsTabsComponent: FC<TableProps> = ({
  transactionsAdyen,
  dados,
  filter,
}) => {
  const [isModalVisibleRefund, setIsModalVisibleRefund] = useState({
    itemId: 0,
    visible: false,
    amount: 0,
    gatewayTransaction: '',
    document: '',
    email: '',
  });

  const filteredTransactions = useMemo(() => {
    return transactionsAdyen?.filter(transaction => {
      return (
        String(transaction.id).includes(filter) ||
        transaction.gatewayTransaction.includes(filter) ||
        !transaction.subscriptionId ||
        String(transaction.subscriptionId).includes(filter)
      );
    });
  }, [transactionsAdyen, filter]);

  const downloadReport = async (reportIdentifier?: string) => {
    if (reportIdentifier && dados?.email) {
      const response = await customerAdyenReportApi.adyenRefundReportData(
        reportIdentifier,
        dados.email
      );
      customerAdyenRefundPdf((response as IAdyenRefundReportApiDto).data.data);
    }
  };

  const DropdownComponent = (item: AdyenTransaction) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                data-testid='reembolso'
                onClick={() =>
                  setIsModalVisibleRefund({
                    itemId: item.billingId,
                    visible: true,
                    amount: Number(item.amount),
                    gatewayTransaction: item.gatewayTransaction,
                    document: dados?.document ?? '',
                    email: dados?.email ?? '',
                  })
                }
                key='reembolso'
              >
                Reembolso
              </Menu.Item>
              {item.hasReport && (
                <Menu.Item
                  data-testid='report'
                  onClick={() => downloadReport(item.reportIdentifier)}
                  key='reembolso_report'
                >
                  Relatório
                </Menu.Item>
              )}
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  return (
    <div>
      <Table
        scroll={{ x: '100% ' }}
        style={{ width: '100vw' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => Number(a.id) - Number(b.id),
          },
          {
            title: 'Transação',
            dataIndex: 'gatewayTransaction',
            sorter: (a, b) =>
              a.gatewayTransaction.localeCompare(b.gatewayTransaction),
          },
          {
            title: 'Status ',
            dataIndex: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
          },
          {
            title: 'Tipo',
            dataIndex: 'paymentMethod',
            sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: (amount: number) => <>{convertToCurrency(amount)}</>,
            sorter: (a, b) => Number(a.amount) - Number(b.amount),
          },
          {
            title: 'Data da compra',
            dataIndex: 'paymentDate',
            render: (paymentDate: string) => (
              <div>{dateFormatted(paymentDate, true)}</div>
            ),
            defaultSortOrder: 'descend',
            sorter: (a, b) =>
              moment(a.paymentDate).unix() - moment(b.paymentDate).unix(),
          },
          {
            title: 'ID da Assinatura ',
            dataIndex: 'subscriptionId',
            sorter: (a, b) =>
              Number(a.subscriptionId) - Number(b.subscriptionId),
          },
          {
            title: 'Ações',
            key: 'action',
            render: (item: AdyenTransaction) => DropdownComponent(item),
          },
        ]}
        dataSource={filteredTransactions}
      />
      <ModalRefund
        modalValues={isModalVisibleRefund}
        downloadReport={downloadReport}
        setIsModalVisible={() =>
          setIsModalVisibleRefund({
            itemId: 0,
            visible: false,
            amount: 0,
            gatewayTransaction: '',
            document: '',
            email: '',
          })
        }
      />
    </div>
  );
};
