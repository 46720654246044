import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FC, isValidElement, ReactElement, useState } from 'react';

import { Description, Title } from './styles';

type IPageHeader = {
  title: string;
  renderDescription?: React.FC;
  enableRefetch?: boolean;
  refetch?: () => void;
};

export const PageHeader: FC<IPageHeader> = props => {
  const { title, renderDescription, refetch } = props;
  const [isFetching, setFetching] = useState<boolean>();

  const HAS_REFETCH = typeof refetch === 'function';
  const HAS_DESCRIPTION =
    renderDescription && isValidElement(renderDescription);

  const isCTADisabled = () => {
    const INITIAL_STATE = isFetching === undefined;
    return Boolean(INITIAL_STATE || !isFetching);
  };

  const _refetch = async () => {
    setFetching(true);
    if (HAS_REFETCH) {
      await refetch();
    }
    setFetching(false);
  };

  const displayReloadOrReloading = (): ReactElement => (
    <Button
      type='link'
      data-testid='cta-refetch'
      onClick={() => _refetch()}
      icon={isFetching ? <LoadingOutlined /> : <ReloadOutlined />}
      disabled={!props.enableRefetch ?? isCTADisabled()}
    />
  );

  return (
    <Row>
      <Col flex={1}>
        <Row>
          <Title data-testid='title'>{title}</Title>
          {HAS_REFETCH && displayReloadOrReloading()}
        </Row>

        {HAS_DESCRIPTION && (
          <Description data-testid='description-filled'>
            {renderDescription}
          </Description>
        )}
      </Col>
    </Row>
  );
};
