import { Steps } from 'antd';

import { ModalDetailStepProps } from './ModalDetail';

export const ModalDetailSteps = ({
  currentStep,
  setCurrentStep,
}: ModalDetailStepProps) => {
  const getStatusStep = (propStep: number) => {
    if (currentStep > propStep) {
      return 'finish';
    }

    if (propStep === currentStep) {
      return 'process';
    }

    if (propStep < currentStep) {
      return 'wait';
    }
  };

  const items = ['Modelo', 'Conteúdo', 'Agendamento'].map((title, index) => ({
    title,
    status: getStatusStep(index) as 'finish' | 'process' | 'wait' | 'error',
  }));

  return (
    <Steps
      type='navigation'
      size='small'
      current={currentStep}
      onChange={setCurrentStep}
      className='site-navigation-steps'
      items={items}
    />
  );
};
