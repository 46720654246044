import { useSegments } from '@hooks';
import { Modal } from '@models';
import {
  ActionsDropdown,
  ActiveTag,
  InctiveTag,
  ScheduleTag,
} from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { CountResponse } from '@typings';
import { format, isAfter, parseISO } from 'date-fns';
import { useEffect } from 'react';

interface TableModalProps {
  filterComponent: React.ReactNode;
  data?: CountResponse<Modal.IModal>;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditModal: (value: Modal.IModal) => void;
  onDeleteModal: (value: Modal.IModal) => void;
  onPreviewModal?: (value: Modal.IModal) => void;
}

export const TableModal: React.FC<TableModalProps> = ({
  filterComponent,
  data,
  onChangePagination,
  onEditModal,
  onDeleteModal,
  onPreviewModal,
}) => {
  const { getSegmentsList, segmentsList } = useSegments();

  useEffect(() => {
    getSegmentsList({ take: 999 });
  }, []);
  const handleClickEditOption = (params: Modal.IModal) => {
    onEditModal(params);
  };

  const handleClickDeleteOption = (params: Modal.IModal) => {
    onDeleteModal(params);
  };
  const handleClickPreviewOption = (params: Modal.IModal) => {
    onPreviewModal?.(params);
  };

  const getTag = (activated: string, record: Modal.IModal) => {
    if (!activated) return <InctiveTag />;

    if (activated && isAfter(parseISO(record.effectiveDateTime), new Date())) {
      return <ScheduleTag />;
    }

    return <ActiveTag />;
  };

  const headerColumns: PageListProps<Modal.IModal>['columns'] = [
    {
      title: 'Título',
      dataIndex: 'advertisements',
      key: 'advertisements',
      width: '258px',
      render: (advertisements: Modal.IModal['advertisements']) => {
        return advertisements?.[0]?.title;
      },
    },
    {
      title: 'Segmento',
      dataIndex: 'modalSegments',
      key: 'modalSegments',
      width: '226px',
      render: (modalSegments: Modal.IModal['modalSegments'] = []) => {
        return (
          <div>
            {
              segmentsList?.rows.filter(
                segment => String(modalSegments[0]?.segmentId) === segment?.id
              )[0]?.name
            }
          </div>
        );
      },
    },
    {
      title: 'Data de início',
      dataIndex: 'effectiveDateTime',
      key: 'effectiveDateTime',
      width: '156px',
      render: (initialDate: Modal.IModal['effectiveDateTime']) => {
        return <div>{format(parseISO(initialDate), 'dd/MM/yyyy')}</div>;
      },
    },
    {
      title: 'Data de término',
      dataIndex: 'expirationDateTime',
      key: 'expirationDateTime',
      width: '156px',
      render: (endDate: Modal.IModal['expirationDateTime']) => (
        <div>{format(parseISO(endDate), 'dd/MM/yyyy')}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'activated',
      key: 'activated',
      width: '134px',
      render: (status: string, record: Modal.IModal) => {
        return <div>{getTag(status, record)}</div>;
      },
    },
    {
      title: 'Modelo',
      dataIndex: 'type',
      key: 'type',
      width: '150px',
      render: (type: Modal.IModal['type']) => {
        return <div>{Modal.getLabelType(type)}</div>;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text: string | undefined, record: Modal.IModal) => (
        <ActionsDropdown
          onClickEditOption={() => handleClickEditOption(record)}
          onClickDeleteOption={() => handleClickDeleteOption(record)}
          onClickPreviewOption={() => handleClickPreviewOption(record)}
        />
      ),
    },
  ];

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={false}
      onChange={onChangePagination}
    />
  );
};
