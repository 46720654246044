import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { VideoFormData } from '../../types/videoPageTypings';
import { videoPayloadFunnelsPageFactory } from '../../utils/videoFunnelsPageFactory';

export function useTryToRequestCreatingVideoFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: VideoFormData) => {
      const factory = await videoPayloadFunnelsPageFactory(payload);
      await apiCancellationFunnels.tryToRequestCreatingVideoFunnelPage(factory);
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao criar o funil de Vídeo',
          'Ocorreu um erro ao criar o funil de Vídeo, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestCreatingVideoFunnelPage: mutateAsync,
    isLoadingCretingVideo: isLoading,
  };
}
