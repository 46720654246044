import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';

import { useCreateOrderBump } from '../../hooks/useCreateOrderBump';

type TLayoutCreateOrderBumpProps = {
  children: React.ReactNode;
};

export function LayoutCreateOrderBump({
  children,
}: TLayoutCreateOrderBumpProps) {
  const {
    handleCancel,
    dispatchSubmit,
    titlePage,
    ableToSave,
    textButtonSubmit,
    isLoadingSave,
    textButtonPreview,
  } = useCreateOrderBump();
  return (
    <LayoutConfirmationFooter
      onCancel={handleCancel}
      onSave={dispatchSubmit}
      isLoadingSave={isLoadingSave.value}
      cancelLabelButton={textButtonPreview}
      ableToSave={ableToSave.value}
      continueLabelButton={textButtonSubmit}
    >
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={titlePage}
        subTitle={'Crie uma nova oferta especial'}
      />
      {children}
    </LayoutConfirmationFooter>
  );
}
