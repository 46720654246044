import { PageHeader } from '@organisms';
import { coupons } from '@service/api';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { CouponPhysic } from 'src/models';

import { LayoutConfirmationFooter } from '../../../molecules/LayoutConfirmationFooter/LayoutConfirmationFooter.molecule';
import { PhysicCouponForm } from '../components/CouponForm/PhysicCouponForm.component';
import { useProductPhysicCoupon } from '../hooks/useProductPhysicCoupon';

export function EditCouponPhysicPage() {
  const { id }: { id: string } = useParams();
  const couponHookData = useProductPhysicCoupon();

  const [coupon, setCoupon] = useState<CouponPhysic.ICoupon | undefined>(
    undefined
  );

  useEffect(() => {
    couponHookData.setIsLoading(true);
    coupons
      .getPhysicCouponById(Number(id))
      .then(couponData => setCoupon(couponData))
      .catch((error: Error) => {
        OpenNotification(true, 'Erro', error.message, undefined, () => {
          window.history.back();
        });
      })
      .finally(() => couponHookData.setIsLoading(false));
  }, []);

  return (
    <Spin spinning={couponHookData.isLoading}>
      <LayoutConfirmationFooter
        onCancel={couponHookData.handleCancel}
        onSave={couponHookData.dispatchSubmit}
        cancelLabelButton='Descartar'
        ableToSave
      >
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={`Formulário para edição do Cupom ${
            coupon ? coupon.code : '...'
          }`}
          subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
        />
        <PhysicCouponForm
          couponHookData={couponHookData}
          coupon={coupon}
          isEditing={true}
        />
      </LayoutConfirmationFooter>
    </Spin>
  );
}
