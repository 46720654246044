import { DataResponseApiDto } from '@core/dto/data-response-api.dto';
import { EventTypeResponseDto } from '@core/gamefication/dto/event/event-response.dto';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';
import { AxiosResponse } from 'axios';

const BANNER_MS_BASE_URL = enviroments.GAMEFICATION_URL;

export async function getEventTypeList(): Promise<
  DataResponseApiDto<Array<EventTypeResponseDto>>
> {
  const response: AxiosResponse<
    DataResponseApiDto<Array<EventTypeResponseDto>>
  > = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/event/type`,
    method: 'GET',
  });

  if (response?.status !== 200) {
    throw Error(response.data.message);
  }

  return response.data;
}
