import { OptionList, Select } from '@atoms';
import { Form } from 'antd';
import React from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

const { Item: FormItemAntd } = Form;

type ValidationStatus =
  | ''
  | 'error'
  | 'success'
  | 'warning'
  | 'validating'
  | undefined;

export interface FormSelectItemProps
  extends ControllerRenderProps<FieldValues, string> {
  status: ValidationStatus;
  help?: string;
  placeholder?: string;
  onChange: (...event: any[]) => void;
  options: OptionList;
  label?: string;
  mode?: 'multiple' | 'tags';
  innerRef?: any;
}
export const FormSelectItem: React.FC<FormSelectItemProps> = ({
  status,
  help,
  placeholder,
  name,
  onChange,
  label,
  options,
  mode,
  ...props
}) => {
  return (
    <FormItemAntd validateStatus={status} help={help} label={label}>
      <Select
        placeholder={placeholder}
        data-testid={name}
        onChange={onChange}
        options={options}
        mode={mode}
        innerRef={props.innerRef}
        value={props.value || ''}
      />
    </FormItemAntd>
  );
};
