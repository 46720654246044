import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  flex-wrap: wrap;
  justify-content: space-around;

  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
`;
