import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../api';
import { cancellationFunnelsFactory } from '../utils/cancellationFunnelsFactory';

const fetchAllCancellationFunnels = async () => {
  const response =
    await apiCancellationFunnels.tryToRequestAllCancellationFunnels();

  if (response.status === 200) {
    const data = cancellationFunnelsFactory(response.data.data);
    return data;
  }
  OpenNotification(
    true,
    'Erro ao carregar os funis',
    'Ocorreu um erro  ao carregar os funis, por favor tente novamente mais tarde.'
  );

  return response;
};

export function useTryToRequestAllCancellationFunnels() {
  const { data, isLoading } = useQuery(
    ['cancelFunnel'],
    () => fetchAllCancellationFunnels(),
    { refetchOnWindowFocus: false }
  );

  return { funnels: data, isLoading };
}
