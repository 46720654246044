import { Segments } from '@models';
import { AdvancedSwitch, SegmentTypeTag } from '@molecules';
import { KeyAndElement } from '@organisms';
import { Input, Radio } from 'antd';
import React from 'react';

import * as S from './SegmentDetail.style';

interface EventDetailTemplateProps {
  formData?: Segments.SegmentsDto;
  handleChange: (field: string, value: any) => void;
}
export const SegmentsDetailTemplate: React.FC<EventDetailTemplateProps> = ({
  formData,
  handleChange,
}) => {
  const data = [
    {
      key: 'SegmentsDetailTemplate-1',
      name: 'Nome',
      component: (
        <Input
          onChange={evt => handleChange('name', evt.target.value)}
          value={formData?.name}
          placeholder='Insira o nome do Segmento'
          bordered={false}
          allowClear
        />
      ),
    },
    {
      key: 'SegmentsDetailTemplate-2',
      name: 'Descrição',
      component: (
        <Input
          onChange={evt => handleChange('description', evt.target.value)}
          value={formData?.description}
          placeholder='Insira uma descrição sobre este Segmento'
          bordered={false}
          allowClear
        />
      ),
    },
    {
      key: 'SegmentsDetailTemplate-3',
      name: 'Tipo',
      component: (
        <div style={{ paddingLeft: '11px' }}>
          <Radio.Group
            onChange={evt => handleChange('type', evt.target.value)}
            value={formData?.type}
          >
            <Radio value={Segments.TypeEnum.OPERATION}>
              <SegmentTypeTag
                type={Segments.TypeEnum.OPERATION}
                active={formData?.type === Segments.TypeEnum.OPERATION}
              />
            </Radio>
            <Radio value={Segments.TypeEnum.BEHAVIOUR}>
              <SegmentTypeTag
                type={Segments.TypeEnum.BEHAVIOUR}
                active={formData?.type === Segments.TypeEnum.BEHAVIOUR}
              />
            </Radio>
            <Radio value={Segments.TypeEnum.BUSINESS}>
              <SegmentTypeTag
                type={Segments.TypeEnum.BUSINESS}
                active={formData?.type === Segments.TypeEnum.BUSINESS}
              />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      key: 'SegmentsDetailTemplate-4',
      name: 'Status',
      component: (
        <div style={{ paddingLeft: '11px' }}>
          <AdvancedSwitch
            onChange={value => handleChange('activated', value)}
            rightLabel={formData?.activated ? 'Ativo' : 'Inativo'}
            value={formData?.activated}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <S.InfoTitle>Informações do Segmento</S.InfoTitle>
      <KeyAndElement data={data} />
    </div>
  );
};
