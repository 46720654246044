import './style-app.css';
import './style-modal.css';
import './style-table.css';

import { Spinner } from '@atoms';
import { RootState, slices } from '@core/redux';
import { ConfigProvider, Spin } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { FC, useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { queryClient } from 'src/queryClient';

import { AppLayout } from '../features/layout/components/AppLayout';
import { selectCurrentLayout } from '../features/layout/selectors/layoutSelectors';
import { Routes } from './Routes';

const App: FC = () => {
  const isRestoring = useSelector(slices.auth.selectIsRestoringAuth);
  const isAuth = useSelector(slices.auth.selectIsAuth);
  const { loadingApp } = useSelector((state: RootState) => state.layout);
  const currentLayout = useSelector(selectCurrentLayout);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(slices.auth.tryRestoreAuth());
  }, []);

  useEffect(() => {
    if (!isAuth) return;
    dispatch(slices.segments.getAllSegments());
  }, [isAuth]);

  const renderBrowserContent = function () {
    if (currentLayout === 'application') {
      return (
        <AppLayout>
          <Routes />
        </AppLayout>
      );
    }
    return <Routes />;
  };

  const renderContent = function () {
    if (isRestoring === true) {
      return (
        <Centered>
          <Spin />
        </Centered>
      );
    }
    return <BrowserRouter>{renderBrowserContent()}</BrowserRouter>;
  };

  return (
    <>
      {loadingApp > 0 && <Spinner />}
      <div className='App'>
        <ConfigProvider locale={ptBR}>
          <QueryClientProvider client={queryClient}>
            {renderContent()}
          </QueryClientProvider>
        </ConfigProvider>
      </div>
    </>
  );
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default App;
