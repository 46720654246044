import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { CustomerDataResponse } from '../../api/customerApi';
import { useCustomerProfileForm } from '../../hooks/useCustomerProfileForm';
import { calcUtils } from '../../utils/calc';
import { dateUtils } from '../../utils/dates';
import { maskUtils } from '../../utils/masks';
import { stringUtils } from '../../utils/strings';
import { EditCopyInput } from '../EditCopyInput';

type CustomerProfileSectionProps = {
  customerData: CustomerDataResponse;
};

const schema = yup.object().shape({
  phone: yup
    .string()
    .nullable()
    .notRequired()
    .transform(value =>
      stringUtils.transformEmptyToNull(maskUtils.removeMask(value))
    )
    .matches(/^\d{10,13}$/, 'Telefone deve conter 10 a 13 digitos.'),
  document: yup
    .string()
    .nullable()
    .notRequired()
    .transform(value =>
      stringUtils.transformEmptyToNull(maskUtils.removeMask(value))
    )
    .matches(/^\d{11}$/, 'CPF deve conter 11 digitos'),
  email: yup.string().nullable().notRequired().email('E-mail inválido'),
  name: yup.string().nullable().notRequired(),
  challengeParticipation: yup.string().nullable().notRequired(),
});

export const CustomerProfileSection: React.FC<CustomerProfileSectionProps> = ({
  customerData,
}) => {
  const { handleUpdateCustomer } = useCustomerProfileForm();

  const { customer, profile } = customerData;

  const defaultValues = {
    phone: customer.phone,
    document: customer.document,
    email: customer.email,
    name: customer.name,
    challengeParticipation: profile?.challengeParticipation,
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onEditField = (changedField: string, formData: FieldValues) => {
    const sanitizedData = maskUtils.removeMaskFromObject(
      formData as typeof defaultValues,
      ['phone', 'document']
    );

    delete sanitizedData.challengeParticipation; // Temporary until adjust of api

    const payload = {
      id: customer.id,
      ...sanitizedData,
    };

    handleUpdateCustomer(payload, changedField);
  };

  return (
    <FormProvider {...methods}>
      <CustomForm layout='vertical'>
        <CustomTitleForm>Dados cadastrais</CustomTitleForm>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={customer.id}
              name='customerId'
              label='ID do Cliente'
              type='copy'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={customer.phone}
              maskValue={maskUtils.formatPhone}
              name='phone'
              label='Telefone'
              type='edit'
              rules={{ required: false }}
              onSave={onEditField}
              maxLength={19}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={customer.email}
              label='E-mail Cadastro'
              type='copy'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={customer.document}
              name='document'
              label='CPF'
              type='edit'
              maskValue={maskUtils.formatCPF}
              rules={{ required: false }}
              onSave={onEditField}
              maxLength={11}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={String(customer.score)}
              name='score'
              label='Score'
              type='copy'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={customer.email}
              name='email'
              label='E-mail Acesso'
              type='edit'
              rules={{ required: false }}
              onSave={onEditField}
            />
          </Col>
        </Row>
      </CustomForm>
      <CustomForm layout='vertical'>
        <CustomTitleForm>Perfil</CustomTitleForm>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={dateUtils.formatDateToUTC(
                profile?.birthday,
                'dd/MM/yyyy'
              )}
              name='birthday'
              label='Data nascimento'
              type='viewOnly'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={maskUtils.formatBoolean(profile?.hasSonUnder5)}
              name='hasSonUnder5'
              label='Filhos até 5 anos?'
              type='viewOnly'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={
                calcUtils.calculateAge(profile?.birthday)?.toString() || ''
              }
              name='age'
              label='Idade'
              type='viewOnly'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={maskUtils.formatKg(profile?.weight)}
              name='weight'
              label='Peso'
              type='viewOnly'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={maskUtils.formatGender(profile?.gender)}
              name='gender'
              label='Gênero'
              type='viewOnly'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={
                calcUtils.calculateImc(
                  profile?.imc,
                  profile?.height,
                  profile?.weight
                ) || ''
              }
              name='imc'
              label='IMC'
              type='viewOnly'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={maskUtils.formatCustomerLevel(profile?.level)}
              name='level'
              label='Nível'
              type='viewOnly'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={maskUtils.formatObjectives(profile?.objective)}
              name='objectives'
              label='Objetivo(s)'
              type='viewOnly'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='input'
              defaultValue={
                profile?.children ? String(profile?.children) : 'Não informado'
              }
              name='hasSon'
              label='Filhos'
              type='viewOnly'
            />
          </Col>
          <Col xs={24} md={12}>
            <EditCopyInput
              inputType='select'
              defaultValue={maskUtils.formatBoolean(
                profile?.challengeParticipation
              )}
              selectOptions={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
              name='challengeParticipation'
              label='Participou do desafio QD?'
              type='edit'
              maskValue={maskUtils.formatBoolean}
              rules={{ required: false }}
              onSave={onEditField}
            />
          </Col>
        </Row>
      </CustomForm>
    </FormProvider>
  );
};

const CustomTitleForm = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const CustomForm = styled(Form)`
  margin-top: 20px;
`;
