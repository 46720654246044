import { guestApi } from 'src/app/guestApi';

type ChangePasswordResponse = {
  status: number;
  updated?: string;
};

async function changePassword(params: {
  currentPasswordParam: string;
  newPasswordParam: string;
}): Promise<ChangePasswordResponse> {
  const endpoint = `/user/change-password`;
  const data = await guestApi.put(endpoint, {
    currentPassword: params.currentPasswordParam,
    newPassword: params.newPasswordParam,
  });
  return data.data;
}

async function changeNewPassword(params: {
  newAdminPasswordParam: string;
  idUserAdmin: string;
}): Promise<ChangePasswordResponse> {
  const endpoint = `/user/admin/${params.idUserAdmin}/change-password`;
  const data = await guestApi.put(endpoint, {
    newPassword: params.newAdminPasswordParam,
  });
  return data.data;
}

export const userApi = {
  changePassword,
  changeNewPassword,
};
