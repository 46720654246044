import { guestApi } from 'src/app/guestApi';

export interface ListSectorsResponse {
  id: string;
  name: string;
  details: string;
  slugname: string;
  updatedAt: string;
  active: boolean;
}

export interface SectorType {
  id: number;
  name: string;
  details: string;
  slugname: string;
}

export interface SectorCreated {
  status: number;
}

export interface GetAllsectorResponse {
  sector: SectorType[];
  status: number;
}

async function listSectors(): Promise<GetAllsectorResponse> {
  const url = `/sector`;
  const { data } = await guestApi.get(url);
  return data;
}

async function getsectorById(id: string): Promise<ListSectorsResponse> {
  const url = `/sector/` + id;
  const { data } = await guestApi.get(url);
  return data;
}

async function create(param: SectorType): Promise<SectorCreated> {
  const url = `/sector`;
  return guestApi.post(url, param);
}

export const sectorApi = {
  listSectors,
  getsectorById,
  create,
};
