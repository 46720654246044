import { useMutation } from 'react-query';

import { apiCancellationFunnels } from '../api';

export function useTryToRequestRemoveFunnelPage() {
  const { mutateAsync } = useMutation(
    async (id: string) => {
      await apiCancellationFunnels.tryToRequestRemoveCancellationFunnelPage(id);
    },
    {
      onSuccess: success => {
        console.log(success);
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  return { tryToRequestRemoveFunnelPage: mutateAsync };
}
