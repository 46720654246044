import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
`;

export const RightLabel = styled.span`
  margin-left: 12px;
`;

export const LeftLabel = styled.span`
  margin-right: 12px;
`;
