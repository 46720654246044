import { AnyObject } from 'yup/lib/types';

import { guestApi } from 'src/app/guestApi';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { ResponseApiDto } from 'src/core/dto/response-api.dto';

interface IVipGroupSocialNetworkApi {
  obterListaSocialNetwork(): Promise<
    DataResponseApiDto<AnyObject[]> | undefined
  >;
  criarSocialNetwork(values: AnyObject): Promise<ResponseApiDto | undefined>;
}

export function createVipGroupSocialNetworkApi(): IVipGroupSocialNetworkApi {
  async function obterListaSocialNetwork(): Promise<
    DataResponseApiDto<AnyObject[]> | undefined
  > {
    const response = await guestApi.get<DataResponseApiDto<AnyObject[]>>(
      `/vip/group/social-network`
    );

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }
  async function criarSocialNetwork(
    values: AnyObject
  ): Promise<ResponseApiDto | undefined> {
    const response = await guestApi.post<ResponseApiDto>(
      `/vip/group/social-network`,
      {
        name: values.name,
      }
    );

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }

  return {
    obterListaSocialNetwork,
    criarSocialNetwork,
  };
}
