import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  padding: 0 0px 24px 0;
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav::before {
    border-bottom: none;
  }
`;
