import { FileTextOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { env } from 'src/app/env';

import { FormattedOffer } from '../../interfaces/offersInterface';

interface OfferExibitonProps {
  offerActive: FormattedOffer;
  productId?: string;
}

export const CreateOffer: FC<OfferExibitonProps> = ({
  offerActive,
  productId,
}: OfferExibitonProps) => {
  return (
    <>
      <Row
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Title>Oferta Padrão</Title>
        <Button
          style={{ backgroundColor: '#1668DC' }}
          onClick={() => {
            window.location.href = `/formOffers?productId=${productId}`;
          }}
        >
          <PlusCircleOutlined />
          Adicionar nova oferta
        </Button>
      </Row>
      {offerActive && (
        <Row
          style={{
            padding: '16px 16px',
            border: '1px solid var(--color-border, #424242)',
            borderRadius: '6px',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FileTextOutlined />
            <Text style={{ paddingLeft: '8px' }}>
              checkout.queimadiaria /
              <Text
                style={{ color: '#3C89E8', cursor: 'pointer' }}
                onClick={() => {
                  window.open(`${env.CHECKOUT}${productId}`);
                }}
              >
                {' ' + offerActive?.name}
              </Text>
            </Text>
          </Col>
          <Col>
            <Text>
              {`${offerActive?.name} ${offerActive?.installments}x de ${offerActive?.installmentsPrice}/ano`}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
};

const Title = styled.h1`
  margin-bottom: 28px;
  font-size: 20px;
  line-height: 28px;
  color: #dbdbdb;
  font-weight: 600;
`;

const Text = styled.text`
  xmargin-left: 8px;
  font-size: 14px;
  line-height: 22px;
  color: #dbdbdb;
  font-weight: 400;
`;
