import { Switch } from 'antd';
import React from 'react';

import * as S from './AdvancedSwitch.style';

interface AdvancedSwitchProps {
  leftLabel?: string;
  rightLabel?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

export const AdvancedSwitch: React.FC<AdvancedSwitchProps> = ({
  leftLabel,
  rightLabel,
  value,
  onChange,
}) => {
  return (
    <S.SwitchContainer>
      {leftLabel && (
        <S.LeftLabel
          style={{
            color: value ? '#434343' : '#ACACAC',
          }}
        >
          {leftLabel}
        </S.LeftLabel>
      )}
      <Switch onChange={onChange} checked={value} />
      {rightLabel && (
        <S.RightLabel
          style={{
            color: value ? '#ACACAC' : '#434343',
          }}
        >
          {rightLabel}
        </S.RightLabel>
      )}
    </S.SwitchContainer>
  );
};
