import { RcFile } from 'antd/lib/upload';

export enum TypeEnum {
  HIGHLIGHT = 'HIGHLIGHT',
  DEFAULT = 'DEFAULT',
  PAGINATION = 'PAGINATION',
  LAUNCH = 'LAUNCH',
}

export interface IModalSegments {
  id?: string;
  segmentId: number;
  order?: number;
  modalId?: string;
}

export interface IModalAdversements {
  id?: string;
  modalId?: string;
  title: string;
  titleActivated?: boolean;
  description?: string;
  buttonLabel?: string;
  buttonActionLink?: string;
  webImageFileId?: RcFile | string;
  mobileImageFileId?: RcFile | string;
  videoLink?: string;
  createdAt?: string;
  updatedAt?: string;
  openExternalWindow?: boolean;
}

export interface IModal {
  id: string;
  type: TypeEnum;
  effectiveDateTime: string;
  expirationDateTime: string;
  activated: boolean;
  displayScreenLink?: string;
  createdAt?: string;
  updatedAt?: string;
  modalSegments: IModalSegments[];
  advertisements: IModalAdversements[];
}

export interface IDefaultContent {
  id?: string;
  urlLocalShow: string;
  mediaType: string;
  title: string;
  titleActivated?: boolean;
  description?: string;
  textButton?: string;
  redirectButtonUrl?: string;
  openExternalWindow?: boolean;
  urlVideoModal?: string;
  imageWeb?: RcFile | string;
  imageMobile?: RcFile | string;
}

export interface IPaginationContent {
  urlLocalShow: string;
  modalContent: Omit<IDefaultContent, 'urlLocalShow'>[];
}

const ModalTypeLabels: { [key in TypeEnum]: string } = {
  [TypeEnum.HIGHLIGHT]: 'Destaque',
  [TypeEnum.DEFAULT]: 'Padrão',
  [TypeEnum.PAGINATION]: 'Paginação',
  [TypeEnum.LAUNCH]: 'Lançamento',
};

export const getLabelType = (tipo: TypeEnum): string => {
  return ModalTypeLabels[tipo];
};

export interface OrderingAdvertisement {
  title: string | null;
}

export interface OrderingModalItem {
  activated: boolean;
  advertisements: OrderingAdvertisement[];
}

export interface OrderingModal {
  id: string;
  segmentId: number;
  order: number | null;
  modalId: string;
  modal: OrderingModalItem;
}

export interface ResponseOrdering {
  [key: string]: OrderingModal[];
}

export interface RequestSaveSort {
  modalId: string;
  order: number;
  segmentId: number;
}
