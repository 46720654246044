import {
  CalendarOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { isAfter, isBefore } from 'date-fns';

import { CouponDigital } from 'src/models';

import { TColumnCouponDigitalOrder } from '../TableDigital.coupons';

export function getStatusEnumByDates(
  initDate: Date,
  finishDate: Date,
  current: Date
) {
  if (isBefore(current, initDate)) return CouponDigital.StatusEnum.SCHEDULE;

  if (isAfter(current, finishDate)) return CouponDigital.StatusEnum.FINISH;

  return CouponDigital.StatusEnum.ACTIVE;
}

export function parseOrderFilter(orderColumn: string) {
  switch (orderColumn) {
    case 'dateInit':
      return 'start_date';
    case 'dateFinish':
      return 'end_date';
    default:
      return orderColumn;
  }
}

export function getInfoStatus(status: CouponDigital.StatusEnum) {
  switch (status) {
    case CouponDigital.StatusEnum.ACTIVE:
      return {
        Icon: CheckCircleOutlined,
        color: 'success',
        text: 'Ativo',
      };
    case CouponDigital.StatusEnum.FINISH:
      return {
        Icon: MinusCircleOutlined,
        color: 'processing',
        text: 'Finalizado',
      };
    case CouponDigital.StatusEnum.SCHEDULE:
      return {
        Icon: CalendarOutlined,
        color: 'warning',
        text: 'Agendado',
      };
    default:
      return {
        Icon: CalendarOutlined,
        color: 'error',
        text: 'Inativo',
      };
  }
}

export function tableColumnCouponDigitalOrderDefined(
  key: string,
  orderColumn: TColumnCouponDigitalOrder | null
) {
  if (!orderColumn) return undefined;

  return orderColumn.column !== parseOrderFilter(key)
    ? undefined
    : orderColumn.inverse
    ? 'ascend'
    : 'descend';
}
