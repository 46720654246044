import { slices } from '@core/redux';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const GuestRoute: FC<RouteProps> = ({
  children,
  component,
  ...rest
}) => {
  const isAuth = useSelector(slices.auth.selectIsAuth);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = component;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !isAuth ? (
          children ?? <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        );
      }}
    />
  );
};
