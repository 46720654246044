import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useModal } from '@hooks';
import { Modal } from '@models';
import { Template } from '@molecules';
import { PageHeader } from '@organisms';
import { TakeSkipFilter } from '@typings';
import { Modal as AntModal } from 'antd';
import { useEffect } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FilterModals } from './Filter.modals';
import { TableModal } from './Table.modals';

export const ModalPage: React.FC = () => {
  const history = useHistory();

  const [modalToBeDeleted, setModalToBeDeleted] =
    React.useState<Modal.IModal>();

  const [previewModal, setPreviewModal] = React.useState<Modal.IModal>();

  const { deleteModalById, getAllModals, modalList, currentFilter } =
    useModal();

  useEffect(() => {
    getAllModals();
  }, []);

  useBreadcrumbs([
    {
      label: 'Modal',
    },
  ]);

  const handleFilterModal = (
    filterList: { field: string; value?: string }[]
  ) => {
    const current = {
      ...currentFilter,
      filter: {
        ...currentFilter?.filter,
      },
    };

    filterList.forEach(filterToAdd => {
      if (!filterToAdd.value) {
        delete current.filter?.[filterToAdd.field];
      } else {
        current.filter[filterToAdd.field] = filterToAdd.value;
      }
    });

    getAllModals(current as TakeSkipFilter);
  };

  const getTemplateToShow = () => {
    if (!previewModal?.type) return;
    const customModal: Record<Modal.TypeEnum, JSX.Element> = {
      [Modal.TypeEnum.DEFAULT]: (
        <Template.Default
          onClose={() => setPreviewModal(undefined)}
          modalInfo={previewModal}
        />
      ),
      [Modal.TypeEnum.HIGHLIGHT]: (
        <Template.Highlight
          onClose={() => setPreviewModal(undefined)}
          modalInfo={previewModal}
        />
      ),
      [Modal.TypeEnum.PAGINATION]: (
        <Template.Pagination
          onClose={() => setPreviewModal(undefined)}
          modalInfo={previewModal}
        />
      ),
      [Modal.TypeEnum.LAUNCH]: (
        <Template.Pagination
          onClose={() => setPreviewModal(undefined)}
          modalInfo={previewModal}
        />
      ),
    };
    return customModal[previewModal.type];
  };

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de Modais'}
        subTitle={'Cadastrar, editar e configurar todos os modais pós login.'}
      />

      <TableModal
        onChangePagination={(take, skip, pagination) => {
          getAllModals({
            filter: currentFilter,
            take,
            skip,
          });
        }}
        filterComponent={
          <FilterModals
            onAddModal={() => {
              history.push('/screens/modals/detail');
            }}
            onFilterModal={handleFilterModal}
          />
        }
        data={modalList}
        onDeleteModal={modal => setModalToBeDeleted(modal)}
        onPreviewModal={choosedModal => setPreviewModal(choosedModal)}
        onEditModal={modal =>
          history.push('/screens/modals/detail', { ...modal })
        }
      />

      {!!previewModal && getTemplateToShow()}

      <AntModal
        width={704}
        title=''
        open={!!modalToBeDeleted}
        onOk={async () => {
          if (!modalToBeDeleted) {
            OpenNotification(true, 'Nenhuma modal a ser excluída.');
            return;
          }
          setModalToBeDeleted(undefined);
          deleteModalById(modalToBeDeleted);
        }}
        okText='Deletar'
        okButtonProps={{
          style: { backgroundColor: '#a61d24', border: 'none' },
        }}
        onCancel={() => setModalToBeDeleted(undefined)}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <ExclamationCircleOutlined
              style={{
                color: '#D89614',
              }}
            />
          </div>
          Excluir Modal
        </div>

        <div
          style={{
            marginLeft: '24px',
            marginTop: '16px',
          }}
        >
          Você está prestes a excluir o Modal Home, tem certeza que deseja
          continuar?
        </div>
        <div
          style={{
            marginLeft: '24px',
            marginTop: '16px',
          }}
        >
          Essa ação náo poderá ser desfeita.
        </div>
      </AntModal>
    </>
  );
};
