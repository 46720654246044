import {
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
  MoreOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Events } from '@models';
import { PageListProps, PageListTemplate } from '@templates';
import { Button, Dropdown, Menu, Tag } from 'antd';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { env } from 'src/app/env';

import { FormattedOffer } from '../../interfaces/offersInterface';

interface TableOfferProps {
  data: FormattedOffer[];
  productId: string;
}

export const TableOffer: FC<TableOfferProps> = ({
  data,
  productId,
}: TableOfferProps) => {
  const headerColumns: PageListProps<Events.ListEventsResponse>['columns'] = [
    {
      key: 'id',
      title: 'Id da Oferta',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Nome da Oferta',
      dataIndex: 'name',
      render: (text, item) => {
        return (
          <>
            <span>{text}</span>
            {!item.endDate && (
              <Tag style={{ marginInlineStart: '10px' }} color='magenta'>
                Padrão
              </Tag>
            )}
          </>
        );
      },
    },
    {
      key: 'fullPrice',
      title: 'Valor da Oferta',
      dataIndex: 'fullPrice',
    },
    {
      key: 'startDate',
      title: 'Data de Inicio',
      dataIndex: 'startDate',
    },
    {
      key: 'endDate',
      title: 'Data de fim',
      dataIndex: 'endDate',
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: '',
      width: '100px',
      align: 'center',
      render: (item: FormattedOffer) => {
        return (
          <Dropdown
            trigger={['click']}
            arrow={false}
            overlay={() => {
              return (
                <Menu>
                  <MenuItem
                    key='view'
                    onClick={(e: any) => {
                      e.domEvent.stopPropagation();
                      window.location.href = `/formOffers?type=view&id=${item.id}`;
                    }}
                  >
                    <EyeOutlined style={{ marginRight: '12px' }} /> Visualizar
                  </MenuItem>
                  <MenuItem
                    key='edit'
                    onClick={(e: any) => {
                      e.domEvent.stopPropagation();
                      window.location.href = `/formOffers?type=edit&id=${item.id}&productId=${productId}`;
                    }}
                  >
                    <EditOutlined style={{ marginRight: '12px' }} /> Editar
                  </MenuItem>
                  <MenuItem
                    key='copy'
                    onClick={(e: any) => {
                      e.domEvent.stopPropagation();
                      window.location.href = `/formOffers?type=duplicate&id=${item.id}&productId=${productId}`;
                    }}
                  >
                    <CopyOutlined style={{ marginRight: '12px' }} /> Duplicar
                  </MenuItem>
                  <MenuItem
                    key='link'
                    onClick={(e: any) => {
                      e.domEvent.stopPropagation();
                      void navigator.clipboard.writeText(
                        `${env.CHECKOUT}${productId}?offerId=${item.id}`
                      );
                    }}
                  >
                    <LinkOutlined style={{ marginRight: '12px' }} /> Copiar Link
                  </MenuItem>
                </Menu>
              );
            }}
          >
            <ButtomMenu type='text'>
              <MoreOutlined />
            </ButtomMenu>
          </Dropdown>
        );
      },
    },
  ];

  return data ? (
    <PageListTemplate
      columns={headerColumns}
      data={{ count: data.length, rows: data }}
      pageSize={25}
    />
  ) : (
    <EmptyContainer>
      <InfosContainer>
        <UserAddOutlined />
        <Title>Nenhum produto encontrado</Title>
        <Text>
          Ainda não existem cadastros aqui. Você pode criar um novo, clicando no
          botão acima ”Adicionar”.
        </Text>
      </InfosContainer>
    </EmptyContainer>
  );
};

const InfosContainer = styled.div`
  max-width: 332px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
  min-height: 100%;
`;

const Text = styled.h1`
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #7d7d7d;
  text-align: center;
  font-weight: 400;
`;

const Title = styled.h1`
  margin-top: 8px;
  font-size: 18px;
  line-height: 19.8px;
  color: #7d7d7d;
  font-weight: 600;
  text-align: center;
`;

const ButtomMenu = styled(Button)`
  &:focus {
    color: #3c89e8 !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  color: #acacac;

  ${props =>
    props.disabled &&
    css`
      color: rgba(255, 255, 255, 0.25) !important;
    `}
`;
