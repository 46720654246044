import { Empty, Table, TablePaginationConfig } from 'antd';
import React, { useEffect } from 'react';

import { PageListProps } from './PageList.interface';
import * as S from './PageList.style';

export const PageListTemplate: React.FC<PageListProps<any>> = ({
  columns,
  data,
  filterComponent,
  loading,
  amoutLabel,
  onClickRow,
  onChange,
  pageSize = 20,
}) => {
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    total: 0,
    current: 1,
    position: ['bottomCenter'],
    responsive: true,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    size: 'small',
    pageSize,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.count || 0,
    });
  }, [data]);

  const handleChangePagination = (paramsPagination: TablePaginationConfig) => {
    if (
      pagination.current === paramsPagination.current &&
      pagination.pageSize === paramsPagination.pageSize
    )
      return;

    setPagination(prev => ({ ...prev, ...paramsPagination }));
    onChange?.(
      paramsPagination.pageSize || 1,
      ((paramsPagination?.current || 1) - 1) * (paramsPagination.pageSize || 0),
      paramsPagination.current || 1
    );
  };
  return (
    <S.Container>
      <S.FilterHeaderContainer>{filterComponent}</S.FilterHeaderContainer>
      {amoutLabel && data?.rows.length && (
        <S.TableTotalAmountContainer>{amoutLabel}</S.TableTotalAmountContainer>
      )}

      <Table
        rowKey={'id'}
        key={Date.now()}
        bordered
        loading={loading}
        pagination={pagination}
        columns={columns}
        dataSource={data?.rows || []}
        onChange={handleChangePagination}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Nenhum registro encontrado'}
            />
          ),
        }}
        onRow={record => ({
          style: !onClickRow ? undefined : { cursor: 'pointer' },
          onClick: () => {
            if (onClickRow) {
              onClickRow(record);
            }
          },
        })}
      />
    </S.Container>
  );
};

export type { PageListProps };
