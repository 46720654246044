import { Button, Checkbox } from 'antd';
import styled, { css } from 'styled-components';

interface ResponsiveProp {
  isMobile?: boolean;
}

export const Container = styled.div<ResponsiveProp>`
  width: ${({ isMobile }) => (isMobile ? 327 : 688)}px;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #efefef;
`;

export const Content = styled.div`
  padding: 16px 20px;
`;

export const Footer = styled.div<ResponsiveProp>`
  display: flex;
  justify-content: space-between;
  ${({ isMobile }) =>
    isMobile
      ? css`
          flex-direction: column-reverse;
        `
      : css`
          flex-direction: row;
          align-items: center;
        `}
  padding: 20px;
  border-top: 1px solid #efefef;
`;

export const CustomButton = styled(Button)<ResponsiveProp>`
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  background-color: #ff4f00;
  border: none;
  color: #fff;
  margin-bottom: ${({ isMobile }) => (isMobile ? 12 : 0)}px;

  &:focus,
  :active {
    color: #fff;
    text-decoration: none;
    background-color: #ff4f00;
  }

  &:hover {
    color: #fff;
    background-color: #fc6824;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #525252;
  font-size: 12px;
  ${css`
    & .ant-checkbox-inner {
      border-color: #dcdcdc;
    }
    & .ant-checkbox-checked::after {
      border-color: #ff4f00;
    }
    & .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ff4f00;
      border-color: #ff4f00;
    }
  `}
`;
