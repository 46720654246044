import { slices } from '@core/redux';
import { UpsellProducts } from '@models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { offerApi } from 'src/features/offers/apis/offerApis';

import OpenNotification from '../components/antd/OpenNotification';

export const useUpsellProducts = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [allUpsellProductsList, setUpsellProductsList] =
    useState<UpsellProducts.TUpsellProductsResponse>({ count: 0, rows: [] });

  const [currentFilter, setCurrentFilter] = useState<string | null>(null);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getAllUpsellProducts = useCallback(async () => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());

      const response = await offerApi.getProducts();
      setUpsellProductsList({
        count: response.length,
        rows: response,
      });
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllUpsellProducts();
  }, []);

  useBreadcrumbs([
    {
      label: 'Upsell',
    },
    {
      label: 'Produtos',
    },
  ]);

  const handleUpsellProducts = useCallback(() => {
    getAllUpsellProducts();
  }, [getAllUpsellProducts]);

  const handleCreateUpsellProducts = useCallback(() => {
    push('/screens/order-bump/create');
  }, [push]);

  const handleDuplicateUpsellProducts = useCallback(
    (upsellProducts: UpsellProducts.IUpsellProducts) => {
      push('/screens/order-bump/create', {
        defaultValues: upsellProducts,
      });
    },
    [push]
  );

  const handleEditUpsellProducts = useCallback(
    (upsellProducts: UpsellProducts.IUpsellProducts) => {
      push('/screens/order-bump/create', {
        defaultValues: upsellProducts,
        isEditing: true,
      });
    },
    [push]
  );

  const handleClickRow = useCallback(productSellGroup => {
    push('/upsell/flow', {
      productSellGroup,
    });
  }, []);

  const handleFilterUpsellProducts = useCallback(
    (valueFilter: string) => {
      setCurrentFilter(valueFilter || null);
    },
    [getAllUpsellProducts]
  );

  const upsellProducts = useMemo(() => {
    if (!currentFilter) {
      return allUpsellProductsList;
    }
    const productsFiltered = allUpsellProductsList.rows.filter(
      product =>
        String(product.id).includes(currentFilter) ||
        String(product.name).includes(currentFilter)
    );

    return {
      count: productsFiltered.length,
      rows: productsFiltered,
    };
  }, [allUpsellProductsList, currentFilter]);

  return {
    upsellProducts,
    handleFilterUpsellProducts,
    isLoadingData,
    handleCreateUpsellProducts,
    handleUpsellProducts,
    handleDuplicateUpsellProducts,
    handleEditUpsellProducts,
    handleClickRow,
  };
};
