export const NutritionSvg = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_4364_11121'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='48'
      height='48'
    >
      <rect width='48' height='48' fill='white' />
    </mask>
    <g mask='url(#mask0_4364_11121)'>
      <path
        d='M24 42C20.1 42 16.7917 40.6417 14.075 37.925C11.3583 35.2083 10 31.9 10 28C10 24.6667 11.025 21.725 13.075 19.175C15.125 16.625 17.7667 15 21 14.3C20.1333 14.1333 19.3 13.8917 18.5 13.575C17.7 13.2583 16.9833 12.7833 16.35 12.15C15.4167 11.1833 14.7917 10.0667 14.475 8.80001C14.1583 7.53334 14 6.23334 14 4.90001V4.00001C16.8667 3.93334 19.3333 4.87501 21.4 6.82501C23.4667 8.77501 24.4833 11.1667 24.45 14C24.9167 12.5667 25.575 11.2333 26.425 10C27.275 8.76668 28.2333 7.61668 29.3 6.55001C29.6 6.25001 29.95 6.10001 30.35 6.10001C30.75 6.10001 31.1 6.25001 31.4 6.55001C31.7 6.85001 31.85 7.20001 31.85 7.60001C31.85 8.00001 31.7 8.35001 31.4 8.65001C30.5667 9.48334 29.8167 10.3833 29.15 11.35C28.4833 12.3167 27.9167 13.3333 27.45 14.4C30.5833 15.2 33.125 16.8583 35.075 19.375C37.025 21.8917 38 24.7667 38 28C38 31.9 36.6417 35.2083 33.925 37.925C31.2083 40.6417 27.9 42 24 42ZM24 39C27.0667 39 29.6667 37.9333 31.8 35.8C33.9333 33.6667 35 31.0667 35 28C35 24.9333 33.9333 22.3333 31.8 20.2C29.6667 18.0667 27.0667 17 24 17C20.9333 17 18.3333 18.0667 16.2 20.2C14.0667 22.3333 13 24.9333 13 28C13 31.0667 14.0667 33.6667 16.2 35.8C18.3333 37.9333 20.9333 39 24 39Z'
        fill='white'
      />
    </g>
  </svg>
);
