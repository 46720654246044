import { defineEnv } from '@utils/app/defineEnv';

export const enviroments = {
  RUN_MOCKS: defineEnv('REACT_APP_RUN_MOCKS'),
  IS_DEVELOPMENT: defineEnv('NODE_ENV') === 'development',
  BASE_URL: defineEnv('REACT_APP_BASE_URL'),
  GAMEFICATION_URL: defineEnv('REACT_APP_GAMEFICATION_URL'),
  AUTH_ENCRYPT_KEY: defineEnv('REACT_APP_AUTH_ENCRYPT_KEY') as string,
  SEGMENTS_MS_BASE_URL: defineEnv('REACT_APP_SEGMENTS_URL') as string,
  BANNER_MS_BASE_URL: defineEnv('REACT_APP_BANNER_MS_BASE_URL') as string,
};
