import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { useState } from 'react';

import { RequestAnonymizeDTO } from '../interfaces/lgpdInterfaces';
import { CurrentModal } from '../typings/lgpdTipyngs';
import { ModalLgpd } from './ModalLgpd/ModalLgpd';
interface SandwichMenuProps {
  consultLgpd: RequestAnonymizeDTO;
}

export const SandwichMenuTable = ({ consultLgpd }: SandwichMenuProps) => {
  const [showModal, setShowModal] = useState(false);

  const statusButtonActionMapping = {
    'Gerando evidências': {
      text: 'Visualizar',
      stepModal: 'stepTwo' as unknown as CurrentModal,
    },
    'Evidências geradas': {
      text: 'Editar',
      stepModal: 'stepTwo' as unknown as CurrentModal,
    },
    'Evidências não foram geradas': {
      text: 'Visualizar',
      stepModal: 'stepOne' as unknown as CurrentModal,
    },
    'Anonimizando dados': {
      text: 'Visualizar',
      stepModal: 'stepTrhee' as unknown as CurrentModal,
    },
    'Dados anonimizados': {
      text: 'Visualizar',
      stepModal: 'stepThree' as unknown as CurrentModal,
    },
    'Dados não foram anonimizados': {
      text: 'Visualizar',
      stepModal: 'stepThree' as unknown as CurrentModal,
    },
  };

  return (
    <>
      <Dropdown
        trigger={['hover']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                key='edit'
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {statusButtonActionMapping[consultLgpd.status].text}
              </Menu.Item>
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
      {showModal && (
        <ModalLgpd
          setModalVisibility={setShowModal}
          isVisible={showModal}
          alternativeModalCurrent={
            statusButtonActionMapping[consultLgpd.status].stepModal
          }
          tableLgpdConsult={consultLgpd}
        />
      )}
    </>
  );
};
