import { slices } from '@core/redux';
import { Events } from '@models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { events } from '@service/api';

import OpenNotification from 'src/app/components/antd/OpenNotification';

interface EventsFilter {
  name?: { iLike: string };
  eventTypeId?: string;
  statusActivated?: string;
}

type EventsFilterType = EventsFilter | undefined;

export const getAllEvents = createAsyncThunk(
  'events/getAllEvents',
  async (filter: EventsFilterType, { rejectWithValue }) => {
    try {
      const { rows } = await events.getAllEvents(filter);

      return {
        response: rows || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar lista de eventos.');
      return rejectWithValue(error);
    }
  }
);

export const getEventTypeList = createAsyncThunk(
  'events/getEventTypeList',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const { data, message } = await events.getEventTypeList();

      if (!data) {
        throw Error(message);
      }

      return {
        response: data || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar lista de eventos.');
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);

export const getEventById = createAsyncThunk(
  'events/getEventById',
  async (eventId: string, { rejectWithValue, dispatch }) => {
    try {
      if (!eventId) {
        throw Error();
      }
      dispatch(slices.layout.increaseLoading());
      const { data, message } = await events.getEventById(eventId);

      if (!data) {
        throw Error(message);
      }

      return {
        response: data || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar lista de eventos.');
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (
    params: Omit<Events.EventForm, 'id'>,
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const { data, message } = await events.createEvent(params);

      if (!data) {
        throw Error(message);
      }

      OpenNotification(false, 'Evento criado com sucesso.');

      dispatch(slices.events.getAllEvents());
      return {
        response: data || [],
      };
    } catch (error: any) {
      OpenNotification(true, 'Erro ao criar evento.', error.message);
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);

export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (params: Events.EventForm, { rejectWithValue, dispatch }) => {
    try {
      if (!params.id) {
        throw Error();
      }
      dispatch(slices.layout.increaseLoading());
      const { data, message } = await events.updateEvent(params);

      if (!data) {
        throw Error(message);
      }

      OpenNotification(false, 'Evento editado com sucesso!');

      return {
        response: data || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao atualizar evento.');
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventId: string, { rejectWithValue, dispatch }) => {
    try {
      if (!eventId) {
        throw Error();
      }
      dispatch(slices.layout.increaseLoading());
      const { data, message, result } = await events.deleteEvent(eventId);

      if (!result) {
        throw Error(message);
      }

      OpenNotification(false, 'Evento deletado com sucesso.');

      return {
        response: data || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao atualizar evento.');
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);
