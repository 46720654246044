import { Col, Row } from 'antd';
import React from 'react';

import { BackButton } from 'src/app/components/BackButton';

export const LayoutFunnelPage: React.FC = ({ children }) => {
  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '22px',
      }}
    >
      <BackButton />
      <Row justify='center'>
        <Col
          span={12}
          style={{
            minWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {children}
        </Col>
      </Row>
    </Col>
  );
};
