import { FunnelPagesResponse } from '../types/FunnelPagesTypings';
import { pageTypeTranslation } from './pageTypesTranslation';

export const funnelsPagesFactory = (responses: FunnelPagesResponse[]) => {
  const body = responses.map(response => ({
    key: response.id,
    name: response.name,
    pageType: pageTypeTranslation[response.pageType as 'specialist'],
    status: response.active ? 'active' : 'inactive',
  }));

  return body;
};
