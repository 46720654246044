import styled from 'styled-components';

export const ModalModelSpace = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalModelImageSkeleton = styled.div`
  height: 103.62px;
  width: 100%;
  margin-bottom: 8.28px;

  background-color: #434343;
`;

export const ModalModelTitleSkeleton = styled.div`
  height: 8.28px;
  width: 103.62px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const ModalModelDescriptionSkeleton = styled.div`
  height: 8.28px;
  width: 206.95px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const Space = styled.div`
  margin-top: 8.28px;
`;

export const ModalModelContentSkeleton = styled.div`
  height: 16.58px;
  width: 206.95px;
  margin-bottom: 8.28px;

  border-radius: 4.14px;
  background-color: #434343;
`;
