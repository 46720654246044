import {
  CaretRightOutlined,
  PlusCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { RootState, slices } from '@core/redux';
import { Avatar, Button, Col, Collapse, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';
import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import { BadgeComponent } from './Badges/Badge.component';
import { SectionTitle } from './section-title';

const { Panel } = Collapse;
interface Props {
  badgeGroup: IBadgeGroupRequestDto;
  loadingBadgeGroup?: boolean;
  listEvents?: Array<EventResponseDto>;
}

export const BadgeListCollapse: React.FC<Props> = ({
  badgeGroup,
  loadingBadgeGroup = false,
  listEvents = [],
}) => {
  const dispatch = useDispatch();
  const { badgeEditingId, badgeExpandedKey } = useSelector(
    (state: RootState) => state.gamification
  );

  const onBadgeDeleteClick = (indexClick: number) => {
    dispatch(slices.gamefication.badgeSlice.deleteBadgeGroup(indexClick));
    dispatch(
      slices.gamefication.badgeSlice.getFullBadgeGroupsById(badgeGroup?.id)
    );
  };

  const createBadgeCollapse = () => {
    dispatch(slices.gamefication.addNewBadgeinTheBadgeGroup());
  };

  const badgeHeader = (titulo: string, imagePath: string) => {
    const isDefaultImage = !(imagePath && imagePath !== '');
    return (
      <div>
        {isDefaultImage ? (
          <Avatar
            size={32}
            icon={<StarOutlined />}
            style={{ marginRight: 10 }}
          />
        ) : (
          <Avatar size={32} src={imagePath} style={{ marginRight: 10 }} />
        )}
        {titulo}
      </div>
    );
  };

  const handleChange = (key: string | string[]) => {
    dispatch(slices.gamefication.setBadgeExpandedKey(key as string));
  };

  return (
    <>
      <Row justify='space-between'>
        <Col span={4}>
          <SectionTitle>Badges</SectionTitle>
        </Col>
        <Col span={4}>
          <Button
            disabled={loadingBadgeGroup || !!badgeEditingId || !badgeGroup?.id}
            icon={<PlusCircleOutlined />}
            type='primary'
            style={{ float: 'right' }}
            onClick={createBadgeCollapse}
          >
            Cadastrar nova badge
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse
            accordion
            expandIconPosition='right'
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 270 : 90} />
            )}
            collapsible={badgeEditingId ? 'disabled' : undefined}
            onChange={handleChange}
            activeKey={badgeExpandedKey}
          >
            {badgeGroup?.badges
              ?.filter(badge => badge.activated)
              .map((badge, index) => (
                <Panel
                  header={badgeHeader(badge.name, badge.imagePath)}
                  key={`Collapse-${badge.id}`}
                >
                  <BadgeComponent
                    listEvents={listEvents}
                    badge={badge}
                    onBadgeDeleteClick={onBadgeDeleteClick}
                    index={index}
                  />
                </Panel>
              ))}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};
