import {
  Form,
  Image,
  Row,
  TooltipProps,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import React, { ReactNode, useState } from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { extractFileNameFromS3Link } from 'src/app/utils/extractFileNameFromS3Link';
import { toFieldStatus } from 'src/app/utils/toFieldStatus';
import { EmptyUploadImage } from 'src/features/Funnel/Components/EmptyUploadImage';
const { Item: FormItem } = Form;
interface FileImage {
  url: string;
  name: string;
}
interface UploadControllerProps extends UploadProps {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  required?: boolean;
  label?: string;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
}

export const UploadController: React.FC<UploadControllerProps> = ({
  control,
  name,
  defaultValue,
  required,
  label,
  children,
  tooltip,
  ...rest
}) => {
  const [fileImage, setFileImage] = useState<FileImage>({
    url: '',
    name: '',
  } as FileImage);

  const handleChangeFileImageWeb: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    getBase64(info.file.originFileObj as RcFile, url => {
      setFileImage({ url, name: info.file.name });
    });
  };

  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange: fieldOnChange, ...field },
        fieldState,
      }) => {
        const { status, help } = toFieldStatus(fieldState.error);
        return (
          <FormItem
            validateStatus={status}
            help={help}
            label={label}
            {...(tooltip && { tooltip: tooltip })}
            {...(required && { required: required })}
          >
            <Upload.Dragger
              {...rest}
              onChange={info => {
                handleChangeFileImageWeb(info);
                fieldOnChange(info);
              }}
              {...field}
            >
              {fileImage.url || field.value ? (
                <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Image
                    width={40}
                    height={40}
                    src={
                      typeof field.value === 'string'
                        ? field.value
                        : fileImage.url
                    }
                  />
                  <Typography.Text style={{ marginInlineStart: '16px' }}>
                    {typeof field.value === 'string'
                      ? extractFileNameFromS3Link(field.value)
                      : fileImage.name}
                  </Typography.Text>
                </Row>
              ) : (
                <EmptyUploadImage />
              )}
            </Upload.Dragger>
          </FormItem>
        );
      }}
    />
  );
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
