import { slices } from '@core/redux';
import { Segments } from '@models';
import { segments } from '@service/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';
import { CountResponse } from '../typings/CountResponse';
import { TakeSkipFilter } from '../typings/TakeSkipFilter';

export const useSegments = () => {
  const dispatch = useDispatch();
  const [segmentsWithCounts, setSegmentsWithCounts] =
    useState<CountResponse<Segments.SegmentsDto>>();

  const [segmentsList, setSegmentsList] =
    useState<CountResponse<Segments.SegmentsDto>>();

  const getSegmentsList = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getAllSegments(params);
      setSegmentsList(response);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getSegmentsWithCounts = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getSegmentsWithCounts(params);
      setSegmentsWithCounts(response);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getFilterSegmentList = async (filter: Segments.SegmentsFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getSegmentsWithCounts({ filter });
      setSegmentsWithCounts(response);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const postNewSegment = async (segment: Segments.ShortSegmentDto) => {
    try {
      dispatch(slices.layout.increaseLoading());
      await segments.postNewSegment(segment);
      OpenNotification(
        false,
        `O Segmento ${segment.name} foi cadastrado com sucesso`
      );
    } catch (error) {
      OpenNotification(false, `Erro ao cadastrar o segmento ${segment.name}`);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const saveOrder = async (segmentsNewOrder: Segments.SegmentsDto[]) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const newOrder = segmentsNewOrder.map((segment, index) => ({
        segmentId: Number(segment?.id || 0),
        order: index,
      }));
      await segments.putNewOrdering({ values: newOrder });
      await getSegmentsList({ take: 1000, skip: 0 });
      OpenNotification(false, 'Ordem salva com sucesso!');
    } catch (error) {
      OpenNotification(true, 'Erro ao salvar a ordem!');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return {
    segmentsList,
    getSegmentsList,
    segmentsWithCounts,
    getSegmentsWithCounts,
    getFilterSegmentList,
    postNewSegment,
    saveOrder,
  };
};
