import { base } from '@service/base';

type TAddOriginOfferResponse = {
  success: boolean;
};

export async function addOriginOfferOrderBump(
  orderBumpId: number,
  offerIds: number[]
) {
  const response = await base.patch<TAddOriginOfferResponse>(
    `/order-bump/offers`,
    {
      orderBumpId,
      offerIds,
    }
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
