import { useDebounce, useSegmentsRelation } from '@hooks';
import { Customer, Segments } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { CustomersTableList } from '@templates';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import * as S from './LinkCustomers.style';

export const LinkCustomers: React.FC = () => {
  const { state } = useLocation<{
    id: string;
  }>();

  const {
    userNotBySegment,
    getUsersNotBySegment,
    linkCustomers,
    linkedSegments,
    requestLinkCustomers,
  } = useSegmentsRelation(state?.id);

  const history = useHistory();

  const [filter, setFilter] = useState<string>('');
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(1);

  const debounceValue = useDebounce(filter, 900);

  useEffect(() => {
    getUsersNotBySegment({ filter: { name: { iLike: `%${debounceValue}%` } } });
    setPaginationCurrentPage(1);
  }, [debounceValue]);

  useBreadcrumbs([
    {
      label: 'Vínculo de usuários',
    },
  ]);

  useEffect(() => {
    if (!state?.id) {
      history.push('/screens/segments/manage');
    }

    getUsersNotBySegment();
  }, []);

  const onSelectRow = (selectedListRow: Customer.CustomerResponse[]) => {
    linkCustomers(selectedListRow);
  };

  const handleSaveLink = async () => {
    await requestLinkCustomers();
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.CUSTOMERS,
    });
  };

  const handleCancelLink = () => {
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.CUSTOMERS,
    });
    OpenNotification(false, 'Nenhum usuário foi vinculado.', '', 'warning');
  };

  return (
    <LayoutConfirmationFooter
      onSave={handleSaveLink}
      onCancel={handleCancelLink}
      ableToSave={!!linkedSegments?.customers.length}
    >
      <PageHeader
        backToPath={handleCancelLink}
        title='Vincular usuários'
        subTitle='Vincule os usuários que desejar ao Segmento que está adicionando.'
      />

      <S.FilterConatiner>
        <Input.Search
          onChange={evt => setFilter(evt.target.value)}
          placeholder='Busque pelo usuário desejado.'
          allowClear
        />
      </S.FilterConatiner>

      <CustomersTableList
        countLabel='Usuários cadastrados'
        value={userNotBySegment}
        onSelectRow={onSelectRow}
        rowsSelected={linkedSegments?.customers}
        paginationCurrentPage={paginationCurrentPage}
        onChangePagination={(take, skip, pagination) => {
          setPaginationCurrentPage(pagination.current ?? 1);
          getUsersNotBySegment({
            filter: filter
              ? { name: { iLike: `%${debounceValue}%` } }
              : undefined,
            take,
            skip,
          });
        }}
      />
    </LayoutConfirmationFooter>
  );
};
