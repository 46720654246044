import { Segments } from '@models';
import { createSlice } from '@reduxjs/toolkit';

import { getAllSegmentsBuilder } from './getAllSegments.builder';
import { getBannersBuilder } from './getBannerSegments.builder';
import { getSegmentsWithCountBuilder } from './getSegmentsWithCount.builder';

interface SegmentsState {
  status: string;
  fetching: boolean;
  fullSegmentsList: boolean;
  segments: Segments.SegmentsDto[];
  segmentsDetail?: Segments.ShortSegmentDto;
  segmentsWithCounts: Segments.SegmentsWithCounts[];
  segmentsDictionary: Record<string, string>;
  errorDetails?: string;
}

const initialState: SegmentsState = {
  status: 'RESTORING',
  fetching: false,
  fullSegmentsList: false,
  segments: [] as Segments.SegmentsDto[],
  segmentsWithCounts: [] as Segments.SegmentsWithCounts[],
  segmentsDetail: undefined,
  segmentsDictionary: {} as Record<string, string>,
  errorDetails: undefined,
};

const segmentsSlice = createSlice({
  initialState,
  name: 'Assets',
  reducers: {
    clearSegmentDetail: state => {
      state.segmentsDetail = undefined;
    },
    newSegment: state => {
      state.segmentsDetail = {
        name: '',
        description: '',
        type: 'CUSTOMER' as Segments.TypeEnum,
        activated: false,
      };
    },
  },
  extraReducers(builder) {
    getBannersBuilder(builder);
    getAllSegmentsBuilder(builder);
    getSegmentsWithCountBuilder(builder);
  },
});

export const { newSegment, clearSegmentDetail } = segmentsSlice.actions;
export const reducer = segmentsSlice.reducer;
