import { usePrograms } from '@hooks';
import { SelectController } from '@organisms';
import React, { useEffect } from 'react';

interface ProgramSelectProps {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string[] | number[];
}
export const ProgramsSelect: React.FC<ProgramSelectProps> = ({
  control,
  name,
  label,
  placeholder,
  defaultValue,
}) => {
  const { getProgramsList, programsOptions } = usePrograms();

  useEffect(() => {
    getProgramsList({ take: 9999 });
  }, []);

  return (
    <SelectController
      control={control}
      name={name}
      options={programsOptions}
      label={label}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};
