import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { lgpdApi } from '../apis/lgpdApis';
import { RequestAnonymizeDTO } from '../interfaces/lgpdInterfaces';

export default function useControllerTable(showModal: boolean) {
  const [tableData, setTableData] = useState<RequestAnonymizeDTO[]>();
  const [tableFilter, setTableFilter] = useState<RequestAnonymizeDTO[]>();
  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    getConsults();
    setTableFilter(undefined);
    defineTableExibition();
  }, [showModal]);

  useEffect(() => {
    setTableFilter(filterTable(filter ?? '', tableData));
  }, [filter]);

  async function getConsults() {
    await lgpdApi.getAllUserConsulted().then(data => {
      setTableData(sortTable(data));
    });
  }

  function sortTable(data: RequestAnonymizeDTO[]) {
    return data?.sort(function (a, b) {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    });
  }

  function filterTable(filter: string, data?: RequestAnonymizeDTO[]) {
    return data?.filter(function (usuario) {
      return (
        usuario.customerName.toLowerCase().includes(filter.toLowerCase()) ||
        usuario.customerEmail.toString().includes(filter) ||
        (usuario?.customerDocument && usuario.customerDocument.includes(filter))
      );
    });
  }

  function defineTableExibition() {
    return tableFilter ? tableFilter : tableData;
  }
  return [defineTableExibition(), filter, setFilter] as [
    RequestAnonymizeDTO[] | undefined,
    string,
    Dispatch<SetStateAction<string | undefined>>
  ];
}
