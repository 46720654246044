import { TUpsellStepsFlowManage } from '@hooks';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import { Container } from './styles';

type TableFlowStepsProps = {
  flowSteps: TUpsellStepsFlowManage[];
};

const columnsInfo: ColumnsType<TUpsellStepsFlowManage> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '60px',
    render: (id: TUpsellStepsFlowManage['id']) => {
      return String(id);
    },
  },
  {
    title: 'Ordem',
    dataIndex: 'order',
    key: 'order',
    width: '60px',
    render: (order: TUpsellStepsFlowManage['order']) => {
      return String(order);
    },
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    width: '300px',
    render: (name: TUpsellStepsFlowManage['name']) => {
      return name;
    },
  },
  {
    title: 'Fluxo UP',
    dataIndex: 'up',
    key: 'up',
    width: '150px',
    render: (upFlow: TUpsellStepsFlowManage['up']) => {
      return upFlow || '---';
    },
  },
  {
    title: 'Fluxo DOWN',
    dataIndex: 'down',
    key: 'down',
    width: '150px',
    render: (downFlow: TUpsellStepsFlowManage['down']) => {
      return downFlow || '---';
    },
  },
  {
    title: 'Link ID',
    dataIndex: 'linkId',
    key: 'linkId',
    width: '100px',
    render: (linkId: TUpsellStepsFlowManage['linkId']) => {
      return linkId || '---';
    },
  },
];

export const TableFlowSteps: React.FC<TableFlowStepsProps> = ({
  flowSteps,
}) => {
  return (
    <Container>
      <Table dataSource={flowSteps} columns={columnsInfo} />
    </Container>
  );
};
