import {
  DeleteOutlined,
  EllipsisOutlined,
  InboxOutlined,
  PlusCircleOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Upload,
  UploadProps,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'src/app/components/PageHeader';

import { ICompetition } from '../../dto/competition.interface';
import { ManageCompetitionsResponseDto } from '../../dto/manage-competitions.response.dto';
import { StyledTable } from '../../styles';

// interface IFormInterface {
//   initialValues?: ManageCompetitionsResponseDto;
//   enableTabs?: (v: boolean) => void;
// }

const { TextArea } = Input;
const { Dragger } = Upload;

const GameficationCompetitionFormStep5: FC = () => {
  const [form] = Form.useForm();
  const URL_PARAMS: ICompetition = useParams();
  const isEdit = Boolean(Object.keys(URL_PARAMS)?.length);
  const [showModal, setModal] = useState<boolean>(false);

  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line
  const onFinish = async (j: any) => {
    // const re = RegExp(`.*${j.search.toLowerCase().split('').join('.*')}.*`);
    // const matches = data.filter(v => v.customer.toLowerCase().match(re));
    // setData(j.search !== '' ? matches : _genData());
    console.info('@@@@', j);
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
  };

  const RENDER_CTA = () => (
    <>
      <Button
        onClick={() => setModal(true)}
        data-testid='add'
        size='large'
        icon={<PlusCircleOutlined />}
        type='link'
      >
        Adicionar
      </Button>

      <Button
        onClick={() => console.info('yay', selectedRowKeys)}
        data-testid='select-all'
        size='large'
        icon={<SelectOutlined />}
        type='link'
      >
        Selecionar todos
      </Button>

      <Button
        onClick={() => console.info('yay', selectedRowKeys)}
        disabled={!selectedRowKeys?.length}
        data-testid='delete'
        size='large'
        icon={<DeleteOutlined />}
        type='link'
        danger
      >
        Remover
      </Button>
    </>
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const ACTIONS = [
    {
      key: 'edit',
      label: 'Visualizar / Editar',
      // onClick: (j: any) => console.info(j),
      // history.push(`competition/${j.id}`),
    },
    {
      label: 'Remover',
      key: 'remove',
      // onClick: (j: any) => console.info('modal remoção', j),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    { title: 'Nome', dataIndex: 'name', width: 600 },
    { title: 'Pontos', dataIndex: 'points', width: 100 },
    { title: 'Quantidade', dataIndex: 'amount', width: 100 },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      width: 100,
      render: (x: Date) => x.toLocaleDateString('pt-br'),
    },
    {
      title: 'Ações',
      key: 'action',
      width: 20,
      render: (_item: ManageCompetitionsResponseDto) => (
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          overlay={() => (
            <Menu>
              {ACTIONS.map(({ label, key }) => (
                <Menu.Item
                  key={key}
                  // onClick={() => onClick(_item)}
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu>
          )}
        >
          <Button type='text'>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const _genData = () => {
    const dataX = [];
    for (let i = 0; i < 46; i++) {
      dataX.push({
        key: i,
        points: `${i * 4} pts`,
        amount: i % 4 ? i * i + 2 : i * 3,
        name:
          i % 3
            ? `${i * i + 3} meses de Queima Diaria grátis`
            : 'Brindes Queima Diária',
        createdAt: new Date(),
      });
    }

    return dataX;
  };

  // const data: DataType[] = [];
  useEffect(() => {
    setData(_genData());
  }, []);

  const PROPS = {
    dataSource: data,
    rowSelection,
    columns,
    bordered: true,
    // rowKey: 'id',
  };

  const DATE_PROPS = {
    format: 'DD/MM/YYYY',
    style: { width: '100%' },
  };

  const UPLOAD_PROPS: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Prêmios' />
        </Col>

        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          {RENDER_CTA()}
        </Col>
      </Row>

      <Form size='large' layout='vertical' {...FORM_PROPS}>
        <Row style={{ marginBottom: 20 }} gutter={30}>
          <Col span={8}>
            <Form.Item label='Pesquisar' name='search'>
              <Input.Search
                placeholder='Filtrar por...'
                style={{ width: '100%' }}
                onSearch={form.submit}
                size='large'
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name='points_from' label='Pontuação inicial'>
              <Input type='number' data-testid='points_from' />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name='points_to' label='Pontuação final'>
              <Input type='number' data-testid='points_to' />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label='Vigência' name='from'>
              <DatePicker {...DATE_PROPS} data-testid='from' />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label='Até' name='to'>
              <DatePicker {...DATE_PROPS} data-testid='to' />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StyledTable {...PROPS} pagination={{ position: ['bottomCenter'] }} />

      <Modal
        title={isEdit ? 'Editar Prêmio' : 'Adicionar Prêmio'}
        visible={showModal}
        onOk={() => form.submit()}
        onCancel={() => {
          setModal(false);
          form.resetFields();
        }}
        cancelText='Cancelar'
        okText='Salvar'
        width={650}
      >
        <Form {...FORM_PROPS} size='large' layout='vertical' hideRequiredMark>
          <Form.Item
            name='name'
            label='Nome do prêmio'
            rules={[{ required: true }]}
          >
            <Input
              allowClear
              placeholder='Insira o nome do prêmio que deseja cadastrar'
            />
          </Form.Item>

          <Form.Item
            name='description'
            label='Descrição'
            rules={[{ required: true }]}
          >
            <TextArea
              showCount
              maxLength={140}
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder='Insira uma descrição'
            />
          </Form.Item>

          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Form.Item
                name='points'
                label='Pontos'
                rules={[{ required: true }]}
              >
                <InputNumber data-testid='points' style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name='limit'
                label='Quantidade disponível'
                rules={[{ required: true }]}
              >
                <InputNumber data-testid='limit' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name='image_path'
                label='Imagem'
                rules={[{ required: true }]}
              >
                <Dragger {...UPLOAD_PROPS}>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Clique aqui ou arraste para fazer upload
                  </p>
                  <p className='ant-upload-hint'>
                    Selecione a imagem que deseja representar o cadastro do
                    premio
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default GameficationCompetitionFormStep5;
