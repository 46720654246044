import { DataResponseApiDto } from '@core/dto/data-response-api.dto';
import { enviroments } from '@infrastructure/enviroments';
import { Events } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.GAMEFICATION_URL;

export async function updateEvent(
  params: Events.EventForm
): Promise<DataResponseApiDto<Events.EventCreated>> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/event/${params.identifier}`,
    method: 'PUT',
    data: {
      name: params.name,
      description: params.description,
      eventTypeId: params.eventTypeId,
      eventsContents: params.eventsContents,
      statusActivated: !params.statusActivated ? false : params.statusActivated,
      languages: params.languages,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
