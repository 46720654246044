import { Form, Input, Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout)`
  background-color: transparent;
  margin-top: 24px;
`;

export const FormOrderBump = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputFormOrderBump = styled(Input)`
  background: rgba(20, 20, 20, 1);
  border: 1px solid rgba(66, 66, 66, 1);
  border-radius: 6px;
`;

export const StatusDisplay = styled.label``;

export const StatusContainer = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100px;
`;

export const ErrorText = styled.p`
  color: #e84749;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;
