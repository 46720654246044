import { SearchOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, Select } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { TypesSearchEnum } from '../constants/search';

const { Search } = Input;

type InputSearchProps = {
  onSearch: (data: InputSearchData) => void;
};

export interface InputSearchData {
  search: string;
  type: string;
}

const schema = yup.object().shape({
  search: yup
    .string()
    .required('Campo obrigatório')
    .test('valid', 'Formato inválido', (value, context) => {
      const type = context.parent.type as TypesSearchEnum;
      if (type === TypesSearchEnum.EMAIL) {
        return yup.string().email().isValidSync(value);
      } else if (type === TypesSearchEnum.ID) {
        return yup
          .string()
          .matches(/^\d+$/, 'ID deve ser numérico')
          .isValidSync(value);
      }
      return false;
    }),
  type: yup.string().oneOf(Object.values(TypesSearchEnum)).required(),
});

export const InputSearch = ({ onSearch }: InputSearchProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<InputSearchData>({
    resolver: yupResolver(schema),
    defaultValues: {
      search: '',
      type: 'email',
    },
  });

  const [searchType, setSearchType] = useState('email');

  const onSubmit = useCallback(
    (data: InputSearchData) => {
      onSearch(data);
    },
    [onSearch]
  );

  const getPlaceholder = useMemo(() => {
    switch (searchType) {
      case 'id':
        return 'Insira o ID do cliente';
      case 'email':
      default:
        return 'Insira o e-mail do cliente';
    }
  }, [searchType]);

  return (
    <FormContainer>
      <Form
        layout='inline'
        style={{ gap: '16px', rowGap: '8px', width: '100%' }}
      >
        <CustomLabel>Fazer nova busca:</CustomLabel>
        <Form.Item style={{ margin: 0 }}>
          <Select
            defaultValue={TypesSearchEnum.EMAIL}
            style={{ width: 120 }}
            onChange={value => {
              setSearchType(value);
              setValue('type', value);
            }}
          >
            <Select.Option value={TypesSearchEnum.EMAIL}>Email</Select.Option>
            <Select.Option value={TypesSearchEnum.ID}>ID</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ margin: 0, flex: 1 }}>
          <Controller
            name='search'
            control={control}
            render={({ field }) => (
              <Search
                placeholder={getPlaceholder}
                enterButton={<SearchOutlined />}
                {...field}
                onSearch={() => {
                  handleSubmit(onSubmit)();
                }}
                style={{ width: '100%' }}
              />
            )}
          />
          {errors.search && (
            <p style={{ color: 'red' }}>{errors.search.message}</p>
          )}
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 560px;
  align-items: end;
  justify-content: end;
`;

const CustomLabel = styled.label`
  width: 100%;
`;
