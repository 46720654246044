import { Modal } from '@models';
import { useCallback } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { DefaultContent } from './components/DefaultContent.component';
import { HighlightContent } from './components/HighlightContent.component';
import { ModelStepComponent } from './components/ModelStep.component';
import { PaginationContent } from './components/PaginationContent.component';
import { ScheduleComponent } from './components/Schedule.component';
import { ModalModelProperties, ModalScheduleProperties } from './ModalDetail';

export const ModalDetailHandle = ({
  scheduleForm,
  modelModalForm,
  defaultModalForm,
  highLightModalForm,
  paginationForm,

  currentStep,
}: {
  scheduleForm: UseFormReturn<ModalScheduleProperties>;
  modelModalForm: UseFormReturn<ModalModelProperties>;
  paginationForm: UseFormReturn<Modal.IPaginationContent>;
  defaultModalForm: UseFormReturn<Modal.IDefaultContent>;
  highLightModalForm: UseFormReturn<Modal.IDefaultContent>;
  currentStep: number;
}) => {
  const getComponent = useCallback((step: number) => {
    switch (step) {
      case 0:
        return <ModelStepComponent form={modelModalForm} />;

      case 1:
        if (modelModalForm.getValues('modalType') === Modal.TypeEnum.DEFAULT) {
          return <DefaultContent form={defaultModalForm} />;
        }
        if (
          modelModalForm.getValues('modalType') === Modal.TypeEnum.HIGHLIGHT
        ) {
          return <HighlightContent form={highLightModalForm} />;
        }
        if (
          modelModalForm.getValues('modalType') === Modal.TypeEnum.PAGINATION
        ) {
          return <PaginationContent form={paginationForm} />;
        }
        return null;

      case 2:
        return <ScheduleComponent form={scheduleForm} />;

      default:
        return null;
    }
  }, []);
  return getComponent(currentStep);
};
