import { Assets } from '@models';
import { ActiveTag, InctiveTag, TableList } from '@molecules';
import { CountResponse } from '@typings';
import { TablePaginationConfig } from 'antd';

import { getAllAssetDevices } from 'src/app/utils/getAllAssetDevices';
import { getAssetType } from 'src/app/utils/getAssetType';

const getAmountLabel = (count?: number) => {
  if (!count) return '';

  return `${count} Asset${count > 1 ? 's' : ''} cadastrado${
    count > 1 ? 's' : ''
  }`;
};

interface AssetsTabProps {
  value?: CountResponse<Assets.IAsset>;
  onSelectRow: (selectedListRow: Assets.IAsset[]) => void;
  onChangePagination?: (
    take: number,
    skip: number,
    pagination: TablePaginationConfig
  ) => void;
  rowsSelected?: Assets.IAsset[];
  countLabel?: string;
  paginationCurrentPage?: number;
}
export const AssetsTableList = ({
  value,
  onSelectRow,
  rowsSelected,
  onChangePagination,
  paginationCurrentPage,
}: AssetsTabProps) => (
  <TableList<Assets.IAsset>
    onChangePagination={onChangePagination}
    countLabel={!!value?.count ? getAmountLabel(value.count) : ''}
    emptyText={'Nenhum asset vinculado.'}
    data={value}
    onChangeSelected={onSelectRow}
    rowsSelected={rowsSelected}
    paginationCurrentPage={paginationCurrentPage}
    columns={[
      { title: 'Nome do asset', dataIndex: 'name', key: 'name' },
      {
        title: 'Tipo',
        width: '240px',
        dataIndex: 'contexts',
        key: 'contexts',
        render: (bannerContexts: Assets.IAssetContext[]) => {
          return getAssetType(bannerContexts);
        },
      },
      {
        title: 'Device',
        width: '200px',
        dataIndex: 'banner',
        key: 'banner',
        render: (banner: Assets.IBannerDetail) => {
          return <div>{getAllAssetDevices(banner)}</div>;
        },
      },
      {
        title: 'Status',
        width: '140px',
        dataIndex: 'activated',
        key: 'activated',
        render: (status: boolean) => (status ? <ActiveTag /> : <InctiveTag />),
      },
    ]}
  />
);
