import { UpsellLinks } from '@models';
import { base } from '@service/base';

type PayloadUpdate = {
  id: UpsellLinks.IUpsellLinks['id'];
  urlName: UpsellLinks.IUpsellLinks['urlName'];
  url: UpsellLinks.IUpsellLinks['url'];
  description: UpsellLinks.IUpsellLinks['description'];
} & (
  | {
      offerId: UpsellLinks.IUpsellLinks['offerId'];
    }
  | {
      productSellGroupId: UpsellLinks.IUpsellLinks['productSellGroupId'];
    }
);

export async function updateUpsellLink({ id, ...data }: PayloadUpdate) {
  const response = await base.put<UpsellLinks.IUpsellLinks>(
    `/funnel/link/${id}`,
    data
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
