export const WatchSvg = () => (
  <svg
    width='48'
    height='49'
    viewBox='0 0 48 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.3 44.5L15.95 35.95C14.1167 34.7833 12.6667 33.2 11.6 31.2C10.5333 29.2 10 26.9667 10 24.5C10 22.0333 10.5333 19.7917 11.6 17.775C12.6667 15.7583 14.1167 14.1833 15.95 13.05L18.3 4.5H29.7L32.05 13.05C33.8833 14.1833 35.3333 15.7583 36.4 17.775C37.4667 19.7917 38 22.0333 38 24.5C38 26.9667 37.4667 29.2 36.4 31.2C35.3333 33.2 33.8833 34.7833 32.05 35.95L29.7 44.5H18.3ZM24 35.5C27.0667 35.5 29.6667 34.4333 31.8 32.3C33.9333 30.1667 35 27.5667 35 24.5C35 21.4333 33.9333 18.8333 31.8 16.7C29.6667 14.5667 27.0667 13.5 24 13.5C20.9333 13.5 18.3333 14.5667 16.2 16.7C14.0667 18.8333 13 21.4333 13 24.5C13 27.5667 14.0667 30.1667 16.2 32.3C18.3333 34.4333 20.9333 35.5 24 35.5ZM19.65 11.3C20.4167 11.0667 21.1583 10.8917 21.875 10.775C22.5917 10.6583 23.3 10.6 24 10.6C24.7 10.6 25.4083 10.6583 26.125 10.775C26.8417 10.8917 27.5833 11.0667 28.35 11.3L27.45 7.5H20.55L19.65 11.3ZM20.55 41.5H27.45L28.35 37.7C27.5833 37.9 26.8417 38.0583 26.125 38.175C25.4083 38.2917 24.7 38.35 24 38.35C23.3 38.35 22.5917 38.2917 21.875 38.175C21.1583 38.0583 20.4167 37.9 19.65 37.7L20.55 41.5ZM20.55 41.5H19.65H28.35H20.55Z'
      fill='white'
    />
  </svg>
);
