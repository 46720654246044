import { guestApi } from '../../../app/guestApi';
import { Success } from '../../../app/utils/statusCode';
import { DataResponseApiDto } from '../../../core/dto/data-response-api.dto';
import { ResponseClient } from '../../../features/clients/types/clientTypes';
import { IClientPayload } from './dto/client-api.dto';
import { ScheduledTaskResponseDto } from './dto/scheduledTaskResponse.dto';
import { _checkForInvalidDateRange, _mountURI, _notifyError } from './utils';

const scheduledCancellationReport = async (
  args: IClientPayload
): Promise<DataResponseApiDto<ScheduledTaskResponseDto[]>> => {
  await _checkForInvalidDateRange(args);
  const response = await guestApi.get(_mountURI(args));

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

export const subscriptionApi = {
  scheduledCancellationReport,
};
