import { CheckboxOrderBump } from '../../CheckboxOrderBump/CheckboxOrderBump';
import { TOrderBumpCheckoutProps } from '..';
import {
  CheckContent,
  Container,
  DescriptionContent,
  Image,
  ResponsiveContainer,
} from './styles';

export function TemplateA({
  orderBumpData,
  checked,
  onCheckChange,
  sizeMode,
}: TOrderBumpCheckoutProps) {
  const {
    title,
    webLink,
    mobileLink,
    description,
    confirmationButtonText,
    backgroundColor,
    textColor,
    primaryColor,
    secondaryColor,
    isActive,
  } = orderBumpData;

  const isMobile = sizeMode === 'small';

  if (!isActive) return null;

  return (
    <Container
      style={{ backgroundColor, color: textColor, borderColor: primaryColor }}
    >
      <p>{title}</p>
      <ResponsiveContainer
        style={{ flexDirection: isMobile ? 'column' : 'row' }}
      >
        {isMobile
          ? !!mobileLink && <Image src={mobileLink} isSmall={isMobile} />
          : !!webLink && <Image src={webLink} isSmall={isMobile} />}
        <DescriptionContent dangerouslySetInnerHTML={{ __html: description }} />
      </ResponsiveContainer>
      <CheckContent
        style={{ backgroundColor: secondaryColor, borderColor: primaryColor }}
      >
        <CheckboxOrderBump
          labelText={confirmationButtonText}
          onCheckedChange={onCheckChange || console.log}
          boxColor={primaryColor}
          iconColor={backgroundColor}
          value={!!checked}
        />
      </CheckContent>
    </Container>
  );
}
