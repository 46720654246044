import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';
import { TakeSkipFilter } from '@typings';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function getAllActiveUsers({
  skip,
  take,
  filter,
}: TakeSkipFilter): Promise<any> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/user/filtered`,
    method: 'GET',
    params: {
      where: JSON.stringify({
        status: true,
        ...where,
      }),
      skip,
      take,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
