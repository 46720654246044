import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { PropsWithChildren } from 'react';

import * as S from './LayoutConfirmationFooter.style';

interface LayoutConfirmationFooterProps {
  onCancel: () => void;
  onSave: () => void;
  isLoadingSave?: boolean;
  ableToSave?: boolean;
  continueLabelButton?: string;
  cancelLabelButton?: string;
  ableToSubmitDisabled?: boolean;
}
export const LayoutConfirmationFooter: React.FC<
  PropsWithChildren<LayoutConfirmationFooterProps>
> = ({
  onCancel,
  onSave,
  ableToSave,
  continueLabelButton,
  cancelLabelButton,
  ableToSubmitDisabled,
  children,
  isLoadingSave,
}) => {
  const disableStyleButton = {
    color: 'rgba(255, 255, 255, 0.3)',
    borderColor: '#434343',
    background: 'rgba(255, 255, 255, 0.08)',
    textShadow: 'none',
    boxShadow: 'none',
  };

  return (
    <S.Container>
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
      <S.PaddingOverride>
        <S.ButtonGroupContainer>
          <Button onClick={onCancel}>{cancelLabelButton || 'Cancelar'}</Button>
          <Button
            disabled={ableToSubmitDisabled ? false : !ableToSave}
            style={
              ableToSubmitDisabled && !ableToSave ? disableStyleButton : {}
            }
            type='primary'
            icon={!continueLabelButton ? <CheckCircleOutlined /> : null}
            onClick={onSave}
            loading={isLoadingSave}
          >
            {continueLabelButton || 'Salvar'}
          </Button>
        </S.ButtonGroupContainer>
      </S.PaddingOverride>
    </S.Container>
  );
};
