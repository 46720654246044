import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import { EventsState } from './events.interface';
import * as eventsThunk from './events.thunk';

export const getAllEventsTypeBuilder = (
  builder: ActionReducerMapBuilder<EventsState>
) => {
  builder.addCase(eventsThunk.getEventTypeList.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    eventsThunk.getEventTypeList.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }

      state.eventTypeList = payload.response;
      state.status = 'NORMAL';
    }
  );

  builder.addCase(
    eventsThunk.getEventTypeList.rejected,
    (state, { payload }: any) => {
      console.log('ERROR EVENT THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
