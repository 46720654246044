import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { OfferFormData } from '../../types/offerPageTypings';
import { offerPayloadFactory } from '../../utils/offerFunnelsPagesFactory';

interface UpdateOfferFunnelPageRequest {
  id: string;
  payload: OfferFormData;
}

export function useTryToRequestUpdateOfferPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async ({ id, payload }: UpdateOfferFunnelPageRequest) => {
      const factory = await offerPayloadFactory(payload);

      return await apiCancellationFunnels.tryToRequestUpdateCancellationFunnelPage(
        id,
        factory
      );
    },
    {
      onSuccess: response => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao editar o funil de Oferta',
          'Ocorreu um erro ao editar o funil de Oferta, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestUpdateOfferPage: mutateAsync,
    isLoadingUpdateOffer: isLoading,
  };
}
