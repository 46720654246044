export const downloadCsvFile = (csv: string, filename?: string): void => {
  filename = filename ?? new Date().toISOString().toString();

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const href = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = href;
  a.download = `${filename}.csv`;
  a.style.display = 'none';

  a.click();
  URL.revokeObjectURL(a.href);
};
