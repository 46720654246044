import OpenNotification from 'src/app/components/antd/OpenNotification';
import { gameficationApi } from 'src/app/gameficationApi';
import { _notifyError } from 'src/features/subscriptions/scheduled-reports/utils';

import { Success } from '../../../app/utils/statusCode';
import { DataResponseApiDto } from '../../../core/dto/data-response-api.dto';
import { ResponseClient } from '../../../features/clients/types/clientTypes';
import { IBadges } from './dto/badges.interface';
import { ICompetition } from './dto/competition.interface';
import { ICompetitionType } from './dto/competition-type.interface';
import { IFilters } from './dto/filters.interface';
import { ManageCompetitionsResponseDto } from './dto/manage-competitions.response.dto';

export const _mountURI = ({ from, to, search }: IFilters): string => {
  let URI = 'competition/manage-competitions';

  if (from) {
    URI += `&firstDate=${from}`;
  }

  if (to) {
    URI += `&lastDate=${to}`;
  }

  if (search) {
    URI += `&search=${search}`;
  }

  return URI.replace('&', '?');
};

const manageCompetitions = async (
  args: IFilters
): Promise<DataResponseApiDto<ManageCompetitionsResponseDto[]>> => {
  const response = await gameficationApi.get(_mountURI(args));

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

const getCompetition = async ({
  id,
}: ICompetition): Promise<
  DataResponseApiDto<ManageCompetitionsResponseDto>
> => {
  const response = await gameficationApi.get(
    `competition/manage-competition/${id}`
  );

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

const getCompetitionType = async (): Promise<
  DataResponseApiDto<ICompetitionType[]>
> => {
  const response = await gameficationApi.get('competition/type');

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

const getSegment = async (): Promise<ICompetitionType[]> => {
  const response = await gameficationApi.get('segment');

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

const updateCompetition = async (
  id: number,
  payload: ManageCompetitionsResponseDto
): Promise<DataResponseApiDto<ManageCompetitionsResponseDto>> => {
  const response = await gameficationApi.put(`competition/${id}`, payload);

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  OpenNotification(false, 'Competição atualizada!', '');
  return response.data;
};

const createCompetition = async (
  payload: ManageCompetitionsResponseDto
): Promise<DataResponseApiDto<ManageCompetitionsResponseDto>> => {
  const response = await gameficationApi.post(`competition`, payload);

  if ((response as ResponseClient).status !== 201) {
    _notifyError('Ops, algo deu errado...');
  }

  OpenNotification(false, 'Competição adicionada!', '');
  return response.data;
};

const getBadges = async (): Promise<DataResponseApiDto<IBadges[]>> => {
  const response = await gameficationApi.get('badge');

  if ((response as ResponseClient).status !== Success) {
    _notifyError('Ops, algo deu errado...');
  }

  return response.data;
};

export const gameficationCompetition = {
  manageCompetitions,
  getCompetition,
  getCompetitionType,
  updateCompetition,
  createCompetition,
  getSegment,
  getBadges,
};
