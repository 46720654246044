import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { ReleaseFormData } from '../../types/releasePageTypings';
import { releasePayloadFactory } from '../../utils/releaseFunnelsPageFactory';

export function useTryToRequestCreatingReleaseFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: ReleaseFormData) => {
      const factory = await releasePayloadFactory(payload);
      const response = await apiCancellationFunnels.tryToRequestCreatingRelease(
        factory
      );
      return response;
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao crir o funil de Lançamento',
          'Ocorreu um erro ao criar o funil de Lançamento, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestCreatingReleaseFunnelPage: mutateAsync,
    isLoadingCreatingRelease: isLoading,
  };
}
