import { Tabs } from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { GeneralError } from 'src/app/components/Error';
import { PageHeader } from 'src/app/components/PageHeader';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { gameficationCompetition } from '../client.api';
import { ManageCompetitionsFilter } from '../components/Filter';
import { GameficationManageCompetitionTable } from '../components/Table';
import { IFilters } from '../dto/filters.interface';

const { TabPane } = Tabs;

const GameficationManageCompetitionsList: FC = () => {
  const TITLE = 'Gerenciar Competições';
  const DEFAULTS = { from: undefined, to: undefined, search: undefined };

  useBreadcrumbs([{ label: TITLE }]);

  const [filters, setFilters] = useState<IFilters>(DEFAULTS);

  const _callApi = async () => {
    const client = gameficationCompetition.manageCompetitions;
    const response = await client(filters);
    return response?.data;
  };

  const OPTS = { refetchOnWindowFocus: false };
  const QUERY = useQuery('gamefication_competitions', _callApi, OPTS);
  const { isFetching, data, error, refetch } = QUERY;

  const HEADER_PROPS = { refetch, enableRefetch: true, title: TITLE };
  const TABLE_PROPS = { dataSource: data, loading: isFetching };

  const REFETCH = (props: IFilters) => {
    setFilters(Object.assign(filters, props));
    refetch();
  };

  const RENDER_TABS = () => {
    const now = moment();

    return (
      <Tabs defaultActiveKey='2'>
        <TabPane tab='Todos' key='all'>
          <GameficationManageCompetitionTable {...TABLE_PROPS} />
        </TabPane>

        <TabPane tab='Encerrados' key='finished'>
          <GameficationManageCompetitionTable
            {...TABLE_PROPS}
            dataSource={data?.filter(k => moment(k.endDate).isBefore(now))}
          />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <>
      <PageHeader {...HEADER_PROPS} />
      <ManageCompetitionsFilter refetch={REFETCH} />
      {error ? <GeneralError action={refetch} /> : RENDER_TABS()}
    </>
  );
};

export default GameficationManageCompetitionsList;
