import { useCallback, useEffect } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { MediaFormData } from '../../types/mediaPageTypings';
import { useTryToRequestMediaPage } from './useTryToRequestMediaPage';
import { useTryToRequestMediaQuestionPage } from './useTryToRequestUpdateMediaPage';

interface UseRequestMediaPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<Omit<MediaFormData, 'name'>>;
  replace: UseFieldArrayReplace<Omit<MediaFormData, 'name'>, 'slides'>;
}

export function useRequestMediaPage({
  isUpdate,
  setIsUpdate,
  titlePage,
  setTitlePage,
  setValue,
  replace,
}: UseRequestMediaPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestMediaPage } = useTryToRequestMediaPage();
  const { tryToRequestUpdateMediaPage, isLoadingMediaUpdate } =
    useTryToRequestMediaQuestionPage();

  const handleUpdateMediaPage = useCallback(
    async (e: Omit<MediaFormData, 'name'>) => {
      await tryToRequestUpdateMediaPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [titlePage, tryToRequestUpdateMediaPage, pageId]
  );

  const handleRequestMediaPage = useCallback(async () => {
    await tryToRequestMediaPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      replace(response.slides);
      setTitlePage(response.name);
    });
  }, [setTitlePage, setValue]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestMediaPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { handleUpdateMediaPage, isLoadingMediaUpdate };
}
