import { base } from '@service/base';

import { CouponDigital } from 'src/models';

export type TCreateDigitalCouponProps = {
  code: string;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: CouponDigital.PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  destinationOfferId: number;
  maxUse: number;
  maxUsePerProduct: number;
  maxUsePerCustomer: number;
  discount: {
    type: string;
    value: number;
  };
  active: boolean;
};

export async function createDigitalCoupon(data: TCreateDigitalCouponProps) {
  const response = await base.post('/coupon', data);

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
