import { Checkbox, CheckboxProps, Form } from 'antd';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';
const { Item: FormItem } = Form;

interface UploadControllerProps extends CheckboxProps {
  control: Control<any>;
  name: string;
  defaultValue?: boolean;
  required?: boolean;
}

export const CheckboxController: React.FC<UploadControllerProps> = ({
  control,
  name,
  defaultValue,
  required,
  children,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={'checked'}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);
        return (
          <FormItem
            validateStatus={status}
            help={help}
            valuePropName={defaultValue ? 'checked' : 'unchecked'}
            {...(required && { required: required })}
          >
            <Checkbox {...rest} {...field} defaultChecked={defaultValue}>
              {children}
            </Checkbox>
          </FormItem>
        );
      }}
    />
  );
};
