import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Radio, RadioChangeEvent, Row, Tooltip } from 'antd';
import { useState } from 'react';
import { AnyObject } from 'yup/lib/object';

import { CompetitionEventModalAddResponseDto } from 'src/core/gamefication/dto/competition-event/competition-event-modal-add-response.dto';
import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import { OrSelectRegraCondicional } from './or-select-regra-condicional';
import { SelectRegraCondicional } from './select-regra-condicional';

interface Props {
  index?: number;
  loading?: boolean;
  rule?: AnyObject;
  events: Array<EventResponseDto>;
  dataSource?: CompetitionEventModalAddResponseDto;
  onRemoveClick?: (index?: number) => void;
  onChange?: (
    eventsSelected: Array<EventResponseDto>,
    index?: number,
    type?: number
  ) => void;
}

const initialState: AnyObject = {
  key: `${Date.now()}`,
  event: undefined,
};

export const CardRegraCondicional: React.FC<Props> = ({
  loading = false,
  rule,
  events = [],
  index = undefined,
  dataSource = undefined,
  onRemoveClick,
  onChange,
}) => {
  const [listExtraConditionals, setListExtraConditionals] = useState<
    Array<AnyObject>
  >(
    dataSource?.events && dataSource?.events.length > 1
      ? dataSource?.events
          .slice(1)
          .map(p => ({ event: p, key: `${Date.now()}` }))
      : [initialState]
  );

  const [regraCondicionalPrincipal, setRegraCondicionalPrincipal] = useState<
    AnyObject | undefined
  >(
    dataSource?.events && dataSource?.events.length
      ? dataSource?.events[0]
      : undefined
  );

  const [tipoSelecionado, setTipoSelecionado] = useState<number>(
    !dataSource || (!!dataSource && dataSource.tipo === 1) ? 1 : 2
  );

  const handleRemoveExtraCondicionalClick = (indexField?: number) => {
    listExtraConditionals.splice(indexField as number, 1);
    setListExtraConditionals([...listExtraConditionals]);
  };

  const handleAddExtraCondicionalClick = () => {
    const newListExtraConditionals = listExtraConditionals.concat({
      key: `${Date.now()}`,
      event: undefined,
    });
    setListExtraConditionals(newListExtraConditionals);
  };

  const onOrSelectRegraCondicionalChange = (
    indexField: number,
    value?: {
      value?: number;
      label: React.ReactNode;
    }
  ) => {
    if (!value) {
      return;
    }
    const valorEvento = events.find(p => p.id === value?.value);
    listExtraConditionals[indexField] = {
      key: `${Date.now()}`,
      event: valorEvento,
    };
    const lista = listExtraConditionals?.map((p: AnyObject) => p?.event);
    onChange?.(
      [regraCondicionalPrincipal as EventResponseDto, ...lista],
      index,
      tipoSelecionado
    );
  };

  const onSelectRegraCondicionalChange = (value?: {
    value?: number;
    label: React.ReactNode;
  }) => {
    if (!value) {
      return;
    }
    const valorEvento = events.find(p => p.id === value?.value);
    setRegraCondicionalPrincipal(valorEvento as EventResponseDto);
    const lista = listExtraConditionals
      ?.map((p: AnyObject) => p?.event)
      .filter(p => p !== undefined);
    onChange?.(
      [valorEvento as EventResponseDto, ...lista],
      index,
      tipoSelecionado
    );
  };

  const renderAditionalConditionRule = (): Array<JSX.Element> => {
    if (tipoSelecionado === 1) {
      return [];
    }
    return listExtraConditionals.map((p: AnyObject, indexField: number) => (
      <OrSelectRegraCondicional
        eventSelected={p?.event}
        events={p?.event ? [p?.event, ...events] : events}
        key={`extra-col-${indexField}`}
        isLast={indexField === listExtraConditionals.length - 1}
        onRemoveClick={handleRemoveExtraCondicionalClick}
        onAddClick={handleAddExtraCondicionalClick}
        index={indexField}
        onChange={onOrSelectRegraCondicionalChange}
      />
    ));
  };

  const handleRemoveClick = () => {
    onRemoveClick?.(index);
  };

  const tipoRegraOnChange = (e: RadioChangeEvent) => {
    setTipoSelecionado(e.target.value as number);
  };

  return (
    <Card
      style={{ width: '100%', marginBottom: 10 }}
      bodyStyle={{ backgroundColor: '#141414', padding: 10 }}
    >
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <Row>
            <Col span={20}>Regras</Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Tooltip placement='bottomRight' title='Remover'>
                <Button
                  type='link'
                  style={{ color: '#ACACAC', paddingRight: 5 }}
                  onClick={handleRemoveClick}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Radio.Group value={tipoSelecionado} onChange={tipoRegraOnChange}>
                <Radio value={1}>Simples</Radio>
                <Radio value={2}>Condicionada</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SelectRegraCondicional
            eventSelected={
              dataSource?.events && dataSource?.events.length
                ? dataSource?.events[0]
                : undefined
            }
            events={events}
            onChange={onSelectRegraCondicionalChange}
          />
        </Col>
        {renderAditionalConditionRule()}
      </Row>
    </Card>
  );
};
