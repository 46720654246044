import { yupResolver } from '@hookform/resolvers/yup';
import { PageHeader } from '@organisms';
import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { RouteProps, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { object, SchemaOf, string } from 'yup';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { ContentComponent } from 'src/app/components/ContentComponent';
import { isEmail } from 'src/app/helpers/StringHelper';
import { Success } from 'src/app/utils/statusCode';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { clientApi } from '../../api/clientApi';
import useCancelationAdyen from '../../hooks/useCancelationAdyen';
import useSubscriptionsAdyen from '../../hooks/useSubscriptionsAdyen';
import useSubscriptionsHotmart from '../../hooks/useSubscriptionsHotmart';
import useSubscriptionsPagarMe from '../../hooks/useSubscriptionsPagarMe';
import useTransactionsAdyen from '../../hooks/useTransactionsAdyen';
import useTransactionsHotmart from '../../hooks/useTransactionsHotmart';
import useTransactionsItunes from '../../hooks/useTransactionsItunes';
import useTransactionsPagarMe from '../../hooks/useTransactionsPagarMe';
import { ResponseClientByIdOrEmail } from '../../types/clientTypes';
import { ClientCommentsTab } from './client-comments/ClientCommentsTab';
import { ClientDashboardTab } from './client-dashboard/ClientDashboardTab';
import { ClientDataTab } from './client-data/ClientDataTab';
import { ClientDetailsTab } from './client-details/ClientDetailsTab';
import { ClientGroupsVipTab } from './client-groups-vip/ClientGroupsVipTab';

const { TabPane } = Tabs;

export const ClientDetailsScreen: FC<RouteProps> = () => {
  interface UseParamsId {
    id: string;
  }
  const params: UseParamsId = useParams();
  const queryClient = useQueryClient();

  const schema: SchemaOf<{ search: string }> = object({
    search: string()
      .required('Campo Obrigatório')
      .test(
        'test-name',
        'Digite um E-mail/ID válido',
        function (value: string | undefined) {
          if (value && !!isNaN(parseFloat(value)) && !isEmail(value)) {
            return false;
          }
          return true;
        }
      ),
  }).defined();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ search: string }>({
    resolver: yupResolver(schema),
  });

  useBreadcrumbs([
    {
      label: 'Clientes',
      route: '/clientes',
    },
    {
      label: 'Dados do Cliente',
    },
  ]);

  const [idOrEmail, setIdOrEmail] = useState<string | undefined>(params.id);
  const [email, setEmail] = useState<string>();
  const history = useHistory();

  const fetchUsers = async (key: string, idOrEmailParam?: string) => {
    if (!idOrEmailParam) return undefined;

    const response = await clientApi.getCustomerByIdOrEmail(idOrEmailParam);
    if ((response as ResponseClientByIdOrEmail).status === Success) {
      const emailResponse =
        (response as ResponseClientByIdOrEmail).data?.customer?.email || '';
      setEmail(emailResponse);
      const id = (response as ResponseClientByIdOrEmail).data?.customer?.id;
      if (id) {
        history.replace(id.toString());
      }

      return (response as ResponseClientByIdOrEmail).data;
    } else {
      OpenNotification(
        true,
        'Usuário não encontrado por favor tente novamente',
        ''
      );
      return undefined;
    }
  };

  const { data, isLoading, isError } = useQuery(
    ['users', idOrEmail],
    () => fetchUsers('users', idOrEmail),
    { refetchOnWindowFocus: false }
  );

  const subscriptionsPagarMe = useSubscriptionsPagarMe(email);
  const transactionsPagarMe = useTransactionsPagarMe(email);
  const subscriptionsHotmart = useSubscriptionsHotmart(email);
  const transactionsHotmart = useTransactionsHotmart(email);
  const subscriptionsAdyen = useSubscriptionsAdyen(+params.id);
  const transactionsAdyen = useTransactionsAdyen(email);
  const transactionsItunes = useTransactionsItunes(email);
  const transactionsAdyenCancelation = useCancelationAdyen(email);

  const onSubmit = handleSubmit(async (dataParam: { search: string }) => {
    setIdOrEmail(dataParam.search);
  });

  const reloadUser = () => {
    queryClient.invalidateQueries('users');
  };

  const verComoComponent = () => {
    return (
      <Col
        sm={{ span: 17 }}
        xs={{ span: 17 }}
        lg={{ span: 12 }}
        md={{ span: 12 }}
      >
        <ButtonVerQueimaDiaria type='default' onClick={onClickVerQueimaDiaria}>
          Ver Queima Diária como usuário
        </ButtonVerQueimaDiaria>
      </Col>
    );
  };

  const dadosCustomerComponent = () => {
    if (!data) {
      return <></>;
    }

    return (
      <Row>
        <Tabs style={{ marginTop: 20, width: '100%' }} defaultActiveKey='1'>
          <TabPane tab='Dados do Cliente' key='1'>
            <ClientDetailsTab
              dados={data?.customer}
              profile={data?.profile}
              subscriptionsPagarme={subscriptionsPagarMe.data?.subscriptions}
              transactionsPagarme={
                !transactionsPagarMe
                  ? []
                  : transactionsPagarMe.data?.transactions
              }
              assignHotmart={subscriptionsHotmart.data}
              transactionsHotmart={transactionsHotmart.data}
              assignAdyen={subscriptionsAdyen.data}
              transactionsAdyen={transactionsAdyen.data?.transactions}
              refundAdyen={transactionsAdyen.data?.refund}
              reloadUser={reloadUser}
              transactionsCancelation={
                transactionsAdyenCancelation.data?.transactions
              }
              transactionsItunes={transactionsItunes.data?.transactions}
            />
          </TabPane>
          <TabPane tab='Dashboard do Cliente' key='2'>
            <ClientDashboardTab dados={data?.customer} />
          </TabPane>
          <TabPane tab='Dados de Uso' key='3'>
            <ClientDataTab dados={data?.customer} />
          </TabPane>
          <TabPane tab='Comentários' key='4'>
            <ClientCommentsTab dados={data?.customer} />
          </TabPane>
          <TabPane tab='GrupoVip' key='5'>
            <ClientGroupsVipTab customer={data?.customer} />
          </TabPane>
        </Tabs>
      </Row>
    );
  };

  const onClickVerQueimaDiaria = async () => {
    if (!data) {
      return;
    }
    const urlVerQueimaDiaria = data?.urlViewQueimaDiaria;
    window.open(urlVerQueimaDiaria, '_blank');
  };

  const onClickButtonV2 = useCallback(() => {
    if (!data) return;
    history.push(`/cliente/v2/${data.customer.id}`);
  }, [data]);

  return (
    <ContentComponent isLoading={isLoading} isError={isError}>
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Usuários'}
          subTitle={'Informações do cliente'}
          extra={
            <Button
              type='primary'
              onClick={() => onClickButtonV2()}
              style={{ alignSelf: 'center' }}
            >
              Ir para nova visão de Clientes
            </Button>
          }
        />
      </Row>
      <Row>
        <Col
          sm={{ span: 20 }}
          xs={{ span: 20 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
          <Form onFinish={onSubmit}>
            <Row>
              <Col
                sm={{ span: 20 }}
                xs={{ span: 20 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <LabelUserInfo>Email ou ID do Cliente:</LabelUserInfo>

                <Controller
                  control={control}
                  name='search'
                  render={({ field }) => (
                    <Input data-testid='search' {...field} />
                  )}
                />
                <ErrorStyled>
                  {errors.search ? errors.search.message : ''}
                </ErrorStyled>
              </Col>
              <Col
                sm={{ span: 4 }}
                xs={{ span: 4 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <ButtonInput
                  key='submit'
                  htmlType='submit'
                  // onClick={onSubmit}
                  style={{ display: 'inline-flex' }}
                  type='primary'
                  data-testid='submit-search'
                >
                  Buscar
                </ButtonInput>
              </Col>
            </Row>
          </Form>
        </Col>
        {verComoComponent()}
      </Row>
      {dadosCustomerComponent()}
    </ContentComponent>
  );
};

const LabelUserInfo = styled.label`
  padding-top: 10px;
  color: #177ddc;
`;
const ButtonInput = styled(Button)`
  margin-top: 21px;
  margin-left: 10px;
`;
const ButtonVerQueimaDiaria = styled(Button)`
  float: right;
  margin-top: 21px;
  color: #1765ad;
  border-color: #1765ad;
`;
const ErrorStyled = styled.p`
  padding-top: 2px;
  color: #cc0000;
  font-weight: 500;
`;
