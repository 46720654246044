export const DEFAULT_RULES = [{ required: true, message: 'Campo obrigatório' }];

export const STATUSES = [
  { key: 1, value: 'Agendado' },
  { key: 2, value: 'Processando' },
  { key: 3, value: 'Cancelado' },
  { key: 4, value: 'Executado' },
];

export const PLATFORMS = [
  { key: 'adyen', value: 'Adyen' },
  { key: 'hotmart', value: 'Hotmart' },
  { key: 'pagarme', value: 'Pagar.me' },
];
