import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

interface PutSegmentNewOrdering {
  values: Array<{ segmentId: number; order: number }>;
}
export async function putNewOrdering({
  values,
}: PutSegmentNewOrdering): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/orderingForBanners/`,
    method: 'PUT',
    data: values,
  });

  return data;
}
