import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';

export async function generateImage(info: UploadChangeParam<UploadFile>) {
  const base64 = await getBase64(info.file.originFileObj as RcFile);

  const blob = dataURItoBlob(base64, info.file.type ?? '');

  const fileName = info.file.name;

  const file = new File([blob], fileName, { type: info.file.type });

  return file;
}

const getBase64 = (img: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.addEventListener('error', error => reject(error));
    reader.readAsDataURL(img);
  });
};

function dataURItoBlob(dataURI: string, fileType: string) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString =
    fileType || dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}
