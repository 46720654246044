import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { factoryAnonimizeDto } from 'src/features/Lgpd/utils/factoryAnonimizeDto';

import { lgpdApi } from '../../../../../apis/lgpdApis';
import {
  ConsultLgpdProps,
  FormData,
  ModalLgpdData,
  UserAdminData,
} from '../../../../../interfaces/lgpdInterfaces';
import {
  ButtonModalLgpdStatus,
  CurrentModal,
} from '../../../../../typings/lgpdTipyngs';
import { FormModal } from '../FormModal';

export const useControllerFormModal = (
  handleCloseModal: () => void,
  setCurrentModal: Dispatch<SetStateAction<CurrentModal>>,
  setConsultLgpd: Dispatch<SetStateAction<ConsultLgpdProps | undefined>>,
  adminData?: UserAdminData
) => {
  const [modalStepOne, setModalStepOne] = useState<ModalLgpdData>();
  const [invalidConsult, setInvalidConsult] = useState(false);
  const [hasError, setHasError] = useState<boolean>();
  const [stateOkButton, setStateOkButton] =
    useState<ButtonModalLgpdStatus>('disabled');
  const [formData, setFormData] = useState<FormData>({
    customerEmail: '',
    jiraTicket: '',
    requesterId: '',
  });
  const emailNotFound = 'Email not found.';
  const buttonLoading = stateOkButton === 'loading';

  useEffect(() => {
    const isLoadingText = buttonLoading ? '' : 'Proximo';
    setModalStepOne({
      element: (
        <FormModal
          setData={setFormData}
          formData={formData}
          setHasError={setHasError}
          hasError={hasError}
          setStateOkButton={setStateOkButton}
          invalidConsult={invalidConsult}
        />
      ),
      textButtonOk: isLoadingText,
      handleButtonOk: onSubmitForm,
      hasError: hasError,
      handleButtonCancel: handleCloseModal,
      handleButtonPrev: handlePrevFormModal,
      buttonOkState: stateOkButton,
    });
  }, [formData, hasError, stateOkButton, invalidConsult]);

  useEffect(() => {
    setHasError(false);
    setFormData(prevData => ({
      ...prevData,
      requesterId: adminData?.id,
    }));
  }, [adminData]);

  const handlePrevFormModal = () => {
    setHasError(false);
    setInvalidConsult(false);
    setStateOkButton('disabled');
    handleCloseModal();
    setFormData(prevData => ({
      ...prevData,
      customerEmail: '',
      jiraTicket: '',
    }));
  };

  const postLgdConsulter = async () => {
    await lgpdApi.postQueryLgpd(formData).then(async response => {
      const responseError = response.response?.data;
      if (!responseError) {
        const newResponse = await factoryAnonimizeDto(response);
        setConsultLgpd(newResponse);
      } else {
        throw Error(responseError.details);
      }
    });
  };

  const onSubmitForm = async () => {
    setStateOkButton('loading');
    await postLgdConsulter()
      .then(() => {
        setInvalidConsult(false);
        setCurrentModal('stepTwo');
        setStateOkButton('disabled');
      })
      .catch(error => {
        if (error.message === emailNotFound) {
          setCurrentModal('stepTwo');
          setInvalidConsult(false);
          setStateOkButton('disabled');
        } else {
          setInvalidConsult(true);
        }
      });
    setStateOkButton('disabled');
  };

  return modalStepOne;
};
