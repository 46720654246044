import { Card } from 'antd';
import styled from 'styled-components';

interface CardContainerProps {
  selected?: boolean;
  disabled?: boolean;
}

export const CardContainer = styled(Card)<CardContainerProps>`
  height: 159px;
  width: 176px;
  border: none;
  border-radius: 4px;
  background-color: ${({ selected }) =>
    selected ? '#177ddc !important' : 'initial'};

  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};

  & .ant-card-body {
    padding: 18px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }

  &:hover {
    border: ${({ disabled }) => (!disabled ? '1px solid #177ddc' : '')};
  }
`;

export const BottomLine = styled.div`
  border-bottom: 1px solid #f0f0f0;
`;
