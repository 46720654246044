// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

async function createModalImageReference(
  info: {
    modalId: string;
    advertisementId: string;
  },
  params: FormData
): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/modal/${info.modalId}/advertisement/${info.advertisementId}/upload`,
    method: 'PUT',
    data: params,
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export default createModalImageReference;
