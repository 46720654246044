import styled from 'styled-components';

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const Description = styled.span`
  color: #5a5a5a;

  span {
    font-weight: 700;
    color: white;
  }
`;
