import { enviroments } from '@infrastructure/enviroments';
import { Segments } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function putSegment(segment: Segments.SegmentsDto): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/${segment.id}`,
    method: 'PUT',
    data: {
      ...segment,
    },
  });

  return data;
}
