import { CountResponse } from '@typings';
import { Empty, Table } from 'antd';
import {
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface';
import React, { useEffect } from 'react';

import { createKey } from 'src/app/utils/createKey';

import * as S from './TableList.style';

type DataType = Record<string, any>;

interface ColumnDefinition {
  title: string;
  dataIndex: string;
  width?: string;
  key?: string;
  render?: (value: any, record: DataType, index: number) => React.ReactNode;
}

interface TableListProps<T> {
  scrollY?: number;
  columns: ColumnDefinition[];
  data?: CountResponse<T>;
  emptyText?: string;
  countLabel?: string;
  onChangeSelected?: (selected: T[]) => void;
  rowsSelected?: T[];
  onChangePagination?: (
    take: number,
    skip: number,
    pagination: TablePaginationConfig
  ) => void;
  paginationCurrentPage?: number;
}

export const TableList = <T extends DataType>({
  columns,
  data,
  emptyText = 'Nenhum item encontrado',
  countLabel,
  onChangeSelected,
  onChangePagination,
  rowsSelected,
  paginationCurrentPage,
}: TableListProps<T>) => {
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    total: 0,
    current: paginationCurrentPage || 1,
    position: ['bottomCenter'],
    responsive: true,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    size: 'small',
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      current: paginationCurrentPage || pagination.current,
      total: data?.count ?? 0,
    });
  }, [data]);

  const rowSelection: TableRowSelection<DataType> = {
    onChange: (_, selectedRows) => {
      onChangeSelected?.(selectedRows as T[]);
    },
  };

  const handleChangePagination = (paramsPagination: TablePaginationConfig) => {
    setPagination(prev => ({ ...prev, ...paramsPagination }));
    onChangePagination?.(
      paramsPagination.pageSize || 1,
      ((paramsPagination?.current || 1) - 1) * (paramsPagination.pageSize || 0),
      paramsPagination
    );
  };

  return (
    <section>
      {countLabel && data?.rows.length && (
        <S.TableTotalAmountContainer>{countLabel}</S.TableTotalAmountContainer>
      )}

      <Table<T>
        bordered
        pagination={pagination}
        onChange={handleChangePagination}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
        }}
        showHeader
        columns={columns}
        dataSource={data?.rows.map(row => ({
          ...row,
          key: row.id || createKey(row),
        }))}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: rowsSelected?.map(row => row.id),
        }}
      />
    </section>
  );
};
