import styled, { css } from 'styled-components';

interface SelectItemProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  background-color: #262626;
  border-radius: 100px;
`;

export const SelectItem = styled.button<SelectItemProps>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 20px;

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: #177ddc;
          border: 1px solid #177ddc;

          svg {
            color: #fff;
          }
        `
      : css`
          background-color: #141414;
          border: 1px solid #434343;

          svg {
            color: #acacac;
          }
        `}
`;
