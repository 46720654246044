import { PageHeader } from '@organisms';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

import { CouponDigital } from 'src/models';

import { DigitalCouponForm } from '../components/CouponForm/DigitalCouponForm.component';
import { useProductDigitalCoupon } from '../hooks/useProductDigitalCoupon';

export function ReadCouponDigitalPage() {
  const { state }: { state: { coupon: CouponDigital.ICoupon } } = useLocation();
  const productCoupon = useProductDigitalCoupon();

  return (
    <Spin spinning={productCoupon.isLoading}>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={`Visualização do Cupom ${state.coupon.code}`}
        subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
      />

      <DigitalCouponForm
        coupon={state.coupon}
        couponHookData={productCoupon}
        readonly
      />
    </Spin>
  );
}
