import { UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row, Select } from 'antd';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Created, Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import {
  ButtonInput,
  ButtonInputRevogarAcesso,
  ButtonLibertAcess,
  ColStyled,
  DividerStyled,
  DividerStyledResponsive,
  GridStyled,
  RowStyled,
  TextUserInfo,
} from 'src/features/clients/styles/ClientStyles';
import {
  AdyenAssign,
  AdyenRefusedTransaction,
  AdyenTransaction,
  ClientType,
  HotmartSubscriptionItemResponse,
  HotmartTransactionItemResponse,
  ItunesResponse,
  PagarMeSubscription,
  PagarMeTransaction,
  ProfilesResponse,
  ProfileType,
} from 'src/features/clients/types/clientTypes';

import { ClientInfoForm } from './components/ClientInfoForm';
import { ClientProfileForm } from './components/ClientProfileForm';
import { AdyenTableComponent } from './table-components/AdyenTableComponent';
import { HotmartTableComponent } from './table-components/HotmartTableComponent';
import { ItunesComponent } from './table-components/ItunesComponent';
import { PagarMeTableComponent } from './table-components/PagarMeTableComponent';

export type ClientDetailsProps = {
  dados?: ClientType;
  profile?: ProfileType;
  subscriptionsPagarme?: PagarMeSubscription[];
  transactionsPagarme?: PagarMeTransaction[];
  assignHotmart?: HotmartSubscriptionItemResponse[];
  transactionsHotmart?: HotmartTransactionItemResponse[];
  assignAdyen?: AdyenAssign[];
  transactionsAdyen?: AdyenTransaction[];
  refundAdyen?: AdyenTransaction[];
  transactionsCancelation?: AdyenRefusedTransaction[];
  transactionsItunes?: ItunesResponse[];
  reloadUser: () => void;
};
const erroAlterarSenha = 'Erro ao tentar alterar senha';
const erroEnviarEmail = 'Erro ao tentar enviar email';
const inlineGrid = 'inline-grid';
export const ClientDetailsTab: FC<ClientDetailsProps> = ({
  dados,
  profile,
  subscriptionsPagarme,
  transactionsPagarme,
  assignHotmart,
  transactionsHotmart,
  assignAdyen,
  transactionsAdyen,
  refundAdyen,
  transactionsCancelation,
  transactionsItunes,
  reloadUser,
}) => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [dayExpirationDate, setDayExpirationDate] = useState<string>();
  const [legacyId, setLegacyId] = useState<string>();
  const [accessMessage, setAccessMessage] = useState<string>('');

  const changePassword = async (): Promise<string> => {
    const response = await clientApi.changePassword({
      password: newPassword,
      id: dados?.id,
    });

    if ((response as ProfilesResponse).status === Created) {
      return (response as ProfilesResponse).data;
    } else {
      OpenNotification(true, 'Erro ao tentar alterar senha', '');
    }

    return '';
  };

  const changePasswordMutation = useMutation(changePassword, {
    onSuccess: () => {
      OpenNotification(false, 'Senha alterada com sucesso', '');
      setNewPassword('');
    },
  });
  const newPasswordlength = 4;
  const handleClickChangePassword = () => {
    if (newPassword.length < newPasswordlength) {
      return OpenNotification(
        true,
        erroAlterarSenha,
        'A senha deve ter no minimo 4 caracteres!'
      );
    } else {
      return changePasswordMutation.mutate();
    }
  };

  const revokeAccess = async () => {
    const response = await clientApi.revokeAccess(dados?.id);

    if ((response as ProfilesResponse).status === Success) {
      return (response as ProfilesResponse).data;
    } else {
      OpenNotification(true, 'Erro ao revogar acesso', '');
    }

    return '';
  };

  const sendRevokeAccessMutation = useMutation(revokeAccess, {
    onSuccess: () => {
      OpenNotification(false, 'Acesso revogado', '');
    },
  });

  const handleClickSendRevokeAccess = () => {
    sendRevokeAccessMutation.mutate();
  };

  const sendEmailFirstAccess = async () => {
    const response = await clientApi.sendEmailFirstAccess({
      email: dados?.email,
      id: dados?.id,
    });

    if ((response as ProfilesResponse).status === Created) {
      return (response as ProfilesResponse).data;
    } else {
      OpenNotification(true, erroEnviarEmail, '');
    }

    return '';
  };

  const sendEmailFirstAccessMutation = useMutation(sendEmailFirstAccess, {
    onSuccess: () => {
      OpenNotification(false, 'Email enviado com sucesso', '');
    },
  });

  const handleClickSendFirstEmail = () => {
    sendEmailFirstAccessMutation.mutate();
  };

  const libertAccess = async () => {
    const response = await clientApi.libertAccess({
      dayExpirationDate: Number(dayExpirationDate),
      legacyId: Number(legacyId),
      id: dados?.id,
      email: dados?.email,
    });

    if ((response as ProfilesResponse).status === Created) {
      return (response as ProfilesResponse).data;
    } else {
      OpenNotification(true, erroEnviarEmail, '');
    }

    return '';
  };

  const libertAccessMutation = useMutation(libertAccess, {
    onSuccess: () => {
      OpenNotification(false, 'Acesso liberado', '');
    },
  });

  const handleClickLibertAccess = () => {
    libertAccessMutation.mutate();
    const newAccessDate = new Date();
    newAccessDate.setDate(newAccessDate.getDate() + Number(dayExpirationDate));
    setAccessMessage(
      `A nova data de duração do acesso é até ${newAccessDate.toLocaleDateString(
        'pt-BR',
        { timeZone: 'UTC' }
      )}`
    );
  };

  return (
    <>
      <Row>
        <Col
          md={{ span: 24 }}
          lg={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ClientInfoForm dados={dados} reloadUser={reloadUser} />
        </Col>
        <DividerStyledResponsive />
        <Col
          md={{ span: 24 }}
          lg={{ span: 11, offset: 1 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ClientProfileForm
            dados={dados}
            profile={profile}
            reloadUser={reloadUser}
          />
        </Col>
      </Row>
      <Row>
        <DividerStyled />
        <h1>Ações do perfil</h1>
        <TextUserInfo>Informações do Cliente</TextUserInfo>
      </Row>
      <Row>
        <ColStyled
          md={{ span: 24 }}
          lg={{ span: 6 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <label>Alterar senha do cliente:</label>
          <Input.Password
            placeholder='Digite a nova senha...'
            onChange={e => setNewPassword(e.target.value)}
            type='password'
            data-testid='new-password'
            value={newPassword}
            prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
          />
        </ColStyled>
        <Col
          md={{ span: 24 }}
          lg={{ span: 8 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Button
            style={{ float: 'left', marginTop: 22, marginLeft: 5 }}
            type='primary'
            data-testid='button-alter'
            onClick={handleClickChangePassword}
          >
            Alterar
          </Button>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 10 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ButtonInputRevogarAcesso
            type='primary'
            data-testid='revoke-access'
            onClick={handleClickSendRevokeAccess}
          >
            Remover o acesso do cliente
          </ButtonInputRevogarAcesso>
        </Col>
      </Row>
      <RowStyled>
        <Col
          md={{ span: 24 }}
          lg={{ span: 18 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <GridStyled style={{ display: inlineGrid, marginRight: 5 }}>
            <label>Liberar Acesso:</label>
            <Input
              style={{ width: 104 }}
              placeholder=''
              type='number'
              data-testid='input-libert-access'
              min={0}
              onChange={e => setDayExpirationDate(e.target.value)}
            />
          </GridStyled>
          <GridStyled style={{ display: inlineGrid, marginRight: 5 }}>
            <label>Liberar dias de acesso ( A partir de hoje)</label>
            <Select
              allowClear={false}
              style={{ width: 269 }}
              data-testid='select-libert-access'
              onChange={e => setLegacyId(e?.toString())}
            >
              <Select.Option value='2'>Colaborador</Select.Option>
              <Select.Option value='11'>Treino</Select.Option>
              <Select.Option value='12'>Nutricional</Select.Option>
              <Select.Option value='1'>Treino & Nutricional</Select.Option>
              <Select.Option value='16'>Nutricional MEAB</Select.Option>
              <Select.Option value='14'>Pump10</Select.Option>
              <Select.Option value='15'>Nutricional Pump10</Select.Option>
              <Select.Option value='17'>Coach 100% Magra</Select.Option>
              <Select.Option value='18'>Coach Mudança 21 dias</Select.Option>
              <Select.Option value='19'>Protocolo Caio Franco</Select.Option>
              <Select.Option value='20'>Projeto Juju Salimeni</Select.Option>
              <Select.Option value='24'>LATAM</Select.Option>
              <Select.Option value='27'>+QNUTRI</Select.Option>
            </Select>
          </GridStyled>
          <GridStyled style={{ display: inlineGrid }}>
            <ButtonLibertAcess
              type='primary'
              onClick={handleClickLibertAccess}
              data-testid='libert-access'
              disabled={!legacyId || !dayExpirationDate}
            >
              Liberar acesso
            </ButtonLibertAcess>
          </GridStyled>
          <p style={{ color: 'gold' }}>{accessMessage}</p>
        </Col>

        <Col
          md={{ span: 24 }}
          lg={{ span: 6 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ButtonInput
            type='primary'
            data-testid='send-email-fist-access'
            onClick={handleClickSendFirstEmail}
          >
            Reenviar email do primeiro acesso
          </ButtonInput>
        </Col>
      </RowStyled>
      <Divider style={{ border: '1px solid rgba(255, 255, 255, 0.151)' }} />
      <Row>
        <PagarMeTableComponent
          subscriptionsPagarme={subscriptionsPagarme || []}
          transactionsPagarme={transactionsPagarme || []}
        />
        <HotmartTableComponent
          assignHotmart={assignHotmart || []}
          transactionsHotmart={transactionsHotmart || []}
        />
        <AdyenTableComponent
          dados={dados}
          assignAdyen={assignAdyen}
          transactionsAdyen={transactionsAdyen}
          refundAdyen={refundAdyen}
          transactionsCancelation={transactionsCancelation}
        />
        <ItunesComponent transactionsItunes={transactionsItunes} />
      </Row>
    </>
  );
};
