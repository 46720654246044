import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { ClassAttendedResponseDto } from 'src/features/clients/types/clientTypes';

const fetchClassAttended = async (emailParam?: string) => {
  const response = await clientApi.getClassAttended(emailParam);
  console.log('response: ', (response as ClassAttendedResponseDto).data);
  if (
    !isSerializedApiError(response) &&
    (response as ClassAttendedResponseDto).status === Success
  ) {
    console.log((response as ClassAttendedResponseDto).data);
    return (response as ClassAttendedResponseDto).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as últimas aulas assistidas',
      'Ocorreu um erro  ao carregar as últimas aulas assistidas, por favor tente novamente mais tarde.'
    );
  }
};

export default function useClassAttended(email?: string) {
  return useQuery(['classAttended', email], () => fetchClassAttended(email), {
    refetchOnWindowFocus: false,
  });
}
