import { Col, Row } from 'antd';
import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
`;

export const RowContainer = styled(Row)``;

export const TitleContainer = styled(Col)`
  padding: 10px 16px 10px 16px !important;
  background-color: rgba(255, 255, 255, 0.04);

  border: 1px solid rgba(255, 255, 255, 0.06);

  &.first-row {
    border-radius: 6px 0px 0px 0px;
  }

  &.last-row {
    border-radius: 0px 0px 0px 6px;
  }
  & > div {
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-weight: 600;
  }
`;

export const ContentContainer = styled(Col)`
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;

  border: 1px solid rgba(255, 255, 255, 0.06);

  &.first-row {
    border-radius: 0px 6px 0px 0px;
  }

  &.last-row {
    border-radius: 0px 0px 6px 0px;
  }
`;
