import { lgpdApi } from '../apis/lgpdApis';
import {
  ConsultLgpdProps,
  RequestAnonymizeDTO,
} from '../interfaces/lgpdInterfaces';
import { factoryStatusAnonimization } from './factoryStatusAnonimization';

export async function factoryAnonimizeDto(
  data: RequestAnonymizeDTO
): Promise<ConsultLgpdProps> {
  const requesterName = async () => {
    const response = await lgpdApi
      .getUserByIdData(data.requesterId)
      .then(data => {
        console.log(data);
        return data.fullName.name;
      })
      .catch(() => {
        'Não encontrado';
      });
    return response;
  };
  const name = await requesterName();

  const response = {
    linkCsvAnonimization: data.anonymizationFile,
    createdData: data.createdAt,
    customerDocument: data.customerDocument,
    emailCustomer: data.customerEmail,
    customerId: data.customerId,
    customerName: data.customerName,
    linkCsvEvidence: data.evidenceFile,
    consultId: data.id,
    requesterId: data.requesterId,
    status: factoryStatusAnonimization(data.status),
    requesterName: name,
  };

  return response;
}
