// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { Modal } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

async function saveOrder(
  props: Modal.RequestSaveSort[]
): Promise<Modal.ResponseOrdering> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/modal/sort`,
    method: 'POST',
    data: props,
  });

  return data;
}

export default saveOrder;
