import { Col, Divider, Row, Table } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { ItunesResponse } from 'src/features/clients/types/clientTypes';

export type TableProps = {
  transactionsItunes?: ItunesResponse[];
};

export const ItunesComponent: FC<TableProps> = ({ transactionsItunes }) => {
  if (!transactionsItunes?.length) {
    return <></>;
  }

  return (
    <>
      <Row>
        <h1 style={{ fontSize: 20 }}>App Store</h1>
      </Row>
      <Divider style={{ border: 'none', margin: 0 }}></Divider>

      {!!transactionsItunes?.length && (
        <Col span={24}>
          <TitleTable>Transações</TitleTable>
          <Table
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
              },
              {
                title: 'Transação',
                dataIndex: 'transaction',
              },
              {
                title: 'Status',
                dataIndex: 'status',
              },

              {
                title: 'Valor',
                dataIndex: 'fullPrice',
                render: (fullPrice: number) => (
                  <>{convertToCurrency(fullPrice)}</>
                ),
              },
              {
                title: 'Data',
                dataIndex: 'purchaseDate',
                render: (paymentDate: string) => (
                  <div>{dateFormatted(paymentDate, true)}</div>
                ),
              },
            ]}
            dataSource={transactionsItunes}
          />
        </Col>
      )}
    </>
  );
};
const TitleTable = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #dbdbdb;
`;
