import {
  DeleteOutlined,
  PlusCircleOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, TextEditController } from '@organisms';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  Controller as ControllerHookForm,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { CancelationButtonTitleFormFields } from 'src/features/Funnel/Components/formFields/CancelationButtonTitleFormFields';
import { LayoutFunnelPage } from 'src/features/Funnel/Components/LayoutFunnelPage';
import { TitleFunnelPage } from 'src/features/Funnel/Components/TitleFunnelPage';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';
import { useRequestMediaPage } from 'src/features/Funnel/hooks/media/useRequestMediaPage';
import { useTryToRequestCreatingMediaFunnelPage } from 'src/features/Funnel/hooks/media/useTryToRequestCreatingMediaFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { MediaFormData } from 'src/features/Funnel/types/mediaPageTypings';

const slidesSchema = yup.object().shape({
  title: yup.string().required('O Titulo Principal é obrigatório'),
  web: yup
    .mixed()
    .nullable()
    .required('A Versão Web é obrigatório')
    .test('is-filled', 'A Versão Web é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  mobile: yup
    .mixed()
    .nullable()
    .required('A Versão mobile é obrigatório')
    .test('is-filled', 'A Versão mobile é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  imageTitle: yup.string().required('O Título do lado da imagem é obrigatório'),
  description: yup.string().required('A Descrição é obrigatório'),
  informativeNumeric: yup
    .string()
    .required('O Informativo 1 numérico é obrigatório'),
  informativeText: yup
    .string()
    .required(
      'O Informativo 1 texto (abaixo do número) numérico é obrigatório'
    ),
  informativeNumeric2: yup.string().nullable(),
  informativeText2: yup.string().nullable(),
  buttonTitle: yup.string().required('O Título do botão 1 é obrigatório'),
  buttonLink: yup.string().required('O Link do botão é obrigatório'),
  isTarget: yup.boolean().nullable(),
});

const validationSchema = yup.object({
  slides: yup.array().of(slidesSchema).required('Informe ao menos um slide'),
  continueCancelationButtonTitle: yup.string().nullable(),
  keepSubscriptionButtonTitle: yup.string().nullable(),
});

export const FunnelMediaPageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState('Modal de Mídia');
  const [isUpdate, setIsUpdate] = useState(false);

  const { control, handleSubmit, setValue } = useForm<
    Omit<MediaFormData, 'name'>
  >({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      slides: [
        {
          title: '',
          web: '',
          mobile: '',
          imageTitle: '',
          description: '',
          informativeNumeric: '',
          informativeText: '',
          buttonTitle: '',
          buttonLink: '',
          isTarget: false,
        },
      ],
      continueCancelationButtonTitle: null,
      keepSubscriptionButtonTitle: null,
    },
  });
  const history = useHistory();
  const { id } = useParams<ParamasProps>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'slides',
  });
  const { tryToRequestCreatingMediaFunnelPage, isLoadingCretingMedia } =
    useTryToRequestCreatingMediaFunnelPage();
  const { handleUpdateMediaPage, isLoadingMediaUpdate } = useRequestMediaPage({
    isUpdate,
    setIsUpdate,
    setTitlePage,
    titlePage,
    setValue,
    replace,
  });

  const handleMediaPage = useCallback(
    async (e: Omit<MediaFormData, 'name'>) => {
      if (isUpdate) {
        return handleUpdateMediaPage(e);
      }
      await tryToRequestCreatingMediaFunnelPage({
        name: titlePage,
        ...e,
      });
    },
    [
      isUpdate,
      handleUpdateMediaPage,
      tryToRequestCreatingMediaFunnelPage,
      titlePage,
    ]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  const [activeSlide, setActiveSlide] = useState([fields[0].id]);
  useEffect(() => {
    setActiveSlide([fields[0].id]);
  }, [fields]);

  return (
    <LayoutFunnelPage>
      <TitleFunnelPage titlePage={titlePage} setTitlePage={setTitlePage} />
      <Row>
        <Form
          layout='vertical'
          requiredMark='optional'
          onFinish={handleSubmit(handleMediaPage)}
          style={{
            width: '100%',
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Collapse
            activeKey={activeSlide}
            onChange={e => setActiveSlide(e as any)}
            expandIconPosition='end'
          >
            {fields.map((field, index) => (
              <Collapse.Panel
                header={`Slide ${index + 1}`}
                key={field.id}
                extra={
                  <Button
                    type='text'
                    style={{
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 1}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                }
              >
                <Row style={{ flexDirection: 'column', gap: '12px' }}>
                  <Controller
                    control={control}
                    name={`slides[${index}].title`}
                    defaultValue={field.title}
                    placeholder='Escreva o principal título da página.'
                    autoComplete='off'
                    label='Título Principal'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                  <Col
                    style={{
                      gap: '24px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Col style={{ flex: 1 }}>
                      <UploadImage
                        tooltip={{
                          title: () => (
                            <Typography.Text>
                              <strong>Versão Web</strong> - 482 x 282 pixels
                              (jpg, jpeg, png)
                            </Typography.Text>
                          ),
                        }}
                        control={control}
                        name={`slides[${index}].web`}
                        label='Versão Web'
                        required
                      />
                    </Col>
                    <Col style={{ flex: 1 }}>
                      <UploadImage
                        tooltip={{
                          title: () => (
                            <Typography.Text>
                              <strong>Versão App</strong> - 343 x 124 pixels
                              (jpg, jpeg, png)
                            </Typography.Text>
                          ),
                        }}
                        control={control}
                        name={`slides[${index}].mobile`}
                        label='Versão Mobile'
                        required
                      />
                    </Col>
                  </Col>
                  <Controller
                    control={control}
                    name={`slides[${index}].imageTitle`}
                    defaultValue={field.imageTitle}
                    placeholder='Título do lado da imagem'
                    autoComplete='off'
                    label='Título do lado da imagem'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                  <TextEditController
                    control={control}
                    name={`slides[${index}].description`}
                    defaultValue={field.description}
                    placeholder='Apresente essa forma de contato.'
                    label='Descrição'
                    required
                  />
                  <Row gutter={24}>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].informativeNumeric`}
                        defaultValue={field.informativeNumeric}
                        placeholder='Label'
                        autoComplete='off'
                        label='Informativo 1 numérico'
                        required
                        showCount={true}
                        maxLength={250}
                      />
                    </Col>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].informativeText`}
                        defaultValue={field.informativeText}
                        placeholder='Label'
                        autoComplete='off'
                        label='Informativo 1 texto (abaixo do número)'
                        required
                        showCount={true}
                        maxLength={250}
                      />
                    </Col>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].informativeNumeric2`}
                        defaultValue={field.informativeNumeric2}
                        placeholder='Label'
                        autoComplete='off'
                        label='Informativo 2 numérico'
                        showCount={true}
                        maxLength={250}
                      />
                    </Col>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].informativeText2`}
                        defaultValue={field.informativeText2}
                        placeholder='Label'
                        autoComplete='off'
                        label='Informativo 2 texto (abaixo do número)'
                        showCount={true}
                        maxLength={250}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].buttonTitle`}
                        defaultValue={field.buttonTitle}
                        placeholder='Título do botão'
                        autoComplete='off'
                        label='Título do botão 1'
                        showCount={true}
                        maxLength={250}
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name={`slides[${index}].buttonLink`}
                        defaultValue={field.buttonLink}
                        placeholder='Link do botão principal'
                        autoComplete='off'
                        label='Link do botão'
                        showCount={true}
                        maxLength={250}
                        required
                        prefix={
                          <ThunderboltOutlined style={{ color: '#1668DC' }} />
                        }
                      />
                      <ContainerCheckbox>
                        <ControllerHookForm
                          control={control}
                          name={
                            `slides[${index}].isTarget` as `slides.${typeof index}.isTarget`
                          }
                          render={({ field }) => {
                            return (
                              <Row style={{ gap: '8px', alignItems: 'center' }}>
                                <Switch
                                  size='small'
                                  checked={field.value as boolean}
                                  onChange={enable => {
                                    setValue(
                                      `slides[${index}].isTarget` as `slides.${typeof index}.isTarget`,
                                      enable
                                    );
                                  }}
                                />
                                <Typography>
                                  Abrir em uma página externa
                                </Typography>
                              </Row>
                            );
                          }}
                        />
                      </ContainerCheckbox>
                    </Col>
                  </Row>
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Row style={{ justifyContent: 'center' }}>
            <Col>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  append({
                    title: '',
                    web: '',
                    mobile: '',
                    imageTitle: '',
                    description: '',
                    informativeNumeric: '',
                    informativeText: '',
                    buttonTitle: '',
                    buttonLink: '',
                    isTarget: false,
                  });
                }}
                disabled={fields.length >= 10}
              >
                Adicionar mais uma opção
              </Button>
            </Col>
          </Row>
          <CancelationButtonTitleFormFields control={control} />

          <Divider orientation='center' style={{ marginTop: '398px' }} />
          <Row style={{ justifyContent: 'end' }}>
            <Row style={{ gap: '8px' }}>
              <Button type='default' onClick={() => history.goBack()}>
                Cancelar
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                loading={
                  isUpdate ? isLoadingMediaUpdate : isLoadingCretingMedia
                }
              >
                Salvar
              </Button>
            </Row>
          </Row>
        </Form>
      </Row>
    </LayoutFunnelPage>
  );
};

const ContainerCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control-input {
    min-height: 0;
  }
`;
