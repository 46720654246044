import styled, { css } from 'styled-components';

interface ImageProps {
  isSmall: boolean;
}

export const Container = styled.div`
  border: 2px solid;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
    margin: 0;
    padding: 0;
    padding: 16px 24px;
  }
`;

export const ResponsiveContainer = styled.div`
  display: flex;
  margin: 0 24px 24px;
  gap: 14px;
  align-items: center;
  word-wrap: break-word;
`;

export const DescriptionContent = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;

  text-overflow: ellipsis;
  > p {
    all: unset;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
  }
`;

export const CheckContent = styled.div`
  padding: 8px 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.5px;
  text-align: left;
`;

export const Image = styled.img<ImageProps>`
  height: 100px;
  object-fit: cover;
  border-radius: 8px;

  ${({ isSmall }) =>
    isSmall
      ? css`
          width: 100%;
          height: 100px;
          object-fit: cover;
          border-radius: 8px;
        `
      : css`
          flex: 0.5;
        `}
`;
