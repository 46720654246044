import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader } from 'src/app/components/PageHeader';
import { _notifyError } from 'src/features/subscriptions/scheduled-reports/utils';

import { gameficationCompetition } from '../../client.api';
import { ICompetition } from '../../dto/competition.interface';
import { ManageCompetitionsResponseDto } from '../../dto/manage-competitions.response.dto';
import CompetitionTypeInput from './CompetitionType';
import SegmentInput from './SegmentInput';

interface IFormInterface {
  initialValues?: ManageCompetitionsResponseDto;
  enableTabs: (v: boolean) => void;
}

const GameficationCompetitionFormStep1: FC<IFormInterface> = ({
  initialValues,
  enableTabs,
}) => {
  const [form] = Form.useForm();
  const router = useHistory();
  const URL_PARAMS: ICompetition = useParams();

  const isEdit = Boolean(Object.keys(URL_PARAMS)?.length);
  const [enableFields, setEnableFields] = useState<boolean>(!isEdit);

  const onFinish = async (j: ManageCompetitionsResponseDto) => {
    const hasId = Number(URL_PARAMS?.id);

    j.startDate = moment(j.startDate).toISOString();
    j.endDate = moment(j.endDate).toISOString();

    const response =
      Boolean(hasId) && isEdit
        ? await gameficationCompetition.updateCompetition(hasId, j)
        : await gameficationCompetition.createCompetition(j);

    const responseId = response?.data?.id;
    if (!responseId) {
      return _notifyError('Ops, algo deu errado');
    }

    setEnableFields(false);
    enableTabs(true);

    router.push(`/social/gamefication/competition/${responseId}`);
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
    initialValues: {
      ...initialValues,
      startDate: initialValues?.startDate
        ? moment(initialValues.startDate)
        : undefined,
      endDate: initialValues?.endDate
        ? moment(initialValues.endDate)
        : undefined,
    },
  };

  const FIELD_PROPS = { disabled: !enableFields };

  const SWITCH_PROPS = {
    checkedChildren: 'Ativo',
    unCheckedChildren: 'Inativo',
  };

  const DATE_PROPS = {
    format: 'DD/MM/YYYY',
    placeholder: 'Selecione a vigência',
    style: { width: '100%' },
  };

  const RENDER_CTA = () =>
    enableFields ? (
      <>
        <Button
          data-testid='reset'
          onClick={() => {
            form.resetFields();
            if (isEdit) {
              setEnableFields(false);
              enableTabs(true);
            }
          }}
          icon={<DeleteOutlined />}
          type='link'
        >
          Descartar
        </Button>

        <Button
          data-testid='submit'
          style={{ color: '#52c41a' }}
          icon={<CheckCircleOutlined />}
          htmlType='submit'
          type='link'
        >
          Salvar
        </Button>
      </>
    ) : (
      <Button
        data-testid='edit'
        onClick={() => {
          setEnableFields(!enableFields);
          enableTabs(false);
        }}
        icon={<EditOutlined />}
        type='link'
      >
        Editar
      </Button>
    );

  return (
    <Form size='large' layout='vertical' {...FORM_PROPS}>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Dados Básicos' />
        </Col>

        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          {RENDER_CTA()}
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            name='name'
            label='Nome da competição'
            rules={[{ required: true }]}
          >
            <Input {...FIELD_PROPS} placeholder='Power Tonus' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            name='description'
            label='Descrição'
            rules={[{ required: true }]}
          >
            <Input {...FIELD_PROPS} placeholder='Grupo dedicado ao conteúdo' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={1}>
          <CompetitionTypeInput
            {...FIELD_PROPS}
            label='Tipo de Competição'
            name='competitionTypeId'
            required
          />
        </Col>

        <Col xs={24} lg={8}>
          <SegmentInput
            {...FIELD_PROPS}
            label='Segmento'
            name='segmentId'
            required
          />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={24} md={12}>
          <Form.Item
            name='startDate'
            label='Vigência'
            rules={[{ required: true }]}
          >
            <DatePicker {...FIELD_PROPS} {...DATE_PROPS} data-testid='from' />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item name='endDate' label='Até'>
            <DatePicker {...FIELD_PROPS} {...DATE_PROPS} data-testid='to' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col style={{ marginRight: 20 }}>
          <Form.Item name='enableRanking' label='Habilitar Ranking?'>
            <Switch
              {...FIELD_PROPS}
              {...SWITCH_PROPS}
              defaultChecked={initialValues?.enableRanking}
              data-testid='ranking'
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            name='status'
            label='Status'
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <Switch
              {...FIELD_PROPS}
              {...SWITCH_PROPS}
              defaultChecked={initialValues?.status}
              data-testid='status'
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GameficationCompetitionFormStep1;
