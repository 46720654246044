import { UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller as OrganismController } from '@organisms';
import { auth } from '@service/api';
import { Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object, SchemaOf, string } from 'yup';

const validationSchema: SchemaOf<auth.Credentials> = object({
  email: string().email().required('Por favor preencha o campo de E-mail'),
  password: string().required('Por favor preencha este o campo de Senha'),
}).defined();

export interface CredentialFormProps {
  onSubmitted: (credentials: auth.Credentials) => void;
  children: React.ReactNode;
  onErrorForm?: (error: boolean) => void;
}
export const CredentialForm: React.FC<CredentialFormProps> = ({
  onSubmitted,
  children,
  onErrorForm,
}) => {
  const lastLoginEmail = useSelector(slices.auth.selectUserEmail || '');

  const { control, handleSubmit } = useForm<auth.Credentials>({
    resolver: yupResolver(validationSchema),
  });

  const handleFormError = (error: boolean) => {
    onErrorForm && onErrorForm(error);
  };

  return (
    <Form layout='vertical' onFinish={handleSubmit(onSubmitted)}>
      <OrganismController
        control={control}
        name='email'
        placeholder='E-mail'
        defaultValue={lastLoginEmail}
        autoComplete='off'
        onClick={() => handleFormError(false)}
        prefix={<UserOutlined style={{ color: '#177DDC' }} />}
      />

      <OrganismController
        control={control}
        name='password'
        defaultValue=''
        type='password'
        placeholder='Senha'
        autoComplete='off'
        onClick={() => handleFormError(false)}
        prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
      />

      <Form.Item>{children}</Form.Item>
    </Form>
  );
};
