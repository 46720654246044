import { PageHeader } from '@organisms';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DeleteButtonComponent } from 'src/features/clients/components/DeleteButtonComponent';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';

import * as badgesApi from '../api/badgesApi';

export const BadgeDetails = () => {
  const [formData, setFormData] = useState<badgesApi.EventType>({
    description: '',
    id: 0,
    identifier: '',
    name: '',
    eventTypeId: 0,
  });
  interface UseParamsIdentifier {
    identifier: string;
  }
  const { identifier }: UseParamsIdentifier = useParams();
  const [enableForm, setEnableForm] = useState(true);

  const handleEditForm = (
    event: React.MouseEvent<HTMLElement>,
    isEdit: boolean
  ) => {
    event.preventDefault();
    setEnableForm(!enableForm);
    if (isEdit) {
      return updateEvent(formData);
    }
    return false;
  };

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    const res = await badgesApi.getEventById(identifier);
    setFormData(res);
  };

  const updateEvent = (data: badgesApi.EventType) => {
    return badgesApi.update(data);
  };

  const handleDeleteEvent = async () => {
    await badgesApi.deleteEvent(formData);
  };

  const handleChange = (evt: { target: { name: string; value: string } }) => {
    const value = evt.target.value;
    const name = evt.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={12}>
              <PageHeader
                backToPath={() => {
                  window.history.back();
                }}
                title={'Eventos'}
                subTitle={''}
              />
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <EditButtonComponent
                showEdit={enableForm}
                showSave={enableForm}
                showDiscard={!enableForm}
                handleEditButton={handleEditForm}
              />

              <DeleteButtonComponent handleDeleteButton={handleDeleteEvent} />
            </Col>
          </Row>
          <Form layout='horizontal'>
            <InputStyledFirst
              disabled={enableForm}
              bordered={false}
              addonBefore={<AddonBefore>Identificador</AddonBefore>}
              value={formData?.identifier}
              name='identifier'
              onChange={handleChange}
            />

            <InputStyledFirst
              disabled={enableForm}
              bordered={false}
              addonBefore={<AddonBefore>Nome do Evento</AddonBefore>}
              value={formData?.name}
              name='name'
              onChange={handleChange}
            />

            <InputStyled
              disabled={enableForm}
              bordered={false}
              addonBefore={<AddonBefore>Status</AddonBefore>}
              value='indisponível'
              defaultValue='indisponível'
              onChange={handleChange}
              name='status'
            />

            <InputStyled
              disabled={enableForm}
              bordered={false}
              addonBefore={<AddonBefore>Descricão</AddonBefore>}
              value={formData?.description}
              defaultValue='indisponível'
              onChange={handleChange}
              name='description'
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

const AddonBefore = styled.div`
  width: 30vw;
  text-align: left;
`;
const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

const InputStyledFirst = styled(Input)`
  border-top-color: rgb(67, 67, 67);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;
