import 'antd/dist/antd.dark.css';

import { store } from '@core/redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { setLocale } from 'yup';

import App from 'src/app/App';
import { initBrowserMocksIfDev } from 'src/mocks/mockUtils';

import yupBrazilianLocale from './app/utils/yupBrazilianLocale';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://4738c076878c46578e87dc0d4dfc15b1@o530768.ingest.sentry.io/4504129460109312',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.015,
});

async function startApplication() {
  setLocale(yupBrazilianLocale);

  await initBrowserMocksIfDev();

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

startApplication();

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
