import { DataResponseApiDto } from '@core/dto/data-response-api.dto';
import { enviroments } from '@infrastructure/enviroments';
import { Events } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function uploadBadgeImage(
  datasource: any
): Promise<DataResponseApiDto<Events.EventCreated>> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/badge/image/upload`,
    method: 'POST',
    data: datasource,
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
