import React from 'react';

import { useProductPhysicCoupon } from '../../../hooks/useProductPhysicCoupon';
import { FilterCouponsPhysic } from '../../../physic/FilterPhysic.coupons';
import { TableCouponPhysic } from '../../../physic/TablePhysic.coupons';

type CouponsPhysicalProductsProps = {
  onOpenModalCoupon: () => void;
};

export const CouponsPhysicalProducts: React.FC<CouponsPhysicalProductsProps> =
  ({ onOpenModalCoupon }) => {
    const {
      couponList,
      isLoadingData,
      handleChangePagination,
      handleFilterCoupon,
      handleOrderCoupon,
    } = useProductPhysicCoupon();

    return (
      <TableCouponPhysic
        onChangePagination={handleChangePagination}
        filterComponent={
          <FilterCouponsPhysic
            onAddCoupon={onOpenModalCoupon}
            onFilterCoupon={handleFilterCoupon}
          />
        }
        data={couponList}
        onChangeOrder={handleOrderCoupon}
        isLoading={isLoadingData}
      />
    );
  };
