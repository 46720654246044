/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';

import { offerApi } from '../apis/offerApis';
import { FormattedOffer, OffersByProduct } from '../interfaces/offersInterface';
import { offerTableFactory } from '../utils/offerTableFactory';

export default function useOfferPage() {
  const [tableData, setTableData] = useState<FormattedOffer[]>();
  const [activeOffer, setActiveOffer] = useState<FormattedOffer>();
  const [product, setProduct] = useState<OffersByProduct>();

  useEffect(() => {
    getConsults();
  }, []);

  function sortOffersByIdDescending(
    offers: FormattedOffer[]
  ): FormattedOffer[] {
    return offers.sort((a, b) => parseInt(b.id) - parseInt(a.id));
  }

  async function getConsults() {
    const product = new URL(window.location.href).searchParams.get('id');

    await offerApi.getOffers(product ?? '').then(async data => {
      const formattedOffers = offerTableFactory(data.offers);
      setProduct(data);
      defineActiveOffer(formattedOffers);
      setTableData(sortOffersByIdDescending(formattedOffers));
    });
  }

  function defineActiveOffer(dataOffer: FormattedOffer[]) {
    dataOffer?.map(offer => {
      !offer.endDate && setActiveOffer(offer);
    });
  }

  return [tableData, product, activeOffer] as [
    FormattedOffer[],
    OffersByProduct,
    FormattedOffer
  ];
}
