import { RootState, slices } from '@core/redux';
import { usePrograms } from '@hooks';
import { Events } from '@models';
import { Form, Input, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './EventDetailForm.style';

const STATUS_OPTIONS = [
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' },
];

interface EventDetailTemplateProps {
  formData: Events.EventEditingForm;
  enableForm: boolean;
  handleChange: (name: string, value: any) => void;
}
export const EventDetailForm: React.FC<EventDetailTemplateProps> = ({
  formData,
  enableForm,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const { eventTypeList } = useSelector((state: RootState) => state.events);

  const { getProgramsList, programsOptions, programsWithModulesOption } =
    usePrograms();

  const [choosedTypeEvent, setChoosedTypeEvent] = React.useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    dispatch(slices.events.getEventTypeList());
    getProgramsList();
  }, []);

  useEffect(() => {
    setChoosedTypeEvent(formData?.eventTypeId);
  }, [formData]);

  const handleEventType = (field: string, event: number) => {
    handleChange('programs', undefined);
    setChoosedTypeEvent(event);
    handleChange(field, event);
  };

  const getCurrentProgramSelect = (eventProgramId: number) => {
    let currentProgramContent: any = [];
    let currentTitle = '';

    if (eventProgramId === 1) {
      return null;
    }

    if (eventProgramId === 2) {
      if (!programsWithModulesOption?.length) {
        return null;
      }
      currentTitle = 'Programas e aulas';
      currentProgramContent = [...programsWithModulesOption];
    }

    if (eventProgramId === 3) {
      if (!programsOptions?.length) {
        return null;
      }
      currentTitle = 'Programas';
      currentProgramContent = [...programsOptions];
    }
    return (
      <span className='ant-input-group-wrapper'>
        <span className='ant-input-wrapper ant-input-group'>
          <span
            className='ant-input-group-addon'
            style={{
              borderBottomColor: 'rgb(67, 67, 67)',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderRadius: 0,
              borderRight: 'none',
            }}
          >
            <S.AddonBefore>{currentTitle}</S.AddonBefore>
          </span>

          <S.SelectStyled
            disabled={enableForm}
            allowClear={false}
            mode='multiple'
            showSearch
            placeholder='Nenhum programa selecionado'
            style={{ width: '100%' }}
            onChange={(e: any) => handleChange('programs', e)}
            value={formData.programs}
            options={currentProgramContent}
            filterOption={(input, option) =>
              ((option?.label as string) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </span>
      </span>
    );
  };

  return (
    <Form layout='horizontal'>
      <div style={{ marginBottom: 20 }}>
        <S.InputStyledFirst
          disabled
          bordered={false}
          addonBefore={<S.AddonBefore>Identificador</S.AddonBefore>}
          value={formData?.identifier}
          name='identifier'
          onChange={evt => handleChange('identifier', evt.target.value)}
        />

        <S.InputStyledFirst
          disabled={enableForm}
          bordered={false}
          addonBefore={<S.AddonBefore>Nome do Evento</S.AddonBefore>}
          value={formData?.name}
          name='name'
          onChange={evt => handleChange('name', evt.target.value)}
        />

        <span className='ant-input-group-wrapper'>
          <span className='ant-input-wrapper ant-input-group'>
            <span
              className='ant-input-group-addon'
              style={{
                borderBottomColor: 'rgb(67, 67, 67)',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderRadius: 0,
                borderRight: 'none',
              }}
            >
              <S.AddonBefore>Tipo do evento</S.AddonBefore>
            </span>

            <S.SelectStyled
              allowClear={false}
              disabled={enableForm}
              style={{ width: '100%' }}
              onSelect={(value: any) => handleEventType('eventTypeId', value)}
              value={choosedTypeEvent}
              options={eventTypeList?.map(eventType => ({
                value: eventType.id,
                label: eventType.name,
              }))}
            />
          </span>
        </span>

        {!!choosedTypeEvent && getCurrentProgramSelect(+choosedTypeEvent)}

        {enableForm ? (
          <S.InputStyled
            disabled={enableForm}
            bordered={false}
            addonBefore={<S.AddonBefore>Status</S.AddonBefore>}
            value={!!formData?.statusActivated ? 'Ativo' : 'Inativo'}
            defaultValue={!!formData?.statusActivated ? 'Ativo' : 'Inativo'}
            name='statusActivated'
          />
        ) : (
          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: 'rgb(67, 67, 67)',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderRadius: 0,
                  borderRight: 'none',
                }}
              >
                <S.AddonBefore>Status</S.AddonBefore>
              </span>

              <S.SelectStyled
                allowClear={false}
                placeholder='Tipo do evento'
                style={{ width: '100%' }}
                onSelect={(value: any) =>
                  handleChange('statusActivated', !!+value)
                }
                value={!!formData?.statusActivated ? 'Ativo' : 'Inativo'}
                options={STATUS_OPTIONS}
                defaultValue={!!formData?.statusActivated ? 'Ativo' : 'Inativo'}
              />
            </span>
          </span>
        )}

        <S.InputStyled
          disabled={enableForm}
          bordered={false}
          addonBefore={<S.AddonBefore>Descrição</S.AddonBefore>}
          value={formData?.description}
          defaultValue='indisponível'
          onChange={evt => handleChange('description', evt.target.value)}
          name='description'
        />
      </div>
      <S.Label style={{ marginTop: 40 }}>Idiomas</S.Label>
      <Tabs>
        <Tabs.TabPane tab='PT' key='1'>
          <S.Label>Descrição (PT)</S.Label>
          <Input.TextArea
            value={formData.description_pt || ''}
            disabled={enableForm}
            rows={3}
            placeholder='Os pontos são computados sempre que o usuário acessar um grupo colaborativo'
            name='description_pt'
            onChange={evt => handleChange('description_pt', evt.target.value)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='ES' key='2'>
          <S.Label>Descrição (ES)</S.Label>
          <Input.TextArea
            value={formData.description_es || ''}
            disabled={enableForm}
            rows={3}
            placeholder='Los puntos se calculan cada vez que el usuario accede a un grupo colaborativo'
            name='description_es'
            onChange={evt => handleChange('description_es', evt.target.value)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='EN' key='3'>
          <S.Label>Descrição (EN)</S.Label>
          <Input.TextArea
            value={formData.description_en || ''}
            disabled={enableForm}
            rows={3}
            placeholder='Points are computed whenever the user accesses a collaborative grou'
            name='description_en'
            onChange={evt => handleChange('description_en', evt.target.value)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default Events;
