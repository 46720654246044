import { base } from '@service/base';

import { CouponDigital } from 'src/models';

export type TEditDigitalCouponProps = {
  id: number;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: CouponDigital.PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  destinationOfferId: number;
  maxUse: number;
  maxUsePerProduct: number;
  discount: {
    type: string;
    value: number;
  };
  maxUsePerCustomer: number;
  active: boolean;
};

export async function editDigitalCoupon(data: TEditDigitalCouponProps) {
  const response = await base.put(`/coupon`, data);

  return response.data;
}
