import { Col, Divider, Row, Table } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import {
  dateFormatted,
  getDifferenceInDaysFromTwoDates,
} from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import {
  HotmartSubscriptionItemResponse,
  HotmartTransactionItemResponse,
} from 'src/features/clients/types/clientTypes';

export type TableProps = {
  assignHotmart?: HotmartSubscriptionItemResponse[];
  transactionsHotmart?: HotmartTransactionItemResponse[];
};

export const HotmartTableComponent: FC<TableProps> = ({
  assignHotmart,
  transactionsHotmart,
}) => {
  const columnAssinatura = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Início (UTC)',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: string) => (
        <div>{dateFormatted(startDate, true)}</div>
      ),
    },
    {
      title: 'Fim (UTC)',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: string) => <div>{dateFormatted(endDate, true)}</div>,
    },
    {
      title: 'Dias de assinatura',
      dataIndex: 'diasRestantes',
      render: (startDate: string, record: HotmartSubscriptionItemResponse) => (
        <div>
          {getDifferenceInDaysFromTwoDates(record.startDate, record.endDate)}
        </div>
      ),
    },
    {
      title: 'Recorrências',
      dataIndex: 'recurrency',
      key: 'recurrency',
    },
    {
      title: 'Cartão',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
  ];

  const columnTransacao = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Transação',
      dataIndex: 'transaction',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Tipo',
      dataIndex: 'paymentType',
    },
    {
      title: 'Programa',
      dataIndex: 'prodName',
    },
    {
      title: 'Valor',
      dataIndex: 'fullPrice',
      render: (fullPrice: number) => <>{convertToCurrency(fullPrice)}</>,
    },
    {
      title: 'Criada em',
      dataIndex: 'createdAt',
      render: (createdAt: string) => (
        <div>{dateFormatted(createdAt, true)}</div>
      ),
    },
  ];

  if (!assignHotmart?.length && !transactionsHotmart?.length) {
    return <></>;
  }

  return (
    <>
      <Row>
        <h1 style={{ fontSize: 20 }}>Hotmart</h1>
      </Row>
      <Divider style={{ border: 'none', margin: 0 }}></Divider>
      {!!assignHotmart?.length && (
        <Col span={24}>
          <TitleTable>Assinaturas</TitleTable>
          <Table
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={columnAssinatura}
            dataSource={assignHotmart}
          />
        </Col>
      )}
      {!!transactionsHotmart?.length && (
        <Col span={24}>
          <TitleTable>Transações</TitleTable>
          <Table
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={columnTransacao}
            dataSource={transactionsHotmart}
          />
        </Col>
      )}
    </>
  );
};

const TitleTable = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #dbdbdb;
`;
