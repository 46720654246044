import { Col, Empty, Row, Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type EmptyStateProps = {
  image?: React.ReactNode;
  title?: string;
  subtitle?: string;
  type?: BaseType;
} & React.ComponentProps<typeof Empty>;

const { Text, Title } = Typography;
export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
  image,
  type = 'secondary',
  ...rest
}) => {
  const customDescription = useMemo(() => {
    if (!title && !subtitle) return null;

    return (
      <Row justify={'center'}>
        <Col>
          {title && (
            <Title type={type} level={4} style={{ margin: 0 }}>
              {title}
            </Title>
          )}
          {subtitle && <Text type={type}>{subtitle}</Text>}
        </Col>
      </Row>
    );
  }, [title, subtitle, type]);

  return (
    <CustomEmpty
      image={image ?? Empty.PRESENTED_IMAGE_SIMPLE}
      description={customDescription}
      {...rest}
    />
  );
};

const CustomEmpty = styled(Empty)`
  margin: 0;
`;
