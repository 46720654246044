import { FC } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import BadgesGroupsList from '../components/BadgesGroupsList';

export const BadgeGroupListScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Social',
      route: '/social/dashboard',
    },
    {
      label: 'Gamefication',
      route: '/social/dashboard',
    },
    {
      label: 'Badges',
      route: '/social/gamefication/badges/groups',
    },
    {
      label: 'Groups',
      route: '/social/gamefication/badges/groups',
    },
  ]);
  return (
    <>
      <BadgesGroupsList />
    </>
  );
};
