import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { auth, events, gamefication, layout, segments } from './slices';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  layout: layout.reducer,
  gamification: gamefication.reducer,
  segments: segments.reducer,
  events: events.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function createApplicationStore() {
  return configureStore({
    devTools: { name: 'Admin Next' },
    reducer: rootReducer,
  });
}

export const store = createApplicationStore();
