import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { downloadCsv } from 'src/features/Lgpd/utils/downloadCsv';

type UserModalProps = {
  requester?: string;
  date?: string;
  name?: string;
  cpf?: string;
  email?: string;
  linkCsv?: string;
};

export const ModalAnonymizedData: FC<UserModalProps> = ({
  requester,
  date,
  linkCsv,
  name,
  email,
  cpf,
}) => {
  return (
    <>
      <Alert
        message='Anonimização realizada com sucesso!'
        type='success'
        showIcon
        style={{ marginBlockEnd: '24px' }}
      />
      <Row style={{ flexDirection: 'column' }}>
        <Title>Nome</Title>
        <Text>{name}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />

      <Row style={{ flexDirection: 'column' }}>
        <Title>Email</Title>
        <Text>{email}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />

      <Row style={{ flexDirection: 'column' }}>
        <Title>CPF</Title>
        <Text>{cpf}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />
      <Row style={{ flexDirection: 'column' }}>
        <Title>Data da solicitação</Title>
        <Text>{date}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />
      <Row style={{ flexDirection: 'column' }}>
        <Title>Baixe o arquivo CSV com os dados completos</Title>
        <Button
          onClick={() => {
            downloadCsv(linkCsv);
          }}
          style={{
            maxWidth: '165px',
            marginBlockStart: '8px',
            background: '#177DDC',
            color: '#FFFFFF',
          }}
        >
          <DownloadOutlined />
          Baixar arquivo CSV
        </Button>
      </Row>
    </>
  );
};

const Title = styled.h1`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: '#DBDBDB';

}`;

const Text = styled.h1`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ACACAC;

}`;
