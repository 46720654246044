import { useSegments } from '@hooks';
import { Segments } from '@models';
import { LayoutConfirmationFooter, OrderTableSegmens } from '@molecules';
import { PageHeader } from '@organisms';
import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import * as S from './SegmentsOrdering.style';

const sortForBanner = (
  data: Segments.SegmentsDto[]
): Segments.SegmentsDto[] => {
  const newData = [...data];
  return newData.sort((a, b) => {
    if (!a.orderingForBanners?.length) return 1;
    if (!b.orderingForBanners?.length) return -1;

    return a.orderingForBanners[0].order - b.orderingForBanners[0].order;
  });
};

const configureForBanner = (
  data: Segments.SegmentsDto[]
): Segments.SegmentsDto[] => {
  const newData = [...data];
  return newData.map(data => ({
    ...data,
    isNew: !data.orderingForBanners?.length,
  }));
};

export const SegmentsOrdering: React.FC = () => {
  const [currentCollapse, setCurrentCollapse] = useState('');
  const [listReadyToShow, setListReadyToShow] =
    useState<Segments.SegmentsDto[]>();
  const [listWithOrderChanged, setListWithOrderChanged] =
    useState<Segments.SegmentsDto[]>();

  useBreadcrumbs([
    {
      label: 'Ordenação de banners',
    },
  ]);

  const { segmentsList, getSegmentsList, saveOrder } = useSegments();
  const history = useHistory();

  useEffect(() => {
    getSegmentsList({ take: 1000, skip: 0 });
  }, []);

  useEffect(() => {
    switch (currentCollapse) {
      case 'BANNERS':
        setListReadyToShow(
          configureForBanner(sortForBanner(segmentsList?.rows || []))
        );
        break;

      case 'ASSETS':
        setListReadyToShow(sortForBanner(segmentsList?.rows || []));
        break;

      default:
        break;
    }
  }, [currentCollapse, segmentsList]);
  return (
    <LayoutConfirmationFooter
      ableToSave={!!listWithOrderChanged?.length}
      onSave={() => saveOrder(listWithOrderChanged || [])}
      onCancel={() => history.push('/dashboard')}
    >
      <PageHeader
        backToPath='/dashboard'
        title='Ordenar Segmentos'
        subTitle='Ordene os Segmentos dentro de cada módulo de acordo com a sua prioridade.'
      />

      <S.CollapseContainer>
        <Collapse
          accordion
          onChange={key => setCurrentCollapse(key as string)}
          activeKey={currentCollapse}
        >
          <Collapse.Panel header='Banners' key='BANNERS'>
            {!!listReadyToShow?.length && (
              <OrderTableSegmens
                columnTitle='Banners'
                changeOrder={newOrder =>
                  setListWithOrderChanged(newOrder as Segments.SegmentsDto[])
                }
                datakey='name'
                datasource={listReadyToShow || []}
                showHeader={false}
              />
            )}
          </Collapse.Panel>
        </Collapse>
      </S.CollapseContainer>
    </LayoutConfirmationFooter>
  );
};
