export const IphoneSvg = () => (
  <svg
    width='48'
    height='49'
    viewBox='0 0 48 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13 46.5C12.2 46.5 11.5 46.2 10.9 45.6C10.3 45 10 44.3 10 43.5V5.5C10 4.7 10.3 4 10.9 3.4C11.5 2.8 12.2 2.5 13 2.5H35C35.8 2.5 36.5 2.8 37.1 3.4C37.7 4 38 4.7 38 5.5V43.5C38 44.3 37.7 45 37.1 45.6C36.5 46.2 35.8 46.5 35 46.5H13ZM13 39V43.5H35V39H13ZM24.0087 42.75C24.4362 42.75 24.7917 42.6054 25.075 42.3162C25.3583 42.0271 25.5 41.6687 25.5 41.2413C25.5 40.8138 25.3554 40.4583 25.0663 40.175C24.7771 39.8917 24.4187 39.75 23.9913 39.75C23.5638 39.75 23.2083 39.8946 22.925 40.1838C22.6417 40.4729 22.5 40.8313 22.5 41.2588C22.5 41.6862 22.6446 42.0417 22.9337 42.325C23.2229 42.6083 23.5813 42.75 24.0087 42.75ZM13 36H35V10H13V36ZM13 7H35V5.5H13V7Z'
      fill='white'
    />
  </svg>
);
