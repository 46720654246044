import { StepProps, Steps } from 'antd';

import { useCreateOrderBump } from '../../hooks/useCreateOrderBump';
import { CREATE_ORDER_BUMP_STEPS } from '../../providers/CreateOrderBumpProvider';

type TStatus = 'finish' | 'process' | 'wait' | 'error';

export const StepperCreateOrderBump = () => {
  const {
    step: { setter, value },
    isEditing,
  } = useCreateOrderBump();

  const getStatusStep = (propStep: number) => {
    if (isEditing) {
      return 'finish';
    }

    if (value > propStep) {
      return 'finish';
    }

    if (value === propStep) {
      return 'process';
    }

    if (value < propStep) {
      return 'wait';
    }
  };

  const items: StepProps[] = CREATE_ORDER_BUMP_STEPS.map((title, index) => {
    const status = getStatusStep(index) as TStatus;
    return {
      title,
      status,
      disabled: status === 'wait',
    };
  });

  return (
    <Steps
      type='navigation'
      size='small'
      current={value}
      onChange={setter}
      className='site-navigation-steps'
      items={items}
    />
  );
};
