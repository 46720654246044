import * as S from './Default.style';

const Default = () => {
  return (
    <S.ModalModelSpace>
      <S.ModalModelTitleSkeleton />
      <S.ModalModelDescriptionSkeleton />
      <S.ModalModelDescriptionSkeleton />
      <S.ModalModelTextboxSkeleton />

      <S.ModalModelFooterContainerSkeleton>
        <S.ModalModelFooterLeftContainerSkeleton>
          <S.ModalModelFooterCircleSkeleton />
          <S.ModalModelFooterLineSkeleton />
        </S.ModalModelFooterLeftContainerSkeleton>

        <S.ModalModelFooterRightContainerSkeleton>
          <S.ModalModelButtonSkeleton />
        </S.ModalModelFooterRightContainerSkeleton>
      </S.ModalModelFooterContainerSkeleton>
    </S.ModalModelSpace>
  );
};
export default Default;
