import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { QuestionPayload } from '../../types/questionPageTypings';

export function useTryToRequestCreatingQuestionFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: QuestionPayload) => {
      return await apiCancellationFunnels.tryToRequestCreatingQuestionFunnelPage(
        payload
      );
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao crir o funil de Perguntas',
          'Ocorreu um erro ao criar o funil de Perguntas, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return { tryToRequestCreatingQuestion: mutateAsync, isLoading };
}
