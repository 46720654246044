import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Tooltip } from 'antd';
import React from 'react';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { Created, Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import {
  ClientType,
  CommentType,
  CommentTypeResponse,
} from 'src/features/clients/types/clientTypes';

const { TextArea } = Input;

export type ClientCommentsProps = {
  dados?: ClientType;
};

export const ClientCommentsTab: FC<ClientCommentsProps> = ({ dados }) => {
  const [comment, setComment] = useState<string>();
  const queryClient = useQueryClient();

  const fetchComments = async (): Promise<CommentType[]> => {
    const response = await clientApi.getComments(dados?.id);
    if ((response as CommentTypeResponse).status === Success) {
      return (response as CommentTypeResponse).data;
    } else {
      OpenNotification(true, 'Erro ao obter os comentarios', '');
    }
    return [];
  };

  const commentsResponse = useQuery('comments', fetchComments);

  const comments = (item: CommentType) => {
    return (
      <Col md={12} xs={24} lg={12} sm={24}>
        <div style={{ marginTop: 10 }}>
          <Card
            extra={
              <>
                <Tooltip title='Excluir comentário'>
                  <DeleteOutlined
                    data-testid={`delete-comment-${item.id}`}
                    onClick={() => handleClickDeleteComment(item)}
                    style={{
                      color: '#177DDC',
                      cursor: 'pointer',
                      float: 'right',
                      fontSize: 18,
                      display: 'none',
                    }}
                  />
                </Tooltip>
              </>
            }
            style={{ border: 'none' }}
            title={
              <>
                <div
                  style={{
                    display: 'inline-flex',
                    marginLeft: 10,
                    color: '#7D7D7D',
                    fontSize: 12,
                  }}
                >
                  Criado em {dateFormatted(item.createdAt, false)}
                </div>
              </>
            }
            bordered={false}
          >
            <p>{item.annotation}</p>
          </Card>
        </div>
      </Col>
    );
  };

  const [sendCommentisLoading, setsendCommentisLoading] =
    useState<boolean>(false);
  const handleClickSendComment = async () => {
    setsendCommentisLoading(true);
    const responsePostComment = await clientApi.postComments({
      annotation: comment,
      userId: dados?.id,
    });
    if ((responsePostComment as CommentTypeResponse).status === Created) {
      queryClient.invalidateQueries('comments');
      setComment('');
      OpenNotification(false, 'Comentário adicionado com sucesso!', '');
    } else {
      OpenNotification(true, 'Erro ao adicionar comentário', '');
    }
    setsendCommentisLoading(false);
  };

  const handleClickDeleteComment = async (item: CommentType) => {
    const response = await clientApi.deleteComments(item.id);

    if ((response as CommentTypeResponse).status === Success) {
      queryClient.invalidateQueries('comments');
      return (response as CommentTypeResponse).data;
    } else {
      return OpenNotification(true, 'Erro ao deletar comentário', '');
    }
  };

  return (
    <>
      <Col md={12} xs={24} lg={12} sm={24}>
        <TextArea
          onChange={e => setComment(e.target.value)}
          value={comment}
          rows={3}
          showCount
          data-testid='input'
        />
        <Button
          style={{ marginTop: 10, display: 'block' }}
          type='primary'
          onClick={handleClickSendComment}
          disabled={!comment}
          data-testid='addComments'
          loading={sendCommentisLoading}
        >
          Adicionar comentário
        </Button>
      </Col>

      <Divider />
      {commentsResponse.data &&
        React.Children.toArray(
          commentsResponse.data.map((item: CommentType) => {
            return comments(item);
          })
        )}
    </>
  );
};
