import { Button } from 'antd';
import React from 'react';

import * as S from './Footer.style';

interface ModalFooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  allowContinue: boolean;
  buttonFormId?: string;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({
  onCancel,
  onSubmit,
  allowContinue,
  buttonFormId,
}) => {
  return (
    <S.Footer>
      <S.ButtonContainer>
        <S.CancelButtonContainer>
          <Button type='text' onClick={onCancel}>
            Cancelar
          </Button>
        </S.CancelButtonContainer>

        <S.ContinueButtonContainer>
          <Button
            disabled={!allowContinue}
            form={buttonFormId}
            type='primary'
            htmlType='submit'
            onClick={onSubmit}
          >
            Continuar
          </Button>
        </S.ContinueButtonContainer>
      </S.ButtonContainer>
    </S.Footer>
  );
};
