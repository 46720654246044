import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { ClientFilterParams, ResponseClient } from '../types/clientTypes';

function buildUri(paramUri: ClientFilterParams) {
  if (paramUri.id) {
    return `?id=${paramUri.id}`;
  }
  let url = `?limit=20&page=${paramUri.page}`;
  if (paramUri.filter) {
    url += `&text=${paramUri.filter}`;
  }
  if (paramUri.initialDate && paramUri.endDate) {
    url += `&firstDate=${paramUri.initialDate.toJSON()}&lastDate=${paramUri.endDate.toJSON()}`;
  }

  return url;
}

const fetchUsers = async (
  key: string,
  pageParam: number,
  initialDateParam?: Date,
  endDateParam?: Date,
  idParam?: number,
  filterParam?: string
) => {
  const filterParams: ClientFilterParams = {
    filter: filterParam,
    endDate: endDateParam,
    initialDate: initialDateParam,
    id: idParam,
    page: pageParam,
  };

  const responseCient = await clientApi.listCustomers(buildUri(filterParams));

  if ((responseCient as ResponseClient).status === Success) {
    return (responseCient as ResponseClient).data;
  } else {
    return OpenNotification(
      true,
      'Erro ao carregar clientes',
      'Ocorreu um erro ao carregar os clientes, por favor tente novamente mais tarde.'
    );
  }
};

export default function useGetAllUsers(
  page: number,
  initialDate?: Date,
  endDate?: Date,
  id?: number,
  filter?: string
) {
  return useQuery(
    ['users', page, initialDate, endDate, id, filter],
    () => fetchUsers('users', page, initialDate, endDate, id, filter),
    { refetchOnWindowFocus: false }
  );
}
