import { notification } from 'antd';

const handleFinishNotification = (onFinishNotification?: () => void) => {
  if (onFinishNotification) onFinishNotification();
};

export default function OpenNotification(
  isError: boolean,
  message: string,
  description?: string,
  type?: 'error' | 'success' | 'info' | 'warning',
  onFinishNotification?: () => void
) {
  if (isError) {
    return notification.open({
      message,
      description,
      type: 'error',
      onClick: () => {
        notification.destroy();
        handleFinishNotification(onFinishNotification);
      },
      onClose: () => {
        handleFinishNotification(onFinishNotification);
      },
    });
  }

  return notification.open({
    message,
    description,
    type: type || 'success',
    onClick: () => {
      notification.destroy();
      handleFinishNotification(onFinishNotification);
    },
    onClose: () => {
      handleFinishNotification(onFinishNotification);
    },
  });
}
