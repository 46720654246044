import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  height: 100%;

  width: 100%;
  padding-right: 120px;
  background-color: rgba(31, 31, 31, 1);
`;

export const CancelButton = styled.div``;
export const ConfirmButton = styled.div``;

export const ChildrenContainer = styled.div`
  flex: 1;
`;

export const PaddingOverride = styled.div`
  height: 64px;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;
