// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { Modal } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

async function putModal(
  id: string,
  props: Omit<Modal.IModal, 'id'>
): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/modal/${id}`,
    method: 'PATCH',
    data: {
      ...props,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export default putModal;
