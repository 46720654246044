import {
  FunctionalityFormData,
  FunctionalityPayload,
  FunctionalityResponse,
} from '../types/functionalityTypings';
import { uploadS3 } from './uploadS3';

export const functionalityPayloadFactory = async (
  payload: FunctionalityFormData
) => {
  const factory: FunctionalityPayload = {
    cancellationFunnelId: Number(payload.cancellationFunnelId),
    pageType: 'functionality',
    name: payload.name,
    content: {
      slides: await Promise.all(
        payload.slides.map(async slide => ({
          title: slide.title,
          slideTitle: slide.slideTitle,
          imageWeb:
            typeof slide.web === 'string'
              ? slide.web
              : await uploadS3(slide.web),
          imageMobile:
            typeof slide.mobile === 'string'
              ? slide.mobile
              : await uploadS3(slide.mobile),
          description: slide.description,
          buttonName: slide.buttonTitle,
          buttonLink: slide.buttonLink,
          isTargetBlank: slide.isTarget,
        }))
      ),
      continueCancelationButtonTitle:
        payload.continueCancelationButtonTitle || null,
      keepSubscriptionButtonTitle: payload.keepSubscriptionButtonTitle || null,
    },
  };

  return factory;
};

export const functionalityResponseFactory = (
  response: FunctionalityResponse
) => {
  const factory = {
    cancellationFunnelId: String(response.cancellationFunnelId),
    name: response.name,
    slides: response.content.slides.map(slide => ({
      title: slide.title,
      buttonLink: slide.buttonLink,
      buttonTitle: slide.buttonName,
      description: slide.description,
      isTarget: slide.isTargetBlank,
      mobile: slide.imageMobile,
      web: slide.imageWeb,
      slideTitle: slide.slideTitle,
    })),
    continueCancelationButtonTitle:
      response.content.continueCancelationButtonTitle,
    keepSubscriptionButtonTitle: response.content.keepSubscriptionButtonTitle,
  };

  return factory;
};
