import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin-right: 16px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

export const IconContainer = styled.div`
  font-size: 17px;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
`;

export const SubTitleContainer = styled.div`
  margin-left: 32px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgba(255, 255, 255, 0.45);
`;

export const ExtraContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
`;
