import { slices } from '@core/redux';
import { Segments } from '@models';
import { segments } from '@service/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import OpenNotification from '../components/antd/OpenNotification';

export const useSegmentsDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [segmentDetail, setSegmentDetail] = useState<Segments.SegmentsDto>();

  const getSegmentDetail = async (segmentId: string) => {
    try {
      if (!segmentId) {
        setSegmentDetail({
          name: '',
          description: '',
          type: '',
          country: '',
          dns: '',
          language: '',
          show_quiz: false,
          show_recommended_programs: false,
          created_at: '',
          updated_at: '',
          segments_data: '',
          activated: true,
        });
        return;
      }
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getSegmentById(+segmentId);
      setSegmentDetail(response);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const saveChanges = async () => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (!segmentDetail) {
        OpenNotification(true, 'Não existe dados a serem salvos');
        return;
      }

      if (!segmentDetail.id) {
        const response = await segments.postNewSegment({
          name: segmentDetail.name,
          description: segmentDetail.description,
          type: segmentDetail.type as Segments.TypeEnum,
          activated: segmentDetail.activated,
        });
        OpenNotification(false, 'Segmento criado com sucesso');
        history.push('/screens/segments/detalhes', { id: response.id });
        return;
      }
      await segments.putSegment(segmentDetail);
      OpenNotification(false, 'Segmento editado com sucesso');
    } catch (error) {
      OpenNotification(true, 'Erro ao salvar dados do segmento');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const changeSegmentDetail = async (field: string, value: any) => {
    setSegmentDetail(
      prevState =>
        ({
          ...prevState,
          [field]: value,
        } as Segments.SegmentsDto)
    );
  };

  return {
    getSegmentDetail,
    segmentDetail,

    changeSegmentDetail,
    saveChanges,
  };
};
