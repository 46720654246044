export function formatDate(date?: string) {
  if (date) {
    const newDate = new Date(date);

    const day = newDate.getUTCDate().toString().padStart(2, '0');
    const month = (newDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getUTCFullYear().toString();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  } else return '-';
}
