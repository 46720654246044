import { useUpsellStepFlow } from '@hooks';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { Spin } from 'antd';
import React from 'react';

import { ConfirmModal } from '../../components/ConfirmModal';
import { FlowSteps } from '../../components/FlowSteps';
import { ManageEdgeModal } from '../../components/ManageEdgeModal';
import { ManageStepModal } from '../../components/ManageStepModal';
import { TableFlowSteps } from '../../components/TableFlowSteps';

export const UpsellStepFlow: React.FC = () => {
  const {
    upsellFlow,
    allFlowSteps,
    isLoading,
    createStepModal,
    onOpenStepCreateModal,
    manageStepModal,
    onOpenManageStepModal,
    addChangeStep,
    goBack,
    saveUpdatesPage,
    onOpenModalDeleteStep,
    deleteStepModal,
    onOpenModalEditStep,
  } = useUpsellStepFlow();

  return (
    <LayoutConfirmationFooter
      onCancel={goBack}
      onSave={saveUpdatesPage}
      isLoadingSave={isLoading}
      cancelLabelButton='Cancelar'
      ableToSave={!isLoading}
      continueLabelButton={'Aplicar'}
    >
      {!upsellFlow || isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <>
          <PageHeader
            backToPath={() => {
              window.history.back();
            }}
            title={upsellFlow.name}
            subTitle={'Gerencie, edite e crie novas etapas de Funil.'}
          />
          <FlowSteps
            steps={allFlowSteps}
            onChangeStep={addChangeStep}
            onCreateEdge={onOpenManageStepModal}
            onCreateStep={onOpenStepCreateModal}
            onDeleteStep={onOpenModalDeleteStep}
            onEditStep={onOpenModalEditStep}
          />
          <TableFlowSteps flowSteps={allFlowSteps} />

          {createStepModal && <ManageStepModal {...createStepModal} />}
          {manageStepModal && <ManageEdgeModal {...manageStepModal} />}
          {deleteStepModal && <ConfirmModal {...deleteStepModal} />}
        </>
      )}
    </LayoutConfirmationFooter>
  );
};
