import { Card, Col, Divider, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface AddCouponCardComponentProps {
  title: string;
  route: string;
  icon: React.ReactNode;
  disabled?: boolean;
}

export const AddCouponCardComponent = ({
  title,
  route,
  icon,
  disabled = false,
}: AddCouponCardComponentProps) => {
  const history = useHistory();
  return (
    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
      <Card
        bordered={false}
        style={{
          width: '100%',
          background: '#202020',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '12px',
          color: disabled ? '#333333' : 'inherit',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        bodyStyle={{ width: '100%' }}
        onClick={() => {
          if (!disabled) history.push(route);
        }}
      >
        <Row style={{ justifyContent: 'center', fontSize: '48px' }}>{icon}</Row>
        <Divider />
        <Text disabled={disabled}>{title}</Text>
      </Card>
    </Col>
  );
};

const Text = styled.p<{ disabled: boolean }>`
  color: ${p => (p.disabled ? '#333333' : '#fff')};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
