import { Button, Input, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Created } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { CustomerAdyenRefundApiResponseDto } from 'src/features/clients/types/clientTypes';

type ModalProps = {
  modalValues: {
    itemId: number;
    visible: boolean;
    amount: number;
    gatewayTransaction: string;
    document: string;
  };
  downloadReport: (reportIdentifier: string) => void;
  setIsModalVisible: () => void;
};

export const ModalRefund: FC<ModalProps> = ({
  modalValues,
  downloadReport,
  setIsModalVisible,
}) => {
  const [refundSubmit, setRefundSubmit] = useState<boolean>(false);
  const [refundTitle, setRefundTitle] = useState<string>('');
  const [refundError, setRefundError] = useState<string | undefined>();
  const [refundValue, setRefundValue] = useState<number>(0);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [cancelation, setCancelation] = useState<boolean>(false);

  const onSubmit = async (cancelationParams: boolean) => {
    setIsloading(true);
    const param = {
      gatewayTransaction: modalValues.gatewayTransaction,
      amount: parseFloat(refundValue.toString()),
      billingId: modalValues.itemId,
      cancellation: cancelationParams,
      document: modalValues.document,
    };

    const response = await clientApi.adyenRefund(param);

    if ((response as CustomerAdyenRefundApiResponseDto).status === Created) {
      setIsModalVisible();
      OpenNotification(false, 'Reembolso efetuado com sucesso!', '');

      setIsloading(false);
      setCancelation(false);
      queryClient.invalidateQueries('transactionsAdyen');
      queryClient.invalidateQueries('users');

      const responseData = (response as CustomerAdyenRefundApiResponseDto).data;
      if (responseData.reportIdentifier) {
        downloadReport(responseData.reportIdentifier);
      }
      return setRefundSubmit(false);
    } else {
      setIsloading(false);
      setCancelation(false);
      return OpenNotification(
        true,
        'Erro ao efetuar o reembolso',
        'Ocorreu um erro ao efetuar seu reembolso, por favor tente novamente mais tarde.'
      );
    }
  };

  useEffect(() => {
    setRefundTitle('Reembolso');
    setRefundValue(modalValues.amount);
  }, [modalValues]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeRefund = (e: any) => {
    setRefundValue(e.target.value);
    e.target.value > modalValues.amount
      ? setRefundError(
          `Não é possível reembolsar um valor maior que ${modalValues.amount}`
        )
      : setRefundError(undefined);
  };

  const isRefundButton = () => {
    if (!refundSubmit) {
      return (
        <>
          <Button
            key='back'
            data-testid='back'
            onClick={() => setIsModalVisible()}
          >
            Cancelar
          </Button>
          <Button
            key='submit'
            type='primary'
            data-testid='submit'
            loading={isLoading}
            onClick={() => {
              if (!refundError) {
                setRefundTitle('Deseja realizar este reembolso?');
                setRefundSubmit(true);
              }
            }}
          >
            Solicitar
          </Button>
        </>
      );
    }

    if (cancelation) {
      return (
        <>
          <Button
            key='not'
            data-testid='not'
            type='primary'
            loading={!cancelation && isLoading}
            onClick={() => {
              onSubmit(false);
            }}
          >
            Não
          </Button>
          <Button
            key='yes'
            data-testid='yes'
            loading={cancelation && isLoading}
            onClick={() => onSubmit(true)}
          >
            Sim
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          key='not'
          data-testid='notReembolso'
          loading={!cancelation && isLoading}
          onClick={() => {
            setRefundSubmit(false);
            setRefundTitle('Reembolso');
          }}
        >
          Não
        </Button>
        <Button
          key='yes'
          type='primary'
          data-testid='yesCancelamento'
          loading={cancelation && isLoading}
          onClick={() => {
            setCancelation(true);
            setRefundTitle('Cancelamento');
          }}
        >
          Sim
        </Button>
      </>
    );
  };

  const RefundModal = () => {
    return (
      <Modal
        title={refundTitle}
        visible={modalValues.visible}
        onCancel={setIsModalVisible}
        footer={[isRefundButton()]}
      >
        {!cancelation && (
          <>
            <Label>Valor do reembolso</Label>
            <Input
              placeholder='Digite o valor do reembolso'
              value={refundValue}
              data-testid='refundInput'
              onChange={onChangeRefund}
              disabled={refundSubmit}
            />
            {refundError ? <ErrorStyled>{refundError}</ErrorStyled> : ''}
          </>
        )}
        {cancelation && <div>{'Deseja cancelar a assinatura?'}</div>}
      </Modal>
    );
  };

  return <>{RefundModal()}</>;
};

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
`;
const ErrorStyled = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #ff0000;
`;
