import { Avatar, Button, Card, Divider } from 'antd';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
const { Meta } = Card;

export interface BasicCardProps {
  title: string;
  text: string;
  linkName1?: string;
  link1?: string;
  linkName2?: string;
  link2?: string;
  linkName3?: string;
  link3?: string;
  button?: string;
  buttonLink?: string;
  // eslint-disable-next-line
  icon?: React.ReactElement<any>;
}

export const BasicCard: FC<BasicCardProps> = ({
  title,
  text,
  linkName1,
  link1,
  linkName2,
  link2,
  linkName3,
  link3,
  button,
  buttonLink,
  icon,
  ...rest
}) => {
  const history = useHistory();

  return (
    <CardContainer>
      <CardStyled bordered={false}>
        <Meta
          avatar={
            <Avatar
              icon={icon}
              style={{
                backgroundColor: '#2b2b2b',
                borderRadius: 15,
                width: 40,
                height: 40,
                display: 'table-cell',
              }}
            />
          }
          title={title}
        />
        <Divider />
        <p>{text}</p>
        <Link to={link1 || `/admin`}>{linkName1}</Link>
        <br />
        <Link to={`${link2}`}>{linkName2}</Link>
        <br />
        <Link to={link3 || `/dashboard`}>{linkName3}</Link>
        {button && (
          <SubmitButton
            htmlType='button'
            type='default'
            disabled={!buttonLink}
            onClick={() => history.push(`${buttonLink}`)}
          >
            {button}
          </SubmitButton>
        )}
        {!button && <div style={{ height: 30 }}></div>}
      </CardStyled>
    </CardContainer>
  );
};

const CardStyled = styled(Card)`
  border-radius: 10px;
`;
const CardContainer = styled.div`
  padding: 10px;
  height: 275px;
`;
const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: inherit;
`;
