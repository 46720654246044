import { useEffect, useMemo, useState } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import {
  deleteFreezingHistory,
  getFreezingHistory,
} from '../apis/freezingApis';
import { FreezingHistory } from '../interfaces/frezzingInterfaces';

export default function useControllerTable(showModal: boolean) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<FreezingHistory[]>([]);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    getData();
  }, [showModal]);

  const filteredTableData = useMemo(() => {
    return tableData && tableData.length > 0
      ? tableData?.filter(data => {
          return (
            String(data.id).includes(filter) ||
            data.requestingUserMail?.includes(filter)
          );
        })
      : [];
  }, [tableData, filter]);

  function getData() {
    setIsLoading(true);
    getFreezingHistory()
      .then(data => {
        setTableData(data);
      })
      .catch(error => {
        setTableData([]);
        OpenNotification(true, 'Ocorreu um erro', `${error}`);
      })
      .finally(() => setIsLoading(false));
  }

  function deleteFreezing(id: number) {
    setIsLoading(true);
    deleteFreezingHistory(id)
      .then(() => {
        OpenNotification(
          false,
          `Agendamento excluído!`,
          `O agendamento ${id} foi excluído com sucesso`
        );
        getData();
      })
      .catch(error => {
        OpenNotification(true, 'Ocorreu um erro', `${error}`);
      })
      .finally(() => setIsLoading(false));
  }

  return {
    filter,
    setFilter,
    filteredTableData,
    deleteFreezing,
    isLoading,
  };
}
