import { IDefaultIdNameResponseDto } from '@core/dto/default-id-name-response.dto';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export const getCompetitiveEventTypeList = createAsyncThunk(
  'gamification/badgeGroups/getCompetitiveEventTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data }: DataResponseApiDto<IDefaultIdNameResponseDto[]> =
        await badgesGroupsApi.getCompetitionEventType();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompetitiveEventTypeListBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(getCompetitiveEventTypeList.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(
    getCompetitiveEventTypeList.fulfilled,
    (state, { payload }) => {
      state.status = 'FULFILLED';
      state.competitionEventTypeList = payload as IDefaultIdNameResponseDto[];
    }
  );

  builder.addCase(getCompetitiveEventTypeList.rejected, state => {
    state.status = 'ERROR';
  });
};
