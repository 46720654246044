import { createSlice } from '@reduxjs/toolkit';
import { uuid } from 'uuidv4';

import { createEvent } from './createEvent.builder';
import { deleteEvent } from './deleteEvent.builder';
import { EventsState, StatysType } from './events.interface';
import { getAllEventsBuilder } from './getAllEvents.builder';
import { getEventById } from './getEventById.builder';
import { getAllEventsTypeBuilder } from './getEventTypeList.builder';
import { updateEvent } from './updateEvent.builder';

const initialState: EventsState = {
  status: 'RESTORING' as StatysType,
  events: undefined,
  eventDetail: undefined,
  eventTypeList: undefined,
  errorDetails: undefined,
};

const eventsSlice = createSlice({
  initialState,
  name: 'Events',
  reducers: {
    clearEventDetail: state => {
      state.eventDetail = undefined;
    },
    newEvent: state => {
      state.eventDetail = {
        identifier: uuid(),
        name: '',
        description: '',
        eventTypeId: undefined,
        description_pt: '',
        description_es: '',
        description_en: '',
      };
    },
  },
  extraReducers(builder) {
    getAllEventsBuilder(builder);
    getAllEventsTypeBuilder(builder);
    getEventById(builder);
    createEvent(builder);
    updateEvent(builder);
    deleteEvent(builder);
  },
});
export const { clearEventDetail, newEvent } = eventsSlice.actions;
export const reducer = eventsSlice.reducer;
