import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { ClassViewedResponseDto } from 'src/features/clients/types/clientTypes';

const fetchClassViewed = async (emailParam?: string) => {
  const response = await clientApi.getClassViewed(emailParam);
  if (
    !isSerializedApiError(response) &&
    (response as ClassViewedResponseDto).status === Success
  ) {
    return (response as ClassViewedResponseDto).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as aulas visualizadas',
      'Ocorreu um erro  ao carregar as aulas visualizadas, por favor tente novamente mais tarde.'
    );
  }
};

export default function useClassViewed(email?: string) {
  return useQuery(['classViewed', email], () => fetchClassViewed(email), {
    refetchOnWindowFocus: false,
  });
}
