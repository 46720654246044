import { IDefaultIdNameResponseDto } from '@core/dto/default-id-name-response.dto';
import { RootState, slices } from '@core/redux';
import { Events } from '@models';
import { PageListProps, PageListTemplate } from '@templates';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface TableEventsProps {
  filterComponent: React.ReactNode;
}

export const TableEvents: React.FC<TableEventsProps> = ({
  filterComponent,
}) => {
  const { events, eventTypeList } = useSelector(
    (state: RootState) => state.events
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(slices.events.getAllEvents());
    dispatch(slices.events.getEventTypeList());
  }, []);

  const handleClickEditOption = (record: Events.ListEventsResponse) => {
    history.push({
      pathname: '/social/gamefication/events/edit',
      state: { id: record.identifier },
    });
  };

  const headerColumns: PageListProps<Events.ListEventsResponse>['columns'] = [
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      key: 'description',
      title: 'Descrição',
      dataIndex: 'description',
    },
    {
      key: 'eventType',
      title: 'Tipo',
      dataIndex: 'eventType',
      render: (eventType: IDefaultIdNameResponseDto) => {
        const type = eventTypeList?.find(item => item.id === eventType?.id);
        return <p>{type?.name || ''}</p>;
      },
    },

    {
      key: 'statusActivated',
      title: 'Status',
      dataIndex: 'statusActivated',
      render: (status?: boolean) => {
        return <p>{!status ? 'Inativo' : 'Ativo'}</p>;
      },
    },
    {
      key: 'identifier',
      title: 'Identificador',
      dataIndex: 'identifier',
    },
  ];

  const tableConfig = {
    columns: headerColumns,
    data: events ? [...events] : [],
  };

  return (
    <PageListTemplate
      onClickRow={handleClickEditOption}
      filterComponent={filterComponent}
      columns={tableConfig.columns}
      data={tableConfig.data}
      loading={false}
    />
  );
};
