import { base } from '@service/base';

import { CouponPhysic } from 'src/models';

export type TCreatePhysicCouponProps = {
  code: string;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: CouponPhysic.PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  discount: {
    type: CouponPhysic.TypeEnum;
    value: number;
  };
  maxUse: number;
  maxUsePerCustomer: number;
  active: boolean;
};

export async function createPhysicCoupon(data: TCreatePhysicCouponProps) {
  const response = await base.post('/sales/coupon', data);

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
