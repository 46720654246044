import { Card, Divider, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

export interface CardFunnelProps {
  icon: React.ReactNode;
  title: string;
  link: string;
}

export const CardFunnel: React.FC<CardFunnelProps> = ({
  icon,
  title,
  link,
}) => {
  return (
    <Row>
      <Card
        bordered={false}
        style={{
          width: 176,
          background: '#202020',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '12px',
          cursor: 'pointer',
        }}
        bodyStyle={{ width: '100%' }}
        onClick={() => (window.location.href = link)}
      >
        <Row style={{ justifyContent: 'center' }}>{icon}</Row>
        <Divider />
        <Text>{title}</Text>
      </Card>
    </Row>
  );
};

const Text = styled.p`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
