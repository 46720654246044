import { QuestionResponse } from '../types/questionPageTypings';

export const questionFunnelsPagesResponseFactory = (
  response: QuestionResponse
) => {
  const body = {
    cancellationFunnelId: response.cancellationFunnelId,
    subtitle: response.content.subtitle,
    title: response.content.title,
    name: response.name,
    answers: response.content.answers.map(({ answer, finishFunnel }) => ({
      answer,
      finishFunnel: finishFunnel ?? false,
    })),
    continueCancelationButtonTitle:
      response.content.continueCancelationButtonTitle,
    keepSubscriptionButtonTitle: response.content.keepSubscriptionButtonTitle,
  };

  return body;
};
