import { DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, Table, TableColumnType } from 'antd';
import { format, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { Offer } from 'src/features/offers/interfaces/offersInterface';

type TTableOriginOffers = {
  offers: Offer[];
  onRemoveOffer?: (id: string) => void;
  hasLoadingRemoveOffer: boolean;
};

export function TableOriginOffers({
  offers,
  onRemoveOffer,
  hasLoadingRemoveOffer,
}: TTableOriginOffers) {
  const handleRemoveOffer = useCallback(
    (id: string) => {
      if (!onRemoveOffer) return;

      onRemoveOffer(id);
    },
    [onRemoveOffer]
  );

  const columns: TableColumnType<Offer>[] = useMemo(
    () => [
      {
        title: 'ID da oferta',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'ID do Produto',
        dataIndex: 'productSellGroupId',
        key: 'productSellGroupId',
      },
      {
        title: 'Nome da Oferta',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Data Inicio',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (startDate: Offer['startDate']) =>
          format(parseISO(startDate), 'dd/MM/yyyy'),
      },
      {
        title: 'Data Fim',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (endDate: Offer['endDate']) =>
          !!endDate ? format(parseISO(endDate), 'dd/MM/yyyy') : '---',
      },
      {
        title: 'Ações',
        key: 'action',
        dataIndex: 'id',
        width: '2%',
        render: id =>
          !hasLoadingRemoveOffer ? (
            <Button
              onClick={() => handleRemoveOffer(id)}
              style={{ border: 'none' }}
              disabled={hasLoadingRemoveOffer}
            >
              <DeleteOutlined />
            </Button>
          ) : (
            <Spin />
          ),
      },
    ],
    [handleRemoveOffer]
  );

  return <Table dataSource={offers} columns={columns} pagination={false} />;
}
