import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function unlinkProgramToSegment(
  programId: number,
  segmentId: number
): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/programs/${programId}/segment/${segmentId}/relationships`,
    method: 'DELETE',
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
