import styled from 'styled-components';

export const ContainerReactFlow = styled.div`
  position: relative;
  height: 50vh;
`;

export const FloatButtonContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1000;

  > button {
    width: 52px;
    height: 52px;
  }
`;
