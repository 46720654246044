import { Form, Select } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { gameficationCompetition } from '../../client.api';
import { ICompetitionType } from '../../dto/competition-type.interface';

interface IComptitionTypeSelect {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const CompetitionTypeInput: FC<IComptitionTypeSelect> = props => {
  const { required, disabled, ...rest } = props;

  const _callApi = async () => {
    const client = gameficationCompetition.getCompetitionType;
    const response = await client();
    return response.data;
  };

  const OPTS = { refetchOnWindowFocus: false };
  const NAME = 'gamefication_competition_type';
  const { isFetching, data } = useQuery(NAME, _callApi, OPTS);

  const options = data?.map((option: ICompetitionType) => ({
    label: option.description,
    value: option.id,
  }));

  const SELECT_PROPS = { loading: isFetching, disabled, options };

  return (
    <Form.Item {...rest} rules={[{ required }]}>
      <Select {...SELECT_PROPS} data-testid='type' placeholder='Selecione...' />
    </Form.Item>
  );
};

export default CompetitionTypeInput;
