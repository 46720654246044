import styled from 'styled-components';

export const ModalModelSpace = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalModelImageSkeleton = styled.div`
  height: 143px;
  width: 100%;
  margin-bottom: 8.28px;

  background-color: #434343;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalModelItemImageSkeletonContainer = styled.div`
  display: flex;
  margin-right: 20.24px;
`;

export const ModalModelItemImageSkeleton = styled.div`
  height: 90px;
  width: 121px;
  border-radius: 8.29px;

  background-color: #262626;
`;

export const ModalModelTitleSkeleton = styled.div`
  height: 8.28px;
  width: 53.88px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #262626;
`;

export const ModalModelDescriptionSkeleton = styled.div`
  height: 8.28px;
  width: 107.77px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #262626;
`;

export const ModalModelButtonSkeleton = styled.div`
  width: 49.67px;
  height: 16.56px;
  border-radius: 4.14px;
  margin-top: 16px;

  background-color: #262626;
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding: 8.28px 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalModelSquareSkeleton = styled.div`
  width: 71px;
  height: 54px;

  background-color: #434343;
  border-radius: 8.28px;
`;
