import { FormResponse } from '../interfaces/offersInterface';
import { generateImage } from './createFormDataForImage';

export async function createFormDataFromFormResponse(
  formResponse: FormResponse,
  productId: string
): Promise<FormData> {
  const formData = new FormData();
  const offerDuoCode = '43';
  const offerFamilyCode = '44';

  formData.append('productSellGroupId', productId);
  formData.append('offerName', formResponse.offerName);
  formData.append('offerDescription', formResponse.offerDescription);
  formData.append('offerValue', formResponse.fullPrice);
  formData.append('offerInstallments', formResponse.installments);
  formData.append('startDate', formResponse.startDate);
  formData.append('endDate', formResponse.endDate);
  formData.append('facebookPixels', formResponse.facebookPixels);
  const tagValue = formResponse.isMailPromotional ? 'promotional' : '';
  formData.append('tag', tagValue);
  formData.append('renewalOfferId', formResponse.renewalOfferId);
  formData.append('disclaimer', formResponse.disclaimer);
  formData.append('googleAnalyticsIds', formResponse.googleAnalyticsIds);
  formData.append('privacyPolicyRef', formResponse.privacyPolicyRef);
  formData.append('purchaseBtnLabel', formResponse.purchaseBtnLabel);
  formData.append('subscriptionPeriod', formResponse.subscriptionPeriod);
  formData.append('termsRef', formResponse.termsRef);
  formData.append('replaceDefaultOffer', formResponse.offerStandart.toString());
  formData.append('colorBackgroundCounter', formResponse.bgCounter);
  formData.append('enableCounter', formResponse.hasCounter.toString());
  formData.append('isDisplayCoupon', formResponse.isDisplayCoupon.toString());
  formData.append('renewalDisabled', formResponse.renewalDisabled.toString());
  formData.append('isAvailableNuPay', formResponse.isAvailableNuPay.toString());
  if (!!formResponse.promotionalAmount)
    formData.append('promotionalAmount', formResponse.promotionalAmount);
  if (!!formResponse.qtdPromotionalDays)
    formData.append('qtdPromotionalDays', formResponse.qtdPromotionalDays);
  if (!!formResponse.qtdDelayedCaptureDays)
    formData.append(
      'qtdDelayedCaptureDays',
      formResponse.qtdDelayedCaptureDays
    );

  if (formResponse.plan) {
    switch (formResponse.plan) {
      case 'duo':
        formData.append('accessProfileId', offerDuoCode);
        break;
      case 'family':
        formData.append('accessProfileId', offerFamilyCode);
        break;
    }
  }

  formResponse.bannerWeb instanceof Object &&
    formData.append(
      'bannerWeb',
      await generateImage(formResponse.bannerWeb),
      formResponse.bannerWeb.file.name
    );

  formResponse.bannerMobile instanceof Object &&
    formData.append(
      'bannerMobile',
      await generateImage(formResponse.bannerMobile),
      formResponse.bannerMobile.file.name
    );

  formResponse.bannerSideWeb instanceof Object &&
    formData.append(
      'bannerSideWeb',
      await generateImage(formResponse.bannerSideWeb),
      formResponse.bannerSideWeb.file.name
    );

  formResponse.logo instanceof Object &&
    formData.append(
      'logo',
      await generateImage(formResponse.logo),
      formResponse.logo.file.name
    );

  formResponse.rightImg instanceof Object &&
    formData.append(
      'bannerRightWebCounter',
      await generateImage(formResponse.rightImg),
      formResponse.rightImg.file.name
    );

  formResponse.rightImgMobile instanceof Object &&
    formData.append(
      'bannerRightMobileCounter',
      await generateImage(formResponse.rightImgMobile),
      formResponse.rightImgMobile.file.name
    );

  formResponse.leftImg instanceof Object &&
    formData.append(
      'bannerLeftCounter',
      await generateImage(formResponse.leftImg),
      formResponse.leftImg.file.name
    );

  formResponse.leftImgMobile instanceof Object &&
    formData.append(
      'bannerLeftMobileCounter',
      await generateImage(formResponse.leftImgMobile),
      formResponse.leftImgMobile.file.name
    );

  return formData;
}
