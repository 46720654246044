import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { IBadgeRuleCompetitionEventRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export interface SaveBadgeCompetitionEventListPayload {
  competitionId: number;
  badgeId: number;
  competitionEventList: IBadgeRuleCompetitionEventRequestDto[];
}

const createNewCompetitionEvent = async (
  badgeId: number,
  competitionId: number,
  competitionEvent: IBadgeRuleCompetitionEventRequestDto
) => {
  const resp = await badgesGroupsApi.createBadgeRule(badgeId, {
    amount: 0,
    order: 0,
  });

  const competitionEventCreated = await badgesGroupsApi.createCompetitionEvent({
    competitionId: competitionId,
    eventsId: competitionEvent.events?.map(p => p.id) ?? [],

    badgeRuleId: resp.data.id,
    rangeDays: competitionEvent.rangeDays,
    displayName: competitionEvent.displayName ?? '',
    points: competitionEvent.points,
    startDate: new Date(),
    limitOccurrence: competitionEvent.limitOccurrence,
    intervalOccurrenceMinutes: competitionEvent.intervalOccurrenceMinutes,
  });

  return competitionEventCreated;
};

const updateCompetitionEvent = async (
  competitionId: number,
  competitionEvent: IBadgeRuleCompetitionEventRequestDto
) => {
  if (!competitionEvent?.id) {
    throw new Error('Nenhum ID de competição foi encontrado');
  }

  return badgesGroupsApi.updateCompetitionEvent(competitionEvent.id, {
    rangeDays: competitionEvent.rangeDays,
    displayName: competitionEvent.displayName,
    points: competitionEvent.points,
    startDate: competitionEvent.startDate,
    intervalOccurrenceMinutes: competitionEvent.intervalOccurrenceMinutes,
    limitOccurrence: competitionEvent.limitOccurrence,
  });
};

export const saveAllBadgeCompetitionEventList = createAsyncThunk(
  'gamification/badgeGroups/saveBadgeCompetitionEventList',
  async (props: SaveBadgeCompetitionEventListPayload, { rejectWithValue }) => {
    const { badgeId, competitionEventList, competitionId } = props;
    try {
      const requests = competitionEventList.map(async competitionEvent => {
        if (competitionEvent.isNew) {
          return createNewCompetitionEvent(
            badgeId,
            competitionId,
            competitionEvent
          );
        } else {
          return updateCompetitionEvent(competitionId, competitionEvent);
        }
      });

      const fullRequestList = await Promise.all(requests);

      OpenNotification(false, 'Eventos salvos com sucesso');

      fullRequestList.forEach((request, index) => {
        if (!request.data) {
          throw new Error(
            `Falha ao salvar o evento ${competitionEventList[index].displayName}`
          );
        }
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveAllBadgeCompetitionEventListBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(saveAllBadgeCompetitionEventList.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(saveAllBadgeCompetitionEventList.fulfilled, state => {
    state.status = 'FULFILLED';
  });

  builder.addCase(saveAllBadgeCompetitionEventList.rejected, state => {
    state.status = 'ERROR';
  });
};
