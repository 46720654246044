import { Button, Input, Modal, Table } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { RoleType } from 'src/features/roles/api/roleApi';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOk: (data: any) => void;
  data?: RoleType[];
  roles?: React.Key[];
};

const columns = [
  {
    key: 'key',
    title: 'Nome',
    dataIndex: 'name',
  },
];

export const ModalAddRole: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
  data,
  roles,
}) => {
  const [rowSelection, setRowSelection] = useState<React.Key[] | undefined>(
    roles
  );

  useEffect(() => {
    const arrayRolesId: React.Key[] = [];
    roles?.forEach((item: React.Key) => {
      arrayRolesId.push(item);
    });
    setRowSelection(arrayRolesId);
  }, [roles]);

  const [filter, setFilter] = useState<string>();
  const constructDataTable = (filterParam?: string) => {
    if (data) {
      data.forEach(item => {
        item.key = item.id;
      });

      if (filterParam) {
        data = data.filter(x =>
          x.name.toLowerCase().includes(filterParam.toLowerCase())
        );
      }

      return data;
    }
  };

  const onSubmit = () => {
    handleOk(rowSelection);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeSelectRow = (selectedRowKeys: React.Key[]) => {
    setRowSelection(selectedRowKeys);
  };

  return (
    <>
      <Modal
        width={640}
        title='Seleção de papéis'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key='back' data-testid='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key='submit'
            data-testid='submit'
            type='primary'
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      >
        <>
          <Input
            value={filter}
            data-testid='filter'
            onChange={e => setFilter(e.target.value)}
            placeholder='Pesquisar papeis'
            style={{ marginBottom: 10 }}
          />
          <Table
            pagination={false}
            bordered
            rowSelection={{
              onChange: onChangeSelectRow,
              selectedRowKeys: rowSelection,
            }}
            columns={columns}
            dataSource={constructDataTable(filter)}
          />
        </>
      </Modal>
    </>
  );
};
