import { PageHeader } from '@organisms';
import { Divider, Row, Space } from 'antd';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';

import { ContentComponent } from 'src/app/components/ContentComponent';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { adminApi } from '../../api/adminApi';
import { FormAdminAccount } from '../../components/FormAdminAccount';
import { TableAdminRoles } from '../../components/TableAdminRoles';
import { AdminDTOType } from '../../types/adminTypes';

export const AdminScreenDetails: FC<RouteProps> = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Admin',
      route: '/admin',
    },
    {
      label: 'Dados do Admin',
    },
  ]);

  interface UseParamsId {
    id: string;
  }
  const params: UseParamsId = useParams();

  const fetchAdmin = async (key: string) => {
    const response = await adminApi.getAdminById(params.id);
    return response as AdminDTOType;
  };

  const { data, isLoading, isError } = useQuery(
    'admin',
    () => fetchAdmin('admin'),
    { refetchOnWindowFocus: false }
  );

  const queryClient = useQueryClient();
  const handleReloadAdmin = () => queryClient.invalidateQueries('admin');
  return (
    <ContentComponent
      isLoading={isLoading}
      isError={isError}
      data-testid='jokeContainer'
    >
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Admin'}
          subTitle={'Informações do gestor do admin'}
        />
        <Divider />
        <FormAdminAccount admin={data} reloadAdmin={handleReloadAdmin} />
        <Space size={10} direction='vertical' />
        <TableAdminRoles
          data-testid='jokeText'
          roles={data?.roles}
          admin={data}
          reloadAdmin={handleReloadAdmin}
        />
      </Row>
    </ContentComponent>
  );
};
