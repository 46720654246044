import { PageHeader } from '@organisms';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FilterEvents } from './Filter.events';
import { ModalEvents } from './Modal.events';
import { TableEvents } from './Table.events';

export const EventsPage: React.FC = () => {
  useBreadcrumbs([
    {
      label: 'Eventos',
    },
  ]);

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de eventos'}
        subTitle={''}
      />

      <TableEvents filterComponent={<FilterEvents />} />

      <ModalEvents title='Gerenciar eventos' />
    </>
  );
};
