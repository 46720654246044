import { base } from '@service/base';

type TRemoveOriginOfferResponse = {
  success: boolean;
};

export async function removeOriginOfferOrderBump(offerIds: number[]) {
  const response = await base.patch<TRemoveOriginOfferResponse>(
    `/order-bump/offers`,
    {
      orderBumpId: null,
      offerIds,
    }
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
