import { Modal } from 'antd';

export type ConfirmModalProps = {
  onCloseModal: () => void;
  onConfirmDelete: () => void;
  titleConfirm: string;
  textConfirm: string;
};

export function ConfirmModal({
  onCloseModal,
  onConfirmDelete,
  textConfirm,
  titleConfirm,
}: ConfirmModalProps) {
  const handleOk = () => {
    onConfirmDelete();
    onCloseModal();
  };
  return (
    <Modal
      title={titleConfirm}
      open
      onOk={handleOk}
      onCancel={onCloseModal}
      okText='Sim'
      cancelText='Não'
      maskClosable={false}
    >
      <p>{textConfirm}</p>
    </Modal>
  );
}
