import { PictureOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Input, Row } from 'antd';
import { FC, useState } from 'react';

import { IBadgeRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';
import { IBadgeImageResponseDto } from 'src/core/gamefication/dto/badge-image/badge-response.dto';

import { Label } from '../label';
import { ModalViewUploadImageBadge } from '../ModalViewUploadImageBadge';

interface Props {
  formData?: IBadgeRequestDto;
  enableForm?: boolean;
  loadingBadge?: boolean;
  index?: number;
  onFormDataChange?: (name: string, value?: string | number) => void;
}

export const BadgeForm: FC<Props> = ({
  formData,
  enableForm = false,
  loadingBadge = false,
  onFormDataChange,
}) => {
  const [isModalBadgeImageUploadVisible, setIsModalBadgeImageUploadVisible] =
    useState(false);

  const onShowModalUploadClick = () => {
    toggleUploadImageModal?.(true);
  };

  const toggleUploadImageModal = (value?: boolean) => {
    if (typeof value === 'undefined') {
      setIsModalBadgeImageUploadVisible(!isModalBadgeImageUploadVisible);
      return;
    }
    setIsModalBadgeImageUploadVisible(value as boolean);
  };

  const onImageUploadSaveClick = (
    imagemSelecionada: IBadgeImageResponseDto,
    imagemUrl: string
  ): void => {
    onFormDataChange?.('imagePath', imagemUrl);
    setIsModalBadgeImageUploadVisible(false);
  };
  return (
    <>
      <Row justify='space-around' align='middle'>
        <Col
          xs={24}
          sm={20}
          md={20}
          lg={20}
          xl={20}
          style={{ minHeight: '100%' }}
        >
          <Row style={{ marginBottom: 40 }}>
            <Col span={18} style={{ paddingRight: 20 }}>
              <Label>Nome da badge</Label>
              <Input
                disabled={!enableForm || loadingBadge}
                onChange={(evt: { target: { name: string; value: string } }) =>
                  onFormDataChange?.('name', evt.target.value)
                }
                name='name'
                value={formData?.name ?? ''}
              />
            </Col>
            <Col span={6}>
              <Label>Ordem da badge</Label>
              <Input
                disabled={!enableForm || loadingBadge}
                onChange={(evt: { target: { name: string; value: string } }) =>
                  onFormDataChange?.(
                    'order',
                    evt.target.value === '' ? undefined : +evt.target.value
                  )
                }
                name='order'
                value={formData?.order || ''}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Label>Descrição</Label>
              <Input
                disabled={!enableForm || loadingBadge}
                onChange={(evt: { target: { name: string; value: string } }) =>
                  onFormDataChange?.('description', evt.target.value)
                }
                name='description'
                value={formData?.description ?? ''}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          style={{
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: 'center',
          }}
        >
          <div
            className='ant-upload ant-upload-drag'
            style={{
              paddingTop: 35,
              paddingBottom: 35,
              background: 'transparent',
            }}
          >
            <Row justify='center'>
              <Col span={24} style={{ textAlign: 'center' }}>
                {!formData?.imagePath ? (
                  <Avatar
                    size={64}
                    icon={<PictureOutlined />}
                    style={{
                      verticalAlign: 'middle',
                      background: 'transparent',
                    }}
                  />
                ) : (
                  <Avatar
                    size={64}
                    src={formData.imagePath}
                    style={{
                      verticalAlign: 'middle',
                      background: 'transparent',
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                }}
              >
                <p>Adicionar a capa da sua badge</p>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  paddingTop: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  disabled={!enableForm || loadingBadge}
                  icon={<PlusCircleOutlined />}
                  type='primary'
                  onClick={onShowModalUploadClick}
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ModalViewUploadImageBadge
        isModalVisible={isModalBadgeImageUploadVisible}
        onSaveClick={onImageUploadSaveClick}
        toggleUploadImageModal={toggleUploadImageModal}
      />
    </>
  );
};
