import { PlusCircleOutlined } from '@ant-design/icons';
import { TUpsellStepsFlowManage } from '@hooks';
import { Button, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  Edge,
  EdgeTypes,
  getBezierPath,
  Node,
  NodeDragHandler,
  OnConnect,
  OnNodesChange,
  Position,
  ReactFlowInstance,
} from 'react-flow-renderer';

import { DisplayFlowNode } from './Display';
import { ContainerReactFlow, FloatButtonContainer } from './styles';

type StepConfig = {
  toStepFlow: TUpsellStepsFlowManage;
  stepFlow: TUpsellStepsFlowManage;
};

type FlowStepsProps = {
  steps: TUpsellStepsFlowManage[];
  onDeleteStep: (step: TUpsellStepsFlowManage) => void;
  onEditStep: (step: TUpsellStepsFlowManage) => void;
  onCreateStep: () => void;
  onChangeStep: (step: TUpsellStepsFlowManage) => void;
  onCreateEdge: (stepConfig: StepConfig) => void;
};

const edgeTypes: EdgeTypes = {
  custom: ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
  }) => {
    const edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return (
      <>
        <path id={id} className='react-flow__edge-path' d={edgePath} />
        <text>
          <textPath
            href={`#${id}`}
            style={{ fontSize: '12px' }}
            startOffset='50%'
            textAnchor='middle'
          >
            {data.text}
          </textPath>
        </text>
      </>
    );
  },
};

export function FlowSteps({
  steps,
  onChangeStep,
  onCreateEdge,
  onCreateStep,
  onDeleteStep,
  onEditStep,
}: FlowStepsProps) {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    const theSameOrder: { [key: string]: number } = {};

    const initNodes = steps.map((step, index) => {
      const orderTheSame = (theSameOrder[step.order] || 0) + 1;
      theSameOrder[step.order] = orderTheSame;

      return {
        id: `${step.id}`,
        data: { stepFlowData: step, onDeleteStep, onEditStep },
        position: !step.position
          ? { y: 220 * orderTheSame - 200, x: 350 * step.order - 300 }
          : step.position,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        type: 'displayCustom',
      } as Node;
    });

    const initEdges = steps.reduce((acc, step) => {
      const currentEdge: Edge[] = [];

      if (step.up !== null || step.down !== null) {
        if (step.up === step.down) {
          currentEdge.push({
            id: `${step.id}-all-${step.up}`,
            target: `${step.id}`,
            source: `${step.up}`,
            label: 'TODOS',
            labelStyle: { color: '#ff4f00', fontWeight: 500 },
          });
        } else {
          if (step.up !== null) {
            currentEdge.push({
              id: `${step.id}-up-${step.up}`,
              target: `${step.id}`,
              source: `${step.up}`,
              label: 'SIM',
            });
          }
          if (step.down !== null) {
            currentEdge.push({
              id: `${step.id}-down-${step.down}`,
              target: `${step.id}`,
              source: `${step.down}`,
              label: 'NÃO',
            });
          }
        }
      }

      return [...acc, ...currentEdge];
    }, [] as Edge[]);

    setNodes(initNodes);
    setEdges(initEdges);
  }, [steps]);

  const onNodesChange = useCallback<OnNodesChange>(
    changes => {
      setNodes(nds => applyNodeChanges(changes, nds));
    },
    [steps]
  );

  const onEdgesChange = useCallback(
    changes => setEdges(eds => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback<OnConnect>(
    connection => {
      const stepFlow = steps.find(({ id }) => id === Number(connection.target));
      const toStepFlow = steps.find(
        ({ id }) => id === Number(connection.source)
      );
      if (!stepFlow || !toStepFlow) return;

      onCreateEdge({
        stepFlow,
        toStepFlow,
      });
    },
    [steps]
  );

  const onEdgeClick = useCallback(
    (_: React.MouseEvent, node: Edge) => {
      const stepFlow = steps.find(({ id }) => id === Number(node.target));
      const toStepFlow = steps.find(({ id }) => id === Number(node.source));
      if (!stepFlow || !toStepFlow) return;

      onCreateEdge({
        stepFlow,
        toStepFlow,
      });
    },
    [steps]
  );

  const onInit = useCallback(
    (reactFlowInstance: ReactFlowInstance) =>
      reactFlowInstance.fitView({
        padding: 0.5,
      }),
    []
  );

  const onMovementEnd = useCallback<NodeDragHandler>(
    (_, node) => {
      const currentStep = steps.find(({ id }) => Number(node.id) === id);
      if (!currentStep) return;
      onChangeStep({
        ...currentStep,
        position: node.position,
      });
    },
    [steps]
  );

  const nodeTypes = useMemo(() => ({ displayCustom: DisplayFlowNode }), []);

  return (
    <ContainerReactFlow>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDragStop={onMovementEnd}
        onConnect={onConnect}
        onEdgeClick={onEdgeClick}
        onInit={onInit}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        snapToGrid
      >
        <Controls />
        <Background />
      </ReactFlow>
      <FloatButtonContainer>
        <Tooltip title='Adicionar um novo passo'>
          <Button
            type='primary'
            shape='circle'
            icon={<PlusCircleOutlined />}
            onClick={onCreateStep}
          />
        </Tooltip>
      </FloatButtonContainer>
    </ContainerReactFlow>
  );
}
