import { useMemo } from 'react';

import { useCreateOrderBump } from '../../hooks/useCreateOrderBump';
import { Contents } from './Steps/Contents';
import { GeneralConfigurations } from './Steps/GeneralConfigurations';

export function StepsCreateOrderBump() {
  const { step } = useCreateOrderBump();

  const Step = useMemo(() => {
    switch (step.value) {
      case 0:
        return <GeneralConfigurations />;
      case 1:
        return <Contents />;
      default:
        return <></>;
    }
  }, [step.value]);

  return Step;
}
