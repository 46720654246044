import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tag,
} from 'antd';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ICompetition } from '../../../dto/competition.interface';
import { gameficationCompetitionActivities } from './client.api';

interface ActivityModalProps {
  modalState: [boolean, Dispatch<SetStateAction<boolean>>];
  // eslint-disable-next-line
  activity: any;
  // eslint-disable-next-line
  refetch: any;
}

const ActivityModal: FC<ActivityModalProps> = ({
  modalState,
  activity,
  refetch,
}) => {
  const [form] = Form.useForm();
  const URL_PARAMS: ICompetition = useParams();

  const [showModal, setModal] = modalState;
  const [hasActivityId] = useState<number>(activity?.id);
  const [checked, setChecked] = useState<boolean>(
    Boolean(activity?.useCustomerPoints)
  );

  const [ID, setID] = useState<number>(1);

  // eslint-disable-next-line
  const [XXX, setXXX] = useState<any>([]);
  const OPTS = { refetchOnWindowFocus: false };

  const queryClient = useQueryClient();

  const QUERY = useQuery(
    'gamefication_competition_activities_programs',
    async () => {
      const client = gameficationCompetitionActivities;
      const response = await client.listPrograms();
      return response?.data;
    },
    OPTS
  );

  const QUERY2 = useQuery(
    ['gamefication_competition_activities_classes'],
    async () => {
      const client = gameficationCompetitionActivities;
      const response = await client.listClasses(Number(ID));
      return response?.data;
    },
    OPTS
  );

  useEffect(() => {
    QUERY2.refetch();
  }, [ID]);

  useEffect(() => {
    // remove query content when component 'unmount'
    return () => {
      setXXX([]);
      queryClient.removeQueries('gamefication_competition_activities_classes');
    };
  }, []);

  useEffect(() => {
    if (QUERY?.data && QUERY2?.data) {
      // eslint-disable-next-line
      const AAA = QUERY2.data.map((item: any) => ({
        key: item.id,
        name: item.name,
        // eslint-disable-next-line
        program: QUERY.data.find((x: any) => x.id == item.programId),
        modules: item.modules,
      }));

      setXXX([...AAA, ...XXX]);
    }
  }, [QUERY?.data, QUERY2?.data]);

  // eslint-disable-next-line
  const onFinish = async (payload: any) => {
    payload.startDate = String(moment(payload.startDate).toISOString());
    payload.endDate = String(moment(payload.endDate).toISOString());

    const opts = {
      ...payload,
      ...(hasActivityId ? { id: activity.id } : {}),
      competition: Number(URL_PARAMS.id),
      useCustomerPoints: checked,
    };

    try {
      await gameficationCompetitionActivities.upsert(opts);
      await refetch();

      const MSG = hasActivityId ? `"${payload.name}" atualizada` : 'adicionada';
      message.success(`Atividade ${MSG}!`);
      setModal(false);
    } catch (e) {
      const err = 'Não foi possível realizar esta operação. Tente novamente.';
      message.error(err);
    }
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
    initialValues: activity
      ? {
          name: activity?.name,
          points: activity?.points,
          limit: activity?.limit,
          startDate: moment(activity.startDate),
          endDate: activity?.endDate ? moment(activity.endDate) : undefined,
          useCustomerPoints: checked,
        }
      : undefined,
  };

  const DATE_PROPS = {
    format: 'DD/MM/YYYY',
    placeholder: 'Selecione...',
    style: { width: '100%' },
  };

  return (
    <Modal
      title={hasActivityId ? 'Editar Evento' : 'Adicionar Evento'}
      visible={showModal}
      onOk={() => form.submit()}
      onCancel={() => {
        setModal(false);
        form.resetFields();
      }}
      cancelText='Cancelar'
      okText='Salvar'
      width={650}
    >
      <Form {...FORM_PROPS} size='large' layout='vertical' hideRequiredMark>
        <Form.Item
          name='name'
          label='Nome da atividade'
          rules={[{ required: true }]}
        >
          <Input autoFocus allowClear placeholder='Aprovação em curso' />
        </Form.Item>

        <Form.Item
          name='programs'
          label='Programas'
          // rules={[{ required: true }]}
        >
          <Select
            showArrow
            allowClear
            mode='multiple'
            data-testid='type'
            placeholder='Selecione...'
            loading={QUERY.isFetching}
            onChange={(x: number[]) => {
              const [selected] = x.reverse();
              setID(selected);
            }}
          >
            {QUERY?.isSuccess &&
              // eslint-disable-next-line
              QUERY?.data.map((program: any) => (
                <Select.Option key={program.id} value={program.id}>
                  {program.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name='classes' label='Aulas'>
          <Select
            mode='multiple'
            showArrow
            allowClear
            disabled={
              QUERY.isFetching || !QUERY?.data?.length || !QUERY2?.data?.length
            }
            loading={QUERY2.isFetching}
            data-testid='type'
            placeholder={
              QUERY.isFetching ? 'Buscando...' : 'Selecione acima para iniciar'
            }
          >
            /
            {QUERY2?.isSuccess && XXX.length
              ? XXX.map(
                  // eslint-disable-next-line
                  (xx: any, index: number) => {
                    // eslint-disable-next-line
                    return xx?.modules.map((gg: any) => {
                      return (
                        <Select.OptGroup
                          key={`G${gg.id}`}
                          label={
                            <Row>
                              <Col flex='none'>
                                {xx?.program?.name} - {gg.name}
                              </Col>
                              <Col
                                flex='auto'
                                style={{ marginLeft: 10, textAlign: 'right' }}
                              >
                                ({gg.contents.length} aulas)
                              </Col>
                            </Row>
                          }
                        >
                          {gg.contents.map(
                            // eslint-disable-next-line
                            (kk: any) => {
                              return (
                                <Select.Option
                                  key={kk.id}
                                  value={`${xx.program.id}-${gg.pageId}-${kk.moduleId}-${kk.id}`}
                                >
                                  <Row>
                                    <Col flex='none'>{kk.name}</Col>
                                    <Col
                                      flex='auto'
                                      style={{
                                        marginLeft: 10,
                                        textAlign: 'right',
                                      }}
                                    >
                                      <Tag color='default'>
                                        {xx.program.name}
                                      </Tag>
                                    </Col>
                                  </Row>
                                </Select.Option>
                              );
                            }
                          )}
                        </Select.OptGroup>
                      );
                    });
                  }
                )
              : null}
          </Select>
        </Form.Item>

        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item
              name='points'
              label='Pontos'
              rules={[{ required: true }]}
            >
              <InputNumber data-testid='points' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
              <Checkbox onChange={() => setChecked(!checked)} checked={checked}>
                Considerar a nota obitda pela aluno como pontuação.
              </Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name='limit'
              label='Limite de ocorrências'
              rules={[{ required: true }]}
            >
              <InputNumber data-testid='limit' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item
              name='startDate'
              label='Vigência'
              rules={[{ required: true }]}
            >
              <DatePicker {...DATE_PROPS} data-testid='from' />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name='endDate' label='Até' rules={[{ required: true }]}>
              <DatePicker {...DATE_PROPS} data-testid='to' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ActivityModal;
