import { IDefaultIdNameResponseDto } from '@core/dto/default-id-name-response.dto';
import { IBadgeResponseDto } from '@core/gamefication/dto/badge-group/badge-response.dto';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SerializedApiError } from 'src/app/typings/global';
import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';
import {
  IBadgeRequestDto,
  IBadgeRuleCompetitionEventRequestDto,
} from 'src/core/gamefication/dto/badge-group/badge-request.dto';

import { badgeGroupsBuilder } from './badge';
import { createBadgeBuilder } from './badge/createBadge.builder';
import { createBadgeGroupBuilder } from './badge/createBadgeGroup.builder';
import { deleteBadgeGroupBuilder } from './badge/deletebadgeGroup.builder';
import { badgeCompetitionEventListBuilder } from './badge/getCompetitionEvent.builder';
import { getCompetitiveEventTypeListBuilder } from './badge/getCompetitiveEventTypeList.builder';
import { saveAllBadgeCompetitionEventListBuilder } from './badge/saveCompetitionEvents.builder';
import { updateBadgeGroupBuilder } from './badge/updateBadgeGroup.builder';

export type GamificationStatus =
  | 'FULFILLED'
  | 'PENDING'
  | 'ERROR'
  | 'RESTORING';

export type GamificationState = {
  status: GamificationStatus;
  badgeGroup?: IBadgeGroupRequestDto;
  badgeEditingId?: number;
  badgeOnFocus?: IBadgeRequestDto;
  badgeOnFocusCompetitiveEventsList?: IBadgeRuleCompetitionEventRequestDto[];
  competitionEventTypeList?: IDefaultIdNameResponseDto[];
  errorDetails?: SerializedApiError;
  badgeExpandedKey?: string;
};

const initialState: GamificationState = {
  status: 'RESTORING',
  competitionEventTypeList: [
    {
      id: 1,
      name: 'Diário',
    },
    {
      id: 7,
      name: 'Semanal',
    },
    {
      id: 30,
      name: 'Mensal',
    },
    {
      id: 365,
      name: 'Anual',
    },
    {
      id: 0,
      name: 'Personalizado',
    },
  ],
};

const gamificationSlice = createSlice({
  name: 'Gamification',
  initialState,
  reducers: {
    clearBadgeGroup: state => {
      state.badgeGroup = undefined;
    },
    setBadgeExpandedKey(state, action: PayloadAction<string>) {
      state.badgeOnFocusCompetitiveEventsList = undefined;
      state.badgeExpandedKey = action.payload;
      if (!action.payload) {
        state.badgeOnFocus = undefined;
        return;
      }
      const badgeId = action.payload.split('-')[1];

      const badge = state.badgeGroup?.badges?.find(badge => {
        return badge.id === +badgeId;
      });

      state.badgeOnFocus = badge;
    },
    clearBadgeOnEdition: state => {
      state.badgeEditingId = undefined;
    },
    addNewBadgeinTheBadgeGroup: state => {
      if (!state.badgeGroup?.badges?.length) return;
      const tempBadgeId = Date.now();
      const collapseBadgeId = `Collapse-${tempBadgeId}`;
      const newBadge: IBadgeRequestDto = {
        id: tempBadgeId,
        name: 'Nome da badge',
        description: '',
        imagePath: '',
        isNew: true,
        order: state.badgeGroup.badges.length + 1,
        activated: true,
      };

      state.badgeGroup.badges.push(newBadge);
      state.badgeEditingId = tempBadgeId;

      state.badgeOnFocusCompetitiveEventsList = undefined;
      state.badgeExpandedKey = collapseBadgeId;
      state.badgeOnFocus = newBadge;
    },
    editingBadge: (state, { payload }: PayloadAction<number>) => {
      state.badgeEditingId = payload;
    },
    deleteLocalBadge: (state, { payload }: PayloadAction<number>) => {
      if (!state.badgeGroup?.badges?.length) return;
      state.badgeGroup.badges = state.badgeGroup.badges.filter(
        badge => badge.id !== payload
      );
    },
    updateBadgeInBadgeGroup: (
      state,
      {
        payload,
      }: PayloadAction<{
        oldBadge: IBadgeRequestDto;
        newBadge: IBadgeResponseDto;
      }>
    ) => {
      if (!state.badgeGroup) return;
      const { oldBadge, newBadge } = payload;
      state.badgeGroup.badges = state.badgeGroup?.badges?.map(badge => {
        if (badge.id !== oldBadge.id) return badge;

        return {
          ...badge,
          ...oldBadge,
          ...newBadge,
          isNew: false,
        };
      });
    },
  },
  extraReducers: builder => {
    badgeGroupsBuilder(builder);
    badgeCompetitionEventListBuilder(builder);
    saveAllBadgeCompetitionEventListBuilder(builder);
    createBadgeBuilder(builder);
    createBadgeGroupBuilder(builder);
    updateBadgeGroupBuilder(builder);
    deleteBadgeGroupBuilder(builder);
    getCompetitiveEventTypeListBuilder(builder);
  },
});

export const {
  setBadgeExpandedKey,
  clearBadgeOnEdition,
  addNewBadgeinTheBadgeGroup,
  editingBadge,
  deleteLocalBadge,
  updateBadgeInBadgeGroup,
  clearBadgeGroup,
} = gamificationSlice.actions;

export const reducer = gamificationSlice.reducer;
