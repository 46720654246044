import { PageHeader } from '@organisms';
import { useEffect, useState } from 'react';

import { useVerifyPermission } from 'src/features/dashboard/hooks/useVerifyPermission';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { ModalFreezing } from '../components/ModalFreezing';
import { TableFreezing } from '../components/TableFreezing';

export const FreezingScreen = () => {
  const [showModal, setShowModal] = useState(false);
  const [permissionMaster, isLoading] = useVerifyPermission();

  useEffect(() => {
    !isLoading && !permissionMaster && (window.location.href = '../dashboard');
  }, [isLoading]);

  useBreadcrumbs([
    {
      label: 'Agendamento de Freezing',
    },
  ]);

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Agendamento de Freezing'}
        subTitle={''}
      />

      <TableFreezing setShowModal={setShowModal} showModal={showModal} />
      <ModalFreezing setIsVisible={setShowModal} isVisible={showModal} />
    </>
  );
};
