import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';

export interface FilterFreezingProps {
  onClickAdd: () => void;
  filter: string;
  onChangeFilter: (value: string) => void;
}

export const FilterFreezing = ({
  onClickAdd,
  filter,
  onChangeFilter,
}: FilterFreezingProps) => {
  return (
    <Row gutter={24}>
      <Col span={22}>
        {' '}
        <Input
          placeholder={'Buscar por ID ou e-mail'}
          suffix={<SearchOutlined />}
          value={filter}
          onChange={value => {
            onChangeFilter(value.target.value);
          }}
        />
      </Col>
      <Col span={2}>
        <Button
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={() => {
            onClickAdd();
          }}
        >
          Adicionar
        </Button>
      </Col>
    </Row>
  );
};
