import React from 'react';

import { FilterHeaderProps } from './FilterHeader.interface';
import * as S from './FilterHeader.style';

export const FilterHeader: React.FC<FilterHeaderProps> = ({
  buttonList = [],
  customFilter = [],
}) => {
  return (
    <S.Container>
      <S.LeftGroupContainer>
        {buttonList.map((button, index) => {
          return (
            <S.ButtonContainer key={`filter-header-button-${index}`}>
              {button}
            </S.ButtonContainer>
          );
        })}
      </S.LeftGroupContainer>

      <S.RightGroupContainer>
        {customFilter.map((filter, index) => (
          <S.CustomFilter key={`filter-header-${index}`}>
            {' '}
            {filter}{' '}
          </S.CustomFilter>
        ))}
      </S.RightGroupContainer>
    </S.Container>
  );
};
