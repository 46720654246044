import {
  SpecialistFormData,
  SpecialistPayload,
  SpecialistResponse,
} from '../types/specialistPageTypings';

export const specialistFunnelsPagesFactory = (payload: SpecialistFormData) => {
  const body: SpecialistPayload = {
    cancellationFunnelId: Number(payload.cancellationFunnelId),
    pageType: 'specialist',
    name: payload.titlePage,
    content: {
      title: payload.title,
      subtitle: payload.subtitle,
      titleButtonEmail: payload.buttonTitle,
      email: payload.email,
      titleButtonWhatsapp: payload.buttonTitleWhatsApp,
      titleButtonZendesk: payload.buttonTitleZendesk,
      zendeskReasonOptions: payload.buttonTitleZendesk
        ? payload.zendeskReasonTags
        : null,
      whatsapp: payload.whatsApp,
      interaction: payload.interaction,
      continueCancelationButtonTitle:
        payload.continueCancelationButtonTitle || null,
      keepSubscriptionButtonTitle: payload.keepSubscriptionButtonTitle || null,
    },
  };

  return body;
};

export const specialistFunnelsPagesResponseFactory = (
  response: SpecialistResponse
) => {
  const body: SpecialistFormData = {
    titlePage: response.name,
    title: response.content.title,
    subtitle: response.content.subtitle,
    buttonTitle: response.content.titleButtonEmail,
    email: response.content.email,
    buttonTitleWhatsApp: response.content.titleButtonWhatsapp,
    whatsApp: response.content.whatsapp,
    buttonTitleZendesk: response.content.titleButtonZendesk,
    zendeskReasonTags: response.content.zendeskReasonOptions ?? [],
    interaction: response.content.interaction,
    enableEmail: !!response.content.email,
    enableWhatsapp: !!response.content.whatsapp,
    enableZendesk: !!response.content.titleButtonZendesk,
    cancellationFunnelId: response.cancellationFunnelId,
    continueCancelationButtonTitle:
      response.content.continueCancelationButtonTitle,
    keepSubscriptionButtonTitle: response.content.keepSubscriptionButtonTitle,
  };

  return body;
};
