import { Image } from 'antd';
import styled from 'styled-components';

export const Title = styled.div`
  color: #525252;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  padding-top: 12px;
  text-align: center;
`;

export const Description = styled.div`
  color: #525252;
  font-size: 14px;
  font-weight: 400;
  padding-top: 4px;
  line-height: 17.5px;
  text-align: center;
`;

export const CustomImage = styled(Image)`
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const CarouselContainer = styled.div`
  .ant-carousel .slick-dots li button {
    background: #ccc;
    height: 10px;
    width: 10px;
    border-radius: 6px;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #ff4f00;
  }
`;

export const Body = styled.div``;
