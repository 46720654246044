import { TUpsellStepsFlowManage } from '@hooks';
import { Form, Modal, Select } from 'antd';

export type ManageEdgeModalProps = {
  toStepFlow: TUpsellStepsFlowManage;
  stepFlow: TUpsellStepsFlowManage;
  onSelectFinish: (upSellStepUpdated: TUpsellStepsFlowManage) => void;
  onCloseModal: () => void;
};

type FormData = {
  selectedMode: 'Sim' | 'Não' | 'Todos';
};

export function ManageEdgeModal({
  onCloseModal,
  stepFlow,
  toStepFlow,
  onSelectFinish,
}: ManageEdgeModalProps) {
  const [form] = Form.useForm<FormData>();
  const handleSubmit = async ({ selectedMode }: FormData) => {
    try {
      onSelectFinish({
        ...stepFlow,
        ...(selectedMode === 'Não' ? { down: toStepFlow.id } : {}),
        ...(selectedMode === 'Sim' ? { up: toStepFlow.id } : {}),
        ...(selectedMode === 'Todos'
          ? { up: toStepFlow.id, down: toStepFlow.id }
          : {}),
      });
    } catch (err) {
      console.error('>> error ao salvar', err);
    } finally {
      onCloseModal();
    }
  };

  const handleOk = async () => {
    try {
      await form.validateFields();

      form.submit();
    } catch (err) {
      console.error('Error submit', err);
    }
  };

  const handleCancel = () => {
    onCloseModal();
  };

  return (
    <Modal
      title={`${stepFlow.name} -> ${toStepFlow.name}`}
      open
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Selecionar'
      closable={false}
      maskClosable={false}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        validateTrigger='submit'
      >
        <Form.Item
          label={`Tipo de Conexão`}
          name={'selectedMode'}
          required
          rules={[{ required: true, message: 'Favor selecionar um link!' }]}
        >
          <Select
            defaultActiveFirstOption
            showArrow
            filterOption={false}
            options={['Todos', 'Sim', 'Não'].map(d => ({
              value: d,
              label: d,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
