import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  margin-top: 12px;
  border-top: 1px solid #2d2d2d;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 0 24px;
`;
export const CancelButtonContainer = styled.div`
  margin-right: 8px;
`;
export const ContinueButtonContainer = styled.div``;
