import { Button, Modal } from 'antd';
import { FC } from 'react';

type ModalProps = {
  confirmButtonLabel?: string;
  confirmButtonAction: () => void;
  denyButtonLabel?: string;
  denyButtonAction: () => void;
  titleLabel: string;
  messageLabel: string;
  showModal: boolean;
};

export const ModalAlert: FC<ModalProps> = ({
  confirmButtonLabel = 'Confirmar',
  denyButtonLabel = 'Cancelar',
  confirmButtonAction,
  denyButtonAction,
  showModal,
  titleLabel,
  messageLabel,
}) => {
  const IsCancelationButton = () => {
    return (
      <>
        <Button
          key='not'
          data-testid='modal-alert-deny-button'
          onClick={denyButtonAction}
        >
          {denyButtonLabel}
        </Button>
        <Button
          key='yes'
          data-testid='modal-alert-confirm-button'
          type='primary'
          onClick={confirmButtonAction}
        >
          {confirmButtonLabel}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={titleLabel}
      open={showModal}
      onCancel={denyButtonAction}
      footer={[IsCancelationButton()]}
    >
      {messageLabel}
    </Modal>
  );
};
