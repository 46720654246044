import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { FunctionalityFormData } from '../../types/functionalityTypings';
import { functionalityPayloadFactory } from '../../utils/functionalityFunnelsPageFactory';

export function useTryToRequestCreatingFunctionalityFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: FunctionalityFormData) => {
      const factory = await functionalityPayloadFactory(payload);

      const response =
        await apiCancellationFunnels.tryToRequestCreatingFunctionality(factory);

      return response;
    },
    {
      onSuccess: success => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao crir o funil de Funcionalidade',
          'Ocorreu um erro ao criar o funil de Funcionalidade, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestCreatingFunctionalityFunnelPage: mutateAsync,
    isLoadingCreatingFunctionality: isLoading,
  };
}
