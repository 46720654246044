import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { TransactionItunesResponse } from '../types/clientTypes';

const fetchItunesAssign = async (emailParamAdyenAssign?: string) => {
  if (emailParamAdyenAssign) {
    const response = await clientApi.getItunesTableTransactions(
      emailParamAdyenAssign
    );

    if ((response as TransactionItunesResponse).status === Success) {
      return (response as TransactionItunesResponse).data;
    } else {
      OpenNotification(
        true,
        'Erro ao carregar as transações',
        'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
      );
    }
  }
};

export default function useTransactionsItunes(email?: string) {
  return useQuery(
    ['transactionsItunes', email],
    () => fetchItunesAssign(email),
    {
      refetchOnWindowFocus: false,
    }
  );
}
