import { Title } from '@atoms';
import { DatePickerController, SwitchController } from '@organisms';
import moment from 'moment';
import { useEffect } from 'react';

import {
  ModalModelProperties,
  ModelStepBaseComponentProp,
} from '../ModalDetail';

export const ScheduleComponent = ({
  form,
}: ModelStepBaseComponentProp<ModalModelProperties>) => {
  useEffect(() => {
    const values = form?.getValues();
    if (!values?.effectiveDateTime || !values?.expirationDateTime) return;
    form.trigger();
  }, []);

  const effectiveDisable = (currentDate: moment.Moment) => {
    const date = new Date();
    return currentDate < moment(date.setDate(date.getDate() - 1));
  };

  const expirationDisable = (currentDate: moment.Moment) => {
    const initialDate = form.getValues('effectiveDateTime');

    if (initialDate) {
      return currentDate.isBefore(initialDate);
    }

    const date = new Date();
    return currentDate < moment(date.setDate(date.getDate()));
  };

  return (
    <>
      <Title title='Data de Agendamento' />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ paddingRight: '24px', width: '100%' }}>
          <Title title='Data da publicação' />
          <DatePickerController
            {...form.register('effectiveDateTime')}
            control={form.control}
            label=''
            name='effectiveDateTime'
            placeholder='DD/MM/AAAA HH:mm'
            format='DD/MM/YYYY HH:mm'
            showTime
            disableDate={effectiveDisable}
            autoComplete='off'
          />
        </div>
        <div style={{ paddingRight: '24px', width: '100%' }}>
          <Title title='Data de encerramento' />
          <DatePickerController
            {...form.register('expirationDateTime')}
            control={form.control}
            label=''
            name='expirationDateTime'
            placeholder='DD/MM/AAAA HH:mm'
            format='DD/MM/YYYY HH:mm'
            disableDate={expirationDisable}
            showTime
            autoComplete='off'
          />
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <Title title='Status' />
          <div
            style={{
              marginTop: '-12px',
            }}
          >
            <SwitchController
              sideLabel
              {...form.register('activated')}
              control={form.control}
              rightLabel='Ativo'
              leftLabel='Inativo'
              name='activated'
              autoComplete='off'
            />
          </div>
        </div>
      </div>
    </>
  );
};
