import { QueryClient } from 'react-query';

export function createApplicationQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: { retry: false, cacheTime: Infinity },
    },
  });
}

export const queryClient = createApplicationQueryClient();
