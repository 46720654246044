import { slices } from '@core/redux';
import { coupons } from '@service/api';
import {
  createPhysicCoupon,
  TCreatePhysicCouponProps,
} from '@service/api/coupons/physic/createPhysicCoupon';
import {
  editPhysicCoupon,
  TEditPhysicCouponProps,
} from '@service/api/coupons/physic/editPhysicCoupon';
// import { coupons } from '@service/api';
import { PageLimitFilter } from '@typings';
import { Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { offerApi } from 'src/features/offers/apis/offerApis';
import { CouponPhysic } from 'src/models';

import OpenNotification from '../../../../components/antd/OpenNotification';
import { TableCouponPhysicProps } from '../physic/TablePhysic.coupons';
import { TFormData } from '../physic/types/formDataType';

export const useProductPhysicCoupon = () => {
  const dispatch = useDispatch();

  const [couponList, setCouponList] = useState<CouponPhysic.TCouponResponse>();

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  useBreadcrumbs([
    {
      label: 'Gestão de Cupons',
    },
    {
      label: 'Produto físico',
    },
  ]);

  const getAllCoupons = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await coupons.getPhysicalCoupons(props);
      setCouponList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllCoupons();
  }, []);

  const handleChangePagination: TableCouponPhysicProps['onChangePagination'] = (
    take,
    _,
    pagination
  ) => {
    getAllCoupons({
      ...currentFilter,
      limit: take,
      page: pagination,
    });
  };

  const handleFilterCoupon = useCallback(
    (filterList: { field: string; value?: string }[]) => {
      const current = {
        ...currentFilter,
        filter: {
          ...currentFilter?.filter,
        },
      };

      filterList.forEach(filterToAdd => {
        if (!filterToAdd.value) {
          delete current.filter?.[filterToAdd.field];
        } else {
          current.filter[filterToAdd.field] = filterToAdd.value;
        }
      });

      getAllCoupons(current as PageLimitFilter);
    },
    [getAllCoupons, currentFilter]
  );

  const handleOrderCoupon = useCallback(
    (order: string) => {
      getAllCoupons({ ...currentFilter, order });
    },
    [getAllCoupons, currentFilter]
  );

  const dispatchSubmit = useCallback(() => {
    formRef.current?.submit();
  }, [formRef.current]);

  const onSaveCoupon = useCallback(() => {
    Modal.success({
      title: 'Concluído!',
      content: 'Cupom salvo com sucesso!',
    });
    history.push('/screens/coupons?show=physic');
  }, [history]);

  const handleCancel = useCallback(() => {
    history.push('/screens/coupons?show=physic');
  }, [history]);

  const INFINITE_DISCOUNT_LIMIT = 10000000;

  const { isLoading: isLoadingProductOptions, data: productOptionsResult } =
    useQuery(['product-options'], () => offerApi.getProducts(), {
      onError: err => OpenNotification(true, 'Erro', handleDisplayError(err)),
      select: products => {
        return products.map(product => {
          return {
            label: product.name,
            value: Number(product.id),
          };
        });
      },
      refetchOnWindowFocus: false,
    });

  const productOptions = useMemo(() => {
    return productOptionsResult || [];
  }, [productOptionsResult]);

  const submitCreateCoupon = async (formData: TFormData) => {
    const {
      code,
      description,
      startDate,
      endDate,
      limitValue,
      typeDiscount,
      value,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;

    const payload: TCreatePhysicCouponProps = {
      code,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      discount: {
        type: typeDiscount,
        value: Number(value || '0'),
      },
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
    };
    await createPhysicCoupon(payload);
  };

  const submitEditCoupon = async (couponId: number, formData: TFormData) => {
    const {
      description,
      startDate,
      endDate,
      limitValue,
      typeDiscount,
      value,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;

    const payload: TEditPhysicCouponProps = {
      id: couponId,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      discount: {
        type: typeDiscount,
        value: Number(value || '0'),
      },
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
    };
    await editPhysicCoupon(payload);
  };

  const handleDisplayError = (err: unknown) => {
    console.error(err);
    if (isSerializedApiError(err))
      return err.message || `Problema de comunicação com a API`;
    return `Erro ao salvar. Contate a equipe técnica.`;
  };

  return {
    couponList,
    isLoadingData,
    handleChangePagination,
    handleFilterCoupon,
    handleOrderCoupon,
    dispatchSubmit,
    onSaveCoupon,
    handleCancel,
    formRef,
    isLoading,
    setIsLoading,
    INFINITE_DISCOUNT_LIMIT,
    productOptions,
    isLoadingProductOptions,
    submitCreateCoupon,
    submitEditCoupon,
    handleDisplayError,
  };
};
