import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { array, date, object, SchemaOf, string } from 'yup';

// import { DatePicker } from 'src/app/components/antd';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { RoleType } from 'src/features/roles/api/roleApi';
import useGetAllRoles from 'src/features/roles/hooks/useGetAllRoles';
import useGetAllSectors from 'src/features/sector/hooks/useGetAllSectors';

import { adminApi } from '../../api/adminApi';
import { AdminDTOType } from '../../types/adminTypes';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  handleOk: () => void;
};

const ErrorMessage = 'Campo Obrigatório';

const schema: SchemaOf<AdminDTOType> = object({
  id: string(),
  lastAccess: string(),
  fullName: string().required(ErrorMessage),
  document: string()
    .required(ErrorMessage)
    .test('isValidCPF', 'CPF inválido', value => isValidCPF(value)),
  email: string().email().required(ErrorMessage),
  birthDate: date().required(ErrorMessage),
  roles: array().required(ErrorMessage),
  sector: string().required(ErrorMessage),
}).defined();

function isValidCPF(cpf?: string) {
  if (!cpf) return false;

  const cpfNumbers = cpf.toString().replace(/\D/g, '');

  if (cpfNumbers.length !== 11) return false;

  for (let i = 0; i < 10; i++) {
    if (cpfNumbers === Array(12).join(String(i))) return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpfNumbers.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpfNumbers.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpfNumbers.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpfNumbers.substring(10, 11))) return false;

  return true;
}

function maskCPF(cpf: string) {
  if (!cpf) return '';

  const cpfNumbers = cpf.toString().replace(/\D/g, '');

  if (cpfNumbers.length > 11) {
    return cpfNumbers
      .slice(0, 11)
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return cpfNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export const ModalAddAdmin: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
}) => {
  const [form] = Form.useForm<AdminDTOType>();

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<AdminDTOType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data: AdminDTOType) => {
    mutate(data);
  });

  const createAdmin = (data: AdminDTOType) => adminApi.createAdmin(data);

  const { mutate } = useMutation(createAdmin, {
    onSuccess: data => {
      OpenNotification(false, 'Sucesso!', 'Admin cadastrado com sucesso.');
      handleOk();
    },
  });

  const handleCancel = () => {
    form.resetFields();

    setValue('fullName', '');
    setValue('document', '');
    setValue('email', '');
    setValue('birthDate', undefined);
    setValue('roles', []);
    setValue('sector', '');
    clearErrors();

    setIsModalVisible(false);
  };

  const responseRoles = useGetAllRoles();
  const responseSector = useGetAllSectors();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options = (response: any) => {
    const arrayOptions: JSX.Element[] = [];
    if (response) {
      response.map((item: RoleType, index: number) => {
        return arrayOptions.push(
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        );
      });
    }
    return arrayOptions;
  };

  const handleChangeDate = (date: moment.Moment | null) => {
    console.log(date);
    setValue('birthDate', moment(date).toDate() || undefined);
  };

  return (
    <>
      <Modal
        title='Cadastrar Admin'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        width={640}
        footer={[
          <Button key='back' data-testid='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key='submit'
            htmlType='submit'
            onClick={onSubmit}
            type='primary'
            data-testid='submit'
          >
            Salvar
          </Button>,
        ]}
      >
        <Form style={{ width: '100%' }} form={form} layout='horizontal'>
          <Row>
            <Col span={24}>
              <Label>Nome</Label>
              <Controller
                control={control}
                name='fullName'
                render={({ field }) => (
                  <Input
                    style={{ width: '100%', marginTop: 8 }}
                    data-testid='fullName'
                    {...field}
                  />
                )}
              />
              <ErrorStyled>{errors.fullName?.message}</ErrorStyled>
              <Space />
            </Col>
            <Col span={24}>
              <Label>E-mail</Label>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <Input
                    data-testid='email'
                    style={{ width: '100%', marginTop: 8 }}
                    {...field}
                  />
                )}
              />
              <ErrorStyled>{errors.email?.message}</ErrorStyled>
              <Space />
            </Col>
            <Col span={12}>
              <Label>CPF</Label>
              <Controller
                control={control}
                name='document'
                render={({ field }) => (
                  <Input
                    data-testid='document'
                    style={{ width: '96%', marginTop: 8 }}
                    value={maskCPF(field.value)}
                    onChange={e => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorStyled>{errors.document?.message}</ErrorStyled>
              <Space />
            </Col>

            <Col span={12}>
              <Label>Data de Nascimento</Label>
              <Controller
                control={control}
                name='birthDate'
                render={({ field: { value } }) => (
                  <DatePicker
                    style={{ width: '100%', marginTop: 8 }}
                    format='DD/MM/yyyy'
                    data-testid='birthDate'
                    placeholder='Selecionar data'
                    onChange={handleChangeDate}
                    value={value ? moment(value) : undefined}
                  />
                )}
              />
              <ErrorStyled>{errors.birthDate?.message}</ErrorStyled>
              <Space />
            </Col>
            <Col span={12}>
              <Label>Papel</Label>
              <Controller
                name='roles'
                control={control}
                render={({ field }) => (
                  <Select
                    mode='multiple'
                    style={{ width: '96%', marginTop: 8 }}
                    {...field}
                  >
                    {options(responseRoles.data)}
                  </Select>
                )}
              />

              <ErrorStyled>
                {errors.roles ? 'Campo Obrigatório' : ''}
              </ErrorStyled>
            </Col>
            <Col span={12}>
              <Label>Setor</Label>
              <Controller
                name='sector'
                control={control}
                render={({ field }) => (
                  <Select
                    style={{ width: '100%', marginTop: 8 }}
                    allowClear
                    {...field}
                  >
                    {options(responseSector.data)}
                  </Select>
                )}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const Label = styled.label`
  margin-bottom: 18px;
  font-size: 12px;
  line-height: 22px;
`;
const ErrorStyled = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: #ff0000;
`;
const Space = styled.div`
  margin: 30px;
`;
