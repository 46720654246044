import { Modal } from '@models';
import { mixed, number, object, SchemaOf } from 'yup';

import { ModalModelProperties } from '../ModalDetail';

export const modalModelSchema: SchemaOf<ModalModelProperties> = object({
  segment: number().required('Este campo é obrigatório'),
  modalType: mixed()
    .oneOf(Object.values(Modal.TypeEnum))
    .required('Este campo é obrigatório'),
}).defined();
