import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

import { UpsellStepsFlow } from 'src/models';

const BASE_URL = enviroments.BASE_URL;

export async function getAllUpsellStepFlow(
  flowId: number
): Promise<UpsellStepsFlow.TUpsellStepsFlowResponse> {
  const response = await base({
    baseURL: BASE_URL,
    url: `/funnel/flow-steps?flowId=${flowId}`,
    method: 'GET',
    params: {},
  });

  return response.data;
}
