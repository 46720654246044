import styled from 'styled-components';

interface ColorViewProps {
  color: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > label {
    display: flex;
    height: 24px;
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    vertical-align: center;
  }
`;

export const ColorContainer = styled.button`
  all: unset;
  cursor: pointer;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #424242;
  display: flex;
  align-items: center;
  padding: 4px;
  width: 100px;
`;

export const ColorView = styled.div<ColorViewProps>`
  position: relative;
  display: flex;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.05);
  height: 24px;
  width: 24px;
  background-color: ${({ color }) => color};
`;

export const ColorText = styled.p`
  padding: 0;
  margin: 0;
  margin-left: 8px;
  text-transform: uppercase;
`;

export const InputColor = styled.input`
  height: 24px;
  width: 24px;
  opacity: 0;
  position: absolute;
`;
