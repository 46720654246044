import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerSubscriptions = (
  customerId: string,
  page: number,
  limit = 5
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-subscriptions', customerId, page, limit],
    queryFn: () =>
      customerApi.fetchCustomerSubscriptions(customerId, page, limit),
    refetchOnWindowFocus: false,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    subscriptions: data?.rows,
    totalCount: Number(data?.count),
    pageSize: limit,
    isLoading,
    isError,
    refetchOnError,
  };
};
