import { format, parseISO } from 'date-fns';

const formatDateString = (
  dateString: string | undefined,
  formatDate: string
): string => {
  if (!dateString) return '';

  const date = parseISO(dateString);
  return format(date, formatDate);
};

const formatDateToUTC = (
  dateString: string | undefined,
  formatDate: string
): string => {
  if (!dateString) return '';

  const date = parseISO(dateString);
  const millisecUtc = date.getTimezoneOffset() * 60 * 1000;

  const utcDate = new Date(date.getTime() + millisecUtc);

  return format(utcDate, formatDate);
};

export const dateUtils = {
  formatDateString,
  formatDateToUTC,
};
