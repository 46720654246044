import { ModalRecoveryAccess } from '@organisms';
import React, { useState } from 'react';

import * as S from './ForgotPasswordButton.style';

export const ForgotPasswordButton: React.FC = () => {
  const [isModalForgotPassVisible, setIsModalForgotPassVisible] =
    useState<boolean>(false);
  return (
    <>
      <S.Button
        data-testid='forget-password'
        type='link'
        onClick={() => setIsModalForgotPassVisible(true)}
      >
        Esqueci minha senha
      </S.Button>

      <ModalRecoveryAccess
        isModalVisible={isModalForgotPassVisible}
        setIsModalVisible={setIsModalForgotPassVisible}
      />
    </>
  );
};
