import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 132px);

  @media (max-height: 611px) {
    margin-bottom: 28px;
    min-height: calc(100vh - 78px);
  }
`;
export const ContainerCont = styled.div`
  flex: 1;

  @media (max-height: 850px) {
    margin-bottom: 28px;
  }
`;

export const InfoTitle = styled.div`
  margin-bottom: 24px;
`;

export const PaddingOverride = styled.div`
  margin-left: -120px;
  margin-right: -120px;

  height: 64px;
  flex-shrink: 0;
`;
