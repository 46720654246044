import {
  CredentialForm,
  ForgotPasswordButton,
  RememberMeCheckbox,
} from '@organisms';
import { auth } from '@service/api';
import { Button, Form, Row } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';

import * as S from './LoginForm.style';

const { Item: FormItem } = Form;

export interface LoginFormProps {
  onLogin: (credentials: auth.Credentials) => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onLogin }) => {
  const [isLoading] = useState<boolean>(false);

  return (
    <Row justify='center' align='middle'>
      <S.CredentialContainer>
        <CredentialForm onSubmitted={onLogin}>
          <Form.Item>
            <S.AuxiliarButtonsContainer>
              <RememberMeCheckbox />

              <ForgotPasswordButton />
            </S.AuxiliarButtonsContainer>
          </Form.Item>

          <FormItem>
            <SubmitButton
              data-testid='button-login'
              htmlType='submit'
              type='primary'
              loading={isLoading}
            >
              Log in
            </SubmitButton>
          </FormItem>
        </CredentialForm>
      </S.CredentialContainer>
    </Row>
  );
};
const SubmitButton = styled(Button)`
  width: 100%;
`;
