import { SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@organisms';
import { Col, Form, Input, Row } from 'antd';
import { addMonths } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import DatePicker from 'src/app/components/antd/DatePicker';
import { TabComponent } from 'src/app/components/antd/TabComponent';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { cpfMask, phoneMask } from 'src/app/helpers/StringHelper';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import useGetAllUsers from '../../hooks/useGetAllUsers';
import { ClientType } from '../../types/clientTypes';
import { verifyFilter } from '../../utils/userUtils';

export const ClientScreenListing: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Clientes',
    },
  ]);
  const history = useHistory();

  const [form] = Form.useForm();
  const [initialDate, setInitialDate] = useState<Date | undefined>();
  const [initialDateToUri, setInitialDateToUri] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [endDateToUri, setEndDateToUri] = useState<Date | undefined>();
  const [filter, setFilter] = useState<string | undefined>();
  const [filterValue, setFilterValue] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [id, setId] = useState<number | undefined>();
  const [idValue, setIdValue] = useState<number | undefined>();
  const limitTimer = 1500;
  const typedCharacters = 3;

  const { data, isLoading } = useGetAllUsers(
    page,
    initialDateToUri,
    endDateToUri,
    idValue,
    filter
  );

  const onClickRow = (item: ClientType) => {
    history.push(`cliente/${item.id}`);
  };

  const onChangeInitialDate = (
    dateInitial: Date | null,
    dateString: string
  ) => {
    setInitialDate(dateInitial ? dateInitial : undefined);
  };

  const onChangeEndDate = (dateEnd: Date | null, dateString: string) => {
    setEndDate(dateEnd ? dateEnd : undefined);
  };

  const onChangeId = (paramId: string) => {
    let number = undefined;
    if (paramId) {
      number = Number(paramId);
    }
    setId(number);
  };

  const onChange = (onChangeParam: string) => {
    setFilterValue(onChangeParam);
  };

  useEffect(() => {
    if (filterValue !== undefined && filterValue.length === 0) {
      const timeOut = setTimeout(() => {
        setFilter(filterValue);
      }, limitTimer);
      return () => clearTimeout(timeOut);
    }

    if (filterValue && filterValue.length >= typedCharacters) {
      const timeOut = setTimeout(() => {
        setFilter(filterValue);
      }, limitTimer);
      return () => clearTimeout(timeOut);
    }
  }, [filterValue]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIdValue(id);
    }, limitTimer);
    return () => clearTimeout(timeOut);
  }, [id]);

  useEffect(() => {
    if (initialDate && endDate) {
      setInitialDateToUri(initialDate);
      setEndDateToUri(endDate);
    }
  }, [initialDate, endDate]);

  const onChangePage = (onChangePageParam: number) => {
    setPage(onChangePageParam);
  };
  return (
    <>
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Clientes'}
          subTitle={''}
        />
        <Space />
      </Row>
      <Form layout='inline' form={form} style={{ marginBottom: 10 }}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 6 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Form.Item
            style={{ display: 'contents !important' }}
            label='Clientes de'
          >
            <DatePicker
              format='dd/MM/yyyy'
              data-testid='data'
              placeholder='Selecionar data'
              style={{ float: 'right', width: '100%' }}
              onChange={onChangeInitialDate}
              value={initialDate}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 6 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Form.Item label='Até'>
            <DatePicker
              data-testid='ate'
              format='dd/MM/yyyy'
              placeholder='Selecionar data'
              style={{ float: 'right', width: '100%' }}
              onChange={onChangeEndDate}
              value={endDate}
              disabledDate={current => {
                if (!initialDate) {
                  return false;
                } else {
                  const three = 3;
                  return (
                    current <= initialDate ||
                    current >= addMonths(initialDate, three)
                  );
                }
              }}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 4 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Form.Item>
            <InputStyled
              type='number'
              data-testid='number'
              placeholder='Buscar por ID'
              style={{ MozAppearance: 'none' }}
              value={id}
              suffix={<SearchOutlined />}
              onChange={e => onChangeId(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 8 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Form.Item>
            <Input
              type='text'
              data-testid='text'
              placeholder='Buscar por nome, e-mail, CPF ou telefone'
              value={filterValue}
              suffix={<SearchOutlined />}
              onChange={e => onChange(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Form>
      <TabComponent tabList={['Todos']} onChangeTab={() => 'Todos'} />
      <Row>
        <Col span={24}>
          <TableComponent
            data-testid='table'
            loading={isLoading}
            pageSize={20}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
              },
              {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
              },

              {
                title: 'Documento',
                dataIndex: 'document',
                key: 'document',
                render: (document: string) => <div>{cpfMask(document)}</div>,
              },
              {
                title: 'E-mail',
                key: 'email',
                dataIndex: 'email',
              },
              {
                title: 'Telefone',
                key: 'phone',
                dataIndex: 'phone',
                render: (phone: string) => <div>{phoneMask(phone)}</div>,
              },
              {
                title: 'Ultimo Acesso (UTC)',
                key: 'lastSeen',
                dataIndex: 'lastSeen',
                render: (lastSeen: string) => (
                  <div>{dateFormatted(lastSeen, true)}</div>
                ),
              },
            ]}
            data={data?.users}
            total={verifyFilter(
              data?.total,
              initialDateToUri,
              endDateToUri,
              idValue,
              filter
            )}
            onClickRow={onClickRow}
            onChangePage={onChangePage}
          />
        </Col>
      </Row>
    </>
  );
};

const InputStyled = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
const Space = styled.div`
  margin-top: 48px;
`;
