import * as S from './Launch.style';

const Launch = () => {
  return (
    <>
      <S.ModalModelImageSkeleton>
        <S.ModalModelItemImageSkeletonContainer>
          <S.ModalModelItemImageSkeleton />
        </S.ModalModelItemImageSkeletonContainer>

        <S.ModalModelSpace>
          <S.ModalModelTitleSkeleton />
          <S.ModalModelDescriptionSkeleton />
          <S.ModalModelDescriptionSkeleton />
          <S.ModalModelButtonSkeleton />
        </S.ModalModelSpace>
      </S.ModalModelImageSkeleton>

      <S.FooterContainer>
        <S.ModalModelSquareSkeleton />
        <S.ModalModelSquareSkeleton />
        <S.ModalModelSquareSkeleton />
      </S.FooterContainer>
    </>
  );
};
export default Launch;
