import { RootState } from '@core/redux';
import { FilterHeader } from '@organisms';
import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

interface FilterModalsProps {
  onFilter: (handle: { field: string; value?: string }[]) => void;
  filter?: number;
  setFilter: (value: number) => void;
}

export const FilterOrdering: React.FC<FilterModalsProps> = ({
  onFilter,
  filter,
  setFilter,
}) => {
  const { segments } = useSelector((state: RootState) => state.segments);

  return (
    <FilterHeader
      buttonList={[
        <Select
          placeholder={'Segmento'}
          data-testid={'asset-segment-filter-select'}
          onChange={value => {
            console.log(value);
            setFilter(value);

            if (!value) {
              onFilter([{ field: 'segmentId', value: undefined }]);
              return;
            }
            onFilter([{ field: 'segmentId', value: value.toString() }]);
          }}
          options={segments?.map(segment => ({
            label: segment.name,
            value: segment.id,
          }))}
          optionFilterProp='label'
          style={{ width: '400px' }}
          allowClear
          value={filter}
          showSearch
        />,
      ]}
      customFilter={[]}
    />
  );
};
