export const FitnessCenterSvg = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_4384_10032'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='40'
      height='40'
    >
      <rect width='40' height='40' fill='white' />
    </mask>
    <g mask='url(#mask0_4384_10032)'>
      <path
        d='M22.7222 36.5L20.7778 34.5556L26.6945 28.6389L11.3611 13.3055L5.44442 19.2222L3.5 17.2778L5.83333 14.8611L3.5 12.5278L7.02779 9L5.08333 7.02779L7.02779 5.08333L9.02775 7L12.5278 3.5L14.8611 5.83333L17.2778 3.5L19.2222 5.44442L13.3055 11.3611L28.6389 26.6945L34.5556 20.7778L36.5 22.7222L34.1667 25.1389L36.5 27.4722L32.9445 31.0278L34.8889 33L32.9445 34.9444L30.9722 33L27.4722 36.5L25.1389 34.1667L22.7222 36.5Z'
        fill='white'
      />
    </g>
  </svg>
);
