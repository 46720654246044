import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { AdyenAssignResponse } from '../types/clientTypes';

const fetchAdyenAssignByCustomerId = async (customerId?: number) => {
  if (!customerId || customerId === 0) return [];
  const response = await clientApi.getAdyenTableAssignByCustomerId(customerId);

  return processResponse(response);
};

const fetchAdyenAssignByEmail = async (emailParamAdyenAssign?: string) => {
  if (!emailParamAdyenAssign) return [];
  const response = await clientApi.getAdyenTableAssignByEmail(
    emailParamAdyenAssign
  );

  return processResponse(response);
};

const processResponse = (response: unknown) => {
  if ((response as AdyenAssignResponse).status === Success) {
    return (response as AdyenAssignResponse).data.subscriptions;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as transações',
      'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useSubscriptionsAdyenByCustomerId(customerId: number) {
  return useQuery(
    ['assignAdyen', customerId],
    () => fetchAdyenAssignByCustomerId(customerId),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useSubscriptionsAdyenByEmail(email?: string) {
  return useQuery(
    ['assignAdyen', email],
    () => fetchAdyenAssignByEmail(email),
    {
      refetchOnWindowFocus: false,
    }
  );
}
