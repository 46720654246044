import { Modal } from '@models';
import { RcFile } from 'antd/lib/upload';
import { array, boolean, mixed, object, SchemaOf, string } from 'yup';

export const paginationContentSchema: SchemaOf<Modal.IPaginationContent> =
  object({
    urlLocalShow: string().required('Este campo é obrigatório'),
    modalContent: array()
      .of(
        object({
          id: string().notRequired(),
          mediaType: string().required('Este campo é obrigatório'),
          title: string().required('Este campo é obrigatório'),
          titleActivated: boolean().notRequired(),
          description: string().required('Este campo é obrigatório'),
          textButton: string().notRequired(),
          urlVideoModal: string().when('mediaType', {
            is: 'VIDEO',
            then: string().required(
              'Campo obrigatório caso o tipo de mídia seja vídeo'
            ),
            otherwise: string().notRequired(),
          }),
          imageMobile: mixed<RcFile | string>().when('mediaType', {
            is: 'IMAGE',
            then: mixed<RcFile | string>().required(
              'Campo obrigatório caso o tipo de mídia seja imagem'
            ),
            otherwise: mixed<RcFile | string>().notRequired(),
          }),
          imageWeb: mixed<RcFile | string>().when('mediaType', {
            is: 'IMAGE',
            then: mixed<RcFile | string>().required(
              'Campo obrigatório caso o tipo de mídia seja imagem'
            ),
            otherwise: mixed<RcFile | string>().notRequired(),
          }),
          redirectButtonUrl: string().when('textButton', {
            is: (textButton: string) => textButton && textButton.length > 0,
            then: string()
              .required(
                'Este campo é obrigatório caso o texto do botão esteja preenchido'
              )
              .matches(
                /^https:\/\//,
                'A URL de redirecionamento deve começar com https://'
              ),
            otherwise: string().notRequired(),
          }),
          openExternalWindow: boolean(),
        })
      )
      .min(2, 'O conteúdo do modal deve conter no mínimo 2 itens')
      .required('O conteúdo do modal é obrigatório'),
  }).defined();
