import { Alert } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export type AlertComponentProps = {
  type?: 'success' | 'error';
  close: (item?: void) => void;
  message: string;
};

export const AlertComponent: FC<AlertComponentProps> = ({
  type,
  close,
  message,
}) => (
  <DivAlert>
    <AlertStyled
      message={message}
      type={type}
      closable
      showIcon
      afterClose={close}
    />
  </DivAlert>
);

const DivAlert = styled.div`
  margin: auto;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
`;

const AlertStyled = styled(Alert)`
  background: #141414;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.48), 0px 6px 16px rgba(0, 0, 0, 0.32),
    0px 9px 28px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: none;
  width: auto;
  align-items: center;
  z-index: auto;
  position: relative;
`;
