import { UserOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { Avatar, Layout, Menu, Popover } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../../../app/framework/atoms/Icons/Logo';
import { ModalChangePassword } from './ModalChangePassword';
export const AppHeader: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(slices.auth.tryLogout()), []);
  const history = useHistory();
  const content = (
    <div>
      <MenuSelect onClick={() => setIsModalVisible(true)}>
        Alterar senha
      </MenuSelect>
      <MenuSelect onClick={onLogout} style={{ color: 'red' }}>
        Sair
      </MenuSelect>
    </div>
  );
  return (
    <>
      <Header style={{ paddingInline: '120px' }}>
        <RightMenu theme='dark' mode='horizontal'>
          <Menu.Item key='logo' style={{ cursor: 'initial' }}>
            <BrandLogo
              style={{ marginBottom: 0, cursor: 'initial' }}
              width={138}
              height={15}
            />
          </Menu.Item>
          <Menu.Item key='admin' onClick={() => history.push('/dashboard')}>
            Admin
          </Menu.Item>

          <Menu.Item
            disabled
            key='midia'
            onClick={() => history.push('/midia')}
          >
            Mídia
          </Menu.Item>
          <Menu.Item
            disabled
            key='engenharia'
            onClick={() => history.push('/engenharia')}
          >
            Engenharia
          </Menu.Item>
          <Menu.Item
            key='Social'
            onClick={() => history.push('/social/dashboard')}
          >
            Social
          </Menu.Item>
          <Menu.Item
            style={{
              background: 'inherit',
              position: 'absolute',
              right: 10,
              paddingInline: '120px',
            }}
            key='logout'
          >
            <Popover content={content} trigger='click'>
              <Avatar icon={<UserOutlined />} />
            </Popover>
          </Menu.Item>
        </RightMenu>
      </Header>
      <ModalChangePassword
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

const RightMenu = styled(Menu)`
  height: 64px;
`;

const Header = styled(Layout.Header)`
  padding: 0px;
`;
const BrandLogo = styled(Logo)`
  margin-bottom: 0;
`;

const MenuSelect = styled.p`
  cursor: pointer;
`;
