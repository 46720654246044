import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { GeneralError } from 'src/app/components/Error';
import { PageHeader } from 'src/app/components/PageHeader';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { gameficationCompetition } from '../client.api';
import { GameficationManageCompetitionFormStep1 as FormStep1 } from '../components/Form';
import { GameficationManageCompetitionFormStep2 as FormStep2 } from '../components/Form';
import { GameficationManageCompetitionFormStep3 as FormStep3 } from '../components/Form';
import { GameficationManageCompetitionFormStep4 as FormStep4 } from '../components/Form';
import { GameficationManageCompetitionFormStep5 as FormStep5 } from '../components/Form';
import { GameficationManageCompetitionFormStep7 as FormStep7 } from '../components/Form';
import { ICompetition } from '../dto/competition.interface';

const { TabPane } = Tabs;

interface ManageCompetitionTab {
  label: string;
  render: JSX.Element;
}

const GameficationCompetitionForm: FC = () => {
  const TITLE = 'Gerenciar Competição';

  useBreadcrumbs([{ label: 'Gerenciar Competições' }, { label: TITLE }]);
  const URL_PARAMS: ICompetition = useParams();
  const isEdit = Boolean(URL_PARAMS?.id);

  const [disableney, setDisable] = useState<boolean>(isEdit);

  const _callApi = async () => {
    if (isEdit) {
      const client = gameficationCompetition.getCompetition;
      const response = await client(URL_PARAMS);
      return response?.data;
    }
  };

  const OPTS = { refetchOnWindowFocus: false };
  const QUERY = useQuery('gamefication_competition', _callApi, OPTS);
  const { isFetching, data, error, refetch } = QUERY;

  const STEPS: ManageCompetitionTab[] = [
    {
      label: 'Dados básicos',
      render: <FormStep1 enableTabs={setDisable} initialValues={data} />,
    },
    { label: 'Atividades', render: <FormStep2 /> },
    { label: 'Público Alvo', render: <FormStep3 /> },
    { label: 'Conquistas', render: <FormStep4 /> },
    { label: 'Prêmios', render: <FormStep5 /> },
    {
      label: 'Moderação de resgate à prêmios',
      render: <>Design pendente :)</>,
    },
    { label: 'Alunos inscritos', render: <FormStep7 /> },
  ];

  const RENDER_TABS = (
    <Tabs style={{ marginTop: 20 }}>
      {STEPS.map((step: ManageCompetitionTab, key: number) => (
        <TabPane
          disabled={!disableney}
          tab={step.label}
          key={`manageCompetitionStep${key++}`}
        >
          {step.render}
        </TabPane>
      ))}
    </Tabs>
  );

  return (
    <>
      <PageHeader title={TITLE} />

      {error && <GeneralError action={refetch} />}
      {!isFetching && RENDER_TABS}
    </>
  );
};

export default GameficationCompetitionForm;
