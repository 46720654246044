import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { SpecialistFormData } from '../../types/specialistPageTypings';
import { specialistFunnelsPagesFactory } from '../../utils/specialistFunnelsPagesFactory';

export function useTryToRequestCreatingSpecialistFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: SpecialistFormData) => {
      const body = specialistFunnelsPagesFactory(payload);

      return await apiCancellationFunnels.tryToRequestCreatingSpecialistFunnelPage(
        body
      );
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao crir o funil de Atendimento Especialista',
          'Ocorreu um erro ao crir o funil de Atendimento Especialista, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return { tryToRequestCreatingSpecialistFunnelPage: mutateAsync, isLoading };
}
