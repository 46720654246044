import { TableListIcon } from '@assets/icons/tableList';
import { useSegments } from '@hooks';
import { Assets } from '@models';
import { OrderTableAssets } from '@molecules';
import { Empty, Select } from 'antd';
import React, { useEffect } from 'react';

import { DEVICE_TYPE_LIST } from 'src/app/constants/assets';

import * as S from './AssetsOrderList.style';
interface AssetsOrderListProps {
  filteredAssetsList?: Assets.IAsset[];
  setChoosedAssetType: (value: Assets.AssetContext) => void;
  choosedAssetSegment?: number;
  setChoosedAssetSegment: (segmentId: number) => void;
  setChoosedDeviceType: (value: Assets.DeviceTypeEnum) => void;
  onChangeOrder: (value: Assets.IAsset[]) => void;
}

export const AssetsOrderList: React.FC<AssetsOrderListProps> = ({
  filteredAssetsList,
  setChoosedAssetType,
  choosedAssetSegment,
  setChoosedAssetSegment,
  setChoosedDeviceType,
  onChangeOrder,
}) => {
  const { getSegmentsList, segmentsList } = useSegments();

  useEffect(() => {
    getSegmentsList({ take: 10000 });
  }, []);

  return (
    <S.Container>
      <S.FilterContainer>
        <Select
          placeholder={'Tipo de asset'}
          data-testid={'banner-type-select'}
          allowClear
          options={Assets.AssetContextOptions}
          onChange={value => setChoosedAssetType(value)}
        />

        <Select
          placeholder={'Tipo do dispositivo'}
          data-testid={'banner-device-select'}
          options={DEVICE_TYPE_LIST.filter(device => !device.disabled).map(
            device => ({
              label: device.title,
              value: device.value,
            })
          )}
          allowClear
          onChange={value => setChoosedDeviceType(value)}
        />

        <Select
          placeholder={'Segmento'}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Nenhum segmento cadastrado'}
            />
          }
          data-testid={'asset-ordering-segments-select'}
          options={segmentsList?.rows.map(segment => ({
            label: segment.name,
            value: Number(segment.id),
          }))}
          showSearch
          optionFilterProp='label'
          value={choosedAssetSegment}
          allowClear
          onChange={value => setChoosedAssetSegment(value)}
        />
      </S.FilterContainer>
      <S.OrderListContainer>
        {!filteredAssetsList?.length && (
          <S.EmptyWarningContainer>
            <S.IconContainer>
              <TableListIcon />
            </S.IconContainer>

            <S.EmptyTitleContainer>
              Nenhuma ordenação por aqui
            </S.EmptyTitleContainer>

            <S.EmptySubTitleContainer>
              Para ordenar os assets é preciso selecionar o tipo de asset e
              dispositivo
            </S.EmptySubTitleContainer>
          </S.EmptyWarningContainer>
        )}

        {filteredAssetsList && filteredAssetsList?.length > 0 && (
          <OrderTableAssets
            columnTitle='Assets'
            changeOrder={newOrder => onChangeOrder(newOrder as Assets.IAsset[])}
            datasource={filteredAssetsList || []}
            showHeader={false}
          />
        )}
      </S.OrderListContainer>
    </S.Container>
  );
};
