import { slices } from '@core/redux';
import { Events, Programs } from '@models';
import { programs } from '@service/api';
import { TakeSkipFilter } from '@typings';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';

interface IOptions {
  value: string;
  label: string;
}

const getProgramsWithModulesSelectOptions = (
  programsList: Programs.ProgramsResponse[]
) => {
  const programsOptions: IOptions[] = [];
  const programsWithModulesOptions: IOptions[] = [];

  if (!programsList)
    return {
      programsOptions: [],
      programsWithModulesOptions: [],
    };

  for (const program of programsList) {
    programsOptions.push({
      value: `${program.id}-${program.name}`,
      label: `${program.id} - ${program.name}`,
    });

    for (const content of programsList) {
      programsWithModulesOptions.push({
        value: `${program.id}-${content.id}`,
        label: `${program.name}: ${content.name}: ${content.name}`,
      });
    }
  }

  return { programsOptions, programsWithModulesOptions };
};

export const usePrograms = () => {
  const dispatch = useDispatch();

  const [programsList, setProgramsList] =
    useState<Programs.ProgramsResponse[]>();

  const [programsWithModulesOption, setProgramsWithModulesOptions] =
    useState<IOptions[]>();
  const [pagination, setPagination] = useState<{ current: number }>({
    current: 1,
  });
  const [programsOptions, setProgramsOptions] = useState<IOptions[]>([]);

  const [filter, setFilter] = useState<{
    name: {
      iLike: string;
    };
  }>();

  const getAllPrograms = async (props?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!props) {
        props = {
          skip: 0,
          take: 10,
        };
      }

      const response = await programs.getAllPrograms(props);
      return response;
    } catch (err) {
      console.log(err);
      OpenNotification(true, 'Falha ao carregar os programas do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getProgramsList = async (props?: TakeSkipFilter) => {
    dispatch(slices.layout.increaseLoading());
    const response = await getAllPrograms(props);

    if (response) {
      const sortedResponse = response?.sort((a, b) => a.id - b.id);
      setProgramsList(sortedResponse);

      setProgramsWithModulesOptions(
        getProgramsWithModulesSelectOptions(sortedResponse)
          ?.programsWithModulesOptions
      );

      setProgramsOptions(
        getProgramsWithModulesSelectOptions(sortedResponse)?.programsOptions
      );
    }
    dispatch(slices.layout.decreaseLoading());
  };

  const filterByName = (programName: string) => {
    const takeNumber = 10;

    if (!programName) return;

    const filterObj = {
      name: { iLike: `%${programName.replace(' ', ' %')}%` },
    };

    setFilter(filterObj);

    getAllPrograms({
      take: takeNumber,
      skip: 0,
      filter: filterObj,
    });
  };

  const changePage = (page: number) => {
    const takeNumber = 10;
    setPagination({ current: page });
    getAllPrograms({
      filter: { ...filter },
      take: takeNumber,
      skip: page * takeNumber,
    });
  };

  const normalizeProgramsAndClasseseRequest = (programList: string[]) => {
    const eventContentList: any[] = [];
    programList.forEach(program => {
      const eventContent: Record<string, number> = {};
      const [programId, contentId] = program.split('-');

      eventContent['programId'] = +programId;

      if (contentId) {
        eventContent['contentId'] = +contentId;
      }

      eventContentList.push(eventContent);
    });

    return eventContentList as Events.EventsContents[];
  };

  return {
    getProgramsList,
    programsList,
    programsOptions,
    programsWithModulesOption,
    normalizeProgramsAndClasseseRequest,

    filterByName,
    pagination,
    changePage,
  };
};
