import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';

import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import { SelectRegraCondicional } from './select-regra-condicional';

interface Props {
  loading?: boolean;
  events?: Array<EventResponseDto>;
  onChange?: (
    index: number,
    value?: { value?: number; label: React.ReactNode }
  ) => void;
  onRemoveClick?: (index?: number) => void;
  onAddClick?: () => void;
  isLast?: boolean;
  index?: number;
  eventSelected?: EventResponseDto;
}

export const OrSelectRegraCondicional: React.FC<Props> = ({
  loading = false,
  isLast = false,
  events = [],
  onChange,
  onRemoveClick,
  onAddClick,
  index = undefined,
  eventSelected = undefined,
}) => {
  const handleButtonClick = () => {
    if (onAddClick && isLast) {
      onAddClick();
    } else if (onRemoveClick && !isLast) {
      onRemoveClick(index);
    }
  };

  const renderIcon = () => {
    if (isLast) {
      return <PlusCircleOutlined />;
    }
    return <DeleteOutlined />;
  };

  const handleOnChange = (value?: {
    value?: number;
    label: React.ReactNode;
  }) => {
    onChange?.(index as number, value);
  };

  return (
    <>
      <Col span={24} style={{ marginTop: 10 }} key={`extra-col-0`}>
        <Row>
          <Col span={2} style={{ padding: 5, color: '#ACACAC' }}>
            OU
          </Col>
          <Col span={20}>
            <SelectRegraCondicional
              eventSelected={eventSelected}
              events={events}
              onChange={handleOnChange}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <Tooltip
              placement='bottomRight'
              title={isLast ? 'Nova regra' : 'Remover'}
            >
              <Button
                onClick={handleButtonClick}
                type='link'
                block
                style={{ color: '#ACACAC' }}
              >
                {renderIcon()}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </>
  );
};
