import { yupResolver } from '@hookform/resolvers/yup';
import { DatePickerController } from '@organisms';
import { Form, Modal } from 'antd';
import { isAfter } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import useControllerModal from '../hooks/useControllerModal';
import { FreezingFormData } from '../interfaces/frezzingInterfaces';

export interface ModalFreezingProps {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
}

const validationSchema = yup.object().shape({
  startDate: yup
    .date()
    .required('A data de início é obrigatória')
    .test('min', 'A data de início deve ser posterior à data atual', value => {
      const currentDate = new Date();
      if (!value) return false;
      return isAfter(value, currentDate);
    }),
  returnDate: yup
    .date()
    .required('A data de retorno é obrigatória')
    .test(
      'min2',
      'A data de retorno deve ser maior ou igual à data de início',
      (value, context) => !!value && value >= context.parent.startDate
    ),
});

export const ModalFreezing = ({
  isVisible,
  setIsVisible,
}: ModalFreezingProps) => {
  const { createFreezing, isLoading } = useControllerModal();

  const handleAdd = () => {
    handleSubmit(data => createFreezing(data, () => setIsVisible(false)))();
  };

  const { control, handleSubmit } = useForm<FreezingFormData>({
    resolver: yupResolver(validationSchema),
  });

  const datePickerStyle = {
    background: 'rgba(20, 20, 20, 1)',
    border: '1px solid rgba(66, 66, 66, 1)',
    borderRadius: '6px',
  };

  return (
    <ModalAdmin
      title='Cadastrar Freezing'
      open={isVisible}
      style={{ minWidth: '656px' }}
      destroyOnClose={true}
      onCancel={() => setIsVisible(false)}
      onOk={handleAdd}
      okText='Adicionar'
      cancelText='Cancelar'
      okButtonProps={{
        style: {
          borderRadius: '6px',
        },
        loading: !!isLoading,
      }}
      cancelButtonProps={{
        style: {
          borderRadius: '6px',
        },
        onClick: () => setIsVisible(false),
      }}
    >
      <Form
        layout='vertical'
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <DatePickerController
          label='Data de Início'
          control={control}
          name={'startDate'}
          format='DD/MM/YYYY'
          style={datePickerStyle}
        />
        <DatePickerController
          label='Data de Retorno'
          control={control}
          name={'returnDate'}
          format='DD/MM/YYYY'
          style={datePickerStyle}
        />
      </Form>
    </ModalAdmin>
  );
};

export const ModalAdmin = styled(Modal)`
  border-radius: 8px;
  border: 1px solid rgba(66, 66, 66, 1);
  overflow: hidden;
  &.ant-modal .ant-modal-footer {
    padding: 16px 24px;
  }
  &.ant-modal,
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-title {
    background-color: #1f1f1f;
  }
`;
