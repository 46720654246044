import { Button, Col, Modal, Row } from 'antd';
import { FC, useEffect, useState } from 'react';

import { CompetitionEventModalAddResponseDto } from 'src/core/gamefication/dto/competition-event/competition-event-modal-add-response.dto';
import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import { ListaRegrasCondicional } from './modal-add-regra/lista-regra-condicional';

const initialState: CompetitionEventModalAddResponseDto = {
  key: `card-regra-${Date.now()}`,
  eventIdList: [],
  events: [],
  tipo: 1,
};

type ModalProps = {
  isModalVisible: boolean;
  toggleEventModal?: (value?: boolean) => void;
  events: Array<EventResponseDto>;
  onSaveClick?: (selected: Array<CompetitionEventModalAddResponseDto>) => void;
};

export const ModalAddRegra: FC<ModalProps> = ({
  isModalVisible,
  toggleEventModal,
  onSaveClick,
  events,
}) => {
  const [listCompetitionEvent, setListCompetitionEvent] = useState<
    Array<CompetitionEventModalAddResponseDto>
  >([initialState]);
  const [eventsAvailable, setEventsAvailable] =
    useState<Array<EventResponseDto>>(events);

  useEffect(() => {
    updateEventsAvailable();
  }, []);

  const updateEventsAvailable = () => {
    let idsEventos: number[] = [];
    for (const compEvent of listCompetitionEvent) {
      if (!compEvent.events) {
        continue;
      }
      idsEventos = [...idsEventos, ...compEvent.events?.map(p => p?.id)];
    }
    setEventsAvailable(
      idsEventos.length > 0
        ? events.filter(p => idsEventos.indexOf(p.id) === -1)
        : events
    );
  };

  const onSubmit = async () => {
    onSaveClick?.(listCompetitionEvent);
    setListCompetitionEvent([
      {
        ...initialState,
      },
    ]);
    toggleEventModal?.(false);
  };

  const handleCancel = () => {
    toggleEventModal?.(false);
  };

  const handleListaRegrasCondicionalOnChange = (
    index: number,
    competitionEvent?: CompetitionEventModalAddResponseDto
  ) => {
    listCompetitionEvent[index] =
      competitionEvent as CompetitionEventModalAddResponseDto;
    setListCompetitionEvent(listCompetitionEvent);

    updateEventsAvailable();
  };

  const addNewConditionalRule = () => {
    const newListCard = listCompetitionEvent.concat({
      key: `card-regra-${Date.now()}`,
      events: [],
      eventIdList: [],
      tipo: 1,
    });
    setListCompetitionEvent(newListCard);
  };
  const handleRemoveClick = (index?: number) => {
    listCompetitionEvent.splice(index as number, 1);
    setListCompetitionEvent([...listCompetitionEvent]);
  };
  return (
    <>
      <Modal
        title='Adicionar nova regra'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={onSubmit}>
            Salvar
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <ListaRegrasCondicional
              cards={listCompetitionEvent}
              events={eventsAvailable}
              onChange={handleListaRegrasCondicionalOnChange}
              addNewConditionalRule={addNewConditionalRule}
              handleRemoveClick={handleRemoveClick}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
