import { slices } from '@core/redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export function useBreadcrumbs(
  values: slices.layout.BreadcrumbItem[]
): React.Dispatch<React.SetStateAction<slices.layout.BreadcrumbItem[]>> {
  const dispatch = useDispatch();
  const [items, setItems] = useState(values);

  useEffect(() => {
    dispatch(slices.layout.updateBreadcrumbs(items));
  }, [items]);

  return setItems;
}
