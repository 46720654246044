import { slices } from '@core/redux';
import { Assets } from '@models';
import { assets } from '@service/api';
import { CountResponse, TakeSkipFilter } from '@typings';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyObject } from 'yup/lib/types';

import OpenNotification from '../components/antd/OpenNotification';

export const useAssetsOrdering = () => {
  const dispatch = useDispatch();

  const [orderingAssetsList, setOrderingAssetsList] =
    useState<CountResponse<Assets.IAsset>>();

  const getAssetsOrdering = async (props?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const request: AnyObject = {};

      if (props?.filter) {
        Object.keys(props.filter).forEach((key: string, index) => {
          request[key] = props?.filter?.[key];
        });
      }
      const filteredList = await assets.getOrderingAssets(request);
      setOrderingAssetsList(filteredList);
    } catch (error) {
      console.log(error);
      OpenNotification(true, 'Falha ao carregar os Assets');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const postNewOrdering = async (
    value: Assets.IAsset[],
    currentSegmentId: number,
    currentDeviceType: Assets.DeviceTypeEnum
  ) => {
    dispatch(slices.layout.increaseLoading());
    try {
      const newOrder = [] as Assets.IRequestOrderingAsset[];

      value.forEach((asset, index) => {
        const assetOrderFragmentedInDeviceChannel =
          [] as Assets.IRequestOrderingAsset[];

        asset.banner.devices.forEach(device => {
          if (device.device.deviceName === currentDeviceType) {
            assetOrderFragmentedInDeviceChannel.push({
              assetUuid: String(asset.id),
              order: index,
              segmentId: currentSegmentId,
              deviceChannelId: device.deviceChannelId,
            });
          }
        });

        if (assetOrderFragmentedInDeviceChannel.length) {
          newOrder.push(...assetOrderFragmentedInDeviceChannel);
        }
      });

      await assets.postSortAssets(newOrder);

      OpenNotification(false, 'Assets ordenados com sucesso.');
    } catch (err) {
      OpenNotification(true, 'Falha ao ordenar Assets.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const clearOrderingAssetsList = () => {
    setOrderingAssetsList(undefined);
  };

  return {
    getAssetsOrdering,
    postNewOrdering,
    clearOrderingAssetsList,
    orderingAssetsList,
  };
};
