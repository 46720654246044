import { Spin } from 'antd';
import { FC } from 'react';

import { DeleteButtonComponent } from 'src/features/clients/components/DeleteButtonComponent';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';

type ModalProps = {
  disabled?: boolean;
  loading?: boolean;
  showDeleteButton?: boolean;
  showDiscard?: boolean;
  showEdit?: boolean;
  showSave?: boolean;
  showText?: boolean;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onSaveClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onEditClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDiscardClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const SaveEditDeleteButtons: FC<ModalProps> = ({
  disabled = false,
  loading = false,
  showDeleteButton = true,
  showDiscard = true,
  showEdit = true,
  showSave = false,
  showText = true,
  onDeleteClick = undefined,
  onEditClick = undefined,
  onDiscardClick = undefined,
  onSaveClick = undefined,
}) => {
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <EditButtonComponent
            disabled={disabled}
            showDiscard={showDiscard}
            showEdit={showEdit}
            showSave={showSave}
            showText={showText}
            onEditClick={e => onEditClick && onEditClick(e)}
            onDiscardClick={e => onDiscardClick && onDiscardClick(e)}
            onSaveClick={e => onSaveClick && onSaveClick(e)}
          />
          <DeleteButtonComponent
            disabled={disabled}
            show={showDeleteButton}
            showText={showText}
            onDeleteClick={e => onDeleteClick && onDeleteClick(e)}
          />
        </>
      )}
    </>
  );
};
