import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { OfferFormData } from '../../types/offerPageTypings';
import { offerPayloadFactory } from '../../utils/offerFunnelsPagesFactory';

export function useTryToRequestCreatingOfferFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: OfferFormData) => {
      const factory = await offerPayloadFactory(payload);

      return await apiCancellationFunnels.tryToRequestCreatingOffer(factory);
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao crir o funil de Oferta',
          'Ocorreu um erro ao criar o funil de Oferta, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestCreatingOfferFunnelPage: mutateAsync,
    isLoadingCreateOfferFunnelPage: isLoading,
  };
}
