import { FC } from 'react';

import { ClientType } from 'src/features/clients/types/clientTypes';

import { ClientDashboardTabEmails } from './components/ClientDashboardTabEmails';
import { ClientDashboardTabIndications } from './components/ClientDashboardTabIndications';

export type ClientProps = {
  dados?: ClientType;
};

export const ClientDashboardTab: FC<ClientProps> = ({ dados }) => {
  return (
    <>
      <ClientDashboardTabEmails dados={dados} />
      <ClientDashboardTabIndications dados={dados} />
    </>
  );
};
