import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import * as segmentsThunk from './segments.thunk';

export const getSegmentsWithCountBuilder = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder.addCase(segmentsThunk.getSegmentsWithCounts.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    segmentsThunk.getSegmentsWithCounts.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }

      state.segmentsWithCounts = payload.response;
    }
  );

  builder.addCase(
    segmentsThunk.getSegmentsWithCounts.rejected,
    (state, { payload }: any) => {
      console.log('ERROR THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
