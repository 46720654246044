import { useQuery } from 'react-query';

import { sectorApi } from '../api/sectorApi';

const fetchSector = async () => {
  const data = await sectorApi.listSectors();
  return data.sector || [];
};

export default function useGetAllSectors() {
  return useQuery('listAllSector', () => fetchSector(), {
    refetchOnWindowFocus: false,
  });
}
