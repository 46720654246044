import { enviroments } from '@infrastructure/enviroments';
import { Programs } from '@models';
import { base } from '@service/base';
import { TakeSkipFilter } from '@typings';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function getAllPrograms({
  take,
  skip,
  filter,
}: TakeSkipFilter): Promise<Programs.ProgramsResponse[]> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/programs/with-contents`,
    method: 'GET',
    params: {
      where: JSON.stringify({
        ...where,
      }),
      skip,
      take,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
