import { guestApi } from 'src/app/guestApi';

export interface ListRolesResponse {
  id: string;
  name: string;
  details: string;
  slugname: string;
  updatedAt: string;
  active: boolean;
}

export interface RoleType {
  key?: React.Key;
  id: number;
  name: string;
  details?: string;
  slugname?: string;
}

export interface RoleCreated {
  status: number;
}

export interface GetAllRoleResponse {
  role: RoleType[];
  status: number;
}

async function listRoles(): Promise<GetAllRoleResponse> {
  const url = `/role`;
  const { data } = await guestApi.get(url);
  return data;
}

async function getRoleById(id: string): Promise<ListRolesResponse> {
  const url = `/role/` + id;
  const { data } = await guestApi.get(url);
  return data;
}

async function create(param: RoleType): Promise<RoleCreated> {
  const url = `/role`;
  return guestApi.post(url, param);
}

export const roleApi = {
  listRoles,
  getRoleById,
  create,
};
