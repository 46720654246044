import { Assets } from '@models';
import { ActionsDropdown } from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { CountResponse } from '@typings';

import { capitalizeOnlyFirstLetter } from 'src/app/utils/capitalizeOnlyFirstLetter';

interface TableAssetsProps {
  filterComponent: React.ReactNode;
  data?: CountResponse<Assets.FetchAssetDetail>;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditAsset: (value: Assets.IAsset) => void;
  onDeleteAsset: (value: Assets.IAsset['id']) => void;
}

export const TableAssets: React.FC<TableAssetsProps> = ({
  filterComponent,
  data,
  onChangePagination,
  onEditAsset,
  onDeleteAsset,
}) => {
  const handleClickEditOption = (params: Assets.IAsset) => {
    onEditAsset(params);
  };

  const handleClickDeleteOption = (params: Assets.IAsset) => {
    onDeleteAsset(params.id);
  };

  const headerColumns: PageListProps<Assets.IAsset>['columns'] = [
    {
      title: 'Tipo',
      dataIndex: 'contexts',
      key: 'contexts',
      width: '10%',
      render: (item: Assets.IAssetContext[]) => {
        if (!item.length) return;
        return (
          <div>{Assets.AssetContextLabel[item[0].context.identifier]}</div>
        );
      },
    },
    {
      title: 'Título',
      dataIndex: 'banner',
      key: 'banner',
      width: '20%',
      render: (banner: Assets.IBannerDetail) => (
        <div>{banner.title || 'Nenhuma descrição inserida'}</div>
      ),
    },
    {
      title: 'Segmentos',
      dataIndex: 'segments',
      key: 'segments',
      width: '25%',
      render: (assetSegments: Assets.IAssetSegments[]) => {
        let segmentsText = '';
        assetSegments?.forEach((item, index) => {
          segmentsText +=
            assetSegments.length - 1 === index
              ? `${capitalizeOnlyFirstLetter(item.segment?.name)}`
              : `${capitalizeOnlyFirstLetter(item.segment?.name)}, `;
        });

        return <div>{segmentsText}</div>;
      },
    },
    {
      title: 'Dispositivo',
      dataIndex: 'banner',
      key: 'banner',
      width: '5%',
      render: (banner: Assets.IAsset['banner']) => {
        return (
          <div>
            {banner?.devices.length
              ? capitalizeOnlyFirstLetter(
                  banner?.devices?.[0].device?.deviceName
                )
              : 'n/a'}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'activated',
      key: 'activated',
      width: '5%',
      render: (status: string) => {
        return <div>{status ? 'Ativo' : 'Inativo'}</div>;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text: string | undefined, record: Assets.IAsset) => (
        <ActionsDropdown
          onClickEditOption={() => handleClickEditOption(record)}
          onClickDeleteOption={() => handleClickDeleteOption(record)}
        />
      ),
    },
  ];

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={false}
      onChange={onChangePagination}
    />
  );
};
