import React from 'react';

import * as S from './Tabs.style';

interface ModalTabsProps {
  items: { label: string; key: string; children: React.ReactNode }[];
}

export const ModalTabs: React.FC<ModalTabsProps> = ({ items }) => {
  return <S.Tabs items={items} />;
};
