import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div``;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    padding: 0 24px;
  }
  .ant-tabs-content-holder {
    padding: 0 24px;
  }
  .ant-tabs {
    height: 100%;
    overflow: hidden;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
    max-height: 65vh;
  }
`;
