import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { removeSpecialChars } from 'src/app/helpers/StringHelper';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { CustomerDashboardEmailsResponse } from '../types/clientTypes';

const fetchDashboardEmails = async (
  key: string,
  id?: string,
  pageEmails?: number,
  firstDateEmails?: Date,
  lastDateEmails?: Date,
  subject?: string,

  status?: string
) => {
  const response = await clientApi.getCustomerDashboardEmailsById(
    id,
    buildUri(pageEmails, firstDateEmails, lastDateEmails, subject, status)
  );

  if ((response as CustomerDashboardEmailsResponse).status === Success) {
    return (response as CustomerDashboardEmailsResponse).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar emails',
      'Ocorreu um erro  ao carregar os emails, por favor tente novamente mais tarde.'
    );
  }
};

function buildUri(
  pageEmails: number | undefined,
  firstDateEmails: Date | undefined,
  lastDateEmails: Date | undefined,
  subject: string | undefined,
  status?: string | undefined
) {
  let url = `?limit=10&page=${pageEmails}`;
  if (subject) {
    url += `&subject=${removeSpecialChars(subject)}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (firstDateEmails && lastDateEmails) {
    url += `&firstDate=${firstDateEmails.toJSON()}&lastDate=${lastDateEmails.toJSON()}`;
  }

  return url;
}

export default function useDashboardEmails(
  id?: string,
  pageEmails?: number,
  firstDateEmails?: Date,
  lastDateEmails?: Date,
  subject?: string,
  status?: string
) {
  return useQuery(
    [
      'dashboardEmails',
      id,
      pageEmails,
      firstDateEmails,
      lastDateEmails,
      subject,
      status,
    ],
    () =>
      fetchDashboardEmails(
        'dashboardEmails',
        id,
        pageEmails,
        firstDateEmails,
        lastDateEmails,
        subject,
        status
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
}
