import { Button, Input, Select } from 'antd';
import styled from 'styled-components';

export const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;
export const SearchInput = styled(Input)`
  width: 80%;
`;

export const ButtonInput = styled(Button)``;

export const SelectInput = styled(Select)`
  width: 80%;
  margin-left: -10px;
`;

export const Space = styled.div`
  margin: 30px;
`;
export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
`;
export const ErrorStyled = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: #ff0000;
`;

export const SubSectionTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export const SubSectionDescription = styled.span`
  font-size: 10px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #acacac;
  margin-top: -20px;
`;

export const PermissionNameListContainer = styled.div`
  margin-top: 17px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
`;

export const PermissionNameListItem = styled.div`
  padding: 18px 16px;
  background: #1d1d1d;
  border: 1px solid #303030;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0px;
  }
  span {
    font-size: 11px;
    line-height: 22px;
    color: #acacac;
    cursor: pointer;
  }
`;

export const AddonBefore = styled.div`
  width: 200px;
  text-align: left;
  border: 'none';
`;

export const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

export const InputStyledFirst = styled(Input)`
  border-top-color: rgb(67, 67, 67);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;
