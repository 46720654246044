import { AnyObject } from 'yup/lib/types';

import { guestApi } from 'src/app/guestApi';
import { ICustomerVipGroupResponseDto } from 'src/core/admin-cx/customer-vip-group/dto/customer-vip-group-response.dto';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { ResponseApiDto } from 'src/core/dto/response-api.dto';

interface ICustomerVipGroupApi {
  getVipGroupByCustomer(
    idCustomer: number
  ): Promise<DataResponseApiDto<ICustomerVipGroupResponseDto[]> | undefined>;
  addCustomerToVipGroup(
    idVipGroup: number,
    payload: AnyObject
  ): Promise<ResponseApiDto | undefined>;
  updateCustomerVipGroup(
    idCustomer: number,
    idCustomerVipGroup: number,
    payload: AnyObject
  ): Promise<ResponseApiDto | undefined>;
}

export function createCustomerVipGroupApi(): ICustomerVipGroupApi {
  async function getVipGroupByCustomer(
    idCustomer: number
  ): Promise<DataResponseApiDto<ICustomerVipGroupResponseDto[]> | undefined> {
    const response = await guestApi.get<
      DataResponseApiDto<ICustomerVipGroupResponseDto[]>
    >(`/customer/${idCustomer}/vip/group/`);

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }

  async function addCustomerToVipGroup(
    idVipGroup: number,
    payload: AnyObject
  ): Promise<ResponseApiDto | undefined> {
    const response = await guestApi.post<ResponseApiDto>(
      `/vip/group/${idVipGroup}/participant`,
      {
        idCustomer: payload.idCustomer,
        name: payload.name,
        url: payload.url,
        inclusionDate: payload.inclusionDate,
        departureDate: payload.departureDate,
      }
    );

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }

  async function updateCustomerVipGroup(
    idVipGroup: number,
    idVipGroupParticipant: number,
    payload: AnyObject
  ): Promise<ResponseApiDto | undefined> {
    const response = await guestApi.put<ResponseApiDto>(
      `/vip/group/${idVipGroup}/participant/${idVipGroupParticipant}`,
      {
        name: payload.name,
        url: payload.url,
        inclusionDate: payload.inclusionDate,
        departureDate: payload.departureDate,
      }
    );

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }

  return {
    getVipGroupByCustomer,
    addCustomerToVipGroup,
    updateCustomerVipGroup,
  };
}
