import { OptionList } from '@atoms';
import { Form, Select, SelectProps, TooltipProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { ReactNode } from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';

import { OptionsProps } from '../../atoms/Select/Select.interface';

interface SelectControllerProps
  extends Omit<SelectProps<any, DefaultOptionType>, 'onChange'> {
  control: Control<any>;
  name: string;
  placeholder?: string;
  defaultValue?: number[] | string[] | string;
  autoComplete?: string;
  onChange?: (value: any, option: OptionsProps | OptionsProps[]) => void;
  prefix?: React.ReactNode;
  type?: string;
  label?: string;
  options: OptionList;
  mode?: 'multiple' | 'tags';
  required?: boolean;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
}

export const SelectController: React.FC<SelectControllerProps> = ({
  control,
  defaultValue,
  name,
  label,
  options,
  mode,
  placeholder,
  onChange,
  required,
  tooltip,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : undefined}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);
        return (
          <Form.Item
            required={required}
            validateStatus={status}
            help={help}
            label={label}
            {...(tooltip && { tooltip: tooltip })}
          >
            <Select
              {...rest}
              {...field}
              placeholder={placeholder}
              data-testid={name}
              options={options}
              showArrow
              optionFilterProp='label'
              mode={mode}
              onChange={onChange ? onChange : field?.onChange}
            />
          </Form.Item>
        );
      }}
    />
  );
};
