export const TabletSvg = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9 46.5C8.175 46.5 7.46875 46.2062 6.88125 45.6188C6.29375 45.0312 6 44.325 6 43.5V5.5C6 4.675 6.29375 3.96875 6.88125 3.38125C7.46875 2.79375 8.175 2.5 9 2.5H39C39.825 2.5 40.5312 2.79375 41.1188 3.38125C41.7062 3.96875 42 4.675 42 5.5V43.5C42 44.325 41.7062 45.0312 41.1188 45.6188C40.5312 46.2062 39.825 46.5 39 46.5H9ZM9 39V43.5H39V39H9ZM19.9 42.25H28.1V40.25H19.9V42.25ZM9 36H39V10H9V36ZM9 7H39V5.5H9V7Z'
      fill='white'
    />
  </svg>
);
