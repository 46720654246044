import { slices } from '@core/redux';
import {
  AssetsPage,
  CouponPage,
  CreateCouponDigitalPage,
  CreateCouponPhysicPage,
  EventDetailPage,
  EventsPage,
  LinkAssets,
  LinkCustomers,
  LinkPrograms,
  LoginPage,
  ModalDetailPage,
  ModalPage,
  ModalsOrdering,
  OrderBumpPage,
  SegmentDetailPage,
  SegmentsOrdering,
  SegmentsPage,
} from '@pages';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminScreenDetails } from 'src/features/admin/screens/admin-details-screen/AdminScreenDetails';
import { AdminScreenListing } from 'src/features/admin/screens/admin-list-screen/AdminScreenListing';
import { ClientDetailsScreen } from 'src/features/clients/screens/client-details-screen/ClientDetailsScreen';
import { ClientScreenListing } from 'src/features/clients/screens/client-listing-screen/ClientScreenListing';
import { CustomerDetailsScreen } from 'src/features/customer/screens/CustomerDetailsScreen';
import { DashboardScreen } from 'src/features/dashboard/screens/DashboardScreen';
import { FreezingScreen } from 'src/features/freezing/screens/FreezingScreen';
import { FunnelEditScreen } from 'src/features/Funnel/screens/FunnelEdit/FunnelEditScreen';
import { FunelGestation } from 'src/features/Funnel/screens/FunnelGestation/FunelGestationScreen';
import { FunnelFunctionalityPageScreen } from 'src/features/Funnel/screens/FunnelPage/Functionality/FunctionalityPageScreen';
import { FunnelMediaPageScreen } from 'src/features/Funnel/screens/FunnelPage/MediaPage/MediaPageScreen';
import { FunnelPageOfferPageScreen } from 'src/features/Funnel/screens/FunnelPage/OfferPage/OfferPageScreen';
import { FunnelPageQuestionPageScreen } from 'src/features/Funnel/screens/FunnelPage/QuestionPage/QuestionPageScreen';
import { FunnelReleasePageScreen } from 'src/features/Funnel/screens/FunnelPage/ReleasePage/ReleasePageScreen';
import { FunnelSpecialistCarePageScreen } from 'src/features/Funnel/screens/FunnelPage/SpecialistCarePage/SpecialistCarePageScreen';
import { FunnelVideoPageScreen } from 'src/features/Funnel/screens/FunnelPage/VideoPage/VideoPageScreen';
import { BadgeDetailsScreen } from 'src/features/gamefication/badges/screen/BadgeDetailsScreen';
import { BadgeListScreen } from 'src/features/gamefication/badges/screen/BadgeListScreen';
import { BadgeGroupCreateScreeen } from 'src/features/gamefication/badges-groups/screen/BadgeGroupCreateScreeen';
import { BadgeGroupDetailsScreen } from 'src/features/gamefication/badges-groups/screen/BadgeGroupDetailsScreen';
import { BadgeGroupListScreen } from 'src/features/gamefication/badges-groups/screen/BadgeGroupListScreen';
import {
  GameficationmanageCompetitionForm,
  GameficationManageCompetitionList,
} from 'src/features/gamefication/competitions/Index';
import { SocialDashboard } from 'src/features/gamefication/dashboard/SocialDashboard';
import { ErrorScreen } from 'src/features/layout/screens/ErrorScreen';
import { FormOffer } from 'src/features/offers/screens/formOffer/formOffer';
import { OfferExibiton } from 'src/features/offers/screens/offerExibition/offerExibition';
import { ProductExibitons } from 'src/features/offers/screens/productsExibition/productsExibition';
import { RoleScreen } from 'src/features/roles/screen/RoleScreen';
import { SubscriptionListScreen } from 'src/features/subscriptions/scheduled-reports/Index';
import { UpsellFlow } from 'src/features/upsellFunnel/screens/UpsellFlow/UpsellFlow.page';
import { UpsellFunnel } from 'src/features/upsellFunnel/screens/UpsellFunnel/UpsellFunnel.page';
import { UpsellStepFlow } from 'src/features/upsellFunnel/screens/UpsellStepFlow/UpsellStepFlow.page';

import { NotFoundRouteScreen } from '../features/layout/screens/NotFoundRouteScreen';
import { LgpdPage } from '../features/Lgpd/screens/LgpdScreen';
import { GuestRoute } from './components/GuestRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import { EditCouponDigitalPage } from './framework/pages/Coupons/digital/EditCouponDigital.page';
import { ReadCouponDigitalPage } from './framework/pages/Coupons/digital/ReadCouponDigital.page';
import { EditCouponPhysicPage } from './framework/pages/Coupons/physic/EditCouponPhysic.page';
import { ReadCouponPhysicPage } from './framework/pages/Coupons/physic/ReadCouponPhysic.page';
import { CreateOrderBump } from './framework/pages/OrderBumps/CreateOrderBump.page';

export const Routes: FC = () => {
  const isAuth = useSelector(slices.auth.selectIsAuth);
  const [redirect, setRedirect] = useState<string>('/dashboard');

  useEffect(() => {
    if (isAuth) {
      setRedirect('/login');
    }
  }, [isAuth]);

  return (
    <Switch>
      <Route path='/' exact>
        <Redirect to={redirect} />
      </Route>

      <GuestRoute path='/login' component={LoginPage} />

      <ProtectedRoute path='/dashboard' component={DashboardScreen} />
      <ProtectedRoute path='/admin' exact component={AdminScreenListing} />
      <ProtectedRoute path='/admin/:id' component={AdminScreenDetails} />
      <ProtectedRoute path='/clientes' exact component={ClientScreenListing} />
      <ProtectedRoute
        path='/cliente/v2/:id'
        component={CustomerDetailsScreen}
        exact
      />
      <ProtectedRoute path='/cliente/:id' component={ClientDetailsScreen} />

      <ProtectedRoute path='/permissao' exact component={RoleScreen} />
      <ProtectedRoute path='/permissao/:id' component={RoleScreen} />

      <ProtectedRoute
        path='/unsubscribe/funnel'
        exact
        component={FunelGestation}
      />
      <ProtectedRoute path='/upsell/funnel' exact component={UpsellFunnel} />
      <ProtectedRoute path='/upsell/flow' exact component={UpsellFlow} />
      <ProtectedRoute
        path='/upsell/step/flow'
        exact
        component={UpsellStepFlow}
      />
      <ProtectedRoute
        path='/unsubscribe/funnel/:id'
        component={FunnelEditScreen}
        exact
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/question/:id'}
        component={FunnelPageQuestionPageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/specialist-care/:id'}
        component={FunnelSpecialistCarePageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/release/:id'}
        component={FunnelReleasePageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/video/:id'}
        component={FunnelVideoPageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/media/:id'}
        component={FunnelMediaPageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/functionality/:id'}
        component={FunnelFunctionalityPageScreen}
      />
      <ProtectedRoute
        path={'/unsubscribe/funnel/offer/:id'}
        component={FunnelPageOfferPageScreen}
      />

      <ProtectedRoute
        exact
        path='/assinaturas/cancelamentos/agendas'
        component={SubscriptionListScreen}
      />

      <ProtectedRoute path='/screens/Assets' component={AssetsPage} />
      <ProtectedRoute
        path='/screens/segments/manage'
        component={SegmentsPage}
      />

      <ProtectedRoute
        path='/screens/segments/detalhes'
        component={SegmentDetailPage}
      />

      <ProtectedRoute
        path='/screens/segments/link/assets'
        component={LinkAssets}
      />

      <ProtectedRoute
        path='/screens/segments/link/customers'
        component={LinkCustomers}
      />
      <ProtectedRoute
        path='/screens/segments/link/programs'
        component={LinkPrograms}
      />
      <ProtectedRoute
        path='/screens/segments/ordering'
        component={SegmentsOrdering}
      />

      <ProtectedRoute path='/social/dashboard' component={SocialDashboard} />
      <ProtectedRoute
        path='/social/gamefication/events'
        exact
        component={EventsPage}
      />
      <ProtectedRoute
        path='/social/gamefication/events/edit'
        component={EventDetailPage}
      />
      <ProtectedRoute
        path='/social/gamefication/badges/groups'
        exact
        component={BadgeGroupListScreen}
      />
      <ProtectedRoute
        path='/social/gamefication/badges/groups/create'
        exact
        component={BadgeGroupCreateScreeen}
      />
      <ProtectedRoute
        path='/social/gamefication/badges/groups/:id'
        component={BadgeGroupDetailsScreen}
      />
      <ProtectedRoute
        path='/social/gamefication/badges'
        exact
        component={BadgeListScreen}
      />
      <ProtectedRoute
        path='/social/gamefication/badges/:id'
        component={BadgeDetailsScreen}
      />

      <ProtectedRoute
        exact
        path='/social/gamefication/competitions'
        component={GameficationManageCompetitionList}
      />

      <ProtectedRoute
        exact
        path='/social/gamefication/competition/create'
        component={GameficationmanageCompetitionForm}
      />

      <ProtectedRoute
        path='/social/gamefication/competition/:id'
        component={GameficationmanageCompetitionForm}
      />

      <ProtectedRoute
        exact
        path='/social/gamefication/competitions'
        component={GameficationManageCompetitionList}
      />

      <ProtectedRoute
        exact
        path='/social/gamefication/competition/create'
        component={GameficationmanageCompetitionForm}
      />

      <ProtectedRoute
        path='/social/gamefication/competition/:id'
        component={GameficationmanageCompetitionForm}
      />

      <ProtectedRoute path='/Lgpd' component={LgpdPage} />
      <ProtectedRoute path='/freezing' component={FreezingScreen} />

      <ProtectedRoute path='/products' component={ProductExibitons} />
      <ProtectedRoute path='/offers' component={OfferExibiton} />
      <ProtectedRoute path='/formOffers' component={FormOffer} />

      <ProtectedRoute
        path='/screens/modals/detail'
        component={ModalDetailPage}
      />

      <ProtectedRoute path='/screens/modals' component={ModalPage} exact />

      <ProtectedRoute
        path='/screens/modals/ordering'
        component={ModalsOrdering}
      />

      {/* All Coupons Pages */}
      <ProtectedRoute path='/screens/coupons' component={CouponPage} exact />

      {/* Physic Coupons Pages */}
      <ProtectedRoute
        path='/screens/physic/coupons/create'
        component={CreateCouponPhysicPage}
      />
      <ProtectedRoute
        path='/screens/physic/coupons/read'
        component={ReadCouponPhysicPage}
      />
      <ProtectedRoute
        path='/screens/physic/coupons/:id'
        component={EditCouponPhysicPage}
      />

      {/* Digital Coupons Pages */}
      <ProtectedRoute
        path='/screens/digital/coupons/create'
        component={CreateCouponDigitalPage}
      />
      <ProtectedRoute
        path='/screens/digital/coupons/read'
        component={ReadCouponDigitalPage}
      />
      <ProtectedRoute
        path='/screens/digital/coupons/:id'
        component={EditCouponDigitalPage}
      />

      {/* Order Bump Pages */}
      <ProtectedRoute
        path='/screens/order-bump'
        component={OrderBumpPage}
        exact
      />

      <ProtectedRoute
        path='/screens/order-bump/create'
        component={CreateOrderBump}
        exact
      />

      <Route path='/erro'>
        <ErrorScreen />
      </Route>

      <Route path='*'>
        <NotFoundRouteScreen />
      </Route>
    </Switch>
  );
};
