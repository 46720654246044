import { Icons } from '@atoms';
import { Col, Row } from 'antd';
import styled from 'styled-components';

export const TextLogin = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
`;

export const Container = styled(Row)`
  height: 100vh;
  background-image: url("/public/logo-horizontal.png width='12' height='24' viewBox='0 0 12 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fillRule='evenodd'%3E%3Cg fill='%23585858' fill-opacity='0.4'%3E%3Cpath d='M2 0h2v12H2V0zm1 20c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM9 8c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm-1 4h2v12H8V12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BrandLogo = styled(Icons.Logo)`
  padding: 20px;
`;

export const RightCol = styled(Col)`
  margin: auto;
`;

export const Header = styled.div`
  margin-bottom: 8px;
`;

export const FormContainer = styled.div`
  width: 100%;
  background-color: #000000;
  padding: 30px;
  border-radius: 10px;
  max-width: 450px;
`;
