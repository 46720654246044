import React from 'react';

import * as S from './ModalContainer.style';

interface ModalContainerProps {
  children: React.ReactNode;
  width?: number;
  overflow?: 'auto' | 'hidden' | 'overlay';
}
export const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  width,
  overflow,
}) => {
  return (
    <S.Container overflow={overflow}>
      <S.ModalContainer width={width}>{children}</S.ModalContainer>
    </S.Container>
  );
};
