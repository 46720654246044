import { SolutionOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { FC } from 'react';

import { ModalStepTwo } from '../EvidenceModal/ModalStepTwo';

interface ModalAnonymizeProps {
  handleButtonAnonymize: () => void;
  hasError: boolean;
  buttonState: boolean;
}

export const ModalNoAniminization: FC<ModalAnonymizeProps> = ({
  handleButtonAnonymize,
  hasError,
  buttonState,
}) => {
  return (
    <>
      {hasError && (
        <Alert
          message='Não foi possível anonimizar os dados.'
          type='error'
          showIcon
          style={{ marginBlockEnd: '24px' }}
        />
      )}

      <ModalStepTwo
        title={'Nenhum dado Anonimizado'}
        firstText={'Ainda não existem dados anonimizados.'}
        icon={<SolutionOutlined style={{ fontSize: '36px' }} />}
        hasButton={true}
        handleButton={handleButtonAnonymize}
        buttonLoadingMode={buttonState}
        secondText={
          'Você pode anonimizar os dados, clicando no botão abaixo. Essa ação é irreversível.'
        }
      />
    </>
  );
};
