import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import { FC, useEffect, useState } from 'react';

import { IDateRange } from '../dto/date-range.interface';
import { ScheduledReportsModal as ModalExportReport } from './Modal';

type IScheduledReportsFilter = {
  refetch: (from: Date, to: Date) => void;
  dateRange: undefined | IDateRange;
};

export const ScheduledReportsFilter: FC<IScheduledReportsFilter> = ({
  dateRange,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [enableExportReport, setEnableExportReport] = useState(true);
  const [isModalVisible, setModalVisibility] = useState(false);

  const handleRefetch = () => {
    const { from, to } = form.getFieldsValue();
    refetch(from, to);
  };

  useEffect(() => {
    if (dateRange) {
      const { from, to } = dateRange;
      setEnableExportReport(!Boolean(from && to));
    }
  }, [dateRange]);

  const MODAL_PROPS = {
    selectedDateRange: dateRange,
    isVisible: isModalVisible,
    setModalVisibility,
  };

  return (
    <>
      <Row justify='space-between' style={{ marginTop: 20, marginBottom: 20 }}>
        <Col>
          <Form
            form={form}
            layout='inline'
            data-testid='filter'
            onFinish={() => handleRefetch()}
            onValuesChange={() => handleRefetch()}
          >
            <Form.Item name='from' label='Mostrar de'>
              <DatePicker
                format='DD/MM/YYYY'
                placeholder='Selecione'
                data-testid='from'
              />
            </Form.Item>

            <Form.Item name='to' label='até'>
              <DatePicker
                format='DD/MM/YYYY'
                placeholder='Selecione'
                data-testid='to'
              />
            </Form.Item>
          </Form>
        </Col>

        <Col>
          <Button
            data-testid='cta-modal'
            disabled={enableExportReport}
            icon={<PlusCircleOutlined />}
            onClick={() => setModalVisibility(true)}
            type='primary'
          >
            Gerar Relatório
          </Button>
        </Col>
      </Row>

      {isModalVisible && <ModalExportReport {...MODAL_PROPS} />}
    </>
  );
};
