import { Assets } from '@models';
import {
  Controller as OrganismController,
  DatePickerController,
  ProgramsSelect,
  SegmentsSelect,
  SwitchController,
  TextAreaController,
} from '@organisms';
import { Checkbox, Col, Form, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import * as S from './DataScreen.style';

interface DataScreenProps {
  data?: Assets.BannerHookForm;
  form: UseFormReturn<Assets.BannerHookForm>;
  isValid?: () => void;
  onSubmit: (data: Assets.FetchAssetDetail) => void;
  setOpenInWindowCheckbox: (value: boolean) => void;
  onChange?: () => void;
  formId?: string;
}

export const DataScreen: React.FC<DataScreenProps> = ({
  formId,
  form,
  data,
  onChange,
  setOpenInWindowCheckbox,
}) => {
  const [linkActive, setLinkActive] = React.useState(form.watch('linkActive'));

  useEffect(() => {
    setLinkActive(form.watch('linkActive'));
  }, [form.watch('linkActive')]);

  useEffect(() => {
    onChange?.();
  }, [
    form.watch('effectiveDateTime'),
    form.watch('expirationDateTime'),
    form.watch('descriptionActive'),
    form.watch('buttonLabelActive'),
    form.watch('programBasedActive'),
    form.watch('linkActive'),
  ]);

  const expirationDisable = useMemo(() => {
    return (currentDate: moment.Moment) => {
      const initialDate = form.getValues('effectiveDateTime');

      if (initialDate) {
        return currentDate.isBefore(initialDate);
      }

      const date = new Date();
      return currentDate < moment(date.setDate(date.getDate()));
    };
  }, [form]);

  const effectiveDisable = useMemo(() => {
    return (currentDate: moment.Moment) => {
      const date = new Date();
      return currentDate < moment(date.setDate(date.getDate() - 1));
    };
  }, [form]);

  useEffect(() => {
    const field = form.getValues('programBasedActive');
    if (!field) {
      form.resetField('programBased');
    }

    if (field) {
      form.setValue('linkActive', false);
    }
  }, [form.watch('programBasedActive')]);

  if (!data) {
    return <>Sem dados para o banner</>;
  }

  return (
    <Form id={formId} layout='vertical'>
      <Row>
        <Col span={24}>
          <SwitchController
            sideLabel
            control={form.control}
            rightLabel='Ativo'
            leftLabel='Inativo'
            name='status'
            defaultValue={data.status}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row
        style={{
          padding: '16px 0',
        }}
      >
        <Col span={24}>
          <S.Divider />
        </Col>
      </Row>

      <Row>
        <Col span={24} flex={'1 2 0%'}>
          <SegmentsSelect
            control={form.control}
            label='Segmentos'
            name='segments'
            placeholder='Segmentos'
            defaultValue={data.segments}
            dataTake={99999}
            mode='multiple'
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <OrganismController
            control={form.control}
            label='Título'
            name='title'
            placeholder='Título do asset'
            defaultValue={data.title}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row
        style={{
          paddingTop: '16px',
        }}
      >
        <Col span={24}>
          <S.Divider />
        </Col>
      </Row>

      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div>Descritivo</div>

          <div>
            <SwitchController
              sideLabel
              control={form.control}
              rightLabel={form.watch('descriptionActive') ? 'Ativo' : 'Inativo'}
              name='descriptionActive'
              defaultValue={data.descriptionActive}
              autoComplete='off'
            />
          </div>
        </div>
      </Row>

      {form.watch('descriptionActive') ? (
        <Row>
          <Col span={24}>
            <TextAreaController
              control={form.control}
              name='description'
              placeholder='Breve descrição'
              defaultValue={data.description}
              autoComplete='off'
              row={3}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <S.Divider />
          </Col>
        </Row>
      )}

      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div>Botão (Label)</div>

          <div>
            <SwitchController
              sideLabel
              control={form.control}
              rightLabel={form.watch('buttonLabelActive') ? 'Ativo' : 'Inativo'}
              name='buttonLabelActive'
              defaultValue={data.buttonLabelActive}
              autoComplete='off'
            />
          </div>
        </div>
      </Row>

      {form.watch('buttonLabelActive') ? (
        <Row>
          <Col span={24}>
            <OrganismController
              control={form.control}
              name='buttonLabel'
              placeholder='Rótulo do botão'
              defaultValue={data.buttonLabel}
              autoComplete='off'
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <S.Divider />
          </Col>
        </Row>
      )}

      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div>Link</div>

          <div>
            <SwitchController
              sideLabel
              control={form.control}
              disabled={form.watch('programBasedActive')}
              rightLabel={linkActive ? 'Ativo' : 'Inativo'}
              name='linkActive'
              defaultValue={data.buttonLabelActive}
              autoComplete='off'
            />
          </div>
        </div>
      </Row>

      {linkActive ? (
        <>
          <Row>
            <Col span={24}>
              <OrganismController
                control={form.control}
                name='link'
                placeholder='vimeo.com/linkgerado'
                defaultValue={data.link || ''}
                autoComplete='off'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox
                onChange={e => setOpenInWindowCheckbox(e.target.checked)}
              >
                Abrir link em uma nova janela
              </Checkbox>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <S.Divider />
          </Col>
        </Row>
      )}

      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div>Basear em um programa</div>

          <div>
            <SwitchController
              sideLabel
              control={form.control}
              rightLabel={
                form.watch('programBasedActive') ? 'Ativo' : 'Inativo'
              }
              name='programBasedActive'
              defaultValue={data.programBasedActive}
              autoComplete='off'
            />
          </div>
        </div>
      </Row>

      {form.watch('programBasedActive') ? (
        <>
          <Row>
            <Col span={24}>
              <ProgramsSelect control={form.control} name='programBased' />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox
                onChange={e => setOpenInWindowCheckbox(e.target.checked)}
              >
                Abrir link em uma nova janela
              </Checkbox>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <S.Divider />
          </Col>
        </Row>
      )}

      <Row
        style={{
          paddingTop: '16px',
        }}
      >
        <Col span={12} style={{ paddingRight: '5px' }}>
          <DatePickerController
            control={form.control}
            label='Data de início'
            name='effectiveDateTime'
            placeholder='DD/MM/AAAA HH:mm'
            format='DD/MM/YYYY HH:mm'
            showTime
            defaultValue={data.effectiveDateTime?.split('Z')[0]}
            disableDate={effectiveDisable}
            autoComplete='off'
          />
        </Col>
        <Col span={12}>
          <DatePickerController
            control={form.control}
            label='Data de término'
            name='expirationDateTime'
            placeholder='DD/MM/AAAA HH:mm'
            format='DD/MM/YYYY HH:mm'
            disableDate={expirationDisable}
            showTime
            defaultValue={data.expirationDateTime?.split('Z')[0]}
            autoComplete='off'
          />
        </Col>
      </Row>
    </Form>
  );
};
