import { Modal, Row } from 'antd';
import { useCallback } from 'react';
import styled from 'styled-components';

import { CardFunnel, CardFunnelProps } from './CardFunnel';

interface ModalFunnelTypesProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  cardsFunnel: CardFunnelProps[];
}

export const ModalFunnelTypes: React.FC<ModalFunnelTypesProps> = ({
  openModal,
  setOpenModal,
  cardsFunnel,
}) => {
  const handleOpenAndCloseModal = useCallback(() => {
    setOpenModal(currentopenModal => !currentopenModal);
  }, []);

  return (
    <ModalFunnel
      title={'Escolha um modelo de página'}
      centered
      open={openModal}
      footer={false}
      onOk={handleOpenAndCloseModal}
      onCancel={handleOpenAndCloseModal}
      width={864}
    >
      <Row style={{ gap: '32px' }}>
        {cardsFunnel.map((card, index) => (
          <CardFunnel
            key={index}
            icon={card.icon}
            link={card.link}
            title={card.title}
          />
        ))}
      </Row>
    </ModalFunnel>
  );
};

const ModalFunnel = styled(Modal)`
  .ant-modal-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #dbdbdb;
  }

  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-close {
    color: #dbdbdb;
  }
`;
