export const createKey = (row: any) => {
  let id = '';
  const ObjRow = Object.keys(row);
  ObjRow.forEach(key => {
    if (typeof row[key] === 'string') {
      id += row[key].split(' ').join('_');
    }
  });

  return id;
};
