import { Form, Radio, RadioProps, TooltipProps } from 'antd';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';

interface RadioOption {
  label: React.ReactNode;
  value: any;
  disabled?: boolean;
}

interface RadioControllerProps extends Omit<RadioProps, 'onChange'> {
  control: Control<any>;
  name: string;
  label?: string;
  options: RadioOption[];
  defaultValue?: any;
  required?: boolean;
  tooltip?: React.ReactNode | (TooltipProps & { icon: React.ReactNode });
}

export const RadioController: React.FC<RadioControllerProps> = ({
  control,
  defaultValue,
  name,
  label,
  options,
  required,
  tooltip,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        return (
          <Form.Item
            required={required}
            validateStatus={status}
            help={help}
            label={label}
            {...(tooltip && { tooltip: tooltip })}
          >
            <Radio.Group {...rest} {...field}>
              {options.map((option, index) => (
                <Radio
                  key={index}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      }}
    />
  );
};
