import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { lgpdApi } from '../apis/lgpdApis';
import { useModalAnonymization } from '../components/ModalLgpd/components/DataAnonimizateModal/hooks/useModalAnonymization';
import { useControllerFormModalEvidence } from '../components/ModalLgpd/components/EvidenceModal/hooks/useControllerModalEvidence';
import { useControllerFormModal } from '../components/ModalLgpd/components/FormModal/hooks/useControllerFormModal';
import {
  ConsultLgpdProps,
  ModalLgpdData,
  RequestAnonymizeDTO,
  UserAdminData,
} from '../interfaces/lgpdInterfaces';
import { CurrentModal } from '../typings/lgpdTipyngs';
import { factoryAnonimizeDto } from '../utils/factoryAnonimizeDto';

export default function useControllerModal(
  setModalVisibility: Dispatch<SetStateAction<boolean>>,
  setCurrentModal: Dispatch<SetStateAction<CurrentModal>>,
  currentModal: CurrentModal,
  tableLgpdConsult?: RequestAnonymizeDTO
) {
  const [userData, setUserData] = useState<UserAdminData>();
  const [consultLgpd, setConsultLgpd] = useState<
    ConsultLgpdProps | undefined
  >();

  useEffect(() => {
    defineCurrentStep();
  }, [currentModal]);

  const modalForm = useControllerFormModal(
    handleCloseModal,
    setCurrentModal,
    setConsultLgpd,
    userData
  );

  const modalEvidence = useControllerFormModalEvidence(
    setCurrentModal,
    setConsultLgpd,
    handleCloseModal,
    userData?.fullName,
    consultLgpd
  );

  const modalAnonymization = useModalAnonymization(
    handleCloseModal,
    setConsultLgpd,
    setCurrentModal,
    consultLgpd
  );

  const transformRequest = () => {
    tableLgpdConsult &&
      factoryAnonimizeDto(tableLgpdConsult).then(response =>
        setConsultLgpd(response)
      );
  };

  const fetchAdmin = async () => {
    const response = await lgpdApi.getUserData().then(response => {
      return response.data;
    });
    return response as UserAdminData;
  };

  useEffect(() => {
    transformRequest();
    fetchAdmin().then(response => {
      setUserData(response);
    });
  }, []);

  function handleCloseModal() {
    setCurrentModal('stepOne');
    setModalVisibility(false);
    setConsultLgpd(undefined);
  }

  const defineCurrentStep = (): ModalLgpdData | undefined => {
    switch (currentModal) {
      case 'stepOne':
        return modalForm;
      case 'stepTwo':
        return modalEvidence;
      case 'stepThree':
        return modalAnonymization;
    }
  };

  return [defineCurrentStep, handleCloseModal] as [
    typeof defineCurrentStep,
    typeof handleCloseModal
  ];
}
