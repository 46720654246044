import { guestApi } from 'src/app/guestApi';
import { Created } from 'src/app/utils/statusCode';

import {
  AdminDTOType,
  CommentTypeResponse,
  ResponseAdmin,
} from '../types/adminTypes';

async function getAllAdmins(params?: string): Promise<ResponseAdmin | unknown> {
  const endpoint = `/user/admin` + params;
  return guestApi.get(endpoint);
}

async function getAdminById(id: string): Promise<AdminDTOType | unknown> {
  const urlAdminById = `/user/admin/${id}`;
  const responseAdminById = await guestApi.get(urlAdminById);
  const Success = 200;
  if (responseAdminById.status === Success) {
    return responseAdminById.data;
  } else {
    return [];
  }
}

async function updateAdmin(
  param: AdminDTOType
): Promise<ResponseAdmin | unknown> {
  const endpoint = `user/admin/${param.id}`;
  return guestApi.put(endpoint, param);
}

async function updateAdminRoles(param: {
  id?: string;
  roles: React.Key[];
}): Promise<ResponseAdmin | unknown> {
  return guestApi.put(`user/admin/${param.id}/roles`, {
    roles: param.roles,
  });
}

async function createAdmin(
  param: AdminDTOType
): Promise<CommentTypeResponse | unknown> {
  const res = await guestApi.post(`/user/admin`, param);
  if (res.status === Created) {
    return res;
  } else {
    throw new Error();
  }
}

async function deleteAdmin(
  id?: number
): Promise<CommentTypeResponse | unknown> {
  const endpointUrlDeleteAdmin = `/user/admin/${id}`;
  return guestApi.delete(endpointUrlDeleteAdmin);
}

export const adminApi = {
  getAllAdmins,
  getAdminById,
  updateAdmin,
  createAdmin,
  deleteAdmin,
  updateAdminRoles,
};
