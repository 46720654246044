import React from 'react';

import * as S from './KeyAndElement.style';

type DataType = {
  key: string;
  name: string;
  component: React.ReactNode;
};

interface KeyAndElementProps {
  data: DataType[];
}
export const KeyAndElement: React.FC<KeyAndElementProps> = ({ data }) => {
  return (
    <S.TableContainer>
      {data.map((item, index) => (
        <S.RowContainer key={item.name} gutter={2}>
          <S.TitleContainer
            span={2}
            className={`${index === 0 ? 'first-row' : ''} ${
              index === data.length - 1 ? 'last-row' : ''
            }`}
          >
            <div>{item.name}</div>
          </S.TitleContainer>
          <S.ContentContainer
            span={22}
            className={`${index === 0 ? 'first-row' : ''} ${
              index === data.length - 1 ? 'last-row' : ''
            }`}
          >
            {item.component}
          </S.ContentContainer>
        </S.RowContainer>
      ))}
    </S.TableContainer>
  );
};
