import { FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useDebounce } from '@hooks';
import { Segments } from '@models';
import { FilterHeader } from '@organisms';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface IFilterSegmentsProps {
  onFilterList: (filter: Segments.SegmentsFilter) => void;
  onClickAddSegment: () => void;
}
export const FilterSegments: React.FC<IFilterSegmentsProps> = ({
  onFilterList,
  onClickAddSegment,
}) => {
  const [filterByName, setFilterByName] = useState('');

  const debounceValue = useDebounce(filterByName, 900);

  useEffect(() => {
    onFilterList({
      name: filterByName
        ? { iLike: `$${debounceValue.replace(' ', '$')}$` }
        : undefined,
    });
  }, [debounceValue]);

  return (
    <FilterHeader
      customFilter={[
        <Input.Search
          key={`filter-segments-search`}
          value={filterByName}
          placeholder={'Busque pelo segmento desejado.'}
          onChange={evt => setFilterByName(evt.target.value)}
          style={{ width: '360px' }}
          allowClear
        />,
        <Button
          key={`filter-segment-filter-button`}
          style={{ display: 'none' }}
          icon={<FilterOutlined />}
          onClick={onClickAddSegment}
        >
          Filtrar
        </Button>,
        <Button
          key={`filter-add-segment-button`}
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={onClickAddSegment}
        >
          Adicionar
        </Button>,
      ]}
      buttonList={[]}
    />
  );
};
