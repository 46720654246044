import { base } from '@service/base';

import { CouponDigital } from 'src/models';

export async function getDigitalCouponById(
  couponId: number
): Promise<CouponDigital.ICoupon> {
  try {
    const { data } = await base.get<CouponDigital.ICoupon>(
      `/coupon/${couponId}`
    );
    return data;
  } catch (error) {
    throw new Error('Não foi possível carregar o cupom solicitado!');
  }
}
