import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const RightGroupContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const LeftGroupContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonContainer = styled.div``;

export const CustomFilter = styled.div``;
