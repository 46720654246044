import { CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
export const ActiveTag: React.FC = () => {
  return (
    <Tag icon={<CheckCircleOutlined />} color='success'>
      Ativo
    </Tag>
  );
};
