import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { EmptyButton } from '@atoms';
import { useModal } from '@hooks';
import { Modal } from '@models';
import { SelectController } from '@organisms';
import { IModalScreen } from '@service/api/modals/getModalScreens';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { Modal as AntModal } from 'antd';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { env } from 'src/app/env';

import { ModelStepBaseComponentProp } from '../ModalDetail';
import { BaseContent } from './BaseContent.component';

export const PaginationContent = ({
  form,
}: ModelStepBaseComponentProp<Modal.IPaginationContent>) => {
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const [indexState, setIndexState] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'modalContent',
  });

  const { modalScreens, getModalScreens, getModalScreensOptions } = useModal();
  const modalScreensOptions = getModalScreensOptions(
    modalScreens as IModalScreen[],
    env.URL_SITE || ''
  );

  useEffect(() => {
    getModalScreens();
    if (fields.length <= 0) {
      append({});
    }
  }, []);

  return (
    <>
      <Form layout='vertical'>
        <Row>
          <Col span={24}>
            <SelectController
              control={form.control}
              name='urlLocalShow'
              options={modalScreensOptions}
              label='Local para exibição do modal'
              optionFilterProp='label'
              placeholder='Escolha a página onde o modal será exibido'
            />
          </Col>
        </Row>

        <div style={{ paddingTop: '24px' }} />

        <Collapse expandIconPosition='end'>
          {fields.map((field, index) => (
            <Collapse.Panel
              header={`Conteúdo ${index + 1}`}
              key={field.id}
              extra={
                <EmptyButton
                  onClick={() => {
                    setShowRemoveItemModal(true);
                    setIndexState(index);
                  }}
                  disabled={fields.length <= 1}
                >
                  <DeleteOutlined />
                </EmptyButton>
              }
            >
              <BaseContent
                form={form}
                fieldArrayName='modalContent'
                index={index}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Form>

      <div style={{ paddingTop: '24px' }}>
        <Row justify='center' align={'middle'}>
          <Col>
            <Button
              type='text'
              style={{ color: '#1668DC' }}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                append({});
              }}
              disabled={fields.length >= 10}
            >
              Adicionar mais uma opção
            </Button>
          </Col>
        </Row>
      </div>

      <AntModal
        width={704}
        title=''
        open={!!showRemoveItemModal}
        onOk={() => {
          setShowRemoveItemModal(false);
          remove(indexState);
        }}
        okText='Excluir'
        cancelText='Continuar edição'
        okButtonProps={{
          style: { backgroundColor: '#a61d24', border: 'none' },
        }}
        onCancel={() => setShowRemoveItemModal(false)}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <ExclamationCircleOutlined
              style={{
                color: '#D89614',
              }}
            />
          </div>
          <p style={{ fontWeight: 'bold' }}> Excluir item? </p>
        </div>

        <div
          style={{
            marginLeft: '24px',
            marginTop: '8px',
          }}
        >
          Deseja excluir o conteúdo {indexState + 1} do modal de paginação?
        </div>
      </AntModal>
    </>
  );
};
