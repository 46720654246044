import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { SpecialistFormData } from '../../types/specialistPageTypings';
import { specialistFunnelsPagesFactory } from '../../utils/specialistFunnelsPagesFactory';

interface UpdateSpecialistFunnelPageRequest {
  id: string;
  payload: SpecialistFormData;
}

export function useTryToRequestUpdateSpecialistFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async ({ id, payload }: UpdateSpecialistFunnelPageRequest) => {
      const body = specialistFunnelsPagesFactory(payload);

      return await apiCancellationFunnels.tryToRequestUpdateCancellationFunnelPage(
        id,
        body
      );
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao editar o funil de Atendimento Especialista',
          'Ocorreu um erro ao editar o funil de Atendimento Especialista, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return { tryToRequestUpdateSpecialistFunnelPage: mutateAsync, isLoading };
}
