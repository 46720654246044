import { FC } from 'react';
import styled from 'styled-components';

export interface AddonBeforeComponentProps {
  label: string;
  width?: string;
}

const colorGrey = 'rgb(67, 67, 67)';
export const AddonBeforeComponent: FC<AddonBeforeComponentProps> = ({
  label,
  children,
  width,
}) => {
  return (
    <>
      <span
        className='ant-input-group-wrapper'
        style={{
          width: `${width}`,
          borderBottomColor: colorGrey,
          borderBottomStyle: 'solid',
          borderRadius: 0,
          borderBottomWidth: 1,
        }}
      >
        <span className='ant-input-wrapper ant-input-group'>
          <span className='ant-input-group-addon'>
            <AddonBefore>{label}</AddonBefore>
          </span>
          {children}
        </span>
      </span>
    </>
  );
};

const AddonBefore = styled.div`
  width: 200px;
  text-align: left;
  border-radius: 0;
`;
