import { useContext } from 'react';

import { CreateOrderBumpContext } from '../providers/CreateOrderBumpProvider';

export const useCreateOrderBump = () => {
  const context = useContext(CreateOrderBumpContext);
  if (!context)
    throw new Error(
      'This hook useCreateOrderBump must be used in CreateOrderBumpProvider'
    );

  return context;
};
