import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function getAllBannerSegments(): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/banner-segments`,
    method: 'GET',
    headers: {
      'Ngrok-Skip-Browser-Warning': 'any',
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
