import styled from 'styled-components';

export const ModalModelSpace = styled.div`
  padding: 16.56px;
`;

export const ModalModelTitleSkeleton = styled.div`
  height: 8.28px;
  width: 103.48px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const ModalModelDescriptionSkeleton = styled.div`
  height: 8.28px;
  width: 206.95px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const ModalModelTextboxSkeleton = styled.div`
  height: 120.03px;
  width: 248.34px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const ModalModelFooterContainerSkeleton = styled.div`
  width: 248.34px;
  height: 16.56px;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalModelFooterLeftContainerSkeleton = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
`;

export const ModalModelFooterRightContainerSkeleton = styled.div`
  position: absolute;
  right: 0;
`;

export const ModalModelFooterCircleSkeleton = styled.div`
  width: 8.28px;
  min-height: 8.28px;
  border-radius: 16.56px;

  background-color: #434343;
`;

export const ModalModelFooterLineSkeleton = styled.div`
  width: 49.67px;
  height: 8.28px;
  border-radius: 16.56px;
  margin-left: 8.28px;

  background-color: #434343;
`;

export const ModalModelButtonSkeleton = styled.div`
  width: 49.67px;
  min-height: 16.56px;
  border-radius: 4.14px;
  margin-left: 8.28px;

  background-color: #434343;
`;
