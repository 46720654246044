import { AnyObject } from 'yup/lib/types';

import { guestApi } from 'src/app/guestApi';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { ResponseApiDto } from 'src/core/dto/response-api.dto';

interface IVipGroupApi {
  obterLista(
    idSocialNetwork: number
  ): Promise<DataResponseApiDto<AnyObject[]> | undefined>;
  criar(values: AnyObject): Promise<ResponseApiDto | undefined>;
}

export function createVipGroupApi(): IVipGroupApi {
  async function obterLista(
    idSocialNetwork: number
  ): Promise<DataResponseApiDto<AnyObject[]> | undefined> {
    const response = await guestApi.get<DataResponseApiDto<AnyObject[]>>(
      `/vip/group?idVipGroupSocialNetwork=${idSocialNetwork}`
    );

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }
  async function criar(values: AnyObject): Promise<ResponseApiDto | undefined> {
    const response = await guestApi.post<ResponseApiDto>(`/vip/group`, {
      idVipGroupSocialNetwork: values.idVipGroupSocialNetwork,
      name: values.name,
    });

    if (!response || (response && !response?.data?.result)) {
      return undefined;
    }

    return response.data;
  }

  return {
    obterLista,
    criar,
  };
}
