import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, Menu, Row } from 'antd';
import { FC, useState } from 'react';

import { PageHeader } from 'src/app/components/PageHeader';

import { ManageCompetitionsResponseDto } from '../../dto/manage-competitions.response.dto';
import { StyledTable } from '../../styles';
import SegmentInput from './SegmentInput';

const GameficationCompetitionFormStep3: FC = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line
  const onFinish = async (j: any) => {
    const re = RegExp(`.*${j.search.toLowerCase().split('').join('.*')}.*`);

    const matches =
      // eslint-disable-next-line
      data && [...data].filter((v: any) => v?.customer.toLowerCase().match(re));

    // eslint-disable-next-line
    const res: any = j?.search !== '' ? matches : _genData();
    setData(res);
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
  };

  const RENDER_CTA = () => (
    <Button
      onClick={() => console.info('yay', selectedRowKeys)}
      disabled={!selectedRowKeys?.length}
      data-testid='delete'
      size='large'
      icon={<DeleteOutlined />}
      type='link'
      danger
    >
      Remover
    </Button>
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const ACTIONS = [
    {
      key: 'edit',
      label: 'Visualizar / Editar',
      onClick: () => null,
      // history.push(`competition/${j.id}`),
    },
    {
      label: 'Remover',
      key: 'remove',
      onClick: () => null,
      // onClick: j => console.info('modal remoção', j),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    { title: 'Aluno', dataIndex: 'customer' },
    {
      title: 'Ações',
      key: 'action',
      width: 20,
      render: (_item: ManageCompetitionsResponseDto) => (
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          overlay={() => (
            <Menu>
              {ACTIONS.map(({ label, onClick, key }) => (
                <Menu.Item
                  key={key}
                  // onClick={() => onClick(_item)}
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu>
          )}
        >
          <Button type='text'>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const _genData = () => {
    const dataX = [];
    for (let i = 0; i < 46; i++) {
      dataX.push({
        key: i,
        customer: i % 3 ? 'Julio Fonseca' : 'Lucas Rodrigues',
      });
    }

    return dataX;
  };

  // useEffect(() => {
  //   setData(_genData());
  // }, []);

  const PROPS = {
    dataSource: data,
    rowSelection,
    columns,
    bordered: true,
    // rowKey: 'id',
  };

  return (
    <>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Público Alvo' />
        </Col>

        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          {RENDER_CTA()}
        </Col>
      </Row>

      <Form size='large' layout='vertical' {...FORM_PROPS}>
        <Row style={{ marginBottom: 20 }} gutter={30}>
          <Col span={12}>
            <SegmentInput label='Segmento' name='segmentId' />
          </Col>

          <Col span={12}>
            <Form.Item label='Pesquisar' name='search'>
              <Input.Search
                placeholder='Filtrar por...'
                style={{ width: '100%' }}
                onSearch={form.submit}
                size='large'
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StyledTable {...PROPS} pagination={{ position: ['bottomCenter'] }} />
    </>
  );
};

export default GameficationCompetitionFormStep3;
