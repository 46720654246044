import { auth } from '@service/api';
import { Button, Modal } from 'antd';
import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import { RecoveryAccessStepName } from 'src/app/recovery-access-types';

import { ModalRecoveryAccessChangePasswordStep } from './modals/ModalRecoveryAccessChangePasswordStep';
import { ModalRecoveryAccessCodeStep } from './modals/ModalRecoveryAccessCodeStep';
import { ModalRecoveryAccessEmailStep } from './modals/ModalRecoveryAccessEmailStep';
import { ModalRecoveryAccessSuccessStep } from './modals/ModalRecoveryAccessSuccessStep';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  stepName?: RecoveryAccessStepName;
};

export const ModalRecoveryAccess: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  stepName = 'email',
}) => {
  const [loading, setLoading] = useState(false);
  const [emailRecoveryAccess, setEmailRecoveryAccess] = useState('');
  const [codeRecoveryAccess, setCodeRecoveryAccess] = useState('');
  const [newPasswordRecoveryAccess, setNewPasswordRecoveryAccess] =
    useState('');
  const [
    confirmNewPasswordRecoveryAccess,
    setConfirmNewPasswordRecoveryAccess,
  ] = useState('');
  const [errorCodeRecoveryAccess, setErrorCodeRecoveryAccess] = useState('');
  const [errorEmailRecoveryAccess, setErrorEmailRecoveryAccess] = useState('');
  const [errorNovaSenhaRecuperarAcesso, setErrorNovaSenhaRecuperarAcesso] =
    useState('');
  const [stepNameAtual, setStepNameAtual] = useState(stepName);

  const getErrorTextByException = (ex: unknown): string => {
    const { status } = ex as { status: number };
    if (status === 403) {
      return 'Dominio do e-mail não autorizado no sistema.';
    }
    if (status === 404) {
      return 'E-mail não encontrado. Caso o e-mail esteja correto, solicite ajuda de um administrador.';
    }
    return 'Erro ao enviar o e-mail.';
  };

  const nextStep = async () => {
    if (stepNameAtual === 'email') {
      setLoading(true);
      try {
        if (emailRecoveryAccess !== '') {
          setErrorEmailRecoveryAccess('');
          await auth.enviarEmail(emailRecoveryAccess);
          setStepNameAtual('code');
        } else {
          setErrorEmailRecoveryAccess('Informe o e-mail.');
        }
      } catch (e: unknown) {
        setErrorEmailRecoveryAccess(getErrorTextByException(e));
      }
      setLoading(false);
      return;
    }
    if (stepNameAtual === 'code') {
      setLoading(true);
      try {
        if (emailRecoveryAccess !== '' && codeRecoveryAccess !== '') {
          setErrorCodeRecoveryAccess('');
          await auth.validarEmailCodigo(
            emailRecoveryAccess,
            parseInt(codeRecoveryAccess)
          );
          setStepNameAtual('change-password');
        } else {
          setErrorCodeRecoveryAccess(
            emailRecoveryAccess === ''
              ? 'Informe o e-mail.'
              : 'Informe o codigo.'
          );
        }
      } catch (e) {
        setErrorCodeRecoveryAccess('Erro ao validar o código.');
      }
      setLoading(false);
    }
    if (stepNameAtual === 'change-password') {
      setLoading(true);
      try {
        if (
          emailRecoveryAccess !== '' &&
          codeRecoveryAccess !== '' &&
          confirmNewPasswordRecoveryAccess !== ''
        ) {
          setErrorNovaSenhaRecuperarAcesso('');
          await auth.alterarSenha(
            emailRecoveryAccess,
            parseInt(codeRecoveryAccess),
            confirmNewPasswordRecoveryAccess
          );
          setStepNameAtual('success');
        } else {
          setErrorNovaSenhaRecuperarAcesso('Informe o codigo.');
        }
      } catch (e) {
        setErrorNovaSenhaRecuperarAcesso('Erro ao validar o código.');
      }
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEmailRecoveryAccess('');
    setErrorEmailRecoveryAccess('');
    setErrorCodeRecoveryAccess('');
    setErrorNovaSenhaRecuperarAcesso('');
    setStepNameAtual('email');
    setIsModalVisible(false);
  };

  const modalContent = (): JSX.Element | undefined => {
    if (stepNameAtual === 'email') {
      return (
        <ModalRecoveryAccessEmailStep
          loading={loading}
          error={errorEmailRecoveryAccess}
          email={emailRecoveryAccess}
          setErrorEmailRecoveryAccess={setErrorEmailRecoveryAccess}
          setEmailRecoveryAccess={setEmailRecoveryAccess}
          onConfirm={nextStep}
        />
      );
    }
    if (stepNameAtual === 'code') {
      return (
        <ModalRecoveryAccessCodeStep
          loading={loading}
          error={errorCodeRecoveryAccess}
          codigo={codeRecoveryAccess}
          setCodeRecoveryAccess={setCodeRecoveryAccess}
          setErrorCodeRecoveryAccess={setErrorCodeRecoveryAccess}
          onConfirm={nextStep}
        />
      );
    }
    if (stepNameAtual === 'change-password') {
      return (
        <ModalRecoveryAccessChangePasswordStep
          loading={loading}
          error={errorNovaSenhaRecuperarAcesso}
          newPassword={newPasswordRecoveryAccess}
          confirmNewPassword={confirmNewPasswordRecoveryAccess}
          setNewPasswordRecoveryAccess={setNewPasswordRecoveryAccess}
          setConfirmNewPasswordRecoveryAccess={
            setConfirmNewPasswordRecoveryAccess
          }
          setErrorNovaSenhaRecuperarAcesso={setErrorNovaSenhaRecuperarAcesso}
        />
      );
    }
    if (stepNameAtual === 'success') {
      return <ModalRecoveryAccessSuccessStep onClose={handleCancel} />;
    }

    handleCancel();
    return undefined;
  };

  const buttonFooter = ():
    | Array<JSX.Element>
    | JSX.Element
    | ReactNode
    | undefined => {
    if (stepNameAtual === 'success') {
      return null;
    }
    return [
      <Button
        disabled={loading}
        key='back'
        data-testid='back'
        onClick={handleCancel}
      >
        Cancelar
      </Button>,
      <Button
        loading={loading}
        key='submit'
        data-testid='submit'
        type='primary'
        onClick={nextStep}
      >
        Confirmar
      </Button>,
    ];
  };

  const titleModal = (): ReactNode => {
    if (stepNameAtual === 'success') {
      return null;
    }
    return 'Esqueci minha senha';
  };

  return (
    <>
      <Modal
        width={543}
        title={titleModal()}
        onOk={nextStep}
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={buttonFooter()}
      >
        {modalContent()}
      </Modal>
    </>
  );
};
