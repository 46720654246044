import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { ProgramAccessResponseDto } from 'src/features/clients/types/clientTypes';

const fetchProgramAccess = async (emailParam?: string) => {
  const response = await clientApi.getProgramAccess(emailParam);
  if (
    !isSerializedApiError(response) &&
    (response as ProgramAccessResponseDto).status === Success
  ) {
    return (response as ProgramAccessResponseDto).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar os Acessos a Plataforma do Cliente',
      'Ocorreu um erro  ao carregar os Acessos a Plataforma do Cliente, por favor tente novamente mais tarde.'
    );
  }
};

export default function useProgramAccess(email?: string) {
  return useQuery(['programAccess', email], () => fetchProgramAccess(email), {
    refetchOnWindowFocus: false,
  });
}
