import { IEventCount } from '@core/gamefication/dto/event/event-response.dto';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';
import { AnyObject } from 'yup/lib/types';

const BANNER_MS_BASE_URL = enviroments.GAMEFICATION_URL;

export async function getAllEvents(filter?: AnyObject): Promise<IEventCount> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/event`,
    method: 'GET',
    params: {
      where: JSON.stringify(where),
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
