import { useUpsellProducts } from '@hooks';
import React from 'react';

import { FilterUpsellProducts } from './Filter.upsellProducts';
import { TableUpsellProducts } from './Table.upsellProducts';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const UpsellProducts: React.FC = () => {
  const {
    upsellProducts,
    isLoadingData,
    handleClickRow,
    handleFilterUpsellProducts,
  } = useUpsellProducts();

  return (
    <TableUpsellProducts
      filterComponent={
        <FilterUpsellProducts
          onFilterUpsellProducts={handleFilterUpsellProducts}
        />
      }
      data={upsellProducts}
      onClickItem={handleClickRow}
      isLoading={isLoadingData}
    />
  );
};
