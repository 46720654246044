import { base } from '@service/base';

import { CouponPhysic } from 'src/models';

export type TEditPhysicCouponProps = {
  id: number;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: CouponPhysic.PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  discount: {
    type: CouponPhysic.TypeEnum;
    value: number;
  };
  maxUse: number;
  maxUsePerCustomer: number;
  active: boolean;
};

export async function editPhysicCoupon(data: TEditPhysicCouponProps) {
  const response = await base.put(`/sales/coupon`, data);

  return response.data;
}
