import { Assets } from '@models';

import { capitalizeOnlyFirstLetter } from './capitalizeOnlyFirstLetter';

export const getAllAssetDevices = (
  assetBanner: Assets.IAsset['banner']
): string => {
  let deviceText = '';
  const deviceUsed: string[] = [];

  assetBanner.devices?.forEach((item, index) => {
    if (!deviceUsed.includes(item.device.deviceName)) {
      deviceUsed.push(item.device.deviceName);
      const deviceToUse = assetBanner.devices.filter(item => {
        return !deviceUsed.includes(item.device.deviceName);
      });

      deviceText +=
        deviceToUse.length === 0
          ? `${capitalizeOnlyFirstLetter(item.device.deviceName)}`
          : `${capitalizeOnlyFirstLetter(item.device.deviceName)}, `;
    }
  });

  return deviceText;
};
