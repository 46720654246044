import styled from 'styled-components';

export const Container = styled.tr`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #303030;
`;

export const DragButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 64fr 1fr 5fr;
  gap: 0px;
  width: 100%;

  & > td {
    &:last-child {
      display: flex;
      justify-content: end;
    }
  }
`;

export const TagContainer = styled.div``;
