import { ArrowLeftOutlined } from '@ant-design/icons';
import { EmptyButton } from '@atoms';
import { Divider } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  ExtraContent,
  HeaderContent,
  IconContainer,
  LeftContent,
  SubTitle,
  SubTitleContainer,
  Title,
  TitleContainer,
} from './PageHeader.style';

interface PageHeaderProps {
  backToPath: string | (() => void);
  extra?: React.ReactNode;
  hasDivider?: boolean;
  subTitle: string;
  title: string;
}
export const PageHeader: React.FC<PageHeaderProps> = ({
  backToPath,
  extra,
  hasDivider = true,
  subTitle,
  title,
}) => {
  const history = useHistory();
  const backTo = () => {
    if (typeof backToPath === 'string') {
      history.push(backToPath);
    }

    if (typeof backToPath === 'function') {
      backToPath();
    }
  };
  return (
    <Container>
      <HeaderContent>
        <LeftContent>
          <TitleContainer>
            <IconContainer>
              <EmptyButton onClick={backTo}>
                <ArrowLeftOutlined />
              </EmptyButton>
            </IconContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <SubTitleContainer>
            <SubTitle>{subTitle}</SubTitle>
          </SubTitleContainer>
        </LeftContent>

        {extra && <ExtraContent>{extra}</ExtraContent>}
      </HeaderContent>

      {hasDivider && <Divider />}
    </Container>
  );
};
