import { useUpsellLinks } from '@hooks';
import React from 'react';

import { UrlModal } from '../../components/UrlModal/UrlModal';
import { FilterUpsellLinks } from './Filter.upsellLinks';
import { TableUpsellLinks } from './Table.upsellLinks';

export const UpsellLinks: React.FC = () => {
  const {
    isLoadingData,
    handleChangePagination,
    handleFilterUpsellLinks,
    handleCreateUpsellLinks,
    handleUpsellLinks,
    handlePreviewUpsellLinks,
    handleEditUpsellLinks,
    modalUrl,
    upsellLinks,
  } = useUpsellLinks();

  return (
    <>
      <TableUpsellLinks
        onChangePagination={handleChangePagination}
        filterComponent={
          <FilterUpsellLinks
            onAddUpsellLink={handleCreateUpsellLinks}
            onFilterUpsellLink={handleFilterUpsellLinks}
          />
        }
        data={upsellLinks}
        onChangeUrl={handleUpsellLinks}
        onEditUpsellLinks={handleEditUpsellLinks}
        onPreviewOption={handlePreviewUpsellLinks}
        isLoading={isLoadingData}
      />
      <UrlModal
        modalIsOpen={modalUrl.isOpen}
        onCloseModal={modalUrl.handleCloseModalUrl}
        dataModal={modalUrl.dataModal}
        isPreview={modalUrl.isModalPreview}
      />
    </>
  );
};
