import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { MediaFormData } from '../../types/mediaPageTypings';
import { mediaPayloadFunnelsPageFactory } from '../../utils/mediaFunnelsPageFactory';

export function useTryToRequestCreatingMediaFunnelPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async (payload: MediaFormData) => {
      const factory = await mediaPayloadFunnelsPageFactory(payload);
      await apiCancellationFunnels.tryToRequestCreatingMediaFunnelPage(factory);
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao criar o funil de Mídia',
          'Ocorreu um erro ao criar o funil de Mídia, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestCreatingMediaFunnelPage: mutateAsync,
    isLoadingCretingMedia: isLoading,
  };
}
