import { Image } from 'antd';
import styled from 'styled-components';

export const Title = styled.div`
  color: #525252;
  font-size: 24px;
  font-weight: 700;
  padding-top: 12px;
`;

export const Description = styled.div`
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
`;

export const CustomImage = styled(Image)`
  border-radius: 8px;
  margin-bottom: 36px;
`;

export const Body = styled.div``;

export const CarouselContainer = styled.div`
  .ant-carousel .slick-dots li button {
    background: #ccc;
    height: 10px;
    width: 10px;
    border-radius: 6px;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #ff4f00;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px;
    z-index: 1;
  }

  .slick-prev:not(.slick-disabled),
  .slick-next:not(.slick-disabled) {
    background-color: #ffecd3 !important;
    color: #ff4f00 !important;
  }

  .slick-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .slick-next {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      color: initial;
      background-color: initial;
    }
  }
`;
