import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export const deleteBadgeGroup = createAsyncThunk(
  'gamification/badgeGroups/deleteBadgeGroup',
  async (badgeId: number, { rejectWithValue, dispatch }) => {
    try {
      await badgesGroupsApi.deleteBadgeGroup(badgeId);
      OpenNotification(false, 'Badge deletada com sucesso!');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBadgeGroupBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(deleteBadgeGroup.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(deleteBadgeGroup.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
  });

  builder.addCase(deleteBadgeGroup.rejected, state => {
    state.status = 'ERROR';
  });
};
