import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { downloadCsv } from 'src/features/Lgpd/utils/downloadCsv';

type UserModalProps = {
  requester?: string;
  date?: string;
  linkCsv?: string;
};

export const ModalInfoUser: FC<UserModalProps> = ({
  requester,
  date,
  linkCsv,
}) => {
  return (
    <>
      <Row style={{ flexDirection: 'column' }}>
        <Title>Solicitante</Title>
        <Text>{requester}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />
      <Row style={{ flexDirection: 'column' }}>
        <Title>Data da solicitação</Title>
        <Text>{date}</Text>
      </Row>
      <Divider style={{ marginBlock: '10px' }} />
      <Row style={{ flexDirection: 'column' }}>
        <Title>Baixe o arquivo CSV com os dados completos</Title>
        <Button
          onClick={() => {
            downloadCsv(linkCsv);
          }}
          style={{
            maxWidth: '165px',
            marginBlockStart: '8px',
            background: '#177DDC',
            color: '#FFFFFF',
          }}
        >
          <DownloadOutlined />
          Baixar arquivo CSV
        </Button>
      </Row>
    </>
  );
};

const Title = styled.h1`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: '#DBDBDB';

}`;

const Text = styled.h1`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ACACAC;

}`;
