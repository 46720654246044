import { Avatar, Button, Card, Divider } from 'antd';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
const { Meta } = Card;

export interface BasicCardProps {
  title: string;
  text: string;
  linkName1?: string;
  link1?: string;
  linkName2?: string;
  link2?: string;
  linkName3?: string;
  link3?: string;
  button?: string;
  buttonLink?: string;
  // eslint-disable-next-line
  icon?: React.ReactElement<any>;
}

export const BasicCard: FC<BasicCardProps> = ({
  title,
  text,
  linkName1,
  link1,
  linkName2,
  link2,
  linkName3,
  link3,
  button,
  buttonLink,
  icon,
  ...rest
}) => {
  const history = useHistory();

  return (
    <CardStyled bordered={false}>
      <Meta
        avatar={
          <Avatar
            icon={icon}
            style={{
              backgroundColor: '#2b2b2b',
              borderRadius: 15,
              width: 40,
              height: 40,
              display: 'table-cell',
            }}
          />
        }
        title={title}
      />

      <Divider />

      <BodyContent>
        <p>{text}</p>
        <Link to={link1 ?? ''}>{linkName1}</Link>
        <br />
        <Link to={`${link2}`}>{linkName2}</Link>
        <br />
        <Link to={`/dashboard`} style={{ cursor: 'not-allowed' }}>
          {linkName3}
        </Link>
      </BodyContent>

      <FooterContent>
        {button && (
          <SubmitButton
            htmlType='button'
            type='default'
            disabled={!buttonLink}
            onClick={() => history.push(`${buttonLink}`)}
            data-testid={`goto-${buttonLink}`}
          >
            {button}
          </SubmitButton>
        )}
      </FooterContent>
    </CardStyled>
  );
};

const CardStyled = styled(Card)`
  border-radius: 10px;
  background: #202020;
  max-width: 85%;

  & > div {
    height: 357px;
  }

  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  background-color: inherit;
`;

const BodyContent = styled.div`
  height: 54%;
`;

const FooterContent = styled.div`
  width: 100%;

  margin-top: auto;
`;
