import { FC } from 'react';
import { RouteProps } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { BadgeDetails } from '../components/BadgeDetails';

export const BadgeDetailsScreen: FC<RouteProps> = () => {
  useBreadcrumbs([
    {
      label: 'Social',
      route: '/social/dashboard',
    },
    {
      label: 'Gamefication',
    },
    {
      label: 'Badges',
    },
    {
      label: 'Detalhes',
    },
  ]);
  return (
    <>
      <BadgeDetails />
    </>
  );
};
