import { Col, Input, InputNumber, Row, Select } from 'antd';

import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';
import { ICompetitionResumeResponseDto } from 'src/core/gamefication/dto/competition/competition-resume-response.dto';

import { Label } from './label';

interface Props {
  formData?: IBadgeGroupRequestDto;
  disabledForm?: boolean;
  loadingBadgeGroup?: boolean;
  setFormData?: (valueEdited: IBadgeGroupRequestDto) => void;
  listCompetitions?: Array<ICompetitionResumeResponseDto>;
}

const { Option } = Select;

export const BadgeGroupForm: React.FC<Props> = ({
  formData = undefined,
  disabledForm = false,
  loadingBadgeGroup = false,
  setFormData = undefined,
  listCompetitions = undefined,
}) => {
  const handleCompetitionChangeSelect = (value: number) => {
    if (!setFormData || !formData) {
      return;
    }

    setFormData({
      ...formData,
      competition: {
        id: value,
        name: '',
      },
    });
  };

  const handleChange = (evt: { target: { name: string; value: string } }) => {
    if (!setFormData || !formData) {
      return;
    }
    const value = evt.target.value;
    const name = evt.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleHideNameChangeSelect = (value: string) => {
    if (!setFormData || !formData) {
      return;
    }
    setFormData({
      ...formData,
      hideName: Boolean(value),
    });
  };

  const handleOrdemChangeValue = (value: number | null) => {
    if (!setFormData || !formData || !value) {
      return;
    }
    setFormData({
      ...formData,
      order: value,
    });
  };

  const optionsCompetitions = (): Array<JSX.Element> => {
    const arrayOptions: Array<JSX.Element> = [];
    if (!listCompetitions || (listCompetitions && !listCompetitions.length)) {
      return arrayOptions;
    }
    arrayOptions.push(
      <Option key={`option-${Date.now()}`} value={0} disabled>
        Selecione
      </Option>
    );
    listCompetitions.forEach((item: ICompetitionResumeResponseDto, index) => {
      arrayOptions.push(
        <Option key={`option-${index}-${Date.now()}`} value={item.id}>
          {item.name}
        </Option>
      );
    });
    return arrayOptions;
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ paddingRight: 10 }}>
        <Label>Competição</Label>
        <Select
          disabled={!disabledForm || loadingBadgeGroup}
          allowClear={false}
          placeholder='Competição'
          style={{ width: '100%' }}
          value={formData?.competition?.id}
          onChange={e => handleCompetitionChangeSelect(e)}
        >
          {optionsCompetitions()}
        </Select>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ paddingRight: 10 }}>
        <Label>Nome do grupo</Label>
        <Input
          disabled={!disabledForm || loadingBadgeGroup}
          onChange={handleChange}
          value={formData?.name}
          name='name'
        />
      </Col>
      <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ paddingRight: 10 }}>
        <Row>
          <Col span={24}>
            <Label>Mostrar nome</Label>
          </Col>
          <Col span={24}>
            <Select
              disabled={!disabledForm || loadingBadgeGroup}
              defaultValue='true'
              style={{ width: '100%' }}
              onChange={e => handleHideNameChangeSelect(e)}
              value={formData?.hideName.toString()}
            >
              <Option value='true'>Sim</Option>
              <Option value='false'>Não</Option>
            </Select>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <Row>
          <Col span={24}>
            <Label>Ordem</Label>
          </Col>
          <Col span={24}>
            <InputNumber
              disabled={!disabledForm || loadingBadgeGroup}
              min={1}
              onChange={value => handleOrdemChangeValue(value)}
              defaultValue={1}
              style={{ width: '100%' }}
              value={formData?.order}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
