import { guestApi } from 'src/app/guestApi';

import {
  Offer,
  OffersByProduct,
  ProductDto,
} from '../interfaces/offersInterface';

async function getProducts(): Promise<ProductDto[]> {
  const endpoint = `/products/product-sell-group`;
  return (await guestApi.get(endpoint)).data;
}

async function getOffers(productId: string): Promise<OffersByProduct> {
  const endpoint = `/products/product-sell-group/${productId}`;
  return (await guestApi.get(endpoint)).data;
}

async function getOfferById(offerID: string): Promise<Offer> {
  const endpoint = `/products/offer/${offerID}`;
  return (await guestApi.get(endpoint)).data;
}

async function putOfferById(offerID: string, data: FormData) {
  const endpoint = `/products/offer/${offerID}`;
  const result = await guestApi.put(endpoint, data).then(e => {
    return e;
  });

  return result;
}

async function postOfferById(data: FormData) {
  const endpoint = `/products/offer`;
  const result = await guestApi.post(endpoint, data).then(e => {
    return e;
  });

  return result;
}

export const offerApi = {
  getProducts,
  getOffers,
  getOfferById,
  putOfferById,
  postOfferById,
};
