import { Assets } from '@models';

import { capitalizeOnlyFirstLetter } from './capitalizeOnlyFirstLetter';

export const getAssetType = (assetBanner: Assets.IAssetContext[]): string => {
  let contextText = '';
  const contextUsed: string[] = [];

  assetBanner?.forEach((item, index) => {
    if (!contextUsed.includes(item.context.identifier)) {
      contextUsed.push(item.context.identifier);
      const deviceToUse = assetBanner.filter(item => {
        return !contextUsed.includes(item.context.identifier);
      });

      contextText +=
        deviceToUse.length === 0
          ? `${capitalizeOnlyFirstLetter(item.context.identifier)}`
          : `${capitalizeOnlyFirstLetter(item.context.identifier)}, `;
    }
  });

  return contextText;
};
