import { Assets } from '@models';
import moment from 'moment';
import { array, boolean, object, SchemaOf, string } from 'yup';

export const assetValidationSchema: SchemaOf<Assets.BannerHookForm> = object({
  title: string().required('Este campo é obrigatório'),
  status: boolean(),
  buttonLabel: string().test({
    test: (value, context) => {
      if (!context.parent.buttonLabelActive) {
        return true;
      }
      if (!!context.parent.buttonLabelActive && !value) {
        return false;
      }
      if (!!context.parent.buttonLabelActive && !!value) {
        return true;
      }

      return false;
    },
    message: 'Este campo é obrigatório',
  }),
  buttonLabelActive: boolean().default(false),
  description: string().test({
    test: (value, context) => {
      if (!context.parent.descriptionActive) {
        return true;
      }
      if (!!context.parent.descriptionActive && !value) {
        return false;
      }
      if (!!context.parent.descriptionActive && !!value) {
        return true;
      }

      return false;
    },
    message: 'Este campo é obrigatório',
  }),
  descriptionActive: boolean().default(false),
  segments: array().test({
    test: value => {
      if (!value) return false;

      return value.length > 0;
    },
    message: 'Este campo é obrigatório',
  }),
  link: string().test({
    test: (value, context) => {
      if (!context.parent.linkActive) {
        return true;
      }
      if (!!context.parent.linkActive && !value) {
        return false;
      }
      if (!!context.parent.linkActive && !!value) {
        return true;
      }

      return false;
    },
    message: 'Este campo é obrigatório',
  }),
  linkActive: boolean().default(false),
  programBased: string().test({
    test: (value, context) => {
      if (!context.parent.programBasedActive) {
        return true;
      }
      if (!!context.parent.programBasedActive && !value) {
        return false;
      }
      if (!!context.parent.programBasedActive && !!value) {
        return true;
      }

      return false;
    },
    message: 'Este campo é obrigatório',
  }),
  programBasedActive: boolean().default(false),
  effectiveDateTime: string().nullable().required('Este campo é obrigatório'),

  expirationDateTime: string()
    .nullable()
    .required('Este campo é obrigatório')
    .test('notType', 'Este campo é obrigatório', function (value) {
      return !!value;
    })
    .test(
      'date-test',
      'Data de término menor que data de início',
      function (value) {
        const { effectiveDateTime } = this.parent;
        return moment(effectiveDateTime).isBefore(value);
      }
    ),
}).defined();
