import { UpsellStepsFlow } from '@models';
import { base } from '@service/base';

type PayloadCreate = Pick<
  UpsellStepsFlow.IUpsellStepsFlow,
  'flowId' | 'linkId' | 'name' | 'order'
>;

export async function createUpsellStepFlow(data: PayloadCreate) {
  const response = await base.post<{ id: number }>(`/funnel/flow-steps`, {
    ...data,
    up: null,
    down: null,
    isActive: true,
  });

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
