import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { Modal as AntModal } from 'antd';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { useModalDetail } from '../../../hooks/useModalDetail';
import { ModalModelProperties } from './ModalDetail';
import { ModalDetailHandle } from './ModalDetail.handler';
import { ModalDetailSteps } from './ModalDetail.steps';

const haveModelStepDifference = (
  original: Modal.IModal,
  modalModel: ModalModelProperties
) => {
  let haveDifference = false;
  haveDifference = original.type !== modalModel.modalType;
  if (haveDifference) return haveDifference;
  haveDifference = original.modalSegments[0].segmentId !== modalModel.segment;
  return haveDifference;
};

const haveDefaultModalStepDifference = (
  original: Modal.IModalAdversements,
  defaultContent: Modal.IDefaultContent
) => {
  let haveDifference = false;
  haveDifference = original.title !== defaultContent.title;
  if (haveDifference) return true;
  haveDifference = original.description !== defaultContent.description;
  if (haveDifference) return true;
  haveDifference = original.mobileImageFileId !== defaultContent.imageMobile;
  if (haveDifference) return true;
  haveDifference = original.webImageFileId !== defaultContent.imageWeb;
  if (haveDifference) return true;
  haveDifference = original.videoLink !== defaultContent.urlVideoModal;
  if (haveDifference) return true;
  haveDifference = original.buttonLabel !== defaultContent.textButton;
  if (haveDifference) return true;
  haveDifference =
    original.buttonActionLink !== defaultContent.redirectButtonUrl;

  return haveDifference;
};

const havePaginationModalStepDifference = (
  original: Modal.IModalAdversements[],
  { modalContent }: Modal.IPaginationContent
) => {
  let haveDifference = false;
  modalContent.forEach((adversements, index) => {
    haveDifference = original[index].title !== adversements.title;
    if (haveDifference) return true;
    haveDifference = original[index].description !== adversements.description;
    if (haveDifference) return true;
    haveDifference =
      original[index].mobileImageFileId !== adversements.imageMobile;
    if (haveDifference) return true;
    haveDifference = original[index].webImageFileId !== adversements.imageWeb;
    if (haveDifference) return true;
    haveDifference = original[index].videoLink !== adversements.urlVideoModal;
    if (haveDifference) return true;
    haveDifference = original[index].buttonLabel !== adversements.textButton;
    if (haveDifference) return true;
    haveDifference =
      original[index].buttonActionLink !== adversements.redirectButtonUrl;
  });

  return haveDifference;
};

export const ModalDetailPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [ableToSave, setAbleToSave] = useState(false);

  const {
    modelForm,
    defaultForm,
    highlightForm,
    paginationForm,
    schedulingForm,
    getCurrentContentForm,
    saveModal,
    updateModal,
  } = useModalDetail();

  useBreadcrumbs([
    {
      label: 'Modal',
    },
  ]);
  const history = useHistory();

  const { state } = useLocation<Modal.IModal>();

  useEffect(() => {
    if (state?.id) {
      modelForm.setValue('modalType', state.type, { shouldValidate: true });
      modelForm.setValue('segment', state.modalSegments[0]?.segmentId, {
        shouldValidate: true,
      });

      schedulingForm.setValue('activated', state.activated, {
        shouldValidate: true,
      });
      schedulingForm.setValue('effectiveDateTime', state.effectiveDateTime, {
        shouldValidate: true,
      });
      schedulingForm.setValue('expirationDateTime', state.expirationDateTime, {
        shouldValidate: true,
      });

      const customTypeForm = getCurrentContentForm();

      customTypeForm.setValue('urlLocalShow', state.displayScreenLink || '', {
        shouldValidate: true,
      });

      if (state.type === Modal.TypeEnum.PAGINATION) {
        customTypeForm.setValue(
          'modalContent',
          state.advertisements.map(
            adversementItem => {
              let complemet = {};
              const mediaType = !!adversementItem.videoLink ? 'VIDEO' : 'IMAGE';

              if (mediaType === 'VIDEO' && !adversementItem.videoLink) {
                complemet = {
                  urlVideoModal: undefined,
                  ...complemet,
                };
              }

              if (adversementItem.videoLink) {
                complemet = {
                  urlVideoModal: adversementItem.videoLink,
                  ...complemet,
                };
              }

              if (mediaType === 'IMAGE' && !adversementItem.webImageFileId) {
                customTypeForm.setError('imageWeb', { message: 'Campo' });
                complemet = {
                  imageWeb: undefined,
                  ...complemet,
                };
              }

              if (mediaType === 'IMAGE' && !adversementItem.mobileImageFileId) {
                complemet = {
                  imageMobile: undefined,
                  ...complemet,
                };
              }

              if (adversementItem.webImageFileId) {
                complemet = {
                  imageWeb: adversementItem.webImageFileId,
                  ...complemet,
                };
              }

              if (adversementItem.mobileImageFileId) {
                complemet = {
                  imageMobile: adversementItem.mobileImageFileId,
                  ...complemet,
                };
              }

              return {
                id: adversementItem.id,
                title: adversementItem.title,
                titleActivated: adversementItem.titleActivated,
                description: adversementItem.description,
                mediaType: !!adversementItem.videoLink ? 'VIDEO' : 'IMAGE',
                redirectButtonUrl: adversementItem.buttonActionLink || '',
                textButton: adversementItem.buttonLabel || '',
                openExternalWindow: !!adversementItem?.openExternalWindow,
                ...complemet,
              };
            },
            { shouldValidate: true }
          )
        );
      } else {
        customTypeForm.setValue('id', state.advertisements[0].id, {
          shouldValidate: true,
        });

        customTypeForm.setValue('title', state.advertisements[0].title, {
          shouldValidate: true,
        });
        customTypeForm.setValue(
          'titleActivated',
          state.advertisements[0]?.titleActivated,
          {
            shouldValidate: true,
          }
        );
        customTypeForm.setValue(
          'description',
          state.advertisements[0]?.description,
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'mediaType',
          state.advertisements[0]?.webImageFileId &&
            state.advertisements[0]?.mobileImageFileId
            ? 'IMAGE'
            : 'VIDEO',
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'redirectButtonUrl',
          state.advertisements[0]?.buttonActionLink || '',
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'textButton',
          state.advertisements[0]?.buttonLabel || '',
          { shouldValidate: true }
        );

        if (
          !state.advertisements[0]?.videoLink &&
          customTypeForm.getValues('mediaType') === 'VIDEO'
        ) {
          customTypeForm.setValue('urlVideoModal', undefined, {
            shouldValidate: true,
          });
        }

        if (state.advertisements[0]?.videoLink) {
          customTypeForm.setValue(
            'urlVideoModal',
            state.advertisements[0]?.videoLink || undefined,
            { shouldValidate: true }
          );
        }

        if (
          !state.advertisements[0]?.webImageFileId &&
          customTypeForm.getValues('mediaType') === 'IMAGEM'
        ) {
          customTypeForm.setValue('imageWeb', undefined, {
            shouldValidate: true,
          });
        }

        if (
          !state.advertisements[0]?.mobileImageFileId &&
          customTypeForm.getValues('mediaType') === 'IMAGEM'
        ) {
          customTypeForm.setValue('imageMobile', undefined, {
            shouldValidate: true,
          });
        }

        if (state.advertisements[0]?.webImageFileId) {
          customTypeForm.setValue(
            'imageWeb',
            state.advertisements[0]?.webImageFileId,
            { shouldValidate: true }
          );
          customTypeForm.setValue(
            'imageMobile',
            state.advertisements[0]?.mobileImageFileId,
            { shouldValidate: true }
          );
        }
      }
    }
  }, [state]);

  useEffect(() => {
    setAbleToSave(getIfAbleToNext());
  }, [
    paginationForm.formState.isValid,
    modelForm.formState.isValid,
    defaultForm.formState.isValid,
    highlightForm.formState.isValid,
    schedulingForm.formState.isValid,
  ]);

  const getIfAbleToNext = (step?: number): boolean => {
    const verifyStep = step || currentStep;
    if (verifyStep === 0) {
      return modelForm.formState.isValid;
    }

    if (verifyStep === 1) {
      const formInstance: UseFormReturn<
        Modal.IDefaultContent | Modal.IPaginationContent
      > = getCurrentContentForm();
      return formInstance.formState.isValid;
    }

    if (verifyStep === 2) {
      return schedulingForm.formState.isValid;
    }

    return false;
  };

  const handleOnSave = async () => {
    const formInstance: UseFormReturn<
      Modal.IDefaultContent | Modal.IPaginationContent
    > = getCurrentContentForm();

    if (currentStep === 0) {
      modelForm.handleSubmit(() => null);
      if (!state) {
        setAbleToSave(false);
      }
    }

    if (currentStep === 1) {
      formInstance.trigger();
      if (!state) {
        setAbleToSave(false);
      }
    }

    if (currentStep === 2) {
      schedulingForm.trigger();
    }

    const hasAble = getIfAbleToNext();
    if (!hasAble) return;

    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
      return;
    }

    if (currentStep === 2) {
      if (state?.id) {
        await updateModal(state.id);
      } else {
        await saveModal();
      }
      history.push('/screens/modals');
    }
  };

  const handleChangeStep = async (value: number) => {
    const hasAble = getIfAbleToNext(currentStep);

    if (value < currentStep) {
      setCurrentStep(value);
      return;
    }
    if (hasAble) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleCancelForm = () => {
    if (!state) {
      setShowLeaveModal(true);
      return;
    }

    if (haveModelStepDifference(state, modelForm.getValues())) {
      setShowDiscardModal(true);
      return;
    }

    const customTypeForm = getCurrentContentForm();

    if (state.type === Modal.TypeEnum.PAGINATION) {
      if (
        havePaginationModalStepDifference(
          state.advertisements,
          customTypeForm.getValues() as Modal.IPaginationContent
        )
      ) {
        setShowDiscardModal(true);
        return;
      }
    } else {
      if (
        haveDefaultModalStepDifference(
          state.advertisements[0],
          customTypeForm.getValues() as Modal.IDefaultContent
        )
      ) {
        setShowDiscardModal(true);
        return;
      }
    }
  };

  return (
    <LayoutConfirmationFooter
      ableToSave={ableToSave}
      onSave={handleOnSave}
      ableToSubmitDisabled
      onCancel={handleCancelForm}
      continueLabelButton={
        currentStep !== 3 ? 'Continuar' : 'Salvar e publicar'
      }
    >
      {state?.id ? (
        <PageHeader
          backToPath='/screens/modals'
          title='Editar Modal'
          subTitle='Edite um modal definindo seu segmento, tipo, conteúdo e agendamento.'
        />
      ) : (
        <PageHeader
          backToPath='/screens/modals'
          title='Novo Modal'
          subTitle='Crie um novo modal definindo seu segmento, tipo, conteúdo e agendamento.'
        />
      )}

      <ModalDetailSteps
        currentStep={currentStep}
        setCurrentStep={handleChangeStep}
      />

      <div style={{ marginTop: '24px' }} />

      <ModalDetailHandle
        scheduleForm={schedulingForm}
        currentStep={currentStep}
        modelModalForm={modelForm}
        highLightModalForm={highlightForm}
        paginationForm={paginationForm}
        defaultModalForm={defaultForm}
      />

      <AntModal
        width={704}
        title=''
        open={!!showLeaveModal}
        onOk={async () => {
          setShowLeaveModal(false);
          history.push('/screens/modals');
        }}
        okText='Deixar pagina'
        cancelText='Ficar'
        okButtonProps={{
          style: { backgroundColor: '#a61d24', border: 'none' },
        }}
        onCancel={() => setShowLeaveModal(false)}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <ExclamationCircleOutlined
              style={{
                color: '#D89614',
              }}
            />
          </div>
          <p style={{ fontWeight: 'bold' }}>
            Descartar todas as alterações não salvas
          </p>
        </div>

        <div
          style={{
            marginLeft: '24px',
            marginTop: '8px',
          }}
        >
          Sair desta pagina excluirá todas as alterações não salvas.
        </div>
      </AntModal>

      <AntModal
        width={704}
        title=''
        open={!!showDiscardModal}
        onOk={async () => {
          setShowDiscardModal(false);
          history.push('/screens/modals');
        }}
        okText='Deixar página'
        cancelText='Ficar'
        okButtonProps={{
          style: { backgroundColor: '#a61d24', border: 'none' },
        }}
        onCancel={() => setShowDiscardModal(false)}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <ExclamationCircleOutlined
              style={{
                color: '#D89614',
              }}
            />
          </div>
          <p style={{ fontWeight: 'bold' }}>
            Descartar todas as alterações não salvas
          </p>
        </div>

        <div
          style={{
            marginLeft: '24px',
            marginTop: '8px',
          }}
        >
          Se você descartar as alterações, todas as edições feitas desde o
          último salvamento serão excluídas.
        </div>
      </AntModal>
    </LayoutConfirmationFooter>
  );
};
