/* eslint-disable @typescript-eslint/no-empty-function */
import { UpsellLinks } from '@models';
import { ActionsDropdown } from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { format, parseISO } from 'date-fns';

export interface TableUpsellLinksProps {
  filterComponent: React.ReactNode;
  data?: UpsellLinks.TUpsellLinksResponse;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditUpsellLinks?: (value: UpsellLinks.IUpsellLinks) => void;
  onChangeUrl?: (url: string) => void;
  onPreviewOption?: (url: UpsellLinks.IUpsellLinks) => void;
  isLoading: boolean;
}

export type TColumnUrl = {
  column: string;
  inverse: boolean;
};

export const TableUpsellLinks: React.FC<TableUpsellLinksProps> = ({
  filterComponent,
  data,
  onChangePagination,
  isLoading,
  onPreviewOption,
  onEditUpsellLinks,
}) => {
  const headerColumns: PageListProps<UpsellLinks.IUpsellLinks>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '80px',
      render: (id: UpsellLinks.IUpsellLinks['id']) => {
        return String(id);
      },
    },
    {
      title: 'Nome da Url',
      dataIndex: 'urlName',
      key: 'urlName',
      width: '300px',
      render: (urlName: UpsellLinks.IUpsellLinks['urlName']) => {
        return urlName;
      },
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      width: '100px',
      render: (url: UpsellLinks.IUpsellLinks['url']) => {
        return url;
      },
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '156px',
      render: (createdAt: UpsellLinks.IUpsellLinks['createdAt']) => {
        return <div>{format(parseISO(createdAt), 'dd/MM/yyyy')}</div>;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text, record) => (
        <ActionsDropdown
          onClickEditOption={
            !!onEditUpsellLinks ? () => onEditUpsellLinks(record) : undefined
          }
          onClickPreviewOption={
            !!onPreviewOption ? () => onPreviewOption(record) : undefined
          }
        />
      ),
    },
  ];

  if (!data && isLoading) return null;

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={isLoading}
      onChange={onChangePagination}
    />
  );
};
