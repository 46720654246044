import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { CompetitionEventModalAddResponseDto } from 'src/core/gamefication/dto/competition-event/competition-event-modal-add-response.dto';
import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import { CardRegraCondicional } from './card-regra-condicional';

interface Props {
  loading?: boolean;
  events: Array<EventResponseDto>;
  cards: Array<CompetitionEventModalAddResponseDto>;
  onChange?: (
    index: number,
    competitionEvent?: CompetitionEventModalAddResponseDto
  ) => void;
  addNewConditionalRule?: () => void;
  handleRemoveClick?: () => void;
}

export const ListaRegrasCondicional: React.FC<Props> = ({
  loading = false,
  cards = [],
  events = [],
  onChange,
  handleRemoveClick,
  addNewConditionalRule,
}) => {
  const handleOnChange = (
    eventsSelected: Array<EventResponseDto>,
    index?: number,
    type?: number
  ) => {
    onChange?.(index as number, {
      key: `card-regra-${Date.now()}`,
      eventIdList: eventsSelected.map(p => p?.id),
      events: eventsSelected,
      tipo: type as number,
    });
  };

  const renderListaRegrasCondicional = (): Array<JSX.Element> => {
    return cards.map((p, index) => (
      <CardRegraCondicional
        dataSource={p}
        events={events.filter(p => !!p.statusActivated)}
        key={p.key ?? `card-conditional-rule-${index}`}
        index={index}
        onRemoveClick={handleRemoveClick}
        onChange={handleOnChange}
      />
    ));
  };

  return (
    <>
      {renderListaRegrasCondicional()}
      <Button
        type='link'
        icon={<PlusCircleOutlined />}
        style={{ paddingLeft: 0 }}
        onClick={addNewConditionalRule}
      >
        Adicionar outra Regra
      </Button>
    </>
  );
};
