import { CancellationFunnelsReponse } from '../types/CancellationFunnelsTypings';

export const cancellationFunnelsFactory = (
  responses: CancellationFunnelsReponse[]
) => {
  const data = responses.map(response => ({
    id: response.id,
    name: response.name,
    funnelType: response.cancellationFunnelType.description,
  }));

  return data;
};
