/* eslint-disable @typescript-eslint/no-explicit-any */
import './styles/TableComponent.css';

import { Empty, Form, FormInstance, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { FC, Key, useState } from 'react';

import { IBadgeRuleCompetitionEventRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';

export type TableComponentProps = {
  pageSize: number;
  total?: number;
  columns: ColumnsType<any>;
  data: RcTableProps<any>['data'];
  onChangePage?: (item: number) => void;
  onClickRow?: (item?: any, handler?: string) => void;
  style?: React.CSSProperties;
  loading?: boolean;
  showRowSelection?: boolean;
  rowKey?: string;
  formInstance?: FormInstance<IBadgeRuleCompetitionEventRequestDto>;
  rowsSelected?: Key[];
  onSelectedRowChange?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
};

export const TableComponent: FC<TableComponentProps> = ({
  pageSize,
  total,
  columns,
  data,
  formInstance,
  onChangePage,
  onClickRow,
  style,
  loading,
  showRowSelection,
  rowKey,
  rowsSelected = [],
  onSelectedRowChange,
}) => {
  const [listSelectedRowKeys, setListSelectedRowKeys] =
    useState<Key[]>(rowsSelected);
  const onChangeMiddleware = function (pagination: TablePaginationConfig) {
    if (!onChangePage) {
      return null;
    }
    return onChangePage(pagination.current ?? 0);
  };

  const onSelectedRowKeysChange = (
    selectedRowKeys: Key[],
    selectedRows: any[]
  ) => {
    if (onSelectedRowChange) {
      onSelectedRowChange(selectedRowKeys, selectedRows);
    }
    setListSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: listSelectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  return (
    <Form form={formInstance} component={false}>
      <Table
        rowKey={rowKey || 'id'}
        key={Date.now()}
        bordered
        rowSelection={showRowSelection ? rowSelection : undefined}
        loading={loading}
        style={style}
        pagination={{
          pageSize,
          total,
          position: ['bottomCenter'],
          responsive: true,
          hideOnSinglePage: true,
          defaultPageSize: pageSize,
          showSizeChanger: false,
        }}
        columns={columns}
        rowClassName={onClickRow && 'editable-row'}
        dataSource={data}
        onChange={onChangeMiddleware}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Nenhum registro encontrado'}
            />
          ),
        }}
        onRow={record => ({
          onClick: () => {
            if (onClickRow) {
              onClickRow(record);
            }
          },
        })}
      />
    </Form>
  );
};
