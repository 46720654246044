import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { MediaFormData } from '../../types/mediaPageTypings';
import { mediaPayloadFunnelsPageFactory } from '../../utils/mediaFunnelsPageFactory';

interface UpdateMediaFunnelPageRequest {
  id: string;
  payload: MediaFormData;
}

export function useTryToRequestMediaQuestionPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async ({ id, payload }: UpdateMediaFunnelPageRequest) => {
      const factory = await mediaPayloadFunnelsPageFactory(payload);

      return await apiCancellationFunnels.tryToRequestUpdateCancellationFunnelPage(
        id,
        factory
      );
    },
    {
      onSuccess: response => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao editar o funil de Perguntas',
          'Ocorreu um erro ao editar o funil de Perguntas, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestUpdateMediaPage: mutateAsync,
    isLoadingMediaUpdate: isLoading,
  };
}
