/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-template-curly-in-string */

import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from 'yup/lib/locale';

const mixed: Required<MixedLocale> = {
  default: `Inválido`,
  required: `Obrigatório`,
  oneOf: `Deve ser um dos valores: `,
  notOneOf: `Não deve ser um dos valores: `,
  notType: ({ type }: { type: string }) => {
    const types: { [type: string]: string } = {
      date: `data`,
    };

    return `Deve ser do tipo ${types[type] || type}`;
  },
  defined: `Precisar estar definido`,
};

const string: Required<StringLocale> = {
  length: `Deve ter  caracteres`,
  min: `Deve ter ao menos  caracteres`,
  max: `Deve ter no máximo  caracteres`,
  matches: `Formato inválido`,
  email: `Email inválido`,
  url: `URL de formato inválido`,
  uuid: `Dever ser um UUID válido`,
  trim: `Não deve conter espaços no inicio e fim`,
  lowercase: `Deve ser minúscula`,
  uppercase: `Deve ser maiúsculo`,
};

export const number: Required<NumberLocale> = {
  min: `Deve ser maior ou igual a `,
  max: `Deve ser menor ou igual `,
  lessThan: `Deve ser menos que `,
  moreThan: `Deve ser mais que `,
  positive: `Deve ser um número positivo`,
  negative: `Deve ser um número negativo`,
  integer: `Deve ser um inteiro`,
};

export const date: Required<DateLocale> = {
  min: `Deve ser depois de `,
  max: `Deve ser antes de `,
};

export const boolean: BooleanLocale = {
  isValue: `Campo  deve ter o valor `,
};

export const object: Required<ObjectLocale> = {
  noUnknown: ` field cannot have keys not specified in the object shape`,
};

export const array: Required<ArrayLocale> = {
  min: `Deve ter pelo menos itens`,
  max: `Deve ter no máximo ou igual itens`,
  length: `Deve possuir itens`,
};

const yupBrazilianLocale = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};

export default yupBrazilianLocale;
