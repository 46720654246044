import { PlusCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { ReactComponent as Functionality } from '@assets/icons/FunnelType/functionality.svg';
import { ReactComponent as Interrogation } from '@assets/icons/FunnelType/interrogation.svg';
import { ReactComponent as Launch } from '@assets/icons/FunnelType/launch.svg';
import { ReactComponent as Media } from '@assets/icons/FunnelType/media.svg';
import { ReactComponent as Offer } from '@assets/icons/FunnelType/offer.svg';
import { ReactComponent as SpecialistCare } from '@assets/icons/FunnelType/specialist-care.svg';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BackButton } from 'src/app/components/BackButton';

import { ModalFunnelTypes } from '../../Components/ModalFunnelType/index';
import { TableDragSorting } from '../../Components/TableDragSorting';
import { columnsFunnelEdit } from '../../constants/FunnelEditConstants';
import { useTryToRequestCancellationFunnelPublishing } from '../../hooks/useTryToRequestCancellationFunnelPublishing';
import { useTryToRequestFunnelsPages } from '../../hooks/useTryToRequestFunnelsPages';
import { DataType, ParamasProps } from '../../types/FunnelEditTypings';

export const FunnelEditScreen: React.FC = () => {
  const [openModalFunnelType, setOpenModalFunnelType] = useState(false);
  const { id } = useParams<ParamasProps>();
  const [funnelsPagesDataSource, setFunnelsPagesDataSource] = useState<
    DataType[]
  >([]);
  const cardsFunnel = useMemo(
    () => [
      {
        title: 'Pergunta',
        link: `/unsubscribe/funnel/question/${id}`,
        icon: <Interrogation />,
      },
      {
        title: 'Mídia',
        link: `/unsubscribe/funnel/media/${id}`,
        icon: <Media />,
      },
      {
        title: 'Oferta',
        link: `/unsubscribe/funnel/offer/${id}`,
        icon: <Offer />,
      },
      {
        title: 'Funcionalidade',
        link: `/unsubscribe/funnel/functionality/${id}`,
        icon: <Functionality />,
      },
      {
        title: 'Atendimento especialista',
        link: `/unsubscribe/funnel/specialist-care/${id}`,
        icon: <SpecialistCare />,
      },
      {
        title: 'Lançamento',
        link: `/unsubscribe/funnel/release/${id}`,
        icon: <Launch />,
      },
      {
        title: 'Vídeo',
        link: `/unsubscribe/funnel/video/${id}`,
        icon: (
          <VideoCameraOutlined style={{ fontSize: '48px', color: '#ACACAC' }} />
        ),
      },
    ],
    [id]
  );

  const { funnelsPages, isLoading } = useTryToRequestFunnelsPages(id);
  const {
    tryToRequestCancellationFunnelPublishing,
    isLoadingFunnelPublishing,
  } = useTryToRequestCancellationFunnelPublishing();

  const handleCancellationFunnelPublishing = async () => {
    await tryToRequestCancellationFunnelPublishing({
      payload: funnelsPagesDataSource,
    });
  };

  useEffect(() => {
    if (funnelsPages) setFunnelsPagesDataSource(funnelsPages);
  }, [funnelsPages]);

  return (
    <Col
      span={28}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '34px',
      }}
    >
      <BackButton />
      <Row style={{ alignItems: 'center' }}>
        <Col span={12}>
          <Title>Cancelamento - Queima Diária</Title>
        </Col>
        <Col span={12}>
          <Row style={{ justifyContent: 'end' }}>
            <Button
              icon={<PlusCircleOutlined />}
              style={{
                color: '#1668DC',
                borderColor: '#1668DC',
              }}
              onClick={() => setOpenModalFunnelType(true)}
            >
              Adicionar nova página
            </Button>
            <Button
              type='primary'
              style={{ marginLeft: '8px' }}
              onClick={handleCancellationFunnelPublishing}
              loading={isLoadingFunnelPublishing}
            >
              Publicar funil
            </Button>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TableDragSorting
            columns={columnsFunnelEdit}
            dataSource={funnelsPagesDataSource}
            setDataSource={setFunnelsPagesDataSource}
            pagination={false}
            loading={isLoading}
          />
        </Col>
      </Row>
      <ModalFunnelTypes
        openModal={openModalFunnelType}
        setOpenModal={setOpenModalFunnelType}
        cardsFunnel={cardsFunnel}
      />
    </Col>
  );
};

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #dbdbdb;
  line-height: 28px;
`;
