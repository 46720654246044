export const subscriptionPeriodOptions = [
  {
    value: '365',
    label: 'Anual',
    suffix: '/ano',
  },
  {
    value: '180',
    label: 'Semestral',
  },
  {
    value: '90',
    label: 'Trimestral',
  },
  {
    value: '30',
    label: 'Mensal',
  },
];

export const tooltipMessageSubscriptionPeriod = [
  'Anual: 365 dias',
  'Semestral: 180 dias',
  'Trimestral: 90 dias',
  'Mensal: 30 dias',
];
