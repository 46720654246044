import { Button, Modal } from 'antd';
import { FC } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { AdyenCancellationResponse } from 'src/features/clients/types/clientTypes';

type ModalProps = {
  modalValuesCancelation: {
    itemId: number;
    visible: boolean;
  };
  setIsModalVisibleCancelation: () => void;
};

export const ModalCancelation: FC<ModalProps> = ({
  modalValuesCancelation,
  setIsModalVisibleCancelation,
}) => {
  const onSubmit = async () => {
    const response = await clientApi.adyenCancellation(
      modalValuesCancelation.itemId
    );
    if ((response as AdyenCancellationResponse).status === Success) {
      OpenNotification(false, 'Cancelamento efetuado com sucesso!', '');
      return setIsModalVisibleCancelation();
    } else {
      return OpenNotification(
        true,
        'Erro ao efetuar Cancelamento',
        'Ocorreu um erro ao efetuar cancelamento, por favor tente mais tarde!'
      );
    }
  };

  const IsCancelationButton = () => {
    return (
      <>
        <Button
          key='not'
          data-testid='not'
          onClick={() => {
            setIsModalVisibleCancelation();
          }}
        >
          Não
        </Button>
        <Button key='yes' data-testid='yes' type='primary' onClick={onSubmit}>
          Sim
        </Button>
      </>
    );
  };

  const RefundModal = () => {
    return (
      <Modal
        title='Tem certeza que deseja cancelar a renovação automática?'
        visible={modalValuesCancelation.visible}
        onCancel={setIsModalVisibleCancelation}
        footer={[IsCancelationButton()]}
      ></Modal>
    );
  };

  return <>{RefundModal()}</>;
};
