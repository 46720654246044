import { AxiosError } from 'axios';

import { SerializedApiError } from '../typings/global';
import { ServerError } from './statusCode';

export interface BaseErrorResponse {
  statusCode: number;
  name?: string;
  message: string;
  status: number;
  code?: string;
}

export function createSerializedApiError(error: any): SerializedApiError {
  if (isAxiosError(error)) {
    const isNetworkError = !!error.isAxiosError && !error.response;

    if (isNetworkError) {
      return {
        isSerializedApiError: true,
        status: 500,
        name: 'NetworkError',
        code: 'NETWORK_ERROR',
      };
    }

    const response = error.response?.data;

    if (!!response.details?.message)
      return {
        isSerializedApiError: true,
        status: response?.statusCode ?? ServerError,
        name: response?.name,
        message: response?.message || response.details.message.join(', '),
        code: response?.code,
      };

    return {
      isSerializedApiError: true,
      status: response?.statusCode ?? ServerError,
      name: response?.name,
      message: response?.message || JSON.parse(response.details),
      code: response?.code,
    };
  }

  return {
    isSerializedApiError: true,
    status: ServerError,
    message: String(error),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAxiosError(e: any): AxiosError<BaseErrorResponse> {
  return e.isAxiosError;
}
