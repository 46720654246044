import { slices } from '@core/redux';
import { OrderBump } from '@models';
import { orderBump } from '@service/api';
// import { orderTable.orderBump } from '@service/api';
import { PageLimitFilter } from '@typings';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { TOrderBumpResponse } from 'src/models/OrderBump.model';

import OpenNotification from '../components/antd/OpenNotification';
import { TableOrderBumpProps } from '../framework/pages/OrderBumps/Table.orderBump';

export const useOrderBump = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [couponList, setOrderBumpList] = useState<TOrderBumpResponse>();

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getAllOrderBumps = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await orderBump.getAllOrderBumps(props);
      setOrderBumpList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllOrderBumps();
  }, []);

  useBreadcrumbs([
    {
      label: 'Gestão de Order Bump',
    },
  ]);

  const handleChangePagination: TableOrderBumpProps['onChangePagination'] = (
    take,
    _,
    pagination
  ) => {
    getAllOrderBumps({
      ...currentFilter,
      limit: take,
      page: pagination,
    });
  };

  const handleFilterOrderBump = useCallback(
    (filterList: { field: string; value?: string }[]) => {
      const current = {
        ...currentFilter,
        filter: {
          ...currentFilter?.filter,
        },
      };

      filterList.forEach(filterToAdd => {
        if (!filterToAdd.value) {
          delete current.filter?.[filterToAdd.field];
        } else {
          current.filter[filterToAdd.field] = filterToAdd.value;
        }
      });

      getAllOrderBumps(current as PageLimitFilter);
    },
    [getAllOrderBumps, currentFilter]
  );

  const handleOrderOrderBump = useCallback(
    (order: string) => {
      getAllOrderBumps({ ...currentFilter, order });
    },
    [getAllOrderBumps, currentFilter]
  );

  const handleCreateOrderBump = useCallback(() => {
    push('/screens/order-bump/create');
  }, [push]);

  const handleDuplicateOrderBump = useCallback(
    (orderBump: OrderBump.IOrderBump) => {
      push('/screens/order-bump/create', {
        defaultValues: orderBump,
      });
    },
    [push]
  );

  const handleEditOrderBump = useCallback(
    (orderBump: OrderBump.IOrderBump) => {
      push('/screens/order-bump/create', {
        defaultValues: orderBump,
        isEditing: true,
      });
    },
    [push]
  );

  return {
    handleChangePagination,
    couponList,
    handleFilterOrderBump,
    isLoadingData,
    handleCreateOrderBump,
    handleOrderOrderBump,
    handleDuplicateOrderBump,
    handleEditOrderBump,
  };
};
