import { FC } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import BadgesList from '../components/BadgesList';

export const BadgeListScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Social',
      route: '/social/dashboard',
    },
    {
      label: 'Gamefication',
    },
    {
      label: 'Badges',
    },
  ]);
  return (
    <>
      <BadgesList />
    </>
  );
};
