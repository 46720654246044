import { PageHeader } from '@organisms';
import { Col, Divider, Row } from 'antd';

import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';

import { SaveEditDeleteButtons } from '../SaveEditDeleteButtons';

interface Props {
  formData?: IBadgeGroupRequestDto;
  loading?: boolean;
  disabledForm?: boolean;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onDiscardClick?: () => void;
  onDeleteClick?: () => void;
}

export const BadgeGroupDetailsTitle: React.FC<Props> = ({
  formData = undefined,
  loading = false,
  disabledForm = false,
  onEditClick,
  onSaveClick,
  onDiscardClick,
  onDeleteClick,
}) => {
  const titleBadgeGroup = () => {
    return !formData || formData?.isNew ? (
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Cadastrar grupo'}
        subTitle={''}
      />
    ) : (
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Alterar grupo'}
        subTitle={''}
      />
    );
  };

  const handleEditClick = () => {
    if (onEditClick) {
      onEditClick();
    }
  };

  const handleSaveClick = () => {
    if (onSaveClick) {
      onSaveClick();
    }
  };

  const handleDiscardClick = () => {
    if (onDiscardClick) {
      onDiscardClick();
    }
  };

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick();
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {titleBadgeGroup()}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <SaveEditDeleteButtons
            loading={loading}
            showDeleteButton={false}
            showDiscard={disabledForm}
            showEdit={!formData?.isNew && !disabledForm}
            showSave={disabledForm}
            onEditClick={handleEditClick}
            onSaveClick={handleSaveClick}
            onDiscardClick={handleDiscardClick}
            onDeleteClick={handleDeleteClick}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
};
