import { SerializedApiError } from '../typings/global';

/**
 * Type guard para verificar se dado um erro qualquer, ele é um erro serializado pela
 * middleware do createSerializedErroApi
 */
export function isSerializedApiError(e: unknown): e is SerializedApiError {
  const error = e as SerializedApiError;
  return typeof error === 'object' && error.isSerializedApiError === true;
}
