import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { ProgramFavoriteResponseDto } from 'src/features/clients/types/clientTypes';

const fetchProgramFavorite = async (emailParam?: string) => {
  const response = await clientApi.getProgramFavorite(emailParam);
  if (
    !isSerializedApiError(response) &&
    (response as ProgramFavoriteResponseDto).status === Success
  ) {
    return (response as ProgramFavoriteResponseDto).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar os programas preferidos',
      'Ocorreu um erro ao carregar os programas preferidos, por favor tente novamente mais tarde.'
    );
  }
};

export default function useProgramFavorite(email?: string) {
  return useQuery(
    ['programFavorite', email],
    () => fetchProgramFavorite(email),
    {
      refetchOnWindowFocus: true,
    }
  );
}
