import { useCallback } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { SpecialistFormData } from '../../types/specialistPageTypings';
import { useTryToRequestSpecialistFunnelPage } from './useTryToRequestSpecialistFunnelPage';
import { useTryToRequestUpdateSpecialistFunnelPage } from './useTryToRequestUpdateSpecialistFunnelPage';

export function useUpdateScepialistFunnelPage(
  setValue: UseFormSetValue<SpecialistFormData>,
  replace: UseFieldArrayReplace<SpecialistFormData, 'zendeskReasonTags'>
) {
  const { tryToRequestSpecialistFunnelPage } =
    useTryToRequestSpecialistFunnelPage();

  const { tryToRequestUpdateSpecialistFunnelPage, isLoading } =
    useTryToRequestUpdateSpecialistFunnelPage();

  const handleGetOneSpecialistFunnelPage = useCallback(
    async (
      pageId: string,
      setTitlePage: React.Dispatch<React.SetStateAction<string>>
    ) => {
      await tryToRequestSpecialistFunnelPage(pageId).then(response => {
        setValue('cancellationFunnelId', response.cancellationFunnelId);
        setValue('title', response.title);
        setValue('subtitle', response.subtitle);
        setValue('buttonTitle', response.buttonTitle);
        setValue('buttonTitleWhatsApp', response.buttonTitleWhatsApp);
        setValue('buttonTitleZendesk', response.buttonTitleZendesk);
        setValue('email', response.email);
        setValue('whatsApp', response.whatsApp);
        replace(response.zendeskReasonTags);
        setValue('enableEmail', response.enableEmail);
        setValue('enableWhatsapp', response.enableWhatsapp);
        setValue('enableZendesk', response.enableZendesk);
        setValue('interaction', response.interaction);
        setValue(
          'continueCancelationButtonTitle',
          response.continueCancelationButtonTitle
        );
        setValue(
          'keepSubscriptionButtonTitle',
          response.keepSubscriptionButtonTitle
        );
        setTitlePage(response.titlePage);
      });
    },
    []
  );

  const handleUpdateSpecialistFunnelPage = useCallback(
    async (e: SpecialistFormData, pageId: string) => {
      await tryToRequestUpdateSpecialistFunnelPage({ id: pageId, payload: e });
    },
    []
  );

  return {
    handleGetOneSpecialistFunnelPage,
    handleUpdateSpecialistFunnelPage,
    isLoadingSpecialistPageFunnelUpdate: isLoading,
  };
}
