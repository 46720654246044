import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

import * as S from './Header.style';

interface ModalHeaderProps {
  title: string;
  onCrossClick?: () => void;
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  onCrossClick,
}) => {
  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      <S.CloseButtonContainer onClick={onCrossClick}>
        <CloseOutlined />
      </S.CloseButtonContainer>
    </S.Header>
  );
};
