import { Button, Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';

import { convertArrayOfFlatObjectsToCSV } from 'src/utils/array.util';

import OpenNotification from '../../../../../src/app/components/antd/OpenNotification';
import { downloadCsvFile } from '../../../../utils/file.util';
import { subscriptionApi } from '../client.api';
import { DEFAULT_RULES, PLATFORMS, STATUSES } from '../constants';
import { IDateRange } from '../dto/date-range.interface';
import { ScheduledTaskResponseDto } from '../dto/scheduledTaskResponse.dto';
import { _generateCsvPayload, _notifyError } from '../utils';

type IModalExportReport = {
  selectedDateRange: undefined | IDateRange;
  setModalVisibility: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
};

export const ScheduledReportsModal: FC<IModalExportReport> = ({
  selectedDateRange,
  setModalVisibility,
  isVisible,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setModalVisibility(false);
  };

  const handleFinish = async () => {
    const { from, to, ...rest } = form.getFieldsValue();

    if (from && to) {
      const client = subscriptionApi.scheduledCancellationReport;
      const response = await client({ from, to, ...rest });

      if (!response?.data?.length) {
        return _notifyError('Nenhum registro encontrado');
      }

      OpenNotification(
        false,
        'Seu relatório está à caminho',
        'O download vai iniciar em instantes'
      );

      _generateAndDownloadCsv(response.data);
      setModalVisibility(false);
    }
  };

  const _generateAndDownloadCsv = (data: ScheduledTaskResponseDto[]): void => {
    const raw = _generateCsvPayload(data);
    const payload = convertArrayOfFlatObjectsToCSV(raw);

    const filename_sulfix = new Date().toISOString().toString();
    const filename = `agendamentos_de_cancelamento_${filename_sulfix}`;
    downloadCsvFile(payload, filename);
  };

  const MODAL_BUTTONS = [
    <Button key='back' data-testid='back' onClick={handleCancel}>
      Cancelar
    </Button>,
    <Button
      key='generate'
      onClick={form.submit}
      data-testid='submit'
      type='primary'
    >
      Gerar
    </Button>,
  ];

  return (
    <Modal
      title='Gerar Relatório'
      data-testid='modal'
      onCancel={handleCancel}
      footer={MODAL_BUTTONS}
      visible={isVisible}
      width={640}
    >
      <Form
        form={form}
        layout='vertical'
        data-testid='exportReportForm'
        onFinish={handleFinish}
        initialValues={selectedDateRange}
      >
        <Row gutter={12}>
          <Col flex={1}>
            <Form.Item name='from' label='Mostrar de' rules={DEFAULT_RULES}>
              <DatePicker
                allowClear
                format='DD/MM/YYYY'
                placeholder='Selecione'
                style={{ width: '100%' }}
                data-testid='from'
              />
            </Form.Item>
          </Col>

          <Col flex={1}>
            <Form.Item name='to' label='até' rules={DEFAULT_RULES}>
              <DatePicker
                allowClear
                format='DD/MM/YYYY'
                placeholder='Selecione'
                style={{ width: '100%' }}
                data-testid='to'
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col flex={1}>
            <Form.Item name='provider' label='Plataforma'>
              <Select
                showArrow
                allowClear
                mode='multiple'
                placeholder='Selecione as plataformas'
                style={{ width: '100%' }}
                data-testid='provider'
              >
                {PLATFORMS.map(item => (
                  <Select.Option key={`platform-${item.key}`} value={item.key}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col flex={1}>
            <Form.Item name='status' label='Status'>
              <Select
                showArrow
                allowClear
                mode='multiple'
                placeholder='Selecione os status'
                style={{ width: '100%' }}
                data-testid='status'
              >
                {STATUSES.map(item => (
                  <Select.Option key={`status-${item.key}`} value={item.key}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
