import { gameficationApi } from 'src/app/gameficationApi';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';

import { EventResponseDto } from '../dto/event-response.dto';
export interface ListEventsResponse {
  id: number;
  description: string;
  identifier: string;
  name: string;
  status?: string;
  eventType: EventTypeId;
  eventTypeId: number;
  amountCompetition: number;
}
export interface EventType {
  key?: React.Key;
  id?: number;
  name?: string;
  status?: string;
  identifier?: string;
  description?: string;
  eventTypeId: number;
}

export interface EventCreated {
  statusCode: number;
}

export interface EventTypeId {
  id: number;
}

export async function listEvents(): Promise<
  DataResponseApiDto<EventResponseDto[]>
> {
  const url = `/event`;
  const response = await gameficationApi.get<
    DataResponseApiDto<EventResponseDto[]>
  >(url);
  return response.data;
}

export async function getEventById(id: string): Promise<ListEventsResponse> {
  const url = `/event/` + id;
  const { data } = await gameficationApi.get(url);
  return data.data;
}

export async function create(param: EventType): Promise<EventCreated> {
  const url = `/event`;
  return gameficationApi.post(url, param);
}

export async function deleteEvent(param: EventType): Promise<EventCreated> {
  const url = `/event/` + param.identifier;
  return gameficationApi.delete(url);
}

export async function update(param: EventType): Promise<EventCreated> {
  const url = `/event/` + param.identifier;
  return gameficationApi.put(url, {
    name: param.name,
    description: param.description,
  });
}
