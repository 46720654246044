import { Events } from '@models';
import { EventDetailForm } from '@organisms';
import { Col, Row } from 'antd';
import React from 'react';

import { DeleteButtonComponent } from 'src/features/clients/components/DeleteButtonComponent';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';

interface EventDetailTemplateProps {
  formData: Events.EventEditingForm;
  enableForm: boolean;
  handleChange: (name: string, value: any) => void;
  handleDeleteEvent: () => void;
  handleEditEvent: () => void;
  handleDiscardEdition: () => void;
  handleSaveEdition: () => void;
}
export const EventDetailTemplate: React.FC<EventDetailTemplateProps> = ({
  formData,
  enableForm,
  handleDeleteEvent,
  handleDiscardEdition,
  handleEditEvent,
  handleSaveEdition,
  handleChange,
}) => {
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <label>Eventos</label>
          </Col>

          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <EditButtonComponent
              showEdit={enableForm}
              showSave={!enableForm}
              showDiscard={!enableForm}
              onSaveClick={handleSaveEdition}
              onDiscardClick={() => handleDiscardEdition()}
              onEditClick={() => handleEditEvent()}
            />

            <DeleteButtonComponent handleDeleteButton={handleDeleteEvent} />
          </Col>
        </Row>

        <EventDetailForm
          enableForm={enableForm}
          formData={formData}
          handleChange={handleChange}
        />
      </Col>
    </Row>
  );
};
