import { useMutation } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { specialistFunnelsPagesResponseFactory } from '../../utils/specialistFunnelsPagesFactory';

export function useTryToRequestSpecialistFunnelPage() {
  const { mutateAsync } = useMutation(
    async (id: string) => {
      const response =
        await apiCancellationFunnels.tryToRequestGetOneCancellationFunnelPage(
          id
        );
      const data = specialistFunnelsPagesResponseFactory(response.data);
      return data;
    },
    {
      onError: () => {
        OpenNotification(
          true,
          'Erro ao solicitar dados do Atendimento Especialista',
          'Ocorreu um erro ao solicitar dados do Atendimento Especialista, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return { tryToRequestSpecialistFunnelPage: mutateAsync };
}
