import { Assets, Customer, Programs } from '@models';

export enum TypeEnum {
  OPERATION = 'OPERATION',
  BUSINESS = 'BUSINESS',
  BEHAVIOUR = 'BEHAVIOUR',
}

export interface ShortSegmentDto {
  id?: number;
  name: string;
  description: string;
  type: TypeEnum;
  activated?: boolean;
}

export interface SegmentsOrdering {
  idOrder: number;
  order: number;
}

export interface SegmentsDto {
  id?: string;
  name: string;
  description: string;
  segments_data: string;
  country: string;
  created_at: string;
  updated_at: string;
  dns: string;
  language: string;
  show_quiz: boolean;
  show_recommended_programs: boolean;
  activated?: boolean;
  type: string;
  orderingForBanners?: SegmentsOrdering[];
}

export interface SegmentsWithCounts extends ShortSegmentDto {
  countBanners: number;
  countCustomers: number;
  countPrograms: number;
}

export interface SegmentsFilter {
  name?: { iLike: string };
  activated?: boolean;
}

export interface LinkedSegmentsProps {
  assets: Assets.IAsset[];
  programs: Programs.ProgramsResponse[];
  customers: Customer.CustomerResponse[];
}

export enum LinkedSegmentsEnum {
  ASSETS = 'ASSETS',
  CUSTOMERS = 'CUSTOMERS',
  PROGRAMS = 'PROGRAMS',
}
