import moment from 'moment';

import OpenNotification from '../../../app/components/antd/OpenNotification';
import { dateFormatted } from '../../../app/helpers/DateHelper';
import { IClientPayload } from './dto/client-api.dto';
import { ICsvRawPayload } from './dto/csv-raw-payload.dto';
import { ScheduledTaskResponseDto } from './dto/scheduledTaskResponse.dto';

export const _notifyError = (message: string) => {
  const detail = 'Verifique os filtros e tente novamente';
  OpenNotification(true, message, detail);
};

export const _generateCsvPayload = (arr: ScheduledTaskResponseDto[]) => {
  return arr.reduce((acc: ICsvRawPayload[], row: ScheduledTaskResponseDto) => {
    const newItem: ICsvRawPayload = {
      status: `${row.status.name} - ${row.status.description}`,
      tipo: row.type.name,
      plataforma: row.paymentProvider,
      agendado_para: dateFormatted(String(row.dateScheduled), true),
      solicitado_em: dateFormatted(String(row.createdAt), true),
      cliente_id: row.data.idCustomer,
      cliente_nome: row.data.name,
      cliente_email: row.data.email,
      assinatura_id: row.data.idSubscription,
      assinatura_nome: row.data.subscriptionName,
      colaborador: `${row.loggedUser.fullName} - ${row.loggedUser.email}`,
    };
    return [...acc, newItem];
  }, []);
};

export const _mountURI = (args: IClientPayload): string => {
  let URI = `/scheduled/task`;
  URI = `${URI}?firstDate=${args.from}&lastDate=${args.to}&type=1`;

  if (args?.status && args?.status.length) {
    args.status.forEach(s => (URI = `${URI}&status[]=${s}`));
  }

  if (args?.provider && args?.provider.length) {
    args?.provider.forEach(p => (URI = `${URI}&provider[]=${p}`));
  }

  return URI;
};

export const _checkForInvalidDateRange = ({ from, to }: IClientPayload) => {
  const hasDateRange = from && to;

  if (hasDateRange && moment(to).isBefore(from)) {
    _notifyError('Data final não pode ser anterior à data inicial');
    return Promise.reject();
  }
};
