import { useMutation } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { mediaResponseFactory } from '../../utils/mediaFunnelsPageFactory';

export function useTryToRequestMediaPage() {
  const { mutateAsync } = useMutation(
    async (id: string) => {
      const response =
        await apiCancellationFunnels.tryToRequestGetOneCancellationFunnelPage(
          id
        );

      const factory = mediaResponseFactory(response.data);

      return factory;
    },
    {
      onError: () => {
        OpenNotification(
          true,
          'Erro ao solicitar dados de Lançamento',
          'Ocorreu um erro ao solicitar dados de Lançamento, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestMediaPage: mutateAsync,
  };
}
