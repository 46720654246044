import { PageHeader } from '@organisms';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

import { CouponPhysic } from 'src/models';

import { PhysicCouponForm } from '../components/CouponForm/PhysicCouponForm.component';
import { useProductPhysicCoupon } from '../hooks/useProductPhysicCoupon';

export function ReadCouponPhysicPage() {
  const { state }: { state: { coupon: CouponPhysic.ICoupon } } = useLocation();
  const couponHookData = useProductPhysicCoupon();

  return (
    <Spin spinning={couponHookData.isLoading}>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={`Visualização do Cupom ${state.coupon.code}`}
        subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
      />

      <PhysicCouponForm
        couponHookData={couponHookData}
        coupon={state.coupon}
        readonly
      />
    </Spin>
  );
}
