import { Form, Input, TooltipProps } from 'antd';
import React, { ComponentProps, ReactNode } from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';
const { Item: FormItem } = Form;

interface ControllerProps extends ComponentProps<typeof Input> {
  control?: Control<any>;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  autoComplete?: string;
  onClick?: () => void;
  prefix?: React.ReactNode;
  type?: string;
  label?: string;
  required?: boolean;
  maxLength?: number;
  addonAfter?: React.ReactNode;
  disabled?: boolean;
  showCount?: boolean;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
}

export const Controller: React.FC<ControllerProps> = ({
  control,
  defaultValue,
  onClick,
  name,
  placeholder,
  autoComplete,
  prefix,
  type,
  label,
  required,
  maxLength,
  addonAfter,
  disabled,
  showCount,
  tooltip,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : undefined}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        return (
          <FormItem
            required={required}
            validateStatus={status}
            help={help}
            label={label}
            {...(maxLength &&
              !showCount && { extra: `(${maxLength} caracteres no máximo)` })}
            {...(tooltip && { tooltip: tooltip })}
          >
            <Input
              {...rest}
              {...field}
              name={name}
              placeholder={placeholder}
              data-testid={name}
              type={type}
              autoComplete={autoComplete}
              prefix={prefix}
              addonAfter={addonAfter}
              disabled={disabled}
              {...(maxLength && { maxLength: maxLength })}
              {...(showCount && { showCount })}
            />
          </FormItem>
        );
      }}
    />
  );
};
