// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

async function deleteModalById(modalId: string): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/modal/${modalId}`,
    method: 'DELETE',
  });

  if (data.affected < 1) {
    throw Error(data.message);
  }
}

export default deleteModalById;
