import styled from 'styled-components';

export const Container = styled.div<{
  overflow?: 'auto' | 'hidden' | 'overlay';
}>`
  .ant-tabs-content-holder {
    overflow: ${({ overflow }) => overflow || 'inherit'};
  }
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(255, 255, 255, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalContainer = styled.div<{ width?: number }>`
  width: 100%;
  max-width: ${({ width }) => (!width ? '772px' : `${width}px`)};

  background-color: #141414;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.48), 0px 6px 16px rgba(0, 0, 0, 0.32),
    0px 9px 28px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  position: absolute;
  top: 6%;
`;
