import { Title } from '@atoms';
import { Modal } from '@models';
import { ModalModel } from '@molecules';
import { SegmentsSelect } from '@organisms';

import {
  ModalModelProperties,
  ModelStepBaseComponentProp,
} from '../ModalDetail';
import * as S from './ModelStep.style';

export const ModelStepComponent = ({
  form,
}: ModelStepBaseComponentProp<ModalModelProperties>) => {
  const handleModalModelChange = (modalType: Modal.TypeEnum) => {
    form.clearErrors();
    form.setValue('modalType', modalType, { shouldValidate: true });
  };

  return (
    <>
      <Title title='Selecione o Segmento' />

      <SegmentsSelect
        dataTake={99999}
        control={form.control}
        data-testid={'model-modal-segment-select'}
        optionFilterProp='label'
        placeholder={'Selecione o Segmento'}
        allowClear
        name='segment'
      />

      <Title title='Modelo do Modal:' />

      <S.ModalTypeContainer>
        <ModalModel.Default
          label='Padrão'
          selected={form.getValues('modalType') === Modal.TypeEnum.DEFAULT}
          onClick={handleModalModelChange}
        />
        <ModalModel.Highlight
          label='Destaque'
          selected={form.getValues('modalType') === Modal.TypeEnum.HIGHLIGHT}
          onClick={handleModalModelChange}
        />
        <ModalModel.Pagination
          label='Paginação'
          selected={form.getValues('modalType') === Modal.TypeEnum.PAGINATION}
          onClick={handleModalModelChange}
        />
        <ModalModel.Launch
          label='Lançamentos'
          selected={form.getValues('modalType') === Modal.TypeEnum.LAUNCH}
          onClick={handleModalModelChange}
          disabled={true}
        />
      </S.ModalTypeContainer>

      {form.formState?.errors?.modalType?.message && (
        <p style={{ color: '#a61d24' }}>
          {form.formState?.errors?.modalType?.message}
        </p>
      )}
    </>
  );
};
