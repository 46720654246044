/* eslint-disable @typescript-eslint/no-empty-function */
import { OrderBump } from '@models';
import { ActionsDropdown } from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { format, parseISO } from 'date-fns';

import {
  getLabelStatus,
  getLabelTemplate,
  TOrderBumpResponse,
} from 'src/models/OrderBump.model';

export interface TableOrderBumpProps {
  filterComponent: React.ReactNode;
  data?: TOrderBumpResponse;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditOrderBump?: (value: OrderBump.IOrderBump) => void;
  onChangeOrder?: (order: string) => void;
  onDuplicateOption?: (order: OrderBump.IOrderBump) => void;
  isLoading: boolean;
}

export type TColumnOrder = {
  column: string;
  inverse: boolean;
};

export const TableOrderBump: React.FC<TableOrderBumpProps> = ({
  filterComponent,
  data,
  onChangePagination,
  isLoading,
  onDuplicateOption,
  onEditOrderBump,
}) => {
  const headerColumns: PageListProps<OrderBump.IOrderBump>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '80px',
      render: (id: OrderBump.IOrderBump['id']) => {
        return String(id);
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (name: OrderBump.IOrderBump['name']) => {
        return name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '100px',
      render: (isActive: OrderBump.IOrderBump['isActive']) => {
        return getLabelStatus(isActive);
      },
    },
    {
      title: 'ID do Produto Destino',
      dataIndex: 'destinationOffer',
      key: 'destinationOffer',
      width: '100px',
      render: (destinationOffer: OrderBump.IOrderBump['destinationOffer']) => {
        return destinationOffer.productSellGroupId;
      },
    },
    {
      title: 'ID da Oferta Destino',
      dataIndex: 'destinationOfferId',
      key: 'destinationOfferId',
      width: '100px',
      render: (
        destinationOfferId: OrderBump.IOrderBump['destinationOfferId']
      ) => {
        return destinationOfferId;
      },
    },
    {
      title: 'Qtd. Checkouts\nExib.',
      dataIndex: 'offers',
      key: 'offers',
      width: '100px',
      render: (offers: OrderBump.IOrderBump['offers']) => {
        return offers.length;
      },
    },
    {
      title: 'Template',
      dataIndex: 'template',
      key: 'template',
      width: '150px',
      render: (template: OrderBump.IOrderBump['template']) => {
        return getLabelTemplate(template);
      },
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '156px',
      render: (createdAt: OrderBump.IOrderBump['createdAt']) => {
        return <div>{format(parseISO(createdAt), 'dd/MM/yyyy')}</div>;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text, record) => (
        <ActionsDropdown
          onClickEditOption={
            !!onEditOrderBump ? () => onEditOrderBump(record) : undefined
          }
          onClickDuplicateOption={
            !!onDuplicateOption ? () => onDuplicateOption(record) : undefined
          }
        />
      ),
    },
  ];

  if (!data && isLoading) return null;

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={isLoading}
      onChange={onChangePagination}
    />
  );
};
