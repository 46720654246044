import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div``;

export const TitleContainer = styled.div``;
