import { Row, Switch, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';

import { TableDropdown } from '../Components/TableDropdown';
import { DataType } from '../types/FunnelEditTypings';

export const columnsFunnelEdit: ColumnsType<DataType> = [
  {
    key: 'sort',
    colSpan: 0,
    width: '48px',
    align: 'center',
  },
  {
    title: 'Order',
    colSpan: 0,
    render: (_, _data, index) => index + 1,
    width: '48px',
    align: 'center',
  },
  {
    title: 'Nome da página',
    colSpan: 3,
    dataIndex: 'name',
    align: 'left',
    render: (_, data, index) => {
      return (
        <>
          {data.name}{' '}
          {index === 0 && (
            <Tag
              color='blue'
              style={{ marginLeft: '16px', borderRadius: '4px' }}
            >
              Página Inicial
            </Tag>
          )}
        </>
      );
    },
  },
  {
    title: 'Tipo de página',
    dataIndex: 'pageType',
    width: '199px',
  },
  {
    title: 'Status',
    width: '218px',
    align: 'center',
    render: (_, index) => {
      return (
        <Row style={{ justifyContent: 'center' }}>
          <Switch
            defaultChecked={index.status === 'active' ? true : false}
            onChange={(checked: boolean) =>
              checked ? (index.status = 'active') : (index.status = 'inactive')
            }
            style={{ marginRight: '8px' }}
          />
          <TextBody style={{ height: '16px' }}>
            {index.status === 'active' ? 'Ativo' : 'Inativo'}
          </TextBody>
        </Row>
      );
    },
  },
  {
    title: 'Ação',
    width: '103px',
    align: 'center',
    render: item => <TableDropdown item={item} />,
  },
];

const TextBody = styled.p`
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
