import { PlusCircleOutlined } from '@ant-design/icons';
import { useDebounce } from '@hooks';
import { FilterHeader } from '@organisms';
import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { CouponPhysic } from 'src/models';

interface FilterCouponPhysicProps {
  onAddCoupon: () => void;
  onFilterCoupon: (handle: { field: string; value?: string }[]) => void;
}

export const FilterCouponsPhysic: React.FC<FilterCouponPhysicProps> = ({
  onAddCoupon,
  onFilterCoupon,
}) => {
  const [statusFilter, setStatusFilter] = useState<string>();
  const [filterByCode, setFilterByCode] = useState<string | null>(null);

  const debounceValue = useDebounce(filterByCode ?? '', 900);

  useEffect(() => {
    if (
      filterByCode !== null &&
      (filterByCode.length > 2 || filterByCode.length === 0)
    ) {
      onFilterCoupon([
        {
          field: 'filter',
          value: debounceValue,
        },
      ]);
    }
  }, [debounceValue]);

  return (
    <FilterHeader
      buttonList={[
        <Input.Search
          key={`filter-coupon-code-search`}
          placeholder={'Buscar por código do cupom. (Mínimo 3 letras)'}
          value={filterByCode ?? ''}
          onChange={evt => setFilterByCode(evt.target.value)}
          style={{ width: '560px' }}
          allowClear
        />,
        <Select
          placeholder={'Status'}
          data-testid={'asset-data-filter'}
          onChange={value => {
            setStatusFilter(value);
            if (value === undefined) {
              onFilterCoupon([{ field: 'status', value: undefined }]);
              return;
            }
            onFilterCoupon([{ field: 'status', value }]);
          }}
          style={{ width: '360px' }}
          value={statusFilter}
          allowClear
        >
          <Select.Option value={CouponPhysic.StatusEnum.ACTIVE}>
            {CouponPhysic.getLabelStatus(CouponPhysic.StatusEnum.ACTIVE)}
          </Select.Option>
          <Select.Option value={CouponPhysic.StatusEnum.FINISH}>
            {CouponPhysic.getLabelStatus(CouponPhysic.StatusEnum.FINISH)}
          </Select.Option>
          <Select.Option value={CouponPhysic.StatusEnum.SCHEDULE}>
            {CouponPhysic.getLabelStatus(CouponPhysic.StatusEnum.SCHEDULE)}
          </Select.Option>
        </Select>,
      ]}
      customFilter={[
        <Button
          key={'filter-header-button-add-assets'}
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={onAddCoupon}
        >
          Criar desconto
        </Button>,
      ]}
    />
  );
};
