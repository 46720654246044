import { InputNumber } from 'antd';
import { FC } from 'react';

interface OnChangeHandler {
  (e: unknown): void;
}
interface Props {
  value?: number;
  disabled?: boolean;
  name?: string;
  onChange?: OnChangeHandler;
}

export const BadgeIntervaloTableCell: FC<Props> = ({
  value,
  disabled,
  onChange,
  name = 'intervalOccurrenceMinutes',
}) => {
  if (!value && disabled) {
    return <>Não configurado</>;
  }
  const valueOfIntervalOccurrenceMinutes = (value?: number): string => {
    const defaultResponse = 'Não definido';
    return typeof value === 'undefined'
      ? defaultResponse
      : +(value as number) !== 0
      ? `${value} min`
      : defaultResponse;
  };

  if (!disabled) {
    return (
      <InputNumber
        min={0}
        defaultValue={undefined}
        name={name}
        style={{ width: '100%' }}
        value={value || 0}
        onChange={onChange}
      />
    );
  }

  return <p>{valueOfIntervalOccurrenceMinutes(value)}</p>;
};
