import { useCallback, useEffect } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { FunctionalityFormData } from '../../types/functionalityTypings';
import { useTryToRequestFunctionalityPage } from './useTryToRequestFunctionalityPage';
import { useTryToRequestUpdateFunctionalityPage } from './useTryToRequestUpdateFunctionalityPage';

interface useRequestFunctionalityPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<Omit<FunctionalityFormData, 'name'>>;
  replace: UseFieldArrayReplace<Omit<FunctionalityFormData, 'name'>, 'slides'>;
}

export function useRequestFunctionalityPage({
  titlePage,
  setTitlePage,
  isUpdate,
  setIsUpdate,
  setValue,
  replace,
}: useRequestFunctionalityPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestFunctionality } = useTryToRequestFunctionalityPage();
  const { isLoadingFunctionalityUpdate, tryToRequestUpdateFunctionalityPage } =
    useTryToRequestUpdateFunctionalityPage();

  const handleUpdateFunctionalityPage = useCallback(
    async (e: Omit<FunctionalityFormData, 'name'>) => {
      await tryToRequestUpdateFunctionalityPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [titlePage, tryToRequestUpdateFunctionalityPage, pageId]
  );

  const handleRequestFunctionalityPage = useCallback(async () => {
    await tryToRequestFunctionality(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      const slidesFields: any[] = [];
      response.slides.forEach((slide: any) => {
        slidesFields.push({
          ...slide,
          enableCta: slide.buttonLink && slide.buttonTitle,
        });
      });
      replace(slidesFields);
      setTitlePage(response.name);
    });
  }, [tryToRequestFunctionality, setValue, setTitlePage]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestFunctionalityPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { isLoadingFunctionalityUpdate, handleUpdateFunctionalityPage };
}
