export const AddPhotoSvg = () => (
  <svg
    width='41'
    height='40'
    viewBox='0 0 41 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_4369_15611'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='41'
      height='40'
    >
      <rect x='0.5' width='40' height='40' fill='white' />
    </mask>
    <g mask='url(#mask0_4369_15611)'>
      <path
        d='M8.27775 35C7.51386 35 6.85993 34.728 6.31596 34.184C5.77199 33.6401 5.5 32.9861 5.5 32.2222V7.77775C5.5 7.01386 5.77199 6.35993 6.31596 5.81596C6.85993 5.27199 7.51386 5 8.27775 5H24.6389V7.77775H8.27775V32.2222H32.7222V15.8889H35.5V32.2222C35.5 32.9861 35.228 33.6401 34.684 34.184C34.1401 34.728 33.4861 35 32.7222 35H8.27775ZM29.3889 14.5V11.1389H26.0278V8.36112H29.3889V5H32.1667V8.36112H35.5278V11.1389H32.1667V14.5H29.3889ZM10.5 28.2778H30.5L24.4167 20.1667L19.2222 27.0278L15.3611 21.9167L10.5 28.2778Z'
        fill='white'
      />
    </g>
  </svg>
);
