import { CloseOutlined } from '@ant-design/icons';
import { FlameIcon } from '@assets/icons/flame';
import NewLoginImage from '@assets/images/novo-login.png';
import { EmptyButton } from '@atoms';
import { Modal } from '@models';
import { VideoPlayer } from '@organisms';

import { env } from 'src/app/env';

import * as T from './common.styles';
import * as S from './Highlight.style';

interface HighlightProps {
  onClose: () => void;
  modalInfo?: Modal.IModal;
}

const DEFAULT_TITLE = 'Novo Login';
const DEFAULT_DESCRIPTION =
  'Estamos lançando o novo Login, uma experiência de acesso totalmente aprimorada.';
const DEFAULT_BUTTON_LABEL = 'Treinar Agora';

export const Highlight = ({ onClose, modalInfo }: HighlightProps) => {
  const imageSrc = !!modalInfo?.advertisements[0].webImageFileId
    ? `${env.FILE_API}/files/files/view/${modalInfo?.advertisements[0].webImageFileId}`
    : NewLoginImage;

  return (
    <T.Container>
      <T.Header>
        <FlameIcon />
        <EmptyButton onClick={() => onClose()}>
          <CloseOutlined style={{ color: '#000', fontSize: '20px' }} />
        </EmptyButton>
      </T.Header>

      <S.Body>
        <T.Content>
          {!modalInfo?.advertisements[0].videoLink && (
            <S.CustomImage preview={false} src={imageSrc} />
          )}

          {!!modalInfo?.advertisements[0].videoLink && (
            <VideoPlayer url={modalInfo?.advertisements[0].videoLink} />
          )}

          <S.Title>
            {modalInfo?.advertisements[0].title || DEFAULT_TITLE}
          </S.Title>

          <S.Description>
            {modalInfo?.advertisements[0].description || DEFAULT_DESCRIPTION}
          </S.Description>
        </T.Content>

        <T.Footer>
          <T.CustomCheckbox onChange={e => () => null}>
            Não mostrar este conteúdo novamente.
          </T.CustomCheckbox>

          {!!modalInfo && !!modalInfo.advertisements[0].buttonLabel && (
            <T.CustomButton>
              {modalInfo?.advertisements[0].buttonLabel}
            </T.CustomButton>
          )}

          {!modalInfo && (
            <T.CustomButton>{DEFAULT_BUTTON_LABEL}</T.CustomButton>
          )}
        </T.Footer>
      </S.Body>
    </T.Container>
  );
};
