import { gameficationApi } from 'src/app/gameficationApi';

const BASE = 'competition/activities';

const list = async (id: number) => {
  const response = await gameficationApi.get(`${BASE}/${id}`);
  return response.data;
};

// eslint-disable-next-line
const upsert = async (data: any) => {
  const response = await gameficationApi.post(BASE, data);
  return response.data;
};

const remove = async (ids: number[]) => {
  const opts = { data: { ids } };
  const response = await gameficationApi.delete(BASE, opts);
  return response.data;
};

const listPrograms = async () => {
  const response = await gameficationApi.get(`${BASE}/programs`);
  return response.data;
};

const listClasses = async (id: number) => {
  const response = await gameficationApi.get(`${BASE}/classes/${id}`);
  return response.data;
};

export const gameficationCompetitionActivities = {
  list,
  listPrograms,
  listClasses,
  upsert,
  remove,
};
