import styled from 'styled-components';

export const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: inherit;
`;
