import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { ProgramAccessLastResponseDto } from 'src/features/clients/types/clientTypes';

const fetchProgramAccessLast = async (emailParam?: string) => {
  const response = await clientApi.getProgramAccessLast(emailParam);
  if (
    !isSerializedApiError(response) &&
    (response as ProgramAccessLastResponseDto).status === Success
  ) {
    return (response as ProgramAccessLastResponseDto).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar os últimos acessos na plataforma',
      'Ocorreu um erro ao últimos acessos na plataforma, por favor tente novamente mais tarde.'
    );
  }
};

export default function useProgramAccessLast(email?: string) {
  return useQuery(
    ['programAccessLast', email],
    () => fetchProgramAccessLast(email),
    {
      refetchOnWindowFocus: false,
    }
  );
}
