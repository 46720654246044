import axios from 'axios';

import { env } from 'src/app/env';
import { RemoteCheckoutConfig } from 'src/models/remoteCheckoutConfig.model';

export async function getRemoteCheckoutConfigFromS3(): Promise<RemoteCheckoutConfig> {
  try {
    const { data } = await axios({
      baseURL: `${env.ASSETS}checkout/checkout-config.json`,
      method: 'GET',
      withCredentials: false,
    });
    return data as RemoteCheckoutConfig;
  } catch (err) {
    throw err;
  }
}
