import { enviroments } from '@infrastructure/enviroments';
import axios from 'axios';
import qs from 'qs';

import { addAuthorizationHeaderInterceptor } from 'src/features/auth/utils/addAuthorizationHeaderInterceptor';

import { normalizeErrorsInterceptor } from '../interceptors/normalizeErrorsInterceptor';

export const base = axios.create({
  baseURL: enviroments.BASE_URL,
  paramsSerializer: params => qs.stringify(params, { encode: true }),
  headers: {
    'Content-Type': 'application/json',
  },
});

base.interceptors?.request.use(addAuthorizationHeaderInterceptor);
base.interceptors?.response.use(undefined, normalizeErrorsInterceptor);
