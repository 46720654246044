import { PageHeader } from '@organisms';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { ProductTable } from '../../components/productExibitons/ProductTable';

export const ProductExibitons: React.FC = () => {
  useBreadcrumbs([
    {
      label: 'Produtos',
    },
  ]);

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gerenciamento de Ofertas para Checkout'}
        subTitle={'Gerencie, edite e crie novas Ofertas.'}
      />

      <ProductTable />
    </>
  );
};
