import { Button, Col, Divider, Row } from 'antd';
import styled from 'styled-components';

export const GridStyled = styled.div`
  display: inline-grid;
`;

export const ButtonLibertAcess = styled(Button)`
  top: 26px;
  @media (max-width: 992px) {
    top: 5px;
  }
`;

export const ButtonInputRevogarAcesso = styled(Button)`
  float: right;
  margin-top: 21px;
  margin-left: 10px;
  @media (max-width: 992px) {
    margin-left: 0px;
    float: left;
    margin-top: 12px;
  }
`;

export const ButtonInput = styled(Button)`
  float: right;
  margin-top: 21px;
  margin-left: 10px;
  @media (max-width: 992px) {
    float: left;

    margin-top: 20px;
    margin-left: 0px;
  }
`;
export const TextUserInfo = styled.div`
  margin-left: 10px;
  margin-top: 3px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #7d7d7d;
`;
export const DividerStyledResponsive = styled(Divider)`
  border-top: none;
  @media (min-width: 992px) {
    display: none;
  }
`;
export const DividerStyled = styled(Divider)`
  border-top: none;
`;

export const RowStyled = styled(Row)`
  margin-top: 10px;
  @media (min-width: 992px) {
    margin-top: 30px;
  }
`;
export const ColStyled = styled(Col)`
  margin-top: 10px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
`;
