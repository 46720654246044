import styled from 'styled-components';

export const Container = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const LabelContainer = styled.div<{ selected?: boolean }>`
  padding: 8px;
  font-size: 16px;
  color: ${({ selected }) => (selected ? '#1668DC' : '#DBDBDB')};
  text-align: center;
`;

export const ModalModelContainer = styled.div<{ selected?: boolean }>`
  height: 227.65px;
  width: 281.46px;

  border: ${({ selected }) =>
    selected ? '2px solid #1668DC' : '2px solid #434343'};
  border-radius: 8.29px;
  background-color: #262626;
  position: relative;

  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ModalModelSpace = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalModelIconContainer = styled.div`
  position: absolute;
  right: 14px;
  top: 12px;

  & > button > span:hover {
    font-size: 24px !important;
  }
`;
