import { UpsellFlow, UpsellStepsFlow } from '@models';
import { updateUpsellStepFlow } from '@service/api/upsellStepFlow/updateUpsellStepFlow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { XYPosition } from 'react-flow-renderer';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { ProductDto } from 'src/features/offers/interfaces/offersInterface';
import { ConfirmModalProps } from 'src/features/upsellFunnel/components/ConfirmModal';
import { ManageEdgeModalProps } from 'src/features/upsellFunnel/components/ManageEdgeModal';
import { ManageStepModalProps } from 'src/features/upsellFunnel/components/ManageStepModal';
import {
  getAllUpsellStepFlow,
  removeUpsellStepFlow,
} from 'src/service/api/upsellStepFlow';

type ParamsProps = {
  upsellFlow?: UpsellFlow.IUpsellFlow;
  productSellGroup?: ProductDto;
};

export type TUpsellStepsFlowManage = UpsellStepsFlow.IUpsellStepsFlow & {
  changed: boolean;
  position?: XYPosition;
};

export function useUpsellStepFlow() {
  const params = useLocation();
  const { goBack, push } = useHistory();
  const [flowSteps, setFlowSteps] = useState<
    UpsellStepsFlow.IUpsellStepsFlow[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changesSteps, setChangesSteps] = useState<TUpsellStepsFlowManage[]>(
    []
  );
  const [deletedSteps, setDeletedSteps] = useState<TUpsellStepsFlowManage[]>(
    []
  );
  const [manageStepModal, setManageStepModal] =
    useState<ManageEdgeModalProps | null>(null);

  const [deleteStepModal, setDeleteStepModal] =
    useState<ConfirmModalProps | null>(null);
  const [createStepModal, setCreateStepModal] =
    useState<ManageStepModalProps | null>(null);

  const { upsellFlow, productSellGroup } = params.state as ParamsProps;

  const updateFlowSteps = useCallback(async () => {
    try {
      if (!upsellFlow) return;
      setIsLoading(true);
      const steps = await getAllUpsellStepFlow(upsellFlow.id);
      setFlowSteps(steps);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [upsellFlow]);

  useEffect(() => {
    updateFlowSteps();
  }, []);

  useBreadcrumbs([
    {
      label: 'Upsell',
    },
    {
      label: 'Produtos',
    },
    {
      label: productSellGroup?.name || '',
    },
    {
      label: upsellFlow?.name || '',
    },
  ]);

  const addChangeStep = useCallback(
    (changedStep: TUpsellStepsFlowManage) => {
      const findChangeSteps = changesSteps.find(
        oldStep => changedStep.id === oldStep.id
      );

      setChangesSteps(
        !findChangeSteps
          ? [...changesSteps, changedStep]
          : changesSteps.map(oldStep =>
              changedStep.id === oldStep.id ? changedStep : oldStep
            )
      );
    },
    [changesSteps]
  );

  const onOpenManageStepModal = useCallback(
    (stepSource: Pick<ManageEdgeModalProps, 'stepFlow' | 'toStepFlow'>) => {
      setManageStepModal({
        ...stepSource,
        onCloseModal: () => setManageStepModal(null),
        onSelectFinish: addChangeStep,
      });
    },
    [addChangeStep]
  );

  const saveUpdatesPage = useCallback(async () => {
    try {
      setIsLoading(true);

      await updateUpsellStepFlow(changesSteps);
      await removeUpsellStepFlow(deletedSteps.map(({ id }) => id));

      push('/upsell/funnel');
    } catch (err) {
      console.error('Erro ao salvar', err);
    } finally {
      setIsLoading(false);
    }
  }, [changesSteps, push, deletedSteps]);

  const onRemoveStep = useCallback(
    (step: TUpsellStepsFlowManage) => {
      setDeletedSteps(oldState => [...oldState, step]);
    },
    [changesSteps, push]
  );

  const onOpenModalDeleteStep = useCallback(
    (step: TUpsellStepsFlowManage) => {
      setDeleteStepModal({
        onCloseModal: () => setDeleteStepModal(null),
        onConfirmDelete: () => onRemoveStep(step),
        titleConfirm: 'Deseja realmente excluir?',
        textConfirm:
          'Você ainda poderá recarregar a tela para desfazer essa ação, apenas antes de APLICAR',
      });
    },
    [onRemoveStep]
  );

  const onOpenStepCreateModal = useCallback(() => {
    if (!upsellFlow) return;

    setCreateStepModal({
      flowId: upsellFlow.id,
      onCloseModal: () => setCreateStepModal(null),
      onCreateFinish: updateFlowSteps,
    });
  }, [upsellFlow]);

  const onOpenModalEditStep = useCallback(
    (editStep: TUpsellStepsFlowManage) => {
      if (!upsellFlow) return;

      setCreateStepModal({
        flowId: upsellFlow.id,
        onCloseModal: () => setCreateStepModal(null),
        onCreateFinish: updateFlowSteps,
        editMode: {
          onEditFinish: addChangeStep,
          stepEdit: editStep,
        },
      });
    },
    [upsellFlow, addChangeStep]
  );

  const allFlowSteps = useMemo<TUpsellStepsFlowManage[]>(() => {
    return flowSteps
      .map(step => {
        const changeStep = changesSteps.find(({ id }) => step.id === id);
        if (changeStep) {
          return {
            ...changeStep,
            changed: true,
          };
        }

        return {
          ...step,
          changed: false,
        };
      })
      .filter(
        step => !deletedSteps.find(deleteStep => deleteStep.id === step.id)
      )
      .map(step => ({
        ...step,
        up: !deletedSteps.find(deleteStep => deleteStep.id === step.up)
          ? step.up
          : null,
        down: !deletedSteps.find(deleteStep => deleteStep.id === step.down)
          ? step.down
          : null,
      }));
  }, [flowSteps, changesSteps, deletedSteps]);

  return {
    upsellFlow,
    allFlowSteps,
    isLoading,
    onOpenStepCreateModal,
    onOpenManageStepModal,
    manageStepModal,
    addChangeStep,
    goBack,
    saveUpdatesPage,
    onOpenModalDeleteStep,
    deleteStepModal,
    createStepModal,
    onOpenModalEditStep,
  };
}
