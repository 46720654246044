import { Button, Row, Typography } from 'antd';
import { FC, ReactElement } from 'react';

type ModalProps = {
  title: string;
  firstText: string;
  secondText?: string;
  handleButton?: () => void;
  icon: ReactElement<any, any>;
  hasButton: boolean;
  buttonLoadingMode?: boolean;
};

export const ModalStepTwo: FC<ModalProps> = ({
  title,
  firstText,
  secondText,
  handleButton,
  buttonLoadingMode,
  icon,
  hasButton,
}) => {
  const { Text, Title } = Typography;

  return (
    <Row
      style={{
        flexDirection: 'column',
        width: '100% ',
        minHeight: '248px',
        background: '#242424',
        padding: '34px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
      <Title
        style={{
          margin: '0px',
          marginBlockStart: '11px',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '20px',
          color: '#7D7D7D',
        }}
      >
        {title}
      </Title>

      <Text
        style={{
          marginBlockStart: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          maxWidth: '380px',
          color: '#7D7D7D',
          textAlign: 'center',
        }}
      >
        {firstText}
      </Text>
      <Text
        style={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          maxWidth: '380px',
          color: '#7D7D7D',
          textAlign: 'center',
        }}
      >
        {secondText}
      </Text>

      {hasButton && (
        <Button
          disabled={buttonLoadingMode}
          loading={buttonLoadingMode}
          onClick={handleButton}
          style={{
            marginBlockStart: '24px',
            background: '#177DDC',
            color: '#FFFFFF',
          }}
        >
          Anonimizar dados
        </Button>
      )}
    </Row>
  );
};
