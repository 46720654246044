import './userDetailsForm.css';

import { Col, Input, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { AddonBeforeComponent } from 'src/app/components/AddonBeforeComponent';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { cpfMask } from 'src/app/helpers/StringHelper';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';
import {
  ClientDTOType,
  ClientType,
} from 'src/features/clients/types/clientTypes';

import { ClientDetailsProps } from '../ClientDetailsTab';

export const ClientInfoForm: FC<ClientDetailsProps> = ({
  dados,
  reloadUser,
}) => {
  const [enableForm, setEnableForm] = useState(true);
  const initialValueUser = {
    id: '',
    email: '',
    emailVerified: '',
    birthDate: '',
    document: '',
    name: '',
    gender: '',
    hasSon: '',
    imc: '',
    phone: '',
    weight: '',
    createdAt: '',
    updatedAt: '',
    idCredential: 0,
    idLevel: 0,
    customersPrograms: [
      {
        expirationDate: '',
      },
    ],
  };
  const [dataMap, setDataMap] = useState<ClientType>(initialValueUser);

  useEffect(() => {
    if (dados) {
      setDataMap(dados);
    }
  }, [dados]);

  function setUserInfoInDataMap(user?: ClientType) {
    if (!user) {
      return setDataMap(initialValueUser);
    }
    if (dados) {
      setDataMap(dados);
    }
  }

  const handleEditForm = (
    event: React.MouseEvent<HTMLElement>,
    isEdit?: boolean
  ) => {
    event.preventDefault();
    setEnableForm(!enableForm);
    isEdit ? mutate() : setUserInfoInDataMap(dados);
  };

  const edit = async () => {
    const json: ClientDTOType = {
      id: dataMap.id,
      email: dataMap.email,
      name: dataMap.name,
      phone: dataMap.phone,
      document: dataMap.document,
    };
    return clientApi.updateUser(json);
  };

  const { mutate } = useMutation(edit, {
    onSuccess: data => {
      setEnableForm(true);
      if (data.status === Success) {
        OpenNotification(
          false,
          'Sucesso!',
          'As Informações do cliente foram atualizadas!'
        );
        reloadUser();
      } else {
        OpenNotification(
          true,
          'Ocorreu um erro',
          'Tivemos um problema ao tentar atualizar cliente, por favor acione nosso suporte.'
        );
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    if (e.target.validity.valid) {
      setDataMap(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const dynamicMask = [
    { mask: '(00) 00000-0000', lazy: false },
    { mask: '+00 (00) 00000-0000', lazy: false },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={12} style={{ marginBottom: 8 }}>
              <label>Informações de conta do cliente</label>
            </Col>
            <Col span={12} style={{ marginBottom: 8 }}>
              <EditButtonComponent
                data-testid='edit'
                showSave={!enableForm}
                showEdit={enableForm}
                showDiscard={!enableForm}
                handleEditButton={handleEditForm}
              />
            </Col>
          </Row>
          <InputStyledFirst
            bordered={false}
            disabled
            data-testid='id'
            addonBefore={<AddonBefore>ID do Cliente</AddonBefore>}
            value={dataMap?.id}
          />
          <InputStyled
            bordered={false}
            onChange={e => handleChangeInput(e)}
            disabled={enableForm}
            addonBefore={<AddonBefore>E-mail de Acesso</AddonBefore>}
            defaultValue={dataMap?.email}
            value={dataMap?.email}
            name='email'
            data-testid='email'
          />
          <InputStyled
            bordered={false}
            disabled={enableForm}
            addonBefore={<AddonBefore>Nome</AddonBefore>}
            onChange={e => handleChangeInput(e)}
            value={dataMap?.name}
            name='name'
            data-testid='name'
          />
          <AddonBeforeComponent label='Telefone'>
            <MaskedInput
              style={{
                width: '100%',
                borderBottomColor: 'rgb(67, 67, 67)',
                borderBottomStyle: 'solid',
                borderRadius: 0,
                borderBottomWidth: 0,
                borderRightColor: 'rgb(67, 67, 67)',
                borderRightStyle: 'solid',
                borderRightWidth: 1,
              }}
              bordered={false}
              onChange={e => handleChangeInput(e)}
              defaultValue={
                dataMap.phone?.length > 11
                  ? dataMap.phone.split('+')[1]
                  : dataMap.phone
              }
              name='phone'
              data-testid='phone'
              disabled={enableForm}
              mask={dynamicMask}
              maskOptions={{
                dispatch: (apended, masked) => {
                  if (dataMap?.phone?.length > 11) {
                    return masked.compiledMasks[1];
                  }
                  return masked.compiledMasks[0];
                },
              }}
            />
          </AddonBeforeComponent>

          <InputStyled
            bordered={false}
            disabled={enableForm}
            addonBefore={<AddonBefore>CPF</AddonBefore>}
            onChange={e => {
              setDataMap(prevState => ({
                ...prevState,
                document: cpfMask(e.target.value) || '',
              }));
            }}
            value={dataMap?.document}
            name='document'
            data-testid='document'
            pattern='[0-9]*'
            maxLength={14}
          />
          <InputStyled
            bordered={false}
            disabled
            data-testid='last-access'
            addonBefore={<AddonBefore>Ultimo acesso (UTC)</AddonBefore>}
            value={dateFormatted(
              dataMap?.lastSeen ? dataMap?.lastSeen?.toString() : '',
              false
            )}
          />
          <InputStyled
            bordered={false}
            readOnly
            data-testid='score'
            addonBefore={<AddonBefore>Score</AddonBefore>}
            value={dataMap?.score}
          />
          <InputStyled
            bordered={false}
            disabled
            addonBefore={<AddonBefore></AddonBefore>}
          />
          <InputStyled
            bordered={false}
            disabled
            addonBefore={<AddonBefore></AddonBefore>}
          />
        </Col>
      </Row>
    </>
  );
};

const AddonBefore = styled.div`
  width: 200px;
  text-align: left;
  border: 'none';
`;

const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

const InputStyledFirst = styled(Input)`
  border-top-color: rgb(67, 67, 67);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;
