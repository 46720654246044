import { RootState } from '@core/redux';
import { InputNumber, Select } from 'antd';
import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnyObject } from 'yup/lib/types';

import { IDefaultIdNameResponseDto } from 'src/core/dto/default-id-name-response.dto';

import * as S from './badge-periodo-table-cell.style';

const nullOption: IDefaultIdNameResponseDto = {
  id: -1,
  name: 'Selecione',
};

interface Props {
  value?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

export const BadgePeriodoTableCell: FC<Props> = ({
  value = -1,
  disabled,
  onChange,
}) => {
  const { competitionEventTypeList = [], status } = useSelector(
    (state: RootState) => state.gamification
  );

  const [choosedEventState, setChoosedEventState] =
    useState<IDefaultIdNameResponseDto>({ id: -1, name: 'Não definido' });
  const [showCustomRangeDay, setShowCustomRangeDay] = useState<boolean>(false);
  const [customRangeDay, setCustomRangeDay] = useState<number>(0);

  useEffect(() => {
    const choosedEvent = competitionEventTypeList.find(
      event => event.id === value
    );

    if (showCustomRangeDay) {
      setCustomRangeDay(value);
      return;
    }

    if (!choosedEvent && value >= 0) {
      setCustomRangeDay(value);
      setShowCustomRangeDay(true);
      setChoosedEventState(
        competitionEventTypeList[competitionEventTypeList.length - 1]
      );
      return;
    }

    setChoosedEventState(choosedEvent || nullOption);
  }, [value]);

  const handleChange = (choosedValue: number) => {
    if (!competitionEventTypeList?.length) return;

    const choosedEvent = competitionEventTypeList.find(
      event => event.id === choosedValue
    );

    if (!choosedEvent) return;

    if (choosedEvent?.id === 0) {
      setShowCustomRangeDay(true);
    }

    setChoosedEventState(choosedEvent);
    onChange?.(choosedValue);
  };
  const options = (): Array<JSX.Element> => {
    const competitionEventTypeListNormalized = competitionEventTypeList.map(
      (item: AnyObject) => (
        <Select.Option key={`${item.id}_${item.name}`} value={item.id}>
          {item.name}
        </Select.Option>
      )
    );

    return competitionEventTypeListNormalized;
  };

  if (disabled) {
    if (!competitionEventTypeList.length && status === 'PENDING') {
      return (
        <S.Container>
          <S.TitleContainer>-</S.TitleContainer>
          <S.LoadingContainer>
            <Spin />
          </S.LoadingContainer>
        </S.Container>
      );
    }

    if (choosedEventState?.id < 0) {
      return <>{'Não definido'}</>;
    }

    if (choosedEventState.id === 0) {
      return (
        <S.Container>
          <S.TitleContainer>{choosedEventState?.name}</S.TitleContainer>
          <S.TitleContainer>{customRangeDay}</S.TitleContainer>
          <S.TitleContainer>dias</S.TitleContainer>
        </S.Container>
      );
    }

    return (
      <S.Container>
        <S.TitleContainer>{choosedEventState?.name}</S.TitleContainer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Select
        value={choosedEventState.id}
        placeholder={'Selecione'}
        disabled={status === 'PENDING' || disabled}
        loading={status === 'PENDING'}
        style={{ width: '100%' }}
        onChange={handleChange}
      >
        {status === 'PENDING' ? (
          <Select.Option key={`${Date.now()}`} value={-1} disabled>
            -
          </Select.Option>
        ) : (
          <Select.Option key={`${Date.now()}`} value={-1} disabled>
            Selecione
          </Select.Option>
        )}

        {options()}
      </Select>
      {showCustomRangeDay && (
        <InputNumber
          min={0}
          defaultValue={undefined}
          style={{ width: '100%' }}
          value={customRangeDay}
          onChange={value => onChange?.(value || 0)}
        />
      )}
    </S.Container>
  );
};
