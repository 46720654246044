import { Input, Select } from 'antd';
import styled from 'styled-components';

export const AddonBefore = styled.div`
  width: 30vw;
  text-align: left;
`;
export const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

export const InputStyledFirst = styled(Input)`
  border-top-color: rgb(67, 67, 67);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

export const SelectStyled = styled(Select)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
  border-left: none;

  > .ant-select-selector {
    border: none !important;
  }
`;

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
`;
