import { Tabs } from 'antd';
import { useState } from 'react';

import {
  AdyenRefusedTransaction,
  AdyenTransaction,
  ClientType,
} from 'src/features/clients/types/clientTypes';

import { CancelationTabsComponent } from './tables/CancelationTabsComponent';
import { FilterInput } from './tables/FilterInputComponent';
import { RefundsTabsComponent } from './tables/RefundsTabsComponent';
import { TransactionsTabsComponent } from './tables/TransactionsTabsComponent';

export type TabsProps = {
  transactionsAdyen?: AdyenTransaction[];
  refundAdyen?: AdyenTransaction[];
  transactionsCancelation?: AdyenRefusedTransaction[];
  dados?: ClientType;
};

export const AdyenTransactionTabsComponent = ({
  transactionsAdyen,
  refundAdyen,
  transactionsCancelation,
  dados,
}: TabsProps) => {
  const { TabPane } = Tabs;

  const [filter, setFilter] = useState('');

  const filterInput = <FilterInput filter={filter} onChange={setFilter} />;

  return (
    <>
      <Tabs
        defaultActiveKey='1'
        style={{ display: 'block' }}
        tabBarExtraContent={filterInput}
        onChange={() => setFilter('')}
      >
        <TabPane tab='Aprovadas' key='1'>
          <TransactionsTabsComponent
            transactionsAdyen={transactionsAdyen}
            dados={dados}
            filter={filter}
          />
        </TabPane>
        <TabPane tab='Reembolsos' key='2'>
          <RefundsTabsComponent
            refundAdyen={refundAdyen}
            email={dados?.email ?? ''}
            filter={filter}
          />
        </TabPane>
        <TabPane tab='Recusadas' key='3'>
          <CancelationTabsComponent
            transactionsCancelation={transactionsCancelation}
            filter={filter}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
