import { Controller } from '@organisms';
import { Col, Row } from 'antd';
import { Control } from 'react-hook-form';

export const CancelationButtonTitleFormFields = ({
  control,
}: {
  control: Control<any>;
}) => {
  return (
    <Row gutter={[24, 16]} style={{ marginTop: '2rem' }}>
      <Col span={12}>
        <Controller
          control={control}
          name={`continueCancelationButtonTitle`}
          defaultValue={''}
          placeholder='Título do botão "continuar o cancelamento"'
          autoComplete='off'
          label='Título do botão "continuar o cancelamento"'
          showCount={true}
          maxLength={250}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={`keepSubscriptionButtonTitle`}
          defaultValue={''}
          placeholder='Título do botão "Manter inscrição"'
          autoComplete='off'
          label='Título do botão "Manter inscrição"'
          showCount={true}
          maxLength={250}
        />
      </Col>
    </Row>
  );
};
