import jsPDF from 'jspdf';

import { dateFormatted, dateLongFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';

import { IAdyenRefundDataReportDto } from '../types/adyenRefundDataReportDto';

const quarenta = 40;
const vinte = 20;
const centoCetenta = 170;
const oitenta = 80;
const centoVinte = 120;
const dois = 2;
const dezesseis = 16;
const CentoCinquentaCinto = 155;
const onze = 11;
const centoOitentaCinco = 185;
const duzentosCinco = 205;
const quinhentos = 500;
const quatrocentosQuarenta = 440;
const duzentosOitenta = 280;
const trezentosSessenta = 360;

export function customerAdyenRefundPdf(data: IAdyenRefundDataReportDto) {
  if (data) {
    const doc = new jsPDF('p', 'pt');

    const img = new Image();
    img.src = `${window.location.origin}/static/logos/logoQueimaDiaria.png`;
    doc.addImage(img, 'PNG', quarenta, vinte, centoCetenta, oitenta);

    doc.setFontSize(onze);

    doc.text(
      `São Paulo, ${dateLongFormatted(data.createdAt, false)}`,
      quarenta,
      centoVinte
    );

    doc.setFontSize(dezesseis);
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(
      'Carta de Cancelamento de Transação',
      pageWidth / dois,
      CentoCinquentaCinto,
      {
        align: 'center',
      }
    );

    doc.setFontSize(onze);

    doc.text(
      'Nós gostaríamos de informar que cancelamos a transação abaixo:',
      quarenta,
      centoOitentaCinco
    );

    doc.text(
      [
        `Cliente: ${data.customer.fullName}`,
        `Referência do Pagamento: ${data.paymentInfo.paymentRef}`,
        `Data do Pagamento: ${dateFormatted(
          data.paymentInfo.paymentDate,
          false
        )}`,
        `Valor do Pagamento: BRL ${convertToCurrency(data.paymentInfo.amount)}`,
        `Modalidade do Pagamento: ${data.paymentInfo.paymentRef}`,
      ],
      quarenta,
      duzentosCinco
    );

    doc.text(
      [
        `Referência do Cancelamento: ${data.cancellationInfo.cancellationRef}`,
        `Valor do Cancelamento: BRL ${convertToCurrency(
          data.cancellationInfo.cancellationAmount
        )}`,
        `Data do Cancelamento: ${dateFormatted(
          data.cancellationInfo.cancellationDate,
          false
        )}`,
      ],
      quarenta,
      duzentosOitenta
    );

    doc.text(
      [
        `Número do Cartão: ${data.cardNumber}`,
        `Código do Estabelecimento: ${data.establishment}`,
        `Nome do Comerciante: ${data.merchantName}`,
      ],
      quarenta,
      trezentosSessenta
    );

    const splitTitle = doc.splitTextToSize(
      `Este documento foi criado para ajudar o emissor do cartão a encontrar o ` +
        `reembolso sob o numero da transação ${data.cancellationInfo.cancellationRef}. ` +
        `Guarde este comunicado, pois ele ira auxilia-lo em seus controles. ` +
        `Para mais informações, entre em contato com a QueimaDiária.`,
      quinhentos
    );
    doc.text(splitTitle, quarenta, quatrocentosQuarenta);

    doc.save(`${data.paymentInfo.gatewayTransaction}_adyen_refund.pdf`);
  }
}
