import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FC } from 'react';

import { FilterHeader } from '../../../app/framework/organisms/FilterHeader/FilterHeader.organism';
import { FilterLgpdProps } from '../interfaces/lgpdInterfaces';

export const FilterLgpd: FC<FilterLgpdProps> = ({
  setModalVisibility,
  filter,
  setFilter,
}) => {
  return (
    <FilterHeader
      customFilter={[
        <Input
          placeholder={'Buscar por nome, e-mail ou CPF'}
          suffix={<SearchOutlined />}
          value={filter}
          onChange={value => {
            setFilter(value.target.value);
          }}
          style={{ maxWidth: '362px', position: 'absolute', right: '80px' }}
        />,
      ]}
      buttonList={[
        <Button
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={() => {
            setModalVisibility(true);
          }}
        >
          Adicionar
        </Button>,
      ]}
    />
  );
};
