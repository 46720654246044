import { Button, Result } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

type IGeneralError = {
  action: () => void;
  title?: string;
  description?: string;
};

export const GeneralError: FC<IGeneralError> = props => {
  const { action, title, description } = props;
  const history = useHistory();

  const TITLE = title ?? 'Ops, algo deu errado';
  const DESCRIPTION =
    description ?? 'Não foi possível acessar este recurso neste momento';

  const BUTTONS = [
    <Button onClick={() => action()} type='primary' key='tryagain'>
      Tentar novamente
    </Button>,

    <Button onClick={() => history.goBack()} key='back'>
      Voltar
    </Button>,
  ];

  return (
    <Result
      status='error'
      title={TITLE}
      subTitle={DESCRIPTION}
      extra={BUTTONS}
    />
  );
};
