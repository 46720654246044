import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { IFilters } from '../dto/filters.interface';

type IManageCompetitionsFilter = {
  refetch: (n: IFilters) => void;
};

const { Search } = Input;

export const ManageCompetitionsFilter: FC<IManageCompetitionsFilter> = ({
  refetch,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const handleRefetch = () => {
    const values = form.getFieldsValue();

    refetch({
      from: moment(values.from).toISOString(),
      to: moment(values.to).toISOString(),
      search: values.search,
    });
  };

  return (
    <Row justify='space-between' style={{ margin: '20px 0' }}>
      <Col flex={1}>
        <Button
          type='primary'
          data-testid='cta-add-competition'
          onClick={() => history.push('competition/create')}
          icon={<PlusCircleOutlined />}
        >
          Cadastrar Competição
        </Button>
      </Col>

      <Col flex={'auto'}>
        <>
          <Form
            form={form}
            size='large'
            layout='inline'
            data-testid='filter'
            style={{ justifyContent: 'flex-end' }}
            onValuesChange={handleRefetch}
          >
            <Form.Item name='from' style={STYLES.DATE}>
              <DatePicker
                format='DD/MM/YYYY'
                style={STYLES.FIELD}
                placeholder='Data inicial'
                data-testid='from'
              />
            </Form.Item>

            <Form.Item name='to' style={STYLES.DATE}>
              <DatePicker
                format='DD/MM/YYYY'
                style={STYLES.FIELD}
                placeholder='Data final'
                data-testid='to'
              />
            </Form.Item>

            <Form.Item name='search' style={STYLES.SEARCH}>
              <Search
                placeholder='Buscar por nome'
                onSearch={handleRefetch}
                onBlur={handleRefetch}
                data-testid='search'
              />
            </Form.Item>
          </Form>
        </>
      </Col>
    </Row>
  );
};

const STYLES = {
  DATE: { width: 200 },
  SEARCH: { marginRight: 0, width: 300 },
  FIELD: { width: '100%' },
};
