import { slices } from '@core/redux';
import { UpsellLinks } from '@models';
import { UpsellLinks as apiUpsellLinks } from '@service/api';
// import { orderTable.upsellLinks } from '@service/api';
import { PageLimitFilter } from '@typings';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { TableUpsellLinksProps } from 'src/features/upsellFunnel/screens/UpsellFunnel/components/upsellLinks/Table.upsellLinks';

import OpenNotification from '../components/antd/OpenNotification';

export const useUpsellLinks = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [upsellLinks, setUpsellLinksList] =
    useState<UpsellLinks.TUpsellLinksResponse>();
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [isModalPreview, setIsModalPreview] = useState(false);
  const [dataModal, setDataModal] = useState<UpsellLinks.IUpsellLinks | null>(
    null
  );

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const handleOpenModalUrl = useCallback(
    (loadDataModal?: UpsellLinks.IUpsellLinks, isPreview = false) => {
      setIsUrlModalOpen(true);
      !!loadDataModal && setDataModal(loadDataModal);
      setIsModalPreview(isPreview);
    },
    []
  );

  const getAllUpsellLinks = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await apiUpsellLinks.getAllUpsellLinks(props);
      setUpsellLinksList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  const fetchLinks = useCallback(getAllUpsellLinks, [getAllUpsellLinks]);

  const handleCloseModalUrl = useCallback(() => {
    setIsUrlModalOpen(false);
    fetchLinks();
  }, []);

  useEffect(() => {
    fetchLinks();
  }, []);

  useBreadcrumbs([
    {
      label: 'Upsell',
    },
    {
      label: 'URLs',
    },
  ]);

  const handleChangePagination: TableUpsellLinksProps['onChangePagination'] = (
    take,
    _,
    pagination
  ) => {
    getAllUpsellLinks({
      ...currentFilter,
      limit: take,
      page: pagination,
    });
  };

  const handleFilterUpsellLinks = useCallback(
    (filterList: { field: string; value?: string }[]) => {
      const current = {
        ...currentFilter,
        filter: {
          ...currentFilter?.filter,
        },
      };

      filterList.forEach(filterToAdd => {
        if (!filterToAdd.value) {
          delete current.filter?.[filterToAdd.field];
        } else {
          current.filter[filterToAdd.field] = filterToAdd.value;
        }
      });

      getAllUpsellLinks(current as PageLimitFilter);
    },
    [getAllUpsellLinks, currentFilter]
  );

  const handleUpsellLinks = useCallback(
    (order: string) => {
      getAllUpsellLinks({ ...currentFilter, order });
    },
    [getAllUpsellLinks, currentFilter]
  );

  const handleCreateUpsellLinks = useCallback(() => {
    handleOpenModalUrl();
  }, [push]);

  const handlePreviewUpsellLinks = useCallback(
    (upsellLinks: UpsellLinks.IUpsellLinks) => {
      handleOpenModalUrl(upsellLinks, true);
    },
    [push]
  );

  const handleEditUpsellLinks = useCallback(
    (upsellLinks: UpsellLinks.IUpsellLinks) => {
      handleOpenModalUrl(upsellLinks);
    },
    [push]
  );

  return {
    handleChangePagination,
    upsellLinks,
    handleFilterUpsellLinks,
    isLoadingData,
    handleCreateUpsellLinks,
    handleUpsellLinks,
    handlePreviewUpsellLinks,
    handleEditUpsellLinks,
    modalUrl: {
      isOpen: isUrlModalOpen,
      handleOpenModalUrl,
      handleCloseModalUrl,
      dataModal,
      isModalPreview,
    },
  };
};
