import styled from 'styled-components';

export const ModalModelContainer = styled.div`
  height: 227.65px;
  width: 281.46px;
  border: 2px solid #434343;
  border-radius: 8.28px;
  background-color: #262626;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ModalModelSpace = styled.div`
  padding: 16.56px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalModelTitleSkeleton = styled.div`
  height: 8.28px;
  width: 103.62px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const ModalModelDescriptionSkeleton = styled.div`
  height: 8.28px;
  width: 206.95px;
  margin-bottom: 8.28px;

  border-radius: 8.28px;
  background-color: #434343;
`;

export const Space = styled.div`
  margin-top: 8.28px;
`;

export const ModalModelTextboxSkeleton = styled.div`
  height: 103.62px;
  width: 248.7px;
  margin-bottom: 16.58px;

  border-radius: 4.14px;
  background-color: #434343;
`;

export const ModalModelFooterContainerSkeleton = styled.div`
  width: 248.7px;
  height: 16.56px;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8.28px;
`;

export const ModalModelButtonSkeleton = styled.div`
  width: 49.67px;
  height: 16.56px;
  border-radius: 4.14px;
  margin-left: 8.28px;

  background-color: #434343;
`;

export const ModalModelFooterCircleSkeletonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalModelFooterCircleSkeleton = styled.div`
  width: 8.28px;
  height: 8.28px;
  border-radius: 16.56px;
  margin-right: 4.15px;

  background-color: #434343;
`;
