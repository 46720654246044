import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { RootState, slices } from '@core/redux';
import { FilterHeader } from '@organisms';
import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const STATUS_OPTIONS = [
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' },
];

export const FilterEvents: React.FC = () => {
  const dispatch = useDispatch();
  const { eventTypeList } = useSelector((state: RootState) => state.events);
  const [filterState, setFilterState] = useState({
    name: '',
    eventType: '',
    statusActivated: undefined,
    identifier: '',
  });

  const handleState = (range: string, value: any) => {
    setFilterState({ ...filterState, [range]: value });
  };

  const normalizeStatus = (status: string | undefined) => {
    if (status === '1') {
      return 'true';
    }
    if (status === '0') {
      return 'false';
    }
    return undefined;
  };

  useEffect(() => {
    dispatch(
      slices.events.getAllEvents({
        name: filterState.name
          ? { iLike: `$${filterState.name.replace(' ', '$')}$` }
          : undefined,
        eventTypeId: filterState.eventType || undefined,
        statusActivated: normalizeStatus(filterState.statusActivated),
      })
    );
  }, [filterState]);

  const handleClickAddBanner = () => {
    dispatch(slices.events.newEvent());
  };

  return (
    <FilterHeader
      customFilter={[
        <Select
          allowClear
          dropdownMatchSelectWidth
          placeholder={'Status'}
          options={STATUS_OPTIONS}
          onChange={value => handleState('statusActivated', value)}
        />,
        <Select
          allowClear
          dropdownMatchSelectWidth
          placeholder={'Tipo de evento'}
          options={eventTypeList?.map(eventType => ({
            value: eventType.id,
            label: eventType.name,
          }))}
          onChange={value => handleState('eventType', value)}
        />,

        <Input
          value={filterState.name}
          placeholder={'Buscar pelo nome ou identificador'}
          onChange={evt => handleState('name', evt.target.value)}
          suffix={<SearchOutlined />}
        />,
      ]}
      buttonList={[
        <Button
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={handleClickAddBanner}
        >
          Adicionar Evento
        </Button>,
      ]}
    />
  );
};
