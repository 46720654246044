/**
 * Busca uma variável no process.env e retorna o valor.
 * Caso, a variável seja obrigatória, a ausência da variável sera notificada.
 */
export function defineEnv(name: string, isRequired = true): string | undefined {
  if (!name) return;
  const value = process.env[name];

  if (isRequired && value === undefined) {
    console.error(
      `Required environment variable ${name} not defined. Set it on .env's to run the app.`
    );
    return undefined;
  }

  return value;
}
