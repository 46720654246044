import { Layout } from 'antd';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { AppBreadCrumbs } from './AppBreadcrumbs';
import { AppHeader } from './AppHeader';

const { Content } = Layout;

export interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  return (
    <FullScreenLayout>
      <AppHeader />
      <Content>
        <AppBreadCrumbs />
        <ContentWrapper>{children}</ContentWrapper>
      </Content>
    </FullScreenLayout>
  );
};

const FullScreenLayout = styled(Layout)`
  min-height: 100vh;
  background: #141414;
`;

const ContentWrapper = styled.div`
  padding: 18px 120px 0px 120px;
  margin-bottom: 10%;
`;
