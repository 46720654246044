import { PageHeader } from '@organisms';
import { Spin } from 'antd';

import { LayoutConfirmationFooter } from '../../../molecules/LayoutConfirmationFooter/LayoutConfirmationFooter.molecule';
import { PhysicCouponForm } from '../components/CouponForm/PhysicCouponForm.component';
import { useProductPhysicCoupon } from '../hooks/useProductPhysicCoupon';

export function CreateCouponPhysicPage() {
  const couponHookData = useProductPhysicCoupon();

  return (
    <Spin spinning={couponHookData.isLoading}>
      <LayoutConfirmationFooter
        onCancel={couponHookData.handleCancel}
        onSave={couponHookData.dispatchSubmit}
        cancelLabelButton='Descartar'
        ableToSave
      >
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Formulário para a criação do Cupom'}
          subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
        />

        <PhysicCouponForm couponHookData={couponHookData} />
      </LayoutConfirmationFooter>
    </Spin>
  );
}
