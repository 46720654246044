import { Modal } from '@models';
import { RcFile } from 'antd/lib/upload';
import { boolean, mixed, object, SchemaOf, string } from 'yup';

export const defaultContentSchema: SchemaOf<Modal.IDefaultContent> = object({
  id: string().notRequired(),
  urlLocalShow: string().required('Este campo é obrigatório'),
  mediaType: string().required('Este campo é obrigatório'),
  title: string().required('Este campo é obrigatório'),
  titleActivated: boolean().notRequired(),
  description: string().notRequired(),
  textButton: string().notRequired(),
  urlVideoModal: string().when('mediaType', {
    is: 'VIDEO',
    then: string().required(
      'Campo obrigatório caso o tipo de mídia seja vídeo'
    ),
    otherwise: string().notRequired(),
  }),
  imageMobile: mixed<RcFile | string>().when('mediaType', {
    is: 'IMAGE',
    then: mixed<RcFile | string>().required(
      'Campo obrigatório caso o tipo de mídia seja imagem'
    ),
    otherwise: mixed<RcFile | string>().notRequired(),
  }),
  imageWeb: mixed<RcFile | string>().when('mediaType', {
    is: 'IMAGE',
    then: mixed<RcFile | string>().required(
      'Campo obrigatório caso o tipo de mídia seja imagem'
    ),
    otherwise: mixed<RcFile | string>().notRequired(),
  }),
  redirectButtonUrl: string().when('textButton', {
    is: (textButton: string) => textButton && textButton.length > 0,
    then: string()
      .required(
        'Este campo é obrigatório caso o texto do botão esteja preenchido'
      )
      .matches(
        /^https:\/\//,
        'A URL de redirecionamento deve começar com https://'
      ),
    otherwise: string().notRequired(),
  }),
  openExternalWindow: boolean(),
}).defined();
