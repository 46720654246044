export const TvSvg = () => (
  <svg
    width='48'
    height='49'
    viewBox='0 0 48 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.5 42.5V38.5H7C6.175 38.5 5.46875 38.2063 4.88125 37.6187C4.29375 37.0312 4 36.325 4 35.5V11.5C4 10.675 4.29375 9.96875 4.88125 9.38125C5.46875 8.79375 6.175 8.5 7 8.5H41C41.825 8.5 42.5312 8.79375 43.1188 9.38125C43.7062 9.96875 44 10.675 44 11.5V35.5C44 36.325 43.7062 37.0312 43.1188 37.6187C42.5312 38.2063 41.825 38.5 41 38.5H39.5V42.5H38.35L37.05 38.5H11L9.65 42.5H8.5ZM7 35.5H41V11.5H7V35.5Z'
      fill='white'
    />
  </svg>
);
