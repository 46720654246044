import { PlusCircleOutlined } from '@ant-design/icons';
import { useDebounce } from '@hooks';
import { FilterHeader } from '@organisms';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface FilterOrderBumpProps {
  onAddOrderBump: () => void;
  onFilterOrderBump: (handle: { field: string; value?: string }[]) => void;
}

export const FilterOrderBumps: React.FC<FilterOrderBumpProps> = ({
  onAddOrderBump,
  onFilterOrderBump,
}) => {
  const [filterByCode, setFilterByCode] = useState<string | null>(null);

  const debounceValue = useDebounce(filterByCode ?? '', 900);

  useEffect(() => {
    if (
      filterByCode !== null &&
      (filterByCode.length > 2 || filterByCode.length === 0)
    ) {
      onFilterOrderBump([
        {
          field: 'filter',
          value: debounceValue,
        },
      ]);
    }
  }, [debounceValue]);

  return (
    <FilterHeader
      buttonList={[
        <Input.Search
          key={`filter-coupon-code-search`}
          placeholder={'Buscar por código do cupom. (Mínimo 3 letras)'}
          value={filterByCode ?? ''}
          onChange={evt => setFilterByCode(evt.target.value)}
          style={{ width: '560px' }}
          allowClear
        />,
      ]}
      customFilter={[
        <Button
          key={'filter-header-button-add-assets'}
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={onAddOrderBump}
        >
          Criar oferta especial
        </Button>,
      ]}
    />
  );
};
