import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { GeneralError } from 'src/app/components/Error';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { subscriptionApi } from './client.api';
import { ScheduledReportsFilter } from './components/Filter';
import { ScheduledReportsHeader } from './components/Header';
import { ScheduledReportsTable } from './components/Table';
import { IDateRange } from './dto/date-range.interface';

export const SubscriptionListScreen: FC = () => {
  useBreadcrumbs([
    { label: 'Assinaturas' },
    { label: 'Cancelamementos' },
    { label: 'Agendas' },
  ]);

  const [dateRange, setDateRange] = useState<IDateRange>();

  const _callApi = async () => {
    if (dateRange?.from && dateRange?.to) {
      const client = subscriptionApi.scheduledCancellationReport;
      const response = await client(dateRange);
      return response.data;
    }
  };

  const opts = { refetchOnWindowFocus: false };
  const QUERY = useQuery(['scheduled_reports', dateRange], _callApi, opts);
  const { isFetching, data, error, refetch } = QUERY;

  const HEADER_PROPS = {
    dateRange,
    total: data?.length ?? 0,
    isFetching,
    refetch,
  };

  const FILTER_PROPS = {
    refetch: (from: Date, to: Date) => setDateRange({ from, to }),
    dateRange,
  };

  const TABLE_PROPS = { dataSource: data, loading: isFetching };

  return (
    <>
      <ScheduledReportsHeader {...HEADER_PROPS} />
      <ScheduledReportsFilter {...FILTER_PROPS} />

      {!error && <ScheduledReportsTable {...TABLE_PROPS} />}
      {error && <GeneralError action={refetch} />}
    </>
  );
};
