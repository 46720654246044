export const NOT_ANSWERED = 'Não informado';

export enum ObjectivesLabelEnum {
  WEIGHT_LOSS = 'Perda de peso',
  TONE_UP = 'Definição',
  FLEXIBILITY = 'Flexibilidade',
  HEALTH_AND_WELLNESS = 'Saúde e bem-estar',
}

export type ObjectivesLabelType =
  | ObjectivesLabelEnum.FLEXIBILITY
  | ObjectivesLabelEnum.HEALTH_AND_WELLNESS
  | ObjectivesLabelEnum.TONE_UP
  | ObjectivesLabelEnum.WEIGHT_LOSS;

export const getObjectivesLabelById = (
  objectiveId: number
): ObjectivesLabelType | typeof NOT_ANSWERED => {
  switch (objectiveId) {
    case 0:
      return ObjectivesLabelEnum.WEIGHT_LOSS;
    case 1:
      return ObjectivesLabelEnum.TONE_UP;
    case 2:
      return ObjectivesLabelEnum.FLEXIBILITY;
    case 3:
      return ObjectivesLabelEnum.HEALTH_AND_WELLNESS;
    default:
      return NOT_ANSWERED;
  }
};

export enum LevelLabelEnum {
  SEDENTARY = 'Sedentário',
  LIGHTLY_ACTIVE = 'Levemente ativo',
  ACTIVE = 'Ativo',
  VERY_ACTIVE = 'Muito ativo',
}

export type LevelsLabelType =
  | LevelLabelEnum.SEDENTARY
  | LevelLabelEnum.LIGHTLY_ACTIVE
  | LevelLabelEnum.ACTIVE
  | LevelLabelEnum.VERY_ACTIVE;

export const getLevelsLabelById = (
  levelId: number
): LevelsLabelType | typeof NOT_ANSWERED => {
  switch (levelId) {
    case 0:
      return LevelLabelEnum.SEDENTARY;
    case 1:
      return LevelLabelEnum.LIGHTLY_ACTIVE;
    case 2:
      return LevelLabelEnum.ACTIVE;
    case 3:
      return LevelLabelEnum.VERY_ACTIVE;
    default:
      return NOT_ANSWERED;
  }
};
