const InvalidDate = 'Invalid Date';
export function dateFormatted(dateParam?: string, withTime = false) {
  if (!dateParam) {
    return '';
  }
  const date = new Date(dateParam);
  if (date.toString() === InvalidDate) {
    return InvalidDate;
  }

  if (withTime) {
    return date.toLocaleString('pt-BR', { timeZone: 'UTC' });
  } else {
    return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  }
}

export function getDifferenceInDaysFromTwoDates(
  startDate: string,
  endDate: string
): number {
  const millisecconds = 1000;
  const minutes = 60;
  const hours = 24;
  const _MS_PER_DAY = millisecconds * minutes * minutes * hours;

  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);

  const utc1 = Date.UTC(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  );
  const utc2 = Date.UTC(
    secondDate.getFullYear(),
    secondDate.getMonth(),
    secondDate.getDate()
  );

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateLongFormatted(dateParam: string, withTime: boolean) {
  if (!dateParam) {
    return '';
  }
  const date = new Date(dateParam);
  if (date.toString() === InvalidDate) {
    return InvalidDate;
  }

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    timeZone: 'UTC',
  };

  if (withTime) {
    return date.toLocaleString('pt-BR', options);
  } else {
    return date.toLocaleDateString('pt-BR', options);
  }
}
