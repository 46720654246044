import { Button, Result } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

export const NotFoundRouteScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Início',
      route: '/',
    },
    {
      label: '404 - Página não encontrada',
    },
  ]);

  return (
    <Result
      status='404'
      title='404'
      subTitle='A página que você estava tentando acessar ainda está em construção..'
      extra={
        <Link to='/'>
          <Button data-testid='back' type='primary'>
            Voltar ao Início
          </Button>
        </Link>
      }
    />
  );
};
