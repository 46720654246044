import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Col, Empty, Row, Space, Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type EmptyStateProps = {
  image?: React.ReactNode;
  title: string;
  subtitle: string;
  buttonLabel?: string;
  onClickButton?: () => void;
  type?: BaseType;
} & React.ComponentProps<typeof Empty>;

const { Text, Title } = Typography;
export const ErrorState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
  image,
  type = 'secondary',
  buttonLabel,
  onClickButton,
  ...rest
}) => {
  const customDescription = useMemo(() => {
    return (
      <Row justify={'center'}>
        <Col>
          <Space size={'large'} direction='vertical'>
            <Space size={'small'} direction='vertical'>
              {title && (
                <Title
                  type={type}
                  level={4}
                  style={{ margin: 0, color: 'white' }}
                >
                  {title}
                </Title>
              )}
              {subtitle && <Text type={type}>{subtitle}</Text>}
            </Space>
            <Button type='primary' onClick={() => onClickButton?.()}>
              {buttonLabel ?? 'Recarregar'}
            </Button>
          </Space>
        </Col>
      </Row>
    );
  }, [title, subtitle, type, buttonLabel]);

  return (
    <ErrorContainer>
      <CustomEmpty
        image={
          image ?? (
            <CloseCircleFilled
              style={{ fontSize: '72px', color: 'rgba(255, 255, 255, 0.18)' }}
            />
          )
        }
        description={customDescription}
        {...rest}
      />
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const CustomEmpty = styled(Empty)`
  margin: 0;
  & > .ant-empty-image {
    height: auto;
  }
`;
