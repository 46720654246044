import { base } from '@service/base';

import { CouponPhysic } from 'src/models';

export async function getPhysicCouponById(
  couponId: number
): Promise<CouponPhysic.ICoupon> {
  try {
    const { data } = await base.get<CouponPhysic.ICoupon>(
      `/sales/coupon/${couponId}`
    );
    return data;
  } catch (error) {
    throw new Error('Não foi possível carregar o cupom solicitado!');
  }
}
