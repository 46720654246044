import styled from 'styled-components';

export const Container = styled.div`
  background-color: #1d1d1d;

  width: 100%;
  height: 128px;
`;
export const EmptyContainer = styled.label`
  all: unset;
  border: 1px dashed #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  width: 100%;
  height: inherit;
  background-color: transparent;
  color: #7d7d7d;

  outline: none;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Img = styled.img`
  width: inherit;
  height: inherit;
  object-fit: fill;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FullyContainer = styled.label`
  height: inherit;
  width: inherit;

  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 2px;

  cursor: pointer;
`;

export const ImageFooter = styled.div`
  font-size: 10px;
  color: #7d7d7d;
  display: flex;
  justify-content: space-between;
`;

export const DimensionText = styled.div`
  display: inline;
  color: #7d7d7d;
  font-weight: bold;
`;

export const Text = styled.div``;
export const DeleteContainer = styled.button`
  all: unset;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

export const ErrorText = styled.div`
  color: #fc580a;
`;
