import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { AnyObject } from 'yup/lib/types';

import { ICustomerVipGroupResponseDto } from 'src/core/admin-cx/customer-vip-group/dto/customer-vip-group-response.dto';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { createCustomerVipGroupApi } from 'src/features/clients/api/customer-vip-group.api';

import { createVipGroupApi } from '../../../../../features/clients/api/vip-group.api';
import { createVipGroupSocialNetworkApi } from '../../../../../features/clients/api/vip-group-social-network.api';

let index = 0;

const vipGroupParticipantApi = createCustomerVipGroupApi();
const vipGroupSocialNetworkApi = createVipGroupSocialNetworkApi();
const vipGroupApi = createVipGroupApi();

interface ComponenteProps {
  data?: ICustomerVipGroupResponseDto;
  handleCloseModal?: (value?: boolean) => void;
  customerId?: number;
  showModal?: boolean;
}

export const ModalGroupVipActions: FC<ComponenteProps> = ({
  data,
  handleCloseModal,
  showModal = false,
  customerId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (showModal && data) {
      form.setFieldsValue({
        nomeRede: data.name,
        redeSocial: data?.vipGroup?.socialNetwork?.idVipGroupSocialNetwork,
        link: data.url,
        nomeGrupo: data?.vipGroup?.idVipGroup,
        dataInclusao: moment(data?.includedAt),
        dataExclusao: data?.excludedAt ? moment(data?.excludedAt) : undefined,
      });
      setIdSocialNetwork(
        data?.vipGroup?.socialNetwork?.idVipGroupSocialNetwork
      );
    }
  }, [showModal]);

  const handleSalvarClick = async () => {
    setLoading(true);
    const values = await form.validateFields();
    form.resetFields();
    if (!data) {
      await vipGroupParticipantApi.addCustomerToVipGroup(
        values.nomeGrupo as number,
        {
          idCustomer: customerId,
          name: values.nomeRede,
          url: values.link,
          inclusionDate: values.dataInclusao.toDate() as Date,
          departureDate: values.dataExclusao
            ? (values.dataExclusao.toDate() as Date)
            : undefined,
        }
      );
    } else {
      await vipGroupParticipantApi.updateCustomerVipGroup(
        data?.vipGroup?.idVipGroup as number,
        data?.id as number,
        {
          name: values.nomeRede,
          url: values.link,
          inclusionDate: values.dataInclusao.toDate() as Date,
          departureDate: values.dataExclusao
            ? (values.dataExclusao.toDate() as Date)
            : undefined,
        }
      );
    }
    setLoading(false);
    handleCloseModal?.(false);
  };

  const handleFecharClick = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    handleCloseModal?.(false);
  };

  const [itemsRedeSocial, setItemsRedeSocial] = useState<Array<AnyObject>>([]);
  const [itemsGrupos, setItemsGrupos] = useState<Array<AnyObject>>([]);
  const [vipGroupSocialNetworkName, setVipGroupSocialNetworkName] =
    useState('');
  const [vipGroupName, setVipGroupName] = useState('');
  const [idSocialNetwork, setIdSocialNetwork] = useState<number | undefined>(
    undefined
  );

  const onVipGroupSocialNetworkNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVipGroupSocialNetworkName(event.target.value);
  };

  const onVipGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVipGroupName(event.target.value);
  };

  const addVipGroupSocialNetwork = async (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    await vipGroupSocialNetworkApi.criarSocialNetwork({
      name: vipGroupSocialNetworkName || `Nome da rede social ${index++}`,
    });
    await getSocialNetworkList();
    setVipGroupSocialNetworkName('');
  };

  const addVipGroup = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!idSocialNetwork) {
      return;
    }
    await vipGroupApi.criar({
      idVipGroupSocialNetwork: idSocialNetwork as number,
      name: vipGroupName || `Nome do grupo ${index++}`,
    });
    await getGroupVipListBySocialNetwork();
    setVipGroupName('');
  };

  const getSocialNetworkList = async () => {
    const response =
      (await vipGroupSocialNetworkApi.obterListaSocialNetwork()) as DataResponseApiDto<
        AnyObject[]
      >;
    if (!response || (response && !response.result)) {
      setItemsRedeSocial([]);
      return;
    }
    setItemsRedeSocial(
      response?.data?.map(p => ({
        idVipGroupSocialNetwork: p.idVipGroupSocialNetwork,
        name: p.name,
        key: `redesocial-${p.idVipGroupSocialNetwork}`,
      }))
    );
  };

  const getGroupVipListBySocialNetwork = async () => {
    const response = (await vipGroupApi.obterLista(
      idSocialNetwork as number
    )) as DataResponseApiDto<AnyObject[]>;
    if (!response || (response && !response.result)) {
      setItemsGrupos([]);
      return;
    }
    setItemsGrupos(
      response?.data?.map(p => ({
        idVipGroup: p.idVipGroup,
        name: p.name,
        key: `vipgroup-${p.idVipGroup}`,
      }))
    );
  };

  useEffect(() => {
    if (!idSocialNetwork) {
      setItemsGrupos([]);
      return;
    }
    // procurar novas sociais network
    getGroupVipListBySocialNetwork().catch(console.error);
  }, [idSocialNetwork]);

  useEffect(() => {
    // procurar novas sociais network
    getSocialNetworkList().catch(console.error);
  }, []);

  const handleFormValuesChange = (
    _valueChanged: AnyObject,
    allFormValues: AnyObject
  ) => {
    if (allFormValues.redeSocial) {
      setIdSocialNetwork(allFormValues.redeSocial as number);
    } else {
      setIdSocialNetwork(undefined);
    }
  };

  return (
    <Modal
      title='Gestão de Grupos'
      visible={showModal}
      onCancel={handleFecharClick}
      footer={
        <>
          <Button
            disabled={loading}
            loading={loading}
            type='primary'
            onClick={handleSalvarClick}
          >
            Salvar
          </Button>
          <Button
            disabled={loading}
            key='not'
            data-testid='not'
            onClick={handleFecharClick}
          >
            Fechar
          </Button>
        </>
      }
    >
      <>
        <Form
          form={form}
          layout='vertical'
          onValuesChange={handleFormValuesChange}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='Rede social' name='redeSocial'>
                <Select
                  disabled={loading || !!data}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Rede social'
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                          placeholder='Nome da rede social'
                          value={vipGroupSocialNetworkName}
                          onChange={onVipGroupSocialNetworkNameChange}
                        />
                        <Button
                          type='text'
                          icon={<PlusOutlined />}
                          onClick={addVipGroupSocialNetwork}
                        />
                      </Space>
                    </>
                  )}
                >
                  {itemsRedeSocial.map(item => (
                    <Select.Option
                      key={item.key}
                      value={item.idVipGroupSocialNetwork}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Nome do grupo' name='nomeGrupo'>
                <Select
                  disabled={loading || !!data}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Nome do grupo'
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                          placeholder='Nome do grupo'
                          value={vipGroupName}
                          onChange={onVipGroupNameChange}
                        />
                        <Button
                          disabled={!idSocialNetwork}
                          type='text'
                          icon={<PlusOutlined />}
                          onClick={addVipGroup}
                        />
                      </Space>
                    </>
                  )}
                >
                  {itemsGrupos.map(item => (
                    <Select.Option key={item.key} value={item.idVipGroup}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='Nome na rede' name='nomeRede'>
                <Input
                  disabled={loading}
                  name='nomeRede'
                  placeholder='Nome do cliente na rede'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Link' name='link'>
                <Input disabled={loading} name='link' placeholder='Link' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='Data inclusão' name='dataInclusao'>
                <DatePicker
                  style={{ width: '100%' }}
                  format={['DD/MM/YYYY', 'DD/MM/YY']}
                  disabled={loading}
                  name='dataInclusao'
                  placeholder='00/00/0000'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Data exclusão' name='dataExclusao'>
                <DatePicker
                  style={{ width: '100%' }}
                  format={['DD/MM/YYYY', 'DD/MM/YY']}
                  disabled={loading}
                  name='dataExclusao'
                  placeholder='00/00/0000'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};
