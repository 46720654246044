import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { VideoFormData } from '../../types/videoPageTypings';
import { videoPayloadFunnelsPageFactory } from '../../utils/videoFunnelsPageFactory';

interface UpdateVideoFunnelPageRequest {
  id: string;
  payload: VideoFormData;
}

export function useTryToRequestUpdateVideoPage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async ({ id, payload }: UpdateVideoFunnelPageRequest) => {
      const factory = await videoPayloadFunnelsPageFactory(payload);

      return await apiCancellationFunnels.tryToRequestUpdateCancellationFunnelPage(
        id,
        factory
      );
    },
    {
      onSuccess: response => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao editar o funil de Vídeo',
          'Ocorreu um erro ao editar o funil de Vídeo, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestUpdateVideoPage: mutateAsync,
    isLoadingVideoUpdate: isLoading,
  };
}
