import { slices } from '@core/redux';
import { auth } from '@service/api';
import { Login } from '@templates';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './Login.style';
type State = {
  from: ReturnType<typeof useLocation>;
};

export const LoginPage: FC = () => {
  const history = useHistory();
  const location = useLocation<State>();
  const from = location.state?.from ?? { pathname: '/' };
  const dispatch = useDispatch();

  const onPressLogin = useCallback(async (credentials: auth.Credentials) => {
    // TODO: REMOVE ANY
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await dispatch(slices.auth.tryLogin(credentials));
    const hasError = response.type === slices.auth.tryLogin.rejected.type;

    if (!hasError) {
      history.replace(from);
    }
  }, []);

  return (
    <S.Container>
      <S.RightCol span={6}>
        <S.FormContainer>
          <S.Header>
            <S.LogoWrapper>
              <S.BrandLogo style={{ marginBottom: 40 }} />
            </S.LogoWrapper>
            <S.TextLogin>Faça login em sua conta</S.TextLogin>
          </S.Header>
          <Login.LoginForm onLogin={onPressLogin} />
        </S.FormContainer>
      </S.RightCol>
    </S.Container>
  );
};
