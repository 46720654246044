import { HolderOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Assets } from '@models';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import { capitalizeOnlyFirstLetter } from 'src/app/utils/capitalizeOnlyFirstLetter';

import * as S from './OrderTableAssets.style';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    ...restProps
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <S.Container
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...restProps}
    />
  );
};

interface DataTable extends Record<string, any> {
  key: string;
}

interface OrderListProps {
  columnTitle: string;
  datasource: Record<string, any>[];
  changeOrder: (value: Record<string, any>[]) => void;
  showHeader?: boolean;
}
export const OrderTableAssets: React.FC<OrderListProps> = ({
  datasource,
  changeOrder,
}) => {
  const [data, setData] = useState<DataTable[]>([]);
  const columns: ColumnsType<DataTable> = [
    {
      title: '',
      dataIndex: 'act',
      key: 'act',
      width: 50,
      render: () => <HolderOutlined />,
    },
    {
      title: 'Asset',
      dataIndex: 'banner',
      key: 'banner',
      render: (val: Assets.IBannerDetail) => {
        return <div>{val?.title || 'Não informado'}</div>;
      },
    },
    {
      title: 'Segmento',
      dataIndex: 'segments',
      key: 'segments',
      width: 200,
      render: (assetSegments: Assets.IAssetSegments[]) => {
        let segmentsText = '';
        assetSegments?.forEach((item, index) => {
          segmentsText +=
            assetSegments.length - 1 === index
              ? `${capitalizeOnlyFirstLetter(item.segment?.name)}`
              : `${capitalizeOnlyFirstLetter(item.segment?.name)}, `;
        });

        return <div>{segmentsText}</div>;
      },
    },
  ];

  useEffect(() => {
    const normalizedData = datasource.map((value, index) => ({
      ...value,
      key: `${index}`,
    }));

    setData(normalizedData);
  }, [datasource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newData = [...data];
      setData(prev => {
        const activeIndex = prev.findIndex(i => i.key === active.id);
        const overIndex = prev.findIndex(i => i.key === over?.id);
        newData = arrayMove(prev, activeIndex, overIndex);
        return newData;
      });

      changeOrder(newData);
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map(i => i.key)}
        strategy={verticalListSortingStrategy}
      >
        {!!data.length && (
          <Table
            pagination={false}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey='key'
            columns={columns}
            dataSource={data}
            bordered
          />
        )}
      </SortableContext>
    </DndContext>
  );
};

export default OrderTableAssets;
