import { Image } from 'antd';
import styled from 'styled-components';

export const Body = styled.div``;

export const Title = styled.div`
  color: #525252;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
`;

export const Description = styled.div`
  color: #525252;
  font-size: 14px;
  font-weight: 400;
  padding-top: 4px;
  margin-bottom: 24px;
  line-height: 17.5px;
`;

export const CustomImage = styled(Image)`
  border-radius: 8px;
`;
