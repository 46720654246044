// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { Modal } from '@models';
import { base } from '@service/base';

async function saveModal(
  props: Omit<Modal.IModal, 'id'>
): Promise<Modal.IModal> {
  const { data } = await base({
    baseURL: enviroments.BASE_URL,
    url: `/modal`,
    method: 'POST',
    data: {
      ...props,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export default saveModal;
