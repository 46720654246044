import { Steps } from 'antd';
import { FC } from 'react';

import { StepLgpdProps } from 'src/features/Lgpd/interfaces/lgpdInterfaces';
import { defineModal } from 'src/features/Lgpd/utils/definemodal';

export const StepModal: FC<StepLgpdProps> = ({ current, hasError }) => {
  return (
    <Steps
      labelPlacement='vertical'
      size='small'
      current={defineModal(current)}
      style={{ marginBlockEnd: '24px' }}
      items={[
        {
          style: { whiteSpace: 'nowrap' },
          title: 'Dados do usuário',
          status:
            current === 'stepOne' ? (hasError ? 'error' : 'process') : 'finish',
        },
        {
          style: { whiteSpace: 'nowrap' },
          title: 'Evidência dos dados',
        },
        {
          title: 'Dados anonimizados',
        },
      ]}
    />
  );
};
