/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@organisms';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AlertComponent } from 'src/app/components/antd/Alert';
import { TableComponent } from 'src/app/components/antd/TableComponent';

import * as badgesApi from '../api/badgesApi';
import { EventResponseDto } from '../dto/event-response.dto';
import { ModalAddEvent } from './ModalAddEvent';
import * as Styles from './styles';

export default function BadgesList() {
  const history = useHistory();

  const onClickRow = (item: badgesApi.EventType) => {
    history.push(`/social/gamefication/badges/${item.identifier}`);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dados, setDados] = useState<EventResponseDto[] | undefined>();

  useEffect(() => {
    listEvents();
  }, []);

  const listEvents = async () => {
    const res = await badgesApi.listEvents();
    setDados(res.data);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const showModal = () => {
    //setIsModalVisible(true);
  };

  return (
    <>
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Gestão de badges'}
          subTitle={''}
        />
      </Row>
      <Row>
        <Col span={3}>
          <Styles.ButtonInput
            icon={<PlusCircleOutlined />}
            type='primary'
            onClick={showModal}
          >
            Adicionar badge
          </Styles.ButtonInput>
        </Col>
        <Col offset={9} span={5}>
          <Styles.SelectInput placeholder='Selecionar data'></Styles.SelectInput>
        </Col>
        <Col span={6}>
          <Styles.SearchInput
            placeholder='Buscar por nome, email ou ID'
            suffix={<SearchOutlined />}
          />
        </Col>
      </Row>
      <TableComponent
        style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
          },
          {
            title: 'Identificador',
            dataIndex: 'identifier',
            sorter: true,
          },
          {
            title: 'Data da solicitação',
            dataIndex: 'description',
            sorter: true,
          },
          {
            title: 'Tipo',
            dataIndex: 'description',
            sorter: true,
          },
        ]}
        pageSize={7}
        data={dados}
        total={dados ? dados.length : 0}
        onClickRow={onClickRow}
      />

      <ModalAddEvent
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      {visible ? (
        <AlertComponent
          message='Badge cadastrado com sucesso'
          type='success'
          close={handleClose}
        />
      ) : null}
    </>
  );
}
