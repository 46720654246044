import toNoAccents from './toNoAccents';

const BASE_PATH =
  (process.env.REACT_APP_BASE_URL || '').indexOf('apidev') === -1
    ? 'https://play.queimadiaria.com'
    : 'https://dev.queimadiaria.com';

function hyphenCaseWords(string: string) {
  return toNoAccents(string.trim())
    .replace(/[. ,:-]+/g, '-')
    .toLowerCase();
}
function removeEveryNonNumberOrLetterOrHyphen(str: string) {
  return str.replace(/[^A-Za-z0-9-]/g, '');
}
function normalizePathname(string: string) {
  return removeEveryNonNumberOrLetterOrHyphen(hyphenCaseWords(string));
}
interface ProgramParams {
  name: string;
  id: number | string;
}
export const setProgramRoute = (program: ProgramParams) => {
  const programName = normalizePathname(program.name);
  return `${BASE_PATH || ''}/program/${programName}-${program.id}`;
};
