import styled from 'styled-components';

export const DisplayFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262626;
  width: 160px;
  height: 160px;
  border: 2px solid #434343;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
`;

export const ContentFlow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  > p {
    margin: 0;
    padding: 0;

    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  > span {
    width: 100%;
    margin: 0;
    padding: 0;
    //styleName: SM/Normal;
    font-family: SF Pro Text;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #dbdbdb;
  }
`;

export const BottomFlow = styled.div`
  display: flex;
  justify-content: space-evenly;
  > button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 4px;
    transition: background-color 0.7s;

    :hover {
      cursor: pointer;
      background-color: #ffffff33;
    }
  }
`;
