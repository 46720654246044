import { IBadgeGroupRequestDto } from '@core/gamefication/dto/badge-group/badge-group-request.dto';
import { slices } from '@core/redux';
import { Divider, Form } from 'antd';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { useGetAllCompetitions } from '../hooks/use-get-all-competitions';
import { useGetAllEvents } from '../hooks/use-get-all-events';
import { useGetBadgeGroupFormData } from '../hooks/use-get-badge-group-form-data';
import { BadgeGroupDetailsTitle } from './badge-group-details.tsx/badge-group-details-title';
import { BadgeGroupForm } from './badge-group-form';
import { BadgeListCollapse } from './badge-list-collapse';

interface UseParamsIdentifier {
  id: string;
}

export const BadgeGroupDetails: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id }: UseParamsIdentifier = useParams();

  const [listEvents] = useGetAllEvents();
  const [listCompetitions] = useGetAllCompetitions();

  const {
    formData,
    disabledForm,
    loading,
    editFormData,
    enableEditBadgeGroup,
    discardFormDataChanges,
    saveFormEdition,
  } = useGetBadgeGroupFormData(id);

  useEffect(() => {
    return () => {
      dispatch(slices.gamefication.clearBadgeOnEdition());
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(slices.gamefication.badgeSlice.getFullBadgeGroupsById(+id));
    }
  }, [id]);

  useEffect(() => {
    if (formData?.id && !id) {
      history.push(`/social/gamefication/badges/groups/${formData.id}`);
    }
  }, [formData]);

  const handleEditBadgeGroupClick = () => {
    enableEditBadgeGroup();
  };

  const handleSaveBadgeGroupClick = async () => {
    saveFormEdition();
  };

  const handleDiscardBadgeGroupClick = () => {
    discardFormDataChanges();
  };

  const handleDeleteBadgeGroupClick = () => {
    OpenNotification(false, 'Grupo de badge', 'Grupo removido com sucesso!');
    history.push(`/social/gamefication/badges/groups`);
  };

  const handleEditLocalyBadgeGroup = (value: IBadgeGroupRequestDto) => {
    editFormData(value);
  };

  return (
    <>
      <BadgeGroupDetailsTitle
        formData={formData}
        disabledForm={!disabledForm}
        loading={loading}
        onEditClick={handleEditBadgeGroupClick}
        onSaveClick={handleSaveBadgeGroupClick}
        onDiscardClick={handleDiscardBadgeGroupClick}
        onDeleteClick={handleDeleteBadgeGroupClick}
      />
      <Form style={{ width: '100%' }} layout='horizontal'>
        <BadgeGroupForm
          loadingBadgeGroup={loading}
          formData={formData}
          disabledForm={!disabledForm}
          setFormData={handleEditLocalyBadgeGroup}
          listCompetitions={listCompetitions}
        />
        <Divider />

        <BadgeListCollapse
          loadingBadgeGroup={loading}
          badgeGroup={formData}
          listEvents={listEvents}
        />
      </Form>
    </>
  );
};
