import './clientDetails.css';

import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Select } from 'antd';
import { FC, useEffect, useState } from 'react';

import { DatePicker } from 'src/app/components/antd';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import useDashboardEmails from 'src/features/clients/hooks/useDashboardEmails';
import { ClientType } from 'src/features/clients/types/clientTypes';
const { Option } = Select;

type LayoutType = Parameters<typeof Form>[0]['layout'];

export type UserProps = {
  dados?: ClientType;
};

export const ClientDashboardTabEmails: FC<UserProps> = ({ dados }) => {
  const [form] = Form.useForm();
  const [formLayout] = useState<LayoutType>('inline');
  const [firstDateEmails, setfirstDateEmails] = useState<Date | undefined>();
  const [lastDateEmails, setlastDateEmails] = useState<Date | undefined>();

  const [firstDateEmailsToUri, setfirstDateEmailsToUri] = useState<
    Date | undefined
  >();
  const [lastDateEmailsToUri, setlastDateEmailsToUri] = useState<
    Date | undefined
  >();

  const [subject, setsubject] = useState<string>();
  const [statusEmail, setStatusEmail] = useState<string>();
  const [page, setPage] = useState<number>(0);

  const [filterSubject, setFilterSubject] = useState<string>('');

  const { data, isLoading } = useDashboardEmails(
    dados?.id,
    page,
    firstDateEmailsToUri,
    lastDateEmailsToUri,
    filterSubject,
    statusEmail
  );
  const timeoutTime = 1500;
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (subject !== undefined) {
        setFilterSubject(subject);
      }
    }, timeoutTime);
    return () => clearTimeout(timeOut);
  }, [subject]);

  useEffect(() => {
    if (firstDateEmails && lastDateEmails) {
      setfirstDateEmailsToUri(firstDateEmails);
      setlastDateEmailsToUri(lastDateEmails);
    }
  }, [firstDateEmails, lastDateEmails]);

  const onChangePage = (onChangePageParam: number) => {
    setPage(onChangePageParam);
  };

  return (
    <>
      <h1>E-mails enviados</h1>
      <Form
        layout='inline'
        form={form}
        style={{ marginBottom: 10 }}
        initialValues={{ layout: formLayout }}
      >
        <Col span={6}>
          <Form.Item>
            <Input
              data-testid='input-subject'
              onChange={e => setsubject(e.target.value)}
              value={subject}
              placeholder='Buscar por assunto'
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            style={{ display: 'contents !important' }}
            label='E-mails de'
          >
            <DatePicker
              placeholder='Selecionar data'
              format='dd/MM/yyyy'
              data-testid='initialDate'
              value={firstDateEmails}
              onChange={initialDateEmail =>
                setfirstDateEmails(initialDateEmail || undefined)
              }
              style={{ float: 'right', width: '100%' }}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label='a'>
            <DatePicker
              placeholder='Selecionar data'
              format='dd/MM/yyyy'
              data-testid='end-date'
              value={lastDateEmails}
              onChange={lastDateEmail =>
                setlastDateEmails(lastDateEmail || undefined)
              }
              style={{ float: 'right', width: '100%' }}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Select
            placeholder='Filtrar status'
            onChange={value => setStatusEmail(value?.toString())}
            style={{ width: '100%' }}
            data-testid='select'
          >
            {data?.status?.map((item: string) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </Col>
      </Form>
      <TableComponent
        loading={isLoading}
        pageSize={10}
        data-testid='table'
        columns={[
          {
            title: 'Data',
            dataIndex: 'datahora',
            render: (datahora: string) => (
              <div>{dateFormatted(datahora, true)}</div>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
          },
          {
            title: 'E-mail Queima Diaria',
            dataIndex: 'emailbyqueima',
          },
          {
            title: 'E-mail cliente',
            dataIndex: 'emailclient',
          },
          {
            title: 'Assunto',
            dataIndex: 'subject',
          },
        ]}
        data={data?.emails}
        total={data?.emails.length}
        onChangePage={onChangePage}
      />
    </>
  );
};
