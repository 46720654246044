import { AxiosRequestConfig } from 'axios';

export async function addAuthorizationHeaderInterceptor(
  config: AxiosRequestConfig
) {
  const { store } = await import('@core/redux');
  const token = store.getState().auth.token;
  config.headers.Authorization = token;

  return config;
}
