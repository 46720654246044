import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';
import { IBadgeGroupResponseDto } from 'src/core/gamefication/dto/badge-group/badge-group-response.dto';
import { IBadgeRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export interface UptdateBadgePayload {
  index: number;
  badge: IBadgeRequestDto;
}

export const getFullBadgeGroupsById = createAsyncThunk(
  'gamification/badgeGroups/getBadgeGroupsById',

  async (badgeGroupId: number, { rejectWithValue }) => {
    try {
      const {
        data: badgeGroupData,
      }: DataResponseApiDto<IBadgeGroupResponseDto> = await badgesGroupsApi.getBadgeGroupById(
        badgeGroupId
      );

      const { data: badges } = await badgesGroupsApi.getBadgesByBadgeGroupApi(
        badgeGroupData.id
      );

      return {
        ...badgeGroupData,
        badges,
      } as IBadgeGroupRequestDto;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const badgeGroupsBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(getFullBadgeGroupsById.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(getFullBadgeGroupsById.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
    state.badgeGroup = payload as IBadgeGroupRequestDto;
  });

  builder.addCase(getFullBadgeGroupsById.rejected, state => {
    state.status = 'ERROR';
  });
};
