import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

interface PutAssetsBySegmentRelationParams {
  values: Array<{ idAsset: string }>;
  segmentId: string;
}
export async function putAssetsBySegmentRelation({
  values,
  segmentId,
}: PutAssetsBySegmentRelationParams): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/assets/relations/by-segment-id/${segmentId}`,
    method: 'PUT',
    data: values,
  });

  return data;
}
