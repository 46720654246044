import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { IBadgeRuleCompetitionEventRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export const getBadgeCompetitionEventList = createAsyncThunk(
  'gamification/badgeGroups/getBadgeCompetitionEventList',
  async (badgeId: number, { rejectWithValue }) => {
    try {
      const {
        data: competitiveEvents,
      }: DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto[]> =
        await badgesGroupsApi.getCompetitionEventsByBadgeId(badgeId);

      return competitiveEvents as IBadgeRuleCompetitionEventRequestDto[];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const badgeCompetitionEventListBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(getBadgeCompetitionEventList.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(
    getBadgeCompetitionEventList.fulfilled,
    (state, { payload }) => {
      state.status = 'FULFILLED';
      state.badgeOnFocusCompetitiveEventsList =
        payload as IBadgeRuleCompetitionEventRequestDto[];
    }
  );

  builder.addCase(getBadgeCompetitionEventList.rejected, state => {
    state.status = 'ERROR';
  });
};
