import { Typography } from 'antd';
import { FC } from 'react';

import { InputAreaContent } from './styles';

interface CouponFormSectionProps {
  title: string;
  direction?: 'column' | 'row';
}
export const CouponFormSection: FC<CouponFormSectionProps> = ({
  title,
  children,
  direction = 'column',
}) => {
  return (
    <InputAreaContent>
      <header>
        <Typography style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
          {title}
        </Typography>
      </header>
      <div style={{ display: 'flex', flexDirection: direction, gap: 24 }}>
        {children}
      </div>
    </InputAreaContent>
  );
};
