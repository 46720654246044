import { InputNumber } from 'antd';
import { FC } from 'react';

interface Props {
  value?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

export const BadgeOcorrenciaTableCell: FC<Props> = ({
  value,
  disabled,
  onChange,
}) => {
  const handleChange = (changedValue: number | null) => {
    onChange?.(changedValue || 0);
  };

  if (!disabled) {
    return (
      <InputNumber
        min={0}
        defaultValue={value || 0}
        name='amount'
        style={{ width: '100%' }}
        onChange={handleChange}
      />
    );
  }

  return <p>{value || 0}</p>;
};
