import styled from 'styled-components';

import { CustomComponetProps } from './InlineFilter.interface';

export const Container = styled.div`
  display: flex;
  justify-content: end;
`;

export const DatepickerContainer = styled.div<CustomComponetProps>`
  width: 100%;
  margin-right: 10px;
  min-width: 4rem;

  &:first-child {
    margin-left: 0;
  }
`;
export const SelectStatusContainer = styled.div<CustomComponetProps>`
  min-width: 4rem;
  margin-right: 10px;
  width: 100%;
`;
export const SearchInputContainer = styled.div<CustomComponetProps>`
  min-width: 4rem;
  width: 100%;
`;

export const CustomContainer = styled.div<CustomComponetProps>`
  min-width: 4rem;
  width: 100%;
`;
