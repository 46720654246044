import { IBadgeGroupUpdateRequestDto } from '@core/gamefication/dto/badge-group/badge-group-update-request.dto';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

interface IUpdateBadgeGroup extends IBadgeGroupUpdateRequestDto {
  id: number;
}

export const updateBadgeGroup = createAsyncThunk(
  'gamification/badgeGroup/updateBadgeGroup',
  async (params: IUpdateBadgeGroup, { rejectWithValue }) => {
    try {
      if (!params.id) {
        OpenNotification(
          true,
          'Grupo de badge',
          'Nenhum grupo de badge informado.'
        );
        return;
      }

      const response = await badgesGroupsApi.update(params.id, {
        name: params.name,
        hideName: params.hideName,
        order: params.order,
      });

      OpenNotification(
        false,
        'Grupo de badge',
        'Grupo atualizado com sucesso!'
      );

      return response;
    } catch (error) {
      OpenNotification(true, 'Grupo de badge', 'Erro ao atualizar o grupo.');
      return rejectWithValue(error);
    }
  }
);

export const updateBadgeGroupBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(updateBadgeGroup.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(updateBadgeGroup.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
  });

  builder.addCase(updateBadgeGroup.rejected, state => {
    state.status = 'ERROR';
  });
};
