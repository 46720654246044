import { IBadgeGroupResponseDto } from '@core/gamefication/dto/badge-group/badge-group-response.dto';
import { slices } from '@core/redux';
import { events } from '@service/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface BadgeGroupFilter {
  name?: string;
  competitionId?: number;
}

export const useBadgeGroup = () => {
  const dispatch = useDispatch();
  const [badgeGroupsList, setBadgeGroupList] =
    useState<IBadgeGroupResponseDto[]>();

  const getAllBadgeGroups = async (filter?: BadgeGroupFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await events.getAllBadgeGroups(filter);
      setBadgeGroupList(response.rows);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const filterBadgeGroups = (filter: BadgeGroupFilter) => {
    let filterProps: Record<string, any> = {
      name: filter.name
        ? { iLike: `$${filter.name.replace(' ', '$')}$` }
        : undefined,
    };
    if (!!filter.competitionId && filter.competitionId >= 0) {
      filterProps = {
        ...filterProps,
        competition: { id: filter.competitionId },
      };
    }
    getAllBadgeGroups(filterProps as BadgeGroupFilter);
  };

  return {
    getAllBadgeGroups,
    badgeGroupsList,

    filterBadgeGroups,
  };
};
