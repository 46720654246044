import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { TransactionPagarMeResponse } from '../types/clientTypes';

const fetchTransactions = async (
  emailParamTransaction?: string | undefined
) => {
  if (emailParamTransaction === undefined) {
    return null;
  }
  const response = await clientApi.listTransactionsPagarme(
    emailParamTransaction
  );
  if (!response) {
    return;
  }
  if ((response as TransactionPagarMeResponse).status === Success) {
    return (response as TransactionPagarMeResponse).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as transações',
      'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useTransactionsPagarMe(email?: string | undefined) {
  return useQuery(['transactions', email], () => fetchTransactions(email), {
    refetchOnWindowFocus: false,
  });
}
