import React from 'react';

import * as S from './ButtonContainer.style';
interface ButtonContainerProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}
export const ButtonContainer: React.FC<ButtonContainerProps> = ({
  children,
  disabled,
  onClick,
}) => {
  return (
    <S.Button disabled={disabled} onClick={onClick}>
      {children}
    </S.Button>
  );
};
