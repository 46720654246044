import { Form, Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';
const { Item: FormItem } = Form;

const { TextArea } = Input;

interface ControllerProps extends TextAreaProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  autoComplete?: string;
  label?: string;
  required?: boolean;
  isMaxLength?: boolean;
  maxLength?: number;
  row?: number;
  isShowCounter?: boolean;
  showCount?: boolean;
}

export const TextAreaController: React.FC<ControllerProps> = ({
  control,
  defaultValue,
  name,
  placeholder,
  autoComplete,
  label,
  required = false,
  isMaxLength = true,
  maxLength = 250,
  row = 1,
  isShowCounter = true,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : undefined}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        return (
          <FormItem
            validateStatus={status}
            help={help}
            label={label}
            {...(required && { required: required })}
          >
            <TextArea
              {...rest}
              {...field}
              placeholder={placeholder}
              data-testid={`${name}-textarea-controller`}
              autoComplete={autoComplete}
              rows={row}
              {...(isMaxLength && {
                maxLength: maxLength,
              })}
              {...(isShowCounter &&
                isMaxLength && {
                  showCount: {
                    formatter: ({ value, count, maxLength }) =>
                      `${count}/${maxLength} caracteres`,
                  },
                })}
            />
          </FormItem>
        );
      }}
    />
  );
};
