import { BadgeIcon } from '@assets/icons/badge';
import { CurrencyExchangeIcon } from '@assets/icons/currencyExchange';
import { DevicesIcon } from '@assets/icons/devices';
import { FitnessCenterIcon } from '@assets/icons/fitnessCenter';
import { HouseIcon } from '@assets/icons/house';
import { IphoneIcon } from '@assets/icons/iphone';
import { LoginIcon } from '@assets/icons/login';
import { NutritionIcon } from '@assets/icons/nutrition';
import { TabletIcon } from '@assets/icons/tablet';
import { TvIcon } from '@assets/icons/tv';
import { WatchIcon } from '@assets/icons/watch';
import { Assets } from '@models';

import { DeviceTypeEnum } from 'src/models/BannerDetail.model';

export const ASSETS_TYPE_LIST = [
  {
    value: Assets.AssetContext.BACKGROUND_LOGIN,
    title: 'Background Login',
    icon: <LoginIcon />,
    disabled: true,
  },
  {
    value: Assets.AssetContext.HOME,
    title: 'Banner Home',
    icon: <HouseIcon />,
  },
  {
    value: Assets.AssetContext.INDIQUE_GANHE,
    title: 'Banner Indique e Ganhe',
    icon: <CurrencyExchangeIcon />,
    disabled: true,
  },
  {
    value: Assets.AssetContext.NUTRI,
    title: 'Banner +Q Nutri',
    icon: <NutritionIcon />,
    disabled: true,
  },
  {
    value: Assets.AssetContext.BADGES,
    title: 'Banner Medalhas',
    icon: <BadgeIcon />,
    disabled: true,
  },
  {
    value: Assets.AssetContext.COACH,
    title: 'Banner Treinador',
    icon: <FitnessCenterIcon />,
    disabled: true,
  },
];

export const DEVICE_TYPE_LIST = [
  {
    value: DeviceTypeEnum.WEB_PLATFORM,
    title: 'Plataforma web',
    icon: <DevicesIcon />,
  },
  {
    value: DeviceTypeEnum.APP,
    title: 'Aplicativo',
    icon: <IphoneIcon />,
  },
  {
    value: DeviceTypeEnum.SMART_TV,
    title: 'Smart TV',
    icon: <TvIcon />,
    disabled: true,
  },
  {
    value: DeviceTypeEnum.TABLET,
    title: 'Tablet',
    icon: <TabletIcon />,
    disabled: true,
  },
  {
    value: DeviceTypeEnum.SMARTWATCH,
    title: 'Smartwatch',
    icon: <WatchIcon />,
    disabled: true,
  },
];
