import { MinusCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
export const InctiveTag: React.FC = () => {
  return (
    <Tag icon={<MinusCircleOutlined />} color='default'>
      Inativo
    </Tag>
  );
};
