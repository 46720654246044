/* eslint-disable @typescript-eslint/no-explicit-any */
import { Switch } from 'antd';

import { PermissionNameListContainer, PermissionNameListItem } from './styles';
export default function Permissions() {
  const permissionTypes = [
    'Deleção de usuário',
    'Acesso ao controle de programa',
    'Acesso ao controle de assets',
    'Co-Cadastrar usuário',
    'Criar novos perfis',
    'Editar usuário',
    'Atribuir perfis a usuários',
    'Atribuir permissões a perfis',
  ];

  function onChange(checked: unknown) {
    console.log(`switch to ${checked}`);
  }

  return (
    <>
      <PermissionNameListContainer>
        {permissionTypes.map(item => {
          return (
            <PermissionNameListItem>
              <div>
                <p>{item}</p>
                <span>Descrição da permissão</span>
              </div>
              <Switch defaultChecked onChange={onChange} />
            </PermissionNameListItem>
          );
        })}
      </PermissionNameListContainer>
    </>
  );
}
