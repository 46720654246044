import { RootState, slices } from '@core/redux';
import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyObject } from 'yup/lib/types';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import {
  IBadgeRequestDto,
  IBadgeRuleCompetitionEventRequestDto,
} from 'src/core/gamefication/dto/badge-group/badge-request.dto';

export const useBadge = (badge: IBadgeRequestDto) => {
  const {
    status,
    badgeGroup,
    badgeOnFocusCompetitiveEventsList,
    badgeEditingId,
    badgeExpandedKey,
  } = useSelector((state: RootState) => state.gamification);

  const dispatch = useDispatch();

  const [badgeFormData, setBadgeFormData] = useState<IBadgeRequestDto>(badge);
  const [hasBadgeFormEditMode, setHasBadgeFormEditMode] = useState<boolean>(
    badge.id === badgeEditingId
  );

  const [badgeFormDataCache, setBadgeFormDataCache] =
    useState<IBadgeRequestDto>(badge);

  const formHookToCompetitionEvents = async (
    formHook: FormInstance<AnyObject>
  ) => {
    const changedValues = await formHook.validateFields();
    const normalizedValues: AnyObject = {};
    Object.keys(changedValues).forEach(key => {
      const propertyName = key.split('-')[0];
      const badgeGroupId: string = key.split('-')[1];

      if (!normalizedValues[badgeGroupId]) {
        normalizedValues[badgeGroupId] = {};
      }

      normalizedValues[badgeGroupId][propertyName] = changedValues[key];
    });

    const updatedValue = badgeFormData?.competitionEvents?.map(
      competitionEvent => {
        if (!competitionEvent?.id) return false;

        const groupId = competitionEvent.id;
        const updatedCompetitionEvent = {
          ...competitionEvent,
          ...normalizedValues[groupId],
        };

        return updatedCompetitionEvent;
      }
    );

    return updatedValue;
  };

  useEffect(() => {
    setBadgeFormData({
      ...badge,
      competitionEvents: badgeOnFocusCompetitiveEventsList,
    });

    setBadgeFormDataCache({
      ...badgeFormDataCache,
      competitionEvents: badgeOnFocusCompetitiveEventsList,
    });
  }, [badgeOnFocusCompetitiveEventsList]);

  useEffect(() => {
    if (badgeExpandedKey?.split('-')[1] !== badge.id.toString()) return;
    if (badge.isNew) return;
    dispatch(
      slices.gamefication.badgeSlice.getBadgeCompetitionEventList(
        badgeFormData.id
      )
    );
  }, [badgeExpandedKey]);

  const discardBadgeFormChanges = () => {
    dispatch(slices.gamefication.clearBadgeOnEdition());
    setBadgeFormData(badgeFormDataCache);
    setHasBadgeFormEditMode(false);
  };

  const editBadgeFormData = (value: IBadgeRequestDto) => {
    setBadgeFormData(value);
  };

  const enableEditMode = () => {
    dispatch(slices.gamefication.editingBadge(badge.id));
    setHasBadgeFormEditMode(true);
  };

  const editBadgeEvent = (
    badgeEvent: IBadgeRuleCompetitionEventRequestDto[]
  ) => {
    setBadgeFormData({
      ...badgeFormData,
      competitionEvents: badgeEvent,
    });
  };

  const saveAllBadge = async (
    eventReactHookForm: FormInstance<IBadgeRuleCompetitionEventRequestDto>
  ) => {
    if (!badgeGroup?.competition?.id) {
      OpenNotification(true, 'Competição não encontrada');
      return;
    }
    const badgeToSave = await formHookToCompetitionEvents(eventReactHookForm);

    dispatch(
      slices.gamefication.badgeSlice.saveFullBadgeEdition({
        competitionId: badgeGroup.competition.id || 0,
        badgeGroupId: badgeGroup.id,
        badge: {
          ...badgeFormData,
          competitionEvents: badgeToSave,
        },
      })
    );

    if (status !== 'ERROR') {
      setBadgeFormDataCache(badgeFormData);
    }

    setHasBadgeFormEditMode(false);
  };

  return {
    badgeFormData,
    hasBadgeFormEditMode,
    saveAllBadge,
    enableEditMode,
    editBadgeEvent,
    editBadgeFormData,
    discardBadgeFormChanges,
  };
};
