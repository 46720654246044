import { Col, Form, Input, Row } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

type ModalProps = {
  loading?: boolean;
  error?: string;
  email?: string;
  setErrorEmailRecoveryAccess?: Dispatch<SetStateAction<string>>;
  setEmailRecoveryAccess?: Dispatch<SetStateAction<string>>;
  setConfirmarButtonDisabled?: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
};

export const ModalRecoveryAccessEmailStep: FC<ModalProps> = ({
  loading = false,
  error = '',
  email = '',
  setEmailRecoveryAccess,
  setErrorEmailRecoveryAccess,
  onConfirm,
  setConfirmarButtonDisabled,
}) => {
  const [statusEmail, setStatusEmail] = useState<ValidateStatus>('warning');
  const validaStatusEmail = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const isWarning = ((email && email === '') ||
      !email ||
      (email && !emailRegex.test(email))) as boolean;
    const isError = (error && error !== '') as boolean;
    setStatusEmail(isWarning ? 'warning' : isError ? 'error' : 'success');
    if (setConfirmarButtonDisabled) {
      setConfirmarButtonDisabled(isWarning || isError);
    }
  };
  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (setErrorEmailRecoveryAccess) {
      setErrorEmailRecoveryAccess('');
    }
    if (setEmailRecoveryAccess) {
      setEmailRecoveryAccess(e.currentTarget.value);
    }
    validaStatusEmail();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onConfirm && e.key === 'Enter') {
      onConfirm();
    }
  };
  useEffect(() => validaStatusEmail(), [email, error]);

  const showWarningErrorContent = (): JSX.Element | undefined => {
    const isWarning = statusEmail === 'warning';
    if (email && email !== '' && (isWarning || (error && error !== ''))) {
      return (
        <span
          data-testid='input-error-email'
          style={{
            marginTop: 5,
            color: isWarning ? '#D87A16' : '#D32029',
          }}
        >
          {isWarning
            ? 'E-mail digitado incorretamente. Por favor, verifique e tente novamente.'
            : error}
        </span>
      );
    }
    return undefined;
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ width: '100%' }}>
          <p
            className='text-silver-chalice'
            style={{
              backgroundColor: '#1F1F1F',
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            Sem problemas! Informe abaixo o seu e-mail cadastrado para receber
            as instruções de recuperação de senha.
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ width: '100%' }}>
          <Form layout='vertical'>
            <Form.Item
              style={{
                marginBottom: statusEmail !== 'success' ? 5 : 0,
              }}
              hasFeedback={true}
              validateStatus={statusEmail}
              label='E-mail'
              tooltip='Atenção: Digite o mesmo e-mail utilizado para entrar na plataforma.'
            >
              <Input
                disabled={loading}
                placeholder='Atenção: Digite o mesmo e-mail utilizado para entrar na plataforma.'
                data-testid='input-email'
                autoComplete='off'
                onChange={onInputChange}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            {showWarningErrorContent()}
          </Form>
        </Col>
      </Row>
    </>
  );
};
