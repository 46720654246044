import { guestApi } from 'src/app/guestApi';
import { adyenCancelAutoRescueResponse } from 'src/constants/errors';

import { IAdyenAutoRescueApiDto } from '../types/adyenAutoRescueApiDto';
import { IAdyenRefundReportApiDto } from '../types/adyenRefundApiReportDto';

async function adyenRefundReportData(
  reportIdentifier: string,
  email: string
): Promise<IAdyenRefundReportApiDto | unknown> {
  try {
    const endpoint = `/customer/adyen/report/${reportIdentifier}?email=${email}`;
    return guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function adyenCancelAutoRescue(
  subscriptionId: number
): Promise<IAdyenAutoRescueApiDto> {
  try {
    const endpoint = `/customer/adyen/auto-rescue/cancel/${subscriptionId}`;
    const response = await guestApi.put(endpoint);
    if (!response?.data) {
      return {
        error: adyenCancelAutoRescueResponse.ERROR,
      };
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return { error: adyenCancelAutoRescueResponse.ERROR };
  }
}

export const customerAdyenReportApi = {
  adyenRefundReportData,
  adyenCancelAutoRescue,
};
