import { IEventCount } from '@core/gamefication/dto/event/event-response.dto';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';
import { AxiosResponse } from 'axios';

import { gameficationApi } from 'src/app/gameficationApi';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { IDefaultIdNameResponseDto } from 'src/core/dto/default-id-name-response.dto';
import { ResponseApiDto } from 'src/core/dto/response-api.dto';
import { IBadgeCompetitionEventCreateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-competition-event-create-request.dto';
import { IBadgeCompetitionEventUpdateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-competition-event-update-request.dto';
import { IBadgeCreateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-create-request.dto';
import { IBadgeGroupResponseDto } from 'src/core/gamefication/dto/badge-group/badge-group-response.dto';
import { IBadgeGroupUpdateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-update-request.dto';
import {
  IBadgeRuleCompetitionEventRequestDto,
  IBadgeRuleRequestDto,
  IBadgeRuleResponseDto,
} from 'src/core/gamefication/dto/badge-group/badge-request.dto';
import { IBadgeResponseDto } from 'src/core/gamefication/dto/badge-group/badge-response.dto';
import { IBadgeRuleCreateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-rule-create-request.dto';
import { IBadgeRuleUpdateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-rule-update-request.dto';
import { IBadgeUpdateRequestDto } from 'src/core/gamefication/dto/badge-group/badge-update-request.dto';
import { IBadgeImageResponseDto } from 'src/core/gamefication/dto/badge-image/badge-response.dto';
import { ICompetitionResumeResponseDto } from 'src/core/gamefication/dto/competition/competition-resume-response.dto';

import { IBadgeGroupCreateRequestDto } from '../../../../core/gamefication/dto/badge-group/badge-group-create-request.dto';

export async function getBadgeImagens(): Promise<
  DataResponseApiDto<Array<IBadgeImageResponseDto>>
> {
  const url = `/badge/image`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IBadgeImageResponseDto>>
  >(url);

  return response.data;
}

export async function getEvents(): Promise<IEventCount> {
  const url = `/event`;
  const { data } = await gameficationApi.get<IEventCount>(url);
  return data;
}

export async function getCompetitions(): Promise<
  DataResponseApiDto<Array<ICompetitionResumeResponseDto>>
> {
  const url = `/competition/resume`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<ICompetitionResumeResponseDto>>
  >(url);
  return response.data;
}

export async function getCompetitionEventType(): Promise<
  DataResponseApiDto<Array<IDefaultIdNameResponseDto>>
> {
  const url = `/competition/event-type`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IDefaultIdNameResponseDto>>
  >(url);

  if (!response.data) {
    throw new Error('Erro ao buscar tipos de eventos');
  }

  return response.data;
}

export async function listBadgeRules(
  badgeId: number
): Promise<DataResponseApiDto<Array<IBadgeRuleResponseDto>>> {
  const url = `/badge/${badgeId}/rule`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IBadgeRuleResponseDto>>
  >(url);
  return response.data;
}

export async function getCompetitionEventsByBadgeId(badgeId: number) {
  const url = `/competition/event/badge/${badgeId}`;
  const response = await gameficationApi.get<
    DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto[]>
  >(url);
  return response.data;
}

export async function getCompetitionEventsByBadgeRuleId(badgeRuleId: number) {
  const url = `/competition/event/badge/rule/${badgeRuleId}`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IBadgeRuleCompetitionEventRequestDto>>
  >(url);
  return response.data;
}

export async function getBadgesRulesByBadgeGroupApi(badgeGroupId: number) {
  const url = `/badge/rules?badgeGroupId=${badgeGroupId}`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IBadgeRuleResponseDto>>
  >(url);
  return response.data;
}

export async function getBadgesByBadgeGroupApi(badgeGroupId: number) {
  const url = `/badge?badgeGroupId=${badgeGroupId}`;
  const response = await gameficationApi.get<
    DataResponseApiDto<Array<IBadgeResponseDto>>
  >(url);
  return response.data;
}

export async function getBadgeGroupById(
  id: number
): Promise<DataResponseApiDto<IBadgeGroupResponseDto>> {
  const url = `/badge/group/` + id;
  const response = await gameficationApi.get<
    DataResponseApiDto<IBadgeGroupResponseDto>
  >(url);
  return response.data;
}

export async function create(
  param: IBadgeGroupCreateRequestDto
): Promise<DataResponseApiDto<IBadgeGroupResponseDto>> {
  const url = `/badge/group`;
  const response = await gameficationApi.post<
    DataResponseApiDto<IBadgeGroupResponseDto>
  >(url, param);

  if (!response.data) {
    throw new Error('Erro ao criar grupo de badges');
  }
  return response.data;
}

export async function deleteBadgeGroup(id: number): Promise<ResponseApiDto> {
  const BANNER_MS_BASE_URL = enviroments.BASE_URL;
  const url = `/badge/toggle-activated/${id}`;
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url,
    method: 'POST',
  });

  return data;
}

export async function update(
  id: number,
  param: IBadgeGroupUpdateRequestDto
): Promise<DataResponseApiDto<IBadgeGroupResponseDto>> {
  const url = `/badge/group/${id}`;
  const response = await gameficationApi.put<
    DataResponseApiDto<IBadgeGroupResponseDto>
  >(url, param);
  return response.data;
}

export async function createBadge(
  param: IBadgeCreateRequestDto
): Promise<DataResponseApiDto<IBadgeResponseDto>> {
  const url = `/badge`;
  const response = await gameficationApi.post<
    DataResponseApiDto<IBadgeResponseDto>
  >(url, param);
  return response.data;
}

export async function updateBadge(
  id: number,
  param: IBadgeUpdateRequestDto
): Promise<DataResponseApiDto<IBadgeResponseDto>> {
  const url = `/badge/${id}`;
  const response = await gameficationApi.put<
    DataResponseApiDto<IBadgeResponseDto>
  >(url, param);
  return response.data;
}

export async function createBadgeRule(
  badgeId: number,
  param: IBadgeRuleCreateRequestDto
): Promise<DataResponseApiDto<IBadgeRuleRequestDto>> {
  const url = `/badge/${badgeId}/rule`;
  const response = await gameficationApi.post<
    DataResponseApiDto<IBadgeRuleRequestDto>
  >(url, param);
  return response.data;
}

export async function updateBadgeRule(
  badgeId: number,
  badgeRuleId: number,
  param: IBadgeRuleUpdateRequestDto
): Promise<AxiosResponse<DataResponseApiDto<IBadgeRuleRequestDto>>> {
  const url = `/badge/${badgeId}/rule/${badgeRuleId}`;
  const response = await gameficationApi.put<
    DataResponseApiDto<IBadgeRuleRequestDto>
  >(url, param);
  return response;
}

export async function createCompetitionEvent(
  param: IBadgeCompetitionEventCreateRequestDto
): Promise<DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto>> {
  const url = `/competition/event`;
  const response = await gameficationApi.post<
    DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto>
  >(url, param);

  if (!response.data) {
    throw new Error('Erro ao criar evento de competição');
  }
  return response.data;
}

export async function updateCompetitionEvent(
  id: number,
  param: IBadgeCompetitionEventUpdateRequestDto
): Promise<DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto>> {
  const url = `/competition/event/${id}`;
  const response = await gameficationApi.put<
    DataResponseApiDto<IBadgeRuleCompetitionEventRequestDto>
  >(url, param);

  if (!response.data) {
    throw new Error('Erro ao atualizar evento de competição');
  }

  return response.data;
}

export async function deleteCompetitionEvent(
  id: number
): Promise<ResponseApiDto> {
  const url = `/competition/event/${id}`;
  const response = await gameficationApi.delete<ResponseApiDto>(url);
  return response.data;
}
