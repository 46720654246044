import { UserAddOutlined } from '@ant-design/icons';
import { Events } from '@models';
import { PageListProps, PageListTemplate } from '@templates';
import { FC } from 'react';
import styled from 'styled-components';

import useControllerTable from '../hooks/useControllerTable';
import {
  RequestAnonymizeDTO,
  TableLgpdProps,
} from '../interfaces/lgpdInterfaces';
import { FilterLgpd } from './FilterLgpd';
import { SandwichMenuTable } from './SandwichMenuTable';

export const TableLgpd: FC<TableLgpdProps> = ({ setShowModal, showModal }) => {
  const [tableData, filter, setFilter] = useControllerTable(showModal);
  const filteredData = tableData;

  const headerColumns: PageListProps<Events.ListEventsResponse>['columns'] = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
    },
    {
      key: 'customerName',
      title: 'Nome',
      dataIndex: 'customerName',
    },
    {
      key: 'customerEmail',
      title: 'Email',
      dataIndex: 'customerEmail',
    },
    {
      key: 'customerDocument',
      title: 'CPF',
      dataIndex: 'customerDocument',
    },
    {
      key: 'customerPhone',
      title: 'Telefone',
      dataIndex: 'customerPhone',
    },

    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: '',
      render: (item: RequestAnonymizeDTO) => {
        return <SandwichMenuTable consultLgpd={item} />;
      },
    },
  ];

  return filteredData ? (
    <>
      <PageListTemplate
        filterComponent={
          <FilterLgpd
            setModalVisibility={setShowModal}
            setFilter={setFilter}
            filter={filter}
          />
        }
        columns={headerColumns}
        data={{ count: filteredData.length, rows: filteredData }}
        loading={false}
      ></PageListTemplate>
    </>
  ) : (
    <EmptyContainer>
      <InfosContainer>
        <UserAddOutlined />
        <Title>Nenhum cadastro salvo</Title>
        <Text>
          Ainda não existem cadastros aqui. Você pode criar um novo, clicando no
          botão acima ”Adicionar”.
        </Text>
      </InfosContainer>
    </EmptyContainer>
  );
};

const InfosContainer = styled.div`
  max-width: 332px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
  min-height: 100%;
`;

const Text = styled.h1`
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #7d7d7d;
  text-align: center;
  font-weight: 400;
`;

const Title = styled.h1`
  margin-top: 8px;
  font-size: 18px;
  line-height: 19.8px;
  color: #7d7d7d;
  font-weight: 600;
  text-align: center;
`;
