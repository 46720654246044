import { OrderBump } from '@models';
import { base } from '@service/base';

type TCreateOrderBumpProps = Omit<
  OrderBump.IOrderBump,
  'id' | 'destinationOffer' | 'createdAt' | 'destinationOffer' | 'offers'
>;

export async function createOrderBump(data: TCreateOrderBumpProps) {
  const response = await base.post<OrderBump.IOrderBump>(`/order-bump`, data);

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
