import { env } from '../app/env';

export const createUrlForApi = (endpoint: string): string => `${env.BASE_URL}`;
export const createUrlForGameficationApi = (endpoint: string): string =>
  `${env.GAMEFICATION_URL}`;

export async function initBrowserMocksIfDev(): Promise<void> {
  if (!env.IS_DEVELOPMENT || (env.RUN_MOCKS && env.RUN_MOCKS === 'false')) {
    console.log('Mocks are not running');
    return;
  }

  const { worker } = await import('./browser');
  worker.start();
}
