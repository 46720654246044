import { OptionList } from '@atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRemoteCheckoutConfig } from '@service/api/remoteCheckoutConfig';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Control, useForm, UseFormHandleSubmit } from 'react-hook-form';
import * as yup from 'yup';

import { offerApi } from '../apis/offerApis';
import { createFormDataFromFormResponse } from '../utils/createFormDataFromFormResponse';
import { formOfferFactory } from '../utils/formOfferFactory';

export default function useFormOffer() {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const isEdit = type === 'edit';
  const idOffer = urlParams.get('id');
  const idProduct = urlParams.get('productId');
  const codePlanDuo = '43';
  const codePlanFamily = '44';
  const [isLoading, setIsLoading] = useState(false);
  const [isOfferStandard, setIsOfferStandard] = useState(false);
  const [hasCounter, sethasCounter] = useState(false);
  const [isDisplayCoupon, setIsDisplayCoupon] = useState(false);
  const [isAvailableNuPay, setIsAvailableNuPay] = useState(false);
  const [bgCounter, setBgCounter] = useState('#FFF');
  const [subscriptionPeriodOptions, setSubscriptionPeriodPeriodOptions] =
    useState<OptionList>([]);

  useEffect(() => {
    idOffer && getOffers(idOffer);
    if (subscriptionPeriodOptions.length === 0) getSubscriptionPeriods();
  }, [idOffer]);

  function defineTitlePage() {
    switch (type) {
      case 'view':
        return 'Visualizar Oferta';
      case 'edit':
        return 'Editar Oferta';
      default:
        return 'Criar Oferta';
    }
  }

  function definePlanType(accessProfileId?: string) {
    switch (String(accessProfileId)) {
      case codePlanDuo:
        return 'duo';
      case codePlanFamily:
        return 'family';
      default:
        return 'individual';
    }
  }

  async function getOffers(id: string) {
    setIsLoading(true);
    id &&
      (await offerApi.getOfferById(id).then(data => {
        const newOffer = formOfferFactory(data, definePlanType);
        reset(newOffer);
        !newOffer?.endDate && setIsOfferStandard(true);
        setIsLoading(false);
      }));
  }

  async function getSubscriptionPeriods() {
    const configs = await getRemoteCheckoutConfig();
    const options = configs.subscriptionPeriods.map(period => {
      return {
        value: String(period.days),
        label: period.recurrenceDescription,
      };
    });
    setSubscriptionPeriodPeriodOptions(options);
  }

  const validationSchema = yup.object().shape({
    offerName: yup.string().required('O Nome da oferta é obrigatório'),
    offerDescription: yup
      .string()
      .required('A descrição da oferta é obrigatória'),
    bannerWeb: yup.mixed(),
    bannerMobile: yup.mixed(),
    bannerSideWeb: yup.mixed(),
    logo: yup.mixed(),
    fullPrice: yup.string().required('O valor é obrigatório'),
    installments: yup.string().required('O valor é obrigatório'),
    startDate: yup.date().required('A data de início é obrigatória'),
    endDate: yup
      .date()
      .nullable()
      .when('startDate', (startDate, schema) => {
        return startDate
          ? schema.min(
              startDate,
              'A data de término deve ser maior ou igual à data de início'
            )
          : schema;
      })
      .when('isOfferStandard', {
        is: false,
        then: yup.date(),
      }),
    subscriptionPeriod: yup.string(),
    purchaseBtnLabel: yup.string(),
    googleAnalyticsIds: yup.string(),
    facebookPixels: yup.string(),
    privacyPolicyRef: yup
      .string()
      .required('O link para a política de privacidade é obrigatório'),
    termsRef: yup
      .string()
      .required('O link para os termos de responsabilidade é obrigatório'),
    plan: yup.string(),
    hasCounter: yup.boolean(),
    isDisplayCoupon: yup.boolean(),
    isAvailableNuPay: yup.boolean(),
    bgCounter: yup
      .mixed()
      .test(
        'isValidHex',
        'O campo bgCounter deve ser um código HEX válido iniciado com # ou vazio',
        (value, context) => {
          if (!!value && context.parent.hasCounter) {
            // Verifica se o valor é um código HEX válido iniciado com #
            return /^#[0-9A-Fa-f]{6}$/.test(value);
          }
          return true; // Valor vazio é permitido
        }
      ),
    rightImg: yup.mixed(),
    rightImgMobile: yup.mixed(),
    leftImg: yup.mixed(),
    leftImgMobile: yup.mixed(),
  });

  const onSubmit = () => {
    setIsLoading(true);
    showNotification('submitOffer');
  };

  const { control, handleSubmit, reset, getValues, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      offerName: '',
      accessProfileId: '',
      offerDescription: '',
      bannerWeb: '',
      bannerMobile: '',
      bannerSideWeb: '',
      logo: '',
      fullPrice: '',
      installments: '',
      startDate: '',
      endDate: '',
      subscriptionPeriod: '365',
      purchaseBtnLabel: '',
      googleAnalyticsIds: '',
      facebookPixels: '',
      isMailPromotional: false,
      renewalDisabled: false,
      renewalOfferId: '',
      disclaimer: '',
      privacyPolicyRef: 'https://www.queimadiaria.com/politica-de-privacidade/',
      termsRef: 'https://www.queimadiaria.com/termos-de-uso/',
      plan: 'individual',
      offerStandart: false,
      hasCounter: false,
      isDisplayCoupon: false,
      isAvailableNuPay: false,
      bgCounter: '',
      rightImg: '',
      rightImgMobile: '',
      leftImg: '',
      leftImgMobile: '',
      hasPromotionalOffer: false,
      promotionalAmount: '',
      qtdPromotionalDays: '',
      qtdDelayedCaptureDays: '',
    },
  });

  const hasPromotionalOffer = watch('hasPromotionalOffer');

  useEffect(() => {
    if (!hasPromotionalOffer) {
      setValue('promotionalAmount', '');
      setValue('qtdPromotionalDays', '');
      setValue('qtdDelayedCaptureDays', '');
    }
  }, [hasPromotionalOffer]);

  useEffect(() => {
    const hasCounterValue = getValues().hasCounter;
    sethasCounter(hasCounterValue);
    if (hasCounterValue === false) setValue('bgCounter', '');
  }, [watch('hasCounter')]);

  useEffect(() => {
    const isDisplayCouponValue = getValues().isDisplayCoupon;
    setIsDisplayCoupon(isDisplayCouponValue);
  }, [watch('isDisplayCoupon')]);

  useEffect(() => {
    const isAvailableNuPayValue = getValues().isAvailableNuPay;
    setIsAvailableNuPay(isAvailableNuPayValue);
  }, [watch('isAvailableNuPay')]);

  useEffect(() => {
    const bgCounter = getValues().bgCounter;
    setBgCounter(bgCounter);
  }, [watch('bgCounter')]);

  const submitEdit = async () => {
    idProduct &&
      idOffer &&
      (await offerApi
        .putOfferById(
          idOffer,
          await createFormDataFromFormResponse(getValues(), idProduct)
        )
        .then(data => {
          data.data ? showNotification('success') : showNotification('error');
        }));
    setIsLoading(false);
  };

  const submitCreate = async () => {
    idProduct &&
      (await offerApi
        .postOfferById(
          await createFormDataFromFormResponse(getValues(), idProduct)
        )
        .then(data => {
          data.data ? showNotification('success') : showNotification('error');
        }));
    setIsLoading(false);
  };

  const showNotification = (type: string) => {
    switch (type) {
      case 'submitOffer':
        Modal.warning({
          title: 'Atenção!',
          content:
            'Você está prestes a criar uma nova oferta. Por favor, verifique se os dados estão corretos antes de prosseguir, pois essa ação não poderá ser desfeita ou excluída posteriormente.',
          cancelText: 'Cancelar',
          onOk: () => {
            isEdit ? submitEdit() : submitCreate();
          },
          okCancel: true,
        });
        break;
      case 'success':
        Modal.success({
          title: 'Atenção!',
          content: 'Solicitação realizada com sucesso!',
          onOk: () => {
            window.location.href = `/offers?id=${idProduct}`;
          },
        });
        break;
      case 'error':
        Modal.error({
          title: 'Atenção!',
          content:
            'Não foi possivel realizar a sua solicitação, tente editar a oferta para ver os campos que foram criados corretamente!',
          onOk: () => {
            Modal.destroyAll();
          },
          okCancel: true,
        });
        break;
    }
  };

  return [
    onSubmit,
    control,
    handleSubmit,
    defineTitlePage(),
    type,
    isEdit,
    idProduct,
    isLoading,
    isOfferStandard,
    hasCounter,
    isDisplayCoupon,
    isAvailableNuPay,
    bgCounter,
    subscriptionPeriodOptions,
    hasPromotionalOffer,
  ] as unknown as [
    (data: any) => void,
    Control<any, any>,
    UseFormHandleSubmit<any, undefined>,
    string,
    string,
    boolean,
    string,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    string,
    OptionList,
    boolean
  ];
}
