import { Card, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const CustomCard = styled(Card)`
  & > .ant-card-head {
    padding-inline: 12px;
    font-weight: 600;
  }

  & .ant-card-extra {
    padding: 12px 0;
  }

  & .ant-card-head-title {
    padding: 12px 0;
  }

  & > .ant-card-body {
    padding: 12px;
  }
`;

export const CustomLinkCard = styled(Text)`
  display: flex;
  align-items: center;
  color: #1890ff;
  cursor: pointer;

  & > .anticon {
    margin-left: 6px;
  }
`;
