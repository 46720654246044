import { SettingOutlined } from '@ant-design/icons';
import { PageHeader } from '@organisms';
import { Col, Divider, Row } from 'antd';
import { FC } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { BasicCard } from './components/BasicCard';

export const SocialDashboard: FC = () => {
  useBreadcrumbs([]);

  const arrayItens = [
    {
      title: 'Gamefication',
      text: '',
      linkName1: 'Gerenciar Eventos',
      link1: '/social/gamefication/events',
      linkName2: 'Gerenciar Grupos de badges',
      link2: '/social/gamefication/badges/groups',
      linkName3: 'Gerenciar Competições',
      link3: '/social/gamefication/competitions',
      icon: <SettingOutlined />,
    },
    {
      title: 'Grupos Colaborativos',
      text: '',
      linkName1: '',
      link1: '',
      icon: <SettingOutlined />,
    },
  ];

  return (
    <>
      <Row>
        <Col span={14}>
          <PageHeader
            backToPath={() => {
              window.history.back();
            }}
            title={'Social'}
            subTitle={''}
          />
        </Col>
        <Col span={10} />
        <Divider />
      </Row>
      <Row>
        {arrayItens.map((param, index) => {
          return (
            <Col
              key={`col-${index}`}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              style={{ marginTop: 30 }}
            >
              <BasicCard
                key={index}
                title={param.title}
                text={param.text}
                link1={param.link1}
                linkName1={param.linkName1}
                link2={param.link2}
                linkName2={param.linkName2}
                link3={param.link3}
                linkName3={param.linkName3}
                icon={param.icon}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
