import { Empty } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { FC, Key, useState } from 'react';

import * as S from './Table.style';

export type TableComponentProps = {
  pageSize: number;
  currentPage?: number;
  total?: number;
  columns: ColumnsType<any>;
  data: RcTableProps<any>['data'];
  onChangePage?: (
    currentPage: number,
    paginationSize?: number | undefined
  ) => void;
  onClickRow?: (item?: any, handler?: string) => void;
  style?: React.CSSProperties;
  loading?: boolean;
  showRowSelection?: boolean;
  rowKey?: string;
  rowsSelected?: Key[];
  onSelectedRowChange?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
};

export const TableComponent: FC<TableComponentProps> = ({
  currentPage,
  pageSize,
  total,
  columns,
  data,
  onChangePage,
  onClickRow,
  style,
  loading,
  showRowSelection,
  rowKey,
  rowsSelected = [],
  onSelectedRowChange,
}) => {
  const [listSelectedRowKeys, setListSelectedRowKeys] =
    useState<Key[]>(rowsSelected);
  const onChangeMiddleware = function (pagination: TablePaginationConfig) {
    if (!onChangePage) {
      return null;
    }

    return onChangePage(pagination.current ?? 0, pagination.pageSize);
  };

  const onSelectedRowKeysChange = (
    selectedRowKeys: Key[],
    selectedRows: any[]
  ) => {
    if (onSelectedRowChange) {
      onSelectedRowChange(selectedRowKeys, selectedRows);
    }
    setListSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: listSelectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  return (
    <S.Table
      rowKey={rowKey || 'id'}
      key={Date.now()}
      bordered
      rowSelection={showRowSelection ? rowSelection : undefined}
      loading={loading}
      style={style}
      pagination={{
        current: currentPage ?? undefined,
        pageSize,
        total,
        position: ['bottomCenter'],
        responsive: true,
        hideOnSinglePage: true,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        size: 'small',
      }}
      columns={columns}
      rowClassName={onClickRow && 'row-style'}
      dataSource={data}
      onChange={onChangeMiddleware}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Nenhum registro encontrado'}
          />
        ),
      }}
      onRow={record => ({
        onClick: () => {
          if (onClickRow) {
            onClickRow(record);
          }
        },
      })}
    />
  );
};
