import { useDebounce, useSegmentsRelation } from '@hooks';
import { Programs, Segments } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { ProgramsTableList } from '@templates';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import * as S from './LinkPrograms.style';

export const LinkPrograms: React.FC = () => {
  const { state } = useLocation<{
    id: string;
  }>();

  const {
    programsNotBySegment,
    getProgramsNotBySegment,
    requestLinkPrograms,
    linkPrograms,
    linkedSegments,
  } = useSegmentsRelation(state?.id);

  const history = useHistory();

  const [filter, setFilter] = useState<string>('');
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(1);

  const debounceValue = useDebounce(filter, 900);

  useEffect(() => {
    getProgramsNotBySegment({
      filter: { name: { iLike: `%${debounceValue}%` } },
    });
    setPaginationCurrentPage(1);
  }, [debounceValue]);

  useBreadcrumbs([
    {
      label: 'Vínculo de programas',
    },
  ]);

  useEffect(() => {
    if (!state?.id) {
      history.push('/screens/segments/manage');
    }

    getProgramsNotBySegment({});
  }, []);

  const onSelectRow = (selectedListRow: Programs.ProgramsResponse[]) => {
    linkPrograms(selectedListRow);
  };

  const handleSaveLink = async () => {
    await requestLinkPrograms();
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.PROGRAMS,
    });
  };

  const handleCancelLink = () => {
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.PROGRAMS,
    });
    OpenNotification(false, 'Nenhum programa foi vinculado.', '', 'warning');
  };

  return (
    <LayoutConfirmationFooter
      onSave={handleSaveLink}
      onCancel={handleCancelLink}
      ableToSave={!!linkedSegments?.programs.length}
    >
      <PageHeader
        backToPath={handleCancelLink}
        title='Vincular programas'
        subTitle='Vincule os programas que desejar ao segmento que está adicionando.'
      />

      <S.FilterConatiner>
        <Input.Search
          onChange={evt => setFilter(evt.target.value)}
          placeholder='Busque pelo programa desejado.'
          allowClear
        />
      </S.FilterConatiner>

      <ProgramsTableList
        value={programsNotBySegment}
        onSelectRow={onSelectRow}
        rowsSelected={linkedSegments?.programs}
        paginationCurrentPage={paginationCurrentPage}
        onChangePagination={(take, skip, pagination) => {
          setPaginationCurrentPage(pagination.current ?? 1);
          getProgramsNotBySegment({
            filter: filter
              ? { name: { iLike: `%${debounceValue}%` } }
              : undefined,
            take,
            skip,
          });
        }}
      />
    </LayoutConfirmationFooter>
  );
};
