export function verifyFilter(
  total: number | undefined,
  initialDateToUri: Date | undefined,
  endDateToUri: Date | undefined,
  idValue: number | undefined,
  filter: string | undefined
): number | undefined {
  if (initialDateToUri || endDateToUri || idValue || filter) {
    return total;
  } else {
    return 0;
  }
}

export function calcImc(
  imc: number | undefined,
  height: string | undefined,
  weight: string | undefined
): string | number | string[] | undefined {
  if (imc) {
    return imc;
  }
  if (height && weight) {
    const calcIMC = Number(weight) / (Number(height) * Number(height));
    return calcIMC.toFixed(1);
  }
}

export function calcAge(
  birthday: Date | undefined
): string | number | string[] | undefined {
  if (birthday) {
    const birthdayDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthdayDate.getFullYear();
    const month = today.getMonth() - birthdayDate.getMonth();
    if (
      month < 0 ||
      (month === 0 && today.getDate() < birthdayDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  return '';
}
