import { Form, InputNumber, InputNumberProps } from 'antd';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';
const { Item: FormItem } = Form;

interface ControllerProps extends InputNumberProps {
  control: Control<any>;
  name: string;
  label?: string;
  required?: boolean;
  maxLength?: number;
}

export const InputNumberController: React.FC<ControllerProps> = ({
  control,
  defaultValue,
  name,
  label,
  required,
  maxLength,
  ...rest
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        return (
          <FormItem
            required={required}
            validateStatus={status}
            help={help}
            label={label}
            {...(maxLength && { extra: `(${maxLength} caracteres no máximo)` })}
          >
            <InputNumber
              data-testid={name}
              {...(maxLength && { maxLength: maxLength })}
              {...field}
              {...rest}
            />
          </FormItem>
        );
      }}
    />
  );
};
