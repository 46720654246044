import { slices } from '@core/redux';
import { coupons } from '@service/api';
import {
  createDigitalCoupon,
  TCreateDigitalCouponProps,
} from '@service/api/coupons/digital/createDigitalCoupon';
import {
  editDigitalCoupon,
  TEditDigitalCouponProps,
} from '@service/api/coupons/digital/editDigitalCoupon';
// import { coupons } from '@service/api';
import { PageLimitFilter } from '@typings';
import { Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { offerApi } from 'src/features/offers/apis/offerApis';
import { CouponDigital } from 'src/models';

import OpenNotification from '../../../../components/antd/OpenNotification';
import { discountMock } from '../digital/mocks/digitalCoupon.mock';
import { TableCouponDigitalProps } from '../digital/TableDigital.coupons';
import { TFormData } from '../digital/types/formDataType';

export const useProductDigitalCoupon = () => {
  const dispatch = useDispatch();

  const [couponList, setCouponList] = useState<CouponDigital.TCouponResponse>();

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [destinationOfferId, setDestinationOfferId] = useState('');
  const [destinyOfferStatus, setDestinyOfferStatus] = useState<
    '' | 'success' | 'error' | 'validating'
  >('');
  const [searchDestinyOfferError, setSearchDestinyOfferError] = useState<
    null | string
  >(null);

  const formRef = useRef<HTMLFormElement>(null);

  useBreadcrumbs([
    {
      label: 'Gestão de Cupons',
    },
    {
      label: 'Produto Digital',
    },
  ]);

  const getAllCoupons = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await coupons.getDigitalCoupons(props);
      setCouponList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllCoupons();
  }, []);

  const handleChangePagination: TableCouponDigitalProps['onChangePagination'] =
    (take, _, pagination) => {
      getAllCoupons({
        ...currentFilter,
        limit: take,
        page: pagination,
      });
    };

  const handleFilterCoupon = useCallback(
    (filterList: { field: string; value?: string }[]) => {
      const current = {
        ...currentFilter,
        filter: {
          ...currentFilter?.filter,
        },
      };

      filterList.forEach(filterToAdd => {
        if (!filterToAdd.value) {
          delete current.filter?.[filterToAdd.field];
        } else {
          current.filter[filterToAdd.field] = filterToAdd.value;
        }
      });

      getAllCoupons(current as PageLimitFilter);
    },
    [getAllCoupons, currentFilter]
  );

  const handleOrderCoupon = useCallback(
    (order: string) => {
      getAllCoupons({ ...currentFilter, order });
    },
    [getAllCoupons, currentFilter]
  );

  const dispatchSubmit = useCallback(() => {
    formRef.current?.submit();
  }, [formRef.current]);

  const onSaveCoupon = useCallback(() => {
    Modal.success({
      title: 'Concluído!',
      content: 'Cupom salvo com sucesso!',
    });
    history.push('/screens/coupons?show=digital');
  }, [history]);

  const handleCancel = useCallback(() => {
    history.push('/screens/coupons?show=digital');
  }, [history]);

  const INFINITE_DISCOUNT_LIMIT = 10000000;

  const { isLoading: isLoadingProductOptions, data: productOptionsResult } =
    useQuery(['product-options'], () => offerApi.getProducts(), {
      onError: err => OpenNotification(true, 'Erro', handleDisplayError(err)),
      select: products => {
        return products.map(product => {
          return {
            label: product.name,
            value: Number(product.id),
          };
        });
      },
      refetchOnWindowFocus: false,
    });

  const productOptions = useMemo(() => {
    return productOptionsResult || [];
  }, [productOptionsResult]);

  const onChangeSearchDestinyOffer = useCallback((value: string) => {
    setDestinyOfferStatus('');
    setSearchDestinyOfferError(null);
    setDestinationOfferId(value);
  }, []);

  const handleSearchDestinyOffer = useCallback(async (id: string) => {
    try {
      if (!id) {
        return;
      }
      if (isNaN(Number(id))) {
        setDestinyOfferStatus('error');
        setSearchDestinyOfferError('Digite um número válido');
        return;
      }
      setDestinyOfferStatus('validating');
      const coupon = await offerApi.getOfferById(id);

      if (coupon) {
        setDestinyOfferStatus('success');
      } else {
        setDestinyOfferStatus('error');
      }
    } catch (err) {
      console.error(err);
      setDestinyOfferStatus('error');
    }
  }, []);

  const submitCreateCoupon = async (formData: TFormData) => {
    const {
      code,
      description,
      startDate,
      endDate,
      limitValue,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;
    if (destinyOfferStatus !== 'success') return;

    const payload: TCreateDigitalCouponProps = {
      code,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      destinationOfferId: Number(destinationOfferId),
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerProduct: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
      discount: discountMock,
    };
    await createDigitalCoupon(payload);
  };

  const submitEditCoupon = async (couponId: number, formData: TFormData) => {
    const {
      description,
      startDate,
      endDate,
      limitValue,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;

    const payload: TEditDigitalCouponProps = {
      id: couponId,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      destinationOfferId: Number(destinationOfferId),
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerProduct: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
      discount: discountMock,
    };
    await editDigitalCoupon(payload);
  };

  const handleDisplayError = (err: unknown) => {
    console.error(err);
    if (isSerializedApiError(err))
      return err.message || `Problema de comunicação com a API`;
    return `Erro ao salvar. Contate a equipe técnica.`;
  };

  return {
    couponList,
    isLoadingData,
    handleChangePagination,
    handleFilterCoupon,
    handleOrderCoupon,
    dispatchSubmit,
    onSaveCoupon,
    handleCancel,
    formRef,
    isLoading,
    setIsLoading,
    INFINITE_DISCOUNT_LIMIT,
    productOptions,
    isLoadingProductOptions,
    submitCreateCoupon,
    submitEditCoupon,
    handleDisplayError,
    destinyOfferStatus,
    handleSearchDestinyOffer,
    searchDestinyOfferError,
    onChangeSearchDestinyOffer,
    destinationOfferId,
    setDestinationOfferId,
    setDestinyOfferStatus,
  };
};
