import { Customer } from '@models';
import { ActiveTag, InctiveTag, TableList } from '@molecules';
import { CountResponse } from '@typings';
import { TablePaginationConfig } from 'antd';

import { customer, general } from 'src/app/constants';

const getAmountLabel = (count?: number) => {
  if (!count) return '';

  return `${count} Usuário${count > 1 ? 's' : ''} cadastrado${
    count > 1 ? 's' : ''
  }`;
};
interface CustomerTabProps {
  value?: CountResponse<Customer.CustomerResponse>;
  onSelectRow: (selectedListRow: Customer.CustomerResponse[]) => void;
  onChangePagination?: (
    take: number,
    skip: number,
    pagination: TablePaginationConfig
  ) => void;
  rowsSelected?: Customer.CustomerResponse[];
  countLabel?: string;
  emptyText?: string;
  paginationCurrentPage?: number;
}
export const CustomersTableList = ({
  value,
  onSelectRow,
  onChangePagination,
  rowsSelected,
  emptyText,
  paginationCurrentPage,
}: CustomerTabProps) => (
  <TableList<Customer.CustomerResponse>
    countLabel={!!value?.count ? getAmountLabel(value.count) : ''}
    emptyText={emptyText || 'Nenhum usuário vinculado.'}
    rowsSelected={rowsSelected}
    onChangeSelected={onSelectRow}
    onChangePagination={onChangePagination}
    paginationCurrentPage={paginationCurrentPage}
    columns={[
      {
        title: 'Nome do usuário',
        dataIndex: 'name',
        key: 'name',
        render: _ => _ || general.NOT_ANSWERED,
      },
      {
        title: 'Idade',
        dataIndex: 'profileOnboarding.ageNum',
        key: 'profileOnboarding.ageNum',
        render: (_, record) =>
          `${record.profileOnboarding?.ageNum || general.NOT_ANSWERED}`,
      },
      {
        title: 'Gênero',
        dataIndex: 'gender',
        key: 'gender',
        render: (_, record) =>
          `${
            customer.getGenderLabelById(record?.profileOnboarding?.gender) ||
            general.NOT_ANSWERED
          }`,
      },
      {
        title: 'Nível',
        dataIndex: 'level',
        key: 'level',
        render: (_, record) =>
          `${
            general.getLevelsLabelById(record.profileOnboarding.level) ||
            general.NOT_ANSWERED
          }`,
      },
      {
        title: 'Objetivo',
        dataIndex: 'objective',
        key: 'objective',
        render: (_, record) =>
          `${
            general.getObjectivesLabelById(
              record.profileOnboarding.objective
            ) || general.NOT_ANSWERED
          }`,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: boolean) => (status ? <ActiveTag /> : <InctiveTag />),
      },
    ]}
    data={value}
  />
);
