import { Form, Row, Switch } from 'antd';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

const { Item: FormItem } = Form;

interface SwitchGroupControllerProps {
  control: Control<any>;
  name: string;
  label: string;
  options: Array<{ value: string; label: string }>;
}

export const SwitchGroupController: React.FC<SwitchGroupControllerProps> = ({
  control,
  name,
  label,
  options,
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={options[0].value}
      render={({ field }) => {
        return (
          <FormItem label={label}>
            <Row
              style={{
                width: '100%',
                display: 'flex',
              }}
            >
              {options.map(option => (
                <div
                  key={option.value}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingInlineEnd: '15px',
                  }}
                >
                  <span style={{ marginRight: '8px' }}>{option.label}</span>
                  <Switch
                    checked={field.value === option.value}
                    onChange={checked => {
                      if (checked) {
                        field.onChange(option.value);
                      }
                    }}
                  />
                </div>
              ))}
            </Row>
          </FormItem>
        );
      }}
    />
  );
};
