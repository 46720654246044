import { useSegments } from '@hooks';
import { SelectController } from '@organisms';
import { SelectProps } from 'antd';
import React, { useEffect } from 'react';

interface BannerTypeSelectProps extends SelectProps {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  dataTake?: number;
}
const SegmentsSelect: React.FC<BannerTypeSelectProps> = ({
  control,
  name,
  label,
  placeholder,
  dataTake,
  defaultValue,
  ...props
}) => {
  const segmentsArr = [] as any[];
  const { segmentsList, getSegmentsList } = useSegments();

  useEffect(() => {
    getSegmentsList({
      take: dataTake || 10,
    });
  }, []);

  segmentsList?.rows?.forEach(segment => {
    segmentsArr.push({
      label: segment.name,
      value: Number(segment.id),
    });
  });

  return (
    <SelectController
      control={control}
      name={name}
      options={segmentsArr}
      label={label}
      placeholder={placeholder}
      mode={props.mode}
      {...props}
    />
  );
};

export default SegmentsSelect;
