import { slices } from '@core/redux';
import { Modal } from '@models';
import { modals } from '@service/api';
import { TakeSkipFilter } from '@typings';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';

export const useModalOrdering = () => {
  const dispatch = useDispatch();

  const [modalOrderingList, setModalOrderingList] =
    useState<Modal.ResponseOrdering>({});

  const [orderignModal, setOrderingModal] = useState<Modal.OrderingModal[]>([]);

  const getOrderingModals = async (props?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());

      const response = await modals.getOrderingModals({
        filter: { ...props?.filter },
      });
      setModalOrderingList(response);
    } catch (err) {
      console.log(err);
      OpenNotification(true, 'Falha ao carregar os programas do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const saveSort = async (props: Modal.OrderingModal[]) => {
    try {
      dispatch(slices.layout.increaseLoading());

      await modals.saveOrder(
        props.map((modal, index) => ({
          modalId: modal.modalId,
          order: index,
          segmentId: modal.segmentId,
        }))
      );
      setOrderingModal([]);
      OpenNotification(false, 'Modais ordenados com sucesso!');
    } catch (err) {
      console.log(err);
      OpenNotification(true, 'Falha ao carregar os programas do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return {
    saveSort,
    getOrderingModals,
    modalOrderingList,
    orderignModal,
    setOrderingModal,
  };
};
