import { LayoutCreateOrderBump as Layout } from './components/LayoutCreateOrderBump';
import { StepperCreateOrderBump as Stepper } from './components/StepperCreateOrderBump';
import { StepsCreateOrderBump as Steps } from './components/StepsCreateOrderBump';
import { CreateOrderBumpProvider as Provider } from './providers/CreateOrderBumpProvider';

export function CreateOrderBump() {
  return (
    <Provider>
      <Layout>
        <Stepper />
        <Steps />
      </Layout>
    </Provider>
  );
}
