import { slices } from '@core/redux';
import { Assets } from '@models';
import { assets } from '@service/api';
import { CountResponse, TakeSkipFilter } from '@typings';
import { UploadFile } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyObject } from 'yup/lib/types';

import OpenNotification from '../components/antd/OpenNotification';
const TAKE_NUMBER = 10;
export const useAssets = () => {
  const dispatch = useDispatch();

  const [filteredAssetsList, setFilteredAssetsList] =
    useState<CountResponse<Assets.FetchAssetDetail>>();

  const [pagination, setPagination] = useState<{ current: number }>({
    current: 1,
  });

  const [uploadImageStatus, setUploadImageStatus] = useState<{
    currentAssetId?: string;
    smallImageStatus?: 'SUCCESS' | 'FAILED';
    midImageStatus?: 'SUCCESS' | 'FAILED';
    largeImageStatus?: 'SUCCESS' | 'FAILED';
  }>({});

  const getAllAssets = async (props?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const request: AnyObject = {
        skip: props?.skip || 0,
        take: props?.take || 10,
      };

      if (props?.filter) {
        Object.keys(props.filter).forEach((key: string, index) => {
          request[key] = props?.filter?.[key];
        });
      }
      const filteredList = await assets.getAssets(request);

      setFilteredAssetsList(filteredList);
    } catch (error) {
      console.log(error);
      OpenNotification(true, 'Falha ao carregar os Assets');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const changePage = (page: number) => {
    setPagination({ current: page });
    getAllAssets({
      filter: { ...filteredAssetsList },
      take: TAKE_NUMBER,
      skip: page * TAKE_NUMBER,
    });
  };

  const clearOrderList = () => {
    setFilteredAssetsList(undefined);
  };

  const addAsset = async (
    asset: Assets.IPostAsset,
    deviceChannel: Assets.DeviceTypeEnum,
    openInANewWindow: boolean,
    uploadImages: UploadFile[][] | string[]
  ) => {
    try {
      dispatch(slices.layout.increaseLoading());

      const { assetUuid } = await assets.createAsset(asset);
      const [smallImage, midImage, largeImage] = uploadImages;
      const uploadImagesRequest = [];

      setUploadImageStatus({
        currentAssetId: assetUuid,
      });

      if (typeof smallImage !== 'string') {
        if (uploadImageStatus?.smallImageStatus !== 'SUCCESS') {
          const smallImageRequest = new FormData();
          smallImageRequest.append('deviceChannelName', deviceChannel);
          smallImageRequest.append(
            'openLinkTarget',
            openInANewWindow ? '_blank' : '_self'
          );
          smallImageRequest.append(
            'deviceChannelVersion',
            Assets.DeviceVersion[`${deviceChannel}_small`]
          );
          smallImageRequest.append('file', smallImage[0].response || '');
          uploadImagesRequest.push(
            await assets.uploadImages(assetUuid, smallImageRequest)
          );
        }
      }

      if (typeof midImage !== 'string') {
        if (uploadImageStatus?.midImageStatus !== 'SUCCESS') {
          const midImageRequest = new FormData();
          midImageRequest.append('deviceChannelName', deviceChannel);
          midImageRequest.append(
            'openLinkTarget',
            openInANewWindow ? '_blank' : '_self'
          );
          midImageRequest.append(
            'deviceChannelVersion',
            Assets.DeviceVersion[`${deviceChannel}_mid`]
          );
          midImageRequest.append('file', midImage[0].response || '');
          uploadImagesRequest.push(
            await assets.uploadImages(assetUuid, midImageRequest)
          );
        }
      }

      if (typeof largeImage !== 'string') {
        if (uploadImageStatus?.largeImageStatus !== 'SUCCESS') {
          const largeImageRequest = new FormData();
          largeImageRequest.append('deviceChannelName', deviceChannel);
          largeImageRequest.append(
            'openLinkTarget',
            openInANewWindow ? '_blank' : '_self'
          );
          largeImageRequest.append(
            'deviceChannelVersion',
            Assets.DeviceVersion[`${deviceChannel}_large`]
          );
          largeImageRequest.append('file', largeImage[0].response || '');
          uploadImagesRequest.push(
            await assets.uploadImages(assetUuid, largeImageRequest)
          );
        }
      }

      const [smallImageResult, midImageResult, largeImageResult] =
        await Promise.all([...uploadImagesRequest]);

      if (!smallImageResult.success) {
        OpenNotification(true, 'Falha ao adicionar banner versão 1x');
      } else {
        setUploadImageStatus(prevState => ({
          ...prevState,
          smallImageStatus: 'SUCCESS',
        }));
      }
      if (!midImageResult.success) {
        OpenNotification(true, 'Falha ao adicionar banner versão 2x');
      } else {
        setUploadImageStatus(prevState => ({
          ...prevState,
          midImageResult: 'SUCCESS',
        }));
      }
      if (!largeImageResult.success) {
        OpenNotification(true, 'Falha ao adicionar banner versão 3x');
      } else {
        setUploadImageStatus(prevState => ({
          ...prevState,
          largeImageResult: 'SUCCESS',
        }));
      }

      if (
        smallImageResult.success &&
        midImageResult.success &&
        largeImageResult.success
      ) {
        OpenNotification(false, 'Asset adicionado com sucesso.');
        setUploadImageStatus({});
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      OpenNotification(true, 'Falha ao adicionar o Asset');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const editAsset = async (
    originalAsset: Assets.IAsset,
    editedAsset: Assets.IPostAsset,
    deviceChannel: Assets.DeviceTypeEnum,
    uploadImages: UploadFile[][]
  ) => {
    try {
      dispatch(slices.layout.increaseLoading());

      const changeImagePromises = [] as any[];

      uploadImages.forEach((image, index) => {
        const smallImageRequest = new FormData();
        smallImageRequest.append('deviceChannelName', deviceChannel);
        smallImageRequest.append(
          'deviceChannelVersion',
          Assets.DeviceVersion[
            `${deviceChannel}${Assets.DeviceVersionSuffix[index + 1]}`
          ]
        );

        if (!!image[0].response) {
          smallImageRequest.append('file', image[0].response || '');
          changeImagePromises.push(
            assets.uploadImages(originalAsset.id, smallImageRequest)
          );
        }
      });

      const [smallImageResult, midImageResult, largeImageResult] =
        await Promise.all([...changeImagePromises]);

      const allRequestSuccess: Record<string, boolean> = {};

      if (smallImageResult) {
        if (!smallImageResult.success) {
          OpenNotification(true, 'Falha ao adicionar banner versão 1x');
          allRequestSuccess.small = false;
        } else {
          allRequestSuccess.small = true;
          setUploadImageStatus(prevState => ({
            ...prevState,
            smallImageStatus: 'SUCCESS',
          }));
        }
      }

      if (midImageResult) {
        if (!midImageResult.success) {
          OpenNotification(true, 'Falha ao adicionar banner versão 2x');
          allRequestSuccess.mid = false;
        } else {
          allRequestSuccess.mid = true;
          setUploadImageStatus(prevState => ({
            ...prevState,
            midImageResult: 'SUCCESS',
          }));
        }
      }

      if (largeImageResult) {
        if (!largeImageResult.success) {
          OpenNotification(true, 'Falha ao adicionar banner versão 3x');
          allRequestSuccess.large = false;
        } else {
          allRequestSuccess.large = true;
          setUploadImageStatus(prevState => ({
            ...prevState,
            largeImageResult: 'SUCCESS',
          }));
        }
      }

      const haveFailure = Object.keys(allRequestSuccess).filter(
        key => !allRequestSuccess[key]
      );

      if (!haveFailure.length) {
        if (!originalAsset.id) {
          throw new Error('Não existe asset sendo editado!');
        }
        await assets.updateAssets(originalAsset.id, editedAsset);
        OpenNotification(false, 'Asset editado com sucesso.');
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      OpenNotification(true, 'Falha ao adicionar o Asset');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const deleteAsset = async (assetId: string) => {
    try {
      dispatch(slices.layout.increaseLoading());

      await assets.deleteAsset(assetId);

      OpenNotification(false, 'Asset removido com sucesso.');
    } catch (error) {
      console.log(error);
      OpenNotification(true, 'Falha ao deletar os Assets');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getSkip = () => (pagination.current - 1) * TAKE_NUMBER;

  return {
    filteredAssetsList,
    uploadImageStatus,
    pagination,
    take: TAKE_NUMBER,
    skip: getSkip(),
    clearOrderList,
    getAllAssets,
    changePage,
    addAsset,
    editAsset,
    deleteAsset,
    setPagination,
  };
};
