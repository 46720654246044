import { useCallback, useEffect } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { ReleaseFormData } from '../../types/releasePageTypings';
import { useTryToRequestReleasePage } from './useTryToRequestReleasePage';
import { useTryToRequestUpdateReleasePage } from './useTryToRequestUpdateReleasePage';

interface useRequestReleasePageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<Omit<ReleaseFormData, 'name'>>;
  replace: UseFieldArrayReplace<Omit<ReleaseFormData, 'name'>, 'slides'>;
}

export function useRequestReleasePage({
  isUpdate,
  setIsUpdate,
  setTitlePage,
  setValue,
  titlePage,
  replace,
}: useRequestReleasePageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');
  const { tryToRequestReleasePage } = useTryToRequestReleasePage();
  const { isLoadingReleaseUpdate, tryToRequestUpdateReleasePage } =
    useTryToRequestUpdateReleasePage();

  const handleUpdateReleasePage = useCallback(
    async (e: Omit<ReleaseFormData, 'name'>) => {
      await tryToRequestUpdateReleasePage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [titlePage, tryToRequestUpdateReleasePage]
  );

  const handleRequestReleasePage = useCallback(async () => {
    await tryToRequestReleasePage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      replace(response.slides);
      setTitlePage(response.name);
    });
  }, []);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestReleasePage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate, handleRequestReleasePage]);

  return { handleUpdateReleasePage, isLoadingReleaseUpdate };
}
