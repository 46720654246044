import 'react-quill/dist/quill.snow.css';

import { Form } from 'antd';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';
const { Item: FormItem } = Form;

interface TextEditControllerProps {
  control: Control<any>;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
}

export const TextEditController: React.FC<TextEditControllerProps> = ({
  control,
  name,
  placeholder,
  defaultValue,
  required,
  label,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        return (
          <FormItem
            required={required}
            validateStatus={status}
            help={help}
            label={label}
          >
            <TextEdit
              theme='snow'
              placeholder={placeholder}
              data-testid={name}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  ['link', 'code'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'code',
              ]}
              {...field}
            />
          </FormItem>
        );
      }}
    />
  );
};

const TextEdit = styled(ReactQuill)`
  .ql-toolbar,
  .ql-container {
    border: 1px solid #434343;
  }
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.25);
    font-style: normal;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;
