import { FieldError } from 'react-hook-form';

type ValidationStatus =
  | ''
  | 'error'
  | 'success'
  | 'warning'
  | 'validating'
  | undefined;

export interface FieldStatus {
  status: ValidationStatus;
  help?: string;
}

export function toFieldStatus(
  field?: FieldError,
  helpMessage?: string
): FieldStatus {
  const hasError = Boolean(field);
  return {
    status: hasError ? 'error' : undefined,
    help: hasError ? field?.message : helpMessage,
  };
}
