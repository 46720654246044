import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

export const BackButton = () => {
  const history = useHistory();

  return (
    <Row>
      <Col span={24}>
        <Button
          type='link'
          ghost
          style={{
            padding: 0,
            color: '#DBDBDB',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'cemter',
          }}
          onClick={() => history.goBack()}
          key='back'
        >
          <LeftOutlined style={{ fontSize: '24px' }} />
          Voltar
        </Button>
      </Col>
    </Row>
  );
};
