import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BASE_URL = enviroments.BASE_URL;

type TUploadOrderBumpImageResponse = Array<{
  fileName: string;
  url: string;
}>;

export async function uploadOrderBumpImage(
  datasource: any
): Promise<TUploadOrderBumpImageResponse> {
  const { data } = await base({
    baseURL: BASE_URL,
    url: `order-bump/upload`,
    method: 'POST',
    data: datasource,
  });

  if (!data) {
    throw Error(data.message);
  }

  return data as TUploadOrderBumpImageResponse;
}
