import { enviroments } from '@infrastructure/enviroments';
import { Assets } from '@models';
import { base } from '@service/base';
import { CountResponse, TakeSkipFilter } from '@typings';
const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function getAssets(
  props?: TakeSkipFilter
): Promise<CountResponse<Assets.FetchAssetDetail>> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/assets`,
      method: 'GET',
      params: {
        ...props,
      },
    });

    if (!data) {
      throw Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
}

export async function getOrderingAssets(
  props?: TakeSkipFilter
): Promise<CountResponse<Assets.IAsset>> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/assets/ordering`,
      method: 'GET',
      params: {
        ...props,
        type: 'BANNER',
      },
    });

    if (!data) {
      throw Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
}

export async function postSortAssets(
  props: Assets.IRequestOrderingAsset[]
): Promise<void> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/assets/sort`,
      method: 'POST',
      data: { orderingList: [...props] },
    });

    if (!data) {
      throw Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
}

export async function getAssetsByType(
  type: string
): Promise<Assets.FetchAssetDetail[]> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/ordering-banners`,
      method: 'GET',
      params: {
        where: JSON.stringify({
          'banner.type': type,
        }),
      },
    });

    if (!data) {
      throw Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
}

export async function getAssetDetail(
  id: string
): Promise<Assets.FetchAssetDetail> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/banners/${id}`,
    });

    if (!data) {
      throw Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
}

export async function createAsset(
  params: Omit<Assets.IPostAsset, 'id'>
): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/assets`,
    method: 'POST',
    data: {
      ...params,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export async function updateAssets(
  assetId: string,
  params: Assets.IPostAsset
): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/assets/${assetId}`,
    method: 'PUT',
    data: {
      ...params,
    },
  });

  if (data.affected < 1) {
    throw Error(data.message);
  }
}

export async function uploadImages(
  bannerId: string,
  params: FormData
): Promise<any> {
  try {
    const { data } = await base({
      baseURL: BANNER_MS_BASE_URL,
      url: `/assets/${bannerId}/upload`,
      method: 'PUT',
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
}

export async function deleteAsset(bannerId: string): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/assets/${bannerId}`,
    method: 'DELETE',
  });

  if (data.affected < 1) {
    throw Error(data.message);
  }
}

export async function linkAssetToSegment(
  assetId: string,
  segmentId: number
): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/assets/${assetId}/segment/${segmentId}/relationships`,
    method: 'POST',
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export async function unlinkAssetToSegment(
  assetId: string,
  segmentId: number
): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/assets/${assetId}/segment/${segmentId}/relationships`,
    method: 'DELETE',
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export async function bulkBannerOrder(
  orderList: Assets.IOrderingBanners[]
): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/ordering-banners/bulk`,
    method: 'PUT',
    data: orderList,
  });

  if (data.affected < 1) {
    throw Error(data.message);
  }
}
