import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { offerApi } from '../apis/offerApis';
import { ProductDto } from '../interfaces/offersInterface';

export default function useProductTable() {
  const [tableData, setTableData] = useState<ProductDto[]>();
  const [tableFilter, setTableFilter] = useState<ProductDto[]>();
  const [filter, setFilter] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getConsults();
  }, []);

  useEffect(() => {
    setTableFilter(filterTable(filter ?? '', tableData));
  }, [filter]);

  function filterTable(filter: string, data?: ProductDto[]) {
    return data?.filter(function (product) {
      return (
        product.name.toLowerCase().includes(filter.toLowerCase()) ||
        product.id.toString().includes(filter)
      );
    });
  }

  async function getConsults() {
    setIsLoading(true);
    await offerApi.getProducts().then(data => {
      setTableData(data);
      setTableFilter(data);
      setIsLoading(false);
    });
  }

  const tableExibition = () => {
    return filter ? tableFilter : tableData;
  };

  return [tableExibition(), filter, setFilter, isLoading] as [
    ProductDto[],
    string,
    Dispatch<SetStateAction<string | undefined>>,
    boolean
  ];
}
