import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { HotmartSubscriptionApiResponse } from '../types/clientTypes';

const fetchHotmartAssign = async (emailParamHotmartAssign?: string) => {
  const response = await clientApi.getHotmartTableAssign(
    emailParamHotmartAssign
  );

  if ((response as HotmartSubscriptionApiResponse).status === Success) {
    return (response as HotmartSubscriptionApiResponse).data.subscriptions;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as transações',
      'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useSubscriptionsHotmart(email?: string) {
  return useQuery(['assignHotmart', email], () => fetchHotmartAssign(email), {
    refetchOnWindowFocus: false,
  });
}
