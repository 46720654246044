import { ReactComponent as DragIndicator } from '@assets/icons/drag-indicator.svg';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { ComponentProps } from 'react';

interface TableDragSortingProps extends ComponentProps<typeof Table> {
  columns: ColumnsType<any>;
  dataSource: readonly any[];
  setDataSource: React.Dispatch<React.SetStateAction<any[]>>;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowTable = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <div
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              >
                <DragIndicator />
              </div>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const TableDragSorting: React.FC<TableDragSortingProps> = ({
  columns,
  dataSource,
  setDataSource,
  ...rest
}) => {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource(previous => {
        const activeIndex = previous.findIndex(i => i.key === active.id);
        const overIndex = previous.findIndex(i => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map(i => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: RowTable,
            },
          }}
          rowKey='key'
          columns={columns}
          dataSource={dataSource}
          bordered
          {...rest}
        />
      </SortableContext>
    </DndContext>
  );
};
