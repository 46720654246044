import React from 'react';

import { FilterCouponsDigital } from '../../../digital/FilterDigital.coupons';
import { TableDigitalCoupon } from '../../../digital/TableDigital.coupons';
import { useProductDigitalCoupon } from '../../../hooks/useProductDigitalCoupon';

type CouponsDigitalProductsProps = {
  onOpenModalCoupon: () => void;
};

export const CouponsDigitalProducts: React.FC<CouponsDigitalProductsProps> = ({
  onOpenModalCoupon,
}) => {
  const {
    couponList,
    isLoadingData,
    handleChangePagination,
    handleFilterCoupon,
    handleOrderCoupon,
  } = useProductDigitalCoupon();

  return (
    <TableDigitalCoupon
      onChangePagination={handleChangePagination}
      filterComponent={
        <FilterCouponsDigital
          onAddCoupon={onOpenModalCoupon}
          onFilterCoupon={handleFilterCoupon}
        />
      }
      data={couponList}
      onChangeOrder={handleOrderCoupon}
      isLoading={isLoadingData}
    />
  );
};
