import { Select } from 'antd';

import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

interface Props {
  loading?: boolean;
  events?: Array<EventResponseDto>;
  onChange?: (value?: { value?: number; label: React.ReactNode }) => void;
  eventSelected?: EventResponseDto;
}

export const SelectRegraCondicional: React.FC<Props> = ({
  loading = false,
  eventSelected = undefined,
  events = [],
  onChange,
}) => {
  const renderEventsOptions = (): Array<JSX.Element> => {
    return events.map(p => (
      <Select.Option value={p.id} key={`option-regra-${p.id}`}>
        {p.name}
      </Select.Option>
    ));
  };
  return (
    <>
      <Select
        labelInValue
        showSearch
        style={{ width: '100%' }}
        placeholder='Selecione uma regra'
        defaultValue={
          !!eventSelected
            ? { value: eventSelected?.id, label: eventSelected?.name }
            : undefined
        }
        onChange={onChange}
        optionFilterProp='label'
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.children as unknown as string)
            .toLowerCase()
            .localeCompare(
              (optionB?.children as unknown as string).toLowerCase()
            )
        }
      >
        {renderEventsOptions()}
      </Select>
    </>
  );
};
