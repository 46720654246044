import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounce, useSegments } from '@hooks';
import { FilterHeader } from '@organisms';
import { Button, DatePicker, Input, Select } from 'antd';
import { formatISO, parse } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface FilterModalProps {
  onAddModal: () => void;
  onFilterModal: (handle: { field: string; value?: string }[]) => void;
}

export const FilterModals: React.FC<FilterModalProps> = ({
  onAddModal,
  onFilterModal,
}) => {
  const { segmentsList, getSegmentsList } = useSegments();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [dateFilter, setDateFilter] =
    useState<[moment.Moment | null, moment.Moment | null]>();
  const [filterBysegment, setFilterBysegment] = useState([]);
  const [filterByName, setFilterByName] = useState<string | null>(null);

  const debounceValue = useDebounce(filterByName ?? '', 900);

  useEffect(() => {
    if (filterByName !== null) {
      onFilterModal([
        {
          field: 'title',
          value: debounceValue,
        },
      ]);
    }
  }, [debounceValue]);

  useEffect(() => {
    getSegmentsList({ take: 99999 });
  }, []);

  return (
    <FilterHeader
      buttonList={[
        <Input.Search
          key={`filter-modal-name-search`}
          placeholder={'Buscar por título.'}
          value={filterByName ?? ''}
          onChange={evt => setFilterByName(evt.target.value)}
          style={{ width: '360px' }}
          allowClear
        />,
        <DatePicker.RangePicker
          style={{ width: '218px' }}
          onChange={moment => {
            setDateFilter(moment || undefined);

            if (!!moment?.[0] && !!moment[1]) {
              const effectObj = parse(
                moment[0].format('yyyy-MM-DD'),
                'yyyy-MM-dd',
                new Date()
              );
              const expDate = parse(
                moment[1].format('yyyy-MM-DD'),
                'yyyy-MM-dd',
                new Date()
              );
              onFilterModal([
                {
                  field: 'effectiveDateTime',
                  value: formatISO(effectObj),
                },
                {
                  field: 'expirationDateTime',
                  value: formatISO(expDate),
                },
              ]);
            } else {
              onFilterModal([
                {
                  field: 'effectiveDateTime',
                  value: undefined,
                },
                {
                  field: 'expirationDateTime',
                  value: undefined,
                },
              ]);
            }
          }}
          data-testid={'asset-date-filter-select'}
          format={'DD/MM/YYYY'}
          defaultValue={dateFilter}
        />,
        <Select
          placeholder={'Status'}
          data-testid={'asset-data-filter'}
          onChange={value => {
            setStatusFilter(value);

            if (value === undefined) {
              onFilterModal([{ field: 'activated', value: undefined }]);
              return;
            }

            onFilterModal([
              { field: 'activated', value: String(value === 'ACTIVE') },
            ]);
          }}
          style={{ width: '114px' }}
          value={statusFilter}
          allowClear
        >
          <Select.Option value='ACTIVE'>Ativo</Select.Option>
          <Select.Option value='INACTIVE'>Inativo</Select.Option>
        </Select>,

        <Select
          placeholder={'Segmento'}
          data-testid={'asset-segment-filter-select'}
          onChange={value => {
            setFilterBysegment(value);

            if (!value) {
              onFilterModal([{ field: 'segmentId', value: undefined }]);
              return;
            }
            onFilterModal([{ field: 'segmentId', value: value.toString() }]);
          }}
          options={segmentsList?.rows?.map(segment => ({
            label: segment.name,
            value: segment.id,
          }))}
          optionFilterProp='label'
          style={{ width: '216px' }}
          allowClear
          value={filterBysegment}
          showSearch
          suffixIcon={<SearchOutlined />}
        />,
      ]}
      customFilter={[
        <Button
          key={'filter-header-button-add-assets'}
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={onAddModal}
        >
          Novo Modal
        </Button>,
      ]}
    />
  );
};
