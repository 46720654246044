import { Spin } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const Spiner: FC = () => (
  <Centered>
    <Spin />
  </Centered>
);

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
