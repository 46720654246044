import { HolderOutlined } from '@ant-design/icons';
import { RootState } from '@core/redux';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Modal } from '@models';
import { ActiveTag, InctiveTag } from '@molecules';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as S from './OrderTableModals.style';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
  disableOrder: boolean;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: props.disableOrder ? 'default' : 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };
  return (
    <S.Container
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {!props.disableOrder && (
        <S.DragButtonContainer>
          <HolderOutlined />
        </S.DragButtonContainer>
      )}
      <S.TitleContainer>{children}</S.TitleContainer>
    </S.Container>
  );
};

interface DataTable extends Record<string, any> {
  key: string;
}

interface OrderListProps {
  columnTitle: string;
  datakey: string;
  datasource: Record<string, any>[];
  changeOrder: (value: Record<string, any>[]) => void;
  showHeader?: boolean;
  disableOrder?: boolean;
}
export const OrderList: React.FC<OrderListProps> = ({
  datakey,
  datasource,
  changeOrder,
  showHeader = true,
  disableOrder = false,
}) => {
  const [data, setData] = useState<DataTable[]>([]);
  const { segmentsDictionary } = useSelector(
    (state: RootState) => state.segments
  );

  const columns: ColumnsType<DataTable> = [
    {
      title: 'title',
      dataIndex: 'modal',
      key: 'modal',
      render: ({ advertisements }: Modal.OrderingModalItem) => {
        return advertisements?.[0]?.title || '';
      },
    },
    {
      title: 'segmentId',
      dataIndex: 'segmentId',
      key: 'segmentId',
      render: (value: number) =>
        segmentsDictionary[value] || 'Segmento inválido',
    },
    {
      title: 'active',
      dataIndex: 'modal',
      key: 'modal',
      render: ({ activated }: Modal.OrderingModalItem) =>
        activated ? <ActiveTag /> : <InctiveTag />,
    },
  ];

  useEffect(() => {
    const normalizedData = datasource.map((value, index) => ({
      ...value,
      key: `${index}`,
    }));

    setData(normalizedData);
  }, [datasource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newData = [...data];
      setData(prev => {
        const activeIndex = prev.findIndex(i => i.key === active.id);
        const overIndex = prev.findIndex(i => i.key === over?.id);
        newData = arrayMove(prev, activeIndex, overIndex);
        return newData;
      });

      changeOrder(newData);
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        disabled={disableOrder}
        items={data.map(i => i.key)}
        strategy={verticalListSortingStrategy}
      >
        {!!data.length && (
          <Table
            bordered={false}
            pagination={false}
            showHeader={showHeader}
            components={{
              body: {
                row: (props: any) => (
                  <Row disableOrder={disableOrder} {...props} />
                ),
              },
            }}
            rowKey='key'
            columns={columns}
            dataSource={data}
          />
        )}
      </SortableContext>
    </DndContext>
  );
};

export default OrderList;
