import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const CarouselArrowRight = ({
  className,
  style,
  onClick,
  ...props
}: any) => {
  const disabled = props.currentSlide === props.slideCount - 1;
  return (
    <Button
      className={className}
      style={{
        ...style,
        color: disabled ? '#BDBDBD' : '#fff',
        backgroundColor: disabled ? '#DCDCDC' : '#ff4f00',
        height: '48px',
        width: '48px',
        paddingTop: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
      }}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <RightOutlined />
    </Button>
  );
};

export const CarouselArrowLeft = ({
  className,
  style,
  onClick,
  ...props
}: any) => {
  const disabled = props.currentSlide === 0;
  return (
    <Button
      className={className}
      style={{
        ...style,
        color: disabled ? '#BDBDBD' : '#fff',
        backgroundColor: disabled ? '#DCDCDC' : '#ff4f00',
        height: '48px',
        width: '48px',
        paddingTop: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <LeftOutlined />
    </Button>
  );
};
