import { Tabs } from 'antd';
import { FC } from 'react';

const { TabPane } = Tabs;

export type TabComponentProps = {
  tabList: (string | { title: string; icon?: React.ReactNode })[];
  onChangeTab: (item: string) => void;
  defaultActiveKey?: string;
} & Omit<React.ComponentProps<typeof Tabs>, 'onChange'>;

export const TabComponent: FC<TabComponentProps> = ({
  tabList,
  onChangeTab,
  defaultActiveKey = '0',
  ...rest
}) => {
  return (
    <Tabs defaultActiveKey={defaultActiveKey} onChange={onChangeTab} {...rest}>
      {tabList.map((item, index) => (
        <TabPane
          tab={
            <>
              {typeof item === 'object' && item.icon}
              {typeof item === 'string' ? item : item.title}
            </>
          }
          key={index}
        ></TabPane>
      ))}
    </Tabs>
  );
};
