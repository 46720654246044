import { Steps } from 'antd';
import React from 'react';

type ItemProps = {
  title: string;
};

interface StepsProps {
  items: ItemProps[];
  currentStep?: number;
}
export const AdvancedSteps: React.FC<StepsProps> = ({ items, currentStep }) => (
  <Steps
    style={{
      width: '107%',
      marginLeft: '-32px',
    }}
    current={currentStep}
    size='small'
    labelPlacement='vertical'
    items={items}
  />
);
