import {
  DeleteOutlined,
  EllipsisOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
} from 'antd';
import { FC, useEffect, useState } from 'react';

// import { useParams } from 'react-router-dom';
import { PageHeader } from 'src/app/components/PageHeader';

// import { ICompetition } from '../../dto/competition.interface';
import { ManageCompetitionsResponseDto } from '../../dto/manage-competitions.response.dto';
import { StyledTable } from '../../styles';

// interface IFormInterface {
//   initialValues?: ManageCompetitionsResponseDto;
//   enableTabs?: (v: boolean) => void;
// }

const GameficationCompetitionFormStep7: FC = () => {
  const [form] = Form.useForm();
  // const URL_PARAMS: ICompetition = useParams();
  // const isEdit = Boolean(Object.keys(URL_PARAMS)?.length);
  // const [showModal, setModal] = useState<boolean>(false);

  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line
  const onFinish = async (j: any) => {
    // const re = RegExp(`.*${j.search.toLowerCase().split('').join('.*')}.*`);
    // const matches = data.filter(v => v.customer.toLowerCase().match(re));
    // setData(j.search !== '' ? matches : _genData());
    console.info('@@@@', j);
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
  };

  const RENDER_CTA = () => (
    <>
      <Button
        onClick={() => console.info('yay', selectedRowKeys)}
        data-testid='select-all'
        size='large'
        icon={<SelectOutlined />}
        type='link'
      >
        Selecionar todos
      </Button>

      <Button
        onClick={() => console.info('yay', selectedRowKeys)}
        disabled={!selectedRowKeys?.length}
        data-testid='delete'
        size='large'
        icon={<DeleteOutlined />}
        type='link'
        danger
      >
        Remover
      </Button>
    </>
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const ACTIONS = [
    {
      key: 'edit',
      label: 'Visualizar / Editar',
      // onClick: (j: any) => console.info(j),
      // history.push(`competition/${j.id}`),
    },
    {
      label: 'Remover',
      key: 'remove',
      // onClick: (j: any) => console.info('modal remoção', j),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    { title: 'Nome do aluno', dataIndex: 'name', width: 600 },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (x: number) => (x ? 'Ativo' : 'Inativo'),
    },
    {
      title: 'Data da solicitação',
      dataIndex: 'createdAt',
      width: 150,
      render: (x: Date) => x.toLocaleDateString('pt-br'),
    },
    { title: 'Nível', dataIndex: 'level', width: 100 },
    { title: 'Total de pontos', dataIndex: 'points', width: 100 },
    { title: 'Posição no ranking', dataIndex: 'ranking', width: 120 },
    { title: 'Eventos realizados', dataIndex: 'events', width: 100 },
    {
      title: 'Ações',
      key: 'action',
      width: 20,
      render: (_item: ManageCompetitionsResponseDto) => (
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          overlay={() => (
            <Menu>
              {ACTIONS.map(({ label, key }) => (
                <Menu.Item
                  key={key}
                  // onClick={() => onClick(_item)}
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu>
          )}
        >
          <Button type='text'>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const _genData = () => {
    const dataX = [];
    for (let i = 0; i < 46; i++) {
      dataX.push({
        key: i,
        status: i % 3 ? 1 : 0,
        level: 12,
        points: 5,
        ranking: 3,
        events: 8,
        name: i % 2 ? `Carlos Antônio` : 'Mariane Silva',
        createdAt: new Date(),
      });
    }

    return dataX;
  };

  // const data: DataType[] = [];
  useEffect(() => {
    setData(_genData());
  }, []);

  const PROPS = {
    dataSource: data,
    rowSelection,
    columns,
    bordered: true,
    // rowKey: 'id',
  };

  const DATE_PROPS = {
    format: 'DD/MM/YYYY',
    style: { width: '100%' },
  };

  return (
    <>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Alunos inscritos' />
        </Col>

        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          {RENDER_CTA()}
        </Col>
      </Row>

      <Form size='large' layout='vertical' {...FORM_PROPS}>
        <Row style={{ marginBottom: 20 }} gutter={30}>
          <Col span={8}>
            <Form.Item label='Pesquisar' name='search'>
              <Input.Search
                placeholder='Filtrar por...'
                style={{ width: '100%' }}
                onSearch={form.submit}
                size='large'
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name='filter' label='Filtrar por'>
              <Input data-testid='filter' />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label='Vigência' name='from'>
              <DatePicker {...DATE_PROPS} data-testid='from' />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label='Até' name='to'>
              <DatePicker {...DATE_PROPS} data-testid='to' />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StyledTable {...PROPS} pagination={{ position: ['bottomCenter'] }} />
    </>
  );
};

export default GameficationCompetitionFormStep7;
