import { useAssets } from '@hooks';
import { Assets } from '@models';
import { ModalAddAssets, PageHeader } from '@organisms';
import { UploadFile } from 'antd';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import React from 'react';
import { AnyObject } from 'yup/lib/types';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FilterAssets } from './Filter.assets';
import ModalOrderList from './Order.modal';
import { TableAssets } from './Table.assets';

export const AssetsPage: React.FC = () => {
  const {
    getAllAssets,
    filteredAssetsList,
    addAsset,
    editAsset,
    deleteAsset,
    skip,
    take,
    setPagination,
  } = useAssets();

  const [showAddAssetModal, setShowAddAssetModal] = useState(false);
  const [showSortAssetsModal, setShowSortAssetsModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<AnyObject>({});

  const [smallImage, setSmallImage] = React.useState<UploadFile[]>([]);
  const [midImage, setMidImage] = React.useState<UploadFile[]>([]);
  const [largeImage, setLargeImage] = React.useState<UploadFile[]>([]);

  const [currentEditingAsset, setCurrentEditingAsset] =
    React.useState<Assets.IAsset>();

  const [assetToBeDeleted, setAssetToBeDeleted] =
    React.useState<Assets.IAsset['id']>();

  useBreadcrumbs([
    {
      label: 'Assets',
    },
  ]);

  useEffect(() => {
    getAllAssets();
  }, []);

  const handleFilterAssets = (
    filterList: { field: string; value?: string }[]
  ) => {
    const filter = {
      ...currentFilter,
    };

    filterList.forEach(filterToAdd => {
      if (!filterToAdd.value) {
        delete filter[filterToAdd.field];
      } else {
        filter[filterToAdd.field] = filterToAdd.value;
      }
    });

    setCurrentFilter(filter);
    getAllAssets({
      filter,
    });
  };

  const handleEditAsset = (asset: Assets.IAsset) => {
    setCurrentEditingAsset(asset);
    setShowAddAssetModal(true);
  };

  const handleAddAsset = async (
    value: Assets.IPostAsset,
    deviceChannel: Assets.DeviceTypeEnum,
    openInANewWindow: boolean
  ) => {
    let result = undefined;
    if (currentEditingAsset) {
      result = await editAsset(currentEditingAsset, value, deviceChannel, [
        smallImage,
        midImage,
        largeImage,
      ]);
    } else {
      result = await addAsset(value, deviceChannel, openInANewWindow, [
        smallImage,
        midImage,
        largeImage,
      ] as UploadFile[][] | string[]);
    }

    if (result?.success) {
      setShowAddAssetModal(false);
      setSmallImage([]);
      setMidImage([]);
      setLargeImage([]);
      getAllAssets({
        filter: {
          ...currentFilter,
        },
        take,
        skip,
      });
    }

    return !!result?.success;
  };

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de assets'}
        subTitle={
          'Gerencie os assets da plataforma e os ordene conforme a segmentação necessária'
        }
      />

      <TableAssets
        onChangePagination={(take, skip, pagination) => {
          setPagination({ current: pagination });
          getAllAssets({
            filter: currentFilter,
            take,
            skip,
          });
        }}
        filterComponent={
          <FilterAssets
            onAddAsset={() => setShowAddAssetModal(true)}
            onSortAsset={() => setShowSortAssetsModal(true)}
            onFilterAssets={handleFilterAssets}
          />
        }
        data={filteredAssetsList}
        onDeleteAsset={assetId => setAssetToBeDeleted(assetId)}
        onEditAsset={handleEditAsset}
      />

      <ModalAddAssets
        title='Assets'
        showModal={showAddAssetModal}
        editingAsset={currentEditingAsset}
        onCloseModal={() => {
          setCurrentEditingAsset(undefined);
          setShowAddAssetModal(false);
        }}
        onSubmit={handleAddAsset}
        smallImage={smallImage}
        midImage={midImage}
        largeImage={largeImage}
        onChangeImage={(field, image) => {
          switch (field) {
            case 'small':
              setSmallImage(image);
              break;
            case 'mid':
              setMidImage(image);
              break;
            case 'large':
              setLargeImage(image);
              break;

            default:
              break;
          }
        }}
      />

      <ModalOrderList
        title='Ordenar'
        showModal={showSortAssetsModal}
        onCloseModal={() => setShowSortAssetsModal(false)}
      />

      <Modal
        title='Tem certeza que deseja excluir esse asset?'
        open={!!assetToBeDeleted}
        onOk={async () => {
          await deleteAsset(assetToBeDeleted || '');
          getAllAssets({
            filter: {
              ...currentFilter,
            },
            skip,
            take,
          });
          setAssetToBeDeleted(undefined);
        }}
        okText='Deletar'
        okButtonProps={{
          style: { backgroundColor: '#a61d24', border: 'none' },
        }}
        onCancel={() => setAssetToBeDeleted(undefined)}
      >
        Ao confirmar o asset selecionado será excluido da lista.
      </Modal>
    </>
  );
};
