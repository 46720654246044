import { ButtonContainer } from '@molecules';
import React from 'react';

import * as S from './AdvancedCard.style';

interface AdvancedCardProps {
  title: string;
  icon: React.ReactNode;

  selected?: boolean;
  disabled?: boolean;

  onClick?: () => void;
}
export const AdvancedCard: React.FC<AdvancedCardProps> = ({
  title,
  icon,
  selected,
  disabled,
  onClick,
}) => {
  return (
    <ButtonContainer disabled={disabled} onClick={() => onClick?.()}>
      <S.CardContainer selected={selected} disabled={disabled}>
        <div>{icon}</div>

        <S.BottomLine />

        <div>{title}</div>
      </S.CardContainer>
    </ButtonContainer>
  );
};

export default AdvancedCard;
