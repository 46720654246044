/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MoreOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@organisms';
import { Button, Col, Dropdown, Form, Input, Menu, Row } from 'antd';
import { addMonths } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import DatePicker from 'src/app/components/antd/DatePicker';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { removeSpecialChars } from 'src/app/helpers/StringHelper';
import { Success } from 'src/app/utils/statusCode';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { adminApi } from '../../api/adminApi';
import {
  AdminDTOType,
  CommentTypeResponse,
  ResponseAdmin,
} from '../../types/adminTypes';
import { AdminChangePasswordModal } from '../admin-change-password-modal/AdminChangePasswordModal';
import { ModalAddAdmin } from '../admin-create-modal/ModalAddAdmin';

export const AdminScreenListing: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Admin',
    },
  ]);
  const history = useHistory();

  const [form] = Form.useForm();
  const [initialDate, setInitialDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [filter, setFilter] = useState<string | undefined>();
  const [filterValue, setFilterValue] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryClient = useQueryClient();

  const handleOk = () => {
    setIsModalVisible(false);
    queryClient.invalidateQueries('admin');
  };

  function buildUri(
    pageParam: number | undefined,
    firstDate: Date | undefined,
    lastDate: Date | undefined,
    text: string | undefined
  ) {
    if (text) {
      return `?text=${removeSpecialChars(text)}`;
    }
    let url = `?page=1&limit=20`;
    if (firstDate && lastDate) {
      url += `&firstDate=${firstDate.toJSON()}&lastDate=${lastDate.toJSON()}`;
    }
    return url;
  }

  const fetchAdmin = async (
    key: string,
    pageParameter: number,
    initialDateParam?: Date,
    endDateParam?: Date,
    filterParam?: string
  ) => {
    const response = await adminApi.getAllAdmins(
      buildUri(pageParameter, initialDateParam, endDateParam, filterParam)
    );
    const responseAdmin = response as ResponseAdmin;
    if (responseAdmin.status === Success) {
      return responseAdmin.data;
    } else {
      return OpenNotification(
        true,
        'Erro ao carregar admin',
        'Ocorreu um erro ao carregar os admin, por favor tente novamente mais tarde.'
      );
    }
  };

  const { data, isLoading } = useQuery(
    ['admin', page, initialDate, endDate, filter],
    () => fetchAdmin('admin', page, initialDate, endDate, filter),
    { refetchOnWindowFocus: false }
  );

  const timeoutMillisseconds = 1500;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (filterValue !== undefined) {
        setFilter(filterValue);
      }
    }, timeoutMillisseconds);
    return () => clearTimeout(timeOut);
  }, [filterValue]);

  const onChangePage = (pageSelected: number) => {
    setPage(pageSelected);
  };

  const onClickRow = (item: AdminDTOType, isClicked: boolean) => {
    if (isClicked) {
      history.push(`admin/${item.id}`);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const deleteAdmin = async (item: number) => {
    const response = await adminApi.deleteAdmin(item);
    if ((response as CommentTypeResponse).status === Success) {
      OpenNotification(false, 'Admin excluído com sucesso!', '');
      return queryClient.invalidateQueries('admin');
    }

    return OpenNotification(
      true,
      'Erro ao excluir Admin!',
      'Não foi possível excluir o Admin, por favor tente novamente mais tarde ou acione nosso suporte.'
    );
  };

  const [idAdminChangePassword, setIdAdminChangePassword] =
    useState<string>('');
  const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] =
    useState(false);

  const alterarSenhaAdmin = (id: string) => {
    setIdAdminChangePassword(id);
    setIsModalChangePasswordVisible(true);
  };

  return (
    <>
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Admin'}
          subTitle={''}
        />
        <Space />
      </Row>
      <Form layout='inline' form={form}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 10 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Button type='primary' onClick={showModal}>
            {' '}
            <PlusCircleOutlined /> Adicionar admin
          </Button>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 4 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <DatePicker
            format='dd/MM/yyyy'
            placeholder='Selecionar data'
            data-testid='initialDate'
            style={{ width: '96%' }}
            onChange={(
              dateInitialDate: Date | null,
              dateStringInitialDate: string
            ) => setInitialDate(dateInitialDate ? dateInitialDate : undefined)}
            disabledDate={current => {
              const d = new Date();
              const ThreeMonths = 3;
              d.setMonth(d.getMonth() - ThreeMonths);
              return current && current < d;
            }}
            value={initialDate}
          ></DatePicker>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 4 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <DatePicker
            format='dd/MM/yyyy'
            placeholder='Selecionar data'
            style={{ width: '96%' }}
            onChange={(date: Date | null, dateString: string) => {
              if (date) {
                setEndDate(date);
              } else {
                setEndDate(undefined);
              }
            }}
            value={endDate}
            data-testid='endDate'
            disabledDate={current => {
              if (!initialDate) {
                return false;
              } else {
                const ThreeMonthsEndDate = 3;

                return (
                  current <= initialDate ||
                  current >= addMonths(initialDate, ThreeMonthsEndDate)
                );
              }
            }}
          ></DatePicker>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 6 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Form.Item>
            <InputStyled
              type='text'
              placeholder='Buscar por nome, email ou ID'
              value={filterValue}
              data-testid='input'
              suffix={<SearchOutlined />}
              onChange={e => setFilterValue(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Form>
      <Space />

      <Row>
        <Col span={24}>
          <TableComponent
            rowKey='id'
            loading={isLoading}
            pageSize={20}
            columns={[
              {
                title: 'Nome',
                dataIndex: 'fullName',
                key: 'fullName',
              },

              {
                title: 'Documento',
                dataIndex: 'document',
                key: 'document',
              },

              {
                title: 'E-mail',
                key: 'email',
                dataIndex: 'email',
              },
              {
                title: 'Papel',
                key: 'roles',
                dataIndex: 'roles',
                render: (roles: any) => <>{roles.join(', ')}</>,
              },
              {
                title: 'Setor',
                key: 'sector',
                dataIndex: 'sector',
              },
              {
                title: 'Ações',
                key: 'action',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                render: (item: any) => (
                  <Dropdown
                    trigger={['hover']}
                    overlay={() => {
                      return (
                        <Menu>
                          <Menu.Item
                            key='edit'
                            onClick={(e: any) => {
                              e.domEvent.stopPropagation();
                              return deleteAdmin(item.id);
                            }}
                          >
                            Excluir
                          </Menu.Item>
                          <Menu.Item
                            key='changePassword'
                            onClick={(e: any) => {
                              e.domEvent.stopPropagation();
                              return alterarSenhaAdmin(item.id);
                            }}
                          >
                            Alterar Senha
                          </Menu.Item>
                        </Menu>
                      );
                    }}
                  >
                    <Button style={{ border: 'none' }}>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                ),
              },
            ]}
            data={data?.users}
            total={data?.total}
            onChangePage={onChangePage}
            onClickRow={(item: any) => onClickRow(item, true)}
          />
        </Col>
      </Row>
      <ModalAddAdmin
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleOk={handleOk}
      />
      <AdminChangePasswordModal
        isModalVisible={isModalChangePasswordVisible}
        setIsModalChangePasswordVisible={setIsModalChangePasswordVisible}
        idAdmin={idAdminChangePassword}
      />
    </>
  );
};

const InputStyled = styled(Input)`
  width: 100%;
  @media (max-width: 992px) {
    width: 101%;
  }
`;

const Space = styled.div`
  margin-top: 48px;
`;
