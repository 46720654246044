import { Image } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 8px;
  }
`;

export const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const PreviewImage = styled(Image)`
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
`;

export const PreviewRemoveButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 8px;
  color: #dc4446;

  transition: 0.6s;

  :hover {
    transform: scale(1.1);
  }
`;
