import { useUpsellFunnel } from '@hooks';
import { PageHeader } from '@organisms';
import React from 'react';

import { TabComponent } from 'src/app/components/antd/TabComponent';

export const UpsellFunnel: React.FC = () => {
  const {
    onChangeMenu,
    indexSelectedMenu,
    labelsMenu,
    tabActive: { Component },
  } = useUpsellFunnel();
  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de Funil Upsell'}
        subTitle={'Gerencie, edite e crie novas etapas de Funil.'}
      />

      <TabComponent
        tabList={labelsMenu}
        onChangeTab={onChangeMenu}
        defaultActiveKey={indexSelectedMenu}
      />

      <Component />
    </>
  );
};
