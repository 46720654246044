import { useEffect } from 'react';

import { useCreateOrderBump } from '../../../../hooks/useCreateOrderBump';

export function useContent() {
  const { ableToSave, title, description, confirmationButtonText } =
    useCreateOrderBump();

  useEffect(() => {
    if (!title.value || !description.value || !confirmationButtonText.value) {
      ableToSave.setter(false);
      return;
    }

    ableToSave.setter(true);
  }, [title.value, description.value, confirmationButtonText.value]);
}
