import { slices } from '@core/redux';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const ProtectedRoute: FC<RouteProps> = ({
  children,
  component,
  ...rest
}) => {
  const isAuth = useSelector(slices.auth.selectIsAuth);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = component;

  const isAuthVerification = () => {
    if (isAuth) {
      return children ?? <Component />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }
  };

  return <Route {...rest} render={isAuthVerification} />;
};
