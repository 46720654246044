import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AlertComponent } from 'src/app/components/antd/Alert';
import { TableComponent } from 'src/app/components/antd/TableComponent';

import { roleApi, RoleType } from '../api/roleApi';
import { ModalAddPermisson } from './ModalAddRole';
import * as Styles from './styles';

export default function Permissions() {
  const history = useHistory();

  const onClickRow = (item: RoleType) => {
    history.push(`permissao/${item.id}`);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dados, setDados] = useState<RoleType[] | undefined>();

  useEffect(() => {
    listPermissions();
  }, []);

  const listPermissions = async () => {
    const res = await roleApi.listRoles();
    setDados(res.role);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const timeoutTime = 5000;
  const handleOk = () => {
    setIsModalVisible(false);
    setVisible(true);
    setTimeout(() => setVisible(false), timeoutTime);
    listPermissions();
  };

  return (
    <>
      <Row>
        <Styles.SectionTitle>Gestão de Papéis</Styles.SectionTitle>
      </Row>
      <Row>
        <Col span={3}>
          <Styles.ButtonInput
            icon={<PlusCircleOutlined />}
            type='primary'
            onClick={showModal}
          >
            Adicionar papel
          </Styles.ButtonInput>
        </Col>
        <Col offset={9} span={5}>
          <Styles.SelectInput placeholder='Tipo de permissão'></Styles.SelectInput>
        </Col>
        <Col span={6}>
          <Styles.SearchInput
            placeholder='Buscar por nome, email ou ID'
            suffix={<SearchOutlined />}
          />
        </Col>
      </Row>
      <TableComponent
        style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
        columns={[
          {
            title: 'Nome do papel',
            dataIndex: 'name',
          },
          {
            title: 'Quantidade de pessoas',
            dataIndex: 'quantity',
            sorter: true,
          },
          {
            title: 'Tipo de usuário',
            dataIndex: 'details',
            sorter: true,
          },
          {
            title: 'Data de atualização',
            dataIndex: 'updatedAt',
            sorter: true,
          },
          {
            title: '',
            sorter: true,
          },
        ]}
        pageSize={7}
        data={dados}
        total={dados ? dados.length : 0}
        onClickRow={onClickRow}
      />

      <ModalAddPermisson
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleOk={handleOk}
      />

      {visible ? (
        <AlertComponent
          message='Perfil cadastrado com sucesso'
          type='success'
          close={handleClose}
        />
      ) : null}
    </>
  );
}
