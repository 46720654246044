import { OrderBump } from '@models';
import { useMemo, useState } from 'react';

import { TSize } from '../../StepsCreateOrderBump/Steps/Contents/components/PreviewOrderBump';
import { TemplateA } from './TemplateA/TemplateA';
import { TemplateB } from './TemplateB/TemplateB';

export type TOrderBumpCheckoutProps = {
  orderBumpData: OrderBump.IOrderBump;
  sizeMode: TSize;
  onCheckChange?: (value: boolean) => void;
  checked?: boolean;
};

export function OrderBumpCheckout(props: TOrderBumpCheckoutProps) {
  const [isChecked, setIsChecked] = useState(false);

  const Component = useMemo(() => {
    switch (props.orderBumpData.template) {
      case 'tempA':
        return (
          <TemplateA
            {...props}
            checked={isChecked}
            onCheckChange={setIsChecked}
          />
        );
      case 'tempB':
        return (
          <TemplateB
            {...props}
            checked={isChecked}
            onCheckChange={setIsChecked}
          />
        );
      default:
        return <></>;
    }
  }, [props, isChecked]);
  return Component;
}
