import { Form, Select } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { gameficationCompetition } from '../../client.api';
import { ICompetitionType } from '../../dto/competition-type.interface';

interface ISegmentSelect {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const SegmentInput: FC<ISegmentSelect> = props => {
  const { required, disabled, ...rest } = props;

  const { isFetching, data } = useQuery(
    'gamefication_segments',
    gameficationCompetition.getSegment,
    { refetchOnWindowFocus: false }
  );

  const options = data?.map((option: ICompetitionType) => ({
    label: option.name,
    value: Number(option.id),
  }));

  const SELECT_PROPS = {
    loading: isFetching,
    disabled,
    options,
  };

  return (
    <Form.Item {...rest} rules={[{ required }]}>
      <Select {...SELECT_PROPS} data-testid='type' placeholder='Selecione...' />
    </Form.Item>
  );
};

export default SegmentInput;
