import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { SubscriptionsPagarMeResponse } from '../types/clientTypes';

const fetchSubscriptions = async (emailParamSubscription?: string) => {
  const response = await clientApi.listSubscriptionsPagarme(
    emailParamSubscription
  );
  if (!response) {
    return;
  }
  if ((response as SubscriptionsPagarMeResponse).status === Success) {
    return (response as SubscriptionsPagarMeResponse).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as assinaturas',
      'Ocorreu um erro  ao carregar as assinaturas, por favor tente novamente mais tarde.'
    );
  }
};

export default function useSubscriptionsPagarMe(email?: string) {
  return useQuery(['subscriptions', email], () => fetchSubscriptions(email), {
    refetchOnWindowFocus: false,
  });
}
