import styled from 'styled-components';

export const Container = styled.tr`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #303030;
`;

export const DragButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 15px 15px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 64fr 10fr 1fr;
  gap: 0px;
  width: 100%;
  padding: 15px;

  & > :nth-child(2) {
    /* Seleciona o segundo filho diretamente, que seria o segundo "grid item" no layout */
    text-align: end;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }
`;

export const TagContainer = styled.div``;
