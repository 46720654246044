import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

export interface IModalScreen {
  id: number;
  alias: string;
  keyAction: string;
  webLink: string;
}

export default async function getModalScreens(): Promise<IModalScreen[]> {
  const { data } = await base({
    baseURL: enviroments.BASE_URL,
    url: `/modal/actions`,
    method: 'GET',
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
