import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { dateFormatted } from 'src/app/helpers/DateHelper';

import { ManageCompetitionsResponseDto } from '../dto/manage-competitions.response.dto';
import { StyledTable } from '../styles';

type IManageCompetitionsTable = {
  dataSource: ManageCompetitionsResponseDto[] | undefined;
  loading: boolean;
};

export const GameficationManageCompetitionTable: FC<IManageCompetitionsTable> =
  props => {
    const history = useHistory();
    const ACTIONS = [
      {
        key: 'edit',
        label: 'Visualizar / Editar',
        onClick: (j: ManageCompetitionsResponseDto) =>
          history.push(`competition/${j.id}`),
      },
      {
        label: 'Remover',
        key: 'remove',
        onClick: (j: ManageCompetitionsResponseDto) =>
          console.info('modal remoção', j),
      },
    ];

    const columns = [
      { title: 'Nome', dataIndex: 'name', key: 'fullName' },

      {
        title: 'Período vigente',
        dataIndex: 'dateScheduled',
        width: 250,
        render: (value: Array<Date>) => {
          const [start, end] = value;
          const firstDate = dateFormatted(String(start));
          const lastDate = end ? dateFormatted(String(end)) : 'indefinido';
          return `${firstDate} a ${lastDate}`;
        },
      },

      { title: 'Inscritos', dataIndex: 'subscribers', width: 120 },

      {
        title: 'Cadastro',
        dataIndex: 'registrationCompleted',
        width: 120,
        render: (value: boolean) => (value ? 'Completo' : 'Incompleto'),
      },

      { title: 'Status', dataIndex: 'competitionStatus', width: 80 },

      {
        title: 'Data criação',
        dataIndex: 'createdAt',
        width: 120,
        render: (value: Date) => dateFormatted(String(value)),
      },

      {
        title: 'Ações',
        key: 'action',
        width: 20,
        render: (_item: ManageCompetitionsResponseDto) => (
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            overlay={() => (
              <Menu>
                {ACTIONS.map(({ label, onClick, key }) => (
                  <Menu.Item key={key} onClick={() => onClick(_item)}>
                    {label}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          >
            <Button type='text'>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];

    const PROPS = { ...props, columns, bordered: true, rowKey: 'id' };
    return (
      <StyledTable {...PROPS} pagination={{ position: ['bottomCenter'] }} />
    );
  };
