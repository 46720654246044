import { DataType, FunnelPublishingPayload } from '../types/FunnelEditTypings';

export const FunnelPublishingFactory = (payload: DataType[]) => {
  const factory: FunnelPublishingPayload[] = payload.map(
    (funnelData, index) => ({
      id: funnelData.key,
      name: funnelData.name,
      pageType: funnelData.pageType,
      active: funnelData.status === 'active' ? true : false,
      orderItem: index,
    })
  );

  return factory;
};
