import { Button, Form, Input, Modal } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as badgesApi from '../api/badgesApi';
import * as Styles from './styles';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const ModalAddEvent: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const [formData, setFormData] = useState({
    identifier: '',
    name: '',
    description: '',
    eventTypeId: 0,
  });

  const onSubmit = async () => {
    const res = await badgesApi.create({
      identifier: formData.identifier,
      name: formData.name,
      description: formData.description,
      eventTypeId: Number(formData.eventTypeId),
    });
    if (res.statusCode === 500) {
      OpenNotification(
        true,
        'Erro ao cadastrar o evento',
        'Verifique os dados informados e tente novamente.'
      );
    }
  };

  const handleChange = (evt: { target: { name: string; value: string } }) => {
    const value = evt.target.value;
    const name = evt.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title='Cadastrar Eventos'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={onSubmit}>
            Salvar
          </Button>,
        ]}
      >
        <Form style={{ width: '100%' }} layout='horizontal'>
          <Styles.Label>Identificador</Styles.Label>
          <Input
            placeholder='Padrão'
            onChange={handleChange}
            value={formData.identifier}
            name='identifier'
          />
          <Styles.Space />

          <Styles.Label>Tipo</Styles.Label>
          <Input
            placeholder='Padrão'
            onChange={handleChange}
            value={formData.eventTypeId}
            type='text'
            name='eventTypeId'
          />
          <Styles.Space />

          <Styles.Label>Nome do evento</Styles.Label>
          <Input
            placeholder='Aula assistida - Barriga seca'
            onChange={handleChange}
            value={formData.name}
            name='name'
          />
          <Styles.Space />

          <Styles.Label>Detalhes</Styles.Label>
          <Input
            placeholder='Os pontos são computados sempre que o usuário acessar um grupo colaborativo'
            onChange={handleChange}
            value={formData.description}
            name='description'
          />
          <Styles.Space />
        </Form>
      </Modal>
    </>
  );
};
