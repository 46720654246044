import { Segments } from '@models';
import {
  ActionsDropdown,
  ActiveTag,
  InctiveTag,
  SegmentTypeTag,
} from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { CountResponse } from '@typings';
import { useHistory } from 'react-router-dom';

const getAmountLabel = (count?: number) => {
  if (!count) return '';

  return `${count} Segmento${count > 1 ? 's' : ''} cadastrado${
    count > 1 ? 's' : ''
  }`;
};

interface TableEventsProps {
  filterComponent: React.ReactNode;
  onDeleteSegment: (id: string) => void;
  onChangePagination?: (take: number, skip: number) => void;
  segmentsWithCounts?: CountResponse<Segments.SegmentsDto>;
}

export const TableSegments: React.FC<TableEventsProps> = ({
  filterComponent,
  onDeleteSegment,
  onChangePagination,
  segmentsWithCounts,
}) => {
  const history = useHistory();
  const headerColumns: PageListProps<Segments.SegmentsDto>['columns'] = [
    {
      key: 'name',
      title: 'Nome do segmento',
      dataIndex: 'name',
    },
    {
      key: 'segmentType',
      title: 'Tipo',
      dataIndex: 'type',
      width: '180px',
      render(_, record: Segments.SegmentsDto) {
        return (
          <SegmentTypeTag type={record.type as Segments.TypeEnum} active />
        );
      },
    },
    {
      key: 'countBanners',
      title: 'Assets',
      dataIndex: 'countBanners',
      width: '140px',
      render(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
    },
    {
      key: 'countCustomers',
      title: 'Usuários',
      dataIndex: 'countCustomers',
      width: '140px',
      render(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
    },
    {
      key: 'countPrograms',
      title: 'Programas',
      dataIndex: 'countPrograms',
      width: '140px',
      render(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
    },
    {
      key: 'activated',
      title: 'Status',
      dataIndex: 'activated',
      width: '140px',
      render(_, record) {
        return record.activated ? <ActiveTag /> : <InctiveTag />;
      },
    },
    {
      title: 'Editar',
      key: 'action',
      width: '5%',
      dataIndex: 'index',
      render: (_: string | undefined, record: Segments.SegmentsDto) => {
        return (
          <ActionsDropdown
            onClickEditOption={() =>
              history.push('detalhes', { id: record.id })
            }
            onClickDeleteOption={() => onDeleteSegment(record.id || '')}
            deleteLabel={'Excluir'}
            disabled={record.type === Segments.TypeEnum.OPERATION}
          />
        );
      },
    },
  ];

  return (
    <PageListTemplate
      onChange={onChangePagination}
      amoutLabel={getAmountLabel(segmentsWithCounts?.count)}
      filterComponent={filterComponent}
      columns={headerColumns}
      data={segmentsWithCounts}
      loading={false}
    />
  );
};
