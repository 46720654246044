import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

import { DatePicker } from 'src/app/components/antd';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { clientApi } from 'src/features/clients/api/clientApi';
import {
  AdyenTransaction,
  ClientType,
  ScheduledCancellationResponse,
} from 'src/features/clients/types/clientTypes';

type ModalProps = {
  modalValuesCancelSchedule: {
    item: AdyenTransaction | undefined;
    visible: boolean;
  };
  setIsModalVisibleCancelSchedule: () => void;
  dados?: ClientType;
};

export const ModalCancelSchedule: FC<ModalProps> = ({
  modalValuesCancelSchedule,
  setIsModalVisibleCancelSchedule,
  dados,
}) => {
  const [step, setStep] = useState(1);
  const [scheduledDate, setScheduleDate] = useState<Date | undefined>();

  const onSubmit = async () => {
    const { item } = modalValuesCancelSchedule;
    const response = await clientApi.scheduledCancellation({
      idCustomer: dados?.id,
      scheduledDate: scheduledDate,
      idStatus: 1,
      idSubscription: item && parseInt(item.id),
      paymentProvider: item?.offer?.productSellGroups.paymentProvider,
      customerName: dados?.name,
      subscriptionName: item?.offer?.name,
    });
    if ((response as ScheduledCancellationResponse).result) {
      OpenNotification(false, 'Cancelamento efetuado com sucesso!', '');
      return setIsModalVisibleCancelSchedule();
    } else {
      return OpenNotification(
        true,
        'Erro ao agendar Cancelamento',
        'Ocorreu um erro ao efetuar cancelamento, por favor tente mais tarde!'
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalVisibleCancelSchedule();
  };

  const IsCancelScheduleButton = () => {
    return (
      <>
        <Button
          key='not'
          data-testid='not'
          onClick={() => {
            handleCloseModal();
          }}
        >
          Fechar
        </Button>
        <Button
          key='yes'
          data-testid='yes'
          type='primary'
          onClick={() => setStep(2)}
        >
          Solicitar
        </Button>
      </>
    );
  };

  const IsCancelScheduleButtoFinish = () => {
    return (
      <>
        <Button key='not' data-testid='not' onClick={() => setStep(1)}>
          Não
        </Button>
        <Button key='yes' data-testid='yes' type='primary' onClick={onSubmit}>
          Sim
        </Button>
      </>
    );
  };

  const CancelModal = () => (
    <>
      {step === 1 && (
        <Modal
          title='Data de agendamento'
          visible={modalValuesCancelSchedule.visible}
          onCancel={handleCloseModal}
          footer={[IsCancelScheduleButton()]}
        >
          <DatePicker
            placeholder='Selecionar data'
            format='dd/MM/yyyy'
            data-testid='scheduledDate'
            value={scheduledDate}
            onChange={(scheduledDateValue: Date | null) =>
              setScheduleDate(scheduledDateValue || undefined)
            }
            style={{ width: '100%' }}
          ></DatePicker>
        </Modal>
      )}
      {step === 2 && (
        <Modal
          title='Tem certeza que deseja agendar o cancelamento para esta data?'
          visible={modalValuesCancelSchedule.visible}
          onCancel={handleCloseModal}
          footer={[IsCancelScheduleButtoFinish()]}
        ></Modal>
      )}
    </>
  );

  return <>{CancelModal()}</>;
};
