import styled from 'styled-components';

export const CollapseContainer = styled.div`
  .ant-collapse-content-box {
    padding: 0;
  }
  margin-bottom: 24px;
`;

export const FilterContainer = styled.div`
  margin-bottom: 24px;
`;
