import styled from 'styled-components';

export const FilterConatiner = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  & > .ant-input-search {
    width: 360px;
  }
`;
