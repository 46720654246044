import { PageHeader } from '@organisms';
import { Divider } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { CreateOffer } from '../../components/offerExibitions/CreateOffer';
import { TableOffer } from '../../components/offerExibitions/OfferTable';
import useOfferPage from '../../hooks/useOfferPage';
import { OffersByProduct } from '../../interfaces/offersInterface';

interface OfferExibitonProps {
  product: OffersByProduct;
}

export const OfferExibiton: FC<OfferExibitonProps> = () => {
  useBreadcrumbs([
    {
      label: 'Ofertas',
    },
  ]);

  const [dataOffers, dataProduct, offerActive] = useOfferPage();

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={dataProduct?.name}
        subTitle={''}
      />
      <Text
        style={{
          color: 'rgba(255, 255, 255, 0.45)',
          fontSize: '14px',
          fontStyle: 'italic',
          lineHeight: '22px',
          fontWeight: 'normal',
        }}
      >
        Crie ou edite um oferta associada
      </Text>
      <Divider />
      <CreateOffer offerActive={offerActive} productId={dataProduct?.id} />
      <TableOffer data={dataOffers} productId={dataProduct?.id} />
    </>
  );
};

const Text = styled.h1`
  margin-bottom: 28px;
  font-size: 20px;
  line-height: 28px;
  color: #dbdbdb;
  font-weight: 600;
`;
