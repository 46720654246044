import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { TableComponent } from '@atoms';
import { Row, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useMemo, useState } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';

import { CustomerDataResponse } from '../../api/customerApi';
import { useCustomerSubscriptions } from '../../hooks/useCustomerSubscriptions';
import { CustomerSubscriptions } from '../../types/customer';
import { ErrorState } from '../ErrorState';

export type CustomerSubscriptionsProps = {
  customerData: CustomerDataResponse;
};

const { Text } = Typography;

export const CustomerSubscriptionsSection: React.FC<CustomerSubscriptionsProps> =
  ({ customerData }) => {
    const { id: customerId } = customerData.customer;
    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(5);

    const {
      subscriptions,
      pageSize,
      totalCount,
      isError,
      isLoading,
      refetchOnError,
    } = useCustomerSubscriptions(customerId, page, pageLimit);

    const handleChangePage = useCallback(
      (selectedPage: number, paginationSize: number | undefined) => {
        if (selectedPage !== page) setPage(selectedPage);
        if (paginationSize && paginationSize !== pageLimit)
          setPageLimit(paginationSize);
      },
      [page, pageLimit]
    );

    const renderTag = useCallback((value: string) => {
      const isYes = value === 'Sim';

      const tagConfig = {
        icon: isYes ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
        color: isYes ? 'success' : 'error',
        label: isYes ? 'Sim' : 'Não',
      };

      return (
        <Tag icon={tagConfig.icon} color={tagConfig.color}>
          {tagConfig.label}
        </Tag>
      );
    }, []);

    const tableColumns: ColumnsType<CustomerSubscriptions> = useMemo(
      () => [
        {
          title: 'Produto',
          dataIndex: 'productName',
          key: 'productName',
          sorter: (a, b) => a.productName.localeCompare(b.productName),
        },
        {
          title: 'Mês do produto',
          dataIndex: 'productMonth',
          key: 'productMonth',
          sorter: (a, b) => a.productMonth.localeCompare(b.productMonth),
        },
        {
          title: 'Tipo de acesso',
          dataIndex: 'accessType',
          key: 'accessType',
          sorter: (a, b) => a.accessType.localeCompare(b.accessType),
        },
        {
          title: 'Data de compra',
          dataIndex: 'purchaseDate',
          key: 'purchaseDate',
          render: (purchaseDate: string) => dateFormatted(purchaseDate, false),
          sorter: (a, b) =>
            new Date(a.purchaseDate).getTime() -
            new Date(b.purchaseDate).getTime(),
        },
        {
          title: 'Data de cancelamento',
          dataIndex: 'cancelDate',
          key: 'cancelDate',
          render: (cancelDate: string) => {
            if (dateFormatted(cancelDate, false) === 'Invalid Date') {
              return cancelDate;
            }
            return dateFormatted(cancelDate, false);
          },
          sorter: (a, b) =>
            new Date(a.cancelDate).getTime() - new Date(b.cancelDate).getTime(),
        },
        {
          title: 'Tipo de cliente',
          dataIndex: 'customerType',
          key: 'customerType',
          sorter: (a, b) => a.customerType.localeCompare(b.customerType),
        },
        {
          title: 'Renovação ativa',
          dataIndex: 'isAutoRenew',
          key: 'isAutoRenew',
          render: (isAutoRenew: string) => renderTag(isAutoRenew),
          sorter: (a, b) => a.isAutoRenew.localeCompare(b.isAutoRenew),
        },
        {
          title: 'Promocional',
          dataIndex: 'isPromotional',
          key: 'isPromotional',
          render: (isPromotional: string) => renderTag(isPromotional),
          sorter: (a, b) => a.isPromotional.localeCompare(b.isPromotional),
        },
      ],
      []
    );

    if (isError) {
      return (
        <Space
          direction='vertical'
          size='middle'
          style={{ display: 'flex', marginTop: '20px' }}
        >
          <Text style={{ fontSize: '16px', fontWeight: 600 }}>
            Dados de assinaturas
          </Text>
          <Row>
            <ErrorState
              title='Falha no carregamento'
              subtitle={
                'Infelizmente tivemos um problema no momento de carregar os dados de assinatura. Clique no botão abaixo para tentar novamente.'
              }
              buttonLabel='Recarregar dados de assinatura'
              onClickButton={() => refetchOnError()}
            />
          </Row>
        </Space>
      );
    }

    return (
      <Space
        direction='vertical'
        size='middle'
        style={{ display: 'flex', marginTop: '20px' }}
      >
        <Text style={{ fontSize: '16px', fontWeight: 600 }}>
          Dados de assinaturas
        </Text>
        <TableComponent
          columns={tableColumns}
          data={subscriptions}
          pageSize={pageSize}
          total={totalCount}
          loading={isLoading}
          currentPage={page}
          onChangePage={(selectedPage, paginationPageSize) =>
            handleChangePage(selectedPage, paginationPageSize)
          }
        />
      </Space>
    );
  };
