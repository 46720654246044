/* eslint-disable @typescript-eslint/no-empty-function */
import { UpsellProducts } from '@models';
import { PageListProps, PageListTemplate } from '@templates';

import { ProductDto } from 'src/features/offers/interfaces/offersInterface';

export interface TableUpsellProductsProps {
  filterComponent: React.ReactNode;
  data?: UpsellProducts.TUpsellProductsResponse;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditUpsellProducts?: (value: UpsellProducts.IUpsellProducts) => void;
  onChangeUrl?: (url: string) => void;
  onClickItem?: (productSellGroup: ProductDto) => void;
  onDuplicateOption?: (url: UpsellProducts.IUpsellProducts) => void;
  isLoading: boolean;
}

export type TColumnUrl = {
  column: string;
  inverse: boolean;
};

export const TableUpsellProducts: React.FC<TableUpsellProductsProps> = ({
  filterComponent,
  data,
  onChangePagination,
  isLoading,
  onClickItem,
}) => {
  const headerColumns: PageListProps<UpsellProducts.IUpsellProducts>['columns'] =
    [
      {
        title: 'Nome do produto',
        dataIndex: 'name',
        key: 'name',
        render: (name: UpsellProducts.IUpsellProducts['name']) => {
          return String(name);
        },
      },
      {
        title: 'Código',
        dataIndex: 'id',
        key: 'id',
        width: '120px',
        render: (id: UpsellProducts.IUpsellProducts['id']) => {
          return id;
        },
      },
    ];

  if (!data && isLoading) return null;

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={isLoading}
      onChange={onChangePagination}
      pageSize={5}
      onClickRow={item => {
        !!item && onClickItem?.(item);
      }}
    />
  );
};
