import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC } from 'react';

export type DeleteButtonComponentProps = {
  show?: boolean;
  disabled?: boolean;
  showText?: boolean;
  handleDeleteButton?: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const DeleteButtonComponent: FC<DeleteButtonComponentProps> = ({
  show = true,
  disabled = false,
  showText = true,
  onDeleteClick,
  handleDeleteButton,
}) => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    Modal.confirm({
      title: 'Apagar',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja realmente apagar?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        if (onDeleteClick) {
          onDeleteClick(event);
        }
        if (handleDeleteButton) {
          handleDeleteButton(event);
        }
      },
    });
  };

  return (
    <>
      {show ? (
        <Button
          data-testid='handle-cancel'
          disabled={disabled}
          onClick={e => onClick(e)}
          style={{
            marginLeft: '10px',
          }}
          type='link'
          danger
        >
          <DeleteOutlined />
          {showText ? 'Apagar' : null}
        </Button>
      ) : null}
    </>
  );
};
