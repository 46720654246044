import { Upload } from 'antd';
import styled from 'styled-components';

export const UploadContainer = styled(Upload)`
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 88px;
    padding: 0 24px;
    background-color: #242424;

    border: 1px dashed #434343;
    border-radius: 4px;
    margin: 0;
  }

  .ant-upload-list-item-name {
    color: rgba(255, 255, 255, 0.45);
  }
`;

export const DraggerContainer = styled(Upload.Dragger)`
  .ant-upload {
    background-color: #242424;
  }
`;
