import { AdvancedCard } from '@molecules';
import React, { Key } from 'react';

import * as S from './ModelScreen.style';

interface CardProps<T> {
  title: string;
  icon: React.ReactNode;
  value: T;
  disabled?: boolean;
}

interface CardScreenProps<T> {
  selected?: T;
  onSelectCard?: (value: T) => void;
  cardList?: CardProps<T>[];
}

export const CardScreen = <T extends Key | null | undefined>({
  selected,
  onSelectCard,
  cardList,
}: CardScreenProps<T>) => {
  return (
    <S.Container>
      {cardList?.map(card => (
        <AdvancedCard
          key={card.value}
          title={card.title}
          icon={card.icon}
          disabled={card.disabled}
          selected={card.value === selected}
          onClick={() =>
            card.value !== selected ? onSelectCard?.(card.value) : () => null
          }
        />
      ))}
    </S.Container>
  );
};
