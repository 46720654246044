import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { ActionsDropdownProps } from './ActionsDropdown.interface';

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  onClickEditOption,
  onClickDeleteOption,
  onClickPreviewOption,
  hideDelete = false,
  onClickDuplicateOption,
  deleteLabel,
  disabled,
}) => {
  const handleClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'EDIT':
        onClickEditOption?.();
        break;
      case 'DELETE':
        onClickDeleteOption?.();
        break;
      case 'DUPLICATE':
        onClickDuplicateOption?.();
        break;
      case 'PREVIEW':
        onClickPreviewOption?.();
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'PREVIEW',
      label: 'Visualizar',
      icon: <EyeOutlined />,
      style: { display: onClickPreviewOption ? 'inherit' : 'none' },
      disabled,
    },
    {
      key: 'EDIT',
      label: 'Editar',
      icon: <EditOutlined />,
      style: { display: onClickEditOption ? 'inherit' : 'none' },
      disabled,
    },
    hideDelete
      ? null
      : {
          key: 'DELETE',
          label: `Apagar`,
          icon: <DeleteOutlined />,
          style: { display: onClickDeleteOption ? 'inherit' : 'none' },
          danger: !disabled,
          disabled,
        },
    {
      key: 'DUPLICATE',
      label: 'Duplicar',
      icon: <CopyOutlined />,
      style: { display: onClickDuplicateOption ? 'inherit' : 'none' },
      disabled,
    },
  ];

  return (
    <Dropdown
      disabled={disabled}
      placement='bottomRight'
      menu={{ items, onClick: handleClick }}
      trigger={['click']}
    >
      <CustomButton
        disabled={disabled}
        data-testid='menu-test'
        style={{ border: 'none' }}
      >
        <MoreOutlined />
      </CustomButton>
    </Dropdown>
  );
};

export type { ActionsDropdownProps };

const CustomButton = styled(Button)`
  &:hover {
    background-color: rgba(62, 62, 62, 0.7);
    color: white;
  }
`;
