import {
  CheckboxController,
  Controller as OrganismController,
  RadioController,
  SwitchController,
  TextAreaController,
  UploadBiggerController,
} from '@organisms';
import { Col, Row } from 'antd';

import { ModelStepBaseComponentProp } from '../ModalDetail';

export const BaseContent = <T extends unknown>({
  form,
  fieldArrayName,
  index,
}: ModelStepBaseComponentProp<T>) => {
  return (
    <>
      <Row style={{ paddingTop: '12px' }}>
        <Col span={24}>Tipo de mídia</Col>
      </Row>

      <Row style={{ paddingTop: '8px' }}>
        <Col span={24}>
          <RadioController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].mediaType`
                : 'mediaType'
            )}
            control={form.control}
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].mediaType`
                : 'mediaType'
            }
            options={[
              {
                label: 'Vídeo (.mp4)',
                value: 'VIDEO',
              },
              {
                label: 'Imagem (.jpg, .png ou .gif)',
                value: 'IMAGE',
              },
            ]}
          />
        </Col>
      </Row>

      {form.watch(
        fieldArrayName ? `${fieldArrayName}[${index}].mediaType` : 'mediaType'
      ) === 'VIDEO' && (
        <Row style={{ paddingTop: '24px' }}>
          <Col span={24}>
            <OrganismController
              {...form.register(
                fieldArrayName
                  ? `${fieldArrayName}[${index}].urlVideoModal`
                  : 'urlVideoModal'
              )}
              control={form.control}
              label='Vídeo'
              name={
                fieldArrayName
                  ? `${fieldArrayName}[${index}].urlVideoModal`
                  : 'urlVideoModal'
              }
              placeholder='Insira o ID do vídeo (Spalla)'
              autoComplete='off'
            />
          </Col>
        </Row>
      )}

      {form.watch(
        fieldArrayName ? `${fieldArrayName}[${index}].mediaType` : 'mediaType'
      ) === 'IMAGE' && (
        <>
          <Row style={{ paddingTop: '24px' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <b>Imagem web</b>
            </Col>
            <Col span={12} style={{ paddingLeft: '12px' }}>
              <b>Imagem mobile</b>
            </Col>
          </Row>

          <Row style={{ paddingTop: '12px' }}>
            <Col span={12}>
              <UploadBiggerController
                {...form.register(
                  fieldArrayName
                    ? `${fieldArrayName}[${index}].imageWeb`
                    : 'imageWeb'
                )}
                control={form.control}
                name={
                  fieldArrayName
                    ? `${fieldArrayName}[${index}].imageWeb`
                    : 'imageWeb'
                }
              />
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <UploadBiggerController
                {...form.register(
                  fieldArrayName
                    ? `${fieldArrayName}[${index}].imageMobile`
                    : 'imageMobile'
                )}
                control={form.control}
                name={
                  fieldArrayName
                    ? `${fieldArrayName}[${index}].imageMobile`
                    : 'imageMobile'
                }
              />
            </Col>
          </Row>
        </>
      )}

      <Row style={{ paddingTop: '12px' }}>
        <Col span={21}>
          <OrganismController
            {...form.register(
              fieldArrayName ? `${fieldArrayName}[${index}].title` : 'title'
            )}
            control={form.control}
            name={
              fieldArrayName ? `${fieldArrayName}[${index}].title` : 'title'
            }
            label='Titulo'
            placeholder='Titulo do modal'
            autoComplete='off'
            showCount
            maxLength={110}
          />
        </Col>
        <Col span={3} style={{ paddingLeft: '15px' }}>
          <SwitchController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].titleActivated`
                : 'titleActivated'
            )}
            sideLabel
            control={form.control}
            rightLabel='Ativo'
            leftLabel='Inativo'
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].titleActivated`
                : 'titleActivated'
            }
            defaultValue={true}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: '24px' }}>
        <Col span={24}>
          <TextAreaController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].description`
                : 'description'
            )}
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].description`
                : 'description'
            }
            control={form.control}
            label='Descritivo'
            placeholder='Descrição do Modal'
            autoComplete='off'
            row={3}
            showCount
            maxLength={179}
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: '12px' }}>
        <Col span={12} style={{ paddingRight: '12px' }}>
          <OrganismController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].textButton`
                : 'textButton'
            )}
            control={form.control}
            label='Texto do botão'
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].textButton`
                : 'textButton'
            }
            placeholder='Texto do botão'
            autoComplete='off'
            showCount
            maxLength={110}
          />
        </Col>

        <Col span={12} style={{ paddingLeft: '12px' }}>
          <OrganismController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].redirectButtonUrl`
                : 'redirectButtonUrl'
            )}
            control={form.control}
            label='Redirect do botão'
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].redirectButtonUrl`
                : 'redirectButtonUrl'
            }
            placeholder='Insira a URL de redirecionamento para o Clique do Botão'
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} style={{ paddingRight: '12px' }}></Col>

        <Col span={12} style={{ paddingLeft: '12px' }}>
          <CheckboxController
            {...form.register(
              fieldArrayName
                ? `${fieldArrayName}[${index}].openExternalWindow`
                : 'openExternalWindow'
            )}
            control={form.control}
            name={
              fieldArrayName
                ? `${fieldArrayName}[${index}].openExternalWindow`
                : 'openExternalWindow'
            }
            defaultValue={false}
          >
            Abrir em uma página externa
          </CheckboxController>
        </Col>
      </Row>
    </>
  );
};
