import { Assets } from '@models';

type ImageURL = string;

interface IDynamicBannerOptions {
  super: File | ImageURL | any;
  highlight?: ImageURL;
}

interface IOptions {
  id?: number | null;
  name?: string | null;
  expressionId?: number | null;
}

interface IInstructor {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  linkImage?: ImageURL | null;
}

interface IActions {
  type?: string | null;
  label?: string | null;
  extraParam?: string | null;
}

export interface IDynamicBanner {
  tv?: IDynamicBannerOptions;
  mobile?: IDynamicBannerOptions;
  tablet?: IDynamicBannerOptions;
  login?: IDynamicBannerOptions;
}
export interface IBackgroundBanner {
  tv?: ImageURL | any;
  web?: ImageURL;
  mobile?: ImageURL;
}

export interface AssetsSegment {
  id: string;
  idAsset: string;
  segmentId: number;
  segment: {
    id: number;
    name: string;
  };
}

export interface IAssetSegments {
  id: string;
  segment: {
    id: number;
    name: string;
  };
  segmentId: number;
}

export interface IBannerOrderingList {
  id: number;
  order: number;
  segmentId: number;
}

export interface IBannerDevice {
  id: number;
  assetId: string;
  fileUuid: string;
  device: {
    id: number;
    deviceName: string;
    version: string;
  };
  deviceChannelId: number;
}

export interface IBannerDetail {
  id: number;
  title: string;
  titleActivated: boolean;
  buttonLabelActivate: boolean;
  buttonLabelText: string;
  description: string;
  descriptionActivated: boolean;
  orderingList: IBannerOrderingList;
  devices: IBannerDevice[];
  linkActivate: boolean;
  link?: string;
  programBasedActivated: boolean;
  programBased: number;
}

interface IAssetContextInner {
  id: string;
  identifier: AssetContext;
}

export interface IAssetContext {
  id: string;
  context: IAssetContextInner;
}

export interface IAsset {
  id: string;
  activated: boolean;
  name: string;
  description: string;
  effectiveDateTime: string;
  expirationDateTime: string;

  banner: IBannerDetail;
  segments: IAssetSegments[];
  contexts: IAssetContext[];
  devices: IBannerDevice[];
}

export interface IRequestOrderingAsset {
  assetUuid: string;
  deviceChannelId: number;
  order: number;
  segmentId: number;
}

export interface BannerHookForm {
  title: string;
  status?: boolean;
  buttonLabel?: string;
  buttonLabelActive: boolean;
  description?: string;
  descriptionActive: boolean;
  effectiveDateTime?: string;
  expirationDateTime?: string;
  link?: string;
  linkActive?: boolean;
  programBased?: string;
  programBasedActive?: boolean;
  segments: number[];
}

export interface IOrderingBanners {
  id: number;
  idBanner: number;
  idSegment?: number;
  order: number;
}

export interface FetchAssetDetail {
  id?: number;
  segmentIds?: number[];
  title: string;
  titleActive?: boolean;
  logo?: string | null;
  tag?: string | null;
  userType: string;
  order: number;
  published: boolean;
  liveStartsAt?: string | null;
  backgrounds?: IBackgroundBanner;
  dynamicBanner?: IDynamicBanner;
  segmentId?: string | null;
  description?: string;
  descriptionActive?: boolean;
  buttonLabel?: string;
  buttonLabelActive?: boolean;
  meanDuration?: string;
  level?: IOptions | null;
  objective: IOptions;
  instructor: IInstructor;
  actions: IActions[];
  type?: string;
  languages: string | null;
  device: string | null;
  status: string;
  segments?: number[];
  publishDate: string;
  endDate?: string | null;
  programId?: number | null;
  link?: string | null;
  orderingBanners?: Array<IOrderingBanners>;
  programBasedActive: boolean;
  programBased: string;
}

export interface IPostAsset {
  asset: {
    id?: string;
    name: string;
    type: 'BANNER';
    description: string;
    activated: boolean;
    contextsIdentifier: AssetContext[];
    expirationDateTime: number;
    effectiveDateTime: number;
  };
  bannerParams: {
    title: string;
    segmentIds: number[];
    description: string;
    link?: string;
    titleActivated: boolean;
    descriptionActivated: boolean;
    buttonLabelActivated: boolean;
    buttonLabelText: string;
  };
}

export interface ReorderedBanner extends FetchAssetDetail {
  key?: string;
}

export enum DeviceTypeEnum {
  WEB_PLATFORM = 'web',
  APP = 'mobile',
  SMART_TV = 'tv',
  TABLET = 'tablet',
  SMARTWATCH = 'smartwatch',
}

export enum AssetContext {
  NUTRI = 'NUTRI',
  INDIQUE_GANHE = 'INDIQUE_GANHE',
  HOME = 'HOME',
  BACKGROUND_LOGIN = 'BACKGROUND_LOGIN',
  BADGES = 'BADGES',
  COACH = 'COACH',
}

export const getBannerDimension = (
  context: Assets.AssetContext,
  device: DeviceTypeEnum
) => {
  return DeviceDimension[context][device];
};

export const DeviceDimension: Record<string, any> = {
  [`${AssetContext.BACKGROUND_LOGIN}`]: {
    [`${DeviceTypeEnum.WEB_PLATFORM}`]: {
      small: 'Desktop - 720 x 810',
      mid: 'Tablet - 1440 x 1680',
      large: 'Mobile - 2160 x 2520',
    },
    [`${DeviceTypeEnum.APP}`]: {
      small: 'Versão 1x - 360 x 640',
      mid: 'Versão 2x - 720 x 1280',
      large: 'Versão 3x - 1080 x 1920',
    },
    [`${DeviceTypeEnum.SMART_TV}`]: {
      small: 'Versão 1x - 1920 x 1080',
      mid: 'Versão 2x - 2048 x 1080',
      large: 'Versão 3x - 3840 x 2160',
    },
  },

  [`${AssetContext.HOME}`]: {
    [`${DeviceTypeEnum.WEB_PLATFORM}`]: {
      small: 'Mobile - 654 x 980',
      mid: 'Tablet - 1434 x 1434',
      large: 'Desktop - 2208 x 828',
    },
    [`${DeviceTypeEnum.APP}`]: {
      small: 'Versão 1x - 327 x 490',
      mid: 'Versão 2x - 654 x 980',
      large: 'Versão 3x - 981 x 1470',
    },
    [`${DeviceTypeEnum.SMART_TV}`]: {
      small: 'Versão 1x - 1920 x 512',
      mid: 'Versão 2x - 3840 x 11024',
      large: 'Versão 3x - 5760 x 1536',
    },
  },

  [`${DeviceTypeEnum.WEB_PLATFORM}_small`]: '720 x 840',
  [`${DeviceTypeEnum.WEB_PLATFORM}_mid`]: '1440 x 1680',
  [`${DeviceTypeEnum.WEB_PLATFORM}_large`]: '2160 x 2520',

  [`${DeviceTypeEnum.SMARTWATCH}_small`]: '1',
  [`${DeviceTypeEnum.SMARTWATCH}_mid`]: '2',
  [`${DeviceTypeEnum.SMARTWATCH}_large`]: '3',

  [`${DeviceTypeEnum.SMART_TV}_small`]: '1920 x 1',
  [`${DeviceTypeEnum.SMART_TV}_mid`]: '2',
  [`${DeviceTypeEnum.SMART_TV}_large`]: '3',
};

export const DeviceVersionSuffix: Record<string, string> = {
  1: '_small',
  2: '_mid',
  3: '_large',
};

export const DeviceVersion: Record<string, string> = {
  [`${DeviceTypeEnum.APP}_small`]: '1',
  [`${DeviceTypeEnum.APP}_mid`]: '2',
  [`${DeviceTypeEnum.APP}_large`]: '3',

  [`${DeviceTypeEnum.WEB_PLATFORM}_small`]: '1',
  [`${DeviceTypeEnum.WEB_PLATFORM}_mid`]: '2',
  [`${DeviceTypeEnum.WEB_PLATFORM}_large`]: '3',

  [`${DeviceTypeEnum.SMARTWATCH}_small`]: '1',
  [`${DeviceTypeEnum.SMARTWATCH}_mid`]: '2',
  [`${DeviceTypeEnum.SMARTWATCH}_large`]: '3',

  [`${DeviceTypeEnum.SMART_TV}_small`]: '1',
  [`${DeviceTypeEnum.SMART_TV}_mid`]: '2',
  [`${DeviceTypeEnum.SMART_TV}_large`]: '3',
};

export const AssetContextLabel: Record<AssetContext, string> = {
  [AssetContext.NUTRI]: 'Banner +Q Nutri',
  [AssetContext.HOME]: 'Banner Home',
  [AssetContext.INDIQUE_GANHE]: 'Banner Indique e Ganhe',
  [AssetContext.BACKGROUND_LOGIN]: 'Background Login',
  [AssetContext.BADGES]: 'Banner Medalhas',
  [AssetContext.COACH]: 'Banner Treinador',
};

export const AssetContextOptions = [
  {
    label: AssetContextLabel[AssetContext.NUTRI],
    value: AssetContext.NUTRI,
  },
  {
    label: AssetContextLabel[AssetContext.HOME],
    value: AssetContext.HOME,
  },
  {
    label: AssetContextLabel[AssetContext.INDIQUE_GANHE],
    value: AssetContext.INDIQUE_GANHE,
  },
  {
    label: AssetContextLabel[AssetContext.NUTRI],
    value: AssetContext.BACKGROUND_LOGIN,
  },
  {
    label: AssetContextLabel[AssetContext.BADGES],
    value: AssetContext.BADGES,
  },
];

export interface AssetsFilter {
  title?: { iLike: string };
  status?: string;
  publishDate?: {
    between: string[];
  };
  endDate?: {
    between: string[];
  };
}

export interface AssetsOrdering {
  type: 'BANNER';
  deviceName: DeviceTypeEnum;
  segmentId: number;
  context: AssetContext;
}
