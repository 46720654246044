/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { useBadgeGroup } from '@hooks';
import { PageHeader } from '@organisms';
import { Col, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AlertComponent } from 'src/app/components/antd/Alert';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { IDefaultIdNameResponseDto } from 'src/core/dto/default-id-name-response.dto';
import { IBadgeGroupResponseDto } from 'src/core/gamefication/dto/badge-group/badge-group-response.dto';

import { useGetAllCompetitions } from '../hooks/use-get-all-competitions';
import * as Styles from './styles';

export default function BadgesGroupsList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [listCompetitions] = useGetAllCompetitions();

  const { filterBadgeGroups, getAllBadgeGroups, badgeGroupsList } =
    useBadgeGroup();

  const onClickRow = (item: IBadgeGroupResponseDto) => {
    history.push(`/social/gamefication/badges/groups/${item.id}`);
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(slices.gamefication.clearBadgeGroup());
    getAllBadgeGroups();
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const createBadgeGroup = () => {
    history.push(`/social/gamefication/badges/groups/create`);
  };

  const getCompetitioOptions = () => {
    const options = listCompetitions.map(item => ({
      label: item.name,
      value: item.id,
    }));
    options.push({ label: 'Todas as competições', value: -1 });

    return options;
  };

  return (
    <>
      <Row>
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Gestão de grupo de badges'}
          subTitle={''}
        />
      </Row>
      <Row>
        <Col span={24}>
          <Styles.HeaderContainer>
            <Styles.ButtonContainer>
              <Styles.ButtonInput
                icon={<PlusCircleOutlined />}
                type='primary'
                onClick={createBadgeGroup}
              >
                Cadastrar novo Grupo
              </Styles.ButtonInput>
            </Styles.ButtonContainer>

            <Styles.InputFilterContainer>
              <Select
                style={{ width: '100%', marginRight: '14px' }}
                allowClear
                defaultValue={-1}
                onChange={value => filterBadgeGroups({ competitionId: value })}
                options={getCompetitioOptions()}
              />
              <Input
                suffix={<SearchOutlined />}
                placeholder='Pesquise pelo nome do grupo'
                onChange={evt => filterBadgeGroups({ name: evt.target.value })}
              />
            </Styles.InputFilterContainer>
          </Styles.HeaderContainer>
        </Col>
      </Row>
      <TableComponent
        style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
          },
          {
            title: 'Badges',
            dataIndex: 'countBadgesActivated',
          },
          {
            title: 'Data da solicitação',
            dataIndex: 'createdAt',
            render: (createdAt: string) => (
              <div>{dateFormatted(createdAt, true)}</div>
            ),
          },
          {
            title: 'Competicao',
            dataIndex: 'competition',
            render: (competition: IDefaultIdNameResponseDto) => (
              <p>{competition?.name}</p>
            ),
          },
          // {
          //   title: 'Ações',
          //   dataIndex: 'acoes',
          //   render: () => <div></div>,
          //   sorter: true,
          // },
        ]}
        pageSize={10}
        data={badgeGroupsList}
        total={badgeGroupsList ? badgeGroupsList.length : 0}
        onClickRow={onClickRow}
      />

      {visible ? (
        <AlertComponent
          message='Badge cadastrado com sucesso'
          type='success'
          close={handleClose}
        />
      ) : null}
    </>
  );
}
