import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import { EventsState } from './events.interface';
import * as eventsThunk from './events.thunk';

export const createEvent = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(eventsThunk.createEvent.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    eventsThunk.createEvent.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }

      state.eventDetail = undefined;
    }
  );

  builder.addCase(
    eventsThunk.createEvent.rejected,
    (state, { payload }: any) => {
      console.log('ERROR EVENT THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
