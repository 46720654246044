import * as S from './Highlight.style';

const Highlight = () => {
  return (
    <>
      <S.ModalModelImageSkeleton />

      <S.ModalModelSpace>
        <S.ModalModelTitleSkeleton />
        <S.ModalModelDescriptionSkeleton />
        <S.ModalModelDescriptionSkeleton />
        <S.Space />
        <S.ModalModelContentSkeleton />
        <S.ModalModelContentSkeleton />
      </S.ModalModelSpace>
    </>
  );
};
export default Highlight;
