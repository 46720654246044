import { PageHeader } from '@organisms';
import { Spin } from 'antd';

import { LayoutConfirmationFooter } from '../../../molecules/LayoutConfirmationFooter/LayoutConfirmationFooter.molecule';
import { DigitalCouponForm } from '../components/CouponForm/DigitalCouponForm.component';
import { useProductDigitalCoupon } from '../hooks/useProductDigitalCoupon';

export function CreateCouponDigitalPage() {
  const productCoupon = useProductDigitalCoupon();

  return (
    <Spin spinning={productCoupon.isLoading}>
      <LayoutConfirmationFooter
        onCancel={productCoupon.handleCancel}
        onSave={productCoupon.dispatchSubmit}
        cancelLabelButton='Descartar'
        ableToSave={
          !!productCoupon.destinationOfferId &&
          productCoupon.destinyOfferStatus === 'success'
        }
      >
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Formulário para a criação do Cupom'}
          subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
        />

        <DigitalCouponForm couponHookData={productCoupon} />
      </LayoutConfirmationFooter>
    </Spin>
  );
}
