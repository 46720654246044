import { RootState } from '@core/redux';

import { SerializedApiError } from 'src/app/typings/global';

import { TokenPayload } from './auth.interface';

export const selectIsAuth = (state: RootState): boolean =>
  state.auth.status === 'AUTH';

export const selectIsAuthPending = (state: RootState): boolean =>
  state.auth.status === 'PENDING';

export const selectIsRestoringAuth = (state: RootState): boolean =>
  state.auth.status === 'RESTORING';

export const selectHasAuthError = (state: RootState): boolean =>
  state.auth.status === 'ERROR';

export const selectAuthErrorDetails = (
  state: RootState
): SerializedApiError | undefined => state.auth.errorDetails;

export const selectAuthMetadata = (
  state: RootState
): TokenPayload | undefined => state.auth.payload;

export const selectUserEmail = (state: RootState): string | undefined =>
  state.auth.email;
