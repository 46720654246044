import { HourglassOutlined } from '@ant-design/icons';

import { ModalStepTwo } from './ModalStepTwo';

export const ModalSearch: React.FC = () => {
  return (
    <ModalStepTwo
      title={'Buscando dados'}
      firstText={'Por favor aguarde entre 3 a 5 minutos.'}
      secondText={'Estamos buscando os dados solicitados.'}
      icon={<HourglassOutlined style={{ fontSize: '36px' }} />}
      hasButton={false}
    />
  );
};
