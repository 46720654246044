import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { roleApi } from '../api/roleApi';

const fetchRoles = async () => {
  try {
    const roles = await roleApi.listRoles();
    return roles.role;
  } catch (error) {
    OpenNotification(
      true,
      'Erro ao carregar as transações',
      'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useGetAllRoles() {
  return useQuery('listAllRoles', () => fetchRoles(), {
    refetchOnWindowFocus: false,
  });
}
