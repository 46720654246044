import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { HotmartTransactionApiResponse } from '../types/clientTypes';

const fetchHotmartAssign = async (emailParamHotmartAssign?: string) => {
  const response = await clientApi.getHotmartTableTransactions(
    emailParamHotmartAssign
  );
  if ((response as HotmartTransactionApiResponse).status === Success) {
    return (response as HotmartTransactionApiResponse).data.transactions;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar as transações',
      'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useTransactionsHotmart(email?: string) {
  return useQuery(
    ['transactionHotmart', email],
    () => fetchHotmartAssign(email),
    {
      refetchOnWindowFocus: false,
    }
  );
}
