import { Input } from 'antd';
import { FC } from 'react';
interface Props {
  value?: string;
  dataIndex: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  disabled?: boolean;
  index?: number;
}

export const BadgeTextoDescritivoTableCell: FC<Props> = ({
  value = '',
  disabled,
  dataIndex,
  onChange,
}) => {
  if (disabled) {
    return <>{value}</>;
  }

  return <Input name={dataIndex} onChange={onChange} value={value} />;
};
