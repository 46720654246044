import { RootState, slices } from '@core/redux';
import { ModalAlert } from '@molecules';
import { Col, Divider, Form, Row } from 'antd';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IBadgeRequestDto,
  IBadgeRuleCompetitionEventRequestDto,
} from 'src/core/gamefication/dto/badge-group/badge-request.dto';
import { CompetitionEventModalAddResponseDto } from 'src/core/gamefication/dto/competition-event/competition-event-modal-add-response.dto';
import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import * as badgesGroupsApi from '../../api/badgesGroupsApi';
import { BadgeForm } from '../badge-collapse/badge-form';
import { BadgeEventsTable } from '../badge-collapse/table-events';
import { ModalAddRegra } from '../ModalAddRegra';
import { SaveEditDeleteButtons } from '../SaveEditDeleteButtons';
import { useBadge } from './Badge.hook';

type ModalProps = {
  badge: IBadgeRequestDto;
  index?: number;
  onBadgeDeleteClick?: (indexClick: number) => void;
  listEvents?: Array<EventResponseDto>;
};

export const BadgeComponent: FC<ModalProps> = ({
  badge,
  index = 0,
  listEvents = [],
  onBadgeDeleteClick,
}) => {
  const dispatch = useDispatch();

  const { status, badgeOnFocus } = useSelector(
    (state: RootState) => state.gamification
  );

  const [competitionEventForm] =
    Form.useForm<IBadgeRuleCompetitionEventRequestDto>();

  const {
    badgeFormData,
    hasBadgeFormEditMode,
    enableEditMode,
    editBadgeFormData,
    editBadgeEvent,
    discardBadgeFormChanges,
    saveAllBadge,
  } = useBadge(badge);

  const [isModalRegraVisible, setIsModalRegraVisible] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleDiscardBadgeClick = () => {
    if (badge.isNew) {
      setShowAlertModal(true);
      return;
    }
    discardBadgeFormChanges();
  };

  const handleDiscardLocalBadge = () => {
    dispatch(slices.gamefication.deleteLocalBadge(badge.id));
    dispatch(slices.gamefication.clearBadgeOnEdition());
  };

  const handleEditBadgeClick = () => {
    enableEditMode();
  };

  const handleSaveBadgeClick = async () => {
    saveAllBadge(competitionEventForm);
  };

  const handleDeleteBadgeClick = () => {
    onBadgeDeleteClick?.(badge.id);
  };

  const handleToggleModalEvent = (value?: boolean) => {
    toggleEventModal?.(value);
  };

  const onCompetitionEventSaveClick = (
    selectedEvents: Array<CompetitionEventModalAddResponseDto>
  ) => {
    const competitionEventsEdited = [
      ...(badgeFormData.competitionEvents || []),
    ];

    if (!badgeOnFocus) return;

    selectedEvents.forEach(item => {
      competitionEventsEdited?.push({
        id: +`${Date.now()}${competitionEventsEdited.length}`,
        displayName: item.events[0].description,
        isNew: true,
        isDisabledForm: false,
        events: item.events,
        competitionEventType: {
          id: 0,
        },
        competition: {
          id: 0,
        },
        limitOccurrence: 0,
        points: 0,
        startDate: new Date(),
        badgeRule: {
          key: `badge-rule-${Date.now()}`,
          amount: 0,
          id: 0,
          isNew: true,
          order: 0,
        },
      });
    });

    editBadgeEvent(competitionEventsEdited);
    handleEditBadgeClick();
  };

  const handleFormDataChange = (name: string, value?: string | number) => {
    const newBadge = { ...badgeFormData, [name]: value };
    editBadgeFormData(newBadge as IBadgeRequestDto);
  };

  const toggleEventModal = (value?: boolean) => {
    if (typeof value === 'undefined') {
      setIsModalRegraVisible(!isModalRegraVisible);
      return;
    }
    setIsModalRegraVisible(value as boolean);
  };

  const handleCompetitionEventOnDelete = async (
    indexCompetitionEvent: number,
    record: IBadgeRuleCompetitionEventRequestDto
  ) => {
    const lista = [...(badgeFormData.competitionEvents || [])];
    if (record.isNew) {
      lista.splice(indexCompetitionEvent, 1);
    } else {
      if (!record.id) {
        return;
      }
      lista.splice(indexCompetitionEvent, 1);
      await badgesGroupsApi.deleteCompetitionEvent(record.id);
    }
    dispatch(
      slices.gamefication.badgeSlice.getBadgeCompetitionEventList(badge.id)
    );
  };

  const handleCompetitionEventOnChange = async (
    record: IBadgeRuleCompetitionEventRequestDto[]
  ) => {
    editBadgeEvent(record);
  };

  let idsEventos: number[] = [];
  for (const compEvent of badgeFormData.competitionEvents || []) {
    if (!compEvent.events) {
      continue;
    }
    idsEventos = [...idsEventos, ...compEvent.events?.map(p => p.id)];
  }

  const eventosDisponiveis =
    idsEventos.length > 0
      ? listEvents.filter(p => idsEventos.indexOf(p.id) === -1)
      : listEvents;

  return (
    <>
      <BadgeForm
        index={index}
        formData={badgeFormData}
        onFormDataChange={handleFormDataChange}
        enableForm={hasBadgeFormEditMode}
      />

      <BadgeEventsTable
        addRulesDisabled={false}
        badgeForm={competitionEventForm}
        editionMode={hasBadgeFormEditMode}
        listCompetitionEvents={badgeFormData.competitionEvents || []}
        toggleEventModal={handleToggleModalEvent}
        onDelete={handleCompetitionEventOnDelete}
        onChange={handleCompetitionEventOnChange}
      />

      <Divider />

      <Row>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <SaveEditDeleteButtons
            loading={status === 'PENDING'}
            disabled={false}
            showDeleteButton={!hasBadgeFormEditMode}
            showDiscard={hasBadgeFormEditMode}
            showSave={hasBadgeFormEditMode}
            showEdit={!hasBadgeFormEditMode}
            onEditClick={handleEditBadgeClick}
            onSaveClick={handleSaveBadgeClick}
            onDiscardClick={handleDiscardBadgeClick}
            onDeleteClick={handleDeleteBadgeClick}
          />
        </Col>
      </Row>

      <ModalAddRegra
        isModalVisible={isModalRegraVisible}
        events={eventosDisponiveis}
        onSaveClick={onCompetitionEventSaveClick}
        toggleEventModal={toggleEventModal}
      />

      <ModalAlert
        showModal={showAlertModal}
        confirmButtonAction={handleDiscardLocalBadge}
        denyButtonAction={() => setShowAlertModal(false)}
        titleLabel={'Descartar alterações?'}
        messageLabel={
          'Não houve alteração salvas na badge após cria-la, por isso ao descartar as alterações essa badge será deletada.'
        }
      />
    </>
  );
};
