import { Collapse, Layout } from 'antd';

import { useCreateOrderBump } from '../../../../hooks/useCreateOrderBump';
import { ColorField } from './components/ColorField';
import { DescriptionField } from './components/DescriptionField';
import { ImageField } from './components/ImageField';
import { InputField } from './components/InputField';
import { PreviewOrderBump } from './components/PreviewOrderBump';
import { TemplateSelect } from './components/TemplateSelect';
import { Container, Content } from './styles';
import { useContent } from './useContent';

const { Sider } = Layout;
const { Panel } = Collapse;

export function Contents() {
  const {
    template,
    title,
    confirmationButtonText,
    description,
    primaryColor,
    secondaryColor,
    backgroundColor,
    textColor,
    linkImageResponsive,
    linkImageWeb,
  } = useCreateOrderBump();

  useContent();

  return (
    <Container>
      <Layout
        style={{
          background: 'transparent',
          gap: 24,
        }}
      >
        <Sider style={{ background: 'transparent' }} width={'40%'}>
          <Collapse
            defaultActiveKey={['1']}
            style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <Panel header='Informações textuais' key='1'>
              <TemplateSelect
                onChange={template.setter}
                value={template.value}
              />
              <InputField
                onChange={title.setter}
                value={title.value}
                label='Título'
                placeholder='10% de desconto somente agora!'
              />
              <DescriptionField
                value={description.value}
                onChange={description.setter}
                label='Descrição'
                placeholder='Por apenas mais R$5,90 por mês, você terá dois acessos para treinar junto com quem quiser. Esta oportunidade única está disponível somente agora. Clique no botão abaixo para adicionar essa vantagem incrível ao seu pedido.'
              />
              <InputField
                onChange={confirmationButtonText.setter}
                value={confirmationButtonText.value}
                label='Texto do botão de confirmação'
                placeholder='Quero adicionar ao meu pedido'
              />
            </Panel>
            <Panel header='Imagem da Oferta' key='2'>
              <ImageField
                label='Versão Web'
                onChange={linkImageWeb.setter}
                value={linkImageWeb.value}
              />
              <ImageField
                label='Versão responsivo'
                onChange={linkImageResponsive.setter}
                value={linkImageResponsive.value}
              />
            </Panel>
            <Panel header='Cores de Estilo' key='3'>
              <ColorField
                label='Cor Primária:'
                value={primaryColor.value}
                onChange={primaryColor.setter}
              />
              <ColorField
                label='Cor Secundária:'
                value={secondaryColor.value}
                onChange={secondaryColor.setter}
              />
              <ColorField
                label='Cor de Fundo:'
                value={backgroundColor.value}
                onChange={backgroundColor.setter}
              />
              <ColorField
                label='Cor do Texto:'
                value={textColor.value}
                onChange={textColor.setter}
              />
            </Panel>
          </Collapse>
        </Sider>
        <Content>
          <PreviewOrderBump
            title={title.value}
            description={description.value}
            textButton={confirmationButtonText.value}
            template={template.value}
            primaryColor={primaryColor.value}
            backgroundColor={backgroundColor.value}
            secondaryColor={secondaryColor.value}
            textColor={textColor.value}
            linkImageResponsive={linkImageResponsive.value || undefined}
            linkImageWeb={linkImageWeb.value || undefined}
          />
        </Content>
      </Layout>
    </Container>
  );
}
