import { Container, Indicator, Label, Root } from './styles';

export type TCheckboxOrderBumpProps = {
  labelText: string;
  onCheckedChange: (value: boolean) => void;
  borderColor?: string;
  boxColor?: string;
  boxUnselectedColor?: string;
  iconColor?: string;
  radius?: number;
  value: boolean;
};

export function CheckboxOrderBump({
  labelText,
  onCheckedChange,
  boxColor = '#fff',
  boxUnselectedColor = '#fff',
  iconColor,
  borderColor,
  radius = 8,
  value,
}: TCheckboxOrderBumpProps) {
  return (
    <Container>
      <Root
        onClick={() => onCheckedChange(!value)}
        style={{
          borderColor: borderColor || boxColor,
          backgroundColor: value ? boxColor : boxUnselectedColor,
          borderRadius: radius,
        }}
      >
        {value && (
          <Indicator>
            <svg
              width='15'
              height='11'
              viewBox='0 0 15 11'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.46891 8.59003L13.3789 0.680024C13.5656 0.493358 13.7872 0.400024 14.0439 0.400024C14.3006 0.400024 14.5222 0.493358 14.7089 0.680024C14.8956 0.866691 14.9889 1.08836 14.9889 1.34502C14.9889 1.60169 14.8956 1.82336 14.7089 2.01002L6.12225 10.5967C5.93558 10.7834 5.7178 10.8767 5.46891 10.8767C5.22003 10.8767 5.00225 10.7834 4.81558 10.5967L0.802247 6.58336C0.615581 6.39669 0.526136 6.17502 0.533914 5.91836C0.541692 5.66169 0.638914 5.44002 0.825581 5.25336C1.01225 5.06669 1.23391 4.97336 1.49058 4.97336C1.74725 4.97336 1.96891 5.06669 2.15558 5.25336L5.46891 8.59003Z'
                fill={iconColor}
              />
            </svg>
          </Indicator>
        )}
      </Root>
      <Label>{labelText}</Label>
    </Container>
  );
}
