import { CloseOutlined } from '@ant-design/icons';
import { FlameIcon } from '@assets/icons/flame';
import NewLoginImage from '@assets/images/gerenciamento-contas.png';
import { CarouselArrowLeft, CarouselArrowRight, EmptyButton } from '@atoms';
import { Modal } from '@models';
import { VideoPlayer } from '@organisms';
import { Carousel } from 'antd';
import { useState } from 'react';

import { env } from 'src/app/env';

import * as T from './common.styles';
import * as S from './Pagination.style';

interface PaginationProps {
  onClose: () => void;
  modalInfo?: Modal.IModal;
}

const DEFAULT_TITLE = 'Gerenciamento de Conta';
const DEFAULT_DESCRIPTION =
  'Estamos lançando o novo Gerenciamento de Conta, simplificando a administração dos seus dados e com controle dos seus dados.';
const DEFAULT_BUTTON_LABEL = 'Treinar Agora';

export const Pagination = ({ onClose, modalInfo }: PaginationProps) => {
  const [carouselState, setCarouselState] = useState(0);

  return (
    <T.Container>
      <T.Header>
        <FlameIcon />
        <EmptyButton onClick={() => onClose()}>
          <CloseOutlined style={{ color: '#000', fontSize: '20px' }} />
        </EmptyButton>
      </T.Header>

      <S.Body>
        <T.Content>
          <S.CarouselContainer>
            <Carousel
              arrows
              nextArrow={<CarouselArrowRight />}
              prevArrow={<CarouselArrowLeft />}
              infinite={false}
              beforeChange={(_, nextPage) => setCarouselState(nextPage)}
            >
              {!!modalInfo &&
                modalInfo?.advertisements.map(adversement => {
                  if (adversement.videoLink) {
                    return <VideoPlayer url={adversement.videoLink} />;
                  }

                  return (
                    <div>
                      <S.CustomImage
                        preview={false}
                        src={`${env.FILE_API}/files/files/view/${adversement.webImageFileId}`}
                      />
                    </div>
                  );
                })}

              {!modalInfo &&
                Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <div key={index}>
                      <S.CustomImage preview={false} src={NewLoginImage} />
                    </div>
                  ))}
            </Carousel>
          </S.CarouselContainer>

          <S.Title>
            {modalInfo?.advertisements[carouselState].title || DEFAULT_TITLE}
          </S.Title>

          <S.Description>
            {modalInfo?.advertisements[carouselState].description ||
              DEFAULT_DESCRIPTION}
          </S.Description>
        </T.Content>

        <T.Footer>
          <T.CustomCheckbox onChange={e => () => null}>
            Não mostrar este conteúdo novamente.
          </T.CustomCheckbox>

          <T.CustomButton>
            {modalInfo?.advertisements[carouselState].buttonLabel ||
              DEFAULT_BUTTON_LABEL}
          </T.CustomButton>
        </T.Footer>
      </S.Body>
    </T.Container>
  );
};
