import './tabs-content.css';

import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { store } from '@core/redux';
import { events } from '@service/api';
import { Button, Col, Image, Modal, Row, Spin, Upload } from 'antd';
import { Tabs } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { FC, useState } from 'react';

import { env } from 'src/app/env';
import { IBadgeImageResponseDto } from 'src/core/gamefication/dto/badge-image/badge-response.dto';

import * as badgesGroupsApi from '../api/badgesGroupsApi';

const { Dragger } = Upload;

const { TabPane } = Tabs;

type ModalProps = {
  badgeId?: number;
  badgeIndex?: number | undefined;
  isModalVisible: boolean;
  onSaveClick?: (
    imagemSelecionada: IBadgeImageResponseDto,
    imagemUrl: string
  ) => void;
  toggleUploadImageModal?: (value?: boolean) => void;
};

export const ModalViewUploadImageBadge: FC<ModalProps> = ({
  badgeId = 0,
  badgeIndex = undefined,
  isModalVisible,
  toggleUploadImageModal,
  onSaveClick,
}) => {
  const [listaArquivosUpload, setListaArquivosUpload] =
    useState<UploadFile[]>();
  const [listaBadgeImagens, setListaBadgeImagens] =
    useState<IBadgeImageResponseDto[]>();
  const [imagemSelecionada, setImagemSelecionada] = useState<
    IBadgeImageResponseDto | undefined
  >();
  const [loadingImagens, setLoadingImages] = useState<boolean>(false);

  async function callback(key: string) {
    setLoadingImages(true);
    const response = await badgesGroupsApi.getBadgeImagens();
    setListaBadgeImagens(response.data);
    setLoadingImages(false);
  }

  const token =
    store.getState().auth.payload?.access_token || store.getState().auth.token;

  const props = {
    name: 'file',
    multiple: true,
    action: `${env.BASE_URL}badge/image/upload`,
    fileList: listaArquivosUpload,
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true,
      downloadIcon: <CheckCircleOutlined />,
    },
    headers: {
      accept: '*/*',
      'access-control-allow-origin': '*',
      authorization: token as string,
      mode: 'no-cors',
    },
    beforeUpload: (file: RcFile) => {
      const isJpg = file.type === 'image/jpg' || file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isPNG && !isJpg) {
        console.log(`${file.name} is not a image file`);
      }
      return isPNG || isJpg || Upload.LIST_IGNORE;
    },
    customRequest: async (options: any) => {
      try {
        const formData = new FormData();
        formData.append('file', options.file);
        const response = await events.uploadBadgeImage(formData);

        if (response.result) {
          options.onSuccess(response.data, options.file);
        }
      } catch (error) {
        options.onError(error, options.file);
      }
    },
    onDownload() {
      return;
    },
    onChange(info: UploadChangeParam) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }

      const list = info.fileList.map(file => {
        file.crossOrigin = 'anonymous';
        return file;
      });

      info.file.crossOrigin = 'anonymous';

      setListaArquivosUpload(list);
    },
    onDrop(e: React.DragEvent<HTMLDivElement>) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onSubmit = async () => {
    onSaveClick?.(
      imagemSelecionada as IBadgeImageResponseDto,
      imagemSelecionada?.url as string
    );
    if (toggleUploadImageModal) {
      toggleUploadImageModal(false);
    }
  };

  const handleCancel = () => {
    if (toggleUploadImageModal) {
      toggleUploadImageModal(false);
    }
  };

  const conteudoDragnDrop = (
    <>
      <p className='ant-upload-text'>Arraste suas capas para cá</p>
      <p className='ant-upload-hint'>ou se preferir</p>
      <Button
        icon={<PlusCircleOutlined />}
        type='primary'
        style={{
          marginTop: 10,
        }}
      >
        Escolher arquivos
      </Button>
    </>
  );

  const colunasImagens = (rowIndex: number): Array<JSX.Element> => {
    const arrayOptions: Array<JSX.Element> = [];
    if (
      !listaBadgeImagens ||
      (listaBadgeImagens && listaBadgeImagens.length === 0)
    ) {
      return arrayOptions;
    }
    const inicio = rowIndex * 5;
    const fim = inicio + 5;
    // if (fim > listaBadgeImagens.length - 1) {
    //   fim = listaBadgeImagens.length - 1;
    // }
    for (let i = inicio; i < fim; i++) {
      if (i >= listaBadgeImagens.length) {
        arrayOptions.push(
          <Col key={`linha-col-${i}`}>
            <div
              style={{
                padding: 10,
                width: 150,
              }}
            ></div>
          </Col>
        );
      } else {
        const id = listaBadgeImagens[i].id;
        const url = listaBadgeImagens[i].url;
        arrayOptions.push(
          <Col key={`linha-col-${i}`}>
            <Image
              key={`linha-col-img-${i}`}
              preview={false}
              onClick={() => {
                if (imagemSelecionada && imagemSelecionada?.id === id) {
                  setImagemSelecionada(undefined);
                } else {
                  setImagemSelecionada(listaBadgeImagens[i]);
                }
              }}
              style={{
                padding: 10,
              }}
              className={
                imagemSelecionada?.id === id ? 'ant-image-selected' : ''
              }
              width={150}
              src={url}
            />
          </Col>
        );
      }
    }
    return arrayOptions;
  };

  const linhasImagens = (): Array<JSX.Element> => {
    const arrayOptions: Array<JSX.Element> = [];
    if (
      !listaBadgeImagens ||
      (listaBadgeImagens && listaBadgeImagens.length === 0)
    ) {
      return arrayOptions;
    }
    const totalPages = Math.ceil(listaBadgeImagens.length / 5);
    for (let i = 1; i <= totalPages; i++) {
      arrayOptions.push(
        <Row
          justify='space-around'
          align='top'
          style={{
            paddingTop: 10,
          }}
          key={`linha-${i}`}
        >
          {colunasImagens(i - 1)}
        </Row>
      );
    }
    return arrayOptions;
  };

  const listaImagensExistentes = (): JSX.Element => {
    return loadingImagens ? (
      <>
        <Spin />
      </>
    ) : (
      <>
        <Image.PreviewGroup>{linhasImagens()}</Image.PreviewGroup>
      </>
    );
  };

  return (
    <>
      <Modal
        title='Adicionar capa'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        width={960}
        bodyStyle={{
          paddingTop: 5,
          paddingBottom: 5,
        }}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={onSubmit}
            disabled={!imagemSelecionada}
          >
            Salvar
          </Button>,
        ]}
      >
        <Row style={{ height: '100%' }}>
          <Col span={24} style={{ height: '100%' }}>
            <Tabs
              defaultActiveKey='1'
              onChange={callback}
              style={{ height: '100%' }}
            >
              <TabPane
                tab='Carregar capas'
                key='1'
                style={{
                  height: '100%',
                }}
              >
                <Row style={{ height: '100%' }}>
                  <Col
                    span={24}
                    style={{
                      height: '90%',
                      background: '#272727',
                      padding: 10,
                    }}
                  >
                    <Dragger
                      withCredentials
                      {...props}
                      style={{
                        paddingTop: 25,
                        paddingBottom: 25,
                        background: 'transparent',
                      }}
                      className={listaArquivosUpload?.length ? 'withFiles' : ''}
                    >
                      {conteudoDragnDrop}
                    </Dragger>
                  </Col>
                  <Col span={24} style={{ height: '5%' }}>
                    <p>
                      * As capas que tiverem o upload feito com sucesso irão ser
                      apresentadas na aba "Capas recentes"
                    </p>
                  </Col>
                  <Col span={24}>
                    <p>
                      <b>Tamanho máximo:</b> 500kb //
                      <b> Formatos do arquivo: </b> JPG, PNG, SVG //
                      <b> Dimensões: </b> 240x240px
                    </p>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab='Capas recentes'
                key='2'
                style={{
                  height: '100%',
                  overflowY: 'scroll',
                  background: '#272727',
                }}
              >
                {listaImagensExistentes()}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
