import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export interface FilterInputProps {
  filter: string;
  onChange: (filter: string) => void;
}

export const FilterInput = ({ filter, onChange }: FilterInputProps) => {
  return (
    <Input
      placeholder={'Pesquisar'}
      suffix={<SearchOutlined />}
      value={filter}
      onChange={e => {
        onChange(e.target.value);
      }}
    />
  );
};
