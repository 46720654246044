export enum GenderLabelEnum {
  MASCULINO = 'Masculino',
  FEMININO = 'Feminino',
  OUTROS = 'Outros',
}
export type GenderLabelType =
  | GenderLabelEnum.MASCULINO
  | GenderLabelEnum.FEMININO
  | GenderLabelEnum.OUTROS;

const genderLabel = [
  GenderLabelEnum.FEMININO,
  GenderLabelEnum.MASCULINO,
  GenderLabelEnum.OUTROS,
];

export const getGenderLabelById = (genderId: number): GenderLabelType => {
  return genderLabel[genderId] as GenderLabelType;
};
