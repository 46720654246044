import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { lgpdApi } from 'src/features/Lgpd/apis/lgpdApis';
import {
  ConsultLgpdProps,
  ModalLgpdData,
} from 'src/features/Lgpd/interfaces/lgpdInterfaces';
import {
  CurrentModal,
  EvidenceStatus,
} from 'src/features/Lgpd/typings/lgpdTipyngs';
import { factoryAnonimizeDto } from 'src/features/Lgpd/utils/factoryAnonimizeDto';
import { formatDate } from 'src/features/Lgpd/utils/formatDate';

import { ModalInfoUser } from '../ModalInfoUser';
import { ModalNoEvidence } from '../ModalNoEvidence';
import { ModalSearch } from '../ModalSearch';

export const useControllerFormModalEvidence = (
  setCurrentModal: Dispatch<SetStateAction<CurrentModal>>,
  setConsultLgpd: Dispatch<SetStateAction<ConsultLgpdProps | undefined>>,
  handleCloseModal: () => void,
  userName?: string,
  consultLgpd?: ConsultLgpdProps
) => {
  const [evidenceModal, setEvidenceModal] = useState<ModalLgpdData>();

  function loadingEvidence(status: EvidenceStatus) {
    return status === 'loading';
  }

  const statusButton = consultLgpd?.status === 'success';

  useEffect(
    () =>
      setEvidenceModal({
        element: defineTemplateEvidenceModal(),
        textButtonOk: 'Proximo',
        textButtonCancel: 'Anterior',
        handleButtonOk: handleButtonNext,
        handleButtonCancel: handleCloseModal,
        handleButtonPrev: handleButtonPrevious,
        buttonOkState: statusButton ? 'enable' : 'disabled',
      }),
    [consultLgpd?.status]
  );

  useEffect(() => {
    consultEvidence();
  }, [consultLgpd?.status]);

  const handleButtonPrevious = () => {
    setConsultLgpd(undefined);
    setCurrentModal('stepOne');
  };

  const handleButtonNext = () => {
    setCurrentModal('stepThree');
  };

  const consultEvidence = async () => {
    if (consultLgpd?.consultId) {
      await lgpdApi
        .getCustomerEvidence(consultLgpd?.consultId)
        .then(async response => {
          const newResponse = await factoryAnonimizeDto(response);
          if (loadingEvidence(newResponse.status)) {
            setTimeout(consultEvidence, 30000);
          } else {
            setConsultLgpd(newResponse);
          }
        });
    }
  };

  const defineTemplateEvidenceModal = () => {
    switch (consultLgpd?.status) {
      case 'success':
        return (
          <ModalInfoUser
            requester={userName}
            date={formatDate(consultLgpd?.createdData)}
            linkCsv={consultLgpd?.linkCsvEvidence}
          />
        );
      case 'loading':
        return <ModalSearch />;

      default:
        return <ModalNoEvidence />;
    }
  };

  return evidenceModal;
};
