import { useCallback, useEffect } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { VideoFormData } from '../../types/videoPageTypings';
import { useTryToRequestUpdateVideoPage } from './useTryToRequestUpdateVideoPage';
import { useTryToRequestVideoPage } from './useTryToRequestVideoPage';

interface UseRequestVideoPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<Omit<VideoFormData, 'name'>>;
  replace: UseFieldArrayReplace<Omit<VideoFormData, 'name'>, 'slides'>;
}

export function useRequestVideoPage({
  isUpdate,
  setIsUpdate,
  titlePage,
  setTitlePage,
  setValue,
  replace,
}: UseRequestVideoPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestVideoPage } = useTryToRequestVideoPage();
  const { tryToRequestUpdateVideoPage, isLoadingVideoUpdate } =
    useTryToRequestUpdateVideoPage();

  const handleUpdateVideoPage = useCallback(
    async (e: Omit<VideoFormData, 'name'>) => {
      await tryToRequestUpdateVideoPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [pageId, titlePage, tryToRequestUpdateVideoPage]
  );

  const handleRequestVideoPage = useCallback(async () => {
    await tryToRequestVideoPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      replace(response.slides);
      setTitlePage(response.name);
    });
  }, [setTitlePage, setValue]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestVideoPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { handleUpdateVideoPage, isLoadingVideoUpdate };
}
