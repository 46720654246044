/* eslint-disable no-useless-escape */
export const extractFileNameFromS3Link = (link: string) => {
  const regex = /\/([^\/]+)_[a-f0-9]+(\.jpg)$/;
  const match = link.match(regex);

  if (match && match[1] && match[2]) {
    return match[1] + match[2];
  }
  return null;
};
