import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SwitchController, TextEditController } from '@organisms';
import { Button, Col, Collapse, Divider, Form, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CancelationButtonTitleFormFields } from 'src/features/Funnel/Components/formFields/CancelationButtonTitleFormFields';
import { LayoutFunnelPage } from 'src/features/Funnel/Components/LayoutFunnelPage';
import { TitleFunnelPage } from 'src/features/Funnel/Components/TitleFunnelPage';
import { useRequestVideoPage } from 'src/features/Funnel/hooks/video/useRequestVideoPage';
import { useTryToRequestCreatingVideoFunnelPage } from 'src/features/Funnel/hooks/video/useTryToRequestCreatingVideoFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { VideoFormData } from 'src/features/Funnel/types/videoPageTypings';

const videoUrlRegex = /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?].*)?$/;
const slidesSchema = yup.object().shape({
  title: yup.string().required('O Titulo Principal é obrigatório'),
  videoUrl: yup
    .string()
    .required('O vídeo é obrigatório')
    .matches(
      videoUrlRegex,
      'Utilize uma url válida (ex: https://www.videosource.com?embedSrc=code)'
    ),
});

const validationSchema = yup.object({
  slides: yup.array().of(slidesSchema).required('Informe ao menos um slide'),
  continueCancelationButtonTitle: yup.string().nullable(),
  keepSubscriptionButtonTitle: yup.string().nullable(),
});

export const FunnelVideoPageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState('Modal de Vídeo');
  const [isUpdate, setIsUpdate] = useState(false);

  const { control, handleSubmit, setValue } = useForm<
    Omit<VideoFormData, 'name'>
  >({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      slides: [
        {
          title: '',
          videoUrl: '',
          description: undefined,
          mustWatch: false,
        },
      ],
      continueCancelationButtonTitle: null,
      keepSubscriptionButtonTitle: null,
    },
  });
  const history = useHistory();
  const { id } = useParams<ParamasProps>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'slides',
  });
  const { tryToRequestCreatingVideoFunnelPage, isLoadingCretingVideo } =
    useTryToRequestCreatingVideoFunnelPage();
  const { handleUpdateVideoPage, isLoadingVideoUpdate } = useRequestVideoPage({
    isUpdate,
    setIsUpdate,
    setTitlePage,
    titlePage,
    setValue,
    replace,
  });

  const handleVideoPage = useCallback(
    async (e: Omit<VideoFormData, 'name'>) => {
      if (isUpdate) {
        return handleUpdateVideoPage(e);
      }
      await tryToRequestCreatingVideoFunnelPage({
        name: titlePage,
        ...e,
      });
    },
    [
      isUpdate,
      handleUpdateVideoPage,
      tryToRequestCreatingVideoFunnelPage,
      titlePage,
    ]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  const [activeSlide, setActiveSlide] = useState([fields[0].id]);
  useEffect(() => {
    setActiveSlide([fields[0].id]);
  }, [fields]);

  return (
    <LayoutFunnelPage>
      <TitleFunnelPage titlePage={titlePage} setTitlePage={setTitlePage} />
      <Row>
        <Form
          layout='vertical'
          requiredMark='optional'
          onFinish={handleSubmit(handleVideoPage)}
          style={{ width: '100%' }}
        >
          <Collapse
            activeKey={activeSlide}
            onChange={e => setActiveSlide(e as any)}
            expandIconPosition='end'
          >
            {fields.map((field, index) => (
              <Collapse.Panel
                header={`Slide ${index + 1}`}
                key={field.id}
                extra={
                  <Button
                    type='text'
                    style={{
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 1}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                }
              >
                <Row style={{ flexDirection: 'column', gap: '12px' }}>
                  <Controller
                    control={control}
                    name={`slides[${index}].title`}
                    defaultValue={field.title}
                    placeholder='Escreva o principal título da página.'
                    autoComplete='off'
                    label='Título Principal'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                  <Controller
                    control={control}
                    name={`slides[${index}].videoUrl`}
                    defaultValue={field.videoUrl}
                    placeholder='Insira a url do vídeo'
                    autoComplete='off'
                    label='Vídeo'
                    required
                  />
                  <SwitchController
                    control={control}
                    name={`slides[${index}].mustWatch`}
                    label='Interação com o video'
                    defaultValue={field.mustWatch}
                    description='Ativar obrigatoriedade de assistir até o final'
                  />
                  <TextEditController
                    control={control}
                    name={`slides[${index}].description`}
                    defaultValue={field.description}
                    placeholder='Descrição'
                    label='Descrição'
                  />
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Row style={{ padding: '2rem', justifyContent: 'center' }}>
            <Col>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  append({
                    title: '',
                    videoUrl: '',
                    description: undefined,
                    mustWatch: false,
                  });
                }}
                disabled={fields.length >= 10}
              >
                Adicionar mais uma opção
              </Button>
            </Col>
          </Row>
          <CancelationButtonTitleFormFields control={control} />
          <Divider orientation='center' style={{ marginTop: '398px' }} />
          <Row style={{ gap: '8px', justifyContent: 'end' }}>
            <Button type='default' onClick={() => history.goBack()}>
              Cancelar
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={isUpdate ? isLoadingVideoUpdate : isLoadingCretingVideo}
            >
              Salvar
            </Button>
          </Row>
        </Form>
      </Row>
    </LayoutFunnelPage>
  );
};
