import { Button, Result } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

export const ErrorScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Início',
      route: '/',
    },
    {
      label: '500 - Página não encontrada',
    },
  ]);

  return (
    <Result
      status='500'
      title='Desculpe, algo deu errado'
      subTitle='Estamos passando por uma instabilidade, estamos trabalhando para corrigir.'
      extra={
        <Link to='/'>
          <Button type='primary'>Voltar ao Início</Button>
        </Link>
      }
    />
  );
};
