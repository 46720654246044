import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { lgpdApi } from 'src/features/Lgpd/apis/lgpdApis';
import {
  ConsultLgpdProps,
  ModalLgpdData,
} from 'src/features/Lgpd/interfaces/lgpdInterfaces';
import { CurrentModal } from 'src/features/Lgpd/typings/lgpdTipyngs';
import { factoryAnonimizeDto } from 'src/features/Lgpd/utils/factoryAnonimizeDto';
import { formatDate } from 'src/features/Lgpd/utils/formatDate';

import { ModalAnonymizedData } from '../ModalAnonymizedData';
import { ModalNoAniminization } from '../ModalNoAnonymization';

export const useModalAnonymization = (
  closeModal: () => void,
  setConsultLgpd: Dispatch<SetStateAction<ConsultLgpdProps | undefined>>,
  setCurrentModal: Dispatch<SetStateAction<CurrentModal>>,
  consultLgpd?: ConsultLgpdProps
) => {
  const [anonymizedModal, setAnonymizedModal] = useState<ModalLgpdData>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setAnonymizedModal({
      element: defineTemplateAnonymizationModal(),
      textButtonOk: 'Concluir',
      textButtonCancel: 'Anterior',
      handleButtonOk: closeModal,
      handleButtonCancel: closeModal,
      handleButtonPrev: handleButtonPrev,
      buttonOkState: defineStatusButtonOk(),
    });
  }, [consultLgpd]);

  function defineStatusButtonOk() {
    switch (consultLgpd?.status) {
      case 'successAnonymization':
        return 'enable';
      case 'errorAnonymization':
        return 'disabled';
      case 'loading':
        return 'loading';
      default:
        return 'disabled';
    }
  }

  function buttonAnonymizedLoading() {
    return consultLgpd?.status === 'loadingAnonymization';
  }

  const consultAnonimization = async () => {
    if (consultLgpd?.consultId) {
      await lgpdApi
        .getCustomerEvidence(consultLgpd?.consultId)
        .then(async response => {
          const newResponse = await factoryAnonimizeDto(response);
          if (newResponse.status === 'successAnonymization') {
            setConsultLgpd(newResponse);
          } else {
            setTimeout(consultAnonimization, 30000);
          }
        });
    }
  };

  const anonymizeData = async () => {
    if (consultLgpd) {
      return await lgpdApi
        .patchAnonymizeEvidence(consultLgpd?.consultId)
        .then(async response => {
          const newResponse = await factoryAnonimizeDto(response);
          setConsultLgpd(newResponse);
          consultAnonimization();
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };

  const handleButtonPrev = () => {
    setCurrentModal('stepTwo');
  };

  const handleButtonAnonymize = async () => {
    anonymizeData();
  };

  const defineTemplateAnonymizationModal = () => {
    if (consultLgpd?.status !== 'successAnonymization') {
      return (
        <ModalNoAniminization
          handleButtonAnonymize={handleButtonAnonymize}
          buttonState={buttonAnonymizedLoading()}
          hasError={hasError}
        />
      );
    } else {
      return (
        consultLgpd && (
          <ModalAnonymizedData
            name={consultLgpd?.customerName}
            email={consultLgpd.emailCustomer}
            cpf={consultLgpd?.customerDocument}
            requester={consultLgpd.requesterName}
            date={formatDate(consultLgpd.createdData)}
            linkCsv={consultLgpd.linkCsvAnonimization}
          />
        )
      );
    }
  };

  return anonymizedModal;
};
