import { EyeOutlined } from '@ant-design/icons';
import { EmptyButton } from '@atoms';
import { Modal } from '@models';
import { useState } from 'react';

import * as S from './Base.style';
import Template from './Template.molecule';
import DefaultModel from './variations/Default';
import HighlightModel from './variations/Highlight';
import Launch from './variations/Launch';
import Pagination from './variations/Pagination';

interface BaseModalModelProps {
  onClick: (value: Modal.TypeEnum) => void;
  selected?: boolean;
  disabled?: boolean;
  label?: string;
  type?: Modal.TypeEnum;
}

const Base = ({
  children,
  onClick,
  selected,
  disabled,
  label,
  type,
}: React.PropsWithChildren<BaseModalModelProps>) => {
  const [showTemplate, setShowTemplate] = useState(false);
  return (
    <>
      <S.Container disabled={disabled}>
        <S.ModalModelContainer
          selected={selected}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            return !disabled ? onClick(Modal.TypeEnum.DEFAULT) : () => null;
          }}
        >
          <S.ModalModelIconContainer>
            <EmptyButton
              onClick={!disabled ? () => setShowTemplate(true) : () => null}
            >
              <EyeOutlined style={{ fontSize: '22px' }} />
            </EmptyButton>
          </S.ModalModelIconContainer>

          {children}
        </S.ModalModelContainer>

        {!!label && (
          <S.LabelContainer selected={selected}>{label}</S.LabelContainer>
        )}
      </S.Container>

      {showTemplate && type === Modal.TypeEnum.DEFAULT && (
        <Template.Default onClose={() => setShowTemplate(false)} />
      )}

      {showTemplate && type === Modal.TypeEnum.HIGHLIGHT && (
        <Template.Highlight onClose={() => setShowTemplate(false)} />
      )}

      {showTemplate && type === Modal.TypeEnum.PAGINATION && (
        <Template.Pagination onClose={() => setShowTemplate(false)} />
      )}
    </>
  );
};

export default Base;

function Default(props: BaseModalModelProps) {
  return (
    <Base
      {...props}
      onClick={() => props.onClick(Modal.TypeEnum.DEFAULT)}
      type={Modal.TypeEnum.DEFAULT}
    >
      <DefaultModel />
    </Base>
  );
}
Base.Default = Default;

function Highlight(props: BaseModalModelProps) {
  return (
    <Base
      {...props}
      onClick={() => props.onClick(Modal.TypeEnum.HIGHLIGHT)}
      type={Modal.TypeEnum.HIGHLIGHT}
    >
      <HighlightModel />
    </Base>
  );
}
Base.Highlight = Highlight;

function PaginationComponent(props: BaseModalModelProps) {
  return (
    <Base
      {...props}
      onClick={() => props.onClick(Modal.TypeEnum.PAGINATION)}
      type={Modal.TypeEnum.PAGINATION}
    >
      <Pagination />
    </Base>
  );
}
Base.Pagination = PaginationComponent;

function LaunchComponent(props: BaseModalModelProps) {
  return (
    <Base
      {...props}
      onClick={() => props.onClick(Modal.TypeEnum.LAUNCH)}
      type={Modal.TypeEnum.LAUNCH}
    >
      <Launch />
    </Base>
  );
}
Base.Launch = LaunchComponent;
