import {
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import { UpsellLinks } from '@models';
import { createUpsellLink, updateUpsellLink } from '@service/api/upsellLinks';
import { Button, Form, FormInstance, Modal, Spin } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { offerApi } from 'src/features/offers/apis/offerApis';
import {
  Offer,
  OffersByProduct,
} from 'src/features/offers/interfaces/offersInterface';

import { sanitizeUrl } from '../../../../utils/links';
import {
  BottomContainer,
  Container,
  ContainerOptionsSearch,
  ErrorContent,
  FormCoupon,
  InputFormCoupon,
  TextAreaDescription,
} from './styles';

interface UrlModalProps {
  modalIsOpen: boolean;
  onCloseModal: () => void;
  dataModal: UpsellLinks.IUpsellLinks | null;
  isPreview: boolean;
}

export type DataUpsellLinksForm = Pick<
  UpsellLinks.IUpsellLinks,
  'urlName' | 'url' | 'description'
> & {
  productSellGroupSearch: string;
  offerSearch: string;
};

type StatusSearch = '' | 'success' | 'error' | 'validating';

export const UrlModal = ({
  modalIsOpen,
  onCloseModal,
  dataModal,
  isPreview,
}: UrlModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [errorsDisplay, setErrorsDisplay] = useState<string[]>([]);
  const [productSellGroup, setProductSellGroup] =
    useState<OffersByProduct | null>(null);
  const [productSellGroupId, setProductSellGroupId] = useState('');
  const [offerId, setOfferId] = useState('');
  const [offer, setOffer] = useState<Offer | null>(null);
  const [statusSearch, setStatusSearch] = useState<StatusSearch>('');
  const [form] = FormCoupon.useForm<DataUpsellLinksForm>();
  const formRef = useRef<FormInstance<DataUpsellLinksForm>>(null);
  const values = Form.useWatch([], form);

  const handleCloseModal = () => {
    form.resetFields();
    onCloseModal();
  };

  useEffect(() => {
    if (!dataModal) return;
    form.setFieldsValue(dataModal);

    if (!!dataModal.offerId) {
      const parsedOfferId = String(dataModal.offerId);
      form.setFieldValue('offerSearch', parsedOfferId);
      setOfferId(parsedOfferId);
      handleSearchOffer(parsedOfferId);
    } else if (!!dataModal.productSellGroupId) {
      const parsedProductSellGroupId = String(dataModal.productSellGroupId);
      form.setFieldValue('productSellGroupSearch', parsedProductSellGroupId);
      setProductSellGroupId(parsedProductSellGroupId);
      handleSearchProductSellGroup(parsedProductSellGroupId);
    }
  }, [dataModal]);

  useEffect(() => {
    if (!values?.urlName) {
      setSubmittable(false);
      return;
    }
    if (!values?.url) {
      setSubmittable(false);
      return;
    }
    if (!offer && !productSellGroup) {
      setSubmittable(false);
      return;
    }

    setSubmittable(true);
  }, [values, productSellGroup, offer]);

  const handleSubmit = useCallback(
    async props => {
      if ((!offer && !productSellGroup) || isPreview) return;
      try {
        setIsLoading(true);
        const destiny = !offer
          ? { productSellGroupId: Number(productSellGroup?.id as any) }
          : { offerId: Number(offer.id) };

        const { url, urlName, description } = props as DataUpsellLinksForm;

        if (!dataModal) {
          await createUpsellLink({
            description,
            url: sanitizeUrl(url),
            urlName,
            ...destiny,
          });
        } else {
          await updateUpsellLink({
            id: dataModal.id,
            description,
            url: sanitizeUrl(url),
            urlName,
            ...destiny,
          });
        }

        handleCloseModal();
      } finally {
        setIsLoading(false);
      }
    },
    [productSellGroup, offer, dataModal, isPreview]
  );

  const handleSearchProductSellGroup = async (initValue?: string) => {
    try {
      if ((!productSellGroupId && !initValue) || isPreview) {
        return;
      }
      form.validateFields();

      setStatusSearch('validating');
      const product = await offerApi.getOffers(initValue || productSellGroupId);

      if (!product) {
        setProductSellGroup(null);
        setStatusSearch('error');
        return;
      }

      setProductSellGroup(product);
      setStatusSearch('success');
    } catch (err: unknown) {
      console.error('Error handleSearchProductSellGroup', err);
      setStatusSearch('error');
      setProductSellGroup(null);
    }
  };

  useEffect(() => {
    if (!productSellGroupId) {
      setStatusSearch('');
      setProductSellGroup(null);
    }
  }, [productSellGroupId]);

  useEffect(() => {
    if (!offerId) {
      setStatusSearch('');
      setOffer(null);
    }
  }, [offerId]);

  const handleSearchOffer = async (initValue?: string) => {
    try {
      if ((!offerId && !initValue) || isPreview) {
        return;
      }
      setStatusSearch('validating');
      form.validateFields();

      const offerData = await offerApi.getOfferById(initValue || offerId);

      if (!offerData) {
        setOffer(null);
        setStatusSearch('error');
        return;
      }
      setOffer(offerData);
      setStatusSearch('success');
    } catch (err: unknown) {
      console.error('Error handleSearchProductSellGroup', err);
      setStatusSearch('error');
      setOffer(null);
    }
  };

  return (
    <Modal
      title='Adicionar Url'
      open={modalIsOpen}
      footer={false}
      onCancel={handleCloseModal}
      width={'70%'}
    >
      <Spin spinning={isLoading}>
        {errorsDisplay.length > 0 && (
          <ErrorContent>
            <CloseCircleFilled />
            <div>
              <p>
                Há {errorsDisplay.length} erro
                {errorsDisplay.length !== 1 && 's'} com esta URL:
              </p>

              {errorsDisplay.map(errorMsg => (
                <span key={errorMsg}>-{errorMsg}</span>
              ))}
            </div>
            <Button
              style={{ marginLeft: 'auto', border: 'none' }}
              onClick={() => setErrorsDisplay([])}
            >
              <CloseOutlined />
            </Button>
          </ErrorContent>
        )}
        <Container style={{ overflow: 'hidden' }}>
          <FormCoupon
            ref={formRef}
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            disabled={isPreview}
            autoComplete='off'
          >
            <FormCoupon.Item
              name='urlName'
              label='Nome da URL'
              rules={[{ required: true }]}
              required
            >
              <InputFormCoupon placeholder='Digite um nome de fácil memorização' />
            </FormCoupon.Item>
            <FormCoupon.Item
              rules={[{ required: true }]}
              name='url'
              label='URL da página'
              required
            >
              <InputFormCoupon prefix={!dataModal ? 'https://' : undefined} />
            </FormCoupon.Item>
            <ContainerOptionsSearch>
              <FormCoupon.Item
                name='productSellGroupSearch'
                label='ID do Produto'
                required={!offerId}
                rules={[{ required: !offerId }]}
                style={{ flex: 1, ...(!!offerId ? { opacity: 0.3 } : {}) }}
                hasFeedback
                validateStatus={!!productSellGroupId ? statusSearch : ''}
              >
                <InputFormCoupon
                  placeholder='ID do produto'
                  value={productSellGroupId}
                  onChange={({ target }) => setProductSellGroupId(target.value)}
                  onBlur={() => handleSearchProductSellGroup()}
                  allowClear
                  type='number'
                  disabled={
                    !!offerId || statusSearch === 'validating' || isPreview
                  }
                />
              </FormCoupon.Item>
              <span>Ou</span>
              <FormCoupon.Item
                name='offerSearch'
                label='ID da Oferta'
                required={!productSellGroupId}
                rules={[{ required: !productSellGroupId }]}
                style={{
                  flex: 1,
                  ...(!!productSellGroupId ? { opacity: 0.3 } : {}),
                }}
                validateStatus={!!offerId ? statusSearch : ''}
                hasFeedback
              >
                <InputFormCoupon
                  placeholder='ID da Oferta'
                  value={offerId}
                  onChange={({ target }) => setOfferId(target.value)}
                  onBlur={() => handleSearchOffer()}
                  type='number'
                  allowClear
                  disabled={
                    !!productSellGroupId ||
                    statusSearch === 'validating' ||
                    isPreview
                  }
                />
              </FormCoupon.Item>
            </ContainerOptionsSearch>
            <FormCoupon.Item
              name='description'
              label='Descrição'
              required={false}
            >
              <TextAreaDescription placeholder='Adicionar descrição...' />
            </FormCoupon.Item>
          </FormCoupon>
        </Container>
        <BottomContainer>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          {!isPreview && (
            <Button
              disabled={!submittable}
              type='primary'
              icon={<CheckCircleOutlined />}
              onClick={() => formRef.current?.submit()}
              loading={isLoading}
            >
              {!dataModal ? 'Salvar' : 'Editar'}
            </Button>
          )}
        </BottomContainer>
      </Spin>
    </Modal>
  );
};
