import { ModalPadding } from '@atoms';
import { RootState, slices } from '@core/redux';
import { usePrograms } from '@hooks';
import { Events } from '@models';
import { Modal } from '@molecules';
import { EventRegisterForm } from '@organisms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OpenNotification from 'src/app/components/antd/OpenNotification';

export interface ModalEventsProps {
  title: string;
}

export const ModalEvents: React.FC<ModalEventsProps> = ({ title }) => {
  const dispatch = useDispatch();
  const {
    getProgramsList,
    programsList,
    programsOptions,
    programsWithModulesOption,
    normalizeProgramsAndClasseseRequest,
  } = usePrograms();
  const { eventDetail } = useSelector((state: RootState) => state.events);
  const [canContiue] = useState(true);

  const [formData, setFormData] = useState<Events.EventEditingForm>();

  useEffect(() => {
    setFormData({
      ...eventDetail,
    });
    getProgramsList();
  }, [eventDetail]);

  const handleChange = (field: string, value: any) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onCancelModal = () => {
    dispatch(slices.events.clearEventDetail());
  };

  const onSubmit = () => {
    try {
      if (!formData?.name) {
        throw Error('Nome do evento é obrigatório');
      }

      if (!formData?.eventTypeId) {
        throw Error('Tipo de evento é obrigatório');
      }

      if (!formData?.description) {
        throw Error('Descrição do evento é obrigatório');
      }

      const eventsContents = normalizeProgramsAndClasseseRequest(
        formData?.programs || []
      );

      dispatch(
        slices.events.createEvent({
          identifier: formData?.identifier || '',
          name: formData?.name || '',
          description: formData?.description || '',
          eventTypeId: Number(formData?.eventTypeId) || 0,
          statusActivated: !!formData?.statusActivated,
          eventsContents,
          languages: [
            { locale: 'es_ES', description: formData?.description_es || '' },
            { locale: 'en_US', description: formData?.description_en || '' },
            { locale: 'pt_BR', description: formData?.description_pt || '' },
          ],
        } as Omit<Events.EventForm, 'id'>)
      );
    } catch (error: any) {
      OpenNotification(true, 'Erro ao cadastrar o evento', error.message);
    }
  };

  return (
    <>
      {!!eventDetail && !eventDetail.id && (
        <Modal
          title={title}
          onCancel={onCancelModal}
          onSubmit={onSubmit}
          allowContinue={canContiue}
          onClose={onCancelModal}
          buttonFormId={'register-assets-banners'}
        >
          <ModalPadding>
            <EventRegisterForm
              {...formData}
              onChange={handleChange}
              programList={programsList || []}
              programsOptions={programsOptions || []}
              programsWithModulesOption={programsWithModulesOption || []}
            />
          </ModalPadding>
        </Modal>
      )}
    </>
  );
};
