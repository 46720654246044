import { RootState } from '@core/redux';
import { Events, Programs } from '@models';
import { Col, Form, Input, Row, Select, Tabs } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import * as S from './EventRegisterForm.style';

interface IOptions {
  value: string;
  label: string;
}

const STATUS_OPTIONS = [
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' },
];

interface EventRegisterFormProps extends Events.EventEditingForm {
  onChange: (fieldname: string, value: any) => void;
  programList: Programs.ProgramsResponse[];
  programsOptions: IOptions[];
  programsWithModulesOption: IOptions[];
}

export const EventRegisterForm: React.FC<EventRegisterFormProps> = ({
  identifier,
  name,
  description,
  statusActivated,
  description_pt,
  description_es,
  description_en,
  programsOptions,
  programsWithModulesOption,
  programs,
  onChange,
}) => {
  const { eventTypeList } = useSelector((state: RootState) => state.events);
  const [choosedTypeEvent, setChoosedTypeEvent] = React.useState<
    number | undefined
  >(undefined);

  const getCurrentProgramSelect = (eventProgramId: number) => {
    let currentProgramContent: any = [];
    let currentTitle = '';

    if (eventProgramId === 1) {
      return null;
    }

    if (eventProgramId === 2) {
      currentTitle = 'Programas e aulas';
      currentProgramContent = [...programsWithModulesOption];
    }

    if (eventProgramId === 3) {
      currentTitle = 'Programas';
      currentProgramContent = [...programsOptions];
    }

    return (
      <>
        <S.Label>{currentTitle}</S.Label>

        <Row>
          <Col span={24}>
            <Select
              allowClear={false}
              showSearch
              mode='multiple'
              filterOption={(input, option) =>
                ((option?.label as string) ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
              onChange={e => onChange('programs', e)}
              value={programs}
              options={currentProgramContent}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleEventType = (field: string, event: number) => {
    onChange('programs', undefined);
    setChoosedTypeEvent(event);
    onChange(field, event);
  };

  return (
    <Form style={{ width: '100%' }} layout='horizontal'>
      <S.Label>Identificador</S.Label>

      <Input
        placeholder='Padrão'
        disabled
        value={identifier}
        name='identifier'
      />

      <S.Space />

      <S.Label>Tipo</S.Label>

      <Row>
        <Col span={24}>
          <Select
            allowClear={false}
            placeholder='Tipo do evento'
            style={{ width: '100%' }}
            onChange={e => handleEventType('eventTypeId', e)}
            value={choosedTypeEvent}
            options={eventTypeList?.map(eventType => ({
              value: eventType.id,
              label: eventType.name,
            }))}
          />
        </Col>
      </Row>

      <S.Space />

      {getCurrentProgramSelect(choosedTypeEvent || 1)}

      <S.Space />

      <S.Label>Status</S.Label>

      <Row>
        <Col span={24}>
          <Select
            allowClear={false}
            placeholder='Selecione o status'
            style={{ width: '100%' }}
            value={statusActivated ? '1' : '0'}
            options={STATUS_OPTIONS}
            onChange={value => onChange('statusActivated', !!+value)}
          />
        </Col>
      </Row>
      <S.Space />

      <S.Label>Nome do evento</S.Label>
      <Input
        placeholder='Aula assistida - Barriga seca'
        onChange={evt => onChange('name', evt.target.value)}
        value={name}
        name='name'
      />
      <S.Space />

      <S.Label>Detalhes</S.Label>
      <Input
        placeholder='Os pontos são computados sempre que o usuário acessar um grupo colaborativo'
        onChange={evt => onChange('description', evt.target.value)}
        value={description}
        name='description'
      />
      <S.Space />

      <S.Label>Idiomas</S.Label>
      <Tabs>
        <Tabs.TabPane tab='PT' key='1'>
          <S.Label>Descrição (PT)</S.Label>
          <Input.TextArea
            rows={3}
            placeholder='Os pontos são computados sempre que o usuário acessar um grupo colaborativo'
            onChange={evt => onChange('description_pt', evt.target.value)}
            value={description_pt}
            name='description_pt'
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='ES' key='2'>
          <S.Label>Descrição (ES)</S.Label>
          <Input.TextArea
            rows={3}
            placeholder='Los puntos se calculan cada vez que el usuario accede a un grupo colaborativo'
            onChange={evt => onChange('description_es', evt.target.value)}
            value={description_es}
            name='description_es'
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='EN' key='3'>
          <S.Label>Descrição (EN)</S.Label>
          <Input.TextArea
            rows={3}
            placeholder='Points are computed whenever the user accesses a collaborative grou'
            onChange={evt => onChange('description_en', evt.target.value)}
            value={description_en}
            name='description_en'
          />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};
