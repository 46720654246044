import { differenceInYears, parseISO } from 'date-fns';

const calculateAge = (
  birthDateString: string | undefined
): number | undefined => {
  if (!birthDateString) return undefined;

  const birthDate = parseISO(birthDateString);

  const age = differenceInYears(new Date(), birthDate);

  return age;
};

const calculateImc = (
  imc: number | undefined,
  height: string | undefined,
  weight: string | undefined
): string | undefined => {
  let calcIMC: number | undefined;

  if (imc) {
    calcIMC = imc;
  }

  if (!imc && height && weight) {
    calcIMC = Number(weight) / (Number(height) * Number(height));
  }

  if (!calcIMC) {
    return undefined;
  }

  let category: string;
  switch (true) {
    case calcIMC < 18.5:
      category = 'Abaixo do peso';
      break;
    case calcIMC >= 18.5 && calcIMC < 24.9:
      category = 'Normal';
      break;
    case calcIMC >= 25 && calcIMC < 29.9:
      category = 'Sobrepeso';
      break;
    case calcIMC >= 30 && calcIMC < 34.9:
      category = 'Obesidade Grau 1';
      break;
    case calcIMC >= 35 && calcIMC < 39.9:
      category = 'Obesidade Grau 2';
      break;
    default:
      category = 'Obesidade Grau 3';
      break;
  }

  return `${calcIMC.toFixed(1)} (${category})`;
};

export const calcUtils = {
  calculateAge,
  calculateImc,
};
