import { useOrderBump } from '@hooks';
import { PageHeader } from '@organisms';
import React from 'react';

import { FilterOrderBumps } from './Filter.orderBump';
import { TableOrderBump } from './Table.orderBump';

export const OrderBumpPage: React.FC = () => {
  const {
    couponList,
    isLoadingData,
    handleChangePagination,
    handleFilterOrderBump,
    handleCreateOrderBump,
    handleOrderOrderBump,
    handleDuplicateOrderBump,
    handleEditOrderBump,
  } = useOrderBump();

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de Order Bump'}
        subTitle={
          'Cadastrar, editar e configurar todos os order bumps do checkout.'
        }
      />

      <TableOrderBump
        onChangePagination={handleChangePagination}
        filterComponent={
          <FilterOrderBumps
            onAddOrderBump={handleCreateOrderBump}
            onFilterOrderBump={handleFilterOrderBump}
          />
        }
        data={couponList}
        onChangeOrder={handleOrderOrderBump}
        onEditOrderBump={handleEditOrderBump}
        onDuplicateOption={handleDuplicateOrderBump}
        isLoading={isLoadingData}
      />
    </>
  );
};
