import styled from 'styled-components';

export const Container = styled.div``;

export const FilterContainer = styled.div`
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  column-gap: 8px;
`;

export const OrderListContainer = styled.div`
  z-index: 99;
`;

export const EmptyWarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  width: 100%;
  height: 425px;

  color: #7d7d7d;
  background-color: #1d1d1d;
`;

export const IconContainer = styled.div``;

export const EmptyTitleContainer = styled.div``;
export const EmptySubTitleContainer = styled.div``;
