import { SolutionOutlined } from '@ant-design/icons';

import { ModalStepTwo } from './ModalStepTwo';

export const ModalNoEvidence: React.FC = () => {
  return (
    <ModalStepTwo
      title={'Nenhuma evidência encontrada'}
      firstText={'Ainda não existem evidências.'}
      icon={<SolutionOutlined style={{ fontSize: '36px' }} />}
      hasButton={false}
    />
  );
};
