import { FC } from 'react';

import { ErrorScreen } from 'src/features/layout/screens/ErrorScreen';

import { Spiner } from './antd/Spiner';

export interface ContentComponentProps {
  isLoading: boolean;
  isError: boolean;
}

export const ContentComponent: FC<ContentComponentProps> = ({
  isLoading,
  isError,
  children,
}) => {
  if (isLoading) {
    return <Spiner />;
  }

  if (isError) {
    return <ErrorScreen />;
  }

  return <>{children}</>;
};
