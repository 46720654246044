import { Modal, Row, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';

import useControllerModal from '../../hooks/useControllerModal';
import { ModalLgpdData, ModalLgpdProps } from '../../interfaces/lgpdInterfaces';
import { CurrentModal } from '../../typings/lgpdTipyngs';
import { StepModal } from './components/StepModal';

export const ModalLgpd: FC<ModalLgpdProps> = ({
  setModalVisibility,
  isVisible,
  alternativeModalCurrent,
  tableLgpdConsult,
}) => {
  const { Text } = Typography;

  const [currentModal, setCurrentModal] = useState<CurrentModal>(
    alternativeModalCurrent ? alternativeModalCurrent : 'stepOne'
  );

  const [defineCurrentStep] = useControllerModal(
    setModalVisibility,
    setCurrentModal,
    currentModal,
    tableLgpdConsult
  );
  const [currentModalData, setCurrentModalData] = useState<
    ModalLgpdData | undefined
  >(defineCurrentStep);

  useEffect(() => {
    setCurrentModalData(defineCurrentStep);
  }, [currentModal, defineCurrentStep]);

  const isLoading =
    currentModal !== 'stepThree' &&
    currentModalData?.buttonOkState === 'loading';
  const isDisabled =
    currentModalData?.hasError === true ||
    currentModalData?.buttonOkState !== 'enable';

  return (
    <Modal
      open={isVisible}
      style={{ minWidth: '656px' }}
      destroyOnClose={true}
      onCancel={currentModalData?.handleButtonCancel}
      onOk={currentModalData?.handleButtonOk}
      okText={currentModalData?.textButtonOk}
      cancelText={currentModalData?.textButtonCancel}
      okButtonProps={{
        loading: !!isLoading,
        disabled: !!isDisabled,
      }}
      cancelButtonProps={{
        disabled: !!isLoading,
        onClick: currentModalData?.handleButtonPrev,
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Text
          style={{
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '150%',
            marginBlockEnd: '25px',
          }}
        >
          Cadastrar/Editar usuário
        </Text>
      </Row>
      <StepModal current={currentModal} hasError={currentModalData?.hasError} />

      {currentModalData?.element}
    </Modal>
  );
};
