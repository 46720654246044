import { useEffect, useState } from 'react';

import { lgpdApi } from 'src/features/Lgpd/apis/lgpdApis';
import { UserAdminData } from 'src/features/Lgpd/interfaces/lgpdInterfaces';

export const useVerifyPermission = () => {
  const [permissionMaster, setPermissionMaster] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchAdmin = async () => {
    setIsLoading(true);
    const response = await lgpdApi.getUserData().then(response => {
      return response.data;
    });
    return response as UserAdminData;
  };

  const fetchPermission = async (id: string) =>
    await lgpdApi.getUserPermission(id).then(response => {
      setPermissionMaster(response.roles.includes(1));
      setIsLoading(false);
      return permissionMaster;
    });
  useEffect(() => {
    fetchAdmin().then(response => {
      if (response?.id) fetchPermission(response.id);
    });
  }, []);

  return [permissionMaster, isLoading] as [boolean, boolean];
};
