import { useSegmentsDetail, useSegmentsRelation } from '@hooks';
import { Assets, Customer, Programs, Segments } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { SegmentsDetailTemplate } from './Detail.component';
import { SegmentsTabListTemplate } from './TabList.component';

export const SegmentDetailPage: React.FC = () => {
  useBreadcrumbs([
    {
      label: 'Segmentos',
    },
  ]);

  const { state } = useLocation<{
    id: string;
    currentTab: Segments.LinkedSegmentsEnum;
  }>();
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [assetCurrentPage, setAssetCurrentPage] = useState(1);
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [programCurrentPage, setProgramCurrentPage] = useState(1);

  const { segmentDetail, getSegmentDetail, changeSegmentDetail, saveChanges } =
    useSegmentsDetail();

  const {
    getProgramsBySegment,
    getAssetsBySegment,
    getUsersBySegment,
    assetsBySegment,
    programsBySegment,
    userBySegment,

    linkAssets,
    linkPrograms,
    linkCustomers,
    linkedSegments,

    requestUnlinkAssets,
    requestUnlinkPrograms,
    requestUnlinkCustomers,

    changeAssetsBySegmentFilter,
    changeAssetsBySegmentPagination,

    changeUserBySegmentFilter,
    changeUserBySegmentPagination,

    changeProgramsBySegmentFilter,
    changeProgramsBySegmentPagination,
  } = useSegmentsRelation(state?.id);

  const history = useHistory();

  useEffect(() => {
    getSegmentDetail(state?.id);
    if (state?.id) {
      getAssetsBySegment();
      getUsersBySegment();
      getProgramsBySegment();
    }
  }, []);

  const handleOnLink = (value: Segments.LinkedSegmentsEnum) => {
    switch (value) {
      case Segments.LinkedSegmentsEnum.ASSETS:
        return history.push('/screens/segments/link/assets', {
          id: state?.id,
        });
      case Segments.LinkedSegmentsEnum.CUSTOMERS:
        return history.push('/screens/segments/link/customers', {
          id: state?.id,
        });
      case Segments.LinkedSegmentsEnum.PROGRAMS:
        return history.push('/screens/segments/link/programs', {
          id: state?.id,
        });

      default:
        return false;
    }
  };

  const handleUnlinkSelection = (
    field: Segments.LinkedSegmentsEnum,
    data:
      | Segments.LinkedSegmentsProps['assets']
      | Segments.LinkedSegmentsProps['customers']
      | Segments.LinkedSegmentsProps['programs']
  ) => {
    switch (field) {
      case Segments.LinkedSegmentsEnum.ASSETS:
        linkAssets(data as Assets.IAsset[]);
        break;
      case Segments.LinkedSegmentsEnum.CUSTOMERS:
        linkCustomers(data as Customer.CustomerResponse[]);
        break;
      case Segments.LinkedSegmentsEnum.PROGRAMS:
        linkPrograms(data as Programs.ProgramsResponse[]);
        break;

      default:
        break;
    }
  };

  const handleUnlinkRequest = async (field: Segments.LinkedSegmentsEnum) => {
    switch (field) {
      case Segments.LinkedSegmentsEnum.ASSETS:
        await requestUnlinkAssets();
        break;
      case Segments.LinkedSegmentsEnum.CUSTOMERS:
        requestUnlinkCustomers();
        break;
      case Segments.LinkedSegmentsEnum.PROGRAMS:
        requestUnlinkPrograms();
        break;

      default:
        break;
    }
  };

  const handleFilterRequest = (
    field: Segments.LinkedSegmentsEnum,
    value: string
  ) => {
    switch (field) {
      case Segments.LinkedSegmentsEnum.ASSETS:
        !!value
          ? changeAssetsBySegmentFilter({
              name: { iLike: `$${value.replace(' ', '$')}$` },
            })
          : changeAssetsBySegmentFilter({});
        setAssetCurrentPage(1);
        break;
      case Segments.LinkedSegmentsEnum.CUSTOMERS:
        !!value
          ? changeUserBySegmentFilter({
              name: { iLike: `$${value.replace(' ', '$')}$` },
              email: { iLike: `$${value.replace(' ', '$')}$` },
            })
          : changeUserBySegmentFilter({});

        setUserCurrentPage(1);
        break;
      case Segments.LinkedSegmentsEnum.PROGRAMS:
        !!value
          ? changeProgramsBySegmentFilter({
              name: { iLike: `$${value.replace(' ', '$')}$` },
            })
          : changeProgramsBySegmentFilter({});
        setProgramCurrentPage(1);
        break;

      default:
        break;
    }
  };

  const verifyIfAbleToSave = (data?: Segments.SegmentsDto): boolean => {
    if (!data?.name || !data?.description || !data?.type) return false;
    if (linkedSegments.assets.length) return false;
    if (linkedSegments.customers.length) return false;
    if (linkedSegments.programs.length) return false;
    return true;
  };

  return (
    <LayoutConfirmationFooter
      ableToSave={verifyIfAbleToSave(segmentDetail) && formHasChanged}
      onSave={saveChanges}
      onCancel={() => history.push('/screens/segments/manage')}
    >
      {state?.id ? (
        <PageHeader
          backToPath='/screens/segments/manage'
          title='Editar Segmento'
          subTitle='Edite as informações e vincule ou desvincule Assets, Usuários e Programas deste Segmento.'
        />
      ) : (
        <PageHeader
          backToPath='/screens/segments/manage'
          title='Adicionar Segmento'
          subTitle='Cadastre um novo Segmento.'
        />
      )}

      <SegmentsDetailTemplate
        handleChange={(field, value) => {
          if (!formHasChanged) setFormHasChanged(true);
          changeSegmentDetail(field, value);
        }}
        formData={segmentDetail}
      />

      <br />
      <br />
      <SegmentsTabListTemplate
        assetsList={assetsBySegment}
        programsList={programsBySegment}
        usersList={userBySegment}
        assetCurrentPage={assetCurrentPage}
        userCurrentPage={userCurrentPage}
        programCurrentPage={programCurrentPage}
        linkedSegments={linkedSegments}
        onSelectRowToUnlink={handleUnlinkSelection}
        onClickToUnlink={handleUnlinkRequest}
        onFilter={handleFilterRequest}
        disabled={!state?.id}
        onChangeProgramsPagination={(take, skip) => {
          changeProgramsBySegmentPagination({ take, skip });
          setProgramCurrentPage(skip / take + 1);
        }}
        onChangeUsersPagination={(take, skip) => {
          changeUserBySegmentPagination({ take, skip });
          setUserCurrentPage(skip / take + 1);
        }}
        onChangeAssetsPagination={(take, skip) => {
          changeAssetsBySegmentPagination({ take, skip });
          setAssetCurrentPage(skip / take + 1);
        }}
        onLink={handleOnLink}
        sugestedTab={state?.currentTab}
      />
    </LayoutConfirmationFooter>
  );
};
