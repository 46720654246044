import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Pagination, Row, Typography } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { PageHeader } from 'src/app/components/PageHeader';

import { gameficationCompetition } from '../../client.api';
import SegmentInput from './SegmentInput';

const { Text, Title } = Typography;

// interface IFormInterface {
//   initialValues?: ManageCompetitionsResponseDto;
//   enableTabs?: (v: boolean) => void;
// }

const GameficationCompetitionFormStep4: FC = () => {
  const [form] = Form.useForm();
  // const URL_PARAMS: ICompetition = useParams();
  // const isEdit = Boolean(Object.keys(URL_PARAMS)?.length);
  // const [enableFields, setEnableFields] = useState<boolean>(!isEdit);
  // const [showModal, setModal] = useState<boolean>(false);
  // const [data, setData] = useState<any[]>([]);

  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line
  const onFinish = async (j: any) => {
    // const re = RegExp(`.*${j.search.toLowerCase().split('').join('.*')}.*`);
    // const matches = data.filter(v => v.customer.toLowerCase().match(re));
    // setData(j.search !== '' ? matches : _genData());
    console.info('@@@@', j);
  };

  const FORM_PROPS = {
    form,
    onFinish,
    scrollToFirstError: true,
    hideRequiredMark: true,
  };

  const _callApi = async () => {
    const client = gameficationCompetition.getBadges;
    const response = await client();
    return response?.data;
  };

  const OPTS = { refetchOnWindowFocus: false };
  const QUERY = useQuery('gamefication_badges', _callApi, OPTS);
  const { isFetching, data } = QUERY;

  return (
    <>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Gerenciar medalhas' />
        </Col>
      </Row>

      <Form size='large' layout='vertical' {...FORM_PROPS}>
        <Row style={{ marginBottom: 20, alignItems: 'center' }} gutter={30}>
          <Col xs={24} md={10} lg={12} style={{ marginTop: 21 }}>
            <Button
              onClick={() => console.info('yay')}
              icon={<EditOutlined />}
              data-testid='create-badge'
              size='large'
            >
              Adicionar nova badge
            </Button>
          </Col>

          <Col xs={24} md={14} lg={12}>
            <Row gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item label='Pesquisar' name='search'>
                  <Input.Search
                    placeholder='Filtrar por...'
                    style={{ width: '100%' }}
                    // onSearch={form.submit}
                    size='large'
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <SegmentInput label='Segmento' name='segmentId' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {!isFetching && data?.length && (
        <Row style={{ margin: '0 -20px' }}>
          {data.map(item => (
            <Col
              xs={24}
              md={12}
              lg={8}
              xl={6}
              style={{
                position: 'relative',
                justifyContent: 'flex-end',
                alignItems: 'stretch',
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                }}
              >
                <div
                  style={{
                    padding: '20px 30px',
                    margin: '10px',
                    display: 'flex',
                    border: '1px solid #2b2b2b',
                    borderRadius: 20,
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div
                    style={{ position: 'absolute', right: '40px', top: '30px' }}
                  >
                    <Button
                      icon={
                        <EllipsisOutlined
                          style={{ color: 'white', fontSize: '30px' }}
                        />
                      }
                      onClick={() => alert('heehee')}
                      type='link'
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={item.imagePath}
                        style={{ maxWidth: '50%' }}
                        alt='badge'
                      />
                    </div>

                    <Title level={4} style={{ margin: '20px 0 10px' }}>
                      {item.name}
                    </Title>

                    <Text style={{ textAlign: 'center' }}>
                      {item.description}
                    </Text>
                  </div>

                  <Title
                    level={5}
                    style={{ textAlign: 'center', margin: '10px 0 0 0' }}
                  >
                    Ativo
                  </Title>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}

      <Row style={{ margin: '30px 0' }}>
        <Col style={{ margin: '0 auto' }}>
          <Pagination defaultCurrent={3} total={50} />
        </Col>
      </Row>
    </>
  );
};

export default GameficationCompetitionFormStep4;
