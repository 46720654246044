type CharMap = { [key: string]: string };

const charMap: CharMap = {
  Ç: 'C',
  À: 'A',
  Á: 'A',
  Â: 'A',
  Ã: 'A',
  È: 'E',
  É: 'E',
  Ê: 'E',
  Ì: 'I',
  Í: 'I',
  Î: 'I',
  Ò: 'O',
  Ó: 'O',
  Ô: 'O',
  Õ: 'O',
  Ù: 'U',
  Ú: 'U',
  Û: 'U',
};

function toKeyValueLowerCase(object: CharMap): CharMap {
  const keys = Object.keys(object);
  const result: CharMap = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = object[key];
    result[key.toLowerCase()] = value.toLowerCase();
  }
  return result;
}

const allChars: CharMap = {
  ...charMap,
  ...toKeyValueLowerCase(charMap),
};

const chars = Object.keys(allChars).join('|');

export function toNoAccents(str: string): string {
  return str.replace(new RegExp(chars, 'g'), (m: string) => allChars[m]);
}

export default toNoAccents;
