import { Container, TextEdit } from './styles';

type TDescriptionFieldProps = {
  onChange: (value: string) => void;
  value: string;
  label: string;
  placeholder: string;
};

export function DescriptionField({
  onChange,
  value,
  label,
  placeholder,
}: TDescriptionFieldProps) {
  return (
    <Container>
      <label>{label}</label>
      <TextEdit
        theme='snow'
        value={value}
        onChange={onChange}
        modules={{
          toolbar: [
            [
              'bold',
              'italic',
              'underline',
              'strike',
              'link',
              { list: 'ordered' },
              { list: 'bullet' },
            ],
          ],
        }}
        formats={[
          'bold',
          'italic',
          'underline',
          'strike',
          'link',
          'list',
          'bullet',
          'link',
        ]}
        placeholder={placeholder}
      />
    </Container>
  );
}
