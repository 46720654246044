import { TUpsellStepsFlowManage } from '@hooks';
import { Tooltip } from 'antd';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

import { BottomFlow, ContentFlow, DisplayFlowContainer } from './styles';

export function DisplayFlowNode({
  data: { stepFlowData, onDeleteStep, onEditStep },
}: NodeProps<{
  stepFlowData: TUpsellStepsFlowManage;
  onDeleteStep: (step: TUpsellStepsFlowManage) => void;
  onEditStep: (step: TUpsellStepsFlowManage) => void;
}>) {
  return (
    <>
      <Handle
        type='target'
        position={Position.Right}
        id={`target-${stepFlowData.id}`}
      />
      <DisplayFlowContainer>
        <ContentFlow>
          <p>{stepFlowData.name}</p>
          <Tooltip title={stepFlowData.link.url}>
            <span>{stepFlowData.link.url}</span>
          </Tooltip>
        </ContentFlow>
        <BottomFlow>
          <button type='button' onClick={() => onEditStep(stepFlowData)}>
            <svg
              width='17'
              height='17'
              viewBox='0 0 17 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                opacity='0.1'
                d='M12.4483 5.06133L11.303 3.91602L5.63584 9.58164L5.39209 10.9723L6.78115 10.727L12.4483 5.06133Z'
                fill='white'
                fill-opacity='0.45'
              />
              <path
                d='M14.3062 13.6191H2.80615C2.52959 13.6191 2.30615 13.8426 2.30615 14.1191V14.6816C2.30615 14.7504 2.3624 14.8066 2.43115 14.8066H14.6812C14.7499 14.8066 14.8062 14.7504 14.8062 14.6816V14.1191C14.8062 13.8426 14.5827 13.6191 14.3062 13.6191ZM4.58272 12.3066C4.61397 12.3066 4.64522 12.3035 4.67647 12.2988L7.30459 11.8379C7.33584 11.8316 7.36553 11.8176 7.3874 11.7941L14.0108 5.1707C14.0253 5.15625 14.0368 5.13908 14.0447 5.12018C14.0525 5.10127 14.0565 5.08101 14.0565 5.06055C14.0565 5.04008 14.0525 5.01982 14.0447 5.00092C14.0368 4.98202 14.0253 4.96485 14.0108 4.95039L11.414 2.35195C11.3843 2.32227 11.3452 2.30664 11.303 2.30664C11.2608 2.30664 11.2218 2.32227 11.1921 2.35195L4.56865 8.97539C4.54521 8.99883 4.53115 9.02695 4.5249 9.0582L4.06396 11.6863C4.04876 11.77 4.0542 11.8562 4.07979 11.9373C4.10538 12.0184 4.15036 12.0921 4.21084 12.152C4.31396 12.252 4.44365 12.3066 4.58272 12.3066ZM5.63584 9.58164L11.303 3.91602L12.4483 5.06133L6.78115 10.727L5.39209 10.9723L5.63584 9.58164Z'
                fill='white'
                fill-opacity='0.45'
              />
            </svg>
          </button>
          <button type='button' onClick={() => onDeleteStep(stepFlowData)}>
            <svg
              width='17'
              height='17'
              viewBox='0 0 17 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                opacity='0.1'
                d='M5.01729 13.6816H11.8704L12.2485 5.68164H4.63916L5.01729 13.6816Z'
                fill='white'
                fill-opacity='0.45'
              />
              <path
                d='M13.9438 4.55664H11.9438V3.30664C11.9438 2.75508 11.4954 2.30664 10.9438 2.30664H5.94385C5.39229 2.30664 4.94385 2.75508 4.94385 3.30664V4.55664H2.94385C2.66729 4.55664 2.44385 4.78008 2.44385 5.05664V5.55664C2.44385 5.62539 2.5001 5.68164 2.56885 5.68164H3.5126L3.89854 13.8535C3.92354 14.3863 4.36416 14.8066 4.89697 14.8066H11.9907C12.5251 14.8066 12.9642 14.3879 12.9892 13.8535L13.3751 5.68164H14.3188C14.3876 5.68164 14.4438 5.62539 14.4438 5.55664V5.05664C14.4438 4.78008 14.2204 4.55664 13.9438 4.55664ZM6.06885 3.43164H10.8188V4.55664H6.06885V3.43164ZM11.8704 13.6816H5.01729L4.63916 5.68164H12.2485L11.8704 13.6816Z'
                fill='white'
                fill-opacity='0.45'
              />
            </svg>
          </button>
        </BottomFlow>
      </DisplayFlowContainer>
      <Handle
        type='source'
        position={Position.Left}
        id={`source-${stepFlowData.id}`}
      />
    </>
  );
}
