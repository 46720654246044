import { OrderBump } from '@models';
import { base } from '@service/base';

type TUpdateOrderBumpProps = Omit<
  OrderBump.IOrderBump,
  'destinationOffer' | 'createdAt' | 'destinationOffer' | 'offers'
>;

export async function updateOrderBump({ id, ...data }: TUpdateOrderBumpProps) {
  const response = await base.put<OrderBump.IOrderBump>(
    `/order-bump/${id}`,
    data
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
