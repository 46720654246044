import { useDebounce } from '@hooks';
import { FilterHeader } from '@organisms';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface FilterUpsellProductsProps {
  onFilterUpsellProducts: (value: string) => void;
}

export const FilterUpsellProducts: React.FC<FilterUpsellProductsProps> = ({
  onFilterUpsellProducts,
}) => {
  const [filterByCode, setFilterByCode] = useState<string | null>(null);

  const debounceValue = useDebounce(filterByCode ?? '', 900);

  useEffect(() => {
    if (
      filterByCode !== null &&
      (filterByCode.length > 2 || filterByCode.length === 0)
    ) {
      onFilterUpsellProducts(debounceValue);
    }
  }, [debounceValue]);

  return (
    <FilterHeader
      buttonList={[
        <Input.Search
          key={`filter-coupon-code-search`}
          placeholder={
            'Buscar por código do produto ou nome. (Mínimo 3 números)'
          }
          value={filterByCode ?? ''}
          onChange={evt => setFilterByCode(evt.target.value)}
          style={{ width: '560px' }}
          allowClear
        />,
      ]}
    />
  );
};
