import { UnlockOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, Modal } from 'antd';
import { passwordStrength } from 'check-password-strength';
import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { object, ref, SchemaOf, string } from 'yup';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { toFieldStatus } from 'src/app/utils/toFieldStatus';
import { userApi } from 'src/features/user/userApi';

interface ChangePasswordType {
  newPassword: string;
  confirmNewPasswowrd: string;
}
type ModalProps = {
  isModalVisible: boolean;
  setIsModalChangePasswordVisible: Dispatch<SetStateAction<boolean>>;
  idAdmin: string;
};
const { Item: FormItem } = Form;
const TAMANHO_MINIMO_SENHA = 8;

const validationSchema: SchemaOf<ChangePasswordType> = object({
  confirmNewPasswowrd: string()
    .oneOf([ref('newPassword'), null], 'As senhas não correspondem!')
    .required('Por favor preencha este o campo para confirmar a senha'),
  newPassword: string()
    .min(
      TAMANHO_MINIMO_SENHA,
      'A nova senha precisa ter pelo menos 8 caracteres'
    )
    .required('Por favor preencha este o campo de Senha'),
}).defined();

export const AdminChangePasswordModal: FC<ModalProps> = ({
  isModalVisible,
  setIsModalChangePasswordVisible,
  idAdmin,
}) => {
  const [form] = Form.useForm();

  const { control, handleSubmit, setValue } = useForm<ChangePasswordType>({
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = () => {
    setIsModalChangePasswordVisible(false);
  };

  const onSubmit = handleSubmit(async (data: ChangePasswordType) => {
    const response = await userApi.changeNewPassword({
      idUserAdmin: idAdmin,
      newAdminPasswordParam: data.newPassword,
    });
    if (response) {
      OpenNotification(false, 'Senha atualizada com sucesso', '');
      setValue('newPassword', '');
      setValue('confirmNewPasswowrd', '');
      return setIsModalChangePasswordVisible(false);
    } else {
      OpenNotification(
        true,
        'Erro ao atualizar senha',
        'Por favor verifique novamente as senhas informadas'
      );
    }
  });

  return (
    <>
      <Modal
        width={640}
        title='Alterar senha'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key='back' data-testid='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key='submit'
            data-testid='submit'
            type='primary'
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      >
        <>
          <Form layout='vertical' form={form}>
            <Rectangule>
              {
                'A senha deve conter: Pelo menos 8 caracteres, 1 letra maiúscula, 1 número e 1 carácter especial'
              }
            </Rectangule>
            <br></br>
            <label>Senha nova</label>
            <Controller
              control={control}
              name='newPassword'
              defaultValue=''
              render={({ field, fieldState }) => {
                const fieldStatus = toFieldStatus(fieldState.error);
                if (
                  (field.value &&
                    passwordStrength(field.value).value === 'Too weak') ||
                  passwordStrength(field.value).value === 'Weak'
                ) {
                  fieldStatus.status = 'error';
                  fieldStatus.help = 'Sua senha não é forte suficiente';
                }
                return (
                  <FormItem
                    validateStatus={fieldStatus.status}
                    help={fieldStatus.help}
                  >
                    <InputFieldPassword
                      placeholder='Insira a nova senha atual'
                      autoComplete='off'
                      data-testid='newPassword'
                      prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
                      {...field}
                    />
                  </FormItem>
                );
              }}
            />

            <label>Confirmar senha nova</label>
            <Controller
              control={control}
              name='confirmNewPasswowrd'
              defaultValue=''
              render={({ field, fieldState }) => {
                const { status, help } = toFieldStatus(fieldState.error);
                return (
                  <FormItem validateStatus={status} help={help}>
                    <InputFieldPassword
                      placeholder='Confirmar a nova senha atual'
                      autoComplete='off'
                      data-testid='confirmNewPasswowrd'
                      prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
                      {...field}
                    />
                  </FormItem>
                );
              }}
            />
          </Form>
        </>
      </Modal>
    </>
  );
};

const Rectangule = styled.div`
  padding: 10px;
  background: #2c2c2c;
`;
const InputFieldPassword = styled(Input.Password)`
  margin: 10;
`;
