import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { ReleaseFormData } from '../../types/releasePageTypings';
import { releasePayloadFactory } from '../../utils/releaseFunnelsPageFactory';

interface UpdateReleaseFunnelPageRequest {
  id: string;
  payload: ReleaseFormData;
}

export function useTryToRequestUpdateReleasePage() {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(
    async ({ id, payload }: UpdateReleaseFunnelPageRequest) => {
      const factory = await releasePayloadFactory(payload);

      return await apiCancellationFunnels.tryToRequestUpdateCancellationFunnelPage(
        id,
        factory
      );
    },
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        OpenNotification(
          true,
          'Erro ao editar o funil de Lançamento',
          'Ocorreu um erro ao editar o funil de Lançamento, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return {
    tryToRequestUpdateReleasePage: mutateAsync,
    isLoadingReleaseUpdate: isLoading,
  };
}
