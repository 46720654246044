import styled from 'styled-components';

export const FilterContainer = styled.div`
  padding-bottom: 24px;
  display: flex;
`;

export const PaginationContainer = styled.div`
  margin-top: 14px;
`;
