import { useRef } from 'react';

import {
  ColorContainer,
  ColorText,
  ColorView,
  Container,
  InputColor,
} from './styles';

type TColorFieldProps = {
  onChange: (value: string) => void;
  value: string;
  label: string;
};

export function ColorField({ onChange, value, label }: TColorFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <label>{label}</label>
      <ColorContainer onClick={() => inputRef.current?.click()}>
        <ColorView color={value}>
          <InputColor
            ref={inputRef}
            type='color'
            value={value}
            onChange={({ target }) => onChange(target.value)}
          />
        </ColorView>
        <ColorText>{value}</ColorText>
      </ColorContainer>
    </Container>
  );
}
