import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 14px;
`;
