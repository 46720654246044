import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import * as segmentsThunk from './segments.thunk';

export const getBannersBuilder = (builder: ActionReducerMapBuilder<any>) => {
  builder.addCase(segmentsThunk.getBannerSegments.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    segmentsThunk.getBannerSegments.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'NORMAL';
      state.bannerSegments = payload.response;
    }
  );

  builder.addCase(
    segmentsThunk.getBannerSegments.rejected,
    (state, { payload }: any) => {
      console.log('ERROR THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
