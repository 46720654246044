import { CalendarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
export const ScheduleTag: React.FC = () => {
  return (
    <Tag icon={<CalendarOutlined />} color='processing'>
      Programado
    </Tag>
  );
};
