import { UploadController } from '@organisms';
import { TooltipProps } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';

interface UploadImageProps extends UploadProps {
  control: Control<any>;
  name: string;
  label: string;
  required: boolean;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
}

export const UploadImage: React.FC<UploadImageProps> = ({
  control,
  name,
  label,
  required,
  tooltip,
}) => {
  return (
    <UploadController
      control={control}
      name={name}
      defaultValue=''
      required={required}
      label={label}
      listType='picture-card'
      showUploadList={false}
      maxCount={1}
      style={{
        minWidth: '369px',
        minHeight: '88px',
        maxHeight: '88px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...(tooltip && { tooltip: tooltip })}
    />
  );
};
