import { Events } from '@models';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import { EventsState } from './events.interface';
import * as eventsThunk from './events.thunk';

export const getEventById = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(eventsThunk.getEventById.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    eventsThunk.getEventById.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }

      let programs = [] as string[];

      const value: Events.EventResponseDto = { ...payload.response };

      const descriptionPt = value.eventNames?.find(
        event => event.locale === 'pt_BR'
      );
      const descriptionEs = value.eventNames?.find(
        event => event.locale === 'es_ES'
      );
      const descriptionEn = value.eventNames?.find(
        event => event.locale === 'en_US'
      );

      if (!!value.eventsContents?.length) {
        programs = value.eventsContents.map(item => {
          if (!item.contentId) {
            return String(item.programId);
          }
          return `${item.programId}-${item.contentId}`;
        });
      }

      state.eventDetail = {
        ...value,
        description: value.description,
        description_pt: descriptionPt?.displayName || '',
        description_en: descriptionEn?.displayName || '',
        description_es: descriptionEs?.displayName || '',
        eventTypeId: value.eventType.id,
        programs,
        id: value.id,
        identifier: value.identifier,
        name: value.name,
        statusActivated: value.statusActivated,
      };
      state.status = 'NORMAL';
    }
  );

  builder.addCase(
    eventsThunk.getEventById.rejected,
    (state, { payload }: any) => {
      console.log('ERROR EVENT THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
