import './clientDetails.css';

import { SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Select } from 'antd';
import { FC, useEffect, useState } from 'react';

import { DatePicker } from 'src/app/components/antd';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import useDashboardIndications from 'src/features/clients/hooks/useDashboardIndications';
import { ClientType } from 'src/features/clients/types/clientTypes';
const { Option } = Select;

type LayoutType = Parameters<typeof Form>[0]['layout'];

export type UserProps = {
  dados?: ClientType;
};

export const ClientDashboardTabIndications: FC<UserProps> = ({ dados }) => {
  const [form] = Form.useForm();
  const [formLayout] = useState<LayoutType>('inline');

  const [firstDateIndications, setfirstDateIndications] = useState<Date>();
  const [lastDateIndications, setlastDateIndications] = useState<Date>();
  const [firstDateIndicationsToUri, setfirstDateIndicationsToUri] =
    useState<Date>();
  const [lastDateIndicationsToUri, setlastDateIndicationsToUri] =
    useState<Date>();

  const [nameOrEmail, setnameOrEmail] = useState<string>();
  const [statusIndications, setStatusIndications] = useState<string>();
  const [page, setPage] = useState<number>(0);

  const [filternameOrEmail, setFilternameOrEmail] = useState<string>('');

  const { data, isLoading } = useDashboardIndications(
    dados?.id,
    page,
    firstDateIndicationsToUri,
    lastDateIndicationsToUri,
    filternameOrEmail,
    statusIndications
  );
  const timeoutTime = 1500;
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (nameOrEmail !== undefined) {
        setFilternameOrEmail(nameOrEmail);
      }
    }, timeoutTime);
    return () => clearTimeout(timeOut);
  }, [nameOrEmail]);

  useEffect(() => {
    if (firstDateIndications && lastDateIndications) {
      setfirstDateIndicationsToUri(firstDateIndications);
      setlastDateIndicationsToUri(lastDateIndications);
    }
  }, [firstDateIndications, lastDateIndications]);

  const onChangePage = (onChangePageParam: number) => {
    setPage(onChangePageParam);
  };

  return (
    <>
      <Divider></Divider>
      <h1>Indicações</h1>
      <Form
        layout='inline'
        form={form}
        style={{ marginBottom: 10 }}
        initialValues={{ layout: formLayout }}
      >
        <Col span={6}>
          <Form.Item>
            <Input
              onChange={e => setnameOrEmail(e.target.value)}
              value={nameOrEmail}
              data-testid='nameOrEmail'
              placeholder='Buscar por nome ou e-mail'
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            style={{ display: 'contents !important' }}
            label='Indicações de'
          >
            <DatePicker
              placeholder='Selecionar data'
              format='dd/MM/yyyy'
              data-testid='firstDateIndications'
              value={firstDateIndications}
              onChange={firstDateIndicationsDate =>
                setfirstDateIndications(firstDateIndicationsDate || undefined)
              }
              style={{ float: 'right', width: '100%' }}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label='a'>
            <DatePicker
              placeholder='Selecionar data'
              format='dd/MM/yyyy'
              data-testid='lastDateIndications'
              value={lastDateIndications}
              onChange={lastDateIndicationsDate =>
                setlastDateIndications(lastDateIndicationsDate || undefined)
              }
              style={{ float: 'right', width: '100%' }}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Select
            placeholder='Filtrar status'
            style={{ width: '100%' }}
            data-testid='status'
            onChange={value => setStatusIndications(value?.toString())}
          >
            {data?.status?.map((item: string) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </Col>
      </Form>
      <TableComponent
        style={{ width: '100vw' }}
        columns={[
          {
            title: 'Data',
            dataIndex: 'datahora',
            render: (datahora: string) => (
              <div>{dateFormatted(datahora, true)}</div>
            ),
          },
          {
            title: 'E-mail',
            dataIndex: 'email',
          },
          {
            title: 'Nome',
            dataIndex: 'name',
          },
          {
            title: 'Status',
            dataIndex: 'status',
          },
        ]}
        data={data?.indications}
        loading={isLoading}
        pageSize={10}
        total={data?.indications.length}
        onChangePage={onChangePage}
      />
    </>
  );
};
