import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PageListTemplate } from '@templates';
import { Button, Modal } from 'antd';
import { add, format, isAfter, parseISO } from 'date-fns';
import { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';

import useControllerTable from '../hooks/useControllerTable';
import { FreezingHistory } from '../interfaces/frezzingInterfaces';
import { FilterFreezing } from './FilterFreezing';

export interface TableLgpdProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}

export const TableFreezing = ({ setShowModal, showModal }: TableLgpdProps) => {
  const { filteredTableData, filter, setFilter, deleteFreezing, isLoading } =
    useControllerTable(showModal);

  const isDeleteDisabled = useCallback((initDate: string) => {
    const currentDate = new Date();
    return isAfter(currentDate, new Date(initDate));
  }, []);

  const handleDelete = (id: number) => {
    Modal.confirm({
      title: 'Atenção',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja realmente excluir o agendamento?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        deleteFreezing(id);
      },
    });
  };

  const parseDate = (dateISO: string) => {
    const date = parseISO(dateISO);
    const utcDate = add(date, { minutes: date.getTimezoneOffset() });

    return format(utcDate, 'dd/MM/yyyy');
  };

  return (
    <>
      <PageListTemplate
        filterComponent={
          <FilterFreezing
            filter={filter}
            onChangeFilter={value => setFilter(value)}
            onClickAdd={() => setShowModal(true)}
          />
        }
        columns={[
          {
            key: 'id',
            title: 'Id',
            dataIndex: 'id',
          },
          {
            key: 'startDate',
            title: 'Data de Início',
            dataIndex: 'startDate',
            render: (startDate: string) => parseDate(startDate),
          },
          {
            key: 'returnDate',
            title: 'Data de Retorno',
            dataIndex: 'returnDate',
            render: (returnDate: string) => parseDate(returnDate),
          },
          {
            key: 'requestingUserMail',
            title: 'Usuário Solicitante',
            dataIndex: 'requestingUserMail',
          },
          {
            title: 'Ações',
            key: 'action',
            dataIndex: '',
            render: (_, record: FreezingHistory) => {
              return (
                <CustomButton
                  onClick={() => {
                    handleDelete(record.id);
                  }}
                  disabled={isDeleteDisabled(record.startDate)}
                  data-testid='handle-cancel'
                >
                  <DeleteOutlined />
                </CustomButton>
              );
            },
          },
        ]}
        data={{ count: filteredTableData.length, rows: filteredTableData }}
        loading={isLoading}
      ></PageListTemplate>
    </>
  );
};

const CustomButton = styled(Button)`
  border: none;
  &:hover {
    background-color: rgba(62, 62, 62, 0.7);
    color: white;
  }
`;
