import { useMutation } from 'react-query';

import { apiCancellationFunnels } from '../api';
import { DataType } from '../types/FunnelEditTypings';
import { FunnelPublishingFactory } from '../utils/FunnelEditFactory';

interface UseFunnelPublishingProps {
  payload: DataType[];
}

export function useTryToRequestCancellationFunnelPublishing() {
  const { mutateAsync, isLoading } = useMutation(
    async ({ payload }: UseFunnelPublishingProps) => {
      const factory = FunnelPublishingFactory(payload);

      return await apiCancellationFunnels.tryToRqeuestCancellationFunnelsPublishing(
        factory
      );
    },
    {
      onSuccess: success => {
        console.log(success);
      },
    }
  );

  return {
    tryToRequestCancellationFunnelPublishing: mutateAsync,
    isLoadingFunnelPublishing: isLoading,
  };
}
