import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  InputNumberController,
  TextEditController,
} from '@organisms';
import { Button, Col, Collapse, Divider, Form, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { CancelationButtonTitleFormFields } from 'src/features/Funnel/Components/formFields/CancelationButtonTitleFormFields';
import { LayoutFunnelPage } from 'src/features/Funnel/Components/LayoutFunnelPage';
import { TitleFunnelPage } from 'src/features/Funnel/Components/TitleFunnelPage';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';
import { useRequestOfferPage } from 'src/features/Funnel/hooks/Offer/useRequestOfferPage';
import { useTryToRequestCreatingOfferFunnelPage } from 'src/features/Funnel/hooks/Offer/useTryToRequestCreatingOfferFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { OfferFormData } from 'src/features/Funnel/types/offerPageTypings';
import { currencyParser } from 'src/features/Funnel/utils/currencyParser';

const slidesValidationSchema = yup.object().shape({
  title: yup.string().required('O Titulo Principal é obrigatório'),
  profileId: yup.number().required('A Liberação é obrigatória'),
  releaseDays: yup.number().required('O período da liberação é obrigatório'),
  web: yup
    .mixed()
    .nullable()
    .required('A Versão Web é obrigatório')
    .test('is-filled', 'A Versão Web é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  mobile: yup
    .mixed()
    .nullable()
    .required('A Versão mobile é obrigatório')
    .test('is-filled', 'A Versão mobile é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  imageTitle: yup.string().required('O Título do lado da imagem é obrigatório'),
  subtitle: yup.string().required('O Subtítulo é obrigatório'),
  description: yup.string().required('A Descrição é obrigatório'),
  oldValue: yup.number().required('O Valor antigo é obrigatório'),
  finalValue: yup.string().nullable(),
  textValue: yup.string().required('O Texto em baixo do valor é obrigatório'),
  buttonTitle: yup.string().required('A Título do Botão é obrigatório'),
});

const valiationSchema = yup.object({
  slides: yup
    .array()
    .of(slidesValidationSchema)
    .required('Informe ao menso um slide'),
  continueCancelationButtonTitle: yup.string().nullable(),
  keepSubscriptionButtonTitle: yup.string().nullable(),
});

export const FunnelPageOfferPageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState('Modal Oferta');
  const [isUpdate, setIsUpdate] = useState(false);

  const history = useHistory();

  const { control, handleSubmit, setValue } = useForm<
    Omit<OfferFormData, 'name'>
  >({
    resolver: yupResolver(valiationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      slides: [
        {
          title: '',
          profileId: '',
          releaseDays: '',
          web: '',
          mobile: '',
          imageTitle: '',
          subtitle: '',
          description: '',
          oldValue: 0,
          finalValue: '',
          textValue: '',
          buttonTitle: '',
        },
      ],
      continueCancelationButtonTitle: null,
      keepSubscriptionButtonTitle: null,
    },
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'slides',
  });
  const { id } = useParams<ParamasProps>();

  const {
    isLoadingCreateOfferFunnelPage,
    tryToRequestCreatingOfferFunnelPage,
  } = useTryToRequestCreatingOfferFunnelPage();
  const { handleUpdateOfferPage, isLoadingUpdateOffer } = useRequestOfferPage({
    isUpdate,
    setIsUpdate,
    setTitlePage,
    setValue,
    replace,
  });

  const handleOffer = useCallback(
    async (e: Omit<OfferFormData, 'name'>) => {
      if (isUpdate) {
        return await handleUpdateOfferPage(e, titlePage);
      }
      await tryToRequestCreatingOfferFunnelPage({ name: titlePage, ...e });
    },
    [isUpdate, tryToRequestCreatingOfferFunnelPage, titlePage]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  const [activeSlide, setActiveSlide] = useState([fields[0].id]);
  useEffect(() => {
    setActiveSlide([fields[0].id]);
  }, [fields]);

  return (
    <LayoutFunnelPage>
      <TitleFunnelPage titlePage={titlePage} setTitlePage={setTitlePage} />
      <Row>
        <Form
          layout='vertical'
          requiredMark='optional'
          style={{
            width: '100%',
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
          onFinish={handleSubmit(handleOffer)}
        >
          <Collapse
            activeKey={activeSlide}
            onChange={e => setActiveSlide(e as any)}
            expandIconPosition='end'
          >
            {fields.map((field, index) => (
              <Collapse.Panel
                header={`Slide ${index + 1}`}
                key={field.id}
                extra={
                  <Button
                    type='text'
                    style={{
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 1}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                }
              >
                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].title`}
                      placeholder='Escreva o principal título da página.'
                      label='Título Principal'
                      defaultValue={field.title}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={12}>
                    <Controller
                      control={control}
                      name={`slides[${index}].profileId`}
                      placeholder='ID de liberação da oferta'
                      label='ID de Liberação'
                      defaultValue={
                        field.profileId ? String(field.profileId) : undefined
                      }
                      type='number'
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={12}>
                    <Controller
                      control={control}
                      name={`slides[${index}].releaseDays`}
                      placeholder='Período de liberação da oferta (em dias)'
                      label='Período (dias)'
                      defaultValue={
                        field.releaseDays
                          ? String(field.releaseDays)
                          : undefined
                      }
                      type='number'
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={12}>
                    <UploadImage
                      tooltip={{
                        title: () => (
                          <Typography.Text>
                            <strong>Versão Web</strong> - 482 x 282 pixels (jpg,
                            jpeg, png)
                          </Typography.Text>
                        ),
                      }}
                      control={control}
                      name={`slides[${index}].web`}
                      label='Versão Web'
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <UploadImage
                      tooltip={{
                        title: () => (
                          <Typography.Text>
                            <strong>Versão App</strong> - 343 x 124 pixels (jpg,
                            jpeg, png)
                          </Typography.Text>
                        ),
                      }}
                      control={control}
                      name={`slides[${index}].mobile`}
                      label='Versão Mobile'
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].imageTitle`}
                      placeholder='Título do lado da imagem'
                      label='Título do lado da imagem'
                      defaultValue={field.imageTitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].subtitle`}
                      placeholder='Subtítulo'
                      label='Subtítulo'
                      defaultValue={field.subtitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <TextEditController
                      control={control}
                      name={`slides[${index}].description`}
                      defaultValue={field.description}
                      placeholder='Apresente essa forma de contato.'
                      label='Descrição'
                      required
                    />
                  </Col>
                  <ContainerAddonAfter span={12}>
                    <InputNumberController
                      control={control}
                      name={`slides[${index}].oldValue`}
                      label='Valor antigo (De:)'
                      defaultValue={field.oldValue}
                      formatter={val =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(val))
                      }
                      parser={currencyParser}
                      placeholder='R$0.00'
                      required
                      style={{ width: '100%' }}
                    />
                  </ContainerAddonAfter>
                  <ContainerAddonAfter disabled span={12}>
                    <InputNumberController
                      control={control}
                      name={`slides[${index}].finalValue`}
                      label='Valor da oferta (Por:)'
                      defaultValue={field.finalValue}
                      formatter={val =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(val))
                      }
                      addonAfter={<Typography.Text>$</Typography.Text>}
                      disabled
                      parser={currencyParser}
                      placeholder='R$0.00'
                      required
                      style={{ width: '100%' }}
                    />
                  </ContainerAddonAfter>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].textValue`}
                      placeholder='Área de texto'
                      label='Texto em baixo do valor'
                      defaultValue={field.textValue}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].buttonTitle`}
                      placeholder='Título do botão'
                      label='Título do botão 1'
                      defaultValue={field.buttonTitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Row justify='center'>
            <Col>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  append({
                    title: '',
                    profileId: '',
                    releaseDays: '',
                    web: '',
                    mobile: '',
                    imageTitle: '',
                    subtitle: '',
                    description: '',
                    oldValue: 0,
                    finalValue: '',
                    textValue: '',
                    buttonTitle: '',
                  });
                }}
                disabled={fields.length >= 10}
              >
                Adicionar mais uma opção
              </Button>
            </Col>
          </Row>
          <CancelationButtonTitleFormFields control={control} />

          <Divider orientation='center' style={{ marginTop: '398px' }} />
          <Row justify='end'>
            <Row gutter={[8, 0]}>
              <Col>
                <Button type='default' onClick={() => history.goBack()}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={
                    isUpdate
                      ? isLoadingUpdateOffer
                      : isLoadingCreateOfferFunnelPage
                  }
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </Row>
    </LayoutFunnelPage>
  );
};

const ContainerAddonAfter = styled(Col)<{ disabled?: boolean }>`
  .ant-input-group-addon {
    border: 1px solid #434343;
    border-left: 0px;
    ${theme =>
      theme.disabled &&
      css`
        background-color: rgba(255, 255, 255, 0.08);
      `}
  }
`;
