import { Radio } from 'antd';

import { TemplateEnum } from 'src/models/OrderBump.model';

import { Container } from './styles';

type TTemplateSelectProps = {
  onChange: (value: TemplateEnum) => void;
  value: TemplateEnum;
};

export function TemplateSelect({ onChange, value }: TTemplateSelectProps) {
  return (
    <Container>
      <label>Selecione o template</label>
      <Radio.Group
        onChange={({ target }) => onChange(target.value)}
        value={value}
      >
        <Radio value={TemplateEnum.TEMPA}>Pontilhado</Radio>
        <Radio value={TemplateEnum.TEMPB}>Sólido</Radio>
      </Radio.Group>
    </Container>
  );
}
