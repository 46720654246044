import { EyeOutlined } from '@ant-design/icons';
import { MoreOutlined, UserAddOutlined } from '@ant-design/icons';
import { Events } from '@models';
import { PageListProps, PageListTemplate } from '@templates';
import { Button, Dropdown, Menu, Table } from 'antd';
import styled, { css } from 'styled-components';

import useProductTable from '../../hooks/useProductTable';
import { ProductDto } from '../../interfaces/offersInterface';
import { FilterProducts } from './FilterProducts';

export const ProductTable: React.FC = () => {
  const [data, filter, setFilter, isLoading] = useProductTable();

  const headerColumns: PageListProps<Events.ListEventsResponse>['columns'] = [
    {
      key: 'name',
      title: 'Nome do Produto',
      dataIndex: 'name',
    },
    {
      key: 'id',
      title: 'Código',
      dataIndex: 'id',
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: '',
      width: '100px',
      align: 'center',
      render: (item: ProductDto) => {
        return (
          <Dropdown
            trigger={['click']}
            arrow={false}
            overlay={() => {
              return (
                <Menu>
                  <MenuItem
                    key='view'
                    onClick={(e: any) => {
                      e.domEvent.stopPropagation();
                      const redirect = `/offers?id=${item.id}`;
                      window.location.href = redirect;
                    }}
                  >
                    <EyeOutlined style={{ marginRight: '12px' }} /> Visualizar
                  </MenuItem>
                </Menu>
              );
            }}
          >
            <ButtomMenu type='text'>
              <MoreOutlined />
            </ButtomMenu>
          </Dropdown>
        );
      },
    },
  ];

  const tableConfig = {
    columns: headerColumns,
    data: data,
    loading: isLoading,
  };

  if (data && !isLoading)
    return (
      <PageListTemplate
        filterComponent={
          <FilterProducts filter={filter} setFilter={setFilter} />
        }
        columns={tableConfig.columns}
        data={{ count: tableConfig.data?.length, rows: tableConfig.data }}
        pageSize={25}
      />
    );

  if (!data && !isLoading)
    return (
      <EmptyContainer>
        <InfosContainer>
          <UserAddOutlined />
          <Title>Nenhum produto encontrado</Title>
          <Text>
            Ainda não existem cadastros aqui. Você pode criar um novo, clicando
            no botão acima ”Adicionar”.
          </Text>
        </InfosContainer>
      </EmptyContainer>
    );

  return (
    <>
      <Table columns={headerColumns} loading={isLoading}></Table>
    </>
  );
};

const InfosContainer = styled.div`
  max-width: 332px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #303030;
  min-height: 100%;
`;

const Text = styled.h1`
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #7d7d7d;
  text-align: center;
  font-weight: 400;
`;

const Title = styled.h1`
  margin-top: 8px;
  font-size: 18px;
  line-height: 19.8px;
  color: #7d7d7d;
  font-weight: 600;
`;

const ButtomMenu = styled(Button)`
  &:focus {
    color: #3c89e8 !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  color: #acacac;

  ${props =>
    props.disabled &&
    css`
      color: rgba(255, 255, 255, 0.25) !important;
    `}
`;
