import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { events } from '@service/api';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { useMemo } from 'react';

import {
  Container,
  PreviewContainer,
  PreviewImage,
  PreviewRemoveButton,
} from './styles';

const { Dragger } = Upload;

type TImageFieldProps = {
  onChange: (value: string) => void;
  value: string;
  label: string;
};

export function ImageField({ label, onChange, value }: TImageFieldProps) {
  const props = useMemo<UploadProps>(
    () => ({
      name: 'file',
      multiple: true,
      showUploadList: {
        showPreviewIcon: false,
        showDownloadIcon: false,
        showRemoveIcon: true,
        downloadIcon: <CheckCircleOutlined />,
      },
      customRequest: async options => {
        try {
          const formData = new FormData();
          formData.append('files', options.file);
          const data = await events.uploadOrderBumpImage(formData);

          options.onSuccess?.(data);
          onChange(data[0].url);
        } catch (error: any) {
          options.onError?.(error, options.file);
        }
      },
    }),
    [onChange]
  );

  return (
    <Container>
      <label>{label}</label>
      {!!value ? (
        <PreviewContainer>
          <PreviewImage src={value} height={140} />
          <PreviewRemoveButton onClick={() => onChange('')}>
            <DeleteOutlined size={40} /> Remover
          </PreviewRemoveButton>
        </PreviewContainer>
      ) : (
        <Dragger style={{ borderRadius: 8 }} {...props}>
          <p className='ant-upload-drag-icon'>
            <svg
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <mask
                id='mask0_5428_5851'
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='32'
                height='32'
              >
                <rect width='32' height='32' fill='#D9D9D9' />
              </mask>
              <g mask='url(#mask0_5428_5851)'>
                <path
                  d='M6.2222 28C5.61109 28 5.08794 27.7824 4.65277 27.3472C4.21759 26.9121 4 26.3889 4 25.7778V6.2222C4 5.61109 4.21759 5.08794 4.65277 4.65277C5.08794 4.21759 5.61109 4 6.2222 4H19.3111V6.2222H6.2222V25.7778H25.7778V12.7111H28V25.7778C28 26.3889 27.7824 26.9121 27.3472 27.3472C26.9121 27.7824 26.3889 28 25.7778 28H6.2222ZM23.1111 11.6V8.9111H20.4222V6.6889H23.1111V4H25.3333V6.6889H28.0222V8.9111H25.3333V11.6H23.1111ZM8 22.6222H24L19.1333 16.1333L14.9778 21.6222L11.8889 17.5333L8 22.6222Z'
                  fill='#7D7D7D'
                />
              </g>
            </svg>
          </p>
          <p className='ant-upload-text'>
            <span style={{ color: '#1668DC' }}>Clique para carregar</span> ou
            arraste e solte
          </p>
          <p className='ant-upload-hint'>.png, .jpg ou .gif 296x140pixels</p>
        </Dragger>
      )}
    </Container>
  );
}
