import { enviroments } from '@infrastructure/enviroments';
import { Programs } from '@models';
import { base } from '@service/base';
import { CountResponse, TakeSkipFilter } from '@typings';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function getProgramsBySegments({
  take,
  skip,
  filter,
}: TakeSkipFilter): Promise<CountResponse<Programs.ProgramsResponse>> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/programs`,
    method: 'GET',
    params: {
      where: JSON.stringify({
        ...where,
      }),
      returnCount: true,
      skip: skip ?? 0,
      take: take ?? 10,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
