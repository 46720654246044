import moment from 'moment';
import { boolean, object, SchemaOf, string } from 'yup';

import { ModalScheduleProperties } from '../ModalDetail';

export const scheduleValidationSchema: SchemaOf<ModalScheduleProperties> =
  object({
    effectiveDateTime: string().nullable().required('Este campo é obrigatório'),
    expirationDateTime: string()
      .nullable()
      .required('Este campo é obrigatório')
      .test('notType', 'Este campo é obrigatório', function (value) {
        return !!value;
      })
      .test(
        'date-test',
        'Data de término menor que data de início',
        function (value) {
          const { effectiveDateTime } = this.parent;
          return moment(effectiveDateTime).isBefore(value);
        }
      ),
    activated: boolean().default(false),
  }).defined();
