import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const Centered = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: ${() => `${window.document.documentElement.scrollHeight}px`};
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 999;
`;
