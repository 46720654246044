/* eslint-disable @typescript-eslint/no-empty-function */
import { UpsellFlow } from '@models';
import { ActionsDropdown } from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { format, parseISO } from 'date-fns';

export interface TableUpsellFlowProps {
  data?: UpsellFlow.TUpsellFlowResponse;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditUpsellFlow?: (value: UpsellFlow.IUpsellFlow) => void;
  onChangeUrl?: (url: string) => void;
  onDuplicateOption?: (url: UpsellFlow.IUpsellFlow) => void;
  isLoading: boolean;
}

export type TColumnUrl = {
  column: string;
  inverse: boolean;
};

export const TableUpsellFlow: React.FC<TableUpsellFlowProps> = ({
  data,
  onChangePagination,
  isLoading,
  onEditUpsellFlow,
}) => {
  const headerColumns: PageListProps<UpsellFlow.IUpsellFlow>['columns'] = [
    {
      title: 'Fluxo',
      dataIndex: 'name',
      key: 'name',
      render: (name: UpsellFlow.IUpsellFlow['name']) => {
        return String(name);
      },
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      render: (createdAt: UpsellFlow.IUpsellFlow['createdAt']) => {
        return format(parseISO(createdAt), 'dd/MM/yyyy');
      },
    },
    {
      title: 'Steps',
      dataIndex: 'steps',
      key: 'steps',
      width: '120px',
      render: (steps: UpsellFlow.IUpsellFlow['steps']) => {
        return steps;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text, record) => (
        <ActionsDropdown
          onClickEditOption={
            !!onEditUpsellFlow ? () => onEditUpsellFlow(record) : undefined
          }
        />
      ),
    },
  ];

  if (!data && isLoading) return null;

  return (
    <PageListTemplate
      columns={headerColumns}
      data={data}
      loading={isLoading}
      onChange={onChangePagination}
      pageSize={5}
    />
  );
};
