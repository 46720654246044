import { useMutation } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { offerResponseFactory } from '../../utils/offerFunnelsPagesFactory';

export function useTryToRequestOfferPage() {
  const { mutateAsync } = useMutation(
    async (id: string) => {
      const response =
        await apiCancellationFunnels.tryToRequestGetOneCancellationFunnelPage(
          id
        );

      const factory = offerResponseFactory(response.data);

      return factory;
    },
    {
      onError: () => {
        OpenNotification(
          true,
          'Erro ao solicitar dados de Oferta',
          'Ocorreu um erro ao solicitar dados de Oferta, por favor tente novamente mais tarde.'
        );
      },
    }
  );

  return { tryToRequestOfferPage: mutateAsync };
}
