export function isEmail(isEmailParam?: string) {
  if (
    !isEmailParam?.includes('@') ||
    !isEmailParam?.includes('.') ||
    isEmailParam?.includes(' ')
  ) {
    return false;
  }
  return true;
}

export function isNumeric(str?: string) {
  if (typeof str != 'string') {
    return false;
  }
  return !isNaN(parseFloat(str));
}

export function removeSpecialChars(paramremoveSpecialChars: string) {
  paramremoveSpecialChars = paramremoveSpecialChars.replace('.', '');
  paramremoveSpecialChars = paramremoveSpecialChars.replace('-', '');
  paramremoveSpecialChars = paramremoveSpecialChars.replace('(', '');
  paramremoveSpecialChars = paramremoveSpecialChars.replace(')', '');
  return paramremoveSpecialChars.trim();
}

export function convertToCurrency(value: number) {
  const cem = 100;
  const dois = 2;
  return (Math.round(value * cem) / cem).toFixed(dois).replace('.', ',');
}

export function cpfMask(value?: string) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+$/, '$1');
  }
  return '';
}

export function phoneMask(value?: string) {
  if (!value) {
    return value;
  }
  const digitTwo = 2;
  const digitZero = 0;
  const digitFour = 4;
  const digitNine = 9;
  const digitThreeTeen = 13;
  const currentValue = value.replace(/[^\d]/g, '');

  return `+${currentValue.slice(digitZero, digitTwo)} (${currentValue.slice(
    digitTwo,
    digitFour
  )}) ${currentValue.slice(digitFour, digitNine)} ${currentValue.slice(
    digitNine,
    digitThreeTeen
  )}`;
}
