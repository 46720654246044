import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AnyObject } from 'yup/lib/types';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { ICustomerVipGroupResponseDto } from 'src/core/admin-cx/customer-vip-group/dto/customer-vip-group-response.dto';
import { createCustomerVipGroupApi } from 'src/features/clients/api/customer-vip-group.api';
import { ModalGroupVipActions } from 'src/features/clients/screens/client-details-screen/client-groups-vip/ModalGroupVipActions';
import { ButtonInput } from 'src/features/clients/styles/ClientStyles';
import { ClientType } from 'src/features/clients/types/clientTypes';

const customerVipGroupApi = createCustomerVipGroupApi();

export type ClientGroupsVipProps = {
  customer?: ClientType;
};

export const ClientGroupsVipTab: FC<ClientGroupsVipProps> = ({
  customer = undefined,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCustomerVipGroup, setSelectedCustomerVipGroup] = useState<
    AnyObject | undefined
  >(undefined);

  const showErrorNotification = (): void => {
    OpenNotification(
      true,
      'Erro ao carregar os grupos',
      'Ocorreu um erro  ao carregar os grupos, por favor tente novamente mais tarde.'
    );
  };

  const fetchGroupsVip = async () => {
    setLoading(true);
    if (!customer) {
      setLoading(false);
      setListCustomerVipGroup([]);
      return;
    }
    const response = await customerVipGroupApi.getVipGroupByCustomer(
      parseInt(customer?.id)
    );
    if (!response || (response && !response.result)) {
      setLoading(false);
      showErrorNotification();
      setListCustomerVipGroup([]);
      return;
    }
    setLoading(false);
    setListCustomerVipGroup(response.data);
  };
  const [listCustomerVipGroup, setListCustomerVipGroup] = useState<
    ICustomerVipGroupResponseDto[]
  >([]);

  useEffect(() => {
    fetchGroupsVip().catch(console.error);
  }, []);

  const handleNovoGrupoClick = () => {
    setShowModal(true);
    setSelectedCustomerVipGroup(undefined);
    return;
  };

  const handleCloseModal = async () => {
    setShowModal(false);
    setSelectedCustomerVipGroup(undefined);
    await fetchGroupsVip();
  };

  const handleOpenModalVipGroupParticipant = (
    rowData: ICustomerVipGroupResponseDto
  ) => {
    setShowModal(true);
    setSelectedCustomerVipGroup(rowData);
  };

  return (
    <>
      <Row justify='end' align='middle' style={{ marginBottom: 10 }}>
        <Col>
          <ButtonInput
            icon={<PlusCircleOutlined />}
            type='link'
            onClick={handleNovoGrupoClick}
          >
            Associar novo grupo
          </ButtonInput>
        </Col>
      </Row>
      <TableComponent
        loading={loading}
        columns={[
          {
            title: 'Rede Social',
            dataIndex: 'socialNetworkName',
            render: (_, rowData: ICustomerVipGroupResponseDto) => (
              <Button
                type='link'
                block
                onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                style={{
                  padding: 0,
                  textAlign: 'start',
                }}
              >
                {rowData.vipGroup?.socialNetwork?.name}
              </Button>
            ),
          },
          {
            title: 'Grupo',
            dataIndex: 'groupName',
            key: 'groupName',
            render: (_, rowData: ICustomerVipGroupResponseDto) => (
              <Button
                type='link'
                block
                onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                style={{
                  padding: 0,
                  textAlign: 'start',
                }}
              >
                {rowData.vipGroup?.name}
              </Button>
            ),
          },
          {
            title: 'Nome Rede',
            dataIndex: 'name',
            key: 'name',
            render: (_, rowData: ICustomerVipGroupResponseDto) => (
              <Button
                type='link'
                block
                onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                style={{
                  padding: 0,
                  textAlign: 'start',
                }}
              >
                {rowData.name}
              </Button>
            ),
          },
          {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (_, rowData: ICustomerVipGroupResponseDto) => (
              <Button
                type='link'
                block
                onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                style={{
                  padding: 0,
                  textAlign: 'start',
                }}
              >
                {rowData.url}
              </Button>
            ),
          },
          {
            title: 'Data inclusão',
            dataIndex: 'inclusionDate',
            key: 'inclusionDate',
            render: (_, rowData: ICustomerVipGroupResponseDto) => (
              <Button
                type='link'
                block
                onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                style={{
                  padding: 0,
                  textAlign: 'start',
                }}
              >
                {dateFormatted(rowData.includedAt)}
              </Button>
            ),
          },
          {
            title: 'Data exclusão',
            dataIndex: 'departureDate',
            key: 'departureDate',
            render: (_, rowData: ICustomerVipGroupResponseDto) => {
              if (!rowData.excludedAt) {
                return '';
              }
              return (
                <>
                  <Button
                    type='link'
                    block
                    onClick={() => handleOpenModalVipGroupParticipant(rowData)}
                    style={{
                      padding: 0,
                      textAlign: 'start',
                    }}
                  >
                    {dateFormatted(rowData.excludedAt)}
                  </Button>
                </>
              );
            },
          },
        ]}
        data={listCustomerVipGroup}
        pageSize={50}
      />
      <ModalGroupVipActions
        data={selectedCustomerVipGroup}
        customerId={customer ? parseInt(customer?.id) : undefined}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
