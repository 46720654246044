import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { DataResponseApiDto } from 'src/core/dto/data-response-api.dto';
import { IBadgeRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';
import { IBadgeResponseDto } from 'src/core/gamefication/dto/badge-group/badge-response.dto';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import {
  clearBadgeOnEdition,
  GamificationState,
  updateBadgeInBadgeGroup,
} from '../gamefication.slice';
import { saveAllBadgeCompetitionEventList } from './saveCompetitionEvents.builder';

export interface SaveFullBadgeProps {
  competitionId: number;
  badgeGroupId: number;
  badge: IBadgeRequestDto;
}

export const saveFullBadgeEdition = createAsyncThunk(
  'gamification/badgeGroups/saveBadgeEdition',
  async (props: SaveFullBadgeProps, { rejectWithValue, dispatch }) => {
    try {
      const { badge, badgeGroupId, competitionId } = props;
      const requestBody = {
        description: badge.description,
        imagePath: badge.imagePath,
        name: badge.name,
        order: badge.order,
      };

      let response: DataResponseApiDto<IBadgeResponseDto>;

      if (badge.isNew) {
        response = await badgesGroupsApi.createBadge({
          badgeGroupId,
          ...requestBody,
        });
      } else {
        response = await badgesGroupsApi.updateBadge(badge.id, requestBody);
      }

      if (!response?.data) {
        throw new Error('saveBadgeEdition: Nenhum dado foi retornado');
      }

      OpenNotification(false, 'Badge salva com sucesso.');

      if (badge.competitionEvents?.length) {
        OpenNotification(false, 'Salvando eventos...', undefined, 'info');
        dispatch(
          saveAllBadgeCompetitionEventList({
            badgeId: response.data.id,
            competitionEventList: badge.competitionEvents,
            competitionId,
          })
        );
      }

      dispatch(
        updateBadgeInBadgeGroup({ oldBadge: badge, newBadge: response.data })
      );
      dispatch(clearBadgeOnEdition());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveBadgeEditionBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(saveFullBadgeEdition.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(saveFullBadgeEdition.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
    if (!state.badgeGroup?.badges) return;

    console.log('BADGE SALVA', payload);

    state.badgeGroup.badges = payload as unknown as IBadgeRequestDto[];
  });

  builder.addCase(saveFullBadgeEdition.rejected, state => {
    state.status = 'ERROR';
  });
};
