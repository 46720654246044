import { Form } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { offerApi } from '../../../../../../../../features/offers/apis/offerApis';
import { useCreateOrderBump } from '../../../../hooks/useCreateOrderBump';

export function useGeneralConfiguration() {
  const [form] = Form.useForm();
  const [isSearchOriginOffer, setIsSearchOriginOffer] = useState(false);
  const [searchOriginOfferError, setSearchOriginOfferError] = useState<
    null | string
  >(null);
  const [searchOriginOffer, setSearchOriginOffer] = useState('');
  const [isLoadingRemoveOriginOffer, setIsLoadingRemoveOriginOffer] =
    useState(false);

  const {
    name,
    addOriginOfferId,
    originOffers,
    removeOriginOfferId,
    statusSelected,
    ableToSave: { setter: setAbleToSave },
    destinationOfferId: {
      value: destinationOfferId,
      setter: setDestinationOfferId,
    },
  } = useCreateOrderBump();

  const [destinationOffer, setDestinationOffer] = useState(
    destinationOfferId ? `${destinationOfferId}` : ''
  );
  const [destinyOfferStatus, setDestinyOfferStatus] = useState<
    '' | 'success' | 'error' | 'validating'
  >('');
  const [searchDestinyOfferError, setSearchDestinyOfferError] = useState<
    null | string
  >(null);

  const onChangeStatus = useCallback(
    (stateStatus: boolean) => statusSelected.setter(stateStatus),
    []
  );

  const descriptionStatus = useMemo(
    () => (statusSelected.value ? 'Ativo' : 'Inativo'),
    [statusSelected.value]
  );

  const onChangeSearchDestinyOffer = useCallback((value: string) => {
    setDestinyOfferStatus('');
    setSearchDestinyOfferError(null);
    setDestinationOffer(value);
  }, []);

  const handleSearchDestinyOffer = useCallback(async (id: string) => {
    try {
      if (!id) {
        return;
      }
      if (isNaN(Number(id))) {
        setDestinyOfferStatus('error');
        setSearchDestinyOfferError('Digite um número válido');
        return;
      }
      setDestinyOfferStatus('validating');
      const offer = await offerApi.getOfferById(id);
      setDestinationOfferId(Number(offer.id));
      setDestinyOfferStatus('success');
    } catch (err) {
      console.error(err);
      setDestinyOfferStatus('error');
    }
  }, []);

  const handleSearchOriginOffer = useCallback(
    async (id: string) => {
      setSearchOriginOfferError(null);
      if (!id || isNaN(Number(id))) {
        setSearchOriginOfferError('Campo vazio ou número inválido.');
        return;
      }
      try {
        setIsSearchOriginOffer(true);
        const offer = await offerApi.getOfferById(id);

        const { success, errorMessage } = await addOriginOfferId(offer);

        if (!success) {
          setSearchOriginOfferError(
            errorMessage || 'Não foi possível encontrar essa oferta.'
          );
          return;
        }
      } catch (err) {
        console.error(err);
        setSearchOriginOfferError('Não foi possível encontrar essa oferta.');
      } finally {
        setSearchOriginOffer('');
        setIsSearchOriginOffer(false);
      }
    },
    [addOriginOfferId, form]
  );

  useEffect(() => {
    if (destinyOfferStatus !== 'success' || !name.value) {
      setAbleToSave(false);
      return;
    }

    setAbleToSave(true);
  }, [destinyOfferStatus, name.value]);

  useEffect(() => {
    if (!destinyOfferStatus && !!destinationOfferId)
      handleSearchDestinyOffer(String(destinationOfferId));
  }, [destinationOfferId]);

  const handleRemoveOriginOffer = useCallback(
    async (offerId: string) => {
      setIsLoadingRemoveOriginOffer(true);
      await removeOriginOfferId(offerId);
      setIsLoadingRemoveOriginOffer(false);
    },
    [removeOriginOfferId]
  );

  return {
    form,
    descriptionStatus,
    onChangeStatus,
    statusSelected,
    handleSearchOriginOffer,
    originOffers,
    handleRemoveOriginOffer,
    isLoadingRemoveOriginOffer,
    isSearchOriginOffer,
    searchOriginOfferError,
    searchOriginOffer,
    setSearchOriginOffer,
    destinyOfferStatus,
    handleSearchDestinyOffer,
    searchDestinyOfferError,
    setDestinyOfferStatus,
    onChangeSearchDestinyOffer,
    destinationOffer,
  };
}
