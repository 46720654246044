import { Controller, TextAreaController } from '@organisms';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

import { UploadImage } from 'src/features/Funnel/Components/UploadImage';

interface FormBasicDataProps {
  control: any;
}

export const FormBasicData: FC<FormBasicDataProps> = ({
  control,
}: FormBasicDataProps) => {
  return (
    <Row style={{ display: 'flex', width: '100%' }}>
      <Col style={{ width: '100%' }}>
        <Controller
          control={control}
          showCount={true}
          name={`offerName`}
          defaultValue={''}
          placeholder='Digite o nome da oferta'
          autoComplete='off'
          label='Nome da Oferta'
          maxLength={80}
          required
        />
      </Col>
      <Col style={{ width: '100%' }}>
        <TextAreaController
          control={control}
          name={`offerDescription`}
          defaultValue={''}
          placeholder='Digite seu texto aqui.'
          label='Descrição'
          isMaxLength={false}
          required
        />
      </Col>
      <Row
        style={{
          display: 'flex',
          paddingBlockStart: '24px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Col style={{ flex: 1, paddingInlineEnd: '12px' }}>
          <UploadImage
            style={{ width: '100%' }}
            tooltip={{
              title: () => (
                <Typography.Text>
                  Banner Web - 602 x 131 pixels ( mp4, jpg, jpeg, png ou gif)
                </Typography.Text>
              ),
            }}
            control={control}
            name={`bannerWeb`}
            label='Banner Web'
            required={false}
          />
        </Col>
        <Col
          style={{
            flex: 1,
            paddingInlineStart: '12px',
          }}
        >
          <UploadImage
            style={{ width: '100%' }}
            tooltip={{
              title: () => (
                <Typography.Text>
                  Banner Responsivo - 351 × 203 pixels ( mp4, jpg, jpeg, png ou
                  gif)
                </Typography.Text>
              ),
            }}
            control={control}
            name={`bannerMobile`}
            label='Banner Responsivo'
            required={false}
          />
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          paddingBlockStart: '24px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Col style={{ flex: 1, paddingInlineEnd: '12px' }}>
          <UploadImage
            style={{ width: '100%' }}
            tooltip={{
              title: () => (
                <Typography.Text>
                  Banner Lateral - 225 x 754 pixels ( mp4, jpg, jpeg, png ou
                  gif)
                </Typography.Text>
              ),
            }}
            control={control}
            name={`bannerSideWeb`}
            label='Banner Lateral'
            required={false}
          />
        </Col>
        <Col style={{ flex: 1, paddingInlineStart: '12px' }}>
          <UploadImage
            style={{ width: '100%' }}
            tooltip={{
              title: () => (
                <Typography.Text>
                  Logotipo da Oferta - 40x40 pixels ( mp4, jpg, jpeg, png ou
                  gif)
                </Typography.Text>
              ),
            }}
            control={control}
            name={`logo`}
            label='Logotipo da Oferta'
            required={false}
          />
        </Col>
      </Row>
    </Row>
  );
};
