import { slices } from '@core/redux';
import { UpsellFlow } from '@models';
import { PageLimitFilter } from '@typings';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { ProductDto } from 'src/features/offers/interfaces/offersInterface';
import { TableUpsellFlowProps } from 'src/features/upsellFunnel/screens/UpsellFlow/Table.upsellFlow';
import { getAllUpsellFlow as getAllUpsellFlowApi } from 'src/service/api/upsellFlow';

import OpenNotification from '../components/antd/OpenNotification';

type ParamsProps = {
  productSellGroup?: ProductDto;
};

export function useUpsellFlow() {
  const params = useLocation();

  const { productSellGroup } = params.state as ParamsProps;

  const dispatch = useDispatch();
  const { push } = useHistory();
  const [upsellList, setUpsellFlowList] =
    useState<UpsellFlow.TUpsellFlowResponse>();

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getAllUpsellFlow = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await getAllUpsellFlowApi(
        productSellGroup?.id || 0,
        props
      );
      setUpsellFlowList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllUpsellFlow();
  }, []);

  useBreadcrumbs([
    {
      label: 'Upsell',
    },
    {
      label: 'Produtos',
    },
    {
      label: productSellGroup?.name || '',
    },
  ]);

  const handleChangePagination: TableUpsellFlowProps['onChangePagination'] = (
    take,
    _,
    pagination
  ) => {
    getAllUpsellFlow({
      ...currentFilter,
      limit: take,
      page: pagination,
    });
  };

  const handleUpsellFlow = useCallback(
    (order: string) => {
      getAllUpsellFlow({ ...currentFilter, order });
    },
    [getAllUpsellFlow, currentFilter]
  );

  const handleEditUpsellFlow = useCallback(
    (upsellFlow: UpsellFlow.IUpsellFlow) => {
      push('/upsell/step/flow', {
        upsellFlow,
        productSellGroup,
      });
    },
    [push]
  );

  return {
    productSellGroup,
    handleChangePagination,
    upsellList,
    isLoadingData,
    handleUpsellFlow,
    handleEditUpsellFlow,
  };
}
