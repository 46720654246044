import { FormattedOffer, Offer } from '../interfaces/offersInterface';

export function offerTableFactory(data: Offer[]) {
  function formatReal(value: number) {
    return `R$ ${value.toFixed(2).replace('.', ',')}`;
  }

  return data.map(data => {
    const formattedOffer: FormattedOffer = {
      id: data.id,
      name: data.name,
      fullPrice: formatReal(data.fullPrice),
      startDate: new Date(data.startDate).toLocaleDateString(),
      endDate: data.endDate && new Date(data.endDate).toLocaleDateString(),
      active: data.active,
      installments: data.installments,
      installmentsPrice: formatReal(data.fullPrice / data.installments),
    };

    return formattedOffer;
  });
}
