import { FC } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';

import { ScheduledTaskResponseDto } from '../dto/scheduledTaskResponse.dto';
import { StyledTable } from '../styles';

type IScheduledReportsTable = {
  dataSource: ScheduledTaskResponseDto[] | undefined;
  loading: boolean;
};

const COLUMNS = [
  { title: 'ID', dataIndex: ['data', 'idCustomer'], key: 'id' },
  { title: 'Nome', dataIndex: ['data', 'name'], key: 'fullName' },
  { title: 'E-mail', dataIndex: ['data', 'email'], key: 'email' },
  {
    title: 'Data Agendada',
    dataIndex: 'dateScheduled',
    key: 'dateScheduled',
    render: (value: Date) => dateFormatted(String(value), true),
  },
  {
    title: 'Assinatura',
    dataIndex: ['data', 'subscriptionName'],
    key: 'subscriptionName',
  },
  {
    title: 'Plataforma',
    dataIndex: 'paymentProvider',
    key: 'provider',
    render: (value: string) => value[0].toUpperCase() + value.substring(1),
  },
  {
    title: 'Colaborador',
    dataIndex: ['loggedUser', 'fullName'],
    key: 'employee',
  },
  { title: 'Status', dataIndex: ['status', 'name'], key: 'status' },
];

export const ScheduledReportsTable: FC<IScheduledReportsTable> = ({
  dataSource,
  loading,
}) => (
  <StyledTable
    bordered
    dataSource={dataSource}
    columns={COLUMNS}
    pagination={{ position: ['bottomCenter'] }}
    loading={loading}
    rowKey='id'
  />
);
