import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC } from 'react';

import { FilterOffersProps } from '../../interfaces/offersInterface';

export const FilterProducts: FC<FilterOffersProps> = ({
  filter,
  setFilter,
}: FilterOffersProps) => {
  return (
    <Input
      placeholder={'Pesquisar'}
      suffix={<SearchOutlined />}
      value={filter}
      onChange={value => {
        setFilter(value.target.value);
      }}
    />
  );
};
