import { enviroments } from '@infrastructure/enviroments';

import { RemoteCheckoutConfig } from 'src/models/remoteCheckoutConfig.model';

import fallbackData from './fallback.json';
import { getRemoteCheckoutConfigFromS3 } from './remoteCheckoutConfigFromS3';

export const implementationRemoteConfig = {
  S3: getRemoteCheckoutConfigFromS3,
  FALLBACK: () => fallbackData as RemoteCheckoutConfig,
};

const PRESET_DEFAULT: keyof typeof implementationRemoteConfig =
  enviroments.IS_DEVELOPMENT ? 'FALLBACK' : 'S3';

export async function getRemoteCheckoutConfig(): Promise<RemoteCheckoutConfig> {
  const configs = await implementationRemoteConfig[PRESET_DEFAULT]();
  return configs;
}
