import { useModal } from '@hooks';
import { Modal } from '@models';
import { SelectController } from '@organisms';
import { IModalScreen } from '@service/api/modals/getModalScreens';
import { Col, Form, Row } from 'antd';
import { useEffect } from 'react';

import { env } from 'src/app/env';

import { ModelStepBaseComponentProp } from '../ModalDetail';
import { BaseContent } from './BaseContent.component';

export const HighlightContent = ({
  form,
}: ModelStepBaseComponentProp<Modal.IDefaultContent>) => {
  const { modalScreens, getModalScreens, getModalScreensOptions } = useModal();
  const modalScreensOptions = getModalScreensOptions(
    modalScreens as IModalScreen[],
    env.URL_SITE || ''
  );

  useEffect(() => {
    getModalScreens();
  }, []);
  return (
    <Form layout='vertical'>
      <Row>
        <Col span={24}>
          <SelectController
            control={form.control}
            name='urlLocalShow'
            options={modalScreensOptions}
            label='Local para exibição do modal'
            optionFilterProp='label'
            placeholder='Escolha a página onde o modal será exibido'
          />
        </Col>
      </Row>

      <BaseContent form={form} />
    </Form>
  );
};
