import { ModalContainer } from '@atoms';

import { ModalFooter } from './Footer.molecule';
import { ModalHeader } from './Header.molecule';
import { ModalProps } from './Modal.interface';
import * as S from './Modal.style';
import { ModalTabs } from './Tabs.molecule';

export const Modal = ({
  children,
  title,
  width,
  onCancel,
  allowContinue = true,
  buttonFormId,
  overflow,
  onClose,
  onSubmit,
}: ModalProps) => {
  return (
    <ModalContainer width={width} overflow={overflow}>
      <ModalHeader title={title} onCrossClick={onClose} />
      <S.Content>{children}</S.Content>

      <ModalFooter
        allowContinue={allowContinue}
        onCancel={onCancel}
        onSubmit={onSubmit}
        buttonFormId={buttonFormId}
      />
    </ModalContainer>
  );
};

Modal.Tabs = ModalTabs;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
