import { RootState, slices } from '@core/redux';

export const selectCurrentLayout = (state: RootState): 'auth' | 'application' =>
  state.layout.layout;

export const selectIsSidebarCollapsed = (state: RootState): boolean =>
  state.layout.isSidebarCollapsed;

export const selectCurrentBreadcrumbs = (
  state: RootState
): slices.layout.BreadcrumbItem[] => state.layout.breadcrumbs;
