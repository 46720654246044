import { Alert, Col, Form, Input, Row } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

type ModalProps = {
  loading?: boolean;
  error?: string;
  codigo?: string;
  setCodeRecoveryAccess?: Dispatch<SetStateAction<string>>;
  setErrorCodeRecoveryAccess?: Dispatch<SetStateAction<string>>;
  onConfirm?: () => void;
};

export const ModalRecoveryAccessCodeStep: FC<ModalProps> = ({
  loading = false,
  error = '',
  codigo = '',
  setCodeRecoveryAccess,
  setErrorCodeRecoveryAccess,
  onConfirm,
}) => {
  const [statusCodigo, setStatusCodigo] = useState<ValidateStatus>('warning');
  const validaStatusCodigo = () => {
    const isWarning = (codigo && codigo === '') || !codigo || codigo.length < 6;
    const isError = error && error !== '';

    setStatusCodigo(isWarning ? 'warning' : isError ? 'error' : 'success');
  };
  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (setErrorCodeRecoveryAccess) {
      setErrorCodeRecoveryAccess('');
    }
    if (setCodeRecoveryAccess) {
      setCodeRecoveryAccess(e.currentTarget.value);
    }
    validaStatusCodigo();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onConfirm && e.key === 'Enter') {
      onConfirm();
    }
  };
  useEffect(() => validaStatusCodigo(), [codigo, error]);
  const showAlerts = (): JSX.Element | undefined => {
    if (loading) {
      return (
        <Alert
          message='Obrigado! Estamos verificando o código... Por favor, aguarde.'
          description=''
          type='info'
          showIcon
        />
      );
    }
    return (
      <>
        <Alert
          style={{
            marginBottom: 10,
          }}
          message='Enviamos um código para seu e-mail'
          description={
            <>
              <p className='text-silver-chalice'>
                Informe o código enviado e clique no botão confirmar.
              </p>
              <p className='text-silver-chalice'>
                Caso não tenha recebido um e-mail, verifique a caixa de Spam.
              </p>
            </>
          }
          type='success'
          showIcon
        />
        <Alert
          message='Caso não tenha recebido um e-mail, entre em contato com o administrador.'
          description=''
          type='info'
          showIcon
        />
      </>
    );
  };
  const showErrorContent = (): JSX.Element | undefined => {
    if (codigo && codigo !== '' && error && error !== '') {
      return (
        <span
          data-testid='input-error-code'
          style={{
            marginTop: 5,
            color: '#D32029',
          }}
        >
          {error}
        </span>
      );
    }
    return undefined;
  };
  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10, width: '100%' }}>
          {showAlerts()}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ width: '100%' }}>
          <Form layout='vertical'>
            <Form.Item
              style={{
                marginBottom: statusCodigo !== 'success' ? 5 : 0,
              }}
              hasFeedback={true}
              validateStatus={statusCodigo}
              label='Código'
              tooltip='Digite o código de validação informado no e-mail que enviamos.'
            >
              <Input
                type='number'
                min='0'
                disabled={loading}
                placeholder='Digite o código de validação informado no e-mail que enviamos.'
                data-testid='input-codigo'
                autoComplete='off'
                onChange={onInputChange}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            {showErrorContent()}
          </Form>
        </Col>
      </Row>
    </>
  );
};
