import { UpsellStepsFlow } from '@models';
import { base } from '@service/base';

export async function updateUpsellStepFlow(
  upsellSteps: Pick<
    UpsellStepsFlow.IUpsellStepsFlow,
    'down' | 'flowId' | 'id' | 'isActive' | 'name' | 'linkId' | 'up' | 'order'
  >[]
) {
  const response = await base.put<UpsellStepsFlow.IUpsellStepsFlow>(
    `/funnel/flow-steps`,
    upsellSteps.map(
      ({ down, flowId, id, isActive, name, linkId, up, order }) => ({
        down,
        flowId,
        id,
        isActive,
        name,
        linkId,
        up,
        order,
      })
    )
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
