import { DataResponseApiDto } from '@core/dto/data-response-api.dto';
import { enviroments } from '@infrastructure/enviroments';
import { Events } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.GAMEFICATION_URL;

export async function getEventById(
  id: string
): Promise<DataResponseApiDto<Events.EventResponseDto>> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/event/${id}`,
    method: 'GET',
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
