import {
  VideoFormData,
  VideoPayload,
  VideoResponse,
} from '../types/videoPageTypings';

export const videoPayloadFunnelsPageFactory = async (
  formData: VideoFormData
) => {
  const factory: VideoPayload = {
    cancellationFunnelId: Number(formData.cancellationFunnelId),
    pageType: 'video',
    name: formData.name,
    content: {
      slides: await Promise.all(
        formData.slides.map(async slide => {
          return {
            title: slide.title,
            videoUrl: slide.videoUrl,
            description: slide.description,
            mustWatch: slide.mustWatch,
          };
        })
      ),
      continueCancelationButtonTitle:
        formData.continueCancelationButtonTitle || null,
      keepSubscriptionButtonTitle: formData.keepSubscriptionButtonTitle || null,
    },
  };

  return factory;
};

export const videoResponseFactory = (response: VideoResponse) => {
  const body = {
    cancellationFunnelId: `${response.cancellationFunnelId}`,
    name: response.name,
    slides: response.content.slides.map(slide => ({
      title: slide.title,
      videoUrl: slide.videoUrl,
      description: slide.description,
      mustWatch: slide.mustWatch,
    })),
    continueCancelationButtonTitle:
      response.content.continueCancelationButtonTitle,
    keepSubscriptionButtonTitle: response.content.keepSubscriptionButtonTitle,
  };

  return body;
};
