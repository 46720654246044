export const LoginSvg = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.45 42V39H39V9H24.45V6H39C39.8 6 40.5 6.3 41.1 6.9C41.7 7.5 42 8.2 42 9V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H24.45ZM20.55 32.75L18.4 30.6L23.5 25.5H6V22.5H23.4L18.3 17.4L20.45 15.25L29.25 24.05L20.55 32.75Z'
      fill='white'
    />
  </svg>
);
