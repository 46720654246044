import { Button, Col, Result, Row } from 'antd';
import { FC } from 'react';

type ModalProps = {
  onClose?: () => void;
};

export const ModalRecoveryAccessSuccessStep: FC<ModalProps> = ({ onClose }) => {
  const onGoToLoginClick = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10, width: '100%' }}>
          <Result
            status='success'
            title='Senha alterada com sucesso!'
            subTitle='Para acessar o sistema, utilize a sua senha recém-criada.'
            extra={[
              <Button type='primary' key='console' onClick={onGoToLoginClick}>
                Ir para tela inicial
              </Button>,
            ]}
          />
        </Col>
      </Row>
    </>
  );
};
