import { useEffect, useState } from 'react';

import { EventResponseDto } from 'src/core/gamefication/dto/event/event-response.dto';

import * as badgesGroupsApi from '../api/badgesGroupsApi';

export const useGetAllEvents = () => {
  const [data, setData] = useState<Array<EventResponseDto>>([]);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const fetchData = async (): Promise<void> => {
    try {
      const response = await badgesGroupsApi.getEvents();

      setData(response.rows);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return [data, error, loading] as const;
};
