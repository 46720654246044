import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type TFAQTooltipProps = {
  message: string;
};

export function FAQTooltip({ message }: TFAQTooltipProps) {
  return (
    <Tooltip title={message}>
      <QuestionCircleOutlined />
    </Tooltip>
  );
}
