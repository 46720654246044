import { InfoCircleOutlined } from '@ant-design/icons';
import { TUpsellStepsFlowManage } from '@hooks';
import { getAllUpsellLinks } from '@service/api/upsellLinks';
import { createUpsellStepFlow } from '@service/api/upsellStepFlow';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';

import { IUpsellLinks } from 'src/models/UpsellLinks.model';

export type ManageStepModalProps = {
  onCloseModal: () => void;
  flowId: number;
  onCreateFinish: () => void;
  editMode?: {
    stepEdit: TUpsellStepsFlowManage;
    onEditFinish: (editStep: TUpsellStepsFlowManage) => void;
  };
};

type FormData = {
  name: string;
  linkId: number;
  order: number;
};

export function ManageStepModal({
  onCloseModal,
  flowId,
  onCreateFinish,
  editMode,
}: ManageStepModalProps) {
  const [form] = Form.useForm<FormData>();
  const [links, setLinks] = useState<IUpsellLinks[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async ({ linkId, name, order }: FormData) => {
    try {
      if (!!editMode) {
        editMode.onEditFinish({
          ...editMode.stepEdit,
          linkId,
          name,
          order,
          flowId,
        });
        return;
      }

      setIsLoading(true);
      await createUpsellStepFlow({
        linkId,
        name,
        order,
        flowId,
      });

      const link = links.find(link => link.id === linkId);

      if (!link) return;

      onCreateFinish();
    } catch (err) {
      console.error('>> error ao salvar', err);
    } finally {
      onCloseModal();
    }
  };

  const handleOk = async () => {
    try {
      await form.validateFields();

      form.submit();
    } catch (err) {
      console.error('Error submit', err);
    }
  };

  const handleCancel = () => {
    onCloseModal();
  };

  const handleSearch = async (value: string) => {
    const { rows } = await getAllUpsellLinks({
      filter: !!value ? { filter: value } : undefined,
    });

    setLinks(rows);
  };

  useEffect(() => {
    if (!editMode) return;

    handleSearch(editMode.stepEdit.link.urlName);
  }, [editMode]);

  return (
    <Modal
      title='Criação de nova etapa'
      open
      onOk={handleOk}
      onCancel={handleCancel}
      okText={!editMode ? 'Salvar' : 'Editar'}
      okButtonProps={{
        loading: isLoading,
        disabled: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={false}
      maskClosable={false}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={editMode?.stepEdit}
        validateTrigger='submit'
      >
        <Form.Item
          label='Nome da etapa'
          required
          name={'name'}
          rules={[{ required: true, message: 'Favor preencher o nome!' }]}
        >
          <Input placeholder='Digite um nome de fácil memorização' />
        </Form.Item>
        <Form.Item
          label='Link'
          name={'linkId'}
          tooltip={{
            title: 'O nome do link que foi criado.',
            icon: <InfoCircleOutlined />,
          }}
          required
          rules={[{ required: true, message: 'Favor selecionar um link!' }]}
        >
          <Select
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            options={links.map(d => ({
              value: d.id,
              label: d.urlName,
            }))}
          />
        </Form.Item>
        <Form.Item
          label='Ordem'
          required
          name={'order'}
          rules={[
            { required: true, message: 'Favor inserir a ordenação do step!' },
            { type: 'number', message: 'Deve ser um número' },
            {
              pattern: /^(10|[1-9])$/,
              message: 'O número deve estar entre 1 e 10',
            },
          ]}
        >
          <InputNumber type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
}
