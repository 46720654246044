import { useCallback, useMemo, useState } from 'react';

import { UpsellLinks } from 'src/features/upsellFunnel/screens/UpsellFunnel/components/upsellLinks/UpsellLinks';
import { UpsellProducts } from 'src/features/upsellFunnel/screens/UpsellFunnel/components/upsellProducts/UpsellProducts';

import { useQueryParams } from './useQueryParams';

type MenuOptionType = {
  key: string;
  label: string;
  Component: React.FC;
};

export const OPTIONS_TAB_FUNNEL: MenuOptionType[] = [
  { key: 'url', label: 'Gestão de Urls', Component: UpsellLinks },
  { key: 'funnel', label: 'Gestão de funis', Component: UpsellProducts },
];

export function useUpsellFunnel() {
  const query = useQueryParams();
  const [tabActive, setTabActive] = useState<MenuOptionType>(() => {
    const showParams = query.get('show');
    const tabDefault = OPTIONS_TAB_FUNNEL[0];

    if (!showParams) return tabDefault;

    const menuSelected = OPTIONS_TAB_FUNNEL.find(
      ({ key }) => key === showParams
    );

    return menuSelected || tabDefault;
  });

  const labelsMenu = useMemo(
    () => OPTIONS_TAB_FUNNEL.map(({ label }) => label),
    []
  );
  const indexSelectedMenu = useMemo(() => {
    const findTab = OPTIONS_TAB_FUNNEL.findIndex(
      ({ key }) => key === tabActive.key
    );
    return `${Math.max(findTab, 0)}`;
  }, [tabActive]);

  const onChangeMenu = useCallback(
    (index: string) => setTabActive(OPTIONS_TAB_FUNNEL[Number(index)]),
    []
  );

  return {
    tabActive,
    onChangeMenu,
    labelsMenu,
    indexSelectedMenu,
  };
}
