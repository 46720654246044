import { AddPhotoIcon } from '@assets/icons/addPhoto';
import { Assets } from '@models';
import { Image, message, UploadProps } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import React from 'react';
import { Link } from 'react-router-dom';

import { getBannerDimension } from 'src/models/BannerDetail.model';

import * as S from './ImageScreen.style';

interface ImageStepProps {
  smallImage: UploadFile[];
  midImage: UploadFile[];
  largeImage: UploadFile[];
  setImage: (type: 'small' | 'mid' | 'large', image: UploadFile[]) => void;
  device: Assets.DeviceTypeEnum;
  context: Assets.AssetContext;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const ImageStep: React.FC<ImageStepProps> = ({
  smallImage,
  midImage,
  largeImage,
  setImage,
  device,
  context,
}) => {
  const [modalPreviewImage, setModalPreviewImage] = React.useState({
    showModal: false,
    image: '',
  });

  const props1: UploadProps = {
    name: 'file',
    multiple: true,
    onRemove: () => {
      setImage('small', [] as UploadFile[]);
    },
    beforeUpload: async file => {
      const url = await getBase64(file);
      setImage('small', [
        {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url,
          thumbUrl: url,
          response: file,
        },
      ]);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const props2: UploadProps = {
    name: 'file',
    multiple: true,
    onRemove: () => {
      setImage('mid', [] as UploadFile[]);
    },
    beforeUpload: async file => {
      const url = await getBase64(file);
      setImage('mid', [
        {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url,
          thumbUrl: url,
          response: file,
        },
      ]);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const props3: UploadProps = {
    name: 'file',
    multiple: false,
    onRemove: () => {
      setImage('large', [] as UploadFile[]);
    },
    beforeUpload: async file => {
      const url = await getBase64(file);
      setImage('large', [
        {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url,
          thumbUrl: url,
          response: file,
        },
      ]);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',

          marginTop: '20px',
        }}
      >
        <div>
          <div style={{ marginBottom: '10px' }}>
            {getBannerDimension(context, device).small} pixels (mp4, jpg, jpeg,
            png ou gif)
          </div>
          <div>
            {smallImage.length === 0 ? (
              <S.DraggerContainer {...props1}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    <AddPhotoIcon />
                  </div>
                  <div>
                    Arraste seu asset aqui ou{' '}
                    <Link to={'#'}>faça upload de um arquivo</Link>
                  </div>
                </div>
              </S.DraggerContainer>
            ) : (
              <S.UploadContainer
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                listType='picture'
                fileList={smallImage}
                onRemove={() => {
                  setImage('small', []);
                }}
                onPreview={image => {
                  setModalPreviewImage({
                    image: image.url || '',
                    showModal: true,
                  });
                }}
              />
            )}
          </div>
        </div>

        <div>
          <div style={{ marginBottom: '10px', marginTop: '24px' }}>
            {getBannerDimension(context, device).mid} pixels (mp4, jpg, jpeg,
            png ou gif)
          </div>
          <div>
            {midImage.length === 0 ? (
              <S.DraggerContainer {...props2}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    <AddPhotoIcon />
                  </div>
                  <div>
                    Arraste seu asset aqui ou{' '}
                    <Link to={'#'}>faça upload de um arquivo</Link>
                  </div>
                </div>
              </S.DraggerContainer>
            ) : (
              <S.UploadContainer
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                listType='picture'
                fileList={midImage}
                onRemove={() => {
                  setImage('mid', []);
                }}
                onPreview={image => {
                  setModalPreviewImage({
                    image: image.url || '',
                    showModal: true,
                  });
                }}
              />
            )}
          </div>
        </div>

        <div>
          <div style={{ marginBottom: '10px', marginTop: '24px' }}>
            {getBannerDimension(context, device).large} pixels (mp4, jpg, jpeg,
            png ou gif)
          </div>
          <div>
            {largeImage.length === 0 ? (
              <S.DraggerContainer {...props3}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    <AddPhotoIcon />
                  </div>
                  <div>
                    Arraste seu asset aqui ou{' '}
                    <Link to={'#'}>faça upload de um arquivo</Link>
                  </div>
                </div>
              </S.DraggerContainer>
            ) : (
              <S.UploadContainer
                style={{ marginBottom: '10px' }}
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                listType='picture'
                fileList={largeImage}
                onRemove={() => {
                  setImage('large', []);
                }}
                onPreview={image => {
                  setModalPreviewImage({
                    image: image.url || '',
                    showModal: true,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>

      {modalPreviewImage?.showModal && (
        <Image
          width={840}
          src={modalPreviewImage?.image || ''}
          style={{ display: 'none' }}
          preview={{
            visible: modalPreviewImage.showModal,
            onVisibleChange: (value: boolean) =>
              setModalPreviewImage({
                showModal: value,
                image: '',
              }),
          }}
        />
      )}
    </>
  );
};
