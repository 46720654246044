import { Programs } from '@models';
import { ActiveTag, InctiveTag, TableList } from '@molecules';
import { CountResponse } from '@typings';
import { TablePaginationConfig } from 'antd';

const getAmountLabel = (count?: number) => {
  if (!count) return '';

  return `${count} Programa${count > 1 ? 's' : ''} cadastrado${
    count > 1 ? 's' : ''
  }`;
};

interface ProgramTabProps {
  value?: CountResponse<Programs.ProgramsResponse>;
  onSelectRow: (selectedListRow: Programs.ProgramsResponse[]) => void;
  onChangePagination?: (
    take: number,
    skip: number,
    pagination: TablePaginationConfig
  ) => void;
  rowsSelected?: Programs.ProgramsResponse[];
  paginationCurrentPage?: number;
}
export const ProgramsTableList = ({
  value,
  onSelectRow,
  onChangePagination,
  rowsSelected,
  paginationCurrentPage,
}: ProgramTabProps) => (
  <TableList<Programs.ProgramsResponse>
    data={value}
    countLabel={!!value?.count ? getAmountLabel(value.count) : ''}
    emptyText={'Nenhum programa vinculado.'}
    rowsSelected={rowsSelected}
    onChangeSelected={onSelectRow}
    onChangePagination={onChangePagination}
    paginationCurrentPage={paginationCurrentPage}
    columns={[
      {
        title: 'Nome do programa',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Treinador',
        dataIndex: 'instructors',
        key: 'instructors',
        render: (value: Programs.ProgramsResponse['instructors']) => {
          if (!value?.length) return 'Não informado';
          return `${value.map(instructor => instructor.name).join(', ')}`;
        },
      },
      {
        title: 'Nível',
        dataIndex: 'levels',
        key: 'levels',
        render: (value: Programs.ProgramsResponse['levels']) => {
          if (!value?.length) return 'Não informado';
          return `${value.map(level => level.name).join(', ')}`;
        },
      },
      {
        title: 'Objetivo',
        dataIndex: 'objectives',
        key: 'objectives',
        render: (value: Programs.ProgramsResponse['objectives']) => {
          if (!value?.length) return 'Não informado';
          return `${value.map(objective => objective.name).join(', ')}`;
        },
      },
      {
        title: 'Status',
        dataIndex: 'publicated',
        key: 'publicated',
        render: (status: boolean) => (status ? <ActiveTag /> : <InctiveTag />),
      },
    ]}
  />
);
