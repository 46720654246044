import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { removeSpecialChars } from 'src/app/helpers/StringHelper';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { CustomerDashboardIndicationsResponse } from '../types/clientTypes';

function buildUri(
  pageIndications: number | undefined,
  firstDateIndications: Date | undefined,
  lastDateIndications: Date | undefined,
  nameOrEmail: string | undefined,
  statusIndications?: string | undefined
) {
  let url = `?limit=10&page=${pageIndications}`;
  if (nameOrEmail) {
    url += `&subject=${removeSpecialChars(nameOrEmail)}`;
  }
  if (statusIndications) {
    url += `&status=${statusIndications}`;
  }
  if (firstDateIndications && lastDateIndications) {
    url += `&firstDate=${firstDateIndications.toJSON()}&lastDate=${lastDateIndications.toJSON()}`;
  }

  return url;
}

const fetchDashboardIndications = async (
  key: string,
  id?: string,
  pageIndications?: number,
  firstDateIndications?: Date,
  lastDateIndications?: Date,
  nameOrEmail?: string,
  statusIndications?: string
) => {
  const response = await clientApi.getCustomerDashboardIndicationsById(
    id,
    buildUri(
      pageIndications,
      firstDateIndications,
      lastDateIndications,
      nameOrEmail,
      statusIndications
    )
  );

  if ((response as CustomerDashboardIndicationsResponse).status === Success) {
    return (response as CustomerDashboardIndicationsResponse).data;
  } else {
    OpenNotification(
      true,
      'Erro ao carregar indicações',
      'Ocorreu um erro  ao carregar indicações, por favor tente novamente mais tarde.'
    );
  }
};

export default function useDashboardIndications(
  id?: string,
  pageIndications?: number,
  firstDateIndications?: Date,
  lastDateIndications?: Date,
  nameOrEmail?: string,
  statusIndications?: string
) {
  return useQuery(
    [
      'dashboardIndications',
      id,
      pageIndications,
      firstDateIndications,
      lastDateIndications,
      nameOrEmail,
      statusIndications,
    ],
    () =>
      fetchDashboardIndications(
        'dashboardIndications',
        id,
        pageIndications,
        firstDateIndications,
        lastDateIndications,
        nameOrEmail,
        statusIndications
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
}
