import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 8px;
  }
`;

export const TextEdit = styled(ReactQuill)`
  .ql-toolbar,
  .ql-container {
    border: 1px solid #434343;
  }
  .ql-toolbar {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .ql-container {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 120px;
  }
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.25);
    font-style: normal;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;
