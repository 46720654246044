import { Col, Row, Table } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { BackButton } from 'src/app/components/BackButton';

import { ColumTableFunelGestation } from '../../constants/FunelGestationConstants';
import { useTryToRequestAllCancellationFunnels } from '../../hooks/useTryToRequestAllCancellationFunnels';

export const FunelGestation: React.FC = () => {
  const { funnels, isLoading } = useTryToRequestAllCancellationFunnels();

  return (
    <Col
      span={28}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '34px',
      }}
    >
      <BackButton />
      <Row>
        <Col span={14}>
          <Title>Gestão de funil</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey='id'
            pagination={false}
            columns={ColumTableFunelGestation}
            bordered
            loading={isLoading}
            dataSource={funnels as any}
          />
        </Col>
      </Row>
    </Col>
  );
};

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #dbdbdb;
  line-height: 28px;
`;
