import { Col, Row, Table } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import {
  dateFormatted,
  getDifferenceInDaysFromTwoDates,
} from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import {
  PagarMeSubscription,
  PagarMeTransaction,
} from 'src/features/clients/types/clientTypes';

export type AssignTableProps = {
  subscriptionsPagarme?: PagarMeSubscription[];
  transactionsPagarme?: PagarMeTransaction[];
};

export const PagarMeTableComponent: FC<AssignTableProps> = ({
  subscriptionsPagarme,
  transactionsPagarme,
}) => {
  if (!transactionsPagarme?.length && !subscriptionsPagarme?.length) {
    return <></>;
  }

  return (
    <>
      <Row>
        <h1 style={{ fontSize: 20 }}>Pagar-Me</h1>
      </Row>
      {!!subscriptionsPagarme?.length && (
        <Col span={24}>
          <Table
            title={() => <TitleTable>Assinaturas</TitleTable>}
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
              },
              {
                title: 'Status',
                dataIndex: 'status',
              },
              {
                title: 'Início (UTC)',
                dataIndex: 'startDate',
                render: (startDate: string) => (
                  <div>{dateFormatted(startDate, false)}</div>
                ),
              },
              {
                title: 'Fim (UTC)',
                dataIndex: 'endDate',
                render: (endDate: string) => (
                  <div>{dateFormatted(endDate, false)}</div>
                ),
              },
              {
                title: 'Dias de assinatura',
                dataIndex: 'diasRestantes',
                render: (startDate: string, record: PagarMeSubscription) => (
                  <div>
                    {getDifferenceInDaysFromTwoDates(
                      record.startDate,
                      record.endDate
                    )}
                  </div>
                ),
              },
              {
                title: 'Tipo',
                dataIndex: ['plan', 'planType'],
              },
              {
                title: 'Programa',
                dataIndex: ['plan', 'name'],
              },
              {
                title: 'Recorrências',
                dataIndex: 'recurrencyType',
              },
              {
                title: 'Cartão',
                dataIndex: 'paymentMethod',
              },
            ]}
            dataSource={subscriptionsPagarme}
          />
        </Col>
      )}
      {!!transactionsPagarme?.length && (
        <>
          <Table
            title={() => <TitleTable>Transação</TitleTable>}
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
              },
              {
                title: 'Transação',
                dataIndex: 'transaction',
              },
              {
                title: 'Status',
                dataIndex: 'status',
              },
              {
                title: 'Tipo',
                dataIndex: 'type',
              },
              {
                title: 'Programa',
                dataIndex: 'prodName',
              },
              {
                title: 'Valor',
                dataIndex: 'amount',
                render: (amount: number) => <>{convertToCurrency(amount)}</>,
              },
              {
                title: 'Criada em',
                dataIndex: 'createdAt',
                render: (createdAt: string) => (
                  <div>{dateFormatted(createdAt, false)}</div>
                ),
              },
              {
                title: 'Data da compra',
                dataIndex: 'purchaseDate',
                render: (purchaseDate: string) => (
                  <div>{dateFormatted(purchaseDate, false)}</div>
                ),
              },
            ]}
            dataSource={transactionsPagarme}
          />
        </>
      )}
      {/* </Row> */}
    </>
  );
};

const TitleTable = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  margin-top: 20px;
  color: #dbdbdb;
`;
