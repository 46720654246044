import { useDebounce, useSegmentsRelation } from '@hooks';
import { Assets, Segments } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { AssetsTableList } from '@templates';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import * as S from './LinkAssets.style';

export const LinkAssets: React.FC = () => {
  const { state } = useLocation<{
    id: string;
  }>();

  const {
    assetsNotBySegment,
    getAssetsNotBySegment,
    requestLinkAssets,
    linkAssets,
    linkedSegments,
  } = useSegmentsRelation(state?.id);

  const history = useHistory();

  const [filter, setFilter] = useState<string>('');
  const [filterUsed, setFilterUsed] = useState(false);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(1);

  const debounceValue = useDebounce(filter, 900);

  useEffect(() => {
    if (!filter && !filterUsed) return;
    setPaginationCurrentPage(1);
    getAssetsNotBySegment(
      !filter
        ? {}
        : {
            filter: { name: { iLike: `%${debounceValue}%` } },
          }
    );
  }, [debounceValue]);

  useBreadcrumbs([
    {
      label: 'Vínculo de assets',
    },
  ]);

  useEffect(() => {
    if (!state?.id) {
      history.push('/screens/segments/manage');
    }

    getAssetsNotBySegment();
  }, []);

  const onSelectRow = (selectedListRow: Assets.IAsset[]) => {
    linkAssets(selectedListRow);
  };

  const handleSaveLink = async () => {
    await requestLinkAssets();
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.ASSETS,
    });
  };

  const handleCancelLink = () => {
    history.push('/screens/segments/detalhes', {
      id: state?.id,
      currentTab: Segments.LinkedSegmentsEnum.ASSETS,
    });
    OpenNotification(false, 'Nenhum asset foi vinculado.', '', 'warning');
  };

  return (
    <LayoutConfirmationFooter
      onSave={handleSaveLink}
      onCancel={handleCancelLink}
      ableToSave={!!linkedSegments?.assets.length}
    >
      <PageHeader
        backToPath={history.goBack}
        title='Vincular assets'
        subTitle='Vincule os assets que desejar ao segmento que está adicionando.'
      />

      <S.FilterConatiner>
        <Input.Search
          onChange={evt => {
            setFilterUsed(true);
            setFilter(evt.target.value);
          }}
          placeholder='Busque pelo asset desejado.'
          allowClear
        />
      </S.FilterConatiner>

      <AssetsTableList
        countLabel='assets cadastrados'
        value={assetsNotBySegment}
        onSelectRow={onSelectRow}
        paginationCurrentPage={paginationCurrentPage}
        rowsSelected={linkedSegments?.assets}
        onChangePagination={(take, skip, pagination) => {
          setPaginationCurrentPage(pagination.current ?? 1);
          getAssetsNotBySegment({
            filter: filter
              ? { name: { iLike: `%${debounceValue}%` } }
              : undefined,
            take,
            skip,
          });
        }}
      />
    </LayoutConfirmationFooter>
  );
};
