import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryLogin, tryLogout, tryRestoreAuth } from '../auth/auth.thunk';

export interface BreadcrumbItem {
  label: string;
  route?: string;
}

export interface LayoutState {
  layout: 'auth' | 'application';
  isSidebarCollapsed: boolean;
  breadcrumbs: BreadcrumbItem[];
  loadingApp: number;
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    layout: 'auth',
    isSidebarCollapsed: false,
    breadcrumbs: [],
    loadingApp: 0,
  } as LayoutState,
  reducers: {
    increaseLoading(state) {
      state.loadingApp += 1;
    },
    decreaseLoading(state) {
      if (state.loadingApp > 0) {
        state.loadingApp -= 1;
      }
    },
    updateLayout(state, action: PayloadAction<'auth' | 'application'>) {
      state.layout = action.payload;
    },
    updateBreadcrumbs(state, action: PayloadAction<BreadcrumbItem[]>) {
      state.breadcrumbs = action.payload;
    },
    toggleSidebar(state) {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
  },
  extraReducers(builder) {
    builder.addCase(tryLogin.fulfilled, state => {
      state.layout = 'application';
    });

    builder.addCase(tryRestoreAuth.fulfilled, state => {
      state.layout = 'application';
    });

    builder.addCase(tryLogout.fulfilled, state => {
      state.layout = 'auth';
    });
  },
});

export const {
  updateLayout,
  updateBreadcrumbs,
  toggleSidebar,
  increaseLoading,
  decreaseLoading,
} = layoutSlice.actions;

export const reducer = layoutSlice.reducer;
