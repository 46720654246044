import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { AdyenTransactionResponse } from '../types/clientTypes';

const fetchAdyenAssign = async (emailParamAdyenAssign?: string) => {
  if (emailParamAdyenAssign) {
    const response = await clientApi.getAdyenTableTransactions(
      emailParamAdyenAssign
    );

    if ((response as AdyenTransactionResponse).status === Success) {
      return (response as AdyenTransactionResponse).data;
    } else {
      OpenNotification(
        true,
        'Erro ao carregar as transações',
        'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
      );
    }
  }
};

export default function useTransactionsAdyen(email?: string) {
  return useQuery(['transactionsAdyen', email], () => fetchAdyenAssign(email), {
    refetchOnWindowFocus: false,
  });
}
