import {
  ReleaseFormData,
  ReleasePayload,
  releaseResponse,
} from '../types/releasePageTypings';
import { uploadS3 } from './uploadS3';

export const releasePayloadFactory = async (payload: ReleaseFormData) => {
  const factory: ReleasePayload = {
    cancellationFunnelId: Number(payload.cancellationFunnelId),
    name: payload.name,
    pageType: 'release',
    content: {
      slides: await Promise.all(
        payload.slides.map(async slide => {
          const carouselImages = await Promise.all(
            slide.slidesImages.map(async slideImage => ({
              imageWeb:
                typeof slideImage.web === 'string'
                  ? slideImage.web
                  : await uploadS3(slideImage.web),
              imageMobile:
                typeof slideImage.mobile === 'string'
                  ? slideImage.mobile
                  : await uploadS3(slideImage.mobile),
              imageLink: slideImage.link,
            }))
          );

          return {
            title: slide.title,
            subtitle: slide.subtitle,
            carouselImages: carouselImages,
          };
        })
      ),
      continueCancelationButtonTitle:
        payload.continueCancelationButtonTitle || null,
      keepSubscriptionButtonTitle: payload.keepSubscriptionButtonTitle || null,
    },
  };

  return factory;
};

export const releaseReponseFactory = (response: releaseResponse) => {
  const factory: ReleaseFormData = {
    cancellationFunnelId: String(response.cancellationFunnelId),
    name: response.name,
    slides: response.content.slides.map(slide => ({
      title: slide.title,
      subtitle: slide.subtitle,
      slidesImages: slide.carouselImages.map(slideImage => ({
        web: slideImage.imageWeb,
        mobile: slideImage.imageMobile,
        link: slideImage.imageLink,
      })),
    })),
    continueCancelationButtonTitle:
      response.content.continueCancelationButtonTitle,
    keepSubscriptionButtonTitle: response.content.keepSubscriptionButtonTitle,
  };

  return factory;
};
