// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { Modal } from '@models';
import { base } from '@service/base';
import { TakeSkipFilter } from '@typings';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

async function getOrderingModals({
  take,
  skip,
  filter,
}: TakeSkipFilter): Promise<Modal.ResponseOrdering> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/modal/ordering`,
    method: 'GET',
    params: {
      ...where,
    },
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}

export default getOrderingModals;
