export const BadgeSvg = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_4364_11127'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='48'
      height='48'
    >
      <rect width='48' height='48' fill='white' />
    </mask>
    <g mask='url(#mask0_4364_11127)'>
      <path
        d='M19.25 27.4L21.05 21.65L16.3 17.95H22.1L24 12L25.85 17.95H31.7L26.95 21.65L28.7 27.4L24 23.85L19.25 27.4ZM12.2 46V30.8C10.7 29.2333 9.625 27.5167 8.975 25.65C8.325 23.7833 8 21.9 8 20C8 15.4667 9.53333 11.6667 12.6 8.6C15.6667 5.53333 19.4667 4 24 4C28.5333 4 32.3333 5.53333 35.4 8.6C38.4667 11.6667 40 15.4667 40 20C40 21.9 39.675 23.7833 39.025 25.65C38.375 27.5167 37.3 29.2333 35.8 30.8V46L24 42.05L12.2 46ZM24 33C27.6333 33 30.7083 31.7417 33.225 29.225C35.7417 26.7083 37 23.6333 37 20C37 16.3667 35.7417 13.2917 33.225 10.775C30.7083 8.25833 27.6333 7 24 7C20.3667 7 17.2917 8.25833 14.775 10.775C12.2583 13.2917 11 16.3667 11 20C11 23.6333 12.2583 26.7083 14.775 29.225C17.2917 31.7417 20.3667 33 24 33ZM15.2 41.8L24 39.05L32.8 41.8V33.25C31.4667 34.2167 30.0333 34.9167 28.5 35.35C26.9667 35.7833 25.4667 36 24 36C22.5333 36 21.0333 35.7833 19.5 35.35C17.9667 34.9167 16.5333 34.2167 15.2 33.25V41.8Z'
        fill='white'
      />
    </g>
  </svg>
);
