import * as S from './Pagination.style';

const Pagination = () => {
  return (
    <>
      <S.ModalModelSpace>
        <S.ModalModelTextboxSkeleton />

        <S.ModalModelTitleSkeleton />
        <S.ModalModelDescriptionSkeleton />
        <S.ModalModelDescriptionSkeleton />

        <S.ModalModelFooterContainerSkeleton>
          <S.ModalModelButtonSkeleton />

          <S.ModalModelFooterCircleSkeletonContainer>
            <S.ModalModelFooterCircleSkeleton />
            <S.ModalModelFooterCircleSkeleton />
            <S.ModalModelFooterCircleSkeleton />
            <S.ModalModelFooterCircleSkeleton />
          </S.ModalModelFooterCircleSkeletonContainer>

          <S.ModalModelButtonSkeleton />
        </S.ModalModelFooterContainerSkeleton>
      </S.ModalModelSpace>
    </>
  );
};
export default Pagination;
