import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const EditButton = ({ itemId }: { itemId: number }) => {
  const history = useHistory();
  return (
    <Button
      onClick={() => history.push(`/unsubscribe/funnel/${itemId}`)}
      style={{ border: 'none' }}
    >
      <EditOutlined />
    </Button>
  );
};

export const ColumTableFunelGestation = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nome do funil',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tipo de funil',
    dataIndex: 'funnelType',
    key: 'funnelType',
    width: '420px',
  },
  {
    title: 'Ações',
    key: 'action',
    width: '100px',
    render: (item: any) => <EditButton itemId={item.id} />,
  },
];
