import { base } from '@service/base';

import { ResponseApiDto } from 'src/core/dto/response-api.dto';

import { Credentials, LoginResponse } from './auth.interface';

export async function login(credentials: Credentials): Promise<LoginResponse> {
  const endpoint = `/auth/login`;
  const { data } = await base.post<LoginResponse>(endpoint, {
    email: credentials.email,
    password: credentials.password,
  });
  return data;
}

export async function enviarEmail(email: string): Promise<void> {
  const { data } = await base.post<ResponseApiDto>(
    '/user/recovery/access/request',
    {
      email,
    }
  );

  if (!data.result) {
    throw Error(data.message);
  }
}

export async function validarEmailCodigo(
  email: string,
  code: number
): Promise<void> {
  const { data } = await base.post<ResponseApiDto>(
    '/user/recovery/access/code/validate',
    {
      email,
      code,
    }
  );

  if (!data.result) {
    throw Error(data.message);
  }
}

export async function alterarSenha(
  email: string,
  code: number,
  newPassword: string
): Promise<void> {
  const { data } = await base.put<ResponseApiDto>(
    '/user/recovery/access/change/password',
    {
      email,
      code,
      newPassword,
    }
  );

  if (!data.result) {
    throw Error(data.message);
  }
}
