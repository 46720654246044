import { CurrentModal } from '../typings/lgpdTipyngs';

export function defineModal(currentModal: CurrentModal) {
  switch (currentModal) {
    case 'stepOne':
      return 0;
    case 'stepTwo':
      return 1;
    case 'stepThree':
      return 2;
  }
}
