import { message } from 'antd';

type ToastMessageProps = {
  content: string;
  type?: 'info' | 'success' | 'error' | 'warning' | 'loading';
};

export const ToastMessage = ({ content, type = 'info' }: ToastMessageProps) => {
  message[type]({
    content,
    duration: 3,
    style: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      margin: 0,
      zIndex: 1000,
    },
  });
};
