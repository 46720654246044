import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import moment from 'moment';
import { FC, useMemo } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { customerAdyenReportApi } from 'src/features/clients/api/customerAdyenReportApi';
import {
  AdyenRefusedTransaction,
  AdyenTransaction,
  RecoveryEngine,
} from 'src/features/clients/types/clientTypes';

export type TableProps = {
  transactionsCancelation?: AdyenRefusedTransaction[];
  filter: string;
};

export const CancelationTabsComponent: FC<TableProps> = ({
  transactionsCancelation,
  filter,
}) => {
  const cancelAutoRescue = async (subscriptionId?: number) => {
    if (subscriptionId) {
      const response = await customerAdyenReportApi.adyenCancelAutoRescue(
        subscriptionId
      );
      if (response?.error) {
        OpenNotification(true, `${response.error}`);
        return;
      }
      OpenNotification(false, 'Auto rescue cancelado com sucesso!', '');
      return;
    }
  };

  const filteredTransactionsCancelation = useMemo(() => {
    return transactionsCancelation?.filter(transaction => {
      return (
        String(transaction.id).includes(filter) ||
        transaction.subscriptionType.includes(filter) ||
        String(transaction.transactionStatusId).includes(filter) ||
        transaction.name.includes(filter)
      );
    });
  }, [transactionsCancelation, filter]);

  const DropdownComponent = (item: AdyenTransaction) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                data-testid='cancel-auto-rescue'
                onClick={() => cancelAutoRescue(item.subscriptionId)}
                key='cancel-auto-rescue'
              >
                Cancelar Auto rescue
              </Menu.Item>
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Table
        scroll={{ x: 1450 }}
        style={{ width: '100vw' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => Number(a.id) - Number(b.id),
          },
          {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: 'Transação',
            dataIndex: 'subscriptionType',
            sorter: (a, b) =>
              a.subscriptionType.localeCompare(b.subscriptionType),
          },
          {
            title: 'Status',
            dataIndex: 'transactionStatusId',
            sorter: (a, b) =>
              Number(a.transactionStatusId) - Number(b.transactionStatusId),
          },
          {
            title: 'Metodo de Pagamento',
            dataIndex: 'paymentMethod',
            sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: (fullPrice: number) => <>{convertToCurrency(fullPrice)}</>,
            sorter: (a, b) => Number(a.amount) - Number(b.amount),
          },
          {
            title: 'Motor de Recuperação',
            dataIndex: 'recoveryEngine',
            render: (value: RecoveryEngine) =>
              value?.status === 'running' ? 'Sim' : 'Não',
            sorter: (a, b) =>
              (a.recoveryEngine?.status || '').localeCompare(
                b.recoveryEngine?.status || ''
              ),
          },

          {
            title: 'Recuperar em',
            dataIndex: 'recoveryEngine',
            render: (value: RecoveryEngine) =>
              value?.nextScheduledAttemptAt ? (
                <div>{dateFormatted(value.nextScheduledAttemptAt, true)}</div>
              ) : (
                ''
              ),
            sorter: (a, b) => {
              const defaultDate = new Date().toISOString();
              return (
                moment(
                  a.recoveryEngine?.nextScheduledAttemptAt || defaultDate
                ).unix() -
                moment(
                  b.recoveryEngine?.nextScheduledAttemptAt || defaultDate
                ).unix()
              );
            },
          },
          {
            title: 'Data',
            dataIndex: 'createdAt',
            render: (paymentDate: string) => (
              <div>{dateFormatted(paymentDate, true)}</div>
            ),
            defaultSortOrder: 'descend',
            sorter: (a, b) =>
              moment(a.createdAt).unix() - moment(b.createdAt).unix(),
          },
          {
            title: 'Ações',
            key: 'action',
            render: (item: AdyenTransaction) => DropdownComponent(item),
          },
        ]}
        dataSource={filteredTransactionsCancelation}
      />
    </>
  );
};
