import { slices } from '@core/redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { segments } from '@service/api';

import OpenNotification from 'src/app/components/antd/OpenNotification';

interface SegmentsFilter {
  name?: { iLike: string };
  activated?: boolean;
}

export const getBannerSegments = createAsyncThunk(
  'assets/getBannerSegments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getAllBannerSegments();

      return {
        response: response || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar detalhes dos assets.');

      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);

export const getAllSegments = createAsyncThunk(
  'assets/getAllSegments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await segments.getAllSegments({ take: 999 });

      return {
        response: response || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar detalhes dos segmentos.');
      return rejectWithValue(error);
    }
  }
);

export const getSegmentsWithCounts = createAsyncThunk(
  'assets/getSegmentsWithCounts',
  async (filter: SegmentsFilter, { rejectWithValue, dispatch }) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const response = await segments.getSegmentsWithCounts({ filter });

      return {
        response: response || [],
      };
    } catch (error) {
      OpenNotification(true, 'Erro ao requisitar detalhes dos segmentos.');
      return rejectWithValue(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  }
);
