import { guestApi } from 'src/app/guestApi';

import {
  CustomerSubscriptions,
  CustomerType,
  ProfileType,
} from '../types/customer';

export interface WithPagination<T> {
  count: number;
  rows: T;
}

export interface CustomerDataResponse {
  urlViewQueimaDiaria: string;
  customer: CustomerType;
  profile?: ProfileType;
}

async function fetchCustomerData(
  searchParam: string
): Promise<CustomerDataResponse> {
  const endpoint = `/customer/${searchParam}`;

  const { data } = await guestApi.get<CustomerDataResponse>(endpoint);
  return data;
}

async function fetchCustomerSubscriptions(
  customerId: string,
  page: number,
  limit: number
): Promise<WithPagination<CustomerSubscriptions[]>> {
  const endpoint = `/customer/${customerId}/subscriptions?page=${page}&limit=${limit}`;

  const { data } = await guestApi.get<WithPagination<CustomerSubscriptions[]>>(
    endpoint
  );
  return data;
}

export interface CustomerUpdatePayload {
  id: string;
  email: string;
  name: string;
  phone: string;
  document: string;
}

async function updateCustomer(params: CustomerUpdatePayload) {
  const { id, ...payload } = params;
  const endpoint = `/customer/${id}`;
  return await guestApi.put(endpoint, payload);
}

export const customerApi = {
  fetchCustomerData,
  fetchCustomerSubscriptions,
  updateCustomer,
};
