import { slices } from '@core/redux';
import { Assets, Customer, Programs, Segments } from '@models';
import { assets, programs, users } from '@service/api';
import { CountResponse, TakeSkipFilter } from '@typings';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';

const initialLinkedSegments: Segments.LinkedSegmentsProps = {
  assets: [],
  customers: [],
  programs: [],
};
export const useSegmentsRelation = (segmentId?: string) => {
  const dispatch = useDispatch();

  const [assetsBySegment, setAssetsBySegments] = useState<
    CountResponse<Assets.IAsset> | undefined
  >();
  const [assetsNotBySegment, setAssetsNotBySegments] = useState<
    CountResponse<Assets.IAsset> | undefined
  >();
  const [assetsBySegmentTakeSkipFilter, setAssetsBySegmentTakeSkipFilter] =
    useState<TakeSkipFilter>();

  const [programsBySegment, setProgramsBySegments] =
    useState<CountResponse<Programs.ProgramsResponse>>();
  const [programsNotBySegment, setProgramsNotBySegments] =
    useState<CountResponse<Programs.ProgramsResponse>>();
  const [programsBySegmentTakeSkipFilter, setProgramsBySegmentTakeSkipFilter] =
    useState<TakeSkipFilter>();

  const [userBySegment, setUserBySegments] =
    useState<CountResponse<Customer.CustomerResponse>>();
  const [userNotBySegment, setUserNotBySegments] =
    useState<CountResponse<Customer.CustomerResponse>>();
  const [userBySegmentTakeSkipFilter, setUserBySegmentTakeSkipFilter] =
    useState<TakeSkipFilter>();

  const [linkedSegments, setLinkedSegments] =
    useState<Segments.LinkedSegmentsProps>(initialLinkedSegments);

  const getAssetsBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          ...params?.filter,
        },
      };

      const response = await assets.getAssetsBySegments(filterParams, {
        segmentIdsIn: [+segmentId],
      });
      setAssetsBySegments(response);
    } catch (error: any) {
      if (error?.status === 500) {
        setAssetsBySegments({
          count: 0,
          rows: [],
        });
      }
      setAssetsBySegments(undefined);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getAssetsNotBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          ...params?.filter,
        },
      };

      const response = await assets.getAssetsBySegments(filterParams, {
        segmentIdsNotIn: [+segmentId],
      });
      setAssetsNotBySegments(response);
    } catch (error) {
      setAssetsNotBySegments(undefined);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getUsersBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          or: [],
          segmentIds: { in: [+segmentId] },
        } as { segmentIds: any; or: any },
      };

      if (!params?.filter || !Object.keys(params?.filter).length) {
        delete filterParams.filter.or;
      } else {
        filterParams.filter.or = Object.entries(params.filter).map(
          ([key, value]) => ({ [key]: value })
        );
      }

      const response = await users.getUsersBySegments(filterParams);

      setUserBySegments(response);
    } catch (error) {
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getUsersNotBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          or: [],
          segmentIds: { notIn: [+segmentId] },
        } as { segmentIds: any; or: any },
      };

      if (!params?.filter || !Object.keys(params?.filter).length) {
        delete filterParams.filter.or;
      } else {
        filterParams.filter.or = Object.entries(params.filter).map(
          ([key, value]) => ({ [key]: value })
        );
      }

      const response = await users.getUsersBySegments(filterParams);

      setUserNotBySegments(response);
    } catch (error) {
      setUserNotBySegments(undefined);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getProgramsBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          ...params?.filter,
          segmentIds: { in: [+segmentId] },
        },
      };
      const response = await programs.getProgramsBySegments(filterParams);

      setProgramsBySegments(response);
    } catch (error) {
      setProgramsBySegments(undefined);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const getProgramsNotBySegment = async (params?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (!segmentId) return;

      const filterParams = {
        take: params?.take,
        skip: params?.skip,
        filter: {
          ...params?.filter,
          segmentIds: { notIn: [+segmentId] },
        },
      };
      const response = await programs.getProgramsBySegments(filterParams);

      setProgramsNotBySegments(response);
    } catch (error) {
      setProgramsNotBySegments(undefined);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestUnlinkAssets = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const unlinkList = linkedSegments.assets.map(item =>
        assets.unlinkAssetToSegment(item.id, +segmentId)
      );

      await Promise.all(unlinkList);
      await getAssetsBySegment();
      setLinkedSegments(props => ({
        ...props,
        assets: [],
      }));

      OpenNotification(false, 'Assets desvinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestUnlinkCustomers = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const unlinkList = linkedSegments.customers.map(item =>
        users.unlinkCustomerToSegment(item.id, +segmentId)
      );

      await Promise.all(unlinkList);
      await getUsersBySegment();

      setLinkedSegments(props => ({
        ...props,
        customers: [],
      }));

      OpenNotification(false, 'Usuários desvinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestUnlinkPrograms = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const unlinkList = linkedSegments.programs.map(item =>
        programs.unlinkProgramToSegment(item.id, +segmentId)
      );

      await Promise.all(unlinkList);
      await getProgramsBySegment();

      setLinkedSegments(props => ({
        ...props,
        programs: [],
      }));
      OpenNotification(false, 'Programas desvinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestLinkAssets = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const linkList = linkedSegments.assets.map(item =>
        assets.linkAssetToSegment(item.id, +segmentId)
      );

      await Promise.all(linkList);
      await getAssetsNotBySegment();

      setLinkedSegments(props => ({
        ...props,
        assets: [],
      }));

      OpenNotification(false, 'Assets vinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestLinkCustomers = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const linkList = linkedSegments.customers.map(item =>
        users.linkCustomerToSegment(item.id, +segmentId)
      );

      await Promise.all(linkList);
      await getUsersNotBySegment();

      setLinkedSegments(props => ({
        ...props,
        programs: [],
      }));

      OpenNotification(false, 'Usuários vinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const requestLinkPrograms = async () => {
    try {
      dispatch(slices.layout.increaseLoading());

      if (!segmentId) return;

      const linkList = linkedSegments.programs.map(item =>
        programs.linkProgramToSegment(item.id, +segmentId)
      );

      await Promise.all(linkList);
      await getProgramsNotBySegment();

      setLinkedSegments(props => ({
        ...props,
        programs: [],
      }));
      OpenNotification(false, 'Programas vinculados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const linkAssets = (assets: Assets.IAsset[]) => {
    setLinkedSegments(props => ({
      ...props,
      assets,
    }));
  };

  const linkCustomers = (customers: Customer.CustomerResponse[]) => {
    setLinkedSegments(props => ({
      ...props,
      customers,
    }));
  };

  const linkPrograms = (programs: Programs.ProgramsResponse[]) => {
    setLinkedSegments(props => ({
      ...props,
      programs,
    }));
  };

  const changeAssetsBySegmentPagination = ({ take, skip }: TakeSkipFilter) => {
    setAssetsBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      take,
      skip,
    }));
    getAssetsBySegment({
      take,
      skip,
      filter: assetsBySegmentTakeSkipFilter?.filter,
    });
  };

  const changeAssetsBySegmentFilter = (filter: TakeSkipFilter['filter']) => {
    setAssetsBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      filter,
    }));
    getAssetsBySegment({
      take: assetsBySegmentTakeSkipFilter?.take,
      skip: assetsBySegmentTakeSkipFilter?.skip,
      filter,
    });
  };

  const changeUserBySegmentPagination = ({ take, skip }: TakeSkipFilter) => {
    setUserBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      take,
      skip,
    }));
    getUsersBySegment({
      take,
      skip,
      filter: userBySegmentTakeSkipFilter?.filter,
    });
  };

  const changeUserBySegmentFilter = (filter: TakeSkipFilter['filter']) => {
    setUserBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      filter,
    }));
    getUsersBySegment({
      take: 10,
      skip: 0,
      filter: { ...filter },
    });
  };

  const changeProgramsBySegmentPagination = ({
    take,
    skip,
  }: TakeSkipFilter) => {
    setProgramsBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      take,
      skip,
    }));
    getProgramsBySegment({
      take,
      skip,
      filter: programsBySegmentTakeSkipFilter?.filter,
    });
  };

  const changeProgramsBySegmentFilter = (filter: TakeSkipFilter['filter']) => {
    setProgramsBySegmentTakeSkipFilter(prevState => ({
      ...prevState,
      filter,
    }));
    getProgramsBySegment({
      take: programsBySegmentTakeSkipFilter?.take,
      skip: programsBySegmentTakeSkipFilter?.skip,
      filter: { ...filter },
    });
  };

  return {
    getAssetsBySegment,
    getAssetsNotBySegment,
    getUsersBySegment,
    getUsersNotBySegment,
    getProgramsBySegment,
    getProgramsNotBySegment,

    assetsBySegment,
    assetsNotBySegment,

    programsBySegment,
    programsNotBySegment,

    userBySegment,
    userNotBySegment,

    linkedSegments,
    linkAssets,
    linkCustomers,
    linkPrograms,

    requestUnlinkAssets,
    requestUnlinkCustomers,
    requestUnlinkPrograms,

    requestLinkAssets,
    requestLinkPrograms,
    requestLinkCustomers,

    changeAssetsBySegmentPagination,
    changeAssetsBySegmentFilter,

    changeUserBySegmentPagination,
    changeUserBySegmentFilter,

    changeProgramsBySegmentPagination,
    changeProgramsBySegmentFilter,
  };
};
