import { slices } from '@core/redux';
import { fromUnixTime, isAfter, isBefore } from 'date-fns';
import jwtDecode from 'jwt-decode';

export function decodeAndValidateAuthToken(
  token: string
): slices.auth.TokenPayload {
  const payload = jwtDecode<slices.auth.TokenPayload>(token);
  const isValid = validateAuthToken(payload.exp, payload.iat);

  if (!isValid) {
    throw new Error('Expired or invalid token');
  }

  return payload;
}

export function validateAuthToken(exp: number, iat: number): boolean {
  const expiresAt = fromUnixTime(exp);
  const issuedAt = fromUnixTime(iat);

  const now = new Date();

  return isAfter(now, issuedAt) && isBefore(now, expiresAt);
}
