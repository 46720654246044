import { Segments } from '@models';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import * as segmentsThunk from './segments.thunk';

export const getAllSegmentsBuilder = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder.addCase(segmentsThunk.getAllSegments.pending, state => {
    state.status = 'PENDING';
    state.errorDetails = undefined;
  });

  builder.addCase(
    segmentsThunk.getAllSegments.fulfilled,
    (state, { payload }: any) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }

      const rep: Segments.SegmentsDto[] = payload.response.rows;
      const dictionary: Record<string, string> = {};
      rep.forEach(value => {
        dictionary[value.id || ''] = value.name;
      });

      state.status = 'NORMAL';
      state.segmentsDictionary = dictionary;
      state.segments = rep;
    }
  );

  builder.addCase(
    segmentsThunk.getAllSegments.rejected,
    (state, { payload }: any) => {
      console.log('ERROR THUNK', payload);
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        return;
      }
      state.status = 'ERROR';
    }
  );
};
