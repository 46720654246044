import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex: 1 1 auto;
`;
export const SearchContainer = styled.div`
  width: 360px;
`;
export const UnlinkButtonContainer = styled.div``;
export const LinkContainer = styled.div``;
