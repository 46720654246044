import { enviroments } from '@infrastructure/enviroments';
import { Segments } from '@models';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function postNewSegment(
  segment: Segments.ShortSegmentDto
): Promise<any> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments`,
    method: 'POST',
    data: {
      ...segment,
    },
  });

  return data;
}
