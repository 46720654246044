import { Container, Input } from './styles';

type TInputFieldProps = {
  onChange: (value: string) => void;
  value: string;
  label: string;
  placeholder: string;
};

export function InputField({
  onChange,
  value,
  label,
  placeholder,
}: TInputFieldProps) {
  return (
    <Container>
      <label>{label}</label>
      <Input
        onChange={({ target }) => onChange(target.value)}
        value={value}
        placeholder={placeholder}
        showCount
        maxLength={65}
      />
    </Container>
  );
}
