import { base } from '@service/base';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import {
  CreateFreezingPayload,
  FreezingHistory,
} from '../interfaces/frezzingInterfaces';

export async function getFreezingHistory(): Promise<FreezingHistory[]> {
  try {
    const endpoint = `/freezing`;
    const { data: response } = await base.get(endpoint);
    return response.data;
  } catch (error) {
    throw new Error(
      'Não foi possível carregar os dados solicitados! Por favor acione nosso suporte.'
    );
  }
}

export async function createFreezingSchedule(
  data: CreateFreezingPayload
): Promise<void> {
  try {
    const endpoint = `/freezing`;
    await base.post(endpoint, data);
  } catch (error) {
    if (isSerializedApiError(error) && error.status === 400) {
      throw new Error(
        `Não foi possível criar o freezing solicitado! ${error.message}`
      );
    }
    throw new Error(
      'Não foi possível criar o freezing solicitado! Por favor acione nosso suporte.'
    );
  }
}

export async function deleteFreezingHistory(id: number): Promise<void> {
  try {
    const endpoint = `/freezing/${id}`;
    await base.delete(endpoint);
  } catch (error) {
    if (isSerializedApiError(error) && error.status === 400) {
      throw new Error(
        `Não foi possível criar o freezing solicitado! ${error.message}`
      );
    }
    throw new Error(
      'Não foi possível excluir o histórico de freezing solicitado! Por favor acione nosso suporte.'
    );
  }
}
