export const DevicesSvg = () => (
  <svg
    width='48'
    height='49'
    viewBox='0 0 48 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 40.5V36H8.3V11.5C8.3 10.675 8.59375 9.96875 9.18125 9.38125C9.76875 8.79375 10.475 8.5 11.3 8.5H42.3V11.5H11.3V36H23V40.5H4ZM27.95 40.5C27.3975 40.5 26.9344 40.275 26.5607 39.825C26.1869 39.375 26 38.8667 26 38.3V16.45C26 15.8975 26.1869 15.4344 26.5607 15.0607C26.9344 14.6869 27.3975 14.5 27.95 14.5H41.5375C42.1792 14.5 42.75 14.675 43.25 15.025C43.75 15.375 44 15.85 44 16.45V38.3137C44 38.9331 43.7583 39.4524 43.275 39.8714C42.7917 40.2905 42.2167 40.5 41.55 40.5H27.95ZM29 36H41V17.5H29V36Z'
      fill='white'
    />
  </svg>
);
