import {
  CheckCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Menu, message, Modal, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { GeneralError } from 'src/app/components/Error';
import { PageHeader } from 'src/app/components/PageHeader';

import { ICompetition } from '../../../dto/competition.interface';
import { ManageCompetitionsResponseDto } from '../../../dto/manage-competitions.response.dto';
import { StyledTable } from '../../../styles';
import { gameficationCompetitionActivities } from './client.api';
import ActivityModal from './Modal';

const GameficationCompetitionFormStep2: FC = () => {
  const URL_PARAMS: ICompetition = useParams();
  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
  // eslint-disable-next-line
  const [activity, setActivity] = useState<any>();
  const modalState = useState<boolean>(false);
  const [showModal, setModal] = modalState;

  const _callApi = async () => {
    const client = gameficationCompetitionActivities.list;
    const response = await client(Number(URL_PARAMS?.id));
    return response?.data;
  };

  const OPTS = { refetchOnWindowFocus: false };
  const QUERY = useQuery('gamefication_competition_activities', _callApi, OPTS);

  useEffect(() => {
    setData(QUERY.data);
  }, [QUERY?.data]);

  const RENDER_CTA = () =>
    selectedRowKeys?.length ? (
      <Button
        data-testid='delete'
        size='large'
        icon={<DeleteOutlined />}
        onClick={() => {
          Modal.confirm({
            title: `Excluir múltiplos itens?`,
            icon: <ExclamationCircleOutlined />,
            content: 'Cuidado! Esta ação é irreversível.',
            onOk() {
              removeItems(selectedRowKeys);
            },
          });
        }}
        type='link'
        danger
      >
        Remover
      </Button>
    ) : (
      <Button
        data-testid='add'
        size='large'
        icon={<CheckCircleOutlined />}
        onClick={() => {
          setActivity(null);
          setModal(true);
        }}
        type='link'
      >
        Adicionar
      </Button>
    );

  // eslint-disable-next-line
  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const removeItems = async (ids: number[]) => {
    setSelectedRowKeys([]);

    try {
      await gameficationCompetitionActivities.remove(ids);
      await QUERY.refetch();
      message.success('Atividade removida!');
    } catch (err) {
      message.error('Erro ao realizar ação. Tente novamente.');
    }
  };

  const columns = [
    { title: 'Atividade', dataIndex: 'name', width: 600 },
    { title: 'Itens', width: 500, render: () => 'Mock: P1: A1, A2 / P2: A3' },
    { title: 'Pontos', dataIndex: 'points', width: 50 },
    { title: 'Limite de ocorrências', dataIndex: 'limit', width: 160 },
    {
      title: 'Vigência',
      width: 300,
      // eslint-disable-next-line
      render: (j: any) => {
        const start = new Date(j?.startDate).toLocaleDateString('pt-br');
        const end = j?.endDate
          ? new Date(j?.endDate).toLocaleDateString('pt-br')
          : null;
        return end ? `${start} à ${end}` : start;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      width: 20,
      render: (ac: ManageCompetitionsResponseDto) => (
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          overlay={() => (
            <Menu>
              <Menu.Item
                onClick={() => {
                  setActivity(ac);
                  setModal(true);
                }}
              >
                Visualizar / Editar
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  Modal.confirm({
                    title: `Excluir "${ac.name}"?`,
                    icon: <ExclamationCircleOutlined />,
                    content: 'Cuidado! Esta ação é irreversível.',
                    onOk() {
                      removeItems([Number(ac.id)]);
                    },
                  });
                }}
              >
                Remover
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type='text'>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const PROPS = {
    dataSource: data,
    loading: QUERY?.isLoading,
    rowSelection,
    columns,
    bordered: true,
    rowKey: 'id',
  };

  const _filterSearch = (query: string) => {
    query = query.toLowerCase().split('').join('.*');
    const DATA = QUERY.data;
    const re = RegExp(`.*${query}.*`);
    // eslint-disable-next-line
    const matches = DATA.filter((v: any) => v.name.toLowerCase().match(re));
    setData(query ? matches : DATA);
  };

  return (
    <>
      <Row gutter={20} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <PageHeader title='Atividades' />
        </Col>

        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          {RENDER_CTA()}
        </Col>
      </Row>

      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          <Input.Search
            disabled={QUERY?.isLoading || QUERY?.isError}
            allowClear
            placeholder='Filtrar por...'
            style={{ width: '100%' }}
            onSearch={_filterSearch}
            size='large'
          />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={1}>
          {!QUERY?.isError ? (
            <StyledTable
              {...PROPS}
              pagination={{ position: ['bottomCenter'] }}
            />
          ) : (
            <GeneralError action={QUERY?.refetch} />
          )}
        </Col>
      </Row>

      {showModal && (
        <ActivityModal
          modalState={modalState}
          activity={activity}
          refetch={QUERY.refetch}
        />
      )}
    </>
  );
};

export default GameficationCompetitionFormStep2;
