import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FC, ReactElement } from 'react';

import { dateFormatted } from '../../../../../src/app/helpers/DateHelper';
import { IDateRange } from '../dto/date-range.interface';
import { Description, Title } from '../styles';

type IScheduledReportsHeader = {
  total: number;
  isFetching: boolean;
  refetch: () => void;
  dateRange: undefined | IDateRange;
};

export const ScheduledReportsHeader: FC<IScheduledReportsHeader> = args => {
  const { total, isFetching, refetch, dateRange } = args;

  const isCTADisabled = () => {
    const hasDateRange = Boolean(dateRange?.from && dateRange?.to);
    return !hasDateRange || isFetching;
  };

  const displayReloadOrReloading = (): ReactElement => (
    <Button
      type='link'
      data-testid='cta-refetch'
      onClick={() => refetch()}
      icon={isFetching ? <LoadingOutlined /> : <ReloadOutlined />}
      disabled={isCTADisabled()}
    />
  );

  const displayCountOrDescription = (): ReactElement => {
    if (isCTADisabled()) {
      return (
        <Description data-testid='description-empty'>
          Selecione um intervalo de datas para iniciar
        </Description>
      );
    }

    const d1 = dateFormatted(String(dateRange?.from), false);
    const d2 = dateFormatted(String(dateRange?.to), false);

    return (
      <Description data-testid='description-filled'>
        <span data-testid='description-filled-amount'>{total}</span>{' '}
        agendamento(s) entre {d1} e {d2}
      </Description>
    );
  };

  return (
    <Row>
      <Col flex={1}>
        <Row>
          <Title data-testid='title'>Agendamento de Cancelamentos</Title>
          {displayReloadOrReloading()}
        </Row>

        {displayCountOrDescription()}
      </Col>
    </Row>
  );
};
