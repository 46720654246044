import { IBadgeRequestDto } from '@core/gamefication/dto/badge-group/badge-request.dto';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

interface ICreateBadgeParams {
  badgeGroupId: number;
  badge: IBadgeRequestDto;
}

export const createBadge = createAsyncThunk(
  'gamification/badge/createBadge',
  async (params: ICreateBadgeParams, { rejectWithValue }) => {
    try {
      if (!params.badgeGroupId) {
        OpenNotification(true, 'Badge', 'Nenhum grupo de badge informado.');
        return;
      }

      await badgesGroupsApi.createBadge({
        badgeGroupId: params.badgeGroupId,
        description: params.badge.description,
        imagePath: params.badge.imagePath,
        name: params.badge.name,
        order: params.badge.order,
      });

      OpenNotification(false, 'Badge cadastrada com sucesso!');
    } catch (error) {
      OpenNotification(true, 'Badge', 'Erro ao cadastrar a badge.');
      return rejectWithValue(error);
    }
  }
);

export const createBadgeBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(createBadge.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(createBadge.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
  });

  builder.addCase(createBadge.rejected, state => {
    state.status = 'ERROR';
  });
};
