import { PageHeader } from '@organisms';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import { TabComponent } from 'src/app/components/antd/TabComponent';
import { ContentComponent } from 'src/app/components/ContentComponent';

import { CustomerSummaryTab } from '../components/CustomerSummary';
import { EmptyState } from '../components/EmptyState';
import { InputSearch, InputSearchData } from '../components/SearchInput';
import { useCustomerDetails } from '../hooks/useCustomerDetails';

export const CustomerDetailsScreen: React.FC = () => {
  const {
    tabActive,
    setTabActive,
    tabList,
    customerData,
    customerExists,
    handleSearchCustomer,
    isLoading,
    isError,
  } = useCustomerDetails();

  return (
    <ContentComponent isError={isError} isLoading={isLoading}>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={!customerExists ? 'Cliente' : customerData?.customer.name || ''}
        subTitle={'Cadastre, edite e configure dados do cliente'}
        extra={
          <InputSearch
            onSearch={(inputData: InputSearchData) =>
              handleSearchCustomer(inputData.search)
            }
          />
        }
        hasDivider={false}
      />
      {customerData && customerExists && (
        <>
          <CustomTab
            tabList={tabList}
            onChangeTab={setTabActive}
            defaultActiveKey={tabActive}
            activeKey={tabActive}
          />
          {tabActive === '0' && (
            <CustomerSummaryTab
              customerData={customerData}
              setTabActive={setTabActive}
            />
          )}
        </>
      )}

      {!customerExists && (
        <Row
          justify='center'
          align='middle'
          style={{ width: '100%', marginTop: '64px' }}
        >
          <Col>
            <EmptyState
              title='Cliente não encontrado.'
              subtitle='Faça a pesquisa novamente utilizando id, ou email do cliente.'
            />
          </Col>
        </Row>
      )}
    </ContentComponent>
  );
};

const CustomTab = styled(TabComponent)`
  margin-top: 32px;
  margin-bottom: 8px;
`;
