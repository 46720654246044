import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

interface PutCustomersBySegmentRelationParams {
  values: Array<{ idCustomer: string }>;
  segmentId: string;
}
export async function putCustomersBySegmentRelation({
  values,
  segmentId,
}: PutCustomersBySegmentRelationParams): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/customer/relations/by-segment-id/${segmentId}`,
    method: 'PUT',
    data: values,
  });

  return data;
}
