import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

export async function toggleSegments(segmentId: string): Promise<void> {
  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/segments/toggle-activated/${segmentId}`,
    method: 'POST',
  });

  return data;
}
