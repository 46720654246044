import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export type EditButtonComponentProps = {
  showSave?: boolean;
  showEdit?: boolean;
  disabled?: boolean;
  showText?: boolean;
  showDiscard?: boolean;
  onSaveClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onEditClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDiscardClick?: (event: React.MouseEvent<HTMLElement>) => void;
  handleEditButton?: (
    event: React.MouseEvent<HTMLElement>,
    isEdit: boolean
  ) => void;
};

export const EditButtonComponent: FC<EditButtonComponentProps> = ({
  showSave = false,
  showEdit = true,
  disabled,
  showText = true,
  showDiscard = true,
  onEditClick,
  onDiscardClick,
  onSaveClick,
  handleEditButton,
}) => {
  return (
    <>
      <InlineElements>
        {showEdit ? (
          <Button
            data-testid='handle-edit'
            disabled={disabled}
            style={{
              color: '#177ddc',
            }}
            onClick={e => {
              if (onEditClick) {
                onEditClick(e);
              }
              if (handleEditButton) {
                handleEditButton(e, false);
              }
            }}
            type='link'
          >
            <EditOutlined />
            {showText ? 'Editar' : null}
          </Button>
        ) : null}
        {showDiscard ? (
          <Button
            data-testid='handle-cancel'
            disabled={disabled}
            style={{
              color: '#ffffff',
            }}
            onClick={e => {
              if (onDiscardClick) {
                onDiscardClick(e);
              }
              if (handleEditButton) {
                handleEditButton(e, false);
              }
            }}
            type='link'
          >
            <DeleteOutlined />
            {showText ? 'Descartar' : null}
          </Button>
        ) : null}
        {showSave ? (
          <Button
            data-testid='handle-save'
            disabled={disabled}
            style={{
              color: '#52c41a',
              marginLeft: '10px',
            }}
            onClick={e => {
              if (onSaveClick) {
                onSaveClick(e);
              }
              if (handleEditButton) {
                handleEditButton(e, true);
              }
            }}
            type='link'
          >
            <CheckCircleOutlined />
            {showText ? 'Salvar' : null}
          </Button>
        ) : null}
      </InlineElements>
    </>
  );
};

const InlineElements = styled.div`
  float: right;
  display: inline-flex;
`;
