import { useDebounce } from '@hooks';
import { Assets, Customer, Programs, Segments } from '@models';
import {
  AssetsTableList,
  CustomersTableList,
  ProgramsTableList,
} from '@templates';
import { CountResponse } from '@typings';
import { Tabs } from 'antd';
import React, { useEffect } from 'react';

import { TabExtra } from './TabExtra/TabExtra.component';

const ASSETS_TAB = `${Segments.LinkedSegmentsEnum.ASSETS}-TAB`;
const CUSTOMER_TAB = `${Segments.LinkedSegmentsEnum.CUSTOMERS}-TAB`;
const PROGRAMS_TAB = `${Segments.LinkedSegmentsEnum.PROGRAMS}-TAB`;

const getFilterPlaceholder = (tab: string) => {
  switch (tab) {
    case ASSETS_TAB:
      return 'Busque pelo asset desejado.';
    case CUSTOMER_TAB:
      return 'Busque pelo usuário desejado.';
    case PROGRAMS_TAB:
      return 'Busque pelo programa desejado.';

    default:
      return '';
  }
};

interface EventDetailTemplateProps {
  usersList?: CountResponse<Customer.CustomerResponse>;
  programsList?: CountResponse<Programs.ProgramsResponse>;
  assetsList?: CountResponse<Assets.IAsset>;
  linkedSegments?: Segments.LinkedSegmentsProps;
  onSelectRowToUnlink?: (
    field: Segments.LinkedSegmentsEnum,
    data:
      | Segments.LinkedSegmentsProps['assets']
      | Segments.LinkedSegmentsProps['customers']
      | Segments.LinkedSegmentsProps['programs']
  ) => void;
  onClickToUnlink?: (field: Segments.LinkedSegmentsEnum) => void;
  onFilter?: (field: Segments.LinkedSegmentsEnum, data: string) => void;
  onLink?: (field: Segments.LinkedSegmentsEnum) => void;
  disabled?: boolean;
  onChangeAssetsPagination: (take: number, skip: number) => void;
  onChangeProgramsPagination: (take: number, skip: number) => void;
  onChangeUsersPagination: (take: number, skip: number) => void;
  sugestedTab?: Segments.LinkedSegmentsEnum;
  assetCurrentPage: number;
  userCurrentPage: number;
  programCurrentPage: number;
}

export const SegmentsTabListTemplate: React.FC<EventDetailTemplateProps> = ({
  assetsList,
  programsList,
  usersList,
  linkedSegments,
  onSelectRowToUnlink,
  onClickToUnlink,
  onFilter,
  onLink,
  disabled,
  onChangeAssetsPagination,
  onChangeProgramsPagination,
  onChangeUsersPagination,
  sugestedTab,
  assetCurrentPage,
  userCurrentPage,
  programCurrentPage,
}) => {
  const [currentTab, setCurrentTab] = React.useState(CUSTOMER_TAB);
  const [firstFilter, setFirstFilter] = React.useState(false);
  const [filterString, setFilterString] = React.useState('');
  const debounceValue = useDebounce(filterString, 900);

  useEffect(() => {
    setCurrentTab(sugestedTab ? `${sugestedTab}-TAB` : CUSTOMER_TAB);
  }, []);

  useEffect(() => {
    if (!firstFilter) {
      setFirstFilter(true);
      return;
    }
    onFilter?.(
      currentTab.split('-')[0] as Segments.LinkedSegmentsEnum,
      debounceValue
    );
  }, [debounceValue]);

  const handleDisableUnlink = () => {
    switch (currentTab) {
      case ASSETS_TAB:
        return !!linkedSegments?.assets?.length;
      case CUSTOMER_TAB:
        return !!linkedSegments?.customers?.length;
      case PROGRAMS_TAB:
        return !!linkedSegments?.programs?.length;

      default:
        return false;
    }
  };

  const handleDisableOnlink = () => {
    switch (currentTab) {
      case ASSETS_TAB:
        return !!assetsList?.rows?.length;
      case CUSTOMER_TAB:
        return !!usersList?.rows?.length;
      case PROGRAMS_TAB:
        return !!programsList?.rows?.length;

      default:
        return false;
    }
  };

  const items = [
    {
      label: 'Assets',
      key: ASSETS_TAB,
      children: (
        <AssetsTableList
          value={assetsList}
          paginationCurrentPage={assetCurrentPage}
          onChangePagination={(take, skip) =>
            onChangeAssetsPagination(take, skip)
          }
          rowsSelected={linkedSegments?.assets}
          onSelectRow={selectedRow =>
            onSelectRowToUnlink?.(
              Segments.LinkedSegmentsEnum.ASSETS,
              selectedRow
            )
          }
        />
      ),
    },
    {
      label: 'Usuários',
      key: CUSTOMER_TAB,
      disabled,
      children: (
        <CustomersTableList
          value={usersList}
          paginationCurrentPage={userCurrentPage}
          onChangePagination={(take, skip) =>
            onChangeUsersPagination(take, skip)
          }
          onSelectRow={selectedRow =>
            onSelectRowToUnlink?.(
              Segments.LinkedSegmentsEnum.CUSTOMERS,
              selectedRow
            )
          }
        />
      ),
    },
    {
      label: 'Programas',
      key: PROGRAMS_TAB,
      disabled,
      children: (
        <ProgramsTableList
          value={programsList}
          rowsSelected={linkedSegments?.programs}
          paginationCurrentPage={programCurrentPage}
          onChangePagination={(take, skip) =>
            onChangeProgramsPagination(take, skip)
          }
          onSelectRow={selectedRow =>
            onSelectRowToUnlink?.(
              Segments.LinkedSegmentsEnum.PROGRAMS,
              selectedRow
            )
          }
        />
      ),
    },
  ];

  return (
    <Tabs
      activeKey={currentTab}
      onChange={activeKey => {
        setCurrentTab(activeKey);
        setFilterString('');
        if (!filterString) return;
        onFilter?.(currentTab.split('-')[0] as Segments.LinkedSegmentsEnum, '');
      }}
      style={{ opacity: disabled ? 0.5 : 1 }}
      tabBarExtraContent={
        <TabExtra
          filterPlaceholder={getFilterPlaceholder(currentTab)}
          filterValue={filterString}
          onFilter={value => {
            if (disabled) return;
            setFilterString(value);
          }}
          onClickToLink={() => {
            if (disabled) return;
            onLink?.(currentTab.split('-')[0] as Segments.LinkedSegmentsEnum);
          }}
          onClickToUnlink={() => {
            if (disabled) return;
            onClickToUnlink?.(
              currentTab.split('-')[0] as Segments.LinkedSegmentsEnum
            );
          }}
          unlinkDisabled={handleDisableUnlink()}
          linkDisabled={disabled}
          onlyLink={handleDisableOnlink() || !!filterString}
        />
      }
      items={items}
    />
  );
};
