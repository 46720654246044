import { Alert, Form, Input } from 'antd';
import { ChangeEvent, FC } from 'react';
import * as yup from 'yup';

import { FormModalProps } from 'src/features/Lgpd/interfaces/lgpdInterfaces';

const schema = yup
  .object()
  .shape({
    customerEmail: yup
      .string()
      .required('O campo de e-mail é obrigatório')
      .email('Digite um e-mail válido'),
  })
  .defined();

export const FormModal: FC<FormModalProps> = ({
  setData,
  formData,
  setHasError,
  hasError,
  setStateOkButton,
  invalidConsult,
}) => {
  const { Item } = Form;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    try {
      await schema.validate(formData);
      setHasError(false);
      setStateOkButton('enable');
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <>
      {invalidConsult && (
        <Alert
          message='Esse cliente já foi consultado e/ou anonimizado.'
          type='error'
          showIcon
        />
      )}
      <Form layout={'vertical'}>
        <Item
          label='E-mail'
          name='email'
          rules={
            hasError
              ? [
                  {
                    type: 'email',
                    message: 'Por favor, insira um e-mail válido!',
                  },
                  {
                    required: true,
                    message: 'Por favor, insira seu e-mail!',
                  },
                ]
              : []
          }
        >
          <Input
            id='customerEmail'
            type='email'
            name='customerEmail'
            onBlur={handleChange}
            onChange={handleChange}
            value={formData.customerEmail}
          />
        </Item>
        <Item label='Ticket do Jira'>
          <Input
            id='jiraTicket'
            type='text'
            name='jiraTicket'
            onChange={handleChange}
            value={formData.jiraTicket}
          />
        </Item>
      </Form>
    </>
  );
};
