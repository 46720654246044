import {
  CheckCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleFilled,
} from '@ant-design/icons';
import { Alert, Col, Form, Input, Row } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { passwordStrength } from 'check-password-strength';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

type ModalProps = {
  loading?: boolean;
  error?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  setNewPasswordRecoveryAccess?: Dispatch<SetStateAction<string>>;
  setConfirmNewPasswordRecoveryAccess?: Dispatch<SetStateAction<string>>;
  setErrorNovaSenhaRecuperarAcesso?: Dispatch<SetStateAction<string>>;
};

export const ModalRecoveryAccessChangePasswordStep: FC<ModalProps> = ({
  loading = false,
  error = '',
  newPassword = '',
  confirmNewPassword = '',
  setNewPasswordRecoveryAccess,
  setConfirmNewPasswordRecoveryAccess,
  setErrorNovaSenhaRecuperarAcesso,
}) => {
  const [passwordStrengthIsValid, setPasswordStrengthIsValid] = useState(false);
  const [lengthIsValid, setLengthIsValid] = useState(false);
  const [uppercaseIsValid, setUppercaseIsValid] = useState(false);
  const [numberIsValid, setNumberIsValid] = useState(false);
  const [symbolIsValid, setSymbolIsValid] = useState(false);
  const [statusNovaSenha, setStatusNovaSenha] =
    useState<ValidateStatus>('warning');
  const [statusConfirmarSenha, setStatusConfirmarSenha] =
    useState<ValidateStatus>('warning');
  const validaStatusConfirmarSenha = () => {
    const isWarning =
      (confirmNewPassword && confirmNewPassword === '') ||
      !confirmNewPassword ||
      !passwordStrengthIsValid ||
      (passwordStrengthIsValid && newPassword !== confirmNewPassword);
    const isError = error && error !== '';

    setStatusConfirmarSenha(
      isWarning ? 'warning' : isError ? 'error' : 'success'
    );
  };
  const onInputPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const passwordStrengthResponse = passwordStrength(e.currentTarget.value);
    const isLengthValid = e.currentTarget.value.length >= 8;
    const isUppercaseValid =
      passwordStrengthResponse.contains.indexOf('uppercase') >= 0;
    const isNumberValid =
      passwordStrengthResponse.contains.indexOf('number') >= 0;
    const isSymbolValid =
      passwordStrengthResponse.contains.indexOf('symbol') >= 0;
    setLengthIsValid(isLengthValid);
    setUppercaseIsValid(isUppercaseValid);
    setNumberIsValid(isNumberValid);
    setSymbolIsValid(isSymbolValid);
    const isValid =
      isLengthValid && isUppercaseValid && isNumberValid && isSymbolValid;
    setStatusNovaSenha(!isValid ? 'warning' : 'success');
    setPasswordStrengthIsValid(isValid);
    if (setNewPasswordRecoveryAccess) {
      setNewPasswordRecoveryAccess(e.currentTarget.value);
    }
  };
  const onInputConfirmPasswordChange = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (setErrorNovaSenhaRecuperarAcesso) {
      setErrorNovaSenhaRecuperarAcesso('');
    }
    if (setConfirmNewPasswordRecoveryAccess) {
      setConfirmNewPasswordRecoveryAccess(e.currentTarget.value);
    }
    validaStatusConfirmarSenha();
  };
  useEffect(
    () => validaStatusConfirmarSenha(),
    [newPassword, confirmNewPassword, error]
  );
  const showIconValidInvalid = (isValid: boolean): JSX.Element | undefined => {
    return isValid ? (
      <CheckCircleFilled
        style={{
          color: '#49aa19',
          marginRight: 5,
        }}
      />
    ) : (
      <InfoCircleFilled
        style={{
          color: '#D87A16',
          marginRight: 5,
        }}
      />
    );
  };
  const showErrorContent = (): JSX.Element | undefined => {
    const isWarning = statusConfirmarSenha === 'warning';
    if (
      confirmNewPassword &&
      confirmNewPassword !== '' &&
      (isWarning || (error && error !== ''))
    ) {
      return (
        <span
          data-testid='input-error-confirmNewPassword'
          style={{
            marginTop: 5,
            color: isWarning ? '#D87A16' : '#D32029',
          }}
        >
          {isWarning
            ? passwordStrengthIsValid
              ? 'Senhas diferentes.'
              : 'Senha deve ser mais forte.'
            : error}
        </span>
      );
    }
    return undefined;
  };
  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10, width: '100%' }}>
          <Alert
            message='Tudo certo! Agora, crie uma nova senha.'
            description={
              <>
                <p className='text-silver-chalice'>
                  Após clicar em Salvar, você deverá usar esta nova senha para
                  acessar o sistema.
                </p>
              </>
            }
            type='success'
            showIcon
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: 10, width: '100%' }}>
          <Form layout='vertical'>
            <Form.Item
              style={{
                marginBottom: 20,
              }}
              hasFeedback={true}
              validateStatus={statusNovaSenha}
              label='Crie uma nova senha:'
            >
              <Input.Password
                minLength={8}
                disabled={loading}
                placeholder='Insira a senha'
                data-testid='nova-senha'
                autoComplete='off'
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={onInputPasswordChange}
              />
            </Form.Item>
            <div
              className='text-silver-chalice'
              style={{
                backgroundColor: '#1F1F1F',
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 15,
                paddingRight: 15,
                marginBottom: 30,
              }}
            >
              <p>Sua nova senha precisa conter ao menos estes itens:</p>
              <p>{showIconValidInvalid(lengthIsValid)} 8 caracteres ou mais</p>
              <p>{showIconValidInvalid(uppercaseIsValid)} 1 letra maiúscula</p>
              <p>{showIconValidInvalid(numberIsValid)} 1 numeral</p>
              <p>
                {showIconValidInvalid(symbolIsValid)} 1 símbolo (alguns
                exemplos: @ & $ & *)
              </p>
            </div>
            <Form.Item
              style={{
                marginBottom: 0,
              }}
              hasFeedback={true}
              validateStatus={statusConfirmarSenha}
              label='Digite novamente a nova senha para confirmar:'
            >
              <Input.Password
                minLength={8}
                disabled={loading}
                placeholder='Insira a senha'
                data-testid='confirmar-senha'
                autoComplete='off'
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={onInputConfirmPasswordChange}
              />
            </Form.Item>
            {showErrorContent()}
          </Form>
        </Col>
      </Row>
    </>
  );
};
