import { RefSelectProps, Select as SelectLib } from 'antd';
import React from 'react';

import { OptionList, SelectProps } from './Select.interface';
import * as S from './Select.style';

const { Option } = SelectLib;

export type { OptionList };

export const Select: React.FC<SelectProps> = ({
  onChange,
  options,
  mode,
  placeholder = 'Selecione',
  value,
  innerRef,
}) => {
  return (
    <S.SelectContainer
      mode={mode}
      defaultValue={value}
      placeholder={placeholder}
      onChange={value => onChange(value as string)}
      ref={innerRef as React.Ref<RefSelectProps>}
    >
      {options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </S.SelectContainer>
  );
};
