import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.5px;
  text-align: left;
`;

export const Root = styled.button`
  all: unset;
  cursor: pointer;
  height: 28px;
  width: 28px;
  margin: 5px;
  border: 3px solid;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Indicator = styled.div`
  height: 100%;
  width: 100%;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  margin-left: 8px;
`;
