interface Props {
  children?: React.ReactNode;
  text?: string;
}

export const SectionTitle: React.FC<Props> = ({
  children = undefined,
  text = '',
}) => {
  return (
    <div
      style={{
        marginBottom: 10,
        fontWeight: 600,
        fontSize: 20,
      }}
    >
      {children || text}
    </div>
  );
};
