import { HolderOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActiveTag, InctiveTag } from '@molecules';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import * as S from './OrderTableSegmens.style';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <S.Container
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <S.DragButtonContainer>
        <HolderOutlined />
      </S.DragButtonContainer>
      <S.TitleContainer>{children}</S.TitleContainer>
    </S.Container>
  );
};

interface DataTable extends Record<string, any> {
  key: string;
}

interface OrderListProps {
  columnTitle: string;
  datakey: string;
  datasource: Record<string, any>[];
  changeOrder: (value: Record<string, any>[]) => void;
  showHeader?: boolean;
}
export const OrderList: React.FC<OrderListProps> = ({
  columnTitle,
  datakey,
  datasource,
  changeOrder,
  showHeader = true,
}) => {
  const [data, setData] = useState<DataTable[]>([]);
  const columns: ColumnsType<DataTable> = [
    {
      title: columnTitle,
      dataIndex: datakey,
      key: datakey,
    },
    {
      title: 'active',
      dataIndex: 'activated',
      key: 'activated',
      render: (value: boolean) => (value ? <ActiveTag /> : <InctiveTag />),
    },
  ];

  useEffect(() => {
    const normalizedData = datasource.map((value, index) => ({
      ...value,
      key: `${index}`,
    }));

    setData(normalizedData);
  }, [datasource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newData = [...data];
      setData(prev => {
        const activeIndex = prev.findIndex(i => i.key === active.id);
        const overIndex = prev.findIndex(i => i.key === over?.id);
        newData = arrayMove(prev, activeIndex, overIndex);
        return newData;
      });

      changeOrder(newData);
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map(i => i.key)}
        strategy={verticalListSortingStrategy}
      >
        {!!data.length && (
          <Table
            bordered={false}
            pagination={false}
            showHeader={showHeader}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey='key'
            columns={columns}
            dataSource={data}
          />
        )}
      </SortableContext>
    </DndContext>
  );
};

export default OrderList;
