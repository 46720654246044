import { FC } from 'react';
import { RouteProps } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { BadgeGroupDetails } from '../components/BadgeGroupDetails';

export const BadgeGroupDetailsScreen: FC<RouteProps> = () => {
  useBreadcrumbs([
    {
      label: 'Social',
      route: '/social/dashboard',
    },
    {
      label: 'Gamefication',
    },
    {
      label: 'Badges',
    },
    {
      label: 'Groups',
    },
    {
      label: 'Detalhes',
    },
  ]);
  return (
    <>
      <BadgeGroupDetails key={Date.now()} />
    </>
  );
};
