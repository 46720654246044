import { IBadgeGroupRequestDto } from '@core/gamefication/dto/badge-group/badge-group-request.dto';
import { slices } from '@core/redux';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as badgesGroupsApi from '../../../../../features/gamefication/badges-groups/api/badgesGroupsApi';
import { GamificationState } from '../gamefication.slice';

export const createBadgeGroup = createAsyncThunk(
  'gamification/badgeGroups/createBadgeGroup',
  async (params: IBadgeGroupRequestDto, { rejectWithValue, dispatch }) => {
    try {
      if (!params.competition?.id) {
        throw new Error('Competição não preenchida corretamentez.');
      }

      const { data } = await badgesGroupsApi.create({
        competition: {
          id: params.competition?.id,
        },
        name: params.name,
        hideName: params.hideName,
        order: params.order,
      });

      OpenNotification(false, 'Grupo criado com sucesso.');
      OpenNotification(false, 'Criando badge inicial', undefined, 'info');

      dispatch(
        slices.gamefication.badgeSlice.createBadge({
          badgeGroupId: data.id,
          badge: {
            id: Date.now(),
            name: 'Nome da badge',
            description: '',
            imagePath: '',
            isNew: true,
            order: undefined,
            competitionEvents: [],
          },
        })
      );

      return data;
    } catch (error: any) {
      OpenNotification(
        true,
        'Grupo de badge',
        error.message || 'Erro ao cadastrar o grupo.'
      );
      return rejectWithValue(error);
    }
  }
);

export const createBadgeGroupBuilder = (
  builder: ActionReducerMapBuilder<GamificationState>
) => {
  builder.addCase(createBadgeGroup.pending, state => {
    state.status = 'PENDING';
  });

  builder.addCase(createBadgeGroup.fulfilled, (state, { payload }) => {
    state.status = 'FULFILLED';
    state.badgeGroup = payload as IBadgeGroupRequestDto;
  });

  builder.addCase(createBadgeGroup.rejected, state => {
    state.status = 'ERROR';
  });
};
