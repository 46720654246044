import { Input as InputAnt } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 8px;
  }
`;

export const Input = styled(InputAnt)`
  border-radius: 6px;
`;
