import { Segments } from '@models';
import { Tag } from 'antd';
import React from 'react';

const tagConfig = {
  [Segments.TypeEnum.OPERATION]: {
    color: 'magenta',
    label: 'Operação',
  },
  [Segments.TypeEnum.BEHAVIOUR]: {
    color: 'blue',
    label: 'Comportamento',
  },
  [Segments.TypeEnum.BUSINESS]: {
    color: 'cyan',
    label: 'Negócio',
  },
};

interface SegmentTypeTagProps {
  type: Segments.TypeEnum;
  active?: boolean;
}
export const SegmentTypeTag: React.FC<SegmentTypeTagProps> = ({
  type,
  active,
}) => {
  if (!tagConfig[type]) return null;

  const { color, label } = tagConfig[type];
  return <Tag color={active ? color : 'default'}>{label}</Tag>;
};
