import { Col, Row } from 'antd';
import { useState } from 'react';

import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';

import {
  AddonBefore,
  InputStyled,
  InputStyledFirst,
  SectionTitle,
} from './styles';

export default function BasicInfo() {
  const [enableForm, setEnableForm] = useState(true);

  const handleEditForm = (
    event: React.MouseEvent<HTMLElement>,
    isEdit?: boolean
  ) => {
    event.preventDefault();
    setEnableForm(!enableForm);
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Row>
            <Col span={12} style={{ marginBottom: 8 }}>
              <SectionTitle>Informações do Papel</SectionTitle>
            </Col>
            <Col span={12} style={{ marginBottom: 8 }}>
              <EditButtonComponent
                data-testid='profileInfo'
                showSave={!enableForm}
                showDiscard={!enableForm}
                showEdit={enableForm}
                handleEditButton={handleEditForm}
              />
            </Col>
          </Row>
          <InputStyledFirst
            bordered={false}
            disabled
            data-testid='id'
            addonBefore={<AddonBefore>Nome do Perfil</AddonBefore>}
            value='Acesso em grupo colaborativo'
          />
          <InputStyled
            bordered={false}
            disabled={enableForm}
            addonBefore={<AddonBefore>Descrição</AddonBefore>}
            defaultValue='Este perfil tem acesso a todas permissões cadastradas no sistema'
            value='Os pontos são computados sempre que o usuário acessar um grupo'
            name='email'
            data-testid='email'
          />
          <InputStyled
            bordered={false}
            disabled={enableForm}
            addonBefore={<AddonBefore>Tipo de usuário</AddonBefore>}
            value='Padrão'
            name='name'
            data-testid='name'
          />
        </Col>
      </Row>
    </>
  );
}
