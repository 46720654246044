import React, { FC, SVGProps } from 'react';

import { StringLogo } from '../../../components/StringLogo';

export interface LogoProps extends SVGProps<SVGSVGElement> {
  fillColor?: string;
}
export const Logo: FC<LogoProps> = ({ fillColor, ...rest }) => {
  return (
    <svg
      style={rest.style}
      width={rest.width}
      height={rest.height}
      viewBox='0 0 352 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d={StringLogo}
        fill='#ACACAC'
      />
    </svg>
  );
};
