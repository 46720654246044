import { RootState, slices } from '@core/redux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { IBadgeGroupRequestDto } from 'src/core/gamefication/dto/badge-group/badge-group-request.dto';

const defaultFormData: IBadgeGroupRequestDto = {
  key: `form-${Date.now()}`,
  id: 0,
  isNew: true,
  name: '',
  hideName: false,
  order: 1,
  competition: {
    id: 0,
    name: '',
  },
};

export const useGetBadgeGroupFormData = (id: string | undefined) => {
  const { badgeGroup, status } = useSelector(
    (state: RootState) => state.gamification
  );

  const dispatch = useDispatch();

  const [formDataRollback, setFormDataRollback] =
    useState<IBadgeGroupRequestDto>(defaultFormData);
  const [disabledForm, setDisabledForm] = useState(true);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] =
    useState<IBadgeGroupRequestDto>(defaultFormData);

  useEffect(() => {
    if (status === 'PENDING') {
      setDisabledForm(true);
      setLoading(true);
    }
    setLoading(false);
  }, [status]);

  useEffect(() => {
    if (!badgeGroup) {
      setFormData(defaultFormData);
      setFormDataRollback(defaultFormData);
      setDisabledForm(false);
      return;
    }

    setFormData(badgeGroup);
    setFormDataRollback(badgeGroup);
    setDisabledForm(true);
  }, [badgeGroup]);

  const enableEditBadgeGroup = (): void => {
    setDisabledForm(false);
  };

  const editFormData = (value: IBadgeGroupRequestDto) => {
    setFormData(value);
  };

  const discardFormDataChanges = () => {
    if (!badgeGroup?.competition?.id) {
      OpenNotification(
        true,
        'Cadastre uma competição primeiro para descartar as alterações'
      );
      return;
    }
    setFormData(formDataRollback);
    setDisabledForm(true);
  };

  const saveFormEdition = () => {
    if (!formData?.competition?.id) {
      OpenNotification(
        true,
        'Selecione uma competição para salvar as alterações'
      );
      return;
    }
    if (formData?.isNew) {
      dispatch(slices.gamefication.badgeSlice.createBadgeGroup(formData));
    } else {
      dispatch(slices.gamefication.badgeSlice.updateBadgeGroup(formData));
    }

    setDisabledForm(true);
  };

  return {
    formData,
    disabledForm,
    formDataRollback,
    loading,
    editFormData,
    enableEditBadgeGroup,
    discardFormDataChanges,
    saveFormEdition,
  };
};
