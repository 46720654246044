import {
  CheckOutlined,
  CloseCircleFilled,
  CopyOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { useState } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ToastMessage } from './ToastMessage';

interface EditCopyInputBaseProps {
  label: string;
  inputType: 'input' | 'select';
  selectOptions?: { value: any; label: string }[];
  type: 'edit' | 'copy' | 'viewOnly';
  defaultValue?: string;
  rules?: Record<string, any>;
  maxLength?: number;
  onSave?: (changedField: string, formData: FieldValues) => void;
  maskValue?: (value: any) => string;
}

interface CopyInputWithDefaultValue extends EditCopyInputBaseProps {
  type: 'copy' | 'viewOnly';
  name?: string;
  defaultValue: string;
}

interface InputEditable extends EditCopyInputBaseProps {
  inputType: 'input';
  type: 'edit';
  name: string;
  defaultValue?: string;
}

interface SelectInputEditable extends EditCopyInputBaseProps {
  inputType: 'select';
  type: 'edit';
  selectOptions: { value: any; label: string }[];
  name: string;
  defaultValue?: string;
}

type EditCopyInputProps =
  | SelectInputEditable
  | InputEditable
  | CopyInputWithDefaultValue;

export const EditCopyInput: React.FC<EditCopyInputProps> = ({
  name,
  label,
  type,
  selectOptions,
  rules,
  onSave,
  defaultValue,
  inputType,
  maskValue,
  maxLength,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    handleSubmit,
  } = useFormContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleCancel = () => {
    if (!name) return;
    setValue(name, defaultValue);
    clearErrors(name);
    setIsEditing(false);
  };

  const handleSave = (value: string) => {
    if (name && onSave) {
      setValue(name, value);
      handleSubmit(data => {
        setIsEditing(false);
        onSave(label, data);
      })();
    }
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    ToastMessage({
      content: 'Texto copiado para área de transferência!',
      type: 'success',
    });
  };

  return (
    <Form.Item
      validateStatus={name && errors[name] ? 'error' : undefined}
      help={name && errors[name]?.message}
    >
      <ContainerInput>
        <CustomLabel>{label}</CustomLabel>
        {type === 'edit' && (
          <>
            {inputType === 'input' && (
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                  <>
                    <CustomInput type={type}>
                      {isEditing ? (
                        <Input
                          value={maskValue ? maskValue(value) : value}
                          onChange={onChange}
                          onPressEnter={() => handleSave(value)}
                          style={{ flex: 1 }}
                          maxLength={maxLength}
                        />
                      ) : (
                        <Input
                          value={maskValue ? maskValue(value) : value}
                          readOnly
                          style={{
                            flex: 1,
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                        />
                      )}

                      {!isEditing && (
                        <Tooltip title={'Copiar'}>
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                              handleCopy(value);
                            }}
                            style={{ borderColor: 'transparent' }}
                          />
                        </Tooltip>
                      )}
                      {isEditing && (
                        <Button
                          icon={<CloseCircleFilled style={{ color: 'red' }} />}
                          onClick={e => {
                            e.stopPropagation();
                            handleCancel();
                          }}
                          style={{ borderColor: 'transparent' }}
                        />
                      )}
                    </CustomInput>
                    {type === 'edit' && !isEditing && (
                      <Tooltip title='Editar'>
                        <Button
                          icon={<EditOutlined />}
                          onClick={e => {
                            e.stopPropagation();
                            setIsEditing(true);
                          }}
                          style={{
                            width: '32px',
                            height: '32px',
                            flexShrink: 0,
                          }}
                        />
                      </Tooltip>
                    )}
                    {type === 'edit' && isEditing && (
                      <Button
                        icon={<CheckOutlined />}
                        onClick={e => {
                          e.stopPropagation();
                          handleSave(value);
                        }}
                        style={{
                          width: '32px',
                          height: '32px',
                          flexShrink: 0,
                        }}
                      />
                    )}
                  </>
                )}
              />
            )}

            {inputType === 'select' && selectOptions && (
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                  <>
                    <CustomInput type={type}>
                      {isEditing ? (
                        <Select
                          value={value}
                          onChange={value => onChange(value)}
                          style={{ flex: 1 }}
                        >
                          {selectOptions.map(option => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          value={maskValue ? maskValue(value) : value}
                          readOnly
                          style={{
                            flex: 1,
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                        />
                      )}

                      {!isEditing && (
                        <Tooltip title={'Copiar'}>
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                              handleCopy(value);
                            }}
                            style={{ borderColor: 'transparent' }}
                          />
                        </Tooltip>
                      )}

                      {isEditing && (
                        <Button
                          icon={<CloseCircleFilled style={{ color: 'red' }} />}
                          onClick={e => {
                            e.stopPropagation();
                            handleCancel();
                          }}
                          style={{ borderColor: 'transparent' }}
                        />
                      )}
                    </CustomInput>
                    {type === 'edit' && !isEditing && (
                      <Tooltip title='Editar'>
                        <Button
                          icon={<EditOutlined />}
                          onClick={e => {
                            e.stopPropagation();
                            setIsEditing(true);
                          }}
                          style={{
                            width: '32px',
                            height: '32px',
                            flexShrink: 0,
                          }}
                        />
                      </Tooltip>
                    )}
                    {type === 'edit' && isEditing && (
                      <Button
                        icon={<CheckOutlined />}
                        onClick={e => {
                          e.stopPropagation();
                          handleSave(value);
                        }}
                        style={{
                          width: '32px',
                          height: '32px',
                          flexShrink: 0,
                        }}
                      />
                    )}
                  </>
                )}
              />
            )}
          </>
        )}

        {type !== 'edit' && (
          <CustomInput type={type}>
            <Input
              value={maskValue ? maskValue(defaultValue) : defaultValue}
              disabled={type === 'viewOnly'}
              readOnly
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                border: 'none',
              }}
            />
            {type === 'copy' && (
              <Tooltip title={'Copiar'}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    handleCopy(defaultValue);
                  }}
                  style={{ borderColor: 'transparent' }}
                />
              </Tooltip>
            )}
          </CustomInput>
        )}
      </ContainerInput>
    </Form.Item>
  );
};

const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
`;

const CustomInput = styled.div<{ type: 'copy' | 'edit' | 'viewOnly' }>`
  display: flex;
  width: 100%;
  height: 34px;
  border: 1px solid #424242;
  margin-right: ${({ type }) => (type !== 'edit' ? '0px' : '16px')};
`;

const CustomLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  max-width: 200px;
  text-align: center;

  border-top: 1px solid #424242;
  border-left: 1px solid #424242;
  border-bottom: 1px solid #424242;
  background-color: #ffffff0a;
`;
