/* eslint-disable @typescript-eslint/no-explicit-any */
import { MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Table } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import {
  AdyenAssign,
  AdyenRefusedTransaction,
  AdyenTransaction,
  ClientType,
} from 'src/features/clients/types/clientTypes';

import { AdyenTransactionTabsComponent } from './adyen-tabs/AdyenTransactionTabsComponent';
import { ModalCancelation } from './components/ModalCancelation';
import { ModalCancelSchedule } from './components/ModalCancelSchedule';

export type TableProps = {
  dados?: ClientType;
  assignAdyen?: AdyenAssign[];
  transactionsAdyen?: AdyenTransaction[];
  refundAdyen?: AdyenTransaction[];
  transactionsCancelation?: AdyenRefusedTransaction[];
};

export const AdyenTableComponent: FC<TableProps> = ({
  dados,
  assignAdyen,
  transactionsAdyen,
  refundAdyen,
  transactionsCancelation,
}) => {
  const [isModalVisibleCancelation, setIsModalVisibleCancelation] = useState({
    itemId: 0,
    visible: false,
  });
  const [isModalVisibleCancelSchedule, setIsModalVisibleCancelSchedule] =
    useState({
      item: null,
      visible: false,
    });
  const DropdownComponent = (item: any) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                onClick={() =>
                  setIsModalVisibleCancelation({
                    itemId: item.id,
                    visible: true,
                  })
                }
                key='cancelamento'
                disabled={item.active === 'Inativo'}
              >
                Cancelamento
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setIsModalVisibleCancelSchedule({
                    item: item,
                    visible: true,
                  });
                }}
                key='cancelar-agendamento'
                disabled={item.active === 'Inativo'}
              >
                Cancelar Agendamento
              </Menu.Item>
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  const columnAssign = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Plataforma',
      dataIndex: ['offer', 'name'],
    },
    {
      title: 'Oferta',
      dataIndex: ['offer', 'description'],
    },
    {
      title: 'ID da Oferta',
      dataIndex: ['offer', 'id'],
    },
    {
      title: 'ID do Produto',
      dataIndex: ['offer', 'productSellGroupId'],
    },
    {
      title: 'Status',
      dataIndex: 'active',
    },
    {
      title: 'Auto Renovação',
      dataIndex: 'autoRenew',
      render: (autoRenew: any) => autoRenew,
    },
    {
      title: 'Método Pagamento',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Tipo',
      dataIndex: 'subscriptionType',
    },
    {
      title: 'Data Pagamento',
      dataIndex: 'startDate',
      render: (startDate: string) => (
        <div>{dateFormatted(startDate, true)}</div>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (item: any) => DropdownComponent(item),
    },
  ];

  if (
    !assignAdyen?.length &&
    !transactionsAdyen?.length &&
    !refundAdyen?.length &&
    !transactionsCancelation?.length
  ) {
    return <></>;
  }

  return (
    <>
      <Row>
        <h1 style={{ fontSize: 20, marginBottom: 10 }}>Adyen</h1>
      </Row>
      {!!assignAdyen?.length && (
        <Col span={24}>
          <TitleTable>Assinaturas</TitleTable>
          <Table
            scroll={{ x: 1450 }}
            style={{ width: '100vw' }}
            columns={columnAssign}
            dataSource={assignAdyen}
          />
        </Col>
      )}

      <Col span={24}>
        <TitleTable>Transações</TitleTable>
        <AdyenTransactionTabsComponent
          transactionsAdyen={transactionsAdyen}
          refundAdyen={refundAdyen}
          transactionsCancelation={transactionsCancelation}
          dados={dados}
        />
      </Col>

      <ModalCancelation
        modalValuesCancelation={isModalVisibleCancelation}
        setIsModalVisibleCancelation={() =>
          setIsModalVisibleCancelation({
            itemId: 0,
            visible: false,
          })
        }
      />
      <ModalCancelSchedule
        modalValuesCancelSchedule={{
          item: isModalVisibleCancelSchedule.item || undefined,
          visible: isModalVisibleCancelSchedule.visible,
        }}
        dados={dados}
        setIsModalVisibleCancelSchedule={() =>
          setIsModalVisibleCancelSchedule({
            item: null,
            visible: false,
          })
        }
      />
    </>
  );
};

const TitleTable = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #dbdbdb;
`;
