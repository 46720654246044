import { Form, Row, Switch, TooltipProps, Typography } from 'antd';
import React, { ReactNode } from 'react';
import {
  Control,
  Controller as ControllerHookForm,
  ControllerRenderProps,
} from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';

import * as S from './SwitchController.style';
const { Item: FormItem } = Form;

interface SwitchControllerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  defaultValue?: boolean;
  autoComplete?: string;
  onChange?: () => void;
  prefix?: React.ReactNode;
  type?: string;
  label?: string;
  sideLabel?: boolean;
  rightLabel?: string;
  leftLabel?: string;
  required?: boolean;
  description?: string;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
  disabled?: boolean;
}

export const SwitchController: React.FC<SwitchControllerProps> = ({
  control,
  defaultValue,
  name,
  label,
  sideLabel,
  rightLabel,
  leftLabel,
  required,
  description,
  tooltip,
  disabled,
}) => {
  const sideLabelSwitch = (field: ControllerRenderProps<any, string>) => {
    return (
      <S.SwitchContainer>
        {leftLabel && (
          <S.LeftLabel
            style={{
              color: field.value ? '#434343' : '#ACACAC',
            }}
          >
            {leftLabel}
          </S.LeftLabel>
        )}
        <Switch checked={field.value} disabled={disabled} {...field} />
        {rightLabel && (
          <S.RightLabel
            style={{
              color: field.value ? '#ACACAC' : '#434343',
            }}
          >
            {rightLabel}
          </S.RightLabel>
        )}
      </S.SwitchContainer>
    );
  };

  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);
        return (
          <FormItem
            validateStatus={status}
            help={help}
            label={sideLabel ? null : label}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginTop: '10px',
            }}
            {...(required && { required: required })}
            {...(tooltip && { tooltip: tooltip })}
          >
            {sideLabel ? (
              sideLabelSwitch(field)
            ) : (
              <Row style={{ flexDirection: 'row', gap: '8px' }}>
                <Switch checked={field.value} {...field} disabled={disabled} />
                {description && <Typography>{description}</Typography>}
              </Row>
            )}
          </FormItem>
        );
      }}
    />
  );
};
