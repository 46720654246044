import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Row, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

interface TitleFunnelPageProps {
  titlePage: string;
  setTitlePage: (value: string) => void;
  isEditTitle?: boolean;
  setIsEditTitle?: (value: boolean) => void;
}

export const TitleFunnelPage: React.FC<TitleFunnelPageProps> = ({
  titlePage,
  setTitlePage,
}) => {
  const [isEditTitle, setIsEditTitle] = useState(false);
  const inputTitleRef = useRef<any>(null);

  const handleEditTitlePage = useCallback(() => {
    if (inputTitleRef.current.input.value) {
      setTitlePage(inputTitleRef.current.input.value);
    }
    setIsEditTitle(false);
  }, []);

  return (
    <Row style={{ alignItems: 'center' }}>
      {isEditTitle ? (
        <>
          <InputTitle ref={inputTitleRef} defaultValue={titlePage} />
          <CheckOutlined
            style={{
              color: '#3C89E8',
              fontSize: '16px',
              marginLeft: '16px',
              cursor: 'pointer',
            }}
            data-testid='saveEditTitlePage'
            onClick={handleEditTitlePage}
          />
          <CloseOutlined
            style={{
              color: '#FFFFFF',
              fontSize: '16px',
              marginLeft: '16px',
              cursor: 'pointer',
              opacity: 0.45,
            }}
            data-testid='closeEditTitlePage'
            onClick={() => setIsEditTitle(false)}
          />
        </>
      ) : (
        <>
          <Typography.Title
            level={4}
            style={{ marginBottom: '0px' }}
            data-testid='titlePage'
          >
            {titlePage}
          </Typography.Title>
          <EditOutlined
            style={{
              color: '#1668DC',
              fontSize: '24px',
              marginLeft: '9px',
              cursor: 'pointer',
            }}
            data-testid='editTitlePage'
            onClick={() => {
              setIsEditTitle(true);
            }}
          />
        </>
      )}
    </Row>
  );
};

const InputTitle = styled(Input)`
  width: auto;
  border: 0px;
  border-bottom: 1px solid #177ddc;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  &:active {
    border: 0px;
  }
  &:focus {
    border: 0px;
    border-bottom: 1px solid #177ddc;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.04);
  }
`;
