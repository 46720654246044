import { UpsellLinks } from '@models';
import { base } from '@service/base';

type PayloadCreate = {
  urlName: UpsellLinks.IUpsellLinks['urlName'];
  url: UpsellLinks.IUpsellLinks['url'];
  description: UpsellLinks.IUpsellLinks['description'];
} & (
  | {
      offerId: UpsellLinks.IUpsellLinks['offerId'];
    }
  | {
      productSellGroupId: UpsellLinks.IUpsellLinks['productSellGroupId'];
    }
);

export async function createUpsellLink(data: PayloadCreate) {
  const response = await base.post<UpsellLinks.IUpsellLinks>(
    `/funnel/link`,
    data
  );

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
