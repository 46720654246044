import { yupResolver } from '@hookform/resolvers/yup';
import { Segments } from '@models';
import { Controller as OrganismController } from '@organisms';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { boolean, mixed, object, SchemaOf, string } from 'yup';

interface SegmentRegisterFormProps {
  data?: Segments.ShortSegmentDto;
  onSubmit: (data: Segments.ShortSegmentDto) => void;
  formId?: string;
}

const validationSchema: SchemaOf<
  Omit<Segments.ShortSegmentDto, 'id' | 'active'>
> = object({
  id: string(),
  name: string().required('Este campo é obrigatório'),
  description: string().required('Este campo é obrigatório'),
  type: mixed()
    .oneOf(Object.values(Segments.TypeEnum))
    .required('Este campo é obrigatório'),
  activated: boolean(),
}).defined();

export const SegmentRegisterForm: React.FC<SegmentRegisterFormProps> = ({
  formId,
  data,
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm<Segments.ShortSegmentDto>({
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = (value: Segments.ShortSegmentDto) => {
    onSubmit({
      ...value,
      activated: true,
    });
  };

  return (
    <Form id={formId} layout='vertical' onFinish={handleSubmit(handleOnSubmit)}>
      <Row>
        <Col span={24}>
          <OrganismController
            control={control}
            label='Nome do segmento'
            name='name'
            placeholder='Insira o nome do segmento que deseja cadastrar'
            defaultValue={data?.name}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <OrganismController
            control={control}
            label='Descrição'
            name='description'
            placeholder='Insira uma descrição'
            defaultValue={data?.description}
            autoComplete='off'
          />
        </Col>
      </Row>
    </Form>
  );
};
