import { CountResponse } from '@typings';

export enum TypeEnum {
  PERCENT = 'PERCENT',
  FIXED = 'FIXED',
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  SCHEDULE = 'SCHEDULE',
  FINISH = 'FINISH',
  DISABLED = 'DISABLED',
}

export enum PurchaseTypeAllowedEnum {
  ALL = 'all',
  FIRST = 'first',
  RECURRENT = 'recurrent',
}

export enum ProductSelectionEnum {
  ALL = 'ALL',
  CERTAIN_KITS = 'CERTAIN_KITS',
}

export interface ICoupon {
  id: number;
  code: string;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: PurchaseTypeAllowedEnum;
  allowedProducts: number[] | null;
  discount: {
    type: TypeEnum;
    value: number;
  };
  maxUse: number;
  maxUsePerCustomer: number;
  status: StatusEnum;
  active: boolean;
}

export type TCouponResponse = CountResponse<ICoupon>;

const CouponTypeLabels: { [key in TypeEnum]: string } = {
  [TypeEnum.PERCENT]: 'Percentual',
  [TypeEnum.FIXED]: 'Valor Fixo',
};

const CouponStatusLabels: { [key in StatusEnum]: string } = {
  [StatusEnum.ACTIVE]: 'Ativo',
  [StatusEnum.DISABLED]: 'Desativado',
  [StatusEnum.FINISH]: 'Finalizado',
  [StatusEnum.SCHEDULE]: 'Agendado',
};

export const getLabelType = (tipo: TypeEnum): string => {
  return CouponTypeLabels[tipo];
};

export const getLabelStatus = (tipo: StatusEnum): string => {
  return CouponStatusLabels[tipo];
};
