import React from 'react';

import * as S from './EmptyButton.style';

interface EmptyButtonProps {
  onClick: () => void;
  disabled?: boolean;
}
export const EmptyButton: React.FC<EmptyButtonProps> = ({
  children,
  onClick,
  disabled,
}) => {
  return (
    <S.Button disabled={disabled} onClick={onClick}>
      {children}
    </S.Button>
  );
};
