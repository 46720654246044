import { Button, Form, Input, Modal } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { roleApi, RoleType } from '../api/roleApi';
import Permissions from './Permissions';
import * as Styles from './styles';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOk: (data: any) => void;
};

const RequiredString = 'Campo Obrigatório';

export const ModalAddPermisson: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RoleType>();

  const onSubmit = handleSubmit(async data => {
    const res = await roleApi.create(data);
    handleOk(res);
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title='Cadastrar Papel'
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={onSubmit}>
            Salvar
          </Button>,
        ]}
      >
        <Form style={{ width: '100%' }} form={form} layout='horizontal'>
          <Styles.Label>Nome do perfil</Styles.Label>
          <Input
            placeholder='Insira o nome do papel que deseja cadastrar'
            {...register('name', { required: true })}
          />
          <Styles.ErrorStyled>
            {errors.name ? { RequiredString } : ''}
          </Styles.ErrorStyled>
          <Styles.Space />
          <Styles.Label>Descrição</Styles.Label>
          <Input
            placeholder='Insira uma descrição'
            {...register('details', { required: true })}
          />
          <Styles.ErrorStyled>
            {errors.details ? { RequiredString } : ''}
          </Styles.ErrorStyled>
          <Styles.Space />

          <Styles.SubSectionTitle>Nome das Permissões</Styles.SubSectionTitle>
          <Styles.SubSectionDescription>
            Se preferir, você pode alterar as permissões depois
          </Styles.SubSectionDescription>
          <Permissions />
        </Form>
      </Modal>
    </>
  );
};
