import { useUpsellFlow } from '@hooks';
import { PageHeader } from '@organisms';
import React from 'react';

import { TableUpsellFlow } from './Table.upsellFlow';

export const UpsellFlow: React.FC = () => {
  const {
    productSellGroup,
    upsellList,
    isLoadingData,
    handleChangePagination,
    handleEditUpsellFlow,
  } = useUpsellFlow();

  if (!productSellGroup) return <></>;

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={productSellGroup.name}
        subTitle={'Gerencie, edite e crie novas etapas de Funilsss.'}
      />

      <TableUpsellFlow
        onChangePagination={handleChangePagination}
        data={upsellList}
        onEditUpsellFlow={handleEditUpsellFlow}
        isLoading={isLoadingData}
      />
    </>
  );
};
