import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: transparent;
  margin-top: 24px;
`;

export const Content = styled(Layout.Content)`
  padding: 24px;
  min-height: 320px;
  background: #1f1f1f;
  border: 1px solid #424242;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
