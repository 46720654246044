const AUTH_STORAGE_KEY = 'AUTH_TOKEN';
const AUTH_LAST_EMAIL_KEY = 'AUTH_LAST_EMAIL';

export async function saveAuthTokenOnStorage(token: string): Promise<void> {
  sessionStorage.setItem(AUTH_STORAGE_KEY, token);
}

export async function getAuthToken(): Promise<string> {
  const token = sessionStorage.getItem(AUTH_STORAGE_KEY);

  if (!token) {
    throw new Error('Not auth token found');
  }

  return token;
}

export async function removeAuthToken(): Promise<void> {
  sessionStorage.clear();
}

export async function saveLoginEmailOnStorage(token: string): Promise<void> {
  localStorage.setItem(AUTH_LAST_EMAIL_KEY, token);
}
export function retriveLoginEmailFromStorage(): string {
  const email = localStorage.getItem(AUTH_LAST_EMAIL_KEY);

  return email ?? '';
}
