import { Col, Divider, Row, Table } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { ClientType } from 'src/features/clients/types/clientTypes';

import useClassAttended from './hooks/useClassAttended';
import useClassViewed from './hooks/useClassViewed';
import useProgramAccess from './hooks/useProgramAccess';
import useProgramAccessLast from './hooks/useProgramAccessLast';
import useProgramFavorite from './hooks/useProgramFavorite';

export type ClientProps = {
  dados?: ClientType;
};

const FIXED_TWO_DECIMAL_PLACES = 2;

export const ClientDataTab: FC<ClientProps> = ({ dados }) => {
  const programFavorite = useProgramFavorite(dados?.email);
  const programAccessLast = useProgramAccessLast(dados?.email);
  const classViewed = useClassViewed(dados?.email);
  const programAccess = useProgramAccess(dados?.email);
  const classAttended = useClassAttended(dados?.email);

  return (
    <>
      <Row>
        <Col span={12}>
          <Table
            bordered
            title={() => <Title>Ultimas aulas assistidas</Title>}
            style={{ width: '98%' }}
            size={'small'}
            columns={[
              {
                title: 'Data / Hora',
                dataIndex: 'hora',
                key: 'hora',
                render: (hora: string) => (
                  <div>{hora.replaceAll('-', '/')}</div>
                ),
              },
              {
                title: 'Aulas Assistidas',
                dataIndex: 'programa',
                key: 'programa',
              },
            ]}
            pagination={{
              pageSize: 5,
              position: ['bottomCenter'],
              responsive: true,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            dataSource={classAttended?.data?.undefined}
          />
        </Col>
        <Col span={12}>
          <Table
            bordered
            style={{ width: '100vw' }}
            title={() => <Title>Ultimos acessos na plataforma</Title>}
            pagination={{
              pageSize: 5,
              position: ['bottomCenter'],
              responsive: true,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            columns={[
              {
                title: 'Acesso',
                dataIndex: 'acesso',
                key: 'acesso',
              },
              {
                title: 'User Agent',
                dataIndex: 'device',
                key: 'device',
              },
            ]}
            dataSource={programAccessLast.data?.programs}
          />
        </Col>
        <Divider />
        <Col span={12}>
          <Table
            bordered
            title={() => <Title>Programas preferidos (12 meses)</Title>}
            style={{ width: '98%' }}
            size={'small'}
            columns={[
              {
                title: 'Programa',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Número de Aulas',
                dataIndex: 'contagem',
                key: 'contagem',
                align: 'center',
              },
            ]}
            pagination={{
              pageSize: 5,
              position: ['bottomCenter'],
              responsive: true,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            dataSource={programFavorite.data?.programs}
          />
        </Col>
        <Col span={12}>
          <Table
            bordered
            style={{ width: '100vw' }}
            title={() => <Title>Acessos a Plataforma do Cliente</Title>}
            pagination={{
              pageSize: 5,
              position: ['bottomCenter'],
              responsive: true,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            columns={[
              {
                title: 'Programa',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Transação',
                dataIndex: 'Transação',
                key: 'Transação',
              },
              {
                title: 'Vence em',
                dataIndex: 'expiration_date',
                key: 'expiration_date',
                render: (expiration_date: string) => (
                  <div>{dateFormatted(expiration_date, true)}</div>
                ),
              },
            ]}
            dataSource={programAccess?.data}
          />
        </Col>
        <Divider />

        <Col span={24}>
          <Table
            bordered
            size={'small'}
            title={() => <Title>Aulas Visualizadas</Title>}
            pagination={{
              pageSize: 5,
              position: ['bottomCenter'],
              responsive: true,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            columns={[
              {
                title: 'Programa',
                dataIndex: 'Programa',
              },
              {
                title: 'Visualizadas',
                dataIndex: 'Visualizações',
                key: 'Visualizações',
              },
              {
                title: 'Device',
                dataIndex: 'Device',
                key: 'Device',
              },
              {
                title: 'Nota',
                dataIndex: 'Nota Média',
                key: 'Nota Média',
                render: (nota: string) => {
                  return (
                    <div>
                      {parseInt(nota).toFixed(FIXED_TWO_DECIMAL_PLACES)}
                    </div>
                  );
                },
              },
            ]}
            dataSource={classViewed.data?.programs}
          />
        </Col>
      </Row>
    </>
  );
};

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`;
