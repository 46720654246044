import './userDetailsForm.css';

import { Col, Input, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { AlertComponent } from 'src/app/components/antd/Alert';
import DatePicker from 'src/app/components/antd/DatePicker';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';
import { clientApi } from 'src/features/clients/api/clientApi';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';
import {
  ProfileDTOType,
  ProfileType,
} from 'src/features/clients/types/clientTypes';
import { calcAge, calcImc } from 'src/features/clients/utils/userUtils';

import { ClientDetailsProps } from '../ClientDetailsTab';
const gray = 'rgb(67, 67, 67)';
export const ClientProfileForm: FC<ClientDetailsProps> = ({
  profile,
  dados,
  reloadUser,
}) => {
  const { Option } = Select;

  const [visible, setVisible] = useState(false);
  function setProfileInDataMap(profileParam?: ProfileType) {
    if (!profileParam) {
      return setDataMap(initialDataMap);
    }
    setDataMap(profileParam);
  }

  useEffect(() => {
    setProfileInDataMap(profile);
  }, [profile]);

  const initialDataMap = {
    id: '',
    birthday: undefined,
    children: undefined,
    createdAt: '',
    customerId: 0,
    gender: undefined,
    goal: '',
    height: '',
    initial_weight: '',
    level: undefined,
    objective: undefined,
    weight: '',
    updatedAt: '',
    imc: undefined,
  };

  const [enableForm, setEnableForm] = useState(true);
  const [dataMap, setDataMap] = useState<ProfileType>(initialDataMap);

  const handleEditForm = (
    event: React.MouseEvent<HTMLElement>,
    isEdit?: boolean
  ) => {
    event.preventDefault();
    setEnableForm(!enableForm);
    isEdit ? mutate() : setProfileInDataMap(profile);
  };

  const edit = async () => {
    const customerId = dados?.id;
    const json: ProfileDTOType = {
      id: dataMap.id,
      objective: dataMap?.objective,
      level: dataMap?.level,
      createdAt: dataMap.createdAt,
      updatedAt: dataMap.updatedAt,
      initial_weight: dataMap.initial_weight,
      weight: dataMap.weight,
      goal: dataMap.goal,
      gender: dataMap?.gender,
      children: dataMap?.children,
      height: dataMap.height,
      customerId: customerId ? Number(customerId) : 0,
      birthday: dataMap.birthday,
    };
    return clientApi.updateProfile(json);
  };

  const { mutate } = useMutation(edit, {
    onSuccess: data => {
      setEnableForm(true);
      if (data.status === Success) {
        OpenNotification(
          false,
          'Sucesso!',
          'As Informações do cliente foram atualizadas!'
        );
        reloadUser();
      } else {
        OpenNotification(
          true,
          'Ocorreu um erro',
          'Tivemos um problema ao tentar atualizar cliente, por favor acione nosso suporte.'
        );
      }
    },
    onError: error => {
      OpenNotification(
        true,
        'Ocorreu um erro',
        'Tivemos um problema ao tentar atualizar cliente, por favor acione nosso suporte.'
      );
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target.validity.valid) {
      setDataMap(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleChangeSelect = (value: number, name: string) => {
    setDataMap(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeDate = (date: Date | null, dateString: string) => {
    if (date) {
      setDataMap(prevState => ({
        ...prevState,
        birthday: date,
      }));
    }
  };

  const renderEditButton = () => {
    if (!profile) {
      return <></>;
    }

    return (
      <EditButtonComponent
        showEdit={enableForm}
        showSave={!enableForm}
        showDiscard={!enableForm}
        handleEditButton={handleEditForm}
      />
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={12} style={{ marginBottom: 8 }}>
              <label>Perfil</label>
            </Col>
            <Col span={12} style={{ marginBottom: 8, display: 'none' }}>
              {renderEditButton()}
            </Col>
          </Row>

          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderTopColor: gray,
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderRadius: 0,
                }}
              >
                <AddonBefore>Nascimento</AddonBefore>
              </span>
              <DatePicker
                style={{
                  width: '100%',
                  borderRadius: 0,
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderRightColor: gray,
                  borderRightStyle: 'solid',
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopColor: gray,
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  background: 'none',
                }}
                format='dd/MM/yyyy'
                allowClear={false}
                disabled={enableForm}
                onChange={onChangeDate}
                value={dataMap?.birthday}
              />
            </span>
          </span>

          <InputStyled
            bordered={false}
            onChange={e => handleChange(e)}
            disabled
            addonBefore={<AddonBefore>Idade</AddonBefore>}
            defaultValue=''
            value={calcAge(dataMap?.birthday)}
          />

          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                }}
              >
                <AddonBefore>Gênero</AddonBefore>
              </span>
              <Select
                bordered={false}
                disabled={enableForm}
                onChange={e => handleChangeSelect(e, 'gender')}
                style={{
                  width: '100%',
                  border: 'none',
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderRightColor: gray,
                  borderRightStyle: 'solid',
                  borderRightWidth: 1,
                }}
                value={dataMap?.gender}
              >
                <Option value={0}>Feminino</Option>
                <Option value={1}>Masculino</Option>
                <Option value={2}>Outro</Option>
              </Select>
            </span>
          </span>

          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                }}
              >
                <AddonBefore>Nível</AddonBefore>
              </span>
              <Select
                bordered={false}
                disabled={enableForm}
                style={{
                  width: '100%',
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderRightColor: gray,
                  borderRightStyle: 'solid',
                  borderRightWidth: 1,
                }}
                value={dataMap?.level}
                onChange={e => handleChangeSelect(e, 'level')}
              >
                <Option value={1}>Sedentário</Option>
                <Option value={2}>Levemente ativo</Option>
                <Option value={3}>Ativo</Option>
              </Select>
            </span>
          </span>

          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                }}
              >
                <AddonBefore>Objetivo(s)</AddonBefore>
              </span>
              <Select
                bordered={false}
                disabled={enableForm}
                style={{
                  width: '100%',
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderRightColor: gray,
                  borderRightStyle: 'solid',
                  borderRightWidth: 1,
                }}
                value={dataMap?.objective}
                onChange={e => handleChangeSelect(e, 'objective')}
              >
                <Option value={1}>Ganhar massa muscular</Option>
                <Option value={2}>Perder gordura localizada</Option>
                <Option value={3}>Perder peso</Option>
                <Option value={4}>Flexibilidade</Option>
              </Select>
            </span>
          </span>

          <span className='ant-input-group-wrapper'>
            <span className='ant-input-wrapper ant-input-group'>
              <span
                className='ant-input-group-addon'
                style={{
                  borderBottomColor: gray,
                  borderBottomStyle: 'solid',
                  borderRadius: 0,
                  borderBottomWidth: 1,
                }}
              >
                <AddonBefore>Filhos</AddonBefore>
              </span>
              <SelectStyled
                bordered={false}
                disabled={enableForm}
                style={{ width: '100%' }}
                value={dataMap?.children}
              >
                <Option value={1}>Não</Option>
                <Option value={2}>Sim</Option>
                <Option value={3}>Não, mas pretendo ter</Option>
              </SelectStyled>
            </span>
          </span>
          <InputStyled
            bordered={false}
            onChange={e => handleChange(e)}
            disabled
            addonBefore={<AddonBefore>Altura</AddonBefore>}
            defaultValue=''
            className='ant-input-custom-border-bottom'
            value={dataMap?.height}
          />
          <InputStyled
            bordered={false}
            onChange={e => handleChange(e)}
            disabled={enableForm}
            addonBefore={<AddonBefore>Peso</AddonBefore>}
            defaultValue=''
            value={dataMap?.weight}
          />
          <InputStyled
            bordered={false}
            onChange={e => handleChange(e)}
            disabled
            addonBefore={<AddonBefore>IMC</AddonBefore>}
            defaultValue=''
            value={calcImc(dataMap?.imc, dataMap?.height, dataMap?.weight)}
          />
        </Col>
        {visible ? (
          <AlertComponent
            type='success'
            message='Informações do cliente atualizadas com sucesso'
            close={() => setVisible(false)}
          />
        ) : null}
      </Row>
    </>
  );
};
const AddonBefore = styled.div`
  width: 200px;
  text-align: left;
  border-radius: 0;
`;

const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-radius: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

const SelectStyled = styled(Select)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;
