import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #333;
  border-radius: 100px;
  position: relative;
  padding: 0;
  width: 98px;
  height: 53px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38%;
  padding: 10px;
  transition: background-color 0.3s;
  background-color: #141414;
  border-radius: 24px;
  border: 1px solid #434343;
  cursor: pointer;

  &.active {
    background-color: #007bff;
    border-radius: 24px;
  }

  svg {
    color: white;
  }
`;

interface CustomSwitchProp {
  LeftIcon: React.ReactNode;
  RightIcon: React.ReactNode;
  onChange: (value: number) => void;
  activeIndex: number;
}

export const CustomSwitch = ({
  LeftIcon,
  RightIcon,
  onChange,
  activeIndex,
}: CustomSwitchProp) => {
  return (
    <SwitchContainer>
      <IconWrapper
        className={activeIndex === 0 ? 'active' : ''}
        onClick={() => onChange(0)}
      >
        {LeftIcon}
      </IconWrapper>
      <IconWrapper
        className={activeIndex === 1 ? 'active' : ''}
        onClick={() => onChange(1)}
      >
        {RightIcon}
      </IconWrapper>
    </SwitchContainer>
  );
};
