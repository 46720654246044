import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { TableComponent } from 'src/app/components/antd/TableComponent';
import { RoleType } from 'src/features/roles/api/roleApi';
import useGetAllRoles from 'src/features/roles/hooks/useGetAllRoles';

import { adminApi } from '../api/adminApi';
import { AdminDTOType } from '../types/adminTypes';
import { ModalAddRole } from './ModalAddRole';

interface TableAdminRolesProps {
  roles?: React.Key[];
  admin?: AdminDTOType;
  reloadAdmin: () => void;
}

export const TableAdminRoles: FC<TableAdminRolesProps> = ({
  roles,
  admin,
  reloadAdmin,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataRoles, setDataRoles] = useState<RoleType[]>();

  const handleOk = async (data: React.Key[]) => {
    const responseUpdateAdminRoles = await adminApi.updateAdminRoles({
      id: admin?.id,
      roles: data,
    });
    if (responseUpdateAdminRoles) {
      OpenNotification(false, 'Sucesso!', 'Papeis atualizados com sucesso.');
      setIsModalVisible(false);
      reloadAdmin();
    } else {
      OpenNotification(
        true,
        'Ocorreu um erro',
        'Tivemos ao atualizar papeis, por favor acione nosso suporte.'
      );
    }
  };
  const responseRoles = useGetAllRoles();

  useEffect(() => {
    const listDataRoles: RoleType[] = [];
    roles?.forEach((role: React.Key) => {
      const dataRole = responseRoles.data?.find(x => x.id === role);
      if (dataRole) {
        listDataRoles.push(dataRole);
      }
    });
    setDataRoles(listDataRoles);
  }, [roles, responseRoles.data]);

  return (
    <>
      <Divider style={{ border: 'none' }} />
      <Col span={24}>
        <h4 style={{ float: 'left' }}>Papeis</h4>
        <Button
          style={{ float: 'right', border: 'none', color: '#1765ad' }}
          onClick={() => setIsModalVisible(true)}
        >
          {' '}
          <EditOutlined /> Editar
        </Button>
      </Col>
      <SpaceStyled />
      <Col span={24}>
        <TableComponent
          rowKey='id'
          pageSize={20}
          columns={[
            {
              title: 'Nome',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Descrição',
              dataIndex: 'details',
              key: 'details',
            },
          ]}
          data={dataRoles}
          total={0}
        />
      </Col>
      <ModalAddRole
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleOk={handleOk}
        data={responseRoles.data}
        roles={roles}
      />
    </>
  );
};

const SpaceStyled = styled.div`
  margin: 5px;
`;
