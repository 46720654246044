import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { FC, useEffect, useState } from 'react';

import { TableComponent } from 'src/app/components/antd/TableComponent';
import { IBadgeRuleCompetitionEventRequestDto } from 'src/core/gamefication/dto/badge-group/badge-request.dto';

import { BadgeEventsTableCell } from './badge-event-table-cell';
import { BadgeIntervaloTableCell } from './badge-intervalo-table-cell';
import { BadgeOcorrenciaTableCell } from './badge-ocorrencia-table-cell';
import { BadgePeriodoTableCell } from './badge-periodo-table-cell';
import { BadgeTextoDescritivoTableCell } from './badge-texto-descritivo-table-cell';
// import { BadgeTextoDescritivoTableCell } from './badge-texto-descritivo-table-cell';

type ModalProps = {
  addRulesDisabled?: boolean;
  editionMode?: boolean;
  listCompetitionEvents: IBadgeRuleCompetitionEventRequestDto[];
  badgeForm: FormInstance<IBadgeRuleCompetitionEventRequestDto>;
  toggleEventModal?: (value?: boolean) => void;
  onDelete?: (
    index: number,
    record: IBadgeRuleCompetitionEventRequestDto
  ) => void;
  onChange: (record: IBadgeRuleCompetitionEventRequestDto[]) => void;
};

type DataType = IBadgeRuleCompetitionEventRequestDto;

export const BadgeEventsTable: FC<ModalProps> = ({
  editionMode = false,
  listCompetitionEvents,
  badgeForm,
  toggleEventModal,
  onDelete,
}) => {
  const [listCompetitionEventsState, setListCompetitionEventsState] =
    useState<IBadgeRuleCompetitionEventRequestDto[]>();

  const handleRemoverCompetitionEvent = (
    index: number,
    record: IBadgeRuleCompetitionEventRequestDto
  ) => {
    onDelete?.(index, record);
  };
  useEffect(() => {
    setListCompetitionEventsState(listCompetitionEvents);
  }, [listCompetitionEvents]);

  if (!listCompetitionEvents) {
    return <></>;
  }

  const getTableColumns = () => {
    const columns: ColumnsType<IBadgeRuleCompetitionEventRequestDto> = [
      {
        title: 'Evento',
        dataIndex: 'events',
        render: (_, record: DataType, index: number) => {
          return (
            <Form.Item
              initialValue={record.events}
              name={`events-${record.id}`}
              style={{ margin: 0 }}
            >
              <BadgeEventsTableCell index={index} events={record?.events} />
            </Form.Item>
          );
        },
      },
      {
        title: 'Texto descritivo (Apresentação)',
        dataIndex: 'displayName',
        sorter: false,
        render: (_, record: DataType, index: number) => {
          return (
            <Form.Item
              initialValue={record.displayName}
              name={`displayName-${record.id}`}
              style={{ margin: 0 }}
            >
              <BadgeTextoDescritivoTableCell
                dataIndex={`displayName-${record.id}`}
                disabled={!editionMode}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Periodo',
        dataIndex: 'rangeDays',
        sorter: false,
        width: '15%',
        render: (_, record: DataType, index: number) => {
          return (
            <Form.Item
              initialValue={record.rangeDays}
              name={`rangeDays-${record.id}`}
              style={{ margin: 0 }}
            >
              <BadgePeriodoTableCell
                value={record.rangeDays}
                disabled={!editionMode}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Intervalo de ocorrência (min)',
        dataIndex: 'intervalOccurrenceMinutes',
        sorter: false,
        width: '15%',
        render: (_, record: DataType) => {
          return (
            <Form.Item
              initialValue={record.intervalOccurrenceMinutes}
              name={`intervalOccurrenceMinutes-${record.id}`}
              style={{ margin: 0 }}
            >
              <BadgeIntervaloTableCell disabled={!editionMode} />
            </Form.Item>
          );
        },
      },
      {
        title: 'Ocorrência',
        dataIndex: 'limitOccurrence',
        sorter: false,
        width: '15%',
        render: (_, record: DataType, index: number) => {
          return (
            <Form.Item
              initialValue={record.limitOccurrence || record.limitOcurrence}
              name={`limitOccurrence-${record.id}`}
              style={{ margin: 0 }}
            >
              <BadgeOcorrenciaTableCell disabled={!editionMode} />
            </Form.Item>
          );
        },
      },
      {
        title: 'Ações',
        sorter: false,
        dataIndex: 'acoes',
        width: '15%',
        render: (_, record: DataType, index: number) => {
          return (
            <Button
              onClick={() => {
                handleRemoverCompetitionEvent(index, record);
              }}
              data-testid='handle-cancel'
              type='link'
              danger
            >
              <DeleteOutlined /> Apagar
            </Button>
          );
        },
      },
    ];

    return columns;
  };

  const newRuleButtonClick = () => {
    toggleEventModal?.(true);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <TableComponent
            style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
            formInstance={badgeForm}
            columns={getTableColumns()}
            pageSize={7}
            data={listCompetitionEventsState}
            total={0}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            disabled={!editionMode}
            icon={<PlusCircleOutlined />}
            type='primary'
            onClick={newRuleButtonClick}
          >
            Nova regra
          </Button>
        </Col>
      </Row>
    </>
  );
};
