import { OrderBump } from '@models';
import {
  addOriginOfferOrderBump,
  createOrderBump,
  removeOriginOfferOrderBump,
  updateOrderBump,
} from '@service/api/orderBump';
import { createContext, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { Offer } from 'src/features/offers/interfaces/offersInterface';
import { TemplateEnum, TypeEnum } from 'src/models/OrderBump.model';

type TOrderBumpCreateProps = {
  defaultValues?: OrderBump.IOrderBump;
  isEditing?: boolean;
};

type TOrderBumpProviderProps = {
  children: React.ReactNode;
};

type TValueForm<T> = {
  value: T;
  setter: (value: T) => void;
};

type TCheckOfferIdResponse = {
  success: boolean;
  errorMessage?: string;
};

type TCreateOrderBumpContext = {
  name: TValueForm<string>;
  step: TValueForm<number>;
  statusSelected: TValueForm<boolean>;
  template: TValueForm<TemplateEnum>;
  title: TValueForm<string>;
  confirmationButtonText: TValueForm<string>;
  description: TValueForm<string>;
  primaryColor: TValueForm<string>;
  secondaryColor: TValueForm<string>;
  backgroundColor: TValueForm<string>;
  textColor: TValueForm<string>;
  linkImageWeb: TValueForm<string>;
  linkImageResponsive: TValueForm<string>;
  destinationOfferId: TValueForm<number>;
  originOffers: Offer[];
  addOriginOfferId: (offer: Offer) => Promise<TCheckOfferIdResponse>;
  removeOriginOfferId: (offerId: string) => Promise<TCheckOfferIdResponse>;
  titlePage: string;
  handleCancel: () => void;
  dispatchSubmit: () => Promise<void>;
  ableToSave: TValueForm<boolean>;
  isLoadingSave: TValueForm<boolean>;
  textButtonSubmit: string;
  textButtonPreview: string;
  isEditing: boolean;
};

const PREVIEW_PAGE = '/screens/order-bump';

export const CreateOrderBumpContext = createContext<TCreateOrderBumpContext>(
  {} as TCreateOrderBumpContext
);

export const CREATE_ORDER_BUMP_STEPS = ['Configurações gerais', 'Conteúdo'];

export function CreateOrderBumpProvider({ children }: TOrderBumpProviderProps) {
  const { state: params } = useLocation<TOrderBumpCreateProps>();

  const [name, setName] = useState(params?.defaultValues?.name || '');
  const [destinationOfferId, setDestinationOfferId] = useState<number>(
    params?.defaultValues?.destinationOfferId || 0
  );
  const [statusSelected, setStatusSelected] = useState<boolean>(
    !!params?.defaultValues ? params.defaultValues.isActive : true
  );
  const [originOffers, setOriginOffers] = useState<Offer[]>(
    params?.isEditing ? params?.defaultValues?.offers || [] : []
  );
  const [template, setTemplate] = useState<TemplateEnum>(
    params?.defaultValues?.template || TemplateEnum.TEMPA
  );
  const [title, setTitle] = useState(params?.defaultValues?.title || '');
  const [confirmationButtonText, setConfirmationButtonText] = useState(
    params?.defaultValues?.confirmationButtonText || ''
  );
  const [description, setDescription] = useState(
    params?.defaultValues?.description || ''
  );
  const [linkImageWeb, setLinkImageWeb] = useState(
    params?.defaultValues?.webLink || ''
  );
  const [linkImageResponsive, setLinkImageResponsive] = useState(
    params?.defaultValues?.mobileLink || ''
  );
  const [primaryColor, setPrimaryColor] = useState(
    params?.defaultValues?.primaryColor || '#FF4F00'
  );
  const [secondaryColor, setSecondaryColor] = useState(
    params?.defaultValues?.secondaryColor || '#FFEDE5'
  );
  const [backgroundColor, setBackgroundColor] = useState(
    params?.defaultValues?.backgroundColor || '#FFFFFF'
  );
  const [textColor, setTextColor] = useState(
    params?.defaultValues?.textColor || '#252525'
  );

  const [step, setStep] = useState(0);
  const [ableToSave, setAbleToSave] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const { push } = useHistory();

  useBreadcrumbs([
    {
      label: 'Gestão de Order Bump',
      route: '/screens/order-bump',
    },
    {
      label: `${params?.isEditing ? 'Edição de' : 'Novo'} Order Bump`,
    },
  ]);

  const addOriginOfferId = useCallback(
    async (offer: Offer) => {
      const duplicatedOffer = originOffers.find(({ id }) => offer.id === id);

      if (duplicatedOffer)
        return {
          success: false,
          errorMessage: 'Essa oferta já foi inserida',
        };

      if (offer.orderBumpId) {
        return {
          success: false,
          errorMessage: 'Essa oferta já possui order bump configurado!',
        };
      }

      if (!!params?.isEditing && !!params?.defaultValues?.id) {
        try {
          await addOriginOfferOrderBump(params?.defaultValues.id, [
            Number(offer.id),
          ]);
        } catch (err: any) {
          console.error(err);
          return {
            success: false,
            errorMessage: 'Não foi possível alterar a oferta!',
          };
        }
      }

      // Alterar na integração com API
      setOriginOffers(oldState => [...oldState, offer]);
      return {
        success: true,
      };
    },
    [originOffers]
  );

  const removeOriginOfferId = useCallback(async (offerId: string) => {
    if (!!params?.isEditing) {
      try {
        await removeOriginOfferOrderBump([Number(offerId)]);
      } catch (err: any) {
        console.error(err);
        return {
          success: false,
          errorMessage: 'Não foi possível alterar a oferta!',
        };
      }
    }
    setOriginOffers(oldState => oldState.filter(state => state.id !== offerId));
    return {
      success: true,
    };
  }, []);

  const handleCancel = useCallback(() => {
    if (step > 0) return setStep(oldValue => oldValue - 1);

    return push(PREVIEW_PAGE);
  }, [step]);

  const dispatchSubmit = useCallback(async () => {
    const nextStep = step + 1;
    if (nextStep < CREATE_ORDER_BUMP_STEPS.length) {
      setStep(nextStep);
      return;
    }
    try {
      setIsLoadingSave(true);
      const payload = {
        name,
        title,
        description,
        confirmationButtonText,
        template,
        destinationOfferId,
        isActive: statusSelected,
        type: TypeEnum.DIGITAL,
        webLink: linkImageWeb || undefined,
        mobileLink: linkImageResponsive || undefined,
        primaryColor,
        secondaryColor,
        backgroundColor,
        textColor,
      };

      if (!params?.isEditing) {
        const orderBump = await createOrderBump(payload);
        try {
          await addOriginOfferOrderBump(
            Number(orderBump.id),
            originOffers.map(({ id }) => Number(id))
          );
        } catch (err: any) {
          console.error(`Erro adicionar oferta de origem`, err);
        }
      } else if (params?.defaultValues) {
        await updateOrderBump({
          id: params?.defaultValues.id,
          ...payload,
        });
      }
      push(PREVIEW_PAGE);
    } catch (err: any) {
      console.error(`Erro criar order bump de origem`, err);
      setIsLoadingSave(false);
    }
  }, [
    step,
    name,
    title,
    description,
    confirmationButtonText,
    template,
    destinationOfferId,
    statusSelected,
    linkImageWeb,
    linkImageResponsive,
    primaryColor,
    secondaryColor,
    backgroundColor,
    textColor,
    originOffers,
    params?.isEditing,
  ]);

  const titlePage = useMemo(
    () => name || `${params?.isEditing ? 'Edição de' : 'Novo'} Order Bump`,
    [name, params?.isEditing]
  );
  const textButtonSubmit = useMemo(
    () =>
      step === CREATE_ORDER_BUMP_STEPS.length - 1
        ? params?.isEditing
          ? 'Alterar'
          : 'Cadastrar'
        : 'Continuar',
    [step, params?.isEditing]
  );

  const textButtonPreview = useMemo(
    () => (step === 0 ? 'Descartar' : 'Voltar'),
    [step]
  );

  return (
    <CreateOrderBumpContext.Provider
      value={{
        name: { value: name, setter: setName },
        statusSelected: { value: statusSelected, setter: setStatusSelected },
        textButtonPreview,
        template: {
          value: template,
          setter: setTemplate,
        },
        title: {
          value: title,
          setter: setTitle,
        },
        confirmationButtonText: {
          value: confirmationButtonText,
          setter: setConfirmationButtonText,
        },
        description: {
          value: description,
          setter: setDescription,
        },
        primaryColor: {
          value: primaryColor,
          setter: setPrimaryColor,
        },
        secondaryColor: {
          value: secondaryColor,
          setter: setSecondaryColor,
        },
        backgroundColor: {
          value: backgroundColor,
          setter: setBackgroundColor,
        },
        textColor: {
          value: textColor,
          setter: setTextColor,
        },
        linkImageWeb: {
          value: linkImageWeb,
          setter: setLinkImageWeb,
        },
        linkImageResponsive: {
          value: linkImageResponsive,
          setter: setLinkImageResponsive,
        },
        step: { value: step, setter: setStep },
        destinationOfferId: {
          value: destinationOfferId,
          setter: setDestinationOfferId,
        },
        isLoadingSave: {
          setter: setIsLoadingSave,
          value: isLoadingSave,
        },
        originOffers,
        addOriginOfferId,
        removeOriginOfferId,
        titlePage,
        handleCancel,
        dispatchSubmit,
        ableToSave: {
          value: ableToSave,
          setter: setAbleToSave,
        },
        textButtonSubmit,
        isEditing: !!params?.isEditing,
      }}
    >
      {children}
    </CreateOrderBumpContext.Provider>
  );
}
