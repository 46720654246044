import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Input, Row, Select } from 'antd';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { array, date, object, SchemaOf, string } from 'yup';

import DatePicker from 'src/app/components/antd/DatePicker';
import OpenNotification from 'src/app/components/antd/OpenNotification';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { EditButtonComponent } from 'src/features/clients/components/EditButtonComponent';
import { RoleType } from 'src/features/roles/api/roleApi';
import { SectorType } from 'src/features/sector/api/sectorApi';
import useGetAllSectors from 'src/features/sector/hooks/useGetAllSectors';

import { adminApi } from '../api/adminApi';
import { AdminDTOType } from '../types/adminTypes';

interface AdminProps {
  admin?: AdminDTOType;
  reloadAdmin: () => void;
}
const colorGrey = 'rgb(67, 67, 67)';

const ErrorMessage = 'Campo Obrigatório';

const schema: SchemaOf<AdminDTOType> = object({
  id: string(),
  fullName: string().required(ErrorMessage),
  document: string().required(ErrorMessage),
  email: string().email().required(ErrorMessage),
  birthDate: date().required(ErrorMessage),
  roles: array(),
  lastAccess: string(),
  sector: string().required(ErrorMessage),
}).defined();

const { Option } = Select;

export const FormAdminAccount: FC<AdminProps> = ({ admin, reloadAdmin }) => {
  const [form] = Form.useForm();
  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AdminDTOType>({
    resolver: yupResolver(schema),
    defaultValues: admin,
  });
  const [enableForm, setEnableForm] = useState(true);
  const handleEditForm = (
    event: React.MouseEvent<HTMLElement>,
    isEdit: boolean
  ) => {
    event.preventDefault();
    setEnableForm(!enableForm);
    if (isEdit) {
      return mutate(getValues());
    }
    return reset(admin);
  };
  const responseSector = useGetAllSectors();

  const updateAdmin = (data: AdminDTOType) => {
    data.id = (admin as AdminDTOType)?.id;
    return adminApi.updateAdmin(data);
  };
  const { mutate } = useMutation(updateAdmin, {
    onSuccess: data => {
      reloadAdmin();
      OpenNotification(false, 'Sucesso!', 'Admin atualizado com sucesso.');
    },
    onError: error => {
      OpenNotification(
        true,
        'Ocorreu um erro',
        'Tivemos ao atualizar Admin, por favor acione nosso suporte.'
      );
    },
  });

  const options = (response?: SectorType[]) => {
    const arrayOptions: JSX.Element[] = [];
    if (response) {
      response.forEach((item: RoleType, index: number) => {
        arrayOptions.push(
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        );
      });
    }
    return arrayOptions;
  };
  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={12}>
              <label>Perfil</label>
            </Col>
            <Col span={12}>
              <EditButtonComponent
                showSave={!enableForm}
                showEdit={enableForm}
                showDiscard={!enableForm}
                handleEditButton={handleEditForm}
              />
            </Col>
          </Row>
          <Form form={form} layout='horizontal'>
            <InputStyledFirst
              disabled
              bordered={false}
              addonBefore={<AddonBefore>Id do Cliente</AddonBefore>}
              value={(admin as AdminDTOType)?.id}
            />
            <InputStyled
              disabled
              bordered={false}
              addonBefore={<AddonBefore>Primeiro Acesso</AddonBefore>}
              value={dateFormatted(
                (admin as AdminDTOType)?.lastAccess || '',
                true
              )}
            />
            <Controller
              control={control}
              name='email'
              render={({ field }) => (
                <InputStyled
                  disabled={enableForm}
                  bordered={false}
                  addonBefore={<AddonBefore>E-mail Cadastrado</AddonBefore>}
                  {...field}
                />
              )}
            />
            <ErrorStyled>{errors.email?.message}</ErrorStyled>
            <Controller
              control={control}
              name='fullName'
              render={({ field }) => {
                return (
                  <InputStyled
                    bordered={false}
                    disabled={enableForm}
                    addonBefore={<AddonBefore>Nome</AddonBefore>}
                    {...field}
                  />
                );
              }}
            />
            <ErrorStyled>{errors.fullName?.message}</ErrorStyled>
            <span
              className='ant-input-group-wrapper'
              style={{
                width: '100%',
                borderBottomColor: colorGrey,
                borderBottomStyle: 'solid',
                borderRadius: 0,
                borderBottomWidth: 1,
                borderTop: 'none',
                borderTopStyle: 'none',
                borderRightColor: colorGrey,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
              }}
            >
              <span className='ant-input-wrapper ant-input-group'>
                <span className='ant-input-group-addon'>
                  <AddonBefore>Data de nascimento</AddonBefore>
                </span>
                <Controller
                  control={control}
                  name='birthDate'
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      style={{ width: '100%' }}
                      bordered={false}
                      disabled={enableForm}
                      format='dd/MM/yyyy'
                      placeholder='Selecionar data'
                      onChange={onChange}
                      value={value ? new Date(value) : new Date()}
                      defaultValue={value}
                    />
                  )}
                />
                <ErrorStyled>{errors.birthDate?.message}</ErrorStyled>
              </span>
            </span>
            <Controller
              control={control}
              name='document'
              render={({ field }) => (
                <InputStyled
                  bordered={false}
                  disabled={enableForm}
                  addonBefore={<AddonBefore>CPF</AddonBefore>}
                  {...field}
                />
              )}
            />
            <ErrorStyled>{errors.document?.message}</ErrorStyled>
            <span
              className='ant-input-group-wrapper'
              style={{
                width: '100%',
                borderBottomColor: colorGrey,
                borderBottomStyle: 'solid',
                borderRadius: 0,
                borderBottomWidth: 1,
                borderTop: 'none',
                borderTopStyle: 'none',
                borderRightColor: colorGrey,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
              }}
            >
              <span className='ant-input-wrapper ant-input-group'>
                <span className='ant-input-group-addon'>
                  <AddonBefore>Setor</AddonBefore>
                </span>
                <Controller
                  name='sector'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        bordered={false}
                        disabled={enableForm}
                        style={{
                          width: '100%',
                          borderBottomColor: colorGrey,
                          borderBottomStyle: 'solid',
                          borderRadius: 0,
                          borderBottomWidth: 0,
                          borderTop: 'none',
                          borderTopStyle: 'none',
                          borderRightColor: colorGrey,
                          borderRightStyle: 'solid',
                          borderRightWidth: 0,
                        }}
                        allowClear
                        {...field}
                        onChange={e => {
                          setValue('sector', e);
                        }}
                      >
                        {options(responseSector.data)}
                      </Select>
                    );
                  }}
                />
              </span>
            </span>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const AddonBefore = styled.div`
  width: 30vw;
  text-align: left;
`;
const ErrorStyled = styled.div`
  font-size: 12px;
  line-height: 22px;
  color: #ff0000;
`;
const InputStyled = styled(Input)`
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;

const InputStyledFirst = styled(Input)`
  border-top-color: rgb(67, 67, 67);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(67, 67, 67);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: rgb(67, 67, 67);
  border-right-style: solid;
  border-right-width: 1px;
`;
