import { EvidenceStatus, StatusBase } from '../typings/lgpdTipyngs';

export function factoryStatusAnonimization(status: StatusBase): EvidenceStatus {
  const statusTransform = {
    'Gerando evidências': 'loading',
    'Evidências geradas': 'success',
    'Evidências não foram geradas': 'error',
    'Anonimizando dados': 'loadingAnonymization',
    'Dados anonimizados': 'successAnonymization',
    'Dados não foram anonimizados': 'errorAnonymization',
  };

  return statusTransform[status] as EvidenceStatus;
}
