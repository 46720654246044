import { DisconnectOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';

import * as S from './TabExtra.style';

interface TabExtraProps {
  onClickToUnlink?: () => void;
  onClickToLink: () => void;
  onFilter?: (value: string) => void;
  unlinkDisabled?: boolean;
  linkDisabled?: boolean;
  onlyLink?: boolean;
  filterPlaceholder?: string;
  filterValue?: string;
}

export const TabExtra: React.FC<TabExtraProps> = ({
  onFilter,
  onClickToLink,
  onClickToUnlink,
  onlyLink,
  unlinkDisabled,
  linkDisabled,
  filterPlaceholder,
  filterValue,
}) => {
  return (
    <S.Container>
      {onlyLink && (
        <>
          <S.SearchContainer>
            <Input.Search
              placeholder={filterPlaceholder || ''}
              onChange={evt => onFilter?.(evt.target.value)}
              value={filterValue}
              allowClear
            />
          </S.SearchContainer>
          <S.UnlinkButtonContainer>
            <Button
              onClick={onClickToUnlink}
              icon={<DisconnectOutlined />}
              disabled={!unlinkDisabled}
            >
              Desvincular
            </Button>
          </S.UnlinkButtonContainer>
        </>
      )}
      <S.LinkContainer>
        <Button
          onClick={onClickToLink}
          icon={<LinkOutlined />}
          disabled={linkDisabled}
        >
          Vincular
        </Button>
      </S.LinkContainer>
    </S.Container>
  );
};
