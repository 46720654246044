import { Checkbox } from 'antd';
import React from 'react';

export const RememberMeCheckbox: React.FC = () => {
  return (
    <Checkbox data-testid='remember-me' checked>
      Lembrar senha
    </Checkbox>
  );
};
