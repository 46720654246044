import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';

import { apiCancellationFunnels } from '../api';

export const uploadS3 = async (file: UploadChangeParam<UploadFile>) => {
  try {
    const response = await apiCancellationFunnels.tryToRequestUpload(
      file.file.originFileObj as RcFile
    );

    return response.data.url;
  } catch {
    return '';
  }
};
