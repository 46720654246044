import { InboxOutlined } from '@ant-design/icons';
import { Form, Image, TooltipProps, Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Control,
  Controller as ControllerHookForm,
  useWatch,
} from 'react-hook-form';

import { env } from 'src/app/env';
import { toFieldStatus } from 'src/app/utils/toFieldStatus';

const { Item: FormItem } = Form;

interface UploadBiggerControllerProps extends UploadProps {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  required?: boolean;
  label?: string;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
  maxNumberImage?: number;
}

export const UploadBiggerController: React.FC<UploadBiggerControllerProps> = ({
  control,
  name,
  required,
  label,
  children,
  tooltip,
  maxNumberImage = 1,
  ...rest
}) => {
  const [base64, setBase64] = useState<string>();
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const fileValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    if (fileValue) {
      setFileList([
        {
          uid: fileValue,
          name: `image-${fileValue}`,
          status: 'done',
          url: `${env.FILE_API}/files/files/view/${fileValue}`,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (fileValue?.file) {
      getBase64(fileValue.file.originFileObj, url => {
        setBase64(url);
      });
    }
  }, [fileValue]);

  const onPreview = async (file: UploadFile) => {
    if (base64) {
      file.url = base64;
    }
    if (file.url) {
      setPreviewImage(file.url);
      setPreviewOpen(true);
    }
  };

  return (
    <ControllerHookForm
      control={control}
      name={name}
      render={({ field: { onChange, ...fields }, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);

        const handleOnChange: UploadProps['onChange'] = ({
          fileList: newFileList,
        }) => {
          setFileList(newFileList);

          const changedValue = newFileList.length
            ? { file: newFileList[0], fileList: newFileList }
            : undefined;

          onChange(changedValue);
        };

        return (
          <FormItem
            validateStatus={status}
            help={help}
            label={label}
            {...(tooltip && { tooltip: tooltip })}
            {...(required && { required: required })}
          >
            <Upload.Dragger
              {...fields}
              {...rest}
              fileList={fileList}
              name={name}
              listType='picture'
              maxCount={maxNumberImage}
              onChange={handleOnChange}
              onPreview={onPreview}
              accept='image/png, image/jpeg, image/gif'
              multiple={false}
              beforeUpload={file => {
                getBase64(file, urlString => {
                  setBase64(urlString);
                });
                return false;
              }}
            >
              <div>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  <p>
                    <b style={{ color: '#1668DC' }}>Clique para carregar </b>
                    <span>ou arraste e solte</span>
                  </p>
                </p>
                <p className='ant-upload-hint'>{paragraphText(name)}</p>
              </div>
            </Upload.Dragger>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                src={base64 || fileList[0].url}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: visible => {
                    setPreviewOpen(visible);
                    if (!visible) setPreviewImage('');
                  },
                }}
              />
            )}
          </FormItem>
        );
      }}
    />
  );
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const paragraphText = (name: string) => {
  return name.includes('imageWeb')
    ? '.png, .jpg ou .gif 1280x720pixel (@2x)'
    : name.includes('imageMobile')
    ? '.png, .jpg ou .gif 560x680pixel (@2x)'
    : '';
};
