import { PageHeader } from '@organisms';
import { FC } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import BasicInfo from '../components/BasicInfo';
import Permissions from '../components/Permissions';
import UsersProfile from '../components/UsersProfile';

export const RoleScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Permissões',
    },
  ]);
  return (
    <>
      <BasicInfo />
      <UsersProfile />
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Permissões'}
        subTitle={''}
      />
      <Permissions />
    </>
  );
};
