import { useCallback, useEffect } from 'react';
import { UseFieldArrayReplace, UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { OfferFormData } from '../../types/offerPageTypings';
import { useTryToRequestOfferPage } from './useTryToRequestOfferPage';
import { useTryToRequestUpdateOfferPage } from './useTryToRequestUpdateOfferPage';

interface UseRequestOfferPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<Omit<OfferFormData, 'name'>>;
  replace: UseFieldArrayReplace<Omit<OfferFormData, 'name'>, 'slides'>;
}

export function useRequestOfferPage({
  isUpdate,
  setIsUpdate,
  setTitlePage,
  setValue,
  replace,
}: UseRequestOfferPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestOfferPage } = useTryToRequestOfferPage();
  const { tryToRequestUpdateOfferPage, isLoadingUpdateOffer } =
    useTryToRequestUpdateOfferPage();

  const handleUpdateOfferPage = useCallback(
    async (e: Omit<OfferFormData, 'name'>, titlePage: string) => {
      await tryToRequestUpdateOfferPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [tryToRequestUpdateOfferPage, pageId]
  );

  const handleRequestOfferPage = useCallback(async () => {
    await tryToRequestOfferPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      setTitlePage(response.name);
      replace(response.slides);
    });
  }, [tryToRequestOfferPage, setValue, setTitlePage]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestOfferPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { handleUpdateOfferPage, isLoadingUpdateOffer };
}
