import styled from 'styled-components';

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
`;

export const Space = styled.div`
  margin: 30px;
`;
