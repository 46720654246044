import { CountResponse } from '@typings';

import { Offer } from 'src/features/offers/interfaces/offersInterface';

export enum TemplateEnum {
  TEMPA = 'tempA',
  TEMPB = 'tempB',
}

export enum TypeEnum {
  DIGITAL = 'digital',
  PHYSICAL = 'physical',
}

export interface IOrderBump {
  id: number;
  name: string;
  title: string;
  description: string;
  confirmationButtonText: string;
  isActive: boolean;
  template: TemplateEnum;
  type: TypeEnum;
  webLink?: string;
  mobileLink?: string;
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  destinationOfferId: number;
  createdAt: string;
  textColor: string;
  destinationOffer: Offer;
  offers: Offer[];
}

export type TOrderBumpResponse = CountResponse<IOrderBump>;

const OrderBumpTemplateLabels: { [key in TemplateEnum]: string } = {
  [TemplateEnum.TEMPA]: 'Template A',
  [TemplateEnum.TEMPB]: 'Template B',
};

const OrderBumpTypeLabels: { [key in TypeEnum]: string } = {
  [TypeEnum.DIGITAL]: 'Digital',
  [TypeEnum.PHYSICAL]: 'Físico',
};

export const getLabelTemplate = (tipo: TemplateEnum): string => {
  return OrderBumpTemplateLabels[tipo];
};

export const getLabelType = (tipo: TypeEnum): string => {
  return OrderBumpTypeLabels[tipo];
};

export const getLabelStatus = (isActive: boolean): string => {
  return isActive ? 'Ativo' : 'Inativo';
};
