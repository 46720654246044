import { RootState, slices } from '@core/redux';
import { usePrograms } from '@hooks';
import { Events } from '@models';
import { PageHeader } from '@organisms';
import { EventDetailTemplate } from '@templates';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
export const EventDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { normalizeProgramsAndClasseseRequest } = usePrograms();
  const { state } = useLocation<{ id: string }>();
  const { eventDetail } = useSelector((state: RootState) => state.events);
  const [enableEdition, setEnableEdition] = useState(false);

  const [formData, setFormData] = useState<Events.EventEditingForm>({});
  const [formDataOriginal, setFormDataOriginal] =
    useState<Events.EventEditingForm>({});

  useEffect(() => {
    if (!state?.id) {
      history.push('/social/gamefication/events');
      return;
    }

    dispatch(slices.events.getEventById(state.id));
  }, []);

  useEffect(() => {
    if (!eventDetail?.identifier) return;
    setFormDataOriginal(eventDetail);
    setFormData(eventDetail);
  }, [eventDetail]);

  const handleEditFormData = (field: string, value: any) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleDiscardChanges = () => {
    setFormData(formDataOriginal);
    setEnableEdition(false);
  };

  const handleSaveEdition = async () => {
    const eventsContents = normalizeProgramsAndClasseseRequest(
      formData?.programs || []
    );

    dispatch(
      slices.events.updateEvent({
        id: eventDetail?.id || 0,
        identifier: formData?.identifier || '',
        name: formData?.name || '',
        description: formData?.description || '',
        eventTypeId: Number(formData?.eventTypeId) || 0,
        statusActivated: formData.statusActivated,
        eventsContents,
        languages: [
          { locale: 'es_ES', description: formData?.description_es || '' },
          { locale: 'en_US', description: formData?.description_en || '' },
          { locale: 'pt_BR', description: formData?.description_pt || '' },
        ],
      } as Events.EventForm)
    );

    setEnableEdition(false);
  };

  const handleDeleteEvent = async () => {
    if (!eventDetail?.identifier) return;
    await dispatch(slices.events.deleteEvent(eventDetail.identifier));
    history.replace('/social/gamefication/events');
  };

  useBreadcrumbs([
    {
      label: 'Eventos',
    },
  ]);

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de eventos'}
        subTitle={''}
      />

      <EventDetailTemplate
        enableForm={!enableEdition}
        formData={formData}
        handleChange={handleEditFormData}
        handleDeleteEvent={handleDeleteEvent}
        handleSaveEdition={handleSaveEdition}
        handleDiscardEdition={handleDiscardChanges}
        handleEditEvent={() => setEnableEdition(true)}
      />
    </>
  );
};
