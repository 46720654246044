import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { selectCurrentBreadcrumbs } from 'src/features/layout/selectors/layoutSelectors';

export const AppBreadCrumbs: FC = () => {
  const items = useSelector(selectCurrentBreadcrumbs);
  const location = useLocation();

  if (items.length === 0 || location.pathname === '/dashboard') {
    return <></>;
  }
  const breadcrumbItems =
    typeof items !== 'string'
      ? items.map((item, index) => {
          return (
            <Breadcrumb.Item key={`bc-${index}`}>
              {item.route && index !== items.length - 1 ? (
                <Link to={item.route ?? '/'}>{item.label}</Link>
              ) : (
                item.label
              )}
            </Breadcrumb.Item>
          );
        })
      : [];
  return (
    <>
      <Breadcrumb style={{ margin: 16, paddingInlineStart: '105px' }}>
        <Breadcrumb.Item key={`bc-arrow`}>
          <Link to={'/'} key={`link-home`}>
            Admin
          </Link>
        </Breadcrumb.Item>
        {breadcrumbItems}
      </Breadcrumb>
    </>
  );
};
