import { Switch } from 'antd';

import { useCreateOrderBump } from '../../../../hooks/useCreateOrderBump';
import {
  Container,
  ErrorText,
  FormOrderBump,
  InputFormOrderBump,
  StatusContainer,
  StatusDisplay,
} from './styles';
import { TableOriginOffers } from './TableOriginOffers';
import { useGeneralConfiguration } from './useGeneralConfigurations';

export function GeneralConfigurations() {
  const {
    descriptionStatus,
    form,
    onChangeStatus,
    statusSelected,
    handleSearchOriginOffer,
    originOffers,
    handleRemoveOriginOffer,
    isLoadingRemoveOriginOffer,
    isSearchOriginOffer,
    searchOriginOfferError,
    searchOriginOffer,
    setSearchOriginOffer,
    destinyOfferStatus,
    handleSearchDestinyOffer,
    searchDestinyOfferError,
    onChangeSearchDestinyOffer,
    destinationOffer,
  } = useGeneralConfiguration();

  const { name } = useCreateOrderBump();

  return (
    <Container style={{ overflow: 'hidden' }}>
      <FormOrderBump
        form={form}
        layout='vertical'
        onFinishFailed={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <FormOrderBump.Item
          name='name'
          label='Nome do Order Bump'
          initialValue={name.value}
        >
          <InputFormOrderBump
            style={{ textTransform: 'uppercase' }}
            maxLength={110}
            value={name.value}
            onChange={({ target }) => name.setter(target.value)}
            placeholder='Escolha um nome'
            showCount
          />
        </FormOrderBump.Item>
        <FormOrderBump.Item
          name='destinationOffer'
          label='Oferta Destino'
          tooltip='OfferID'
          hasFeedback
          validateStatus={destinyOfferStatus}
          initialValue={destinationOffer}
        >
          <InputFormOrderBump
            type='number'
            placeholder='Insira o ID de destino'
            onBlurCapture={({ target }) =>
              handleSearchDestinyOffer(target.value)
            }
            value={destinationOffer}
            onChange={({ target }) => onChangeSearchDestinyOffer(target.value)}
            readOnly={destinyOfferStatus === 'validating'}
            allowClear
          />
          {!!searchDestinyOfferError && (
            <ErrorText>{searchDestinyOfferError}</ErrorText>
          )}
        </FormOrderBump.Item>
        <FormOrderBump.Item
          name='originOffer'
          label='Local de Exibição do Order Bump no Checkout'
        >
          <InputFormOrderBump.Search
            name='originOffer'
            id='originOffer'
            value={searchOriginOffer}
            onChange={({ target }) => setSearchOriginOffer(target.value)}
            placeholder='Insira o ID produto / checkout / oferta'
            enterButton='Adicionar oferta'
            onSearch={handleSearchOriginOffer}
            readOnly={isSearchOriginOffer}
            loading={isSearchOriginOffer}
          />
          {!!searchOriginOfferError && (
            <ErrorText>{searchOriginOfferError}</ErrorText>
          )}
        </FormOrderBump.Item>
        {!!originOffers.length && (
          <TableOriginOffers
            offers={originOffers}
            onRemoveOffer={handleRemoveOriginOffer}
            hasLoadingRemoveOffer={isLoadingRemoveOriginOffer}
          />
        )}
        <FormOrderBump.Item name='status' label='Status'>
          <StatusContainer>
            <StatusDisplay>{descriptionStatus}</StatusDisplay>
            <Switch checked={statusSelected.value} onChange={onChangeStatus} />
          </StatusContainer>
        </FormOrderBump.Item>
      </FormOrderBump>
    </Container>
  );
}
