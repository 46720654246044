import { FC } from 'react';
import { AnyObject } from 'yup/lib/types';

interface Props {
  events?: Array<AnyObject>;
  index?: number;
}

export const BadgeEventsTableCell: FC<Props> = ({
  events = undefined,
  index,
}) => {
  if (!events || (events && !events.length)) {
    return <>Nenhum evento encontrado</>;
  }

  return (
    <>
      {events.map((p, index) => {
        return (index === 0 ? '' : ' / ') + p.name;
      })}
    </>
  );
};
