import { enviroments } from '@infrastructure/enviroments';
import { Assets } from '@models';
import { base } from '@service/base';
import { CountResponse, TakeSkipFilter } from '@typings';

const BANNER_MS_BASE_URL = enviroments.BASE_URL;

interface SegmentParams {
  segmentIdsIn?: number[];
  segmentIdsNotIn?: number[];
}

export async function getAssetsBySegments(
  { skip, take, filter }: TakeSkipFilter,
  segments: SegmentParams
): Promise<CountResponse<Assets.IAsset>> {
  const where = {
    or: [] as Record<string, string>[],
  };
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where.or.push({ [key]: filter[key] });
      }
    });
  }

  let params: any = {
    skip: skip || 0,
    take: take || 10,
  };

  if (where.or.length) {
    params.where = JSON.stringify({
      ...where,
    });
  }

  if (segments.segmentIdsNotIn?.length) {
    params = {
      ...params,
      segmentIdsNotIn: JSON.stringify([...segments.segmentIdsNotIn]),
    };
  }

  if (segments.segmentIdsIn?.length) {
    params = {
      ...params,
      segmentIds: JSON.stringify([...segments.segmentIdsIn]),
    };
  }

  const { data } = await base({
    baseURL: BANNER_MS_BASE_URL,
    url: `/assets`,
    method: 'GET',
    params,
  });

  if (!data) {
    throw Error(data.message);
  }

  return data;
}
