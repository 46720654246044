import { PageHeader } from '@organisms';
import { useEffect, useState } from 'react';

import { useVerifyPermission } from 'src/features/dashboard/hooks/useVerifyPermission';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { ModalLgpd } from '../components/ModalLgpd/ModalLgpd';
import { TableLgpd } from '../components/TableLgpd';

export const LgpdPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [permissionMaster, isLoading] = useVerifyPermission();

  useEffect(() => {
    !isLoading && !permissionMaster && (window.location.href = '../dashboard');
  }, [isLoading]);

  useBreadcrumbs([
    {
      label: 'LGPD',
    },
  ]);

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'LGPD'}
        subTitle={''}
      />

      <TableLgpd setShowModal={setShowModal} showModal={showModal} />
      <ModalLgpd setModalVisibility={setShowModal} isVisible={showModal} />
    </>
  );
};
